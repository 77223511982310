<template>
  <el-dialog append-to-body class="ss-dialog" width="520px" :visible.sync="dialogVisible"
    :title="clickitem ? '编辑' : '新建'">
    <div class="dialog-body">
      <el-form class="add-form" label-position="right" label-width="100px" :model="queryData">
        <el-form-item label="名称" required>
          <el-input v-model="queryData.columnName" maxlength="10" show-word-limit></el-input>
        </el-form-item>
        <el-form-item label="编辑器类型" required>
          <el-radio :disabled="clickitem ? true : false" v-model="queryData.editorType" label="1">网页编辑器</el-radio>
          <el-radio :disabled="clickitem ? true : false" v-model="queryData.editorType" label="2">富文本编辑器</el-radio>
        </el-form-item>
        <el-form-item label="图" required>
          <el-upload class="avatar-uploader" accept=".jpg,.jpeg,.png" action="" ref="upload" :auto-upload="false"
            :multiple="false" :on-change="onChange" :show-file-list="false">
            <img v-if="showList[0]" :src="showList[0].coverUrl" class="avatar" />
            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
          </el-upload>
        </el-form-item>
      </el-form>
    </div>
    <span slot="footer">
      <div class="dialog-footer">
        <span class="info"></span>
        <span>
          <el-button type="info" @click="dialogVisible = false">取&nbsp;消</el-button>
          <el-button type="primary" :loading="confirmLoading" @click="confirmSave">确&nbsp;定</el-button>
        </span>
      </div>
    </span>
  </el-dialog>
</template>
<script>
import { File } from "@/api/File";
import { ProductLibrary } from "@/api/ProductLibrary";
export default {
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    activeColumn: Object,
    queryIds: Object,
    clickitem: Object,
    // columntypeid: String,
  },
  data() {
    return {
      showList: [],
      queryData: {
        columnCategoryId: "",
        columnIcon: "",
        columnId: "", //栏目id
        columnName: "",
        id: "", //主题/公司名片id
        moduleId: "",
        parentId: "",
        // columnTypeId: "",
        // sortNo: 0,
        editorType: "1", //辑器类型 1:网页编辑器 2:富文本编辑器
        versionId: "",
      },
      confirmLoading: false,
    };
  },
  computed: {
    dialogVisible: {
      get() {
        return this.visible;
      },
      set(val) {
        this.$emit("update:visible", val);
      },
    },
  },
  mounted() {
    if (this.clickitem) {
      const props = [
        "parentId",
        "columnCategoryId",
        "versionId",
        "columnName",
        "sortNo",
        "columnIcon",
      ];
      this.queryData = _.pick(this.clickitem, props);
      this.queryData.id = this.queryIds.subjectId;
      this.queryData.columnId = this.clickitem.id;
      this.queryData.editorType = this.clickitem.editorType ? this.clickitem.editorType : "2"
      this.showList = [
        {
          coverType: null,
          coverUrl: this.clickitem.columnIcon.startsWith("http")
            ? this.clickitem.columnIcon
            : require("@/assets/images/sample-5.png"),
          type: "succeed",
        },
      ];
    } else {
      this.queryData.columnCategoryId = this.activeColumn.columnCategoryId;
      this.queryData.parentId = this.activeColumn.id;
      this.queryData.moduleId = this.queryIds.moduleId;
      this.queryData.id = this.queryIds.subjectId;
      // this.queryData.columnTypeId = this.columntypeid;
      this.queryData.versionId = this.queryIds.versionId;
    }
  },
  methods: {
    /**
     * @description: 文件状态改变时的钩子，添加文件、上传成功和上传失败时都会被调用
     * @param {Object} file 文件
     * @return {void}
     */
    async onChange(file) {
      if (file.raw.type.indexOf("image") !== -1) {
        if (file.size > 2 * 1024 * 1024) {
          this.$message.error("封面图片大小不能超过 2MB!");
          this.$refs.upload.handleRemove(file);
          return;
        }
      }
      let cover = {
        coverType: null,
        coverUrl: URL.createObjectURL(file.raw),
        id: file.uid,
        file: file,
        type: "ready",
      };
      if (file.raw.type.indexOf("image") !== -1) {
        cover.coverType = 1;
        this.showList = [cover];
      }
    },
    /**
     * @description: 保存
     * @return {void}
     */
    async confirmSave() {
      if (!this.queryData.columnName) {
        return this.$message.warning("请输入名称");
      }
      if (!this.showList[0]) {
        return this.$message.warning("请添加封面");
      }
      this.confirmLoading = true;
      if (this.showList[0].type === "ready") {
        let formData = new FormData();
        formData.append("photo", this.showList[0].file.raw);
        const coverUrl = await File.uploadCoverPhoto(formData);
        this.queryData.columnIcon = coverUrl.src;
      }
      if (!this.queryData.columnIcon) {
        return;
      }
      const res = await ProductLibrary.updateColumn(
        this.clickitem ? "edit" : "add",
        this.queryData
      );
      if (res) {
        this.dialogVisible = false;
        this.$emit("finish", res);
      }
      this.confirmLoading = false;
    },
  },
};
</script>
<style lang="scss" scoped>
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 100px;
  height: 80px;
  line-height: 80px;
  text-align: center;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.avatar {
  width: 100px;
  height: 80px;
  display: block;
  border-radius: 5px;
}

.add-form {
  padding: 20px 30px;
}
</style>
