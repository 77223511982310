import {
  Row,
  Col,
  Container,
  Header,
  Aside,
  Main,
  Footer,
  Icon,
  Button,
  ButtonGroup,
  Link,
  Radio,
  RadioGroup,
  RadioButton,
  Checkbox,
  CheckboxButton,
  CheckboxGroup,
  Input,
  InputNumber,
  Select,
  Option,
  OptionGroup,
  Cascader,
  CascaderPanel,
  Switch,
  Slider,
  TimeSelect,
  DatePicker,
  TimePicker,
  Upload,
  Rate,
  ColorPicker,
  Transfer,
  Form,
  FormItem,
  Table,
  TableColumn,
  Tag,
  Progress,
  Tree,
  Pagination,
  Badge,
  Avatar,
  Skeleton,
  SkeletonItem,
  Empty,
  Descriptions,
  DescriptionsItem,
  Result,
  Alert,
  Loading,
  Message,
  MessageBox,
  Notification,
  Menu,
  Submenu,
  MenuItem,
  MenuItemGroup,
  Tabs,
  TabPane,
  Breadcrumb,
  BreadcrumbItem,
  PageHeader,
  Dropdown,
  DropdownMenu,
  DropdownItem,
  Steps,
  Step,
  Dialog,
  Tooltip,
  Popover,
  Popconfirm,
  Card,
  Carousel,
  CarouselItem,
  Collapse,
  CollapseItem,
  Timeline,
  TimelineItem,
  Divider,
  Calendar,
  Image,
  Backtop,
  InfiniteScroll,
  Drawer,
  Autocomplete,
  Scrollbar,
  Spinner
} from 'element-ui'
const element = {
  install: function (Vue) {
    Vue.use(Row);
    Vue.use(Col);
    Vue.use(Container);
    Vue.use(Header);
    Vue.use(Aside);
    Vue.use(Main);
    Vue.use(Footer);
    Vue.use(Icon);
    Vue.use(Button);
    Vue.use(ButtonGroup);
    Vue.use(Link);
    Vue.use(Radio);
    Vue.use(RadioGroup);
    Vue.use(RadioButton);
    Vue.use(Checkbox);
    Vue.use(CheckboxButton);
    Vue.use(CheckboxGroup);
    Vue.use(Input);
    Vue.use(InputNumber);
    Vue.use(Select);
    Vue.use(Option);
    Vue.use(OptionGroup);
    Vue.use(Cascader);
    Vue.use(CascaderPanel);
    Vue.use(Switch);
    Vue.use(Slider);
    Vue.use(TimeSelect);
    Vue.use(DatePicker);
    Vue.use(TimePicker);
    Vue.use(Upload);
    Vue.use(Rate);
    Vue.use(ColorPicker);
    Vue.use(Transfer);
    Vue.use(Form);
    Vue.use(FormItem);
    Vue.use(Table);
    Vue.use(TableColumn);
    Vue.use(Tag);
    Vue.use(Progress);
    Vue.use(Tree);
    Vue.use(Pagination);
    Vue.use(Badge);
    Vue.use(Avatar);
    Vue.use(Skeleton);
    Vue.use(SkeletonItem);
    Vue.use(Empty);
    Vue.use(Descriptions);
    Vue.use(DescriptionsItem);
    Vue.use(Result);
    Vue.use(Alert);
    Vue.use(Menu);
    Vue.use(Submenu);
    Vue.use(MenuItem);
    Vue.use(MenuItemGroup);
    Vue.use(Tabs);
    Vue.use(TabPane);
    Vue.use(Breadcrumb);
    Vue.use(BreadcrumbItem);
    Vue.use(PageHeader);
    Vue.use(Dropdown);
    Vue.use(DropdownMenu);
    Vue.use(DropdownItem);
    Vue.use(Steps);
    Vue.use(Step);
    Vue.use(Dialog);
    Vue.use(Tooltip);
    Vue.use(Popover);
    Vue.use(Popconfirm);
    Vue.use(Card);
    Vue.use(Carousel);
    Vue.use(CarouselItem);
    Vue.use(Collapse);
    Vue.use(CollapseItem);
    Vue.use(Timeline);
    Vue.use(TimelineItem);
    Vue.use(Divider);
    Vue.use(Calendar);
    Vue.use(Image);
    Vue.use(Backtop);
    Vue.use(Drawer);
    Vue.use(Autocomplete);
    Vue.use(Scrollbar);
    Vue.use(Spinner);

    Vue.use(Loading.directive);
    Vue.prototype.$loading = Loading.service;
    Vue.prototype.$message = Message;
    Vue.prototype.$msgbox = MessageBox;
    Vue.prototype.$alert = MessageBox.alert;
    Vue.prototype.$confirm = MessageBox.confirm;
    Vue.prototype.$prompt = MessageBox.prompt;
    Vue.prototype.$notify = Notification;
    Vue.directive(InfiniteScroll.name, InfiniteScroll);
  }
}

export default element
