<template>
  <div class="web-editor w-h-100" :class="{ preview: readonly }">
    <InitEditor ref="editor" />
  </div>
</template>
<script>
import "custom-web-editor/dist/custom-web-editor.css";
import { File } from "@/api/File";

export default {
  name: "web-editor",
  props: {
    value: {
      type: String,
      default: "",
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    subjectId: {
      type: String,
      default: "ff8080818940657d01894281d1150003",
    },
    moduleId: {
      type: String,
      default: "1665920045710831617",
    },
    datatype: {
      type: Number,
      default: 6,
    },
  },
  data() {
    return {
      edittext: "",//传入的html
      editortool: null,//编辑器
    };
  },
  watch: {
    value(val) {
      this.editortool.importByHTMLText(val);
    },
    readonly(val) {
      if (val) {
        this.editortool.toPreview();
      } else {
        this.editortool.toStopPreview();
      }
    },
  },
  mounted() {
    this.edittext = _.cloneDeep(this.value);
    this.editortool = this.$refs.editor;
    // 导入html文本
    this.editortool.importByHTMLText(this.edittext);
    this.$nextTick(() => {
      if (this.readonly && this.editortool) {
        this.editortool.toPreview();
      }
    });
    // this.setUploadConfig()
    const uploadFile = async(file) => {
      const formData = new FormData();
      formData.append("photo", file);
      formData.append(`subjectId`, this.subjectId);
      formData.append(`moduleId`, this.moduleId);
      formData.append(`dataType`, this.datatype); //1:内容类(栏目中的);6：思维导图文件
      let res = await File.uploadFile(formData);
      if(res){
        return res.src
      }
    };
    // 设置图片上传路径
    this.editortool.setUploadConfig(uploadFile);
    // 设置视频上传路径
    this.editortool.setVideoUploadCommands(uploadFile);

    // 导入html远端路径测试
    // ed.importByHTMLPath('http://localhost:8081/html/switch.html');

    // 缓存设置测试
    // ed.setStore(true);//参数表示是否开启缓存
  },
  methods: {
    /**
     * @description:获取当前编辑器的html
     * @return {String} 返回当前编辑器的html
     */
    getHTMLText() {
      // 导出html内容测试
      const { html, css } = this.editortool.getHTMLText();
      // console.log('html:',html);
      // console.log('css:',css);
      const text = `<body>${html}</body><style>${css}</style>`;
      return text;
    },

    // 修改文件上传监听配置
    // async setUploadConfig(){
    //   let assetManager = this.$refs.editor.editor.AssetManager;
    //   // let serve = new Function('response','return '+todo);
    //   assetManager.config.multiUpload = false;
    //   // 设置上传路径
    //   // assetManager.config.upload = 'http://home.i3vsoft.com:1080/sales-show-4.0/file/upload';
    //   assetManager.config.upload = '';
    //   // 设置上传后获取返回路径的对象代码
    //   // assetManager.config.serve = serve;
    //   // 设置上传函数
    //   assetManager.config.uploadFile = async (e) =>{
    //     // const reu = await prompt("请输入","");
    //     let files = [];
    //     // 拖拽图片上传
    //     if(e.type=='drop'){
    //       files = [].slice.call(e.dataTransfer.files);  // es5 格式
    //     }else{
    //       files = e.target.files;
    //     }
    //     const file = files[0];
    //     if(!file)return;
    //     // 判断文件类型
    //     if(!file.type.startsWith("image")){
    //       alert("文件格式错误！");
    //       return;
    //     }
    //     const formData = new FormData();
    //     // formData.append('file', file);
    //     formData.append("photo", file);
    //     formData.append(`subjectId`, this.subjectId);
    //     formData.append(`moduleId`, this.moduleId);
    //     formData.append(`dataType`, this.datatype); //1:内容类(栏目中的);6：思维导图文件
    //     let res = await File.uploadFile(formData);
    //     if(res){
    //       assetManager.add(res.src);
    //     }
    //   }
    // },
  },
};
</script>

<style lang="scss" scoped>
.web-editor {
  overflow: hidden;
  div:first-of-type {
    height: 100%;
  }
}
::v-deep .gjs {
  border: none !important;
  height: 100% !important;
}

::v-deep #gjs {
  height: 100% !important;
}
::v-deep .el-row {
  height: 100% !important;
  .blocks:first-of-type {
    height: 100% !important;
  }
}
::v-deep .blocks .el-col .el-col-3 {
  height: 100% !important;
}

.preview {
  ::v-deep .gjs-off-prv {
    // background: #575560;
    display: none !important;
  }
  ::v-deep .el-row {
    border: none !important;
    height: 100% !important;
  }
}
</style>
