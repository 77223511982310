<template>
  <ElDialog
    class="dialog-detail"
    :title="cTitle"
    :visible.sync="cVisible"
    width="70%"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    :modal-append-to-body="false"
  >
    <div class="dialog-content">
      <slot></slot>
    </div>

    <span slot="footer" class="dialog-footer">
      <ElButton @click="onDialogCancel">取 消</ElButton>
      <ElButton type="primary" @click="onDialogSubmit">确 定</ElButton>
    </span>
  </ElDialog>
</template>
<script lang="ts">
import Vue, { PropType } from "vue";
import { Button as ElButton, Dialog as ElDialog } from "element-ui";

import { DataOverviewItem } from "@/flexible-table-module/entity/DataOverview/DataOverviewItem";

export default Vue.extend({
  inheritAttrs: false,
  name: "DialogDetail",
  components: { ElButton, ElDialog },
  props: {
    visible: { type: Boolean as PropType<boolean>, default: false },
    dataOverviewItem: { type: Object as PropType<DataOverviewItem> },
    suffix: { type: String, default: "" },
  },
  computed: {
    cVisible: {
      get(): boolean {
        return this.visible;
      },
      set(val: boolean) {
        this.$emit("update:visible", val);
      },
    },
    cTitle(): string {
      if (this.dataOverviewItem)
        return this.dataOverviewItem.name + this.suffix;
      else return this.suffix;
    },
  },
  methods: {
    onDialogCancel() {
      this.cVisible = false;
    },
    onDialogSubmit() {
      this.cVisible = false;
    },
  },
});
</script>
<style lang="scss" scoped>
.dialog-detail {
}
</style>