<template>
  <ElDropdownItem
    class="operation-dropdown-item"
    :command="name"
    @click.native="onBtnClick"
  >
    <template v-if="hasIcon">
      <span class="icon" :style="{ marginRight: `${this.iconMarginRight}px` }">
        <slot name="icon"></slot>
      </span>
    </template>
    <span class="title">{{ title }}</span>
  </ElDropdownItem>
</template>
<script>
import { DropdownItem as ElDropdownItem } from "element-ui";

export default {
  name: "OperationDropdownItem",
  components: { ElDropdownItem },
  props: {
    name: { type: String, required: true },
    title: { type: String },
    row: { type: Object }, // 所在行数据
    hasIcon: { type: Boolean, default: false },
    // fontSize: { type: Number, default: 12 },
  },
  data: () => ({
    iconMarginRight: 2,
  }),
  methods: {
    onBtnClick() {
      this.$emit("item-click", this.row, this.name);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "src/flexible-table-module/scss/_variables.scss";

.operation-dropdown-item {
  font-size: $fs-operation-btn--title;
  color: $clr-operation-btn--title;
  line-height: $fs-operation-btn--title;

  height: 32px;

  display: flex;
  justify-content: flex-start;
  align-items: center;

  .icon,
  .title {
    display: inline-block;
    line-height: 1;
  }
}
</style>