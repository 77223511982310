<template>
  <div class="app-upload-btn">
    <!-- 上传 -->
    <div style="display: inline-block">
      <el-button size="mini" class="active-btn" type="text" @click="handleClick">{{
        text
        }}</el-button>
    </div>
    <PlugInDialog v-if="plugvisible" :visible.sync="plugvisible" />
    <el-dialog append-to-body class="ss-dialog no-header" top="188px" width="540px" :modal="false"
      :visible.sync="showloadlist" v-if="showloadlist && nowtype == text" @close="showloadlist=false">
      <div class="list-content">
        <UploadList :showtitle="false" />
      </div>
      <span slot="footer">
        <div class="custom-footer">
          <el-button class="custom-footer-btn" type="primary" @click="showloadlist=false">关&nbsp;闭</el-button>
        </div>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import PlugInDialog from "../dialog/PlugIn.vue";
import UploadList from "../LocalFile/UploadList.vue";
import { mapGetters } from "vuex";

export default {
  components: {
    PlugInDialog,
    UploadList,
  },
  props: {
    // 上传的目标文件夹，不传，则上传到左侧目录树的选中文件夹下面
    folderid: {
      type: String,
    },
    // 按钮的文本
    text: {
      type: String,
      default: "上传文件",
    },
    subject: Object,
    folder: Object, //更新的文件
    columnId: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      fileList: [],
      fileSliceLoading: false,
      uploadFile: {}, //上传的单个文件
      uploadFileList: [], //上传的文件列表
      controller: null,
      progressVisible: false,
      updateprogressVisible: false,
      uploadPercent: 0,
      plugvisible: false, //下载插件弹窗
      tmpuploadfolderid: [], //因为文件插件上传文件夹每次都会返回来2次消息，所以这里做一个判断，只接收第一次的文件夹上传
      showloadlist: false,
      visible:true,
      nowtype:"",
    };
  },
  computed: {
    ...mapGetters(["token", "userInfo", "isconnected"]),
    listVisible() {
      return this.showloadlist
    }
  },
  watch: {},
  mounted() {
    this.init();
  },
  beforeDestroy() {
    this.$messageService.$off("websocketMessage");
  },
  methods: {
    handleClick() {
      if(!this.folderid){
        return;
      }
      if (!this.isconnected) {
        this.plugvisible = true;
        return;
      }
      this.nowtype = this.text 
      if (this.text == "上传文件") {
        this.handleUploadFile();
      } else {
        this.handleUploadFloder();
      }
    },
    handleUploadFile() {
      if (this.$websocket.readyState === WebSocket.OPEN) {
        const filereceiveId = (
          new Date().getTime().toString(16) + Math.random().toString(16).substring(2)
        ).substring(2, 18);
        // 上传文件
        const data = {
          msgId: `sale4-${filereceiveId}`,
          funcname: "uploadfile",
          uploadType: "1",
          systemType: "1",
          token: this.token ? this.token : "",
          tenantId: this.userInfo ? this.userInfo.tenantIdNow : "",
          baseurl: process.env.VUE_APP_URL_NETDISK,
          folderId: this.folderid,
          file: "",
          sysToken: "JqjdLEtaFOas5whS8hro2w==",
          fileSysBaseUrl: process.env.VUE_APP_URL_NETDISK,
        };
        this.$websocket.send(JSON.stringify(data));
      }
    },
    handleUploadFloder() {
      if (this.$websocket.readyState === WebSocket.OPEN) {
        const filereceiveId = (
          new Date().getTime().toString(16) + Math.random().toString(16).substring(2)
        ).substring(2, 18);
        const data = {
          msgId: `sale4-${filereceiveId}`,
          funcname: "uploadfolder",
          systemType: "1",
          token: this.token ? this.token : "",
          tenantId: this.userInfo ? this.userInfo.tenantIdNow : "",
          baseurl: process.env.VUE_APP_URL_NETDISK,
          parentId: this.folderid,
          dictItemIds: "",
          folderPath: "",
          sysToken: "JqjdLEtaFOas5whS8hro2w==",
          fileSysBaseUrl: process.env.VUE_APP_URL_NETDISK,
          folderAttributes: "",
        };
        this.$websocket.send(JSON.stringify(data));
      }
    },
    /**
     * @description: 初始化消息
     * @return {void}
     */
    init() {
      this.$messageService.$on("websocketMessage", (data) => {
        // 处理接收到的消息
        const parsedMessage = JSON.parse(data);
        console.log(parsedMessage,999);
        if(parsedMessage.funcname =="receivedMsg"&&this.isJSON(parsedMessage.data)){
          const data = JSON.parse(parsedMessage.data);
          data.msgId.startsWith("sale4-")&&this.$store.commit("saveUploadList", data);
        }
        if (parsedMessage.funcname == "uploadfolder") {
          if (parsedMessage.data.files && parsedMessage.data.files.length) {
            if (
              this.tmpuploadfolderid.find((item) => item == parsedMessage.msgId) ||
              !parsedMessage.msgId.startsWith("sale4-")
            ) {
              return;
            }
            const filereceiveId = (
              new Date().getTime().toString(16) + Math.random().toString(16).substring(2)
            ).substring(2, 18);
            this.tmpuploadfolderid.push(parsedMessage.msgId);
            parsedMessage.data.files.forEach((element) => {
              const data = {
                msgId: `sale4-${filereceiveId}`,
                funcname: "uploadfile",
                uploadType: "1", //上传消息类型，1:开始，2:暂停,3:取消
                systemType: "1",
                token: this.token ? this.token : "",
                tenantId: this.userInfo ? this.userInfo.tenantIdNow : "",
                baseurl: process.env.VUE_APP_URL_NETDISK,
                fileSysBaseUrl: process.env.VUE_APP_URL_NETDISK,
                folderId: element.parentId,
                file: element.localPath,
                sysToken: "JqjdLEtaFOas5whS8hro2w==",
              };
              this.$websocket.send(JSON.stringify(data));
            });
          }
        }
        if (
          parsedMessage.funcname == "uploadingFile" &&
          parsedMessage.msgId &&
          parsedMessage.msgId.startsWith("sale4-")
        ) {
          this.showloadlist = true;
        }
      });
    },
    /**
     * @description: 检测是否为json数据
     * @return {void}
     */
    isJSON(str) {
      try {
        JSON.parse(str);
        return true;
      } catch (error) {
        return false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.active-btn {
  color: #525252;

  &:hover {
    color: var(--color-primary);
  }
}

::v-deep .el-button--text {
  color: #525252;
}

.list-content {
  padding: 20px;
}
</style>
