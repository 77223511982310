<template>
  <el-dialog class="ss-dialog" width="1200px" :visible.sync="dialogVisible" top="5vh">
    <div slot="title">
      <div class="flex-between">
        <div class="dialog-title">全局搜索</div>
      </div>
    </div>
    <div class="dialog-body search-body" v-loading="loading">
      <div class="center-tabs">
        <el-autocomplete
          class="inline-input mr-10"
          size="small"
          style="width: 396px"
          v-model="queryData.value"
          :fetch-suggestions="querySearch"
          placeholder="请输入内容"
          :trigger-on-focus="false"
          @select="handleSelect"
          @keyup.enter.native="handleSelect"
          clearable
        ></el-autocomplete>
        <el-button size="mini" type="primary" @click="handleSelect">搜索</el-button>
      </div>
      <el-tabs class="center-tabs" v-model="activeName" @tab-click="handleClick">
        <el-tab-pane :label="`全部(${totalNumber})`" name="first"></el-tab-pane>
        <el-tab-pane
          v-for="i in menuList"
          :key="i.id"
          :label="i.moduleName + `(${i.total})`"
          :name="i.id"
        ></el-tab-pane>
        <el-tab-pane :label="`文章(${articleData.length})`" name="article"></el-tab-pane>
        <el-tab-pane :label="`文件夹(${floderData.length})`" name="floders"></el-tab-pane>
        <el-tab-pane :label="`文件(${fileData.length})`" name="files"></el-tab-pane>
      </el-tabs>
      <div class="search-detai">
        <QuoteProduct
          v-show="activeName == 'first' || activeName == item.id"
          v-for="item in sumData"
          :key="item.id + datakey"
          :searchdata="item"
        />
        <QuoteArticle
          v-if="(activeName == 'article' || activeName == 'first') && articleData.length"
          :key="'article' + datakey"
          :searchdata="articleData"
        />
        <QuoteFile
          v-if="(activeName == 'floders' || activeName == 'first') && floderData.length"
          :filelist="floderData"
          :key="'floder' + datakey"
          :title="'文件夹'"
        />
        <QuoteFile
          v-if="(activeName == 'files' || activeName == 'first') && fileData.length"
          :filelist="fileData"
          :key="'file' + datakey"
          :title="'文件'"
        />
      </div>
    </div>
  </el-dialog>
</template>

<script>
import { mapGetters } from "vuex";
import { Module } from "@/api/Module";
import { Esengine } from "@/api/Esengine";
import QuoteProduct from "./components/QuoteProduct.vue";
import QuoteFile from "./components/QuoteFile.vue";
import QuoteArticle from "./components/QuoteArticle.vue";
export default {
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    searchvalue: String,
  },
  components: { QuoteProduct, QuoteFile, QuoteArticle },
  data() {
    return {
      queryData: {
        isFile: 0, //查询类型(0:非文件,1:文件)
        value: "",
        moduleId: "",
        pageNo: 1,
        pageSize: 999,
        sortType: "desc",
      },
      activeName: "first",
      menuList: [],
      value: "",
      sumData: [], //搜索到的所有模块数据
      datakey: 1,
      fileData: [], //搜索到的所有文件数据
      floderData: [], //搜索到的所有文件夹数据
      articleData: [], //搜索到的所有文章数据
      totalNumber: 0,
      loading: false,
    };
  },
  computed: {
    dialogVisible: {
      get() {
        return this.visible;
      },
      set(val) {
        this.$emit("update:visible", val);
      },
    },
    ...mapGetters(["Moduleclassification"]),
  },
  mounted() {
    this.getModuleList();
    if (this.searchvalue) {
      this.queryData.value = _.cloneDeep(this.searchvalue);
      this.handleSelect();
    }
  },
  methods: {
    /**
     * @description: 选择模块
     * @return {void}
     */
    handleClick(tab, event) {},
    /**
     * @description: 获取模块列表
     * @return {void}
     */
    async getModuleList() {
      const res = await Module.getModuleList();
      let list = [];
      res.forEach((element) => {
        element.moduleEntityList.forEach((item) => {
          item.icon = item.moduleIcon ? item.moduleIcon : "product";
          item.iconType =
            item.moduleIcon && item.moduleIcon.startsWith("http") ? "online" : "local";
          item.total = 0;
          list.push(item);
        });
      });
      // 这里过滤掉文章的
      if (list.length) {
        const accept = [1, 2]; //需要显示的模块类型"1"内容类 "2"演示方案类 "3"文件资源类 "4"教学教程类 "5"文章类
        this.menuList = [];
        let selectType = this.Moduleclassification.filter((item) =>
          accept.includes(item.type)
        );
        selectType = selectType.map((item) => item.id);
        this.menuList.forEach((item) => {
          const type = this.Moduleclassification.filter(
            (menu) => menu.id == item.categoryId
          );
          type.length && (item.categoryType = type[0].type);
        });
        this.menuList = list.filter((item) => selectType.includes(item.categoryId));
      }
    },
    /**
     * @description: 获取搜索建议
     * @return {void}
     */
    async querySearch(queryString, cb) {
      const res = await Esengine.suggestionQuery(queryString);
      const results = res.map((item) => ({ value: item }));
      cb(results);
    },
    /**
     * @description: 选择搜索建议
     * @return {void}
     */
    async handleSelect() {
      if (!this.queryData.value.trim()) {
        return this.$message.warning("搜索名称不能为空！");
      }
      this.loading = true;
      const res = await Esengine.boolQueryHighlight(this.queryData);
      this.datakey++;
      this.sumData = this.matchDataWithKey(this.menuList, res);
      this.menuList = this.mergeArrays(this.sumData, this.menuList);
      let files = res["fileMap"].data.map((obj) => ({ ...obj, fileType: 1 }));
      let folders = res["folderMap"].data.map((obj) => ({ ...obj, fileType: 2 }));
      this.articleData = res.articleMap.data;
      this.floderData = folders;
      this.fileData = files;
      const menunumber = this.menuList.reduce((acc, curr) => acc + curr.total, 0);
      this.totalNumber =
        folders.length + files.length + menunumber + res.articleMap.total;
      this.loading = false;
    },
    /**
     * @description: 菜单添加搜索数量
     * @param {Array} arr1 搜索结果列表
     * @param {Array} arr2 菜单列表
     * @return {void}
     */
    mergeArrays(arr1, arr2) {
      return arr2.map((item2) => {
        const matchingItem = arr1.find((item1) => item1.id === item2.id);
        if (matchingItem) {
          item2.total = matchingItem.searchValue.total;
        }
        return item2;
      });
    },
    /**
     * @description: 将获取的搜索结果和模块进行匹配
     * @param {Array} dataArray 模块列表
     * @param {Array} keyObject 搜索结果列表
     * @return {void}
     */
    matchDataWithKey(dataArray, keyObject) {
      for (let i = 0; i < dataArray.length; i++) {
        const obj = dataArray[i];
        if (obj.id in keyObject) {
          obj.searchValue = keyObject[obj.id];
        }
      }
      return dataArray;
    },
  },
};
</script>

<style lang="scss" scoped>
.search-body {
  min-height: 200px;
  max-height: 75vh;
  overflow-y: hidden;
  padding: 20px;
}

.dialog-title {
  font-size: 18px;
  color: var(--color-text-primary);
}

.search-detai {
  min-height: 200px;
  max-height: calc(75vh - 120px);
  overflow-y: auto;
}

.mr-10 {
  margin-right: 10px;
}

::v-deep .el-divider--horizontal {
  margin: 10px 0;
}

.center-tabs {
  display: flex;
  justify-content: center;
}
</style>
