<template>
  <div
    class="operation-dropdown operation-btn-outside"
    :style="{ fontSize: `${this.fontSize}px` }"
  >
    <ElDropdown trigger="click" placement="bottom" @command="onCommand">
      <div class="operation-btn" :style="{ padding: `0 ${this.btnPadding}px` }">
        <span class="el-dropdown-link">
          <template v-if="hasTitle">
            <span
              class="title"
              :style="{
                marginRight: `${this.titleMarginRight}px`,
                fontSize: `${fontSize}px`,
              }"
              >{{ title }}</span
            >
          </template>

          <span
            class="icon"
            :style="{
              fontSize: `${fontSize}px`,
            }"
          >
            <!-- <IconMore :size="14" /> -->
            <svg-icon
              sys="common-table"
              name="OperationButtons/more"
              :size="16"
            />
          </span>
        </span>
      </div>

      <template v-slot:dropdown>
        <ElDropdownMenu>
          <slot></slot>
        </ElDropdownMenu>
      </template>
    </ElDropdown>
  </div>
</template>
<script>
import {
  Dropdown as ElDropdown,
  DropdownMenu as ElDropdownMenu,
} from "element-ui";
import SvgIcon from "@/flexible-table-module/svg-icon-importer";

// import IconMore from "@/flexible-table-module/icons/OperationButtons/IconMore.vue";

export default {
  name: "OperationDropdown",
  components: {
    ElDropdown,
    ElDropdownMenu,
    SvgIcon,
    // IconMore,
  },
  props: {
    title: { type: String },
    fontSize: { type: Number, default: 12 },
  },
  computed: {
    hasTitle() {
      return !!this.title && this.title.length > 0;
    },
  },
  data() {
    return {
      titleMarginRight: 2,
      btnPadding: 4,
    };
  },
  methods: {
    /**
     * @description: 下拉菜单命令
     * @param {String} command 命令
     */
    onCommand(command) {},
  },
};
</script>
<style lang="scss" scoped>
@import "src/flexible-table-module/scss/_variables.scss";
@import "src/flexible-table-module/scss/common.scss";

.operation-dropdown {
  user-select: none;

  &.operation-btn-outside {
    @include operation-btn-outside;

    .operation-btn {
      @include operation-btn;

      .el-dropdown-link {
        @include operation-btn-inside;

        // 编译打包时，报了这个错误：
        // Deprecation Warning: Sass's behavior for declarations that appear after nested rules
        // will be changing to match the behavior specified by CSS in an upcoming version.
        // To keep the existing behavior, move the declaration above the nested rule.
        // To opt into the new behavior, wrap the declaration in & {}.
        & {
          // 使用 & {} 明确选择新行为
          justify-content: center;
        }

        .title {
          margin-right: 2px;
        }
      }
    }
  }
}
</style>