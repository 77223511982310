<template>
  <div class="app-header">
    <div class="app-header-content">
      <div v-if="path === '/share'" class="product-tabs">
        <div class="product-tab" :class="{ 'is-active': !$store.state['share'].isShare }"
          @click="changeSharepage('-2')">
          <span>我分享的</span>
          <img v-if="hasNewSubject" class="product-tab-new" src="@/assets/images/productLibrary/new_tab.png" />
        </div>
        <div class="product-tab" :class="{ 'is-active': $store.state['share'].isShare }" @click="changeSharepage('-1')">
          <span>分享给我的</span>
        </div>
      </div>
      <div class="product-tabs2" v-if="path === '/hometable'">
        <SelfadaptionTabs />
      </div>
      <!-- <SelfadaptionTabs v-if="path === '/hometable'"/> -->
    </div>
    <div class="table-search">
      <el-autocomplete class="inline-input mr-10" size="small" style="width: 396px" v-model="searchvalue"
        :fetch-suggestions="querySearch" placeholder="请输入内容" :trigger-on-focus="false" @select="searchallproduct"
        @keyup.enter.native="searchallproduct" clearable></el-autocomplete>
      <el-button size="small" type="primary" @click="searchallproduct">搜索</el-button>
    </div>
    <div class="flex">
      <ProductStatusButton />
      <!-- <div v-if="path != '/hometable'" class="app-header-search" @click="searchallproduct">
        <el-tooltip class="item" effect="dark" content="全局搜索" placement="top">
          <svg-icon icon="homepage-search" width="20" height="20" color="#828a99"></svg-icon>
        </el-tooltip>
      </div> -->
      <div class="app-header-chat" @click="showFileLog">
        <svg-icon icon="file-cloud" width="20" height="20" color="#828a99"></svg-icon>
      </div>
      <div class="app-header-chat" @click="handlePlug">
        <el-tooltip class="item" effect="dark" content="下载插件" placement="top">
          <svg-icon icon="plug-in" width="20" height="20" color="#828a99"></svg-icon>
        </el-tooltip>
      </div>
      <div style="margin-right: 8px">
        <el-avatar v-if="!circleUrl" :size="34" :src="require('@/assets/images/logo/user.png')"></el-avatar>
        <el-avatar v-if="circleUrl" :size="34" :src="circleUrl"></el-avatar>
      </div>
      <el-dropdown placement="bottom" @command="handleCommand">
        <div class="app-header-user">
          <div class="flex tit-a">
            <div class="flex">
              <div v-if="!username">{{ userInfo.realname || userInfo.username }}</div>
              <div v-else>{{ username }}</div>
            </div>
            <div class="flex align-items-center">
              <span>{{ userInfo.tenantNameNow }}</span>
              <img class="app-header-user-arrow" src="@/assets/images/common/admin_triangle_down.png" />
            </div>
          </div>
        </div>
        <el-dropdown-menu class="cmn-dropdown menu" slot="dropdown">
          <el-dropdown-item command="basicSetting"><svg-icon icon="icon-7" width="16" height="16"
              color="#344563"></svg-icon>个人设置</el-dropdown-item>
          <el-dropdown-item v-if="poweradmin" command="backgroundManagement"><svg-icon icon="icon-1" width="16"
              height="16" color="#344563"></svg-icon>后台管理</el-dropdown-item>
          <el-dropdown-item command="createCompany"><svg-icon icon="icon-2" width="16" height="16"
              color="#344563"></svg-icon>创建企业</el-dropdown-item>
          <el-dropdown-item command="joinEnterprise"><svg-icon icon="icon-3" width="16" height="16"
              color="#344563"></svg-icon>加入企业</el-dropdown-item>
          <el-dropdown-item command="inviteMembers"><svg-icon icon="icon-4" width="16" height="16"
              color="#344563"></svg-icon>邀请成员</el-dropdown-item>
          <el-dropdown-item command="switchEnterprise"><svg-icon icon="exchange" width="14" height="14"
              color="#344563"></svg-icon>切换企业</el-dropdown-item>
          <div class="line"></div>
          <el-dropdown-item command="password"><svg-icon icon="icon-5" width="16" height="16"
              color="#344563"></svg-icon>修改密码</el-dropdown-item>
          <el-dropdown-item command="logout"><svg-icon icon="icon-6" width="16" height="16"
              color="#344563"></svg-icon>退出登录</el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </div>
    <GlobalSearching :visible.sync="searchdialog" v-if="searchdialog" :searchvalue="searchvalue" />
    <!-- <SearchDialog :visible.sync="searchdialog" v-if="searchdialog" /> -->
    <InviteMemberDialog v-if="inviteDialogVisible" :visible.sync="inviteDialogVisible" />
    <ChangePassword v-if="passwordDialog" :visible.sync="passwordDialog" />
    <TransferRecord v-if="filedrawerVisible" :visible.sync="filedrawerVisible" />
    <!-- <UpdateStatus v-if="statusvisible" :visible.sync="statusvisible" :buttonlist="buttonList" @finish="getUserbuttonList" /> -->
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { ProductLibrary } from "@/api/ProductLibrary";
import SaaS from "@/api/SaaS";
import { Users } from "@/api/Users";
// import SearchDialog from "./dialog/SearchDialog.vue";
import InviteMemberDialog from "@/components/InviteMember.vue";
import ChangePassword from "@/components/dialog/ChangePassword.vue";
import GlobalSearching from "@/components/GlobalSearching/index.vue";
import ProductStatusButton from "@/components/Product/ProductStatusButton.vue";
import TransferRecord from "./drawer/TransferRecord.vue";
import UpdateStatus from "./dialog/UpdateStatus.vue";
import SelfadaptionTabs from "./components/SelfadaptionTabs.vue";
import { Esengine } from "@/api/Esengine";
export default {
  name: "AppMenu",
  components: { InviteMemberDialog, ChangePassword, TransferRecord, GlobalSearching, UpdateStatus, ProductStatusButton, SelfadaptionTabs },
  data() {
    return {
      date: "",
      path: "",
      hasNewSubject: false,
      hasNewMessage: false,
      searchdialog: false,
      inviteDialogVisible: false, // 邀请成员弹窗
      creatDialogVisible: false, // 创建企业弹窗
      circleUrl: null,
      username: null,
      passwordDialog: false,
      filedrawerVisible: false,
      buttonList: [],
      selectbuttonId: "",//当前选择按钮id
      selectbutton: "",//当前选择按钮
      statusvisible: false,
      showbuttonList: false,
      searchvalue: "",
    };
  },
  watch: {
    $route(to) {
      this.path = to.path;
    },
  },
  computed: {
    ...mapGetters(["userInfo", "poweradmin", "tableTab", "islocked"]),
  },
  mounted() {
    this.date = Object.dayjs(new Date(), "YYYY年MM月DD日");
    this.getUserInfo();
    this.path = this.$route.path
  },
  methods: {
    /**
     * @description: 锁定页面布局，暂时不需要了，原型里面没有这个东西
     * @return {void}
     */
    handleLock() {
      const status = !this.islocked
      this.$store.commit('changeislockedStatus', status)
    },
    /**
     * @description: 切换分享页面
     * @return {void}
     */
    changeSharepage(id) {
      const status = id == "-1" ? true : false;
      this.$store.commit("share/changeShare", status);
    },
    handleCommand(command) {
      if (command === "logout") {
        this.logout();
      } else if (command === "switchEnterprise") {
        this.$router.push({ path: "/switch-enterprise" });
      } else if (command === "joinEnterprise") {
        this.$router.push({ path: "/join-enterprise" });
      } else if (command === "inviteMembers") {
        this.inviteDialogVisible = true;
      } else if (command === "backgroundManagement") {
        this.$router.push({ path: "/backstage-managed" });
      } else if (command === "createCompany") {
        this.$router.push({ path: "/create-company" });
      } else if (command === "password") {
        this.passwordDialog = true;
      } else if (command === "basicSetting") {
        this.$router.push({ path: "/backstage-setting" });
      }
    },
    /**
     * @description: 退出登录
     * @return {void}
     */
    async logout() {
      const res = await SaaS.logout();
      if (res) {
        this.$store.commit("saveToken");
        this.$store.commit("saveUserInfo");
        this.$router.push("/login");
      } else {
        this.$message.error("退出登录失败！");
      }
    },
    searchallproduct() {
      this.searchdialog = true;
      this.$nextTick(() => {
        this.searchvalue = "";
      })
    },
    /**
     * @description: 显示聊天弹窗
     * @return {void}
     */
    showChatPanel() {
      this.$Pubsub.publish("on-chat-window", {
        visible: undefined,
        hasMenu: true,
        topicId: "",
      });
    },
    showFileLog() {
      this.filedrawerVisible = !this.filedrawerVisible;
    },
    /**
     * @description: 获取用户信息
     * @return {void}
     */
    async getUserInfo() {
      const res = await Users.queryUserInfoById({
        tenantId: this.userInfo.tenantIdNow,
        userId: this.userInfo.id,
      });
      res && (this.username = res.realname);
      if (res && res.avatar) {
        this.circleUrl = res.avatar;
      } else {
        this.circleUrl = "";
      }
      this.$store.commit("saveAvatarUrl", this.circleUrl);
    },
    /**
     * @description: 下载文件插件
     * @return {void}
     */
    handlePlug() {
      const fileUrl = process.env.BASE_URL + "I3VMews.exe";
      const link = document.createElement("a");
      link.href = fileUrl;
      link.setAttribute("download", "I3VMews.exe"); // 设置下载文件的名称
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },
    /**
     * @description: 获取搜索建议
     * @return {void}
     */
    async querySearch(queryString, cb) {
      const res = await Esengine.suggestionQuery(queryString);
      const results = res.map((item) => ({ value: item }));
      cb(results);
    },
  },
};
</script>

<style lang="scss">
.header-product-popover {
  @include popover(54px);

  .header-product-popover-item {
    @include popover-item;
    font-size: 14px;
    cursor: pointer;
  }
}
</style>

<style lang="scss" scoped>
.app-header {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .app-header-content {
    flex: 1;
    overflow: hidden;
  }

  .app-header-datetime {
    font-size: 14px;
    font-weight: 500;
    color: var(--color-text-primary);
    padding: 0 36px;
    height: 28px;
    line-height: 28px;
    // border-right: 1px solid #e1e8f2;
  }

  .app-header-search {
    width: 36px;
    height: 36px;
    margin: 0 32px;
    border-radius: 5px;
    padding-top: 8px;
    padding-left: 8px;
    box-sizing: border-box;
    cursor: pointer;
  }

  .app-header-chat {
    width: 36px;
    height: 36px;
    margin-right: 28px;
    border-radius: 5px;
    padding-top: 8px;
    padding-left: 8px;
    box-sizing: border-box;
    cursor: pointer;
  }

  .app-header-message {
    position: relative;
    width: 36px;
    height: 36px;
    margin-right: 28px;
    border-radius: 5px;
    background-image: url("@/assets/images/common/bell.png");
    background-position: center;
    background-size: 22px 22px;
    background-repeat: no-repeat;
    cursor: pointer;

    &:hover {
      background-color: var(--color-primary);
      background-image: url("@/assets/images/common/bell_hover.png");
    }

    .red-dot {
      position: absolute;
      top: 0;
      right: 2px;
      width: 13px;
      height: 13px;
      background-image: url("@/assets/images/common/bell_red_dot.png");
      background-size: 13px 13px;
      background-repeat: no-repeat;
    }
  }

  .app-header-user {
    font-size: 12px;
    font-weight: 400;
    color: var(--color-text-regular);
    cursor: pointer;

    .app-header-user-arrow {
      margin-left: 4px;
      width: 5px;
      height: 3px;
    }
  }
}

.product-tabs {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;

  .product-tab {
    position: relative;
    height: 64px;
    line-height: 64px;
    font-size: 14px;
    font-weight: 400;
    padding: 0 10px;
    margin-right: 12px;
    color: #8997b0;
    cursor: pointer;
    border-bottom: 2px solid #fff;

    &:hover {
      color: #344563;

      .product-tab-more {
        display: block;
      }
    }

    &.is-active {
      font-weight: 700;
      color: var(--color-text-primary);
      border-bottom-color: var(--color-primary);
    }

    .product-tab-new {
      position: absolute;
      z-index: 1;
      width: 21px;
      height: 10px;
      top: 15px;
      right: -2px;
    }

    .product-tab-more {
      display: none;
      position: absolute;
      z-index: 2;
      width: 12px;
      height: 12px;
      top: 26px;
      right: -8px;
    }

    .product-tab-input {
      padding: 0 4px;
      margin: 0;
      border: none;
      outline: none;
      width: 70px;
      height: 18px;
      font-size: 14px;
      font-weight: 400;
      color: #fff;
      background-color: var(--color-primary);
    }
  }

  .hometable-tab {
    height: 64px;
    line-height: 64px;
    font-size: 14px;
    font-weight: 400;
    padding: 0 10px;
    margin-right: 12px;

    &.is-active {
      color: var(--color-primary)
    }
  }
}

.product-tabs2 {
  width: 100%;
  overflow: hidden;
}

.menu {
  ::v-deep .el-dropdown-menu__item {
    display: flex;
    align-items: center;

    &:hover {
      .svg-icon {
        color: #fff !important;
      }
    }
  }

  .svg-icon {
    margin-right: 9px;
  }
}

.tit-a {
  height: 40px;
  flex-direction: column;
  line-height: 20px;
}

.line {
  border-top: 1px solid #d3dae6;
  height: 1px;
  width: 156px;
  // padding: 10px 0;
  color: #d3dae6;
  margin: 0 8px;
}

::v-deep .el-radio-button--small .el-radio-button__inner {
  color: #A7B4CC;
}

.user-button {
  position: relative;

  .retract-icon {
    position: absolute;
    left: -36px;
    width: 22px;
    height: 22px;
    line-height: 22px;
    border-radius: 50%;
    color: #BFCADE;
    background-color: #fff;
    text-align: right;
  }
}

.user-button-set {
  .set-icon {
    display: none;
  }

  &:hover {
    .set-icon {
      display: block;
    }
  }

  ::v-deep .el-radio-button__orig-radio:checked+.el-radio-button__inner {
    border: 1px solid #CFD7E6 !important;
    background-color: #F7F9FC;
  }
}

.table-search {
  flex: 1;
  display: flex;
  align-items: center;

  .el-button {
    margin-left: 10px;
  }
}
</style>
