<template>
  <Dropdown
    ref="dropdown"
    class="body-selectable-cell"
    @before-dropdown="onBeforeDropdown"
  >
    <template v-slot:title>
      <div class="dropdown-title" v-loading="selectConfirming">
        {{ cellValueAfterFormat
        }}<i
          class="el-icon-arrow-down el-icon--right"
          :style="arrowIconStyle"
        ></i>
      </div>
    </template>
    <template v-slot:content>
      <template v-for="item in items">
        <DropdownItem
          :key="item.key"
          :value="item.value"
          @item-click="onItemClick(item)"
        />
      </template>
    </template>
  </Dropdown>
</template>
<script lang="ts">
import Vue, { type DirectiveOptions, PropType } from "vue";

import { Loading } from "@/flexible-table-module/components/Common/Loading";

import Dropdown from "@/flexible-table-module/components/Common/Dropdown/Dropdown.vue";
import DropdownItem from "@/flexible-table-module/components/Common/Dropdown/DropdownItem.vue";
import type { KeyValuePair } from "@/flexible-table-module/entity/KeyValuePair";
import { Column } from "@/flexible-table-module/entity/Table/Column";
import { IBaseTableData } from "@/flexible-table-module/entity/Table/IBaseTableData";

export default Vue.extend({
  inheritAttrs: false,
  name: "BodySelectableCell",
  components: { Dropdown, DropdownItem },
  directives: { loading: Loading.directive },
  props: {
    // scope: { type: Object },
    column: { type: Object as PropType<Column> },
    row: { type: Object as PropType<IBaseTableData> },
    // cellValueBeforeFormat: {},
    cellValueAfterFormat: { type: String },
    isFreezed: { type: Boolean, default: false }, // 是否被冻结
    // 获取下拉列表数据的回调
    getDropdownData: {
      type: Function as PropType<
        (
          row: IBaseTableData,
          column: Column,
          resolve: (data: KeyValuePair<string>[]) => void
        ) => void
      >,
    },
    // 下拉列表的某一项被选中时的回调
    confirmSelection: {
      type: Function as PropType<
        (
          row: IBaseTableData,
          column: Column,
          item: KeyValuePair<string>,
          resolve: (isConfirm: boolean) => void
        ) => void
      >,
    },
  },
  data() {
    return {
      items: [] as KeyValuePair<string>[],
      selectConfirming: false as boolean, // 用户选择了下拉框里的一项，确认中，有加载动画
      isArrowVisible: false as boolean,
      elTableCellElement: null as HTMLElement | null, // el-table__cell 元素，用于监听鼠标移入移出事件
    };
  },
  computed: {
    arrowIconStyle() {
      if (this.isArrowVisible) {
        return {};
      } else {
        return { visibility: "hidden" };
      }
    },
  },
  watch: {
    isFreezed: {
      immediate: false,
      handler(isFreezed: boolean) {
        console.log("isFreezed :>> ", isFreezed);
        // 因为 el-table__cell 元素 DOM 结构可能会变化，所以每次冻结状态变化时都重新查找 el-table__cell 元素
        this.rmMouseMovementListener();
        this.addMouseMovementListener();
      },
    },
  },
  mounted() {
    this.addMouseMovementListener();
  },
  beforeDestroy() {
    this.rmMouseMovementListener();
  },
  methods: {
    onBeforeDropdown(confirm: (isConfirm: boolean) => void) {
      if (typeof this.getDropdownData === "function") {
        const resolve = (data: KeyValuePair<string>[]) => {
          // setTimeout(() => {
          this.items = data;
          confirm(true);
          // this.loadingItems = false;
          // }, 2000);
        };
        this.getDropdownData(this.row, this.column, resolve);
      } else {
        confirm(true);
      }
    },

    onItemClick(item: KeyValuePair<string>) {
      (this.$refs["dropdown"] as any)?.hide();

      this.selectConfirming = true;

      const resolve = (isConfirm: boolean) => {
        if (isConfirm && this.column.id in this.row) {
          if (this.column.type === "key-str-value")
            this.row[this.column.id] = { key: item.key, value: item.value };
          else if (this.column.type === "string")
            this.row[this.column.id] = item.value;
          else throw new Error("column type is not supported");
        }

        this.selectConfirming = false;
      };

      if (typeof this.confirmSelection === "function") {
        // 异步确认选择
        this.confirmSelection(this.row, this.column, item, resolve);
      } else {
        // 同步确认选择
        resolve(true);
      }
    },

    // ----------------------------------------

    // 鼠标移入事件处理函数
    mouseEnterHandler() {
      this.isArrowVisible = true;
    },

    // 鼠标移出事件处理函数
    mouseLeaveHandler() {
      this.isArrowVisible = false;
    },

    // 为 el-table__cell 元素添加鼠标移入移出事件监听
    addMouseMovementListener() {
      this.elTableCellElement = this.findElTableCellElement();
      // 如果找到了 el-table__cell 元素，则监听鼠标移入移出事件
      if (this.elTableCellElement) {
        this.elTableCellElement.addEventListener(
          "mouseenter",
          this.mouseEnterHandler
        );
        this.elTableCellElement.addEventListener(
          "mouseleave",
          this.mouseLeaveHandler
        );
      }
    },

    // 为 el-table__cell 元素移除鼠标移入移出事件监听
    rmMouseMovementListener() {
      if (this.elTableCellElement) {
        this.elTableCellElement.removeEventListener(
          "mouseenter",
          this.mouseEnterHandler
        );
        this.elTableCellElement.removeEventListener(
          "mouseleave",
          this.mouseLeaveHandler
        );
      }
    },

    // 通过递归查找父元素，找到 el-table__cell 元素
    findElTableCellElement(): HTMLElement | null {
      let parentElement = this.$el.parentNode;
      while (parentElement instanceof HTMLElement) {
        if (
          parentElement.classList.contains("el-table__cell") &&
          !parentElement.classList.contains("is-hidden")
        ) {
          return parentElement;
        }
        parentElement = parentElement.parentNode;
      }
      return null;
    },
  },
});
</script>
<style lang="scss" scoped>
@import "src/flexible-table-module/scss/_variables.scss";

.body-selectable-cell {
  font-size: $fs-body-selectable-cell;

  .dropdown-title {
    user-select: none;
    cursor: pointer;
    width: 100%;
    font-size: inherit;

    .el-icon-arrow-down {
      font-size: inherit;
    }
  }
}
</style>