<template>
  <div class="dropdown">
    <ElPopover
      ref="popover"
      :popper-class="allDropdownPopperClass"
      placement="bottom"
      trigger="manual"
      v-model="visible"
      @show="onDropdown"
    >
      <template v-slot:reference>
        <div class="title" v-loading="loading" @click="onClick">
          <slot name="title"></slot>
        </div>
      </template>

      <!-- <div
        class="content"
        :style="{
          minHeight: loading ? '72px' : '24px',
        }"
      > -->
      <div class="content">
        <slot name="content"></slot>
      </div>
    </ElPopover>
  </div>
</template>
<script lang="ts">
import Vue, { type DirectiveOptions, PropType } from "vue";
import { Popover as ElPopover, Loading as ElLoading } from "element-ui";
import { Loading } from "@/flexible-table-module/components/Common/Loading";

// import { KeyValuePair } from "@/flexible-table-module/entity/KeyValuePair";

const DEFAULT_POPOVER_CLASS = "dropdown-popover";

export default Vue.extend({
  inheritAttrs: false,
  name: "Dropdown",
  components: { ElPopover },
  directives: { loading: Loading.directive },
  props: {
    dropdownPopperClass: {
      type: [String, Array] as PropType<string | string[]>,
      default: "",
    },
  },
  computed: {
    allDropdownPopperClass(): string {
      if (Array.isArray(this.dropdownPopperClass))
        return `${DEFAULT_POPOVER_CLASS} ${this.dropdownPopperClass.join(" ")}`;
      else return `${DEFAULT_POPOVER_CLASS} ${this.dropdownPopperClass}`;
    },
  },
  data(): {
    visible: boolean;
    loading: boolean;
    judgeOutsideClickBindThis: (event: MouseEvent) => void;
  } {
    return {
      visible: false,
      loading: false,
      judgeOutsideClickBindThis: () => {},
    };
  },
  mounted() {
    this.judgeOutsideClickBindThis = this.judgeOutsideClick.bind(this);
    window.document.body.addEventListener(
      "click",
      this.judgeOutsideClickBindThis
    );
  },
  beforeDestroy() {
    window.document.body.removeEventListener(
      "click",
      this.judgeOutsideClickBindThis
    );
  },
  methods: {
    hide() {
      this.visible = false;
    },
    show() {
      this.visible = true;
    },

    onDropdown() {
      // this.$emit("dropdown");
    },

    onClick() {
      if (!this.visible) {
        this.loading = true;

        const confirm = (isConfirm: boolean = true) => {
          if (isConfirm) {
            this.visible = true;
            this.loading = false;
          }
        };

        this.$emit("before-dropdown", confirm);
      } else {
        this.visible = false;
      }
    },

    // 判断当前鼠标是否在弹出框外部点击
    judgeOutsideClick(event: MouseEvent) {
      if (this.visible) {
        const popoverInstance = this.$refs.popover as any;

        if (
          popoverInstance &&
          popoverInstance.$el &&
          !popoverInstance.$el.contains(event.target) &&
          popoverInstance.referenceElm &&
          !popoverInstance.referenceElm.contains(event.target)
        ) {
          this.visible = false;
        }
      }
    },
  },
});
</script>

<style lang="scss" scoped>
.dropdown {
}

.content {
  min-width: 96px;
  max-height: 50vh;
  padding: 8px 0;
  overflow: auto;
}
</style>

<style lang="scss">
.el-popover {
  &.dropdown-popover {
    // box-sizing: content-box;
    padding: 0;

    .content {
    }
  }
}
</style>