<template>
  <el-dialog
    append-to-body
    class="ss-dialog"
    :title="updatetabs ? '编辑' : '添加'"
    :width="updatetabs ? '620px' : '820px'"
    :visible.sync="dialogVisible"
  >
    <div class="layout-content">
      <el-form
        :model="queryData"
        status-icon
        :rules="rules"
        ref="ruleForm"
        label-width="100px"
        class="demo-ruleForm"
      >
        <el-form-item label="名称" prop="homeName">
          <el-input class="name-input" v-model="queryData.homeName" />
        </el-form-item>
        <el-form-item label="布局" v-if="!updatetabs">
          <el-radio v-model="queryData.layoutType" :label="1">通用</el-radio>
          <el-radio v-model="queryData.layoutType" :label="2">自定义</el-radio>
        </el-form-item>
        <el-form-item label="" v-if="queryData.layoutType == 2">
          <div class="main-layout-block">
            <div
              v-for="item in itemList"
              :key="item.value"
              class="layout"
              :class="{
                active: item.value === queryData.defaultLayout,
                [`layout-${item.value}`]: true,
              }"
              @click="askSelect(item)"
            ></div>
          </div>
        </el-form-item>
        <el-form-item label="" v-if="queryData.layoutType == 1">
          <div class="main-layout-block"></div>
        </el-form-item>
      </el-form>
    </div>
    <div slot="footer">
      <el-button type="info" @click="dialogVisible = false">取 消</el-button>
      <el-button type="primary" :loading="confirmLoading" @click="handleConfirm"
        >确 定</el-button
      >
    </div>
  </el-dialog>
</template>

<script>
import { mapGetters } from "vuex";
import { Homecontents } from "../../../api/Homecontents";
import layouts from "../../../utils/layouts";

export default {
  components: {},
  props: {
    tabList: {
      type: Array,
      default: () => [],
    },
    visible: {
      type: Boolean,
      default: false,
    },
    updatetabs: Object,//要更新的页签
  },
  data() {
    return {
      itemList: [
        { value: 1, label: "自定义" },
        { value: 2, label: "左右二宫格" },
        { value: 3, label: "上下二宫格" },
        { value: 4, label: "四宫格" },
      ],
      editMode: false,
      queryData: {
        createType: 2, //创建类型1：默认 2：自定义,默认的在登录时由后端自动创建，这里只需要用到2
        defaultLayout: 1, //默认布局(1.空白，2.横向上下，3.四宫格，4.九宫格)
        homeName: "",
        layoutType: 1, //布局类型（1：通用 2：自定义）
      },
      rules: {
        homeName: [{ required: true, message: "请输入名称", trigger: "blur" }],
      },
      confirmLoading: false,
      defaultLayout: [],
    };
  },
  computed: {
    dialogVisible: {
      get() {
        return this.visible;
      },
      set(val) {
        this.$emit("update:visible", val);
      },
    },
    ...mapGetters(["activeMenu"]),
  },
  watch: {},
  created() {},
  mounted() {
    this.defaultLayout = [...layouts];
    if (this.updatetabs) {
      delete this.queryData.createType;
      delete this.queryData.defaultLayout;
      delete this.queryData.layoutType;
      this.queryData.homeName = this.updatetabs.homeName;
      this.queryData.id = this.updatetabs.id;
    }
  },
  beforeDestroy() {},
  methods: {
    handleConfirm() {
      this.$refs.ruleForm.validate(async (valid) => {
        if (valid) {
          let res;
          if (this.updatetabs) {
            res = await Homecontents.updateHomeContents(this.queryData);
          } else {
            res = await Homecontents.saveHomeContents(this.queryData);
            if (res && this.queryData.layoutType == 2) {
              if (this.queryData.defaultLayout != 1) {
                const temlayouts = this.defaultLayout[this.queryData.defaultLayout - 1];
                const addres = this.batchAddDisplayLayout(temlayouts, res);
              }
            }
          }
          if (res) {
            const hasnewTab = !this.updatetabs;
            this.$emit("finishcreat", hasnewTab, res);
            this.dialogVisible = false;
          }
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    askSelect(item) {
      this.queryData.defaultLayout = item.value;
    },
    /**
     * @description: 添加布局
     * @param {Array} temlayouts 要添加的那些布局数组集合
     * @return {void}
     */
    async batchAddDisplayLayout(temlayouts, id) {
      let payload = {
        integratedId: id,
        layoutAddList: [],
      };
      temlayouts.forEach((item) => {
        const layoutitem = {
          coordinateH: item.h,
          coordinateW: item.w,
          coordinateX: item.x,
          coordinateY: item.y,
          integratedId: id,
          typeName: item.type,
          type_id: item.typeId,
        };
        payload.layoutAddList.push(layoutitem);
      });
      const res = await Homecontents.batchAddDisplayLayout(payload);
      return res;
    },
  },
};
</script>

<style lang="scss" scoped>
.layout-content {
  padding-top: 20px;

  .name-input {
    width: 386px;
    border-radius: 10px;
    background: rgba(255, 255, 255, 1);
  }
}

// 模板的样式格子
.main-layout-block {
  padding-right: 28px;
  display: grid;
  grid-template-columns: repeat(4, minmax(0, 1fr));
  gap: 16px;

  > .layout {
    cursor: pointer;
    position: relative;
    width: 156px;
    height: 94px;
    opacity: 1;
    background: #fff;
    border: 4px solid rgba(240, 240, 240, 1);
    box-shadow: 0 0 0 4px #fff;

    &.active,
    &:hover {
      box-shadow: 0 0 0 4px rgba(221, 210, 247, 1);
    }
  }

  .layout-2,
  .layout-4 {
    &:before {
      content: "";
      position: absolute;
      width: 4px;
      background: rgba(240, 240, 240, 1);
      left: 50%;
      top: 0;
      bottom: 0;
      transform: translateX(-50%);
    }
  }

  .layout-3,
  .layout-4 {
    &:after {
      content: "";
      position: absolute;
      height: 4px;
      background: rgba(240, 240, 240, 1);
      top: 50%;
      left: 0;
      right: 0;
      transform: translateY(-50%);
    }
  }
}
</style>
