// DynamicPopup.vue,用来追加那个组件到body上
<template>
  <div class="popup-overlay" @click.self="close">
    <div class="popup-content">
      <!-- <button @click="close" class="close-button">×</button> -->
      <component :is="componentName" v-bind="componentProps" @close="close" />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    visible: Boolean,
    componentName: {
      type: [String, Object],
      required: true,
    },
    componentProps: {
      type: Object,
      default: () => ({}),
    },
  },
  mounted() {},
  methods: {
    // 关闭
    close() {
      this.$emit("close");
    },
  },
};
</script>

<style scoped>
.popup-overlay {
  position: absolute;
  z-index: 12;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
}
.popup-content {
  background: white;
  /* padding: 20px; */
  border-radius: 4px;
  position: relative;
}
.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  border: none;
  background: none;
  font-size: 20px;
  cursor: pointer;
}
</style>
