<template>
  <div class="attribute-container">
    <div class="right-close">
      <svg-icon icon="xs-icon-close" @click.native="closeAttribute"></svg-icon>
    </div>
    <el-tabs v-model="activeTab" class="attribute-tabs" @tab-click="attributeTabClick">
      <el-tab-pane v-loading="attributeLoading" label="属性" name="attribute">
        <div class="attribute-wrapper">
          <div class="attribute-header">
            <div class="resize-box" ref="resizeBox">
              <div class="resize-box-cell one-line" ref="resizeLeft">名称</div>
              <div class="resize-box-div" ref="resizeDiv"></div>
              <div class="resize-box-cell-right">值</div>
            </div>
            <svg-icon class="pointer" icon="xs-icon-setting" @click.native="selectAttributeVisible=true"></svg-icon>
          </div>
          <div class="attribute-row">
            <span class="one-line" :style="`width: ${attributeLabelWidth}px;padding-left: 10px;`">文件名称</span>
            <span class="attribute-row__content">{{ current.folderName }}</span>
          </div>
          <div class="attribute-row">
            <span class="one-line" :style="`width: ${attributeLabelWidth}px;padding-left: 10px;`">文件来源：</span>
            <span class="attribute-row__content">{{ attributeDetail.filePosition || attributeDetail.folderPosition }}</span>
          </div>
          <div class="attribute-row" v-for="item in attributeDetail.fsDictQueryVOList" :key="item.id">
            <span class="one-line" :title="item.dictName" :style="`width: ${attributeLabelWidth}px;padding-left: 10px;color:#B3B3B3`">{{item.dictName}}：</span>
            <span class="attribute-row__content" v-if="item.dictItemType === 2" >
              <span v-for="i in item.detailDTOList" :key="i.id"> <span v-if="i.isSelected"> {{i.itemText}}&nbsp;&nbsp;</span></span>
            </span>
            <span v-if="item.dictItemType === 1">{{item.detailDTOList[0].itemText}}</span>
          </div>
          <div class="file-info">
            <div class="file-info-row">文件大小：{{ common.formatFileSize(attributeDetail.fileSize ||
              attributeDetail.folderSize) }}</div>
            <div class="file-info-item">更新时间：{{ Object.dayjs(attributeDetail.updateTime) }}</div>
            <div class="file-info-item">更新人：{{ attributeDetail?.updateBy }}</div>
            <div class="file-info-item">创建时间：{{ Object.dayjs(attributeDetail.createTime) }}</div>
            <div class="file-info-item">创建人：{{ attributeDetail?.createBy }}</div>
          </div>
        </div>
      </el-tab-pane>
      <el-tab-pane label="概要" name="summary">
        <SummaryComments :subject-type="2" :current="current"/>
      </el-tab-pane>
      <!-- <el-tab-pane label="权限" name="power">
        <FilePermission/>
      </el-tab-pane> -->
      <el-tab-pane label="操作日志" name="log">
        <div v-for="item in operationLog" class="log-item" :key="item.id">
          <div class="log-item-top">
            <div class="log-item-top-left">
              <div class="log-item-top__avatar no-avatar">
                <span>{{ common.getFirstChat(item.createBy) }}</span>
              </div>
              <span class="log-item-top__name">{{ item.createBy }}</span>
            </div>
            <span class="log-item-top__date">{{ Object.dayjs(item.createTime) }}</span>
          </div>
          <span class="log-item-content">{{ item.optContent }}</span>
        </div>
      </el-tab-pane>
      <el-tab-pane label="关联" name="associate">
        <el-table header-cell-class-name="app-header-cell" :data="associateList">
          <el-table-column show-overflow-tooltip label="名称" prop="eventName" min-width="200"></el-table-column>
          <el-table-column label="类型" prop="eventType" min-width="120"></el-table-column>
          <el-table-column label="负责人" prop="responsibleBy" min-width="120"></el-table-column>
          <el-table-column label="状态" prop="status" min-width="120"></el-table-column>
        </el-table>
      </el-tab-pane>
      <el-tab-pane label="版本" name="version" v-if="current&&current.fileType==1">
        <el-table header-cell-class-name="app-header-cell" :data="versionList">
          <el-table-column show-overflow-tooltip label="版本号" prop="fileVersion" min-width="60"></el-table-column>
          <el-table-column label="创建人" prop="createBy" min-width="80" :show-overflow-tooltip="true"></el-table-column>
          <el-table-column label="创建时间" prop="createTime" min-width="140"></el-table-column>
          <el-table-column label="操作" width="60">
            <template slot-scope="{row}">
              <el-dropdown placement="bottom" trigger="click">
                <div style="padding: 0;" @click.stop>
                  <svg-icon color="#000" icon="product-tool11" width="16" height="16"></svg-icon>
                </div>
                <el-dropdown-menu class="more-dropdown" slot="dropdown">
                  <el-dropdown-item>
                    <div class="filter-dropdown-item">
                      <span class="pointer" @click="handleDownload(row)">下载</span>
                    </div>
                  </el-dropdown-item>
                  <el-dropdown-item>
                    <div class="filter-dropdown-item">
                      <span class="pointer" @click="handlePreview(row)">预览</span>
                    </div>
                  </el-dropdown-item>
                  <el-dropdown-item v-if="row.id != versionList[0].id">
                    <div class="filter-dropdown-item">
                      <span class="pointer" @click="resumeFileVersion(row)">设为主版本</span>
                    </div>
                  </el-dropdown-item>
                  <el-dropdown-item v-if="row.id != versionList[0].id">
                    <div class="filter-dropdown-item">
                      <span class="pointer" @click="handleDelete(row)">删除</span>
                    </div>
                  </el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
            </template>
          </el-table-column>
        </el-table>
      </el-tab-pane>
    </el-tabs>
    <AttributeDialog v-if="selectAttributeVisible" :visible.sync="selectAttributeVisible" :category="attributeDetail.fsDictQueryVOList" @finish="getAttributeDetail"/>
    <FilePreview v-if="preview" :visible.sync="preview" :haslist="false" :currentitem="currentRow" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { File } from "@/api/File";
import { Netdisk } from "@/api/Netdisk";
import { AppDownloadByUrl, officeView } from "@/utils/file";
import NETSYSTEM from '@/api/NETSYSTEM';
import AttributeDialog from "../dialog/AttributeDialog.vue";
import FilePreview from "@/components/dialog/FilePreview.vue";
import SummaryComments from "../../../components/Product/SummaryComments.vue";
// import FilePermission from "@/components/filecompanies/FilePermission.vue";
export default {
  props: {
    current: Object,
    visible: {
      type: Boolean,
      default: false,
    },
    moduleId: {
      type: String,
      default: '',
    }
  },
  components: { AttributeDialog, FilePreview, SummaryComments },
  data() {
    return {
      currentItem: null, // 选中的文件/文件夹
      activeTab: "attribute",
      showAttribute: false,
      attributeDetail: {}, // 属性详情
      operationLog: [], // 操作日志
      associateList: [],
      defaultSelect: [],
      disabledList: [], // 不能选择的二级分类id
      attributeLoading: false,
      attributeLabelWidth: 76, // 属性名称的宽度
      cloudTableHeight: 550,
      selectAttributeVisible: false, // 属性选择弹窗
      versionList:[],
      preview:false,//视频预览弹窗
      currentRow:null
    };
  },
  watch: {
    current: {
      handler(newValue) {
        this.activeTab = "attribute";
        newValue&&this.getAttributeDetail()
      },
      deep: true,
    }
  },
  computed: {
    ...mapGetters(["token", "userInfo", "activeMenu"]),
    showdialog: {
      get() {
        return this.visible;
      },
      set(val) {
        this.$emit("update:visible", val);
      },
    },
  },
  created() {

  },
  mounted() {
    this.current&&this.getAttributeDetail()
  },
  methods: {
    /**
     * @description: 获取文本元素宽度
     * @param {String} text 文本
     * @param {String} id 文本id
     * @return {Number} 文本元素宽度
     */
    getElementWidth(text, id = "virtualSpan") {
      let el = document.createElement("span");
      el.setAttribute('id', id);
      el.innerHTML = text;
      el.style.setProperty('font-size', "14px");
      el.style.setProperty('font-weight', 400);
      el.style.setProperty('height', 0);
      el.style.setProperty('padding', 0);
      el.style.setProperty('border', "none");
      el.style.setProperty('margin', 0);
      document.body.appendChild(el);
      let el2 = document.getElementById(id);
      const width = Math.ceil(parseInt(el2.getBoundingClientRect().width * 1000) / 10) / 100;
      document.body.removeChild(el);
      return width
    },
    /**
     * @description: 格式化文件大小
     * @param {Number} size 文件大小
     * @return {String} 格式化后的文件大小
     */
    formatFileSize(size) {
      if (typeof size !== "number") {
        return "--"
      }
      if (size < 1024) {
        return size + "B";
      } else if (size >= 1024 && size < 1024 * 1024) {
        return Math.ceil(size / 1024 * 100) / 100 + "KB";
      } else if (size >= 1024 * 1024 && size < 1024 * 1024 * 1024) {
        return Math.ceil(size / 1024 / 1024 * 100) / 100 + "MB";
      } else {
        return Math.ceil(size / 1024 / 1024 / 1024 * 100) / 100 + "GB";
      }
    },
    /**
     * @description: 获取文件/文件夹属性
     * @return {void}
     */
    async getAttributeDetail() {
      this.attributeLoading = true;
      this.defaultSelect = [];
      this.disabledList = [];
      let payload = {
        fileId: this.current.id,
        moduleId: this.moduleId,
      }
      let payload2 = {
        folderId: this.current.id,
        moduleId: this.moduleId,
      }
      const res =this.current.fileType==2? await Netdisk.getResourceFolderAttributeDetail(payload2):await Netdisk.getResourceFileAttributeDetail(payload);
      res.fsDictQueryVOList.forEach(element => {
        element.id = element.dictId;
        element.dataId = res.id;
        const selected = element.detailDTOList ? element.detailDTOList.filter(item => item.isSelected) : [];
        if (this.defaultSelect.length === 0 && selected.length) {
          this.defaultSelect.push(selected[0].id);
        }
        if (element.dictItemType === 1) {
          element.dictUseType = 2
          // 自由输入
          element.itemText = element.detailDTOList[0] ? element.detailDTOList[0].itemText : '';
          element.description = element.detailDTOList[0] ? element.detailDTOList[0].description : '';
        } else if (element.dictItemType === 2) {
          if (element.dictName != "演示材料") {
            element.dictUseType = 2
          }
          // 固定值
          // element.itemTextId = selected.length ? selected[0].id : "";
          element.itemTextId = [];
          selected.forEach(item => element.itemTextId.push(item.id));
        } 
      })
      this.attributeDetail = res;
      this.attributeLoading = false;
      this.setResize();
    },
    /**
     * @description: 拖动改变属性名称的宽度
     * @return {void}
     */
    setResize() {
      let leftDiv = this.$refs.resizeLeft;
      const resizeDom = this.$refs.resizeDiv;
      resizeDom.onmousedown = (e) => {
        let startX = e.clientX;
        const resizeLeft = leftDiv.offsetWidth;
        const maxWidth = this.$refs.resizeBox.offsetWidth;
        document.onmousemove = (e) => {
          const endX = e.clientX;
          let offsetWidth = endX - startX + resizeLeft;
          if (offsetWidth < 30) {
            offsetWidth = 30;
          }
          if (offsetWidth > maxWidth - 60) {
            offsetWidth = maxWidth - 60;
          }
          leftDiv.style.width = `${offsetWidth}px`;
          this.attributeLabelWidth = offsetWidth;
        };
        document.onmouseup = () => {
          document.onmousemove = null;
          document.onmouseup = null;
        };
        return false;
      }
    },
    /**
     * @description: 获取关联事项列表
     * @return {void}
     */
    async getFileRelated() {
      this.associateList = await File.getFileRelatedMatters(this.attributeDetail.identifyId);
      this.attributeLoading = false;
    },
    /**
     * @description: 关闭属性区域
     * @return {void}
     */
    closeAttribute() {
      this.showdialog=false
      this.activeTab = "attribute";
      this.currentItem = null;
    },
    /**
     * @description: 获取版本信息
     * @return {void}
     */
    async getVersionList() {
      this.versionList = await File.getByFileVersion(this.current.id);
    },
    /**
     * @description: 属性 tab 被选中时触发
     * @param {VueComponent} tab 被选中的标签 tab 实例
     * @return {void}
     */
    attributeTabClick(tab) {
      if (tab.name === "log") {
        this.getLogs();
      }
      if (tab.name === "associate") {
        if (this.attributeDetail.identifyId) {
          this.getFileRelated();
        } else {
          this.associateList = [];
        }
      }
      if (tab.name === "version") {
        this.getVersionList()
      }
    },
    /**
     * @description: 获取操作日志
     * @return {void}
     */
    async getLogs() {
      const payload = {
        dataId: this.current.id,
        sort: "",
        sortType: "",
        pageNo: 1,
        pageSize: 9999,
      }
      const res = await File.getFileLog(payload);
      this.operationLog = res.records;
    },
    handlePreview(row) {
      let type = this.common.getFileType(row.contentType, row.fileSavePath);
      if (type === "image") {
        this.$elImageViewer({ urlList: [row.fileSavePath], initialIndex: 0 });
      }else{
        this.currentRow = row;
        this.preview = true;
      }
      // if (type === "office") {
      //   officeView(row.id);
      // }
      // if (type === "image") {
      //   this.$elImageViewer({ urlList: [row.fileSavePath], initialIndex: 0 });
      // }
      // if (type === "video") {
      //   this.currentRow = row
      //   this.preview = true
      // }
    },
    async handleDownload(data) {
      if (data.fileType === "2") {
        return;
      } else {
        let isCurrent=1
        if(data.fileVersion!='V1.0'){
          isCurrent=2
        }
        AppDownloadByUrl(data,isCurrent)
      }
    },
    /**
     * @description: 设置主版本
     * @param {Object} value 点击项
     * @return {void}
     */
    async resumeFileVersion(value){
      const res = await NETSYSTEM.resumeFileVersion({
        fileId:value.id
      })
      if(res){
        this.getVersionList();
        this.$Pubsub.publish("update-table-data", {})
        this.$message.success('设置成功')
      }
    },
    /**
     * @description: 删除
     * @param {Object} value 点击项
     * @return {void}
     */
    handleDelete(value) {
      this.$showMessage({
        message: `是否确认删除 <span style="font-weight: 1000;">${value.fileVersion}</span> ?`,
        ...this.$global.deleteMessage
      }, async (done) => {
        const res = await File.deleteFile(value.id, 0, 2);
        done(res);
        res && this.getVersionList();
      });
    },
  },
};
</script>
<style scoped lang="scss">
.attribute-header {
  width: 100%;
  height: 32px;
  border-radius: 5px 5px 0 0;
  margin-bottom: 16px;
  background-color: #fafbfc;
  display: flex;
  align-items: center;

  .resize-box {
    flex: 1;
    display: flex;
    align-items: center;

    .resize-box-cell {
      width: 76px;
      padding-left: 10px;
    }

    .resize-box-div {
      width: 2px;
      height: 12px;
      border-radius: 2px;
      background-color: #e6ebf2;
      cursor: col-resize;
    }

    .resize-box-cell-right {
      box-sizing: border-box;
      flex: 1;
      padding-left: 40px;
    }
  }
}
.attribute-container {
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  padding: 20px;
  border-radius: 5px 5px 0 0;
  background-color: #fff;
  position: relative;

  .file-disk-item {
    height: 30%;

    &.cloud-disk {
      height: 70%;
    }

    .file-disk-item__title {
      line-height: 20px;
      margin-bottom: 16px;
      font-size: 14px;
      font-weight: 700;
      color: var(--color-text-regular);
    }

    .file-disk-item__content {
      height: calc(100% - 36px);
      overflow-y: auto;
    }
  }

  .right-close {
    position: absolute;
    z-index: 1;
    top: 30px;
    right: 30px;
    height: 20px;
    cursor: pointer;
    display: flex;
    flex-direction: row-reverse;

    .svg-icon+.svg-icon {
      margin-right: 16px;
    }

    .svg-icon {
      &:hover {
        color: var(--color-primary) !important;
      }
    }

  }

  ::v-deep(.el-tabs) {
    height: 100%;
    display: flex;
    flex-direction: column;

    .el-tabs__nav-wrap::after {
      height: 0;
    }

    .el-tabs__content {
      flex: 1;

      .el-tab-pane {
        height: 100%;
        overflow: auto;
      }
    }
  }

  .attribute-wrapper {
    overflow: auto;
    font-size: 12px;
    font-weight: 500;
    color: var(--color-text-regular);

    .attribute-row {
      line-height: 20px;
      border-radius: 5px;
      margin-bottom: 16px;
      display: flex;
      position: relative;

      &.custom-attribute {
        line-height: 32px;
        padding-right: 56px;

        &:hover {
          background-color: #fafbfc;

          .custom-attribute-icon {
            display: flex;
            align-items: center;
          }
        }

        .custom-attribute-icon {
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          display: none;
        }
      }

      .attribute-row__content {
        flex: 1;
        word-break: break-all;

        .descr {
          padding-left: 16px;
          color: #9facc2;
        }
      }
    }

    .file-attribute {
      display: flex;

      .file-attribute__label {
        width: fit-content;
        line-height: 32px;
        padding-left: 10px;
      }

      .file-attribute__content {
        flex: 1;
        display: flex;
        flex-wrap: wrap;
        align-items: flex-start;

        .xs-checkbox-group {
          .el-checkbox {
            margin: 0 16px 16px 0;
          }

          .el-checkbox.is-bordered {
            border-radius: 5px;
          }

          .el-checkbox.is-bordered+.el-checkbox.is-bordered {
            margin-left: 0;
          }
        }
      }
    }

    .file-info {
      box-sizing: border-box;
      padding: 20px;
      border-radius: 10px;
      background: #fafbfc;
      display: flex;
      flex-wrap: wrap;
      align-content: flex-start;

      .file-info-row {
        width: 100%;
      }

      .file-info-item {
        width: 50%;
        margin-top: 12px;
        word-break: break-all;
      }
    }

    .attr-item {
      font-size: 14px;
      display: flex;
      border-top: 1px solid #f7f7f7;
      border-bottom: 1px solid #f7f7f7;

      .attr-item-label {
        flex-shrink: 0;
        box-sizing: border-box;
        width: 100px;
        min-height: 32px;
        padding: 6px 8px;
        text-align: center;
        background-color: #f2f2f2;

        >span {
          line-height: 20px;
          word-break: break-all;
        }
      }

      .attr-item-label-value {
        box-sizing: border-box;
        width: calc(100% - 100px);
        min-height: 32px;
        padding: 0 6px;
        background-color: #fff;
        display: flex;
        align-items: center;
        position: relative;

        &.custom-attribute {
          padding-right: 34px;
        }

        >span {
          word-break: break-all;
        }

        &:hover {
          .attr-action {
            display: block;
          }
        }

        .descr {
          padding-left: 6px;
          color: #c0c4cc;
        }

        .attr-action {
          display: none;
          position: absolute;
          top: calc(50% - 8px);
          right: 6px;
          font-size: 16px;
          cursor: pointer;

          &.attr-edit {
            right: 28px;
          }
        }
      }
    }
  }

  .associate-wrapper {
    height: 30%;
    overflow: auto;
  }

  .log-item {
    padding: 30px 0 28px;
    border-bottom: 1px solid #e6ebf2;

    &:last-child {
      border: none;
    }

    .log-item-top {
      margin-bottom: 12px;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .log-item-top-left {
        display: flex;
        align-items: center;

        .log-item-top__avatar {
          width: 16px;
          height: 16px;
          border-radius: 5px;

          &.no-avatar {
            font-size: 12px;
            font-weight: 400;
            color: #fff;
            line-height: 1;
            background-color: var(--color-primary);
            display: flex;
            align-items: center;
            justify-content: center;

            span {
              transform: scale(0.83);
            }
          }
        }

        .log-item-top__name {
          padding-left: 8px;
        }
      }

      .log-item-top__date {
        color: #cfd7e6;
      }
    }

    .log-item-content {
      padding-left: 24px;
      word-break: break-all;
    }

    .log-item__value {
      flex: 1;
      font-size: 14px;
      color: #666;
      word-break: break-all;
    }

    .log-item__user {
      display: flex;
      align-items: center;
      padding-left: 12px;
    }

    .log-item__date {
      position: absolute;
      right: 16px;
      bottom: 0;
    }
  }
}
.operate{
  span{
    padding: 0 2px;
    &:hover{
      color: var(--color-primary);
    }
  }
}
</style>