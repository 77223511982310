<template>
  <div class="last-and-next-step-btns">
    <!-- <el-button
      type="primary"
      size="small"
      :disabled="!hasHistory"
      @click="onLastStepClick"
      ><i class="el-icon-back el-icon--left"></i>后退</el-button
    >
    <el-button
      type="primary"
      size="small"
      :disabled="!hasFuture"
      @click="onNextStepClick"
      >前进<i class="el-icon-right el-icon--right"></i
    ></el-button> -->

    <div
      class="icon-btn-container"
      :style="{ width: `${containerSize}px`, height: `${containerSize}px` }"
    >
      <!-- <IconBtn
        v-if="hasHistory"
        class="icon-btn icon-btn-undo"
        :activeIconClass="'undo'"
        :inactiveIconClass="'undo'"
        :iconSize="iconSize"
        :padding="iconPadding"
        @btn-click="onLastStepClick"
      /> -->

      <IconBtn
        v-if="hasHistory"
        class="icon-btn icon-btn-undo"
        :iconSize="iconSize"
        :padding="iconPadding"
        @btn-click="onLastStepClick"
      >
        <template v-slot:active>
          <!-- <IconUndo :size="iconSize" /> -->
          <svg-icon
            sys="common-table"
            name="ViewEditor/undo"
            :size="iconSize"
          />
        </template>
      </IconBtn>
    </div>
    <div
      class="icon-btn-container"
      :style="{ width: `${containerSize}px`, height: `${containerSize}px` }"
    >
      <!-- <IconBtn
        v-if="hasFuture"
        class="icon-btn icon-btn-redo"
        :activeIconClass="'redo'"
        :inactiveIconClass="'redo'"
        :iconSize="iconSize"
        :padding="iconPadding"
        @btn-click="onNextStepClick"
      /> -->

      <IconBtn
        v-if="hasFuture"
        class="icon-btn icon-btn-redo"
        :iconSize="iconSize"
        :padding="iconPadding"
        @btn-click="onNextStepClick"
      >
        <template v-slot:active>
          <!-- <IconRedo :size="iconSize" /> -->
          <svg-icon
            sys="common-table"
            name="ViewEditor/redo"
            :size="iconSize"
          />
        </template>
      </IconBtn>
    </div>
  </div>
</template>

<script lang="ts">
import Vue from "vue";
import SvgIcon from "@/flexible-table-module/svg-icon-importer";

// import IconRedo from "@/flexible-table-module/icons/ViewEditor/IconRedo.vue";
// import IconUndo from "@/flexible-table-module/icons/ViewEditor/IconUndo.vue";

import IconBtn from "@/flexible-table-module/components/Common/IconBtn.vue";

export default Vue.extend({
  inheritAttrs: false,
  name: "LastAndNextStepBtns",
  components: {
    SvgIcon,
    // IconRedo,
    // IconUndo,
    IconBtn,
  },
  props: {
    hasHistory: { type: Boolean, default: false },
    hasFuture: { type: Boolean, default: false },
  },
  data() {
    return {
      iconSize: 16,
      iconPadding: 2,
    };
  },
  computed: {
    containerSize(): number {
      return this.iconSize + this.iconPadding * 2;
    },
  },
  methods: {
    onLastStepClick() {
      this.$emit("last-step-click");
    },
    onNextStepClick() {
      this.$emit("next-step-click");
    },
  },
});
</script>
<style lang="scss" scoped>
.last-and-next-step-btns {
  display: flex;
  justify-content: flex-start;
  align-items: center;

  .icon-btn-container {
    display: inline-block;
    &:not(:first-child) {
      margin-left: 8px;
    }
    .icon-btn {
    }
  }
}
</style>