<template>
  <div class="header-backtool">
    <el-button
      size="small"
      :type="selectbutton.itemValue == 1 ? '' : 'primary'"
      plain
      @click="handleSelectbutton"
      round
      >{{ selectbutton.itemText }}</el-button
    >
  </div>
</template>
<script>
import { Users } from "@/api/Users";
import { mapGetters } from "vuex";
import Mousetrap from "mousetrap";
export default {
  props: {},
  data() {
    return {
      buttonList: [],
      selectbuttonId: "", //当前选择按钮id
      selectbutton: "", //当前选择按钮
      statusvisible: false,
      showbuttonList: false,
      keyresult: "",
    };
  },
  computed: {
    ...mapGetters(["Viewstatus"]),
  },
  watch: {
    Viewstatus(val) {
      this.getUserbuttonList();
    },
  },
  mounted() {
    this.getUserbuttonList();
    this.getKey();
  },
  beforeDestroy() {
    Mousetrap.unbind(this.keyresult);
  },
  methods: {
    /**
     * @description: 获取按钮表
     * @return {void}
     */
    async getUserbuttonList() {
      const res = await Users.UserbuttonList();
      this.buttonList = _.sortBy(res, "sortNumber"); // 按 sortNumber 排序
      this.buttonList = this.buttonList.filter((item) => item.itemText != "演示");
      let select = res.filter((item) => item.isDefault);
      if (select.length) {
        this.selectbutton = select[0];
        this.selectbuttonId = select[0].id;
      } else {
        this.selectbutton = res[0];
        this.selectbuttonId = res[0].id;
      }
      this.$store.commit("saveViewstatus", this.selectbutton.itemValue);
      this.$emit("statuschange", this.selectbutton.itemValue);
    },
    /**
     * @description: 隐藏按钮表
     * @return {void}
     */
    handleHide() {
      if (!this.buttonList[0].isFixed) {
        this.showbuttonList = false;
      }
    },
    /**
     * @description: 选择按钮表
     * @return {void}
     */
    async handleSelectbutton() {
      const buttonvalue = this.selectbutton.itemValue == 1 ? 3 : 1;
      this.selectbutton = this.buttonList.filter(
        (item) => item.itemValue == buttonvalue
      )[0];
      this.selectbuttonId = this.selectbutton.id;
      await Users.UserbuttonsetDefault(this.selectbuttonId);
      this.$store.commit("saveViewstatus", buttonvalue);
      this.$emit("statuschange", buttonvalue);
    },
    /**
     * @description: 获取设置的快捷键
     * @return {void}
     */
    async getKey() {
      const res = await Users.getShortcutKey();
      let hotkey;
      if (res && res.length) {
        hotkey = res.filter((item) => item.keyType == 1);
      }
      if (hotkey && hotkey[0]) {
        let result = hotkey[0].key1;
        if (hotkey[0].key2) {
          result += "+" + hotkey[0].key2;
        }
        if (hotkey[0].key3) {
          result += "+" + hotkey[0].key3;
        }
        this.keyresult = result;
      } else {
        this.keyresult = "ctrl+a"; //未设置时给个默认值
      }
      Mousetrap.bind(this.keyresult, this.onCtrlSShortcut);
    },
    /**
     * @description: 触发快捷键操作
     * @return {void}
     */
    onCtrlSShortcut(event) {
      event.preventDefault(); // Prevent the default browser action
      this.handleSelectbutton();
    },
  },
};
</script>
<style lang="scss">
.header-backtool {
  margin-right: 18px;
  display: flex;
  align-items: center;
  flex-shrink: 0;
  // font-size: 12px;
}
</style>
