<template>
  <div class="dropdown-item" @click="onClick">{{ value }}</div>
</template>

<script lang="ts">
import Vue from "vue";
export default Vue.extend({
  inheritAttrs: false,
  name: "DropdownItem",
  props: {
    // id: { type: String, required: true },
    value: { type: String, required: true },
  },
  methods: {
    onClick() {
      this.$emit("item-click");
    },
  },
});
</script>

<style lang="scss">
@import "src/flexible-table-module/scss/_variables.scss";

$item-height: 28px;

.el-popover {
  &.dropdown-popover {
    .dropdown-item {
      user-select: none;
      cursor: pointer;
      box-sizing: border-box;
      min-width: 96px;
      height: $item-height;
      line-height: $item-height;
      padding: 0 8px;

      &:hover {
        color: #7b5dc2;
        background-color: #efebf7;
      }
    }
  }
}
</style>