import "../scss/DomDraggingHelper.scss";

const DRAGGING_CLASS = 'DomDraggingHelper_dragging';

export class DomDraggingHelper {
    private readonly dom: HTMLElement;
    private includeClasses: string[] = [];
    private excludeClasses: string[] = [];
    private _disabled: boolean = false;
    private _isDragging: boolean = false;

    constructor(
        dom: HTMLElement,
        options?: {
            disabled?: boolean,
            includeClasses?: string[];
            excludeClasses?: string[];
        }
    ) {
        this.dom = dom;

        const {
            disabled = false,
            includeClasses = [],
            excludeClasses = [],
        } = options || {};

        this._disabled = disabled;
        this.includeClasses = includeClasses;
        this.excludeClasses = excludeClasses;

        this.init();
    }

    public get disabled() {
        return this._disabled;
    }

    public set disabled(value: boolean) {
        this._disabled = value;
    }

    public get isDragging() {
        return this._isDragging;
    }

    private init() {
        this.dom.addEventListener('mousedown', this.handleMouseDown);
        this.dom.addEventListener('mousemove', this.handleMouseHover);
        this.dom.addEventListener('mouseout', this.handleMouseOut);
    }

    private handleMouseHover = (e: MouseEvent) => {
        if (this.disabled) {
            return;
        }

        if (this.isInDraggingArea(e)) {
            this.dom.classList.add(DRAGGING_CLASS);
        } else {
            this.dom.classList.remove(DRAGGING_CLASS);
        }
    }

    private handleMouseOut = (e: MouseEvent) => {
        if (this.disabled) {
            return;
        }

        this.dom.classList.remove(DRAGGING_CLASS);
    }

    private handleMouseDown = (e: MouseEvent) => {
        if (this.disabled) {
            return;
        }

        if (!this.isInDraggingArea(e)) {
            return;
        }

        this._isDragging = true;

        const initialX = e.clientX - this.dom.offsetLeft;
        const initialY = e.clientY - this.dom.offsetTop;

        const handleMouseMove = (e: MouseEvent) => {
            this.dom.style.left = `${e.clientX - initialX}px`;
            this.dom.style.top = `${e.clientY - initialY}px`;
        };

        const handleMouseUp = () => {
            document.removeEventListener('mousemove', handleMouseMove);
            document.removeEventListener('mouseup', handleMouseUp);
            this._isDragging = false;
        };

        document.addEventListener('mousemove', handleMouseMove);
        document.addEventListener('mouseup', handleMouseUp);
    }

    private isInDraggingArea(e: MouseEvent) {
        const targetElement = e.target as HTMLElement;

        if (
            this.excludeClasses.some((className) =>
                targetElement.classList.contains(className)
            )) {
            return false;
        }

        if (this.includeClasses.length > 0 &&
            !this.includeClasses.some((className) =>
                targetElement.classList.contains(className)
            )) {
            return false;
        }

        return true;
    }

    public destroy() {
        this.dom.removeEventListener('mousedown', this.handleMouseDown);
        this.dom.removeEventListener('mousemove', this.handleMouseHover);
        this.dom.removeEventListener('mouseout', this.handleMouseOut);
    }
}