export const CHAT_HOST = "101.35.189.186:6060";
export const CHAT_APP_NAME = "I3VIM";
export const CHAT_API_KEY = "AQEAAAABAAD_rAp4DJh05a1HAwFT3A6K";
export const CHAT_USE_HTTPS = false;
export const CHAT_TRANSPORT = "ws";

export const FILE_PERMISSIONS = [
  {
    key: "1",
    perms:
      "file:add,file:copy,file:delete,file:discuss,file:download,file:moveto,file:paste,file:permission,file:rename,file:reversion,file:share,file:update,file:updatestatus,file:upgrade,file:view",
    label: "完全控制",
    tips: "可上传下载 / 查看 / 编辑 / 删除",
  }, // 完全控制
  {
    key: "2",
    perms:
      "file:add,file:copy,file:discuss,file:download,file:paste,file:rename,file:reversion,file:share,file:update,file:upgrade",
    label: "可编辑",
    tips: "可上传下载 / 查看 / 编辑",
  }, // 写入
  {
    key: "3",
    perms: "file:copy,file:discuss,file:download,file:view",
    label: "可下载",
    tips: "可下载 / 在线预览",
  }, // 可查看
  {
    key: "4",
    perms: "file:discuss,file:view",
    label: "查看",
    tips: "仅可在线预览，不能下载",
  }, // 仅查看
];