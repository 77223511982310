<template>
  <div class="table-contain">
    <el-table
      ref="multipleTable"
      header-cell-class-name="app-table-header"
      :data="tableData"
      style="width: 100%"
      @selection-change="handleSelectionChange"
    >
      <el-table-column v-if="showtype == 2" type="selection" width="55">
      </el-table-column>
      <el-table-column prop="date" label="名称" min-width="180">
        <template slot-scope="{ row }">
          <div class="quote-item flex pointer" @click="handlePreview(row)">
            <div class="item-cover">
              <img :src="row.subjectCoverVos[0].coverUrl" alt="" />
            </div>
            <el-link :title="row.subjectName ? row.subjectName : row.sourceName">
              <div style="max-width: 300px" class="one-line">
                {{ row.subjectName ? row.subjectName : row.sourceName }}
              </div>
            </el-link>
          </div>
        </template>
      </el-table-column>
      <el-table-column label="简介" width="180">
        <template slot-scope="{ row }">
          <div :title="row.coverRemark" class="one-line">{{ row.coverRemark }}</div>
        </template>
      </el-table-column>
      <el-table-column prop="address" label="更新时间" width="150">
        <template slot-scope="{ row }">
          {{ Object.dayjs(row.updateAt ? row.updateAt : row.createAt) }}
        </template>
      </el-table-column>
      <el-table-column prop="address" label="操作" v-if="!demonstrate">
        <template slot-scope="{ row }">
          <div class="product-card__info-toolbar flex">
            <div class="product-card__info-toolbar-left flex">
              <el-tooltip
                v-if="showtype == 2"
                effect="dark"
                placement="bottom"
                :enterable="false"
                content="恢复"
              >
                <div class="product-card__info-toolbar-item" @click="handleRecover(row)">
                  <svg-icon icon="arrow-back" color="#344563"></svg-icon>
                </div>
              </el-tooltip>
              <el-tooltip
                v-if="showtype == 2"
                effect="dark"
                placement="bottom"
                :enterable="false"
                content="删除"
              >
                <div class="product-card__info-toolbar-item" @click="handleDelete(row)">
                  <svg-icon icon="delete16" color="#344563"></svg-icon>
                </div>
              </el-tooltip>
              <!-- <el-tooltip
                v-for="column in row.settingColumn"
                :key="column.id"
                effect="dark"
                placement="bottom"
                :enterable="false"
                :content="column.columnName"
              >
                <div
                  class="product-card__info-toolbar-item"
                  @click.stop="getDetail(row, column)"
                >
                  <svg-icon
                    :icon="column.columnIcon ? column.columnIcon : 'column-icon-1'"
                    color="#344563"
                  ></svg-icon>
                </div>
              </el-tooltip>
              <el-tooltip
                v-if="row.subjectDemoLinks && row.subjectDemoLinks.length"
                effect="dark"
                placement="bottom"
                :enterable="false"
                transition=""
                :content="
                  row.subjectDemoLinks && row.subjectDemoLinks.length
                    ? '演示系统'
                    : '暂无'
                "
              >
                <el-dropdown placement="bottom" trigger="click" :hide-on-click="false">
                  <div class="product-card__info-toolbar-item" @click.stop>
                    <svg-icon icon="subject_bar_associations" color="#344563"></svg-icon>
                  </div>
                  <el-dropdown-menu slot="dropdown" class="morelink-dropdown">
                    <el-dropdown-item
                      v-for="demo in row.subjectDemoLinks"
                      :key="demo.id"
                      :command="demo"
                    >
                      <div class="filter-dropdown-item flex">
                        <div class="one-line title" @click="handleLinkJump(demo)">
                          {{ demo.name }}
                        </div>
                        <div
                          class="pl-20 flex item"
                          @click="handleCopy(demo.loginAccount)"
                        >
                          <svg-icon icon="user" color="#344563"></svg-icon>
                          <span>复制账号</span>
                        </div>
                        <div
                          class="pl-20 flex item"
                          @click="handleCopy(demo.loginPassword)"
                        >
                          <svg-icon icon="key" color="#344563"></svg-icon>
                          <span>复制密码</span>
                        </div>
                      </div>
                    </el-dropdown-item>
                  </el-dropdown-menu>
                </el-dropdown>
              </el-tooltip> -->
            </div>
          </div>
        </template>
      </el-table-column>
    </el-table>
    <FileList
      v-if="fileListVisible"
      :visible.sync="fileListVisible"
      :subject="clickItem"
      :activecolum="activeColumn"
    />
    <QuickPreview
      v-if="showPreview"
      :visible.sync="showPreview"
      :subject="clickItem"
      :activeid="activecolumnid"
    />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { ProductLibrary } from "@/api/ProductLibrary";
import { Recycle } from "@/api/Recycle";
import { subjectdata, anlidata } from "../data";
import FileList from "@/views/ProductLibrary/dialog/FileList.vue";
import QuickPreview from "@/views/ProductLibrary/dialog/QuickPreview/index.vue";

export default {
  name: "quote",
  props: {
    selectlist: Array,
    showtype: {
      type: Number,
      default: 1, //1不需要选框，2有选择框
    },
    checkall: {
      type: Boolean,
      default: false, //是否全选
    },
    datalist: Object,
    demonstrate: {
      type: Boolean,
      default: false, //是否为演示类
    },
  },
  components: { FileList, QuickPreview },
  data() {
    return {
      tableData: [],
      viewType: "grid",
      clickItem: null,
      fileListVisible: false, // 演示视频、模型、文档弹窗
      showPreview: false,
      multipleSelection: [],
    };
  },
  computed: {
    ...mapGetters(["userInfo"]),
  },
  watch: {
    selectlist(val) {
      if (!val.length) {
        this.$refs.multipleTable.clearSelection();
      }
    },
    checkall(val) {
      if (val) {
        this.handleManualCheck();
      }
    },
    "datalist.data"(val) {
      val.forEach((element) => {
        element.settingColumn.settingSubjectColumnVos = _.sortBy(
          element.settingColumn.settingSubjectColumnVos,
          "sortNo"
        );
        if (element.subjectCoverVos.length === 0) {
          element.subjectCoverVos.push({
            coverUrl: require(`@/assets/images/sample-${this.common.getLastChar(
              this.showtype == 2 ? element.sourceId : element.id,
            )}.png`),
          });
        }
      });
      this.tableData = val;
    },
  },
  created() {},
  mounted() {
    if (this.datalist) {
      this.datalist.data.forEach((element) => {
        element.settingColumn.settingSubjectColumnVos = _.sortBy(
          element.settingColumn.settingSubjectColumnVos,
          "sortNo"
        );
        if (element.subjectCoverVos.length === 0) {
          element.subjectCoverVos.push({
            coverUrl: require(`@/assets/images/sample-${this.common.getLastChar(
              element.id
            )}.png`),
          });
        }
      });
      this.tableData = this.datalist.data;
    }
    this.selectlist && this.handleManualCheck();
  },
  methods: {
    /**
     * @description: 查看产品详情
     * @return {void}
     */
    async handlePreview(value) {
      const payload = {
        subjectId: this.showtype == 2 ? value.sourceId : value.id,
        mirrorId: value.mirrorId,
        versionId: value.versionId,
      };
      if (this.demonstrate) {
        // 演示类预览的时候。。。。。
        const routeData = this.$router.resolve({
          path: "/playeditorpreview",
          query: payload,
        });
        window.open(routeData.href);
        return;
      }
      let res = await ProductLibrary.getSubjectDetail(payload);
      // 如果有演示模式就跳转到演示模式的预览，否则预览栏目
      const routeData = this.$router.resolve({
        path:
          res.demoModes && res.demoModes.length ? "/product-preview" : "/column-preview",
        query: payload,
      });
      window.open(routeData.href);
    },
    /**
     * @description: 切换格子/列表视图
     * @param {Object} value 点击项
     * @return {void}
     */
    changeViewType(type) {
      this.viewType = type;
    },
    /**
     * @description: 复制
     * @param {String} text 复制项
     * @return {void}
     */
    handleCopy(text) {
      const res = this.common.handleCopy(text);
      res && this.$message.success("复制成功");
    },
    /**
     * @description:预览演示模式
     * @param {Object} value  点击项
     * @return {void}
     */
    handleDemoClick(value, id) {
      this.$router.push({
        path: "/product-preview",
        query: {
          subjectId: this.showtype == 2 ? value.sourceId : value.id,
          mirrorId: value.mirrorId,
          versionId: value.versionId,
          demoId: id,
        },
      });
    },
    /**
     * @description:获取主题详情
     * @param {Object} value  点击项
     * @param {Object} value  栏目
     * @return {void}
     */
    async getDetail(value, column) {
      this.clickItem = value;
      const res = await ProductLibrary.getSubjectDetail({
        mirrorId: value.mirrorId,
        subjectId: this.showtype == 2 ? value.sourceId : value.id,
        versionId: value.versionId,
      });
      if (res.subjectColumnVos) {
        res.subjectColumnVos.forEach((element) => {
          if (element.id == column.id) {
            this.activeColumn = element;
          }
        });
      }
      if (this.activeColumn.columnCategoryName == "文件清单栏目") {
        this.fileListVisible = true;
      } else {
        this.showPreview = true;
        this.activecolumnid = column.id;
      }
    },
    /**
     * @description:当选择项发生变化时会触发该事件
     * @param {Object} value 选择的所有项
     * @return {void}
     */
    handleSelectionChange(value) {
      this.multipleSelection = value;
      this.$emit("select", value);
    },
    /**
     * @description:手动更新选择项
     * @return {void}
     */
    handleManualCheck() {
      this.tableData.forEach((item) => {
        if (this.selectlist.findIndex((i) => i.id == item.id) != -1) {
          this.multipleSelection.push(item);
        }
      });
      if (this.multipleSelection) {
        this.multipleSelection.forEach((row) => {
          this.$refs.multipleTable.toggleRowSelection(row);
        });
      } else {
        this.$refs.multipleTable.clearSelection();
      }
    },
    /**
     * @description: 恢复产品
     * @return {void}
     */
    handleRecover(value) {
      this.$showMessage(
        {
          title: "提示",
          message: `确定恢复 <span style="font-weight: 1000;">${
            value.sourceName ? value.sourceName : value.subjectName
          }</span> 吗?`,
        },
        async (done) => {
          let payload = {
            idList: [value.id],
            sourceType: 1,
          };
          const res = await Recycle.restore(payload)
          this.$emit('update')
          done(res);
        }
      );
    },
    /**
     * @description: 删除
     * @return {void}
     */
    handleDelete(value) {
      this.$showMessage(
        {
          message: `确定删除 <span style="font-weight: 1000;">${
            value.sourceName ? value.sourceName : value.subjectName
          }</span> 吗?<br>此次操作会将所选数据永久删除，请谨慎操作！`,
          ...this.$global.deleteMessage,
        },
        async (done) => {
          // let res = true;
          let payload = {
            idList: [value.id],
            sourceType: 1,
          };
          const res = await Recycle.deleteComplete(payload);
          if(res){
            this.$emit('update')
          }else{
            this.$message.error('删除资源异常')
          }
          
          done(true);
        }
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.table-contain {
  // height: 100%;
  width: 100%;
  padding: 16px 7px;
  // overflow-y: auto;
  box-sizing: border-box;
  .quote-item {
    align-items: center;
    .item-cover {
      border-radius: 5px;
      height: 43px;
      line-height: 43px;
      img {
        width: 73px;
        height: 43px;
        border-radius: 5px;
        margin-right: 14px;
      }
    }
  }
}
::v-deep .el-divider--horizonta {
  margin: 15px 0;
}
.product-card__info-toolbar {
  .product-card__info-toolbar-item {
    width: 30px;
    height: 30px;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    margin-top: 7px;
    box-sizing: border-box;

    &.disabled {
      cursor: default;

      .svg-icon {
        color: #c4cbd7 !important;
      }
    }

    &:not(.disabled):hover {
      background-color: #e4ecf7;
    }
  }
}
.morelink-dropdown {
  box-shadow: 0px 0px 5px 2px rgba(94, 108, 132, 0.2);

  .el-dropdown-menu__item {
    line-height: 32px;
    font-size: 14px;
    font-weight: 500;
    color: #344563;
    border-radius: 4px;
    padding: 0;
    margin: 0 8px;

    &:hover {
      color: var(--color-text-primary);
      background-color: #fbfaff;
    }

    .filter-dropdown-item {
      position: relative;
      line-height: 32px;
      padding: 0 10px 0 10px;
      .title {
        width: 96px;
        color: #6a87fc;
      }
      .item {
        display: flex;
        align-items: center;
        &:hover {
          color: var(--color-primary);
          .svg-icon {
            color: var(--color-primary) !important;
          }
        }
      }
    }
  }
}
</style>
