<template>
  <div class="jh-modal" @click.self="handleClickModal">
    <div class="jh-modal__content">
      <i v-if="showClose" class="el-icon-close jh-modal__closebtn" :class="{ 'disabled': loading }"
        @click="handleClickModal"></i>
      <div class="jh-modal__body">
        <div class="jh-modal__title" :style="`color: ${titleColor};`">{{ title }}</div>
        <div v-html="message" class="jh-modal__message" :class="{ 'text-center': textAlignCenter }"></div>
      </div>
      <div class="jh-modal__footer">
        <el-button type="info" :disabled="loading" @click="$emit('close')">{{ cancelButtonText }}</el-button>
        <el-button :type="isDelete ? 'danger' : 'primary'" :loading="loading" @click="handleConfirm">{{ confirmButtonText
        }}</el-button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    // 标题
    title: {
      type: String,
      default: "提示"
    },
    // 标题颜色, hex 格式
    titleColor: {
      type: String,
      default: "#344563"
    },
    // 提示内容(必须)
    message: {
      type: String,
      required: true
    },
    // 是否是删除提示
    isDelete: {
      type: Boolean,
      default: false,
    },
    // 是否显示关闭按钮,默认不显示
    showClose: {
      type: Boolean,
      default: false
    },
    // 取消按钮的文本
    cancelButtonText: {
      type: String,
      default: "取消"
    },
    // 确定按钮的文本
    confirmButtonText: {
      type: String,
      default: "确定"
    },
    // 确定按钮的背景色, hex 格式
    confirmButtonBgColor: {
      type: String,
      default: "#1664ff"
    }
  },
  data() {
    return {
      loading: false,
    }
  },
  computed: {
    // 只有一行时居中显示,多于一行时靠左显示
    textAlignCenter() {
      return this.getElementWidth(this.message) < 348;
    }
  },
  mounted() {
    const root = document.querySelector(":root");
    root.style.setProperty("--modal-confirm-btn-bg-color", this.confirmButtonBgColor);
    root.style.setProperty("--modal-confirm-btn-bg-color-hover", this.hexToRgba(this.confirmButtonBgColor));
  },
  methods: {
    /**
     * @description: 将hex转为rgba并设置0.8的透明度
     * @param {String} hex hex颜色
     * @param {Number} opacity 透明度 0.8
     * @return {String} 给定透明度的rgba颜色
     */
    hexToRgba(hex, opacity = 0.8) {
      return `rgba(${parseInt('0x' + hex.slice(1, 3))},${parseInt('0x' + hex.slice(3, 5))},${parseInt('0x' + hex.slice(5, 7))},${opacity})`;
    },
    /**
     * @description: 点击遮罩层, loading 时不关闭弹窗
     * @return {void}
     */
    handleClickModal() {
      if (this.loading) {
        return
      }
      this.$emit("close");
    },
    /**
     * @description: 点击确定按钮
     * @return {void}
     */
    handleConfirm() {
      this.loading = true;
      this.$emit("confirm");
    },
    /**
     * @description: 获取文本元素宽度
     * @param {String} text 文本
     * @param {String} id 文本id
     * @return {Number} 文本元素宽度
     */
    getElementWidth(text, id = "virtualSpan") {
      let el = document.createElement("span");
      el.setAttribute('id', id);
      el.innerHTML = text;
      el.style.setProperty('font-size', "14px");
      el.style.setProperty('font-weight', 500);
      el.style.setProperty('height', 0);
      el.style.setProperty('padding', 0);
      el.style.setProperty('border', "none");
      el.style.setProperty('margin', 0);
      document.body.appendChild(el);
      let el2 = document.getElementById(id);
      const width = Math.ceil(parseInt(el2.getBoundingClientRect().width * 1000) / 10) / 100;
      document.body.removeChild(el);
      return width
    },
  }
}
</script>

<style lang="scss" scoped>
.jh-modal {
  position: absolute;
  z-index: 6666;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, .5);

  .jh-modal__content {
    position: relative;
    top: 50%;
    transform: translateY(-50%);
    width: 420px;
    border-radius: 10px;
    margin: 0 auto;
    background-color: #fff;

    .jh-modal__closebtn {
      position: absolute;
      top: 20px;
      right: 20px;
      font-size: 20px;
      color: #c4c4c4;
      cursor: pointer;

      &.disabled {
        cursor: not-allowed;
        color: #e2e2e2;
      }

      &:not(.disabled):hover {
        color: var(--color-primary);
      }
    }

    .jh-modal__body {
      box-sizing: border-box;
      min-height: 160px;
      padding: 56px 36px 20px;

      .jh-modal__title {
        font-size: 22px;
        font-weight: 700;
        padding: 0 16px 16px;
        text-align: center;
      }

      .jh-modal__message {
        font-size: 14px;
        font-weight: 500;
        color: #8997b0;
        word-break: break-all;

        &.text-center {
          text-align: center;
        }
      }
    }

    .jh-modal__footer {
      height: 90px;
      border-radius: 0 0 10px 10px;
      background-color: #f5f7fa;
      display: flex;
      align-items: center;
      justify-content: center;

      .el-button {
        min-width: 112px;
        border-radius: 5px;
      }

      .el-button+.el-button {
        margin-left: 16px;
      }

      .jh-modal__footer-btn {
        border-color: var(--modal-confirm-btn-bg-color);
        background-color: var(--modal-confirm-btn-bg-color);

        &:hover {
          border-color: var(--modal-confirm-btn-bg-color-hover);
          background-color: var(--modal-confirm-btn-bg-color-hover);
        }
      }
    }
  }
}
</style>