<template>
  <el-drawer
    :visible.sync="dialogVisible"
    :direction="direction"
    :modal="false"
    :size="735"
    class="drawer-box"
    append-to-body
  >
    <div slot="title">
      <div class="title">
        <span class="flabel">{{ activeColumn.columnName }}</span>
      </div>
    </div>
    <div class="fd-main">
      <div
        class="preview-detail"
        v-if="dialogVisible"
        element-loading-background="rgba(40, 47, 57,0.8)"
      >
        <div class="detail-main">
          <div v-html="richText"></div>
        </div>
      </div>
    </div>
  </el-drawer>
</template>

<script>
import { ProductLibrary } from "../../../../api/ProductLibrary";
export default {
  props: {
    activeColumn: Object,
    visible: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      richText: "",
      clickitem: {},
      direction: "rtl",
    };
  },
  computed: {
    dialogVisible: {
      get() {
        return this.visible;
      },
      set(val) {
        this.$emit("update:visible", val);
      },
    },
  },
  watch: {
    activeColumn() {
      this.getDetail(this.activeColumn);
    },
  },
  mounted() {
    if (this.activeColumn) {
      this.getDetail(this.activeColumn);
    }
  },
  methods: {
    /**
     * @description: 预览介绍
     * @param {Object} value 介绍id
     * @return {void}
     */
    async getDetail(value) {
      this.clickitem = value;
      const res = await ProductLibrary.getSettingSubjectColumnContent(value.id);
      this.richText = res[0].content;
    },
  },
};
</script>
<style lang="scss" scoped>
@import "~@/scss/common.scss";

.preview-detail {
  height: 90vh;
  width: 100%;
  z-index: 12;
  display: flex;
  padding: 20px;
  // color: #fff;
  box-sizing: border-box;
  border-radius: 5px;

  .detail-main {
    height: 100%;
    width: 100%;
    position: relative;
    background-color: #fff;
    overflow-y: auto;
  }
}
</style>
