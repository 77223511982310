export abstract class KeyValuePair<T>{
    public key: string;
    public value: T;
    public type?: 'normal' | 'user';

    constructor(key: string, value: T) {
        this.key = key;
        this.value = value;
    }
}

export class KeyStrValuePair extends KeyValuePair<string>{

}