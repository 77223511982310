<template>
  <ElTableColumn
    type=""
    :width="SELECTION_COLUMN_WIDTH"
    fixed
    :class-name="'single-selection-column'"
  >
    <template v-slot:default="scope">
      <!-- {{ printScope(scope) }} -->
      <ElRadio
        v-model="selectedRowId"
        :label="scope.row.id"
        @change="onSelectionChange(scope.row)"
      ></ElRadio>
    </template>
  </ElTableColumn>
</template>
<script lang="ts">
import Vue, { PropType } from "vue";
import { TableColumn as ElTableColumn, Radio as ElRadio } from "element-ui";
import { IBaseTableData } from "@/flexible-table-module/entity/Table/IBaseTableData";
import { SELECTION_COLUMN_WIDTH } from "@/flexible-table-module/constants";

export default Vue.extend({
  inheritAttrs: false,
  name: "SingleSelectionColumn",
  components: { ElTableColumn, ElRadio },
  props: {
    isSelectionReserved: { type: Boolean, default: false }, // 换页时，是否保持之前的选择
  },
  data(): {
    selectedRowId: string | undefined;
    SELECTION_COLUMN_WIDTH: number;
  } {
    return {
      // selectedRow: undefined, // 当前选中的行
      selectedRowId: undefined, // 当前选中的行的id
      SELECTION_COLUMN_WIDTH,
    };
  },
  methods: {
    // 选择行(供外部调用)
    $select(row: IBaseTableData, selected: boolean = true) {
      if (!row.id) {
        console.error("当单选时，数据行的 id 是必须的");
        return;
      }

      if (selected) {
        this.selectedRowId = row.id;
      } else {
        this.selectedRowId = undefined;
      }
      this.$emit("selection-change", row);
    },
    // 选择被用户操作改变
    onSelectionChange(row: IBaseTableData) {
      if (!row.id) {
        console.error("当单选时，数据行的 id 是必须的");
        return;
      }

      this.selectedRowId = row.id;
      this.$emit("selection-change", row);
    },

    printScope(scope: any) {
      console.log(scope);
    },
  },
});
</script>
<style lang="scss" scoped>
.el-radio {
  ::v-deep {
    .el-radio__label {
      display: none;
    }
  }
}
</style>

<style lang="scss">
.single-selection-column {
  .cell {
    justify-content: center !important;
  }
}
</style>