// import { IBaseTableData } from "./IBaseTableData";

/** 排序单元 */
export class Sort {
    /** 字段名 */
    public columnId: string;
    /** 排序字段(传给后端) */
    public sortName: string;
    /** 排序方式 */
    public order: "asc" | "desc";

    constructor(params: {
        columnId: string,
        sortName: string,
        order: "asc" | "desc",
    }) {
        const { columnId, sortName, order } = params;
        this.columnId = columnId;
        this.sortName = sortName;
        this.order = order;
    }
}