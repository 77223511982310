var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"scheme-edit-dialog"},[_c('div',{staticClass:"scheme-edit-main"},[_c('div',{staticClass:"preview demo w-h-100"},[_c('div',{staticClass:"w-100 app-edit-header"},[_c('div',{staticClass:"header-firstfloor flex align-items-center"},[_c('div',{staticClass:"w-h-100 flex align-items-center"},[_c('div',{staticClass:"header-title flex align-items-center top-left-name"},[_c('img',{staticStyle:{"width":"37px","height":"22px","margin-right":"9px"},attrs:{"src":_vm.subject.coverList[0] && _vm.subject.coverList[0].coverUrl,"alt":""}}),(_vm.subject)?_c('div',{staticClass:"one-line top-name"},[_vm._v(" "+_vm._s(_vm.subject.subjectName)+" ")]):_vm._e()]),(_vm.activeColumn || _vm.isfile)?_c('div',{staticClass:"header-columns flex align-items-center",attrs:{"id":"columntabs"}},[_c('div',{staticClass:"header-columns-fixed-left",class:{ 'header-columns-fixed-left-active': _vm.activeColumnIndex == 0 }}),_vm._l((_vm.columnList),function(i,index){return _c('div',{key:i.id,staticClass:"header-columns-item header-columns-item-normal",class:{
                  'header-columns-item-first': index == 0,
                  'header-columns-item-active':
                    _vm.activeColumn && i.id === _vm.activeColumn.id,
                  'header-columns-item-between-left':
                    (_vm.activeColumnIndex == 0 && index == _vm.activeColumnIndex + 1) ||
                    (_vm.activeColumnIndex && index == _vm.activeColumnIndex + 1),
                  'header-columns-item-between-right':
                    _vm.activeColumnIndex && index == _vm.activeColumnIndex - 1,
                },on:{"click":function($event){return _vm.changeColumn(i, index)}}},[_c('div',{staticClass:"child one-line",attrs:{"title":i.columnName}},[_c('span',[_vm._v(_vm._s(i.columnName))])])])}),_c('div',{staticClass:"header-columns-item header-columns-item-normal exclude-sort",class:{
                  'header-columns-item-first': _vm.columnList.length == 0,
                  'header-columns-item-active': _vm.isfile,
                  'header-columns-item-between-left':
                    !_vm.isfile &&
                    _vm.activeColumnIndex >= 0 &&
                    _vm.columnList.length == _vm.activeColumnIndex + 1,
                  'header-columns-item-between-right':
                    !_vm.isfile &&
                    _vm.activeColumnIndex >= 0 &&
                    _vm.columnList.length == _vm.activeColumnIndex - 1,
                },on:{"click":_vm.handleFile}},[_c('div',{staticClass:"child one-line",attrs:{"title":_vm.subject ? _vm.subject.moduleName + '文件' : '文件'}},[_vm._v(" "+_vm._s(_vm.subject ? _vm.subject.moduleName : "")+"文件 ")])]),_c('div',{staticClass:"header-columns-fixed-left",class:{
                  'header-columns-fixed-right-active':
                    _vm.activeColumnIndex == _vm.columnList.length,
                }})],2):_vm._e()]),_c('div',{staticClass:"fixed-oprate flex-between align-items-center"},[_c('div',{staticClass:"preview-right-button flex flex-between"},[_c('div',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.confirmLoading),expression:"confirmLoading"}],attrs:{"type":"primary","disabled":_vm.columnList.length === 0,"element-loading-background":"rgba(255,255,255,0.3)"},on:{"click":function($event){_vm.dialogVisible = false}}},[_vm._v(" 取消 ")]),_c('div',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.confirmLoading),expression:"confirmLoading"}],attrs:{"type":"primary","disabled":_vm.columnList.length === 0,"element-loading-background":"rgba(255,255,255,0.3)"},on:{"click":_vm.handleSave}},[_vm._v(" 保存 ")])])])]),_c('div',{staticClass:"header-interlayer"})]),_c('div',{staticClass:"preview-main"},[(
            _vm.activeColumn &&
            _vm.columnCategoryTypeDict[_vm.activeColumn.columnCategoryId] === '5'
          )?_c('ArticleSelect',{attrs:{"active-column":_vm.activeColumn,"subject":_vm.subject,"alreadychose":_vm.queryData.filter((item) => item.subjectColumnId == _vm.activeColumn.id),"scheme":_vm.scheme},on:{"handleselect":_vm.handleselect}}):_vm._e(),(
            _vm.activeColumn &&
            _vm.columnCategoryTypeDict[_vm.activeColumn.columnCategoryId] === '4'
          )?_c('LinkSelect',{attrs:{"active-column":_vm.activeColumn,"subject":_vm.subject,"alreadychose":_vm.queryData.filter((item) => item.subjectColumnId == _vm.activeColumn.id),"scheme":_vm.scheme},on:{"handleselect":_vm.handleselect}}):_vm._e(),(
            _vm.activeColumn &&
            _vm.columnCategoryTypeDict[_vm.activeColumn.columnCategoryId] === '1'
          )?_c('ContentSelect',{attrs:{"active-column":_vm.activeColumn,"subject":_vm.subject,"alreadychose":_vm.queryData.filter((item) => item.subjectColumnId == _vm.activeColumn.id),"scheme":_vm.scheme},on:{"handleselect":_vm.handleselect}}):_vm._e(),(
            _vm.activeColumn &&
            _vm.columnCategoryTypeDict[_vm.activeColumn.columnCategoryId] === '3'
          )?_c('ProductSelect',{attrs:{"active-column":_vm.activeColumn,"subject":_vm.subject,"alreadychose":_vm.queryData.filter((item) => item.subjectColumnId == _vm.activeColumn.id),"scheme":_vm.scheme},on:{"handleselect":_vm.handleselect}}):_vm._e(),(_vm.isfile && !_vm.activeColumn)?_c('FileSelect',{attrs:{"subject":_vm.subject,"alreadychose":_vm.queryData.filter((item) => item && item.dataType == 2),"scheme":_vm.scheme,"temporaryfile":_vm.temporaryfile},on:{"handleselect":_vm.handleselect,"handlesavetemporaryselect":_vm.handleSavetemporaryselect}}):_vm._e()],1)])])])
}
var staticRenderFns = []

export { render, staticRenderFns }