<template>
  <ElTableColumn
    class-name="last-col"
    fixed="right"
    label="操作"
    :width="width"
    :header-align="OPERATION_COLUMN_TITLE_ALIGN"
    :type="''"
  >
    <!-- 当第一列的原生树箭头被隐藏时，本列会出现树箭头，故要隐藏 -->
    <template v-slot:header>
      <slot name="header"></slot>
    </template>
    <template v-slot="{ row }">
      <slot name="operation" v-bind="{ row }"></slot>
    </template>
  </ElTableColumn>
</template>
<script lang="ts">
import Vue from "vue";
import { TableColumn as ElTableColumn } from "element-ui";
import { OPERATION_COLUMN_TITLE_ALIGN } from "@/flexible-table-module/constants";

export default Vue.extend({
  inheritAttrs: false,
  name: "ContainerForLastCol",
  components: { ElTableColumn },
  props: {
    width: { type: Number, required: true },
  },
  data() {
    return {
      OPERATION_COLUMN_TITLE_ALIGN,
    };
  },
  methods: {},
});
</script>

<style lang="scss" scoped>
.operation-header {
  height: 24px;
  line-height: 24px;
}
</style>