<template>
  <div
    class="product-association app-scheme-wrapper"
    @drop="handleDrop"
    @dragover="allowDrop"
  >
    <!-- 文章列表，暂时不需要了 -->
    <!-- <div class="app-ass-article-left">
      <div class="ass-article-title flex-between align-items-center">
        <div class="ass-article-title-name">产品文章列表</div>
      </div>
      <div class="ass-article-title-list" id="articletabs">
        <div
          v-for="i in assarticleList"
          :key="i.id"
          @click="handleClick(i.articleId)"
          class="flex ass-article-title-list-items pointer align-items-center"
          :class="{
            'active-article': currentItem && currentItem.article.id == i.articleId,
          }"
        >
          <svg-icon
            v-if="!ispre"
            class="toolbar-move"
            icon="move-icon"
            :height="16"
            :width="16"
          ></svg-icon>
          <div class="w-100">
            <div class="flex w-100">
              <svg-icon
                class="scheme-items-icon"
                icon="article-icon"
                :height="16"
                :width="16"
                color="#344563"
              ></svg-icon>
              <div class="scheme-items-name one-line">{{ i.name ? i.name : "文章" }}</div>
            </div>
            <div class="scheme-items-time">
              更新于：{{ i.createAt ? i.createAt : "" }}
            </div>
          </div>
          <el-popover
            v-if="!ispre"
            popper-class="toolbar-item-popover popover"
            placement="right-start"
            width="80"
            trigger="click"
            :ref="'toolbar-' + i.id"
            :visible-arrow="false"
          >
            <div class="toolbar-more" slot="reference" @click.stop></div>
            <div class="popover-item" @click="handleDelete(i)">删除</div>
          </el-popover>
        </div>
      </div>
    </div> -->
    <div class="association-preview">
      <UpanddownAround
        v-if="currentItem && currentItem.article.templateType == '2'"
        :current="currentItem"
        :readonly="true"
      />
      <LayoutAround v-else :current="currentItem" :readonly="true" />
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { Playeditor } from "@/api/Playeditor";
import { ProductLibrary } from "../../../api/ProductLibrary";
import { Article } from "../../../api/Article";
import Sortable from "sortablejs";
import UpanddownAround from "../../ArticleView/template/UpanddownAround.vue";
import LayoutAround from "../../ArticleView/template/LayoutAround.vue";
export default {
  components: { UpanddownAround, LayoutAround },
  props: {
    activeColumn: Object,
    subject: Object,
    activeNode: Object,
    ispre: {
      type: Boolean,
      default: false,
    },
    nodeid: String,
    demoId: String,
  },
  data() {
    return {
      dialogVisible: false,
      assarticleList: [],
      currentItem: null,
      selectids: [], //已经选择的文章id合集
    };
  },
  computed: {
    ...mapGetters(["seleceassData"]),
  },
  watch: {
    nodeid() {
      this.getNodeDetail();
      if (this.activeNode.data.dataType == 1) {
        this.$Pubsub.publish("update-resource-visible", { visible: false, type: [] });
      } else {
        this.$Pubsub.publish("update-resource-visible", {
          visible: true,
          type: [5],
        });
      }
    },
    seleceassData(val) {
      if (val) {
        this.addArticle(val);
      }
    },
  },
  mounted() {
    this.getNodeDetail();
    // this.$nextTick(() => {
    //   if (!this.ispre) {
    //     this.handleSort();
    //   }
    // });
    // this.$Pubsub.publish("update-resource-visible", { visible: true, type: [5] });
    if (this.activeNode.data.dataType == 1) {
      this.$Pubsub.publish("update-resource-visible", { visible: false, type: [] });
    } else {
      this.$Pubsub.publish("update-resource-visible", {
        visible: true,
        type: [5],
      });
    }
  },
  methods: {
    /**
     * @description: 获取节点关联详情
     * @return {void}
     */
    async getNodeDetail() {
      const res = await Playeditor.querySubjectDemoModeNodeDetail(
        this.demoId,
        this.nodeid,
        3
      );
      if (res.length) {
        this.assarticleList = _.sortBy(res, "sortNo");
        this.handleClick(this.assarticleList[0].articleId);
      } else {
        this.assarticleList = [];
        this.currentItem = null;
      }
      this.selectids = this.assarticleList.map((item) => item.articleId);
      this.$Pubsub.publish("update-resource-select-data", this.selectids);
    },
    /**
     * @description: 预览文章
     * @param {Object} value 文章id
     * @return {void}
     */
    async handleClick(id) {
      if (this.currentItem && this.currentItem.article.id == id) {
        return;
      }
      const res = await Article.queryArticleDetail(id);
      res && (this.currentItem = res);
    },
    /**
     * @description: 删除
     * @param {Object} value
     * @return {void}
     */
    handleDelete(value) {
      this.$showMessage(
        {
          message: `是否确认删除关联文章 <span style="font-weight: 1000;">${value.name}</span> ?`,
          ...this.$global.deleteMessage,
        },
        async (done) => {
          const res = await Playeditor.subjectDemoModeToResource({
            outlineToResourceId: value.id,
          });
          res && this.getNodeDetail();
          done(true);
        }
      );
    },
    /**
     * @description: 添加拖拽过来的文章
     * @return {void}
     */
    async handleDrop(event) {
      if (this.ispre) {
        return;
      }
      event.preventDefault();
      const article = JSON.parse(event.dataTransfer.getData("dragsubject"));
      this.addArticle(article);
    },
    /**
     * @description: 添加文章
     * @return {void}
     */
    async addArticle(article) {
      if (this.ispre) {
        return;
      }
      let payload = {
        saveOutlineToResource: {
          articleId: article.id,
          createAt: article.createAt,
          name: article.name,
          outlineNodeId: this.nodeid,
          type: 3,
        },
      };
      const res = await Playeditor.subjectDemoModeToResource(payload);
      if (res) {
        this.getNodeDetail();
        this.handleClick(article.id);
        if (this.assarticleList.length || this.activeNode.data.dataType == 2) {
          this.$Pubsub.publish("update-mind-data", this.nodeid);
          this.getNodeDetail();
        } else {
          this.$Pubsub.publish("update-mind-name-data", {
            id: this.nodeid,
            name: payload.saveOutlineToResource.name + `(文章)`,
          });
        }
      }
    },
    // 拖拽事件
    allowDrop(event) {
      // 如果为默认创建的节点就不能再添加文章
      if (this.activeNode.data.dataType == 1) {
        return;
      }
      event.preventDefault();
    },
    /**
     * @description:拖拽排序
     * @return {void}
     */
    handleSort() {
      var that = this;
      var el = document.getElementById("articletabs");

      // 常用
      new Sortable(el, {
        filter: ".w-100 ,.toolbar-more",
        onEnd: function (evt) {
          that.sortData = that.assarticleList[evt.oldIndex];
          that.Sort(that.sortData.id, evt.newIndex);
          // 获取排序之后的data数据
          that.assarticleList.splice(
            evt.newIndex,
            0,
            that.assarticleList.splice(evt.oldIndex, 1)[0]
          );
          var newArray = that.assarticleList.slice(0);
          newArray.forEach((item, index) => {
            item.sortNo = index;
          });
          that.assarticleList = [];
          that.$nextTick(function () {
            that.assarticleList = newArray;
          });
        },
      });
    },
    async Sort(id, index) {
      await Playeditor.moveDemoModeToResource({
        id: id,
        sortNo: index + 1,
        type: 3,
      });
    },
    /**
     * @description:导入文章
     * @return {void}
     */
    async handleImport(id) {
      const res = await ProductLibrary.saveSubjectToArticle({
        articleIds: [id],
        columnId: this.activeColumn.id,
        subjectId: this.subject.id,
      });
      if (res) {
        this.getList();
        this.handleClick(id);
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.product-association {
  display: flex;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  // padding: 1px 0;
  background-color: #fff;
  overflow: hidden;

  .app-ass-article-left {
    width: 322px;
    height: 100%;
    border-right: 1px solid #ebeef2;
    flex-shrink: 0;

    .ass-article-title {
      height: 30px;
      border-bottom: 1px solid #d3dbeb;
      box-sizing: border-box;
      .ass-article-title-name {
        margin-left: 14px;
        font-size: 12px;
        font-weight: 400;
      }
    }

    .ass-article-title-list {
      height: calc(100% - 31px);
      width: 100%;
      // padding: 0 28px;
      box-sizing: border-box;
      overflow-y: auto;

      .ass-article-title-list-items {
        padding: 0 28px;
        box-sizing: border-box;
        width: 100%;
        height: 68px;
        position: relative;
        font-size: 12px;
        line-height: 24px;
        &::after {
          position: absolute;
          content: "";
          width: 268px;
          height: 1px;
          background-color: #d3dbeb;
          bottom: 0;
        }
        &:hover {
          background-color: #f7f9fc;

          .toolbar-more,
          .toolbar-move {
            display: block;
          }
        }

        .toolbar-more {
          display: none;
          position: absolute;
          top: 50%;
          right: 28px;
          width: 12px;
          height: 12px;
          transform: translateY(-50%);
          background-image: url("@/assets/images/common/more_vertical.png");
          background-size: 12px 12px;
          background-repeat: no-repeat;
        }

        .toolbar-move {
          display: none;
          cursor: move;
          position: absolute;
          top: 50%;
          left: 2px;
          width: 12px;
          height: 12px;
          transform: translateY(-50%);
        }

        .scheme-items-icon {
          margin-top: 3px;
          flex-shrink: 0;
        }

        .scheme-items-icon-default {
          width: 30px;
          height: 18px;
          margin-left: 10px;
          flex-shrink: 0;
          text-align: center;
          line-height: 18px;
          background-color: #ebeef2;
          font-size: 12px;
          color: #5e6c84;
        }

        .scheme-items-name {
          margin-left: 6px;
          position: relative;
        }
        .scheme-items-time {
          color: #a7b4cc;
        }
      }
      .active-article {
        color: var(--color-primary);
        .svg-icon {
          color: var(--color-primary) !important;
        }
      }

      .items-active {
        border: 1px solid #5e6c84;
      }
    }

    .scheme-left-outline {
      height: calc(100% - 31px);
      width: 100%;
    }
  }

  .association-preview {
    flex: 1;
    overflow-y: auto;
  }
}

.active-color {
  color: var(--color-primary);
}
</style>
