<template>
  <!-- 左右布局 -->
  <div class="web-page">
    <div class="web-page-top flex" v-if="current">
      <img
        :src="
          current.article.coverList[0]
            ? current.article.coverList[0].coverUrl
            : require('@/assets/images/sample-1.png')
        "
        alt=""
      />
      <div class="top-text">
        <h2>{{ current.article.articleName }}</h2>
        <div>
          {{ current.article.introduce }}
        </div>
      </div>
    </div>
    <div class="web-page-main2" v-if="current">
      <div class="web-page-main2-left">
        <h3 class="title-text">产品简介</h3>
        <p class="title-underline"></p>
        <div v-html="current.article.detailContent" class="web-page-main2-richtext"></div>
      </div>
      <template v-if="loginrequired">
        <div
          class="web-page-main2-right"
          v-if="
            (current.articleToSubjects && current.articleToSubjects.length) ||
            (current.articleToArticles && current.articleToArticles.length)
          "
        >
          <div v-if="current.articleToSubjects && current.articleToSubjects.length">
            <h3 class="title-text">相关产品</h3>
            <p class="title-underline"></p>
            <div class="web-page-main2-relevant">
              <div
                class="relavant-card"
                v-for="item in current.articleToSubjects"
                @click="handleClickSubject(item)"
                :key="item.id"
              >
                <img
                  style="width: 100%"
                  :src="
                    item.coverList && item.subjectCoverVos[0].coverUrl
                      ? item.subjectCoverVos[0].coverUrl
                      : require(`@/assets/images/sample-${common.getLastChar(
                          item.subjectId
                        )}.png`)
                  "
                  alt=""
                />
                <div class="one-line">{{ item.subjectName }}</div>
              </div>
            </div>
          </div>
          <div v-if="current.articleToArticles && current.articleToArticles.length">
            <h3 class="title-text">相关文章</h3>
            <p class="title-underline"></p>
            <div class="web-page-main2-relevant">
              <div
                class="relavant-card"
                v-for="item in current.articleToArticles"
                :key="item.id"
                @click="handleClickArticle(item)"
              >
                <img
                  style="width: 100%"
                  :src="
                    item.coverList && item.coverList[0].coverUrl
                      ? item.coverList[0].coverUrl
                      : '@/assets/images/sample-1.png'
                  "
                  alt=""
                />
                <div class="one-line">{{ item.toArticleName }}</div>
              </div>
            </div>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>
<script>
import { ProductLibrary } from "../../../api/ProductLibrary";

export default {
  props: {
    current: Object,
    readonly: {
      type: Boolean,
      default: false,
    },
    loginrequired: {
      type: Boolean,
      default: true, //是否需要登录
    },
  },
  data() {
    return {
      currentItem: null,
    };
  },
  watch: {},
  mounted() {},
  methods: {
    /**
     * @description: 点击主题
     * @param {Object} value 点击项
     * @return {void}
     */
    async handleClickSubject(value) {
      if (this.readonly) {
        return;
      }
      const payload = {
        subjectId: value.subjectId,
        mirrorId: value.mirrorId,
        versionId: value.versionId,
      };
      // 预览展示方案
      const routeData = this.$router.resolve({
        path: "/column-preview",
        query: payload,
      });
      window.open(routeData.href);
    },
    /**
     * @description: 点击文章
     * @param {Object} value 点击项
     * @return {void}
     */
    handleClickArticle(value) {
      if (this.readonly) {
        return;
      }
      // this.$router.push({
      //   path: `/articlepreview/${value.toArticleId}`,
      //   query: {
      //     preveiew: true,
      //   },
      // });
      const routeData = this.$router.resolve({
        path: `/articlepreview/${value.toArticleId}`,
        query: {
          preveiew: true,
        },
      });
      window.open(routeData.href);
    },
  },
};
</script>
<style lang="scss">
.web-page {
  height: 100%;
  width: 100%;
  padding: 20px;
  box-sizing: border-box;
  overflow-y: auto;
  .web-page-top {
    margin: 0 auto;

    img {
      // width: 30%;
      height: 150px;
      width: 200px;
      margin-right: 20px;
      border-radius: 5px;
    }

    .top-text {
      // width: 50%;
    }
  }

  .web-page-main2 {
    padding-bottom: 40px;
    box-sizing: border-box;
    display: flex;
    overflow: hidden;

    .web-page-main2-left {
      // width: 70%;
      flex: 1;
      // border-right: 1px solid #ccc;
    }

    .web-page-main2-right {
      // width: 30%;
      width: 400px;
    }

    .title-text {
      text-align: center;
    }

    .title-underline {
      width: 42px;
      height: 2px;
      margin: 0 auto;
      border-bottom: 3px solid #1f65e7;
    }

    .web-page-main2-richtext {
      margin-top: 30px;
    }

    .web-page-main2-relevant {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      padding: 16px 16px;
      .relavant-card {
        width: 48%;
        box-shadow: 0px 0px 13px -2px #ccc;
        border-radius: 5px;
        cursor: pointer;
        margin-bottom: 20px;
        div {
          padding: 20px 0 20px 10px;
          font-weight: 500;
        }
      }
    }
  }
}
</style>
