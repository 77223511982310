var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{directives:[{name:"loading",rawName:"v-loading",value:(!_vm.data),expression:"!data"}],staticClass:"data-overview-board",style:(_vm.rootStyle)},[(!_vm.isFolded)?_c('div',{staticClass:"btn-fold-area",style:({
      width: `${_vm.btnFoldSize}px`,
      height: `${_vm.btnFoldSize}px`,
      top: `${_vm.containerPadding}px`,
      right: `${_vm.containerPadding}px`,
    })},[_c('div',{staticClass:"btn",on:{"click":_vm.onBtnFoldClick}},[_c('svg-icon',{staticClass:"svg-icon",attrs:{"sys":"common-table","name":"DataOverview/fold","size":_vm.btnFoldSize}})],1)]):_vm._e(),(_vm.isFolded)?_c('div',{staticClass:"btn-unfold-area",style:({
      width: `${_vm.btnFoldSize}px`,
      height: `${_vm.btnFoldSize}px`,
      top: `${_vm.containerPadding}px`,
      right: `${_vm.containerPadding}px`,
    })},[_c('div',{staticClass:"btn",attrs:{"type":"text"},on:{"click":_vm.onBtnFoldClick}},[_c('svg-icon',{staticClass:"svg-icon",attrs:{"sys":"common-table","name":"DataOverview/unfold","size":_vm.btnFoldSize}})],1)]):_vm._e(),_c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.isFolded),expression:"!isFolded"}],staticClass:"inner"},[_c('div',{staticClass:"header"},[_vm._m(0),_c('div',{staticClass:"setting"},[_c('DateFramePicker',{on:{"date-frame-change":_vm.onDateFrameChange},model:{value:(_vm.cTimeFrame),callback:function ($$v) {_vm.cTimeFrame=$$v},expression:"cTimeFrame"}}),_c('ElButton',{attrs:{"type":"text"},on:{"click":_vm.onBtnSettingClick}},[_vm._v("配置")])],1)]),_c('div',{staticClass:"body"},[_vm._l((_vm.activeItems),function(activeItem){return [_c('OverviewUnit',{key:activeItem.id,attrs:{"title":activeItem.name,"number":activeItem.value,"color":activeItem.color},on:{"unit-click":function($event){return _vm.onUnitClick(activeItem)}}})]})],2)]),_c('DialogSetting',{attrs:{"visible":_vm.dialogSettingVisible,"data":_vm.dialSettingData},on:{"update:visible":function($event){_vm.dialogSettingVisible=$event},"dialog-setting-show":_vm.onDialogSettingShow,"default-fold-change":_vm.onDefaultFoldChange,"item-active-change":_vm.onItemActiveChange,"setting-change":_vm.onSettingChange}}),_c('DialogDetail',{attrs:{"visible":_vm.dialogDetailVisible,"dataOverviewItem":_vm.clickedItem,"suffix":_vm.dialDetailSuffix},on:{"update:visible":function($event){_vm.dialogDetailVisible=$event}}},[_vm._t("default")],2)],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"title"},[_c('span',[_vm._v("数据概览")])])
}]

export { render, staticRenderFns }