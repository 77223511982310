import request from "@/utils/appServer";
import common from "../utils/common"
class Netdisk {
  // 文件资源-创建文件目录
  static async createResourceFileFolder(data) {
    try {
      const res =  await request.post('/sales-show-4.0/file/createResourceFileFolder', data);
      return res.data.code === 200;
    } catch (error) {
      return false;
    }
  }
  // 文件资源-上传文件
  static async uploadResourceFile(data) {
    try {
      const res =  await request.post('/sales-show-4.0/file/uploadResourceFile', data);
      return res.data.code === 200;
    } catch (error) {
      return false;
    }
  }
  // 文件类栏目-文件分片上传
  static async uploadResourceFileSplit(data,options) {
    try {
      const res = await request.post('/sales-show-4.0/file/uploadResourceFileSplit', data,false, false, options);
      return res.data.code === 200 && res.data.result ? res.data.result : [];
    } catch (error) {
      return [];
    }
  }
  // 文件资源-获取分类列表
  static async queryFileResourceAttributeList(moduleId) {
    try {
      const res =  await request.get('/sales-show-4.0/file/queryFileResourceAttributeList', { params: { moduleId } });
      return res.data.code === 200 && res.data.result ? res.data.result : [];
    } catch (error) {
      return [];
    }
  }
  // 文件资源-获取分类下文件列表
  static async queryFileResourceByCategory(data) {
    try {
      const res =  await request.get('/sales-show-4.0/file/queryFileResourceByCategory', { params: data });
      return res.data.code === 200 && res.data.result ? res.data.result : [];
    } catch (error) {
      return [];
    }
  }
  // 批量删除文件和文件夹
  static async deleteFileBatch(data) {
    try {
      const res =  await request.delete('/sales-show-4.0/file/deleteBatch', data );
      return res.data.code === 200 ;
    } catch (error) {
      return false;
    }
  }
  // 文件资源-批量设置属性
  static async batchSetFileAttribute(data) {
    try {
      const res = await request.post('/sales-show-4.0/file/batchSetFileAttribute', data);
      return res.data.code === 200 && res.data.result ? res.data.result : [];
    } catch (error) {
      return [];
    }
  }
  // 文件资源-获取文件属性详情
  static async getResourceFileAttributeDetail(data) {
    try {
      const res =  await request.get('/sales-show-4.0/file/getResourceFileAttributeDetail', { params: data });
      return res.data.code === 200 && res.data.result ? res.data.result : [];
    } catch (error) {
      return [];
    }
  }
  // 文件资源-获取文件夹属性详情
  static async getResourceFolderAttributeDetail(data) {
    try {
      const res =  await request.get('/sales-show-4.0/file/getResourceFolderAttributeDetail', { params: data });
      return res.data.code === 200 && res.data.result ? res.data.result : [];
    } catch (error) {
      return [];
    }
  }
  // 文件资源-批量设置属性列表
  static async queryFileBatchAttribute(data) {
    try {
      const res =  await request.get('/sales-show-4.0/file/queryFileBatchAttribute', { params: data });
      return res.data.code === 200 && res.data.result ? res.data.result : [];
    } catch (error) {
      return [];
    }
  }
}

export { Netdisk }