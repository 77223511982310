const back = {
  state: {
    backmenuList: [],
    activeBackMenu: null,//后台管理当前菜单
    activeBacknormalMenu: null,//后台设置当前菜单
    avatarUrl:null
  },
  getters: {
    activeBackMenu: state => state.activeBackMenu,
    activeBacknormalMenu: state => state.activeBacknormalMenu,
    backmenuList: state => state.backmenuList,
    avatarUrl: state => state.avatarUrl,
  },
  mutations: {
    savebackMenuList(state, payload = []) {
      state.backmenuList = payload;
    },
    saveActiveBackMenu(state, payload = null) {
      state.activeBackMenu = payload;
    },
    saveactiveBacknormalMenu(state, payload = null) {
      state.activeBacknormalMenu = payload;
    },
    saveAvatarUrl(state, payload = null) {
      state.avatarUrl = payload;
    },
  }
};
export default back