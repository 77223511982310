<template>
  <el-drawer :visible.sync="dialogVisible" :direction="direction" :modal="false" :size="735" class="drawer-box">
    <!-- <div slot="title">
      <div class="title">
        <span class="flabel">{{ subject.name }}&nbsp;文件</span>
      </div>
    </div> -->
    <div class="fd-main">
      <div class="preview-detail" v-if="dialogVisible" element-loading-background="rgba(40, 47, 57,0.8)">
        <div class="detail-main">
          <div class="file-card">
            <div class="file-search flex-between align-items-center" style="width:100%">
              <el-input ref="searchInput" v-model="queryFile.name" clearable placeholder="请输入关键字搜索" size="small"
                style="width: 168px;margin-right:14px" @keyup.enter.native="getTableData(1)" @clear="handleClear">
                <i slot="prefix" class="el-input__icon el-icon-search" @click="getTableData(1)"></i>
              </el-input>
              <div class="flex file-tool align-items-center">
                <!-- <div class="file-tool-item flex align-items-center" @click="folderDialogVisible = true">
                  <svg-icon icon="file-folder-16" color="#344563" width="16" height="16"></svg-icon>
                  <span>新建文件夹</span>
                </div>
                <div class="file-tool-item flex align-items-center">
                  <svg-icon icon="file-upload-16" color="#344563" width="16" height="16"></svg-icon>
                  <UploadFile :folderid="queryFile.folderId" :subject="subject" :isPersonal="false" :text="'上传文件'"
                    @finish="updateTableData"></UploadFile>
                </div>
                <div class="file-tool-item flex align-items-center" @click="handeShare">
                  <svg-icon icon="file-share-16" color="#344563" width="16" height="16"></svg-icon>
                  <span>分享</span>
                </div>
                <div class="file-tool-item flex align-items-center" @click="handleDownload" v-if="viewType === 'grid'">
                  <svg-icon icon="file-download-16" color="#344563" width="16" height="16"></svg-icon>
                  <span>下载</span>
                </div>
                <div class="file-tool-item flex align-items-center" @click="handleDeleteBatch">
                  <svg-icon icon="file-batchdel-16" color="#344563" width="16" height="16"></svg-icon>
                  <span>批量删除</span>
                </div> -->
                <div class="filelist-view">
                  <el-tooltip effect="dark" content="网格视图" placement="bottom">
                    <div class="filelist-view-item" :class="{ 'is-active': viewType === 'grid' }"
                      @click="changeViewType('grid')">
                      <svg-icon icon="card-mode" width="16" height="16"
                        :color="viewType === 'grid' ? '4f5d74' : '#9facc2'"></svg-icon>
                    </div>
                  </el-tooltip>
                  <el-tooltip effect="dark" content="列表视图" placement="bottom">
                    <div class="filelist-view-item" :class="{ 'is-active': viewType === 'list' }"
                      @click="changeViewType('list')">
                      <svg-icon icon="list-mode" width="16" height="16"
                        :color="viewType === 'list' ? '4f5d74' : '#9facc2'"></svg-icon>
                    </div>
                  </el-tooltip>
                </div>
              </div>
            </div>
            <div class="file-breadcrumb">
              <!-- <span v-if="breadcrumbList.length" class="pointer" @click="jumpTo(null, -1)">全部</span> -->
              <template v-for="(item, index) in breadcrumbList">
                <span :key="item.id" v-if="index != 0">&nbsp;/&nbsp;</span>
                <span class="pointer" :key="'breadcrumb' + item.id" @click="jumpTo(item, index)">{{ item.folderName
                  }}</span>
              </template>
            </div>

            <div class="card-box" v-loading="loading" v-if="viewType === 'grid'">
              <div class="card-main">
                <div v-for="item in tableData" :key="item.id"
                  class="card-item flex-column align-items-center flex-between"
                  :class="{ 'card-item-file': item.fileType != 2, 'activecard': checkSelect(item) }">
                  <img class="file-icon" @click="handleOpenfolder(item)"
                    :src="require(`@/assets/images/file/file-${item.aliasType}.png`)" style="width:48px;height:48px"
                    v-if="item.describes != '系统默认创建' && item.describes != '自动创建主题文件目录' && item.describes != '自动创建目录' && item.describes != '自动创建模块文件目录'">
                  <div class="file-default" v-else @click="handleOpenfolder(item)">
                    <svg-icon icon="file-folder-48" color="#ffdc53" width="48" height="48"></svg-icon>
                  </div>
                  <div class="one-line item-name" :title="item.folderName">{{ item.folderName }}</div>
                  <div class="item-info two-lines">
                    <span>{{ item.createBy ? item.createBy : '' }}{{ item.fileType == 1 ? "上传" : "创建" }}于{{
    Object.dayjs(item.createTime) }}&nbsp;</span>
                  </div>
                  <!-- <svg-icon @click.native="handleSelect(item)" icon="check-purple" color="#ccc" width="22"
                    height="18"></svg-icon> -->
                </div>
              </div>
            </div>

            <div class="file-content-table" v-loading="loading" v-if="viewType === 'list'">
              <el-table highlight-current-row class="file-table" header-cell-class-name="app-header-cell" ref="table"
                row-class-name="file-table-row" row-key="id" :data="tableData" :height="fileTableHeight"
                @selection-change="handleSelectionChange">
                <!-- <el-table-column type="selection" width="55"
                  :selectable="row => row.describes != '系统默认创建' && row.describes != '自动创建主题文件目录' && row.describes != '自动创建目录' && row.describes != '自动创建模块文件目录' && row.describes != '自动创建模块文件目录'"></el-table-column> -->
                <el-table-column label="名称" prop="folderName" min-width="350px">
                  <template slot-scope="{ row }">
                    <div class="file-name__wrapper">
                      <img class="file-icon" :src="require(`@/assets/images/file/file-${row.aliasType}.png`)"
                        v-if="row.describes != '系统默认创建' && row.describes != '自动创建主题文件目录' && row.describes != '自动创建目录' && row.describes != '自动创建模块文件目录'">
                      <svg-icon v-else icon="file-folder-48" color="#ffdc53" width="48" height="48"
                        style="margin-right:16px"></svg-icon>
                      <div>
                        <div class="file-name one-line">{{ row.folderName }}</div>
                        <div class="file-information ">
                          <span v-if="row.fileType == 1">{{ row.fileSize }}&nbsp;&nbsp;|&nbsp;&nbsp;</span>
                          <span>{{ row.createBy ? row.createBy : '' }}&nbsp;于&nbsp;{{
    Object.dayjs(row.createTime) }}&nbsp;{{ row.fileType == 1 ? "上传" : "创建" }}</span>
                        </div>
                        <div class="file-operation">
                          <div v-if="row.fileType == 2" class="file-operation-item" @click.stop="handleOpenfolder(row)">
                            <svg-icon icon="file-open-16" color="#344563" width="16" height="16"></svg-icon>
                            <span>打开</span>
                          </div>
                          <div v-if="row.fileType == 1" class="file-operation-item"
                            :class="{ 'disabled': row.aliasType === 'other' && row.contentType != 'video' }"
                            @click.stop="handleView(row)">
                            <svg-icon icon="file-preview-16" color="#344563" width="14" height="14"></svg-icon>
                            <span>预览</span>
                          </div>
                          <div v-if="row.fileType == 1" class="file-operation-item"
                            @click.stop="handlesingleDownload(row)">
                            <svg-icon icon="file-uploadsingle-16" color="#344563" width="14" height="14"></svg-icon>
                            <span>下载</span>
                          </div>
                          <!-- <div class="file-operation-item" @click.stop="handleUpdate(row, row.fileType == 1 ? 1 : 2)"
                            v-if="row.describes != '系统默认创建'  && row.describes != '自动创建主题文件目录' && row.describes != '自动创建目录' && row.describes != '自动创建模块文件目录'">
                            <svg-icon icon="file-rename-14" color="#344563" width="14" height="14"></svg-icon>
                            <span>重命名</span>
                          </div>
                          <div class="file-operation-item" @click.stop="handleUpdate(row, row.fileType == 1 ? 3 : 4)"
                            v-if="row.describes != '系统默认创建'  && row.describes != '自动创建主题文件目录' && row.describes != '自动创建目录' && row.describes != '自动创建模块文件目录'">
                            <svg-icon icon="edit" color="#344563" width="14" height="14"></svg-icon>
                            <span>编辑说明</span>
                          </div>
                          <div v-if="row.fileType == 1" class="file-operation-item">
                            <svg-icon icon="file-update-16" color="#344563" width="14" height="14"></svg-icon>
                            <UploadFile :folderid="queryFile.folderId" :subject="subject" :isPersonal="false"
                              :text="'更新'" @finish="updateTableData" :folder="row"></UploadFile>
                          </div>
                          <div class="file-operation-item" @click.stop="handleDelete(row)"
                            v-if="row.describes != '系统默认创建'  && row.describes != '自动创建主题文件目录' && row.describes != '自动创建目录' && row.describes != '自动创建模块文件目录'">
                            <svg-icon icon="delete" color="#344563" width="14" height="14"></svg-icon>
                            <span>删除</span>
                          </div> -->
                        </div>
                      </div>
                    </div>
                  </template>
                </el-table-column>
                <el-table-column label="版本" prop="classify" min-width="50">

                  <template slot-scope="{ row }">
                    <span>{{ row.fileType == 1 ? row.fileVersion : "-" }}</span>
                  </template>
                </el-table-column>
                <el-table-column label="说明" min-width="100" prop="describes" show-overflow-tooltip>

                  <template slot-scope="{ row }">
                    <span style="line-height: 20px;">
                      <span>{{ row.describes }}</span>
                    </span>
                  </template>
                </el-table-column>
              </el-table>
            </div>
            <FilePreview v-if="preview" :visible.sync="preview" :haslist="false" :currentitem.sync="currentItem" />
          </div>
        </div>
      </div>
      <FolderDialog v-if="folderDialogVisible" :visible.sync="folderDialogVisible" :query-id="queryId" :item="subject"
        :parent-id="breadcrumbList.length ? queryFile.folderId : ''" @finish="updateTableData"
        :api="'createSubjectFileFolder'" />
      <!-- <AppShare v-if="shareVisible" :subject-id="queryId.subjectId" :visible.sync="shareVisible" :shareinfo="sharedata"
        ref="AppShare"></AppShare> -->
      <UpdateFolder v-if="updateFolderVisible" :visible.sync="updateFolderVisible" :current="clickItem"
        :dialog-type="updateFolderType" @finish="updateTableData" />
    </div>
  </el-drawer>
</template>

<script>
import { mapGetters } from "vuex";
import { File } from "@/api/File";
import { Share } from "@/api/Share";
import NETSYSTEM from "@/api/NETSYSTEM";
import { deleteBatch, DownloadFile } from '@/utils/file';
import FilePreview from "@/components/dialog/FilePreview.vue";
import FolderDialog from "@/components/filecompanies/Folder.vue";
import AppShare from "@/components/AppShare/index.vue";
import UpdateFolder from "@/components/filecompanies/UpdateFolder.vue";
import UploadFile from "@/components/filecompanies/uploadFile.vue";
export default {
  components: {
    FilePreview,
    FolderDialog,
    AppShare,
    UpdateFolder,
    UploadFile,
  },
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    current: Object,
    folderId: String
  },
  data() {
    return {
      loading: false,
      direction: "rtl",
      queryData: {
        dictItemIds: [],
        pageNo: 1,
        pageSize: 10,
        name: '',
        showLevel: 0,//展示层级数(0：一层,1:二层，2：三层...)
        sort: null,
        sortType: 'desc',//排序的类型（asc: 正序 desc: 倒序)
        sort: "createTime",
      },
      tableData: [],
      queryId: {
        moduleId: "",
        subjectId: "",
      },
      queryFile: {
        // dictItemIds: "", // 二级分类id
        folderId: "",
        name: "",
        pageNo: 1,
        pageSize: 999,
      },
      breadcrumbList: [],
      preview: false,
      selectList: [], // 选择的文件列表	
      folderDialogVisible: false,//创建文件夹弹窗
      sharedata: {
        accessCode: "",
        authWay: 2,//（1、需要登录，2、无需安全验证，3、指定访问码
        bizType: 2,//1、主题产品，2文件,3、公司名片,4、个人名片，5、演示方案
        delFlag: 0,
        expireTime: "",
        id: "",
        shareDetails: [],
        shareWay: 1,
        status: 1,
        timeUnit: "d",
        validDays: 3,
      },
      shareVisible: false,
      fileTableHeight: 650,
      viewType: 'grid',
      updateFolderType: 1,
      updateFolderVisible: false,//重命名和编辑说明的弹窗
      clickItem: null,//点击项
    };
  },
  watch: {
    shareVisible(val) {
      if (!val) {
        this.sharedata.shareDetails = [];
        this.sharedata.id = "";
        this.selectList = []
      }
    }
  },
  computed: {
    dialogVisible: {
      get() {
        return this.visible;
      },
      set(val) {
        this.$emit("update:visible", val);
      },
    },
    ...mapGetters(["userInfo"]),
  },
  created() {
    this.queryFile.folderId = this.current.folderId;
  },
  mounted() {
    // this.getTableData()
    this.getFloder()
  },
  methods: {
    /**
     * @description: 获取文件夹列表
     * @return {void}
     */
    async getFloder() {
      this.loading = true;
      const res = await NETSYSTEM.getParentFolderListById(this.queryFile.folderId);
      this.breadcrumbList = res.slice(1);
      this.loading = false;
      this.getTableData()
    },
    /**
     * @description: 获取文件目录下的文件列表
     * @return {void}
     */
    async getTableData(pageNo) {
      this.loading = true;
      pageNo && (this.queryFile.pageNo = pageNo);
      this.showAttribute = false;
      this.currentItem = null;
      this.$refs.table && this.$refs.table.setCurrentRow();
      let payload = _.cloneDeep(this.queryFile);
      const res = await File.queryFileByFolderId(payload);
      res.records.forEach(element => {
        if (element.fileType == 1) {
          element.fileSize = this.common.formatFileSize(element.fileSize);
          element.aliasType = this.common.getFileType(element.contentType, element.fileSavePath);
        } else {
          element.aliasType = "folder";
        }
      })
      this.tableData = res.records;
      this.loading = false;
    },
    /**
     * @description: 打开文件夹
     * @param {Object} value 文件夹
     * @return {void}
     */
    handleOpenfolder(value) {
      if (value.fileType != 2) {
        this.handleView(value)
        return
      }
      const index = this.breadcrumbList.findIndex(item => item.id === value.id);
      index === -1 && this.breadcrumbList.push(value);
      this.queryFile.folderId = value.id;
      this.queryFile.name = "";
      this.getTableData(1);
    },
    /**
     * @description: 点击面包屑获取文件列表
     * @param {Object} value 点击项
     * @param {Number} index 索引
     * @return {void}
     */
    jumpTo(value, index) {
      if (index === this.breadcrumbList.length - 1) {
        return
      }
      if (value) {
        this.breadcrumbList.splice(index + 1);
        this.queryFile.folderId = value.id;
        this.queryFile.name = "";
        this.getTableData(1);
      } else {
        this.breadcrumbList = [];
        this.queryFile.folderId = "";
        // this.getFirstTableData()
      }
    },
    /**
     * @description:清空搜索字段
     * @return {void}
     */
    handleClear() {
      this.queryFile.name = "";
      this.getTableData(1);
    },
    checkSelect(value) {
      const index = this.selectList.findIndex(item => item.id === value.id);
      return index != '-1'
    },
    handleSelect(value) {
      if (value.fileType == 2) {
        return
      }
      const index = this.selectList.findIndex(item => item.id === value.id);
      if (index !== -1) {
        this.selectList.splice(index, 1);
      } else {
        this.selectList.push(value);
      }
    },
    /**
   * @description: 更新列表
   * @return {void}
   */
    updateTableData() {
      if (this.breadcrumbList.length) {
        this.getTableData()
      } else {
        // this.getFirstTableData()
      }
    },
    /**
     * @description: 下载
     * @return {void}
     */
    async handleDownload() {
      if (this.selectList.length <= 0) {
        this.$message.warning("请选择下载文件");
        return
      }
      this.selectList.forEach(item => {
        DownloadFile(item, 1)
      })
      this.selectList = []
    },
    /**
     * @description: 单个文件下载
     * @return {void}
     */
    handlesingleDownload(value) {
      DownloadFile(value, 1)
    },
    /**
     * @description: 分享文件
     * @param {Object} rowData 点击项
     * @return {void}
     */
    async handeShare() {
      if (this.selectList.length != 0) {
        this.selectList.forEach(element => {
          this.sharedata.shareDetails.push({
            "createName": element.creator,
            "fileCreateTime": element.createTime,
            "fileName": element.folderName,
            "fileType": element.fileType,
            "fileUpdateTime": element.updateTime,
            "fileUrl": element.fileSavePath,
            // "id": element.,
            "shareId": "",
            "size": element.fileSize,
            "subjectColumnId": element.id
          })
        });
        // 拿到分享的id
        const res = await Share.addMyShare(this.sharedata);
        this.sharedata.id = res;
        this.shareVisible = true;
      } else {
        this.$message.warning("请选择要分享的文件");
      }
    },
    /**
     * @description: 批量删除文件
     * @return {void}
     */
    async handleDeleteBatch() {
      if (this.selectList.length <= 0) {
        this.$message.warning("请选择文件");
      } else {
        this.$showMessage({
          message: `是否删除选择的文件和文件夹 <span style="font-weight: 1000;"></span> ?`,
          ...this.$global.deleteMessage,
        }, async (done) => {
          this.loading = true
          let payload = {
            isDeleteChilds: "1",
            fileIds: [],
            folderIds: [],
            isClear: "0",
            isRecycle:0,//是否回收站删除
          }
          this.selectList.forEach(item => {
            if (item.fileType === "1") {
              payload.fileIds.push(item.id);
            } else if (item.fileType === "2") {
              payload.folderIds.push(item.id);
            }
          });
          const res = await deleteBatch(payload)
          if(res){
            this.$message.success('删除成功');
            setTimeout(() => {
              this.updateTableData();
              this.loading = false
            }, 1000)
          } 
          done(true);
        });
      }
    },
    /**
     * @description: 单个删除
     * @param {Object} value 点击项
     * @return {void}
     */
    handleDelete(value) {
      this.$showMessage({
        message: `是否确认删除 <span style="font-weight: 1000;">${value.folderName}</span> ?`,
        ...this.$global.deleteMessage
      }, async (done) => {
        if (value.fileType == 1) {
          // 删除文件
          const res = await File.deleteFile(value.id);
          done(res);
          res && this.updateTableData();
        } else {
          // 删除文件夹
          const res = await File.deleteFolder(value.id);
          done(res);
          res && this.updateTableData();
        }
        this.selectList = []
      });
    },
    /**
     * @description: 预览
     * @param {Object} value 点击项
     * @return {void}
     */
    handleView(value) {
      if (value.aliasType === "image") {
        this.$elImageViewer({ urlList: [value.fileSavePath], initialIndex: 0 });
      }else{
        this.currentItem = value;
        this.preview = true;
      }
    },
    /**
     * @description: 切换格子/列表视图
     * @param {Object} value 点击项
     * @return {void}
     */
    changeViewType(type) {
      this.selectList = []
      this.viewType = type
    },
    handleSelectionChange(val) {
      this.selectList = val
    },
    /**
     * @description: 重命名、编辑说明
     * @param {Object} value 点击项
     * @param {Number} type 类型
     * @return {void}
     */
    handleUpdate(value, type) {
      this.clickItem = value;
      this.updateFolderType = type;
      this.updateFolderVisible = true;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@/scss/common.scss";

.preview-detail {
  height: 90vh;
  width: 100%;
  z-index: 12;
  display: flex;
  padding: 20px;
  // color: #fff;
  box-sizing: border-box;
  border-radius: 5px;

  .detail-main {
    height: 100%;
    width: 100%;
    position: relative;
    background-color: #fff;
    overflow: hidden;

    .file-default {
      height: 48px;
      width: 48px;
      overflow: hidden;

      .svg-icon {
        display: block;
        position: relative;
        top: 0;
        right: 0;
      }
    }
  }
}

.file-tool {
  .file-tool-item {
    font-size: 12px;
    color: var(--color-text-primary);
    cursor: pointer;
    margin-left: 20px;

    &:hover {
      color: var(--color-primary);

      .svg-icon {
        color: var(--color-primary) !important;
      }
    }
  }
}

.file-content-table {
  flex: 1;
  overflow-y: auto;
  margin-top: 10px;

  .describes-icon {
    display: none;
  }

  .file-table-row {

    &:hover {
      .file-information {
        display: none;
      }

      .file-operation {
        display: flex;
      }

      .describes-icon {
        display: inline-block;
      }
    }
  }

  ::v-deep .el-table {
    &::before {
      height: 0;
    }

    .el-table__row {
      cursor: pointer;
    }

    .el-table__body tr:hover,
    tr.current-row {
      td {
        background-color: #f5f7fa;
      }
    }
  }
}

.file-name__wrapper {
  font-size: 14px;
  font-weight: 500;
  display: flex;
  align-items: center;

  .file-icon {
    width: 48px;
    height: 48px;
    margin-right: 16px;
  }

  .file-name {
    line-height: 20px;
    padding-bottom: 4px;
    color: var(--color-text-regular);
  }

  .file-information {
    line-height: 20px;
    color: #cfd7e6;
    margin-top: 6px;
    font-size: 12px;
  }

  .file-operation {
    font-size: 12px;
    display: none;

    .file-operation-item {
      display: flex;
      align-items: center;
      line-height: 26px;
      padding: 0 6px;
      border-radius: 5px;
      color: #8997B0;
      cursor: pointer;

      &.disabled {
        cursor: not-allowed;
      }

      &:not(.disabled):hover {
        color: var(--color-primary);

        .svg-icon {
          color: var(--color-primary) !important;
        }
      }
    }
  }
}

.filelist-view {
  height: 24px;
  border-radius: 5px;
  border: 1px solid #d3dae6;
  margin: 0 10px;
  background: #fff;
  display: flex;
  overflow: hidden;

  .filelist-view-item {
    box-sizing: border-box;
    width: 30px;
    height: 24px;
    border-right: 1px solid #d3dae6;
    background-color: #fff;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;

    &:last-child {
      border-right: none;
    }

    &:hover {
      background-color: #fcfdfe;
    }

    &.is-active {
      background-color: #f0f3f7;
    }
  }
}
</style>