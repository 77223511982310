<template>
  <el-dialog append-to-body class="ss-dialog no-header" width="420px" :visible.sync="dialogVisible">
    <div v-if="dialogType < 3" class="rename">
      <el-input v-model="input" :placeholder="placeholder"></el-input>
    </div>
    <div v-else class="remark">
      <el-input v-model="input" show-word-limit class="textarea" type="textarea" :autosize="{ minRows: 6 }"
        :maxlength="100" :placeholder="placeholder"></el-input>
    </div>
    <div class="dialog-footer" slot="footer">
      <el-button type="info" @click="dialogVisible = false">取 消</el-button>
      <el-button v-if="dialogType < 3" :type="input ? 'primary' : 'info'" :loading="confirmLoading"
        @click="handleConfirm">确 定</el-button>
      <el-button v-else type="primary" :loading="confirmLoading" @click="handleConfirm">确 定</el-button>
    </div>
  </el-dialog>
</template>

<script>
import { File } from "@/api/File";
export default {
  name: "NewFolderDialog",
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    current: Object,
    dialogType: Number, // 1:文件重命名 2:文件夹重命名 3:编辑文件说明 4:编辑文件夹说明
  },
  data() {
    return {
      input: "", // 输入框绑定值
      placeholder: "", // 输入框占位符
      confirmLoading: false,
    }
  },
  computed: {
    dialogVisible: {
      get() {
        return this.visible;
      },
      set(val) {
        this.$emit('update:visible', val);
      }
    },
  },
  created() {
    const label = {
      "1": "文件名称",
      "2": "文件夹名称",
      "3": "文件说明",
      "4": "文件夹说明",
    }
    this.input = this.dialogType < 3 ? this.current.folderName : this.current.describes;
    this.placeholder = `请输入${label[this.dialogType + ""]}`;
  },
  methods: {
    /**
     * @description: 确定
     * @return {void}
     */
    async handleConfirm() {
      if (this.dialogType < 3 && !this.input) {
        return
      }
      if(this.input.trim()=='系统默认创建'){
        this.$message.warning('请勿使用默认字段')
        return
      }
      this.confirmLoading = true;
      let res;
      if (this.dialogType < 3) {
        res = await File.renameFile(this.dialogType, this.current.id, this.input);
      } else if (this.dialogType === 3) {
        res = await File.updateFileRemark(this.current.id, this.input);
      } else if (this.dialogType === 4) {
        res = await File.updateFolderRemark(this.current.id, this.input);
      }
      this.confirmLoading = false;
      if (res) {
        this.dialogVisible = false;
        this.$emit("finish");
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.rename {
  padding: 36px 40px;
}

.remark {
  padding: 24px 24px 16px;

  ::v-deep(.el-textarea__inner) {
    resize: none;
  }
}

.dialog-footer {
  text-align: center;

  .el-button {
    min-width: 112px;
  }
}
</style>