<template>
  <div class="form-cell-4-selection" :class="{ highlight }">
    <!-- <BodySelectableCell
      :column="column"
      :row="formRow"
      :cellValueAfterFormat="value"
      :getDropdownData="getDropdownData"
    /> -->

    <Dropdown
      class="form-cell-4-selection__dropdown"
      ref="dropdown"
      :dropdownPopperClass="'form-cell-4-selection__dropdown-popper'"
      @before-dropdown="onBeforeDropdown"
    >
      <template v-slot:title>
        <!-- <div class="dropdown-title" v-loading="selectConfirming"> -->
        <div class="dropdown-title">
          <OverflowTooltip :content="selectionCurrValue">
            <span class="dropdown-title__text">{{ selectionCurrValue }}</span>
          </OverflowTooltip>
          <!-- <span class="dropdown-title__text"> {{ selectionCurrValue }}</span> -->
          <i class="el-icon-arrow-down el-icon--right"></i>
        </div>
      </template>
      <template v-slot:content>
        <template v-for="item in items">
          <DropdownItem
            :key="item.key"
            :value="item.value"
            @item-click="onItemClick(item)"
          />
        </template>
      </template>
    </Dropdown>
  </div>
</template>
<script lang="ts">
import Vue, { PropType } from "vue";
import { mixin } from "./mixin";

// import BodySelectableCell from "@/flexible-table-module/components/FlexibleTable/CellInsideContent/BodySelectableCell.vue";
import Dropdown from "@/flexible-table-module/components/Common/Dropdown/Dropdown.vue";
import DropdownItem from "@/flexible-table-module/components/Common/Dropdown/DropdownItem.vue";
import OverflowTooltip from "@/flexible-table-module/components/Common/OverflowTooltip.vue";

import { type FormRow } from "@/flexible-table-module/entity/Table/FormRow";
import { type TFormColumn } from "@/flexible-table-module/entity/Table/TFormColumn";
import { type IBaseTableData } from "@/flexible-table-module/entity/Table/IBaseTableData";
import { Column } from "@/flexible-table-module/entity/Table/Column";
import {
  type KeyStrValuePair,
  // type KeyValuePair,
} from "@/flexible-table-module/entity/KeyValuePair";

export default Vue.extend({
  inheritAttrs: false,
  name: "FormCell4Selection",
  components: { Dropdown, DropdownItem, OverflowTooltip },
  mixins: [mixin],
  props: {
    // 使 this.value 的类型推断有效，再写一遍 value 的类型
    value: [String, Number, Date, Array, Object] as PropType<
      string | number | Date | [Date, Date] | KeyStrValuePair
    >, // 值
    column: {
      type: Object as PropType<TFormColumn>,
      required: true,
    },
    // formRow: { type: Object as PropType<FormRow>, required: true },
    // 获取下拉列表数据的回调
    getDropdownData: {
      type: Function as PropType<
        (
          row: IBaseTableData | null | undefined,
          column: Column,
          resolve: (data: KeyStrValuePair[]) => void
        ) => void
      >,
    },
  },
  data(): {
    items: KeyStrValuePair[];
    // selectConfirming: boolean; // 用户选择了下拉框里的一项，确认中，有加载动画
  } {
    return {
      items: [],
      // selectConfirming: false,
    };
  },
  computed: {
    selectionCurrValue(): string | undefined {
      const value = this.value;
      if (this.column instanceof Column) {
        switch (this.column.type) {
          case "string": {
            return typeof value === "string" ? String(value) : "";
          }
          case "key-str-value": {
            if (value === null || value === undefined) return "";
            else {
              const keyStrValue = value as KeyStrValuePair;
              return typeof keyStrValue.value === "string"
                ? String(keyStrValue.value)
                : "";
            }
          }
          default:
            throw new Error("column type is not supported");
        }
      } else throw new Error("column is not instance of Column");
    },
  },
  methods: {
    onBeforeDropdown(confirm: (isConfirm: boolean) => void) {
      if (this.column instanceof Column) {
        if (typeof this.getDropdownData === "function") {
          const resolve = (data: KeyStrValuePair[]) => {
            // setTimeout(() => {
            this.items = data;
            confirm(true);
            // this.loadingItems = false;
            // }, 2000);
          };
          this.getDropdownData(undefined, this.column, resolve);
        } else {
          confirm(true);
        }
      } else {
        throw new Error("column is not instance of Column");
      }
    },
    onItemClick(item: KeyStrValuePair) {
      if (this.column instanceof Column) {
        (this.$refs["dropdown"] as any)?.hide();

        // if (this.column.id in this.row) {
        if (this.column.type === "key-str-value")
          this.$emit("input", { key: item.key, value: item.value });
        else if (this.column.type === "string") this.$emit("input", item.value);
        else throw new Error("column type is not supported");
        // }
      } else {
        throw new Error("column is not instance of Column");
      }
    },
  },
});
</script>
<style lang="scss" scoped>
// @import "src/flexible-table-module/scss/common.scss";
@import "src/flexible-table-module/scss/_variables.scss";

.form-cell-4-selection {
  // @include form-cell-highlight;

  $cell-height: 32px;

  width: 100%;
  height: $cell-height;
  line-height: $cell-height;
  background-color: #fff;
  background-image: none;
  border-radius: 4px;
  border: 1px solid #dcdfe6;
  box-sizing: border-box;
  color: #606266;
  display: inline-block;
  padding: 0 15px;
  transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);

  &.highlight {
    border-color: $color-error;
  }

  &__dropdown {
    width: 100%;
    height: 100%;

    .dropdown-title {
      width: 100%;
      height: $cell-height - 2px;
      font-size: $fs-form-cell-4-selection;
      user-select: none;
      cursor: pointer;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .el-icon-arrow-down {
        // height: $cell-height - 2px;
        // line-height: $cell-height - 2px;

        &.el-icon--right {
          // float: right;
        }
      }
    }
  }
}
</style>