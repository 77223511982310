import { BaseColumn } from "./BaseColumn";

export class IndexColumn extends BaseColumn {

    // public columnType: "index" = "index";

    constructor(params: {
        id: string,
        active?: boolean,
        settingName?: string;
        fieldOrder?: number,
    }) {
        const {
            id,
            active,
            settingName,
            fieldOrder,
        } = params;

        super({
            id,
            active,
            settingName,
            title: '序号',
            fieldOrder,
        });
    }
}