<template>
  <el-dialog
    class="ss-dialog"
    title="分享配置"
    top="188px"
    width="840px"
    :close-on-click-modal="false"
    :visible.sync="dialogVisible"
    append-to-body
  >
    <div class="content">
      <div class="qr-code-wrapper">
        <div class="qr-code">
          <QrCode :id="'QrCode'" :text="codeUrl" />
        </div>
        <div class="qr-code-tip">APP或微信扫码直接浏览</div>
        <span class="expires">
          <span class="expires-label">有效期：</span>
          <el-popover
            v-show="!expiresCustomVisible"
            v-model="expiresVisible"
            popper-class="share-popover expires-popover"
            placement="bottom-end"
            width="103"
            trigger="click"
            :visible-arrow="false"
          >
            <span class="expires-value" slot="reference">
              {{ expiresItem ? expiresItem.realName || expiresItem.name : "" }}
            </span>
            <div
              v-for="item in expiresList"
              class="share-popover-item"
              :class="{
                'is-active': expiresItem && expiresItem.id === item.id,
              }"
              :key="item.id"
              @click="selectExpires(item)"
            >
              <span>{{ item.name }}</span>
            </div>
          </el-popover>
          <template v-if="expiresCustomVisible">
            <el-input
              v-model="expiresTime"
              class="expires-input"
              size="small"
              @input="expiresTime = expiresTime.replace(/[^0-9]/g, '')"
            ></el-input>
            <el-popover
              v-model="expiresTypeVisible"
              popper-class="share-popover"
              placement="bottom-end"
              width="94"
              trigger="click"
              :visible-arrow="false"
            >
              <div class="expires-type" slot="reference">
                <span class="expires-type-label">{{
                  expiresTypeItem ? expiresTypeItem.name : ""
                }}</span>
                <img
                  class="expires-type-icon"
                  src="@/assets/images/common/triangle_down.png"
                />
              </div>
              <div
                v-for="item in expiresTypeList"
                class="share-popover-item"
                :key="item.id"
                @click="selectExpiresType(item)"
              >
                <div class="share-popover-item__label">{{ item.name }}</div>
                <img
                  v-if="expiresTypeItem && expiresTypeItem.id === item.id"
                  class="share-popover-item__icon"
                  src="@/assets/images/common/check_blue.png"
                />
              </div>
            </el-popover>
            <img
              class="expires-check"
              src="@/assets/images/common/check_blue.png"
              @click="setselftime"
            />
          </template>
        </span>
      </div>
      <div class="content-right">
        <template v-if="!schemeid">
          <div class="content-right-title">
            <span>分享内容</span>
          </div>
          <el-checkbox-group
            v-model="checkboxGroup"
            class="share-checkbox"
            @change="changeCheckbox"
          >
            <el-checkbox
              v-for="item in contentList"
              border
              :label="item.id"
              :indeterminate="item.id === '1' ? indeterminate : false"
              :key="item.id"
              >{{ item.name }}</el-checkbox
            >
          </el-checkbox-group>
        </template>
        <div class="content-right-title" style="padding-top: 20px">浏览权限</div>
        <el-radio-group v-model="radio">
          <el-radio :label="1">需要登录系统</el-radio>
          <el-radio :label="2">无须安全验证</el-radio>
          <el-radio :label="3">
            指定访问码
            <el-input
              v-model="pwd"
              class="pwd-input"
              placeholder="请输入4位数密码"
              size="small"
              :maxlength="4"
              @input="pwd = pwd.replace(/[^0-9a-zA-Z]/g, '')"
              :disabled="radio != 3"
            ></el-input>
            <span v-if="pwd.length < 4" style="color: var(--color-danger-hover)"
              >仅可输入4位字母和数字</span
            >
            <!-- <img class="pwd-check" src="@/assets/images/common/check_blue.png" alt="" @click="confirmPwd" /> -->
          </el-radio>
        </el-radio-group>
      </div>
    </div>
    <div class="dialog-footer">
      <el-input
        v-model="codeUrl2"
        class="footer-input"
        placeholder="请输入内容"
        @click.native="inputClick"
        @focus="handleFocus"
        readonly
      >
        <template slot="append">复制链接</template>
      </el-input>
      <div class="footer-btn wechat" @click="seddToWechat">
        <img
          class="footer-btn-icon_wechat"
          src="@/assets/images/common/wechat.png"
          alt=""
        />
        <span class="footer-btn-label">发送到聊天</span>
        <img
          class="footer-btn-arrow"
          src="@/assets/images/common/arrow_right.png"
          alt=""
        />
      </div>
      <div class="footer-btn i3v" @click="seedToFriends">
        <img class="footer-btn-icon_i3v" src="@/assets/images/common/i3v.png" alt="" />
        <span class="footer-btn-label">指定好友</span>
        <img
          class="footer-btn-arrow"
          src="@/assets/images/common/arrow_right.png"
          alt=""
        />
      </div>
    </div>
    <SelectFriends
      v-if="friendsVisible"
      :visible.sync="friendsVisible"
      :shareinfo="sharedetail"
      :sharename="subjectName"
      @Done="finish"
    />
  </el-dialog>
</template>

<script>
import QrCode from "../../../components/QrCode.vue";
import SelectFriends from "./SelectFriends.vue";
import { ProductLibrary } from "@/api/ProductLibrary";
import { Share } from "@/api/Share";
export default {
  name: "share",
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    subjectId: String,
    shareinfo: Object, //点击的主题
    schemeid: String,
  },
  components: { SelectFriends, QrCode },
  data() {
    return {
      codeUrl: "", //访问链接,二维码使用
      codeUrl2: "", //访问链接
      expiresVisible: false,
      expiresList: [
        { id: "1", name: "3天", time: 3, timeUnit: "h" },
        { id: "2", name: "7天", time: 7, timeUnit: "d" },
        { id: "3", name: "1个月", time: 1, timeUnit: "m" },
        { id: "4", name: "6个月", time: 6, timeUnit: "m" },
        { id: "5", name: "长期有效", time: 0, timeUnit: "y" },
        { id: "6", name: "自定义", realName: "自定义", timestamp: -1 },
      ],
      expiresItem: null,

      expiresCustomVisible: false,
      expiresTime: "",
      expiresTypeVisible: false,
      expiresTypeList: [
        { id: "1", name: "小时", timeUnit: "h" },
        { id: "2", name: "天", timeUnit: "d" },
        { id: "3", name: "月", timeUnit: "m" },
        { id: "4", name: "年", timeUnit: "y" },
      ],
      expiresTypeItem: null,

      // input2: "https://imkryjjvpa.feishu.cn/messenger/",
      checkboxGroup: [],
      contentList: [],
      indeterminate: false,
      radio: 2,
      pwd: "WWWW",
      friendsVisible: false,
      bizType: 1, // 分享类型(1.默认分享，2、vip分享)
      sharedata: {
        accessCode: "",
        authWay: 2,
        bizType: 6, //1、主题产品，2文件,3、公司名片,4、个人名片，5、演示方案 6.展示方案
        delFlag: 0,
        expireTime: "",
        id: "",
        mirrorId: this.shareinfo.mirrorId,
        shareDetails: [],
        shareWay: 1,
        status: 1,
        subjectId: this.shareinfo.id,
        timeUnit: "d",
        validDays: 3,
        versionId: this.shareinfo.versionId,
      },
      // 选择的栏目
      selectColums: [],
      subjectName: this.shareinfo.name,
      // 分享详情
      sharedetail: null,
      isdemonstration: false, //是否为演示方案模块
    };
  },
  computed: {
    dialogVisible: {
      get() {
        return this.visible;
      },
      set(val) {
        this.$emit("update:visible", val);
      },
    },
  },
  watch: {
    /**
     * @description: 改变链接的访问方式
     * @return {void}
     */
    async radio(newval) {
      this.sharedata.authWay = newval;
      if (newval === 3) {
        this.sharedata.accessCode = this.pwd;
      }
      this.sharedata.id = "";
      this.toShare();
      // await Share.editShare(this.sharedata);
      // const res2 = await Share.getInfoById(this.sharedata.id);
    },
    /**
     * @description: 自定义的时间数值
     * @return {void}
     */
    expiresTime(val) {
      this.sharedata.validDays = val;
    },
  },
  mounted() {
    this.pwd = this.randomStr();
    this.expiresItem = this.expiresList[0];
    this.expiresTypeItem = this.expiresTypeList[0];

    const pattern = /^\/demonstration\//;
    this.isdemonstration = pattern.test(this.$route.fullPath);
    if (this.isdemonstration) {
      this.sharedata.bizType = 5;
      this.contentList = [];
      this.toShare();
    } else {
      this.getScheme();
    }
    // this.$nextTick(()=>{
    //   this.toShare();
    // })
  },
  methods: {
    /**
     * @description: 获取展示方案列表
     * @return {void}
     */
    async getScheme() {
      const res = await ProductLibrary.SchemequeryList(this.shareinfo.id);
      this.checkboxGroup = [];
      let defaultscheme = res.filter((item) => item.isDefault);
      if (this.schemeid) {
        defaultscheme = res.filter((item) => item.id == this.schemeid);
      }
      if (defaultscheme.length) {
        this.sharedata.shareDetails.push({
          fileName: defaultscheme[0].name,
          subjectColumnId: defaultscheme[0].id,
        });
        this.checkboxGroup = [defaultscheme[0].id];
      } else {
        this.checkboxGroup = [res[0].id];
        this.sharedata.shareDetails.push({
          fileName: res[0].name,
          subjectColumnId: res[0].id,
        });
      }
      this.contentList = res;
      this.$nextTick(() => {
        this.toShare();
      });
    },
    /**
     * @description: 关闭分享弹窗
     * @return {void}
     */
    finish() {
      this.dialogVisible = false;
    },
    /**
     * @description: 选择时间
     * @return {void}
     */
    async selectExpires(value) {
      if (this.expiresItem && this.expiresItem.id === value.id) {
        if (value.id === "6") {
          this.expiresCustomVisible = true;
        } else {
          return;
        }
      }
      this.expiresItem = value;
      this.expiresVisible = false;
      if (value.id === "6") {
        this.expiresCustomVisible = true;
        this.sharedata.timeUnit = this.expiresTypeList[0].timeUnit;
        this.sharedata.validDays = this.expiresTime;
        return;
      }
      this.sharedata.validDays = this.expiresItem.time;
      this.sharedata.timeUnit = this.expiresItem.timeUnit;
      this.sharedata.id = "";
      this.toShare();
      // await Share.editShare(this.sharedata);
      // const res2 = await Share.getInfoById(this.sharedata.id);
    },
    /**
     * @description: 自定义时间选择时间类型
     * @return {void}
     */
    selectExpiresType(value) {
      if (this.expiresTypeItem && this.expiresTypeItem.id === value.id) {
        return;
      }
      this.expiresTypeItem = value;
      this.expiresTypeVisible = false;
      this.sharedata.timeUnit = value.timeUnit;
    },
    randomNumber(max, min) {
      return Math.round(Math.random() * (max - min) + min);
    },
    randomStr() {
      //创建一个空字符，用于存放随机数/字母
      let strData = "";
      //生成随机字符库 (验证码四位，随机数三种，取公倍数12,所以循环4次。也可以是120次，1200次。)
      for (let i = 0; i < 4; i++) {
        let num = this.randomNumber(0, 9); //生成0-9的随机数
        let az = String.fromCharCode(this.randomNumber(97, 122)); //生成a-z
        let AZ = String.fromCharCode(this.randomNumber(65, 90)); //生成A-Z
        strData = strData + num + az + AZ; //将生成的字符进行字符串拼接
      }
      // 开始真正的随机(从随机字符库中随机取出四个)
      let str = "";
      for (let i = 0; i < 4; i++) {
        str += strData[this.randomNumber(0, strData.length - 1)];
      }
      return str;
    },
    confirmPwd() {},
    // 自定义时间完成
    async setselftime() {
      if (!this.expiresTime) {
        this.$message.warning("时间不能为空");
        return;
      }
      this.expiresCustomVisible = false;
      this.sharedata.id = "";
      this.toShare();
    },
    getSchemename(value) {
      const Scheme = this.contentList.filter((item) => item.id == value);
      return Scheme.length ? Scheme[0].name : "";
    },
    /**
     * @description: 当 Checkbox-group 绑定值变化时触发的事件
     * @param {Array} value 更新后的值
     * @return {void}
     */
    async changeCheckbox(value) {
      this.selectColums = [];
      value.forEach((item) => {
        this.selectColums.push({
          fileName: this.getSchemename(item),
          subjectColumnId: item,
        });
      });
      this.sharedata.id = "";
      this.sharedata.shareDetails = this.selectColums;
      this.toShare();
    },
    /**
     * @description: 底部输入框点击事件，用于复制链接
     * @param {Event} event
     * @return {void}
     */
    async inputClick(event) {
      this.sharedetail = await Share.getInfoById(this.sharedata.id);
      let data = {
        bizType: this.sharedetail.bizType,
        delFlag: this.sharedetail.delFlag,
        expireTime: this.sharedetail.expireTime,
        shareDetailSaveRequests: [
          {
            fileName: this.subjectName,
            fileUrl: this.codeUrl2,
          },
        ],
        shareId: this.sharedetail.id,
        status: 1,
        subjectId: this.sharedetail.subjectId,
        subjectName: this.subjectName,
        targetUsers: [
          {
            userId: "",
            userName: "",
          },
        ],
      };
      const res = await Share.addShare(data);
      if (event.target.className === "el-input-group__append") {
        const res = this.common.handleCopy(this.codeUrl2);
        res && this.$message.success("复制成功");
      }
    },
    /**
     * @description: 发送到微信
     * @return {void}
     */
    seddToWechat() {
      // do something
    },
    /**
     * @description: 指定好友
     * @return {void}
     */
    async seedToFriends() {
      this.sharedetail = await Share.getInfoById(this.sharedata.id);
      this.friendsVisible = true;
    },
    /**
     * @description: 链接框聚焦时全选
     * @param {FocusEvent} event 聚焦事件
     * @return {void}
     */
    handleFocus(event) {
      event.currentTarget.select();
    },
    /**
     * @description: 点击分享,新增分享
     * *@param {Object} value 当前项
     * @return {void}
     */
    async toShare() {
      if (!this.isdemonstration && !this.contentList.length) return;
      if (this.isdemonstration) this.sharedata.bizType = 5;
      this.sharedata.authWay = this.radio;
      // 拿到分享的id
      const res = await Share.addMyShare(this.sharedata);
      this.sharedata.id = res;
      this.codeUrl = `${process.env.VUE_APP_URL_SHARE}getdetailbyshareid?shareId=${res}&scanFlag=true`;
      this.codeUrl2 = `${process.env.VUE_APP_URL_SHARE}getdetailbyshareid?shareId=${res}`;
    },
    /**
     * @description: 改变访问码
     * @return {void}
     */
    changepwd(value) {
      if (value.length === 4 && this.radio === 3) {
        this.sharedata.accessCode = value;
      }
    },
  },
};
</script>

<style lang="scss">
.share-popover {
  @include popover(94px);

  .share-popover-item {
    @include popover-item;
    padding: 0 18px 0 6px;
    cursor: pointer;
    text-align: left;
    position: relative;

    &.is-active {
      &::before {
        content: "";
        position: absolute;
        top: 11px;
        right: 0;
        width: 13px;
        height: 10px;
        background-image: url("@/assets/images/common/check_blue.png");
        background-size: 13px 10px;
        background-repeat: no-repeat;
      }
    }
    .share-popover-item__icon {
      width: 13px;
      position: absolute;
      right: 8px;
      top: 11px;
    }
  }
}

.expires-popover {
  margin-top: 13px !important;
}
</style>

<style lang="scss" scoped>
.expires {
  font-size: 14px;
  font-weight: 500;
  // margin-left: 16px;
  display: flex;
  justify-content: center;

  .expires-label {
    line-height: 32px;
    color: var(--color-text-regular);
  }

  .expires-value {
    line-height: 32px;
    color: #ff5733;
    font-weight: 700;
    text-decoration: underline dashed;
    cursor: pointer;
  }
}

.expires-input {
  width: 72px;
  margin-left: 10px;
}

.expires-type {
  width: 75px;
  height: 32px;
  margin-left: 10px;
  border-radius: 5px;
  background: #fff;
  border: 1px solid #d3dae6;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  cursor: pointer;

  .expires-type-label {
    font-size: 14px;
    font-weight: 500;
    color: #344563;
  }

  .expires-type-icon {
    width: 6px;
    height: 5px;
    padding: 0 12px;
  }
}

.expires-check {
  width: 13px;
  height: 10px;
  margin-top: 10px;
  margin-left: 12px;
  cursor: pointer;
}

.configuration {
  box-sizing: border-box;
  min-width: 92px;
  height: 26px;
  padding: 0 6px;
  margin-left: 12px;
  border-radius: 5px;
  background: #fff;
  border: 1px solid #d3dae6;
  display: flex;
  align-items: center;
  cursor: pointer;

  .configuration-label {
    flex: 1;
    text-align: center;
    font-size: 14px;
    font-weight: 500;
    color: #344563;
  }

  .configuration-icon {
    width: 6px;
    height: 5px;
    padding-left: 12px;
  }
}

.content {
  margin: 30px 0 30px 44px;
  display: flex;

  .qr-code-wrapper {
    width: 295px;
    height: 309px;
    border-radius: 10px;
    background: #fff;
    box-shadow: 0px 0px 6px 6px rgba(199, 199, 199, 0.1);

    .qr-code {
      width: 221px;
      height: 242px;
      padding: 18px 37px 0;
      box-sizing: border-box;
    }

    .qr-code-tip {
      font-size: 12px;
      font-weight: 500;
      color: #5e6c84;
      text-align: center;
      padding: 8px 0;
    }
  }

  .content-right {
    margin-left: 43px;
    flex: 1;
    .content-right-title {
      font-size: 16px;
      font-weight: 700;
      color: #344563;
      margin-bottom: 19px;
      display: flex;
      align-items: center;
    }

    .share-checkbox {
      ::v-deep .el-checkbox {
        // width: 120px;
        margin-right: 18px;
        margin-bottom: 14px;
        margin-left: 0 !important;
      }
    }

    .pwd-input {
      width: 128px;
      color: #344563;
      margin: 16px;
    }

    .pwd-check {
      width: 13px;
      height: 10px;
      margin-left: 12px;
    }
  }
}

.dialog-footer {
  height: 90px;
  background-color: #f5f7fa;
  border-radius: 0 0 10px 10px;
  display: flex;
  align-items: center;
  justify-content: center;

  .footer-input {
    width: 390px;

    ::v-deep .el-input__inner {
      font-size: 14px;
      font-weight: 500;
      color: #c5d0e3;
    }

    ::v-deep .el-input-group__append {
      width: 92px;
      padding: 0;
      text-align: center;
      background-color: #fff;
      font-size: 14px;
      font-weight: 500;
      color: #344563;
      cursor: pointer;
    }
  }

  .footer-btn {
    width: 154px;
    height: 40px;
    border-radius: 5px;
    margin-left: 18px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    .footer-btn-icon_wechat {
      width: 30px;
      height: 30px;
    }

    .footer-btn-icon_i3v {
      width: 23px;
      height: 23px;
    }

    .footer-btn-label {
      font-size: 14px;
      font-weight: 500;
      color: #fff;
      padding: 0 2px 0 6px;
    }

    .footer-btn-arrow {
      width: 16px;
      height: 16px;
    }

    &.wechat {
      background-color: #00baad;
    }

    &.i3v {
      background-color: var(--color-primary);
    }
  }
}
</style>
