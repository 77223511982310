import { BaseColumn } from "./BaseColumn";
import { TColumnaAlign } from "./ColumnTypes"

export class ButtonColumn extends BaseColumn {

    // public columnType: "button" = "button";

    /** 按钮svg图标类名 */
    public iconClass: string;
    /** 内嵌的按钮列的位置(默认在右边) */
    public position: 'left' | 'right';

    constructor(params: {
        id: string,
        iconClass: string,
        position?: 'left' | 'right',
        headerAlign?: TColumnaAlign,
        cellAlign?: TColumnaAlign,
        width?: number,
        minWidth?: number,
        active?: boolean,
        settingName?: string,
        title?: string,
        fieldOrder?: number,
    }) {
        const {
            id,

            iconClass,
            position = 'right',

            headerAlign,
            cellAlign,
            width, // 默认值 undefined
            minWidth, // 默认值 undefined

            active,
            settingName,
            title,
            fieldOrder,
        } = params;

        super({ id, active, settingName, title, fieldOrder, headerAlign, cellAlign, width, minWidth });

        if (!this.width && !this.minWidth) {
            this.minWidth = 80;
        }

        this.iconClass = iconClass;
        this.position = position;
    }
}