const session = {
  state: {
    isLoginPage: true,
    cacheList: [] //缓存页面
  },
  getters: {
    isLoginPage: state => state.isLoginPage,
    cacheList: state => state.cacheList,
  },
  mutations: {
    saveIsLoginPage(state, payload = false) {
      state.isLoginPage = payload;
    },
    saveCacheList(state, payload = []) {
      state.cacheList = payload;
    },
    addCacheList(state, payload) {
      if (!state.cacheList.includes(payload)) {
        state.cacheList.push(payload);
      }
    },
  }
};
export default session