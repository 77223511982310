const dict = {
  state: {
    moduleTypeDict: {
      "1": "product", // 内容类
      "2": "demonstration", // 演示方案类
      "3": "document", // 文件资源类
      "4": "tutorial", // 教学教程类
      "5": "article", // 文章类
      "6": "synthesis", // 综合展示类
    },
    columnCategoryTypeDict: {
      "1668175815360421890": "1", // 介绍
      "1668175907014352897": "2", // 文件清单栏目
      "1668175956930764801": "3", // 内容关联
      "a66c1759df930efg48a1": "4", // 链接
      "b66c1759df930efg48a1": "5", // 文章类
      "1668175956933764803": "4", // 链接
      "1668175956932764802": "5", // 文章类
    },
    customerTypeDict: [
      { dictName: "建筑BIM", dictValue: 1 },
      { dictName: "市政BIM", dictValue: 2 },
      { dictName: "结构设计", dictValue: 3 },
    ], // 客户类别
    customerNatureDict: [
      { dictName: "民营企业", dictValue: 1 },
      { dictName: "国有企业", dictValue: 2 },
      { dictName: "央属企业", dictValue: 3 },
      { dictName: "外资企业", dictValue: 4 },
    ], // 客户性质
    customerSizeDict: [
      { dictName: "大型企业", dictValue: 1 },
      { dictName: "中型企业", dictValue: 2 },
      { dictName: "小型企业", dictValue: 3 },
      { dictName: "微型企业", dictValue: 4 },
    ], // 客户规模
    industryDict: [
      { dictName: "施工单位", dictValue: 1 },
      { dictName: "设计院", dictValue: 2 },
    ], // 所属行业
    areaDict: [
      { dictName: "华东区域", dictValue: 1 },
      { dictName: "华南区域", dictValue: 2 },
      { dictName: "华中区域", dictValue: 3 },
      { dictName: "华北区域", dictValue: 4 },
    ], // 所属区域
  },
  getters: {
    moduleTypeDict: state => state.moduleTypeDict,
    columnCategoryTypeDict: state => state.columnCategoryTypeDict,
    customerTypeDict: state => state.customerTypeDict,
    customerNatureDict: state => state.customerNatureDict,
    customerSizeDict: state => state.customerSizeDict,
    industryDict: state => state.industryDict,
    areaDict: state => state.areaDict,
  },
};
export default dict