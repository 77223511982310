<template>
  <div class="ss-contain" v-if="searchdata.searchValue.data && searchdata.searchValue.data.length">
    <ViewType :modelname="searchdata.moduleName" :view.sync="viewType" />
    <ProductTable
      v-if="viewType != 'grid'"
      :datalist="searchdata.searchValue"
      :demonstrate="searchdata.type == 2"
    />
    <ProducCard
      v-if="viewType == 'grid'"
      :datalist="searchdata.searchValue"
      :demonstrate="searchdata.type == 2"
    />
  </div>
</template>

<script>
import ProductTable from "./ProductTable.vue";
import ProducCard from "./ProducCard.vue";
import ViewType from "./ViewType.vue";

export default {
  name: "quote",
  props: {
    searchdata: Object,
  },
  components: { ProductTable, ProducCard, ViewType },
  data() {
    return {
      viewType: "grid",
    };
  },
  computed: {},
  watch: {},
  created() {},
  mounted() {},
  methods: {},
};
</script>

<style lang="scss" scoped>
.ss-contain {
  // height: 100%;
  width: 100%;
  padding: 16px 7px;
  box-sizing: border-box;
  .quote-item {
    align-items: center;
    .item-cover {
      border-radius: 5px;
      height: 43px;
      line-height: 43px;
      img {
        width: 73px;
        height: 43px;
        border-radius: 5px;
        margin-right: 14px;
      }
    }
  }
}
::v-deep .el-divider--horizonta {
  margin: 15px 0;
}
.product-card__info-toolbar {
  .product-card__info-toolbar-item {
    width: 30px;
    height: 30px;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    margin-top: 7px;
    box-sizing: border-box;

    &.disabled {
      cursor: default;

      .svg-icon {
        color: #c4cbd7 !important;
      }
    }

    &:not(.disabled):hover {
      background-color: #e4ecf7;
    }
  }
}
.morelink-dropdown {
  box-shadow: 0px 0px 5px 2px rgba(94, 108, 132, 0.2);

  .el-dropdown-menu__item {
    line-height: 32px;
    font-size: 14px;
    font-weight: 500;
    color: #344563;
    border-radius: 4px;
    padding: 0;
    margin: 0 8px;

    &:hover {
      color: var(--color-text-primary);
      background-color: #fbfaff;
    }

    .filter-dropdown-item {
      position: relative;
      line-height: 32px;
      padding: 0 10px 0 10px;
      .title {
        width: 96px;
        color: #6a87fc;
      }
      .item {
        display: flex;
        align-items: center;
        &:hover {
          color: var(--color-primary);
          .svg-icon {
            color: var(--color-primary) !important;
          }
        }
      }
    }
  }
}
</style>
