<template>
  <div class="overview-unit" :style="{ color }" @click="onClick">
    <div class="title">{{ title }}</div>
    <div class="num">{{ number }}</div>
  </div>
</template>
<script lang="ts">
import Vue from "vue";
export default Vue.extend({
  inheritAttrs: false,
  name: "OverviewUnit",
  props: {
    title: { type: String },
    number: { type: Number },
    color: { type: String, default: "#347EFF" },
  },
  methods: {
    onClick() {
      this.$emit("unit-click");
    },
  },
});
</script>
<style lang="scss" scoped>
@import "src/flexible-table-module/scss/_variables.scss";

.overview-unit {
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  padding: 16px;
  cursor: pointer;

  &:hover {
    background-color: $background-base;
  }

  $title-height: 32px;

  .title {
    width: 100%;
    height: $title-height;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .num {
    width: 100%;
    height: calc(100% - $title-height);
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 32px;
    font-weight: bold;
  }
}
</style>