<template>
  <div>
    <div class="flex-between">
      <div>{{ modelname }}</div>
      <div class="filelist-view">
        <el-tooltip effect="dark" content="网格视图" placement="bottom">
          <div
            class="filelist-view-item"
            :class="{ 'is-active': viewType === 'grid' }"
            @click="changeViewType('grid')"
          >
            <svg-icon
              icon="card-mode"
              width="16"
              height="16"
              :color="viewType === 'grid' ? '4f5d74' : '#9facc2'"
            ></svg-icon>
          </div>
        </el-tooltip>
        <el-tooltip effect="dark" content="列表视图" placement="bottom">
          <div
            class="filelist-view-item"
            :class="{ 'is-active': viewType === 'list' }"
            @click="changeViewType('list')"
          >
            <svg-icon
              icon="list-mode"
              width="16"
              height="16"
              :color="viewType === 'list' ? '4f5d74' : '#9facc2'"
            ></svg-icon>
          </div>
        </el-tooltip>
      </div>
    </div>
    <el-divider></el-divider>
  </div>
</template>

<script>
export default {
  props: {
    modelname: String,
    view: String,
  },
  data() {
    return {
      // viewType: "grid",
    };
  },
  computed: {
    viewType: {
      get() {
        return this.view;
      },
      set(val) {
        this.$emit("update:view", val);
      },
    },
  },
  watch: {},
  created() {},
  mounted() {},
  methods: {
    /**
     * @description: 切换格子/列表视图
     * @param {Object} value 点击项
     * @return {void}
     */
    changeViewType(type) {
      this.viewType = type;
    },
  },
};
</script>

<style lang="scss" scoped>
.filelist-view {
  height: 24px;
  border-radius: 5px;
  border: 1px solid #d3dae6;
  margin: 0 10px;
  background: #fff;
  display: flex;
  overflow: hidden;

  .filelist-view-item {
    box-sizing: border-box;
    width: 30px;
    height: 24px;
    border-right: 1px solid #d3dae6;
    background-color: #fff;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;

    &:last-child {
      border-right: none;
    }

    &:hover {
      background-color: #fcfdfe;
    }

    &.is-active {
      background-color: #f0f3f7;
    }
  }
}
</style>
