<template>
  <div class="cards">
    <div class="cards-inner" :style="{ width: `${cardsInnerWidth}px` }">
      <template v-if="data">
        <template v-if="data.length > 0">
          <template v-for="(row, i) in data">
            <div
              class="card"
              :key="i"
              :style="{
                width: `${cardWidth}px`,
                minHeight: `${minHeight}px`,
                marginRight: (i + 1) % colCount > 0 ? cardMarginPx : undefined,
                marginBottom: cardMarginPx,
              }"
            >
              <slot name="card" v-bind="{ row }"></slot>
            </div>
          </template>
        </template>

        <template v-else>
          <ElEmpty description="暂无数据"></ElEmpty>
        </template>
      </template>
    </div>
  </div>
</template>

<script lang="ts">
import Vue, { PropType } from "vue";
import { Empty as ElEmpty } from "element-ui";
import { Table } from "@/flexible-table-module/entity/Table";
import { IBaseTableData } from "@/flexible-table-module/entity/Table/IBaseTableData";

const DEFAULT_CARD_WIDTH = 320;
const MIN_CARD_HEIGHT = 200;
const CARD_MARGIN = 16;

export default Vue.extend({
  inheritAttrs: false,
  name: "Cards",
  components: { ElEmpty },
  props: {
    table: { type: Object as PropType<Table<IBaseTableData>> }, // 表格定义
    data: { type: Array as PropType<IBaseTableData[]> }, // 表格数据
    cardWidth: { type: Number, default: DEFAULT_CARD_WIDTH }, // 卡片固定宽度
    containerWidth: { type: Number, default: DEFAULT_CARD_WIDTH }, // 卡片容器的可变宽度
  },
  data(): {
    minHeight: number;
    cardMarginPx: string;
  } {
    return {
      minHeight: MIN_CARD_HEIGHT,
      cardMarginPx: `${CARD_MARGIN}px`,
    };
  },
  computed: {
    colCount(): number {
      return Math.floor(
        (this.containerWidth + CARD_MARGIN) / (this.cardWidth + CARD_MARGIN)
      );
    },
    cardsInnerWidth(): number {
      return (this.cardWidth + CARD_MARGIN) * this.colCount - CARD_MARGIN;
    },
  },
});
</script>

<style lang="scss" scoped>
@import "src/flexible-table-module/scss/_variables.scss";

.cards {
  width: 100%;
  display: flex;
  justify-content: center;

  .cards-inner {
    overflow: hidden;

    .card {
      box-sizing: border-box;
      float: left;
      overflow: hidden;
      border: 1px solid $border-color;
      border-radius: 8px;
    }
  }
}
</style>