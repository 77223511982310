import { render, staticRenderFns } from "./SelectColumnData.vue?vue&type=template&id=46575210&scoped=true"
import script from "./SelectColumnData.vue?vue&type=script&lang=js"
export * from "./SelectColumnData.vue?vue&type=script&lang=js"
import style0 from "./SelectColumnData.vue?vue&type=style&index=0&id=46575210&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "46575210",
  null
  
)

export default component.exports