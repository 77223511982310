<template>
  <div class="form-cell-4-date-time-single">
    <template v-if="columnType === 'time'">
      <ElTimePicker
        :class="{ highlight }"
        v-model="cValue"
        :size="FORM_ITEM_SIZE"
        class="form-cell-4-date-time-single__input"
      ></ElTimePicker>
    </template>
    <template v-else-if="columnType === 'date' || columnType === 'datetime'">
      <ElDatePicker
        :class="{ highlight }"
        v-model="cValue"
        :size="FORM_ITEM_SIZE"
        class="form-cell-4-date-time-single__input"
        :type="columnType"
      ></ElDatePicker>
    </template>
  </div>
</template>
<script lang="ts">
import Vue from "vue";
import { mixin } from "./mixin";

// import { TimeSelect as ElTimeSelect } from "element-ui";
import {
  TimePicker as ElTimePicker,
  DatePicker as ElDatePicker,
} from "element-ui";

export default Vue.extend({
  inheritAttrs: false,
  name: "FormCell4DateTimeSingle",
  components: { ElTimePicker, ElDatePicker },
  mixins: [mixin],
  props: {
    columnType: {
      type: String,
      required: true,
      validator(value: "time" | "date" | "datetime") {
        return ["time", "date", "datetime"].includes(value);
      },
    },
  },
});
</script>
<style lang="scss" scoped>
@import "src/flexible-table-module/scss/common.scss";
.form-cell-4-date-time-single {
  @include form-cell-highlight;

  &__input {
    width: 100% !important;
  }
}
</style>