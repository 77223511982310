import { DataOverviewItem } from "./DataOverview/DataOverviewItem";

export class DataOverview {
    /** 所有的项目(延迟加载) */
    public allItems?: DataOverviewItem[];
    /** 默认展开 */
    public defaultFold: boolean;
    /** 被激活的项目 */
    public activeItems: DataOverviewItem[];

    constructor(params: {
        allItems?: DataOverviewItem[],
        defaultFold?: boolean,
        activeItems: DataOverviewItem[],
    }) {
        const { allItems, defaultFold = false, activeItems } = params;
        this.allItems = allItems;
        this.defaultFold = defaultFold;
        this.activeItems = activeItems;
    }
}