<template>
  <el-dialog
    append-to-body
    class="ss-dialog"
    title="配置"
    width="554px"
    :visible.sync="dialogVisible"
  >
    <div class="content">
      <div class="content-title">设置默认</div>
      <div class="content-select">
        <el-radio-group v-model="defaultbutton">
          <el-radio :label="item.id" v-for="item in buttonlist" :key="item.id">{{
            item.itemText
          }}</el-radio>
        </el-radio-group>
      </div>
      <div class="content-title">悬浮图标</div>
      <div class="content-select">
        <el-radio v-model="isfixed" :label="1">固定</el-radio>
        <el-radio v-model="isfixed" :label="0">自动隐藏</el-radio>
      </div>
    </div>
    <div slot="footer">
      <el-button type="info" @click="dialogVisible = false">取 消</el-button>
      <el-button type="primary" :loading="confirmLoading" @click="handleConfirm"
        >确 定</el-button
      >
    </div>
  </el-dialog>
</template>

<script>
import SaaS from "@/api/SaaS.js";
import { Users } from "@/api/Users";
export default {
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    buttonlist: Array,
  },
  data() {
    return {
      defaultbutton: "",
      isfixed: 1,
      confirmLoading: false,
    };
  },
  computed: {
    dialogVisible: {
      get() {
        return this.visible;
      },
      set(val) {
        this.$emit("update:visible", val);
      },
    },
  },
  mounted() {
    let button = this.buttonlist.filter((item) => item.isDefault);
    this.isfixed = this.buttonlist[0].isFixed;
    this.defaultbutton = button[0].id;
  },
  methods: {
    /**
     * @description: 确定
     * @return {void}
     */
    async handleConfirm() {
      this.confirmLoading = true;
      const res = await Users.UserbuttonsetDefault(this.defaultbutton);
      const res2 = await Users.UserbuttonsetFixed(this.isfixed);
      if (res && res2) {
        this.$message.success("成功");
        this.$emit("finish");
        this.dialogVisible = false;
      }
      this.confirmLoading = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.content {
  padding: 20px 60px;
  max-height: 60vh;
  overflow: hidden;

  .content-select{
    height: 70px;
    line-height: 70px;
    padding-left: 40px;
  }
}
</style>
