<template>
  <div
    class="side-single-item"
    @mouseenter="onItemMouseenter"
    @mouseleave="onItemMouseleave"
  >
    <div
      class="item-drag-handler"
      draggable="true"
      @dragstart="onDragStart"
      @dragend="onDragEnd"
    >
      <i class="el-icon-rank"></i>
    </div>
    <div class="item-title">
      {{ filter.title }}
    </div>
    <div class="item-delete" v-if="isMouseOver">
      <div class="item-delete-inner" @click="onItemDeleteClick">
        <!-- <IconDelete :size="8" /> -->
        <svg-icon sys="common-table" name="ViewEditor/delete" :size="8" />
      </div>
    </div>
    <div class="item-switch">
      <ElSwitch
        v-model="filter.active"
        :active-color="SWITCH_ACTIVE_COLOR"
        :inactive-color="SWITCH_INACTIVE_COLOR"
        @change="onSwitchChange"
      ></ElSwitch>
    </div>
  </div>
</template>
<script lang="ts">
import Vue, { PropType } from "vue";
import { Switch as ElSwitch } from "element-ui";
import SvgIcon from "@/flexible-table-module/svg-icon-importer";
import PubSub from "pubsub-js";
// import IconDelete from "@/flexible-table-module/icons/ViewEditor/IconDelete.vue";

import { Filter } from "@/flexible-table-module/entity/Filter";
import {
  FILTER_DRAG_DROP_FORMAT,
  SWITCH_ACTIVE_COLOR,
  SWITCH_INACTIVE_COLOR,
} from "@/flexible-table-module/constants";

export default Vue.extend({
  inheritAttrs: false,
  name: "SideSingleItem",
  components: {
    ElSwitch,
    SvgIcon,
    // IconDelete,
  },
  props: {
    filter: { type: Object as PropType<Filter>, required: true },
  },
  data() {
    return {
      SWITCH_ACTIVE_COLOR,
      SWITCH_INACTIVE_COLOR,
      isMouseOver: false,
    };
  },
  methods: {
    onDragStart(ev: DragEvent) {
      if (ev.dataTransfer) {
        ev.dataTransfer.dropEffect = "copy";
        ev.dataTransfer.setData(
          FILTER_DRAG_DROP_FORMAT,
          JSON.stringify(this.filter)
        );
      }

      PubSub.publish("side-single-item-drag-start");
    },

    onDragEnd() {
      PubSub.publish("side-single-item-drag-end");
    },

    onSwitchChange(val: boolean) {
      this.$emit("switch-change", this.filter, val);
    },

    onItemMouseenter() {
      this.isMouseOver = true;
    },

    onItemMouseleave() {
      this.isMouseOver = false;
    },

    onItemDeleteClick() {
      this.$emit("delete", this.filter);
    },
  },
});
</script>
<style lang="scss" scoped>
@import "src/flexible-table-module/scss/_variables.scss";

$item-height: 24px;

.side-single-item {
  height: $item-height;
  line-height: $item-height;

  .item-drag-handler {
    width: $item-height;
    height: $item-height;
    float: left;
    cursor: grab;
    display: flex;
    justify-content: center;
    align-items: center;

    &:hover {
      background-color: $background-base;
    }
  }

  .item-title {
    height: $item-height;
    color: $color-base;
    float: left;

    // &:hover {
    //   cursor: pointer;
    // }
  }

  .item-delete {
    height: $item-height;
    fill: $color-base;
    float: left;
    display: flex;
    align-items: center;
    margin-left: 4px;

    .item-delete-inner {
      width: 16px;
      height: 16px;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      border-radius: 4px;

      &:hover {
        fill: $color-reverse;
        background-color: $color-info;
      }
    }
  }

  .item-switch {
    height: $item-height;
    float: right;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
</style>