<template>
  <div
    :id="uniqueId"
    class="flexible-table"
    :class="{
      'has-side-filter-bar-shrink-btn': hasSideFilterBarShrinkBtn,
      'side-filter-bar-shrinked': !isFiltersSiderVisible,
      'has-operation-col-shrink-btn': hasOperationColumnShrinkBtn,
      'operation-col-shrinked': isLastColShrink,
      'no-columms-fixed': !currFixedCols || currFixedCols.length === 0,
      'opt-btns-position-auto-hide': isOptBtnsPositionAutoHide,
    }"
    :style="rootStyle"
  >
    <div
      v-if="hasSideFilter"
      v-show="isFiltersSiderVisible"
      class="side spread"
      :style="{ width: `${SIDE_SPREAD_WIDTH}px` }"
    >
      <SelectionFilters
        v-loading="loadingFilters"
        ref="SelectionFilters"
        :headerHeight="currTableHeaderHeight ? currTableHeaderHeight : 36"
        :hasShrinkBtn="hasSideFilterBarShrinkBtn"
        :isShrink="!isFiltersSiderVisible"
        :width="SIDE_SPREAD_WIDTH"
        :filters4ViewEditor="filters4ViewEditor"
        :filters4ViewEditorArchived="filters4ViewEditorArchived"
        :filters4ViewEditorAll="filters4ViewEditorAll"
        :activeFilters="activeFilters"
        :currFilter="currFilter"
        :multiFilters="multiFilters"
        :viewFilters="viewFilters"
        :disableFilterDetail="loadingTableFormat || loadingTableRows"
        :historySelections="historySelections"
        @btn-shrink-click="$emit('btn-shrink-click')"
        @selection-filter-change="onSelectionFilterChange"
        @selection-list-checkbox-change="onSelectionListCheckboxChange"
        @selection-list-single-click="onSelectionListSingleClick"
        @view-editor-show="onViewEditorShow"
        @view-editor-close="onViewEditorClose"
        @single-filter-switch="
          (filter, checked) => $emit('single-filter-switch', filter, checked)
        "
        @single-filter-archive="
          (filter) => $emit('single-filter-archive', filter)
        "
        @add-new-multiple-filter="
          (multiFilter) => $emit('add-new-multiple-filter', multiFilter)
        "
        @edit-title-multiple-filter="
          (multiFilter) => $emit('edit-title-multiple-filter', multiFilter)
        "
        @edit-filter-multiple-filter="
          (multiFilter) => $emit('edit-filter-multiple-filter', multiFilter)
        "
        @add-new-view-filter="
          (viewFilter) => $emit('add-new-view-filter', viewFilter)
        "
        @edit-title-view-filter="
          (viewFilter) => $emit('edit-title-view-filter', viewFilter)
        "
        @edit-filter-view-filter="
          (viewFilter, node) =>
            $emit('edit-filter-view-filter', viewFilter, node)
        "
        @del-multiple-filter="
          (multiFilter) => $emit('del-multiple-filter', multiFilter)
        "
        @del-view-filter="(viewFilter) => $emit('del-view-filter', viewFilter)"
        @single-conditions-editor-submit="
          (filtersUnarchived, callback) =>
            $emit(
              'single-conditions-editor-submit',
              filtersUnarchived,
              callback
            )
        "
      />
    </div>

    <div
      v-if="hasSideFilter && hasSideFilterBarShrinkBtn"
      v-show="!isFiltersSiderVisible"
      class="side narrow"
    >
      <div class="header" :style="{ height: `${currTableHeaderHeight}px` }">
        <IconBtnSingle
          class="icon-btn-single-fold"
          v-show="!isFiltersSiderVisible"
          :iconSize="ICON_BTN_SIZE"
          @btn-click="$emit('btn-shrink-click')"
        >
          <svg-icon
            sys="common-table"
            name="OccupyingColumn/fold"
            :size="ICON_BTN_ICON_SIZE"
          />
        </IconBtnSingle>
      </div>
    </div>

    <div
      class="main"
      :style="{
        width: hasSideFilter
          ? `calc(100% - ${
              isFiltersSiderVisible ? SIDE_SPREAD_WIDTH : SIDE_NARROW_WIDTH
            }px)`
          : '100%',
      }"
    >
      <div
        class="table-container"
        ref="cards-and-table-container"
        v-loading="loadingTableFormat || loadingTableRows"
        :style="containerStyle"
      >
        <Cards
          v-if="cardsRendered"
          v-show="cardsRendered && currView === 'cards'"
          :table="table"
          :data="data"
          :cardWidth="cardWidth"
          :containerWidth="containerWidth"
        >
          <template v-slot:card="slotProps">
            <slot name="card" v-bind="slotProps"></slot>
          </template>
        </Cards>

        <div
          v-if="tableRendered && currView === 'table'"
          class="table-container__fixed-btns"
          :style="{ height: `${currTableHeaderHeight}px` }"
        >
          <template v-if="hasOperationColumnShrinkBtn">
            <IconBtnSingle
              class="icon-btn-single-fold"
              v-show="!isLastColShrink"
              :iconSize="ICON_BTN_SIZE"
              @btn-click="$emit('operation-column-shrink-btn-click')"
            >
              <svg-icon
                sys="common-table"
                name="OccupyingColumn/fold"
                :size="ICON_BTN_ICON_SIZE"
              />
            </IconBtnSingle>

            <IconBtnSingle
              class="icon-btn-single-fold"
              v-show="isLastColShrink"
              :iconSize="ICON_BTN_SIZE"
              @btn-click="$emit('operation-column-shrink-btn-click')"
            >
              <svg-icon
                sys="common-table"
                name="OccupyingColumn/fold"
                :size="ICON_BTN_ICON_SIZE"
                :rotation="180"
              />
            </IconBtnSingle>
          </template>

          <IconBtnSingle
            class="icon-btn-columns-setting"
            :iconSize="ICON_BTN_SIZE"
            @btn-click="$emit('columns-setting-btn-click')"
          >
            <svg-icon
              :sys="'common-table'"
              :name="'ColumnsSetting/gear'"
              :size="ICON_BTN_ICON_SIZE"
            />
          </IconBtnSingle>
        </div>

        <AutoHideAgent
          v-if="tableRendered && isOptBtnsPositionAutoHide"
          v-show="tableRendered && currView === 'table' && !isAutoHideVisible"
          class="table-container__auto-hide-agent"
          :offsetTop="currTableHeaderHeight"
          @show-auto-hide-column="onShowAutoHideColumn"
          @hide-auto-hide-column="onHideAutoHideColumn"
        />

        <ElTable
          v-if="tableRendered"
          v-show="tableRendered && currView === 'table'"
          ref="table"
          class="table"
          :class="{
            'is-tree': isTree,
            'no-vertical-border': !hasVerticalBorder,
          }"
          :data="data"
          :height="containerHeight"
          :border="true"
          :style="{ width: '100%' }"
          :row-key="isTree ? rowKey : undefined"
          :indent="treeArrowIndent"
          :tree-props="
            isTree && (treeMode === 'original' || treeMode === 'original+')
              ? { children: 'children', hasChildren: 'hasChildren' }
              : undefined
          "
          :lazy="isTree ? true : undefined"
          :load="
            isTree && (treeMode === 'original' || treeMode === 'original+')
              ? onLoad
              : undefined
          "
          :span-method="onMerge"
          :row-class-name="onRowClassName"
          @header-dragend="onHeaderDragend"
          @selection-change="onMultiSelectionChange"
          @expand-change="onExpandChange"
        >
          <!-- && !loadingTableFormat 用于解决：在搜索栏有搜索条件时或者左侧筛选栏有选择时，列筛选器的输入值不会提交给后端 -->
          <template v-if="table && !loadingTableFormat">
            <!-- 占位列 -->
            <!-- <OccupyingColumn
              v-if="hasSideFilterBarShrinkBtn"
              :width="isFiltersSiderVisible ? 1 : 32"
              :fixed="'left'"
            >
              <template v-slot:header>
                <IconBtnSingle
                  class="icon-btn-single-fold"
                  v-show="!isFiltersSiderVisible"
                  :iconSize="ICON_BTN_SIZE"
                  @btn-click="$emit('btn-shrink-click')"
                >
                  <svg-icon
                    sys="common-table"
                    name="OccupyingColumn/fold"
                    :size="ICON_BTN_ICON_SIZE"
                  />
                </IconBtnSingle>
              </template>
            </OccupyingColumn> -->

            <!-- 多选列 -->
            <template v-if="hasCheckboxColumn">
              <MultiSelectionColumn
                :isSelectionReserved="isSelectionReserved"
              />
            </template>

            <!-- 单选列 -->
            <template v-else-if="hasRadioColumn">
              <SingleSelectionColumn
                ref="refSingleSelectionColumn"
                :isSelectionReserved="isSelectionReserved"
                @selection-change="onSingleSelectionChange"
              />
            </template>

            <template v-for="(col, index) in table.columns">
              <ElTableColumn
                v-if="col.active"
                :key="col.id + index"
                :prop="col.id"
                :label="col.title"
                :width="
                  hasOperation &&
                  table &&
                  table.optBtnsPosition === 'within-col' &&
                  table.optBtnsTarget &&
                  table.optBtnsTarget.id === col.id
                    ? operationWidth > (col.width || 0)
                      ? operationWidth
                      : col.width
                    : col.width
                "
                :min-width="col.minWidth"
                :header-align="col.headerAlign"
                :formatter="(...params) => onFormat(col, params[2])"
                :fixed="currFixedCols.length > 0 && currFixedCols.includes(col)"
                :type="tableColumnType(col, index)"
              >
                <!-- :align="
                  isTree && isNormalColumn(col) && isTreeArrowCol(col, index)
                    ? 'left'
                    : col.cellAlign
                " -->
                <template v-slot:header="scope">
                  <template v-if="isIndexColumn(col)">
                    <!-- <div class="title index-title">序号</div> -->
                    <HeaderCellNormal
                      :style="{
                        width: headerWidth(col.width),
                        minWidth: headerMinWidth(col.minWidth),
                        margin: headerMargin(),
                      }"
                      :title="'序号'"
                    />
                  </template>
                  <template v-else-if="isIconColumn(col)">
                    <!-- <div class="title icon-title">头像</div> -->
                    <HeaderCellNormal
                      :style="{
                        width: headerWidth(col.width),
                        minWidth: headerMinWidth(col.minWidth),
                        margin: headerMargin(),
                      }"
                      :title="'头像'"
                    />
                  </template>
                  <HeaderCellIconBtn
                    v-else
                    :style="{
                      width: headerWidth(col.width),
                      minWidth: headerMinWidth(col.minWidth),
                      margin: headerMargin(),
                    }"
                    :isFilterable="isColumnFilterable"
                    :scope="scope"
                    :column="col"
                    :isFreezed="
                      currFixedCols.length > 0 && currFixedCols.includes(col)
                    "
                    :sort="currSorts.find((sort) => sort.columnId === col.id)"
                    @header-dropdown-command="onHeaderDropdownCommand"
                    @filter-submit="onHeaderFilterSubmit"
                    @filter-cancel="onHeaderFilterCancel"
                    @filter-change="onHeaderFilterChange"
                  />
                </template>

                <template v-slot:default="scope">
                  <template v-if="!isFormRow(scope.row)">
                    <!-- 以下写法，是用于加上树形表格箭头列的无子级根节点的缩进 -->
                    <template
                      v-if="
                        isTree &&
                        treeMode === 'original' &&
                        isFirstCol(col, index) &&
                        isRootNodeWithNoChildren(scope.row)
                      "
                    >
                      <span class="el-table__placeholder"></span>
                    </template>
                    <template
                      v-if="
                        isTree &&
                        treeMode === 'custom' &&
                        isTreeArrowCol(col, index)
                      "
                    >
                      <TreeArrow
                        :row="scope.row"
                        :width="treeArrowWidth"
                        :indent="treeArrowIndent"
                        @cell-tree-arrow-click="
                          onCellTreeArrowClick(scope.row, arguments[1])
                        "
                      />
                    </template>

                    <CellInsideContent
                      :style="
                        getCellInsideContentStyle(
                          col,
                          index,
                          scope.row,
                          scope.treeNode
                        )
                      "
                      :table="table"
                      :col="col"
                      :index="index"
                      :scope="scope"
                      :isTree="isTree"
                      :isFreezed="
                        currFixedCols.length > 0 && currFixedCols.includes(col)
                      "
                      :hasOperation="hasOperation"
                      :getCellDropdownData="getCellDropdownData"
                      :confirmCellSelection="confirmCellSelection"
                      :isTreeArrowCol="
                        isTree &&
                        ((treeMode === 'original' && isFirstCol(col, index)) ||
                          (treeMode !== 'original' &&
                            isTreeArrowCol(col, index)))
                      "
                      @column-button-click="onColumnButtonClick"
                      @cell-corner-tip-click="onCornerTipClick"
                      @cell-click="(row, col) => $emit('cell-click', row, col)"
                    >
                      <template v-slot:button-column-icon="{ iconClass, data }">
                        <slot
                          name="button-column-icon"
                          v-bind="{
                            iconClass,
                            data,
                          }"
                        ></slot>
                      </template>
                      <template v-slot:operation>
                        <slot name="operation" v-bind="scope.row"></slot>
                      </template>
                      <template v-slot:cell-bottom="{ row, col }">
                        <slot name="cell-bottom" v-bind="{ row, col }"></slot>
                      </template>
                      <template v-slot:cell-left="{ row, col }">
                        <slot name="cell-left" v-bind="{ row, col }"></slot>
                      </template>
                    </CellInsideContent>
                  </template>
                  <template v-else>
                    <InlineFormCell
                      :formRow="scope.row"
                      :column="col"
                      :getCellDropdownData="getCellDropdownData"
                      @form-cell-input="onFormCellInput"
                    >
                      <template v-slot="{ row, col }">
                        <slot name="form-cell" v-bind="{ row, col }"></slot>
                      </template>
                    </InlineFormCell>
                  </template>
                </template>
              </ElTableColumn>

              <template
                v-if="
                  hasOperation &&
                  table &&
                  table.optBtnsPosition === 'next-to-col' &&
                  table.optBtnsTarget &&
                  table.optBtnsTarget.id === col.id
                "
              >
                <!-- 操作按钮特殊列(单独一列) -->
                <ContainerForNextToCol
                  :key="col.id + '-next-to-col'"
                  :width="operationWidth"
                >
                  <template v-slot:header>
                    <div
                      class="header-operation"
                      :style="{
                        width: headerWidth(operationWidth),
                        margin: headerMargin(),
                      }"
                    >
                      <span>操作</span>
                    </div>
                  </template>
                  <template v-slot:operation="{ row }">
                    <template v-if="!isFormRow(row)">
                      <slot name="operation" v-bind="row"></slot>
                    </template>
                  </template>
                </ContainerForNextToCol>
              </template>
            </template>
          </template>

          <template
            v-if="hasOperation && table && table.optBtnsPosition === 'last-col'"
          >
            <template v-if="!isLastColShrink">
              <!-- 操作按钮特殊列(固定在最后一列) -->
              <ContainerForLastCol :width="operationWidth">
                <!-- <template v-slot:shrink-btn>
                    <slot name="operation-shrink-btn"></slot>
                  </template> -->
                <template v-slot:header>
                  <div
                    class="header-operation"
                    :style="{
                      width: headerWidth(operationWidth),
                      margin: headerMargin(),
                    }"
                  >
                    <span>操作</span>
                    <slot name="operation-shrink-btn"></slot>
                  </div>
                </template>
                <template v-slot:operation="{ row }">
                  <template v-if="!isFormRow(row)">
                    <slot name="operation" v-bind="row"></slot>
                  </template>
                </template>
              </ContainerForLastCol>
            </template>
          </template>

          <template v-if="isOptBtnsPositionAutoHide">
            <!-- 操作按钮特殊列(自动隐藏在最右边) -->
            <ContainerForAutoHide
              :tableUniqueKey="uniqueId"
              :width="operationWidth"
              :isVisible.sync="isAutoHideVisible"
            >
              <!-- <template v-slot:shrink-btn>
                    <slot name="operation-shrink-btn"></slot>
                  </template> -->
              <template v-slot:header>
                <div
                  class="header-operation"
                  :style="{
                    width: headerWidth(operationWidth),
                    margin: headerMargin(),
                  }"
                >
                  <span>操作</span>
                </div>
              </template>
              <template v-slot:operation="{ row }">
                <template v-if="!isFormRow(row)">
                  <slot name="operation" v-bind="row"></slot>
                </template>
              </template>
            </ContainerForAutoHide>
          </template>

          <!-- <OccupyingColumn
            :width="hasOperationColumnShrinkBtn ? 64 : 32"
            :fixed="'right'"
            :className="'right-occupying-column'"
          >
            <template v-slot:header>
              <template v-if="hasOperationColumnShrinkBtn">
                <IconBtnSingle
                  class="icon-btn-single-fold"
                  v-show="!isLastColShrink"
                  :iconSize="ICON_BTN_SIZE"
                  @btn-click="$emit('operation-column-shrink-btn-click')"
                >
                  <svg-icon
                    sys="common-table"
                    name="OccupyingColumn/fold"
                    :size="ICON_BTN_ICON_SIZE"
                  />
                </IconBtnSingle>

                <IconBtnSingle
                  class="icon-btn-single-fold"
                  v-show="isLastColShrink"
                  :iconSize="ICON_BTN_SIZE"
                  @btn-click="$emit('operation-column-shrink-btn-click')"
                >
                  <svg-icon
                    sys="common-table"
                    name="OccupyingColumn/fold"
                    :size="ICON_BTN_ICON_SIZE"
                    :rotation="180"
                  />
                </IconBtnSingle>
              </template>

              <IconBtnSingle
                :iconSize="ICON_BTN_SIZE"
                @btn-click="$emit('columns-setting-btn-click')"
              >
                <svg-icon
                  :sys="'common-table'"
                  :name="'ColumnsSetting/gear'"
                  :size="ICON_BTN_ICON_SIZE"
                />
              </IconBtnSingle>
            </template>
          </OccupyingColumn> -->
        </ElTable>
      </div>

      <div class="pagination" :style="{ height: `${paginationSize}px` }">
        <div class="other-info">
          <slot name="otherInfo"></slot>
        </div>
        <PaginationWrapper
          @size-change="onSizeChange"
          @current-change="onCurrPageChange"
          :current-page="currPage"
          :page-sizes="pageSizes"
          :page-size="pageSize"
          layout="total, sizes, prev, pager, next"
          :total="totalCount"
        >
        </PaginationWrapper>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import Vue, { PropType, type DirectiveOptions } from "vue";
import {
  Table as ElTable,
  TableColumn as ElTableColumn,
  Pagination as ElPagination,
} from "element-ui";

import { Loading } from "@/flexible-table-module/components/Common/Loading";

import { cellValueFormatter } from "@/flexible-table-module/util/CellHelper";
import SvgIcon from "@/flexible-table-module/svg-icon-importer";
import HeaderCellIconBtn from "@/flexible-table-module/components/FlexibleTable/HeaderCellIconBtn.vue";
import HeaderCellNormal from "@/flexible-table-module/components/FlexibleTable/HeaderCellNormal.vue";
import MultiSelectionColumn from "@/flexible-table-module/components/FlexibleTable/MultiSelectionColumn.vue";
import SingleSelectionColumn from "@/flexible-table-module/components/FlexibleTable/SingleSelectionColumn.vue";
import SelectionFilters from "@/flexible-table-module/components/FlexibleTable/SelectionFilters.vue";
import ContainerForLastCol from "@/flexible-table-module/components/FlexibleTable/OperationBtnsContainer/ContainerForLastCol.vue";
import ContainerForAutoHide from "@/flexible-table-module/components/FlexibleTable/OperationBtnsContainer/ContainerForAutoHide.vue";
import ContainerForNextToCol from "@/flexible-table-module/components/FlexibleTable/OperationBtnsContainer/ContainerForNextToCol.vue";
import Cards from "@/flexible-table-module/components/FlexibleTable/Cards.vue";
import InlineFormCell from "@/flexible-table-module/components/FlexibleTable/InlineFormCell.vue";
import CellInsideContent from "@/flexible-table-module/components/FlexibleTable/CellInsideContent.vue";
import TreeArrow from "@/flexible-table-module/components/FlexibleTable/TreeArrow.vue";
import AutoHideAgent from "@/flexible-table-module/components/FlexibleTable/AutoHideAgent.vue";
import OccupyingColumn from "@/flexible-table-module/components/FlexibleTable/OccupyingColumn.vue";
import IconBtnSingle from "@/flexible-table-module/components/Common/IconBtnSingle.vue";
import PaginationWrapper from "@/flexible-table-module/components/Common/PaginationWrapper.vue";

import { Table, ColumnType } from "@/flexible-table-module/entity/Table";
import { Sort } from "@/flexible-table-module/entity/Table/Sort";
import {
  Filter,
  MultiFilter,
  TypeFilterConditions,
  ViewFilter,
} from "@/flexible-table-module/entity/Filter";
import { Column } from "@/flexible-table-module/entity/Table/Column";
import { IconColumn } from "@/flexible-table-module/entity/Table/IconColumn";
import { ButtonColumn } from "@/flexible-table-module/entity/Table/ButtonColumn";
import { IndexColumn } from "@/flexible-table-module/entity/Table/IndexColumn";
import { PercentColumn } from "../entity/Table/PercentColumn";
import { IBaseTableData } from "@/flexible-table-module/entity/Table/IBaseTableData";
import { FormRow } from "@/flexible-table-module/entity/Table/FormRow";
import { BaseColumn } from "@/flexible-table-module/entity/Table/BaseColumn";
import { TFormColumn } from "../entity/Table/TFormColumn";
import { TreeRecord } from "../entity/Table/TreeRecord";
import {
  KeyStrValuePair,
  KeyValuePair,
} from "@/flexible-table-module/entity/KeyValuePair";
import { TColumnType } from "@/flexible-table-module/entity/Table/ColumnTypes";
import { HistorySelections } from "@/flexible-table-module/entity/HistorySelections";

import {
  TABLE_PAGE_SIZES,
  STORAGE_NAMESPACE,
  ICON_BTN_SIZE,
  ICON_BTN_ICON_SIZE,
} from "@/flexible-table-module/constants";
import { IFilterListItem } from "@/flexible-table-module/entity/BaseFilter";

const HEADER_MARGIN = 10;

const SIDE_SPREAD_WIDTH = 200;
const SIDE_NARROW_WIDTH = 24;

export default Vue.extend({
  inheritAttrs: false,
  name: "FlexibleTable",
  components: {
    ElTable,
    ElTableColumn,
    ElPagination,
    SvgIcon,
    HeaderCellIconBtn,
    HeaderCellNormal,
    MultiSelectionColumn,
    SingleSelectionColumn,
    SelectionFilters,
    ContainerForLastCol,
    ContainerForAutoHide,
    ContainerForNextToCol,
    InlineFormCell,
    Cards,
    CellInsideContent,
    TreeArrow,
    AutoHideAgent,
    OccupyingColumn,
    IconBtnSingle,
    PaginationWrapper,
  },
  directives: {
    loading: Loading.directive,
  },
  props: {
    height: { type: String },
    table: { type: Object as PropType<Table<IBaseTableData>> }, // 表格定义
    sorts: { type: Array as PropType<Sort[]> }, // 默认排序
    filters4ViewEditor: { type: Array as PropType<Filter[]> }, // 未归档的过滤器列表
    filters4ViewEditorArchived: { type: Array as PropType<Filter[]> }, // 已归档的过滤器列表
    filters4ViewEditorAll: { type: Array as PropType<Filter[]> }, // 全部的过滤器列表
    activeFilters: { type: Array as PropType<Filter[]>, default: () => [] },
    // defaultFilter: {
    //   type: Object as PropType<Filter | MultiFilter | "view" | null>,
    //   default: null,
    // }, // 默认选中的过滤器
    currFilter: {
      type: [Object, String] as PropType<Filter | MultiFilter | "view" | null>,
    },
    data: { type: Array as PropType<IBaseTableData[]> }, // 表格数据
    // -------------------------------------
    isTree: { type: Boolean, default: false }, // 是否树状数据表格(前端定义)
    hasOperation: { type: Boolean, default: false }, // 是否有操作按钮
    operationWidth: { type: Number, default: 100 }, // 操作按钮列的列宽
    isFiltersSiderVisible: { type: Boolean, default: false }, // 过滤侧边栏是否显示
    isColumnFilterable: { type: Boolean, default: true }, // 每一列是否有列过滤器
    isLastColShrink: { type: Boolean, default: false }, // 操作栏是否隐藏
    hasCheckboxColumn: { type: Boolean, default: false }, // 是否有多选列
    hasRadioColumn: { type: Boolean, default: false }, // 是否有单选列
    isSelectionReserved: { type: Boolean, default: false }, // 换页时，是否保持之前的选择
    hasSideFilter: { type: Boolean, default: true }, // 是否有侧边筛选栏(永不显示)
    formRowFlag: { type: String, default: "form-row" }, // 新表格行的类名
    hasVerticalBorder: { type: Boolean, default: false }, // 是否有竖向边框
    // -------------------------------------
    loadingTableFormat: { type: Boolean, default: false }, // 表格格式是否加载中
    loadingTableRows: { type: Boolean, default: false }, // 表格数据是否加载中
    loadingFilters: { type: Boolean, default: false },
    currPage: { type: Number, default: 1 }, // 当前页
    pageSize: { type: Number, default: 10 }, // 页大小
    pageSizes: {
      type: Array as PropType<number[]>,
      default: () => [...TABLE_PAGE_SIZES],
    }, // 页大小的可选项
    totalCount: { type: Number, default: 0 }, // 数据总数
    rowKey: { type: String, default: "id" }, // 如果是树状数据表格，本字段需要指定(默认是 id)
    // 树状数据表格的子树行的加载回调方法
    loadMethod: {
      type: Function as PropType<
        (row: IBaseTableData, treenode: any) => IBaseTableData[]
      >,
      default: (
        a: IBaseTableData,
        b: any /* , resolve: (data: any) => void */
      ) => {
        // resolve([]);
        return [];
      },
    },
    getCellDropdownData: {
      type: Function as PropType<
        (
          row: IBaseTableData | null | undefined,
          column: Column,
          resolve: (data: KeyValuePair<string>[]) => void
        ) => void
      >,
    },
    confirmCellSelection: {
      type: Function as PropType<
        (
          row: IBaseTableData,
          column: Column,
          item: KeyValuePair<string>,
          resolve: (isConfirm: boolean) => void
        ) => void
      >,
    },
    multiFilters: { type: Array as PropType<MultiFilter[]> }, // 视图编辑器的多列条件选择的数据
    viewFilters: { type: Array as PropType<ViewFilter[]> }, // 视图编辑器的视图列表的数据
    historySelections: {
      type: Object as PropType<HistorySelections>,
      required: true,
    }, // 存放所有被选择项的历史记录
    currView: {
      type: String as PropType<"cards" | "table">,
      default: "table",
    },
    cardWidth: {
      type: Number,
      validator: function (value: number) {
        return value > 0;
      },
    },
    // -----------------------------下面两个属性未来需求稳定后要去除-----------------------------
    hasSideFilterBarShrinkBtn: { type: Boolean, default: false }, // 是否有收缩按钮
    hasOperationColumnShrinkBtn: { type: Boolean, default: false }, // 是否收缩操作列
  },
  data(): {
    uniqueId: string;
    optBtnsPosAutoHideStyle: HTMLElement | undefined;
    currFixedCols: ColumnType<IBaseTableData>[]; // 当前冻结的列
    currSorts: Sort[];
    // sideWidth: number;
    SIDE_SPREAD_WIDTH: number;
    SIDE_NARROW_WIDTH: number;
    isCellTooltipVisible: boolean;
    paginationSize: number; // 分页栏的高度
    containerHeight?: number; // 表格的高度(如果没有，则不设置表格高度，让表格自然伸展)
    containerWidth: number;
    tableDataRecord?: TreeRecord<IBaseTableData>; // 记录所有的表格数据，包括树形表格后来懒加载的子节点数据
    mapColMergeableInfo: Map<string, { [key: string]: string[] }>; // 记录单元格的合并信息

    HEADER_MARGIN: number;
    isHeaderDragEnd: boolean;
    // "original" 原生箭头 + 原生懒加载(不能改变箭头列的位置，只能在第一列)；
    // "custom" 自定义箭头 + 读取子节点数据填充父节点 children；
    // "emulation" 自定义箭头 + 读取子节点数据插入父节点后方
    // https://segmentfault.com/a/1190000040719215
    // https://blog.csdn.net/sanlingwu/article/details/119732863
    // https://blog.csdn.net/wyljz/article/details/103391022
    treeMode: "original" | "original+" | "custom" | "emulation";
    treeArrowWidth: number;
    treeArrowIndent: number;

    cardsRendered: boolean; // 当前是否卡片视图
    tableRendered: boolean; // 当前是否表格视图

    isAutoHideVisible: boolean; // 是否显示自动隐藏的列
    currTableHeaderHeight: number; // 当前表头的高度/自动隐藏列的偏移量

    ICON_BTN_SIZE: number;
    ICON_BTN_ICON_SIZE: number;

    containerResizeObserver: ResizeObserver | null;
    headerResizeObserver: ResizeObserver | null;

    isMounted: boolean;
  } {
    return {
      uniqueId: "flexible-table-" + Math.floor(Math.random() * 100000),
      optBtnsPosAutoHideStyle: undefined,
      currFixedCols: [],
      currSorts: [],
      // sideWidth: 200,
      SIDE_SPREAD_WIDTH,
      SIDE_NARROW_WIDTH,
      isCellTooltipVisible: false,
      paginationSize: 40,
      containerHeight: undefined,
      containerWidth: 0,
      tableDataRecord: undefined,
      mapColMergeableInfo: new Map(),
      HEADER_MARGIN,
      isHeaderDragEnd: false,
      treeMode: "original+",
      treeArrowWidth: 16,
      treeArrowIndent: 15,
      cardsRendered: false,
      tableRendered: false,
      isAutoHideVisible: false,
      currTableHeaderHeight: 0,
      ICON_BTN_SIZE,
      ICON_BTN_ICON_SIZE,
      containerResizeObserver: null,
      headerResizeObserver: null,
      isMounted: false,
    };
  },

  computed: {
    // 根样式
    rootStyle(): object | undefined {
      if (this.height) return { height: this.height };
      else return undefined;
    },
    // 表格容器样式
    containerStyle(): object | undefined {
      return { height: `calc(100% - ${this.paginationSize}px)` };
    },
    // 是否可以合并单元格
    isMergeable(): boolean | undefined {
      return (
        this.table &&
        this.table.mergeableColIds &&
        this.table.mergeableColIds.length > 0 &&
        this.table.columns &&
        this.table.columns.length > 0
      );
    },
    // 操作栏是否自动隐藏
    isOptBtnsPositionAutoHide(): boolean {
      return (
        this.hasOperation &&
        this.table &&
        this.table.optBtnsPosition === "auto-hide"
      );
    },
    // 表格格式或数据是否加载中
    loadingTableFormatOrRows(): boolean {
      return this.loadingTableFormat || this.loadingTableRows;
    },
  },
  watch: {
    data: {
      immediate: true,
      async handler(newData: IBaseTableData[], oldData) {
        if (newData) {
          // 如果需要合并单元格
          if (
            this.isMergeable &&
            newData.length > 1 // 有且多于1行
          ) {
            this.tableDataRecord = new TreeRecord(newData);
            this.calcColMergeableInfo(
              this.tableDataRecord.getAllExpandedFlatData() as IBaseTableData[]
            );
          }
          if (this.hasCheckboxColumn) {
            await this.$nextTick();
            this.setRowMultiSelectionBaseOnGivenData(newData);
          }
          if (this.hasRadioColumn) {
            await this.$nextTick();
            this.setRowSingleSelectionBaseOnGivenData(newData);
          }
        }
      },
    },
    currView: {
      immediate: true,
      handler(currView) {
        if (currView === "cards") {
          this.cardsRendered = true;
        } else if (currView === "table") {
          this.tableRendered = true;
        }
      },
    },
    async hasCheckboxColumn(newVal, oldVal) {
      if (newVal) {
        await this.$nextTick();
        this.setRowMultiSelectionBaseOnGivenData(this.data);
      }
    },
    async hasRadioColumn(newVal, oldVal) {
      if (newVal) {
        await this.$nextTick();
        this.setRowSingleSelectionBaseOnGivenData(this.data);
      }
    },
    sorts: {
      immediate: true,
      handler(newSorts, oldSorts) {
        if (newSorts) this.currSorts = newSorts;
        else this.currSorts = [];
      },
    },
    "table.fixedColIds": {
      immediate: true,
      handler(newNames, oldNames) {
        if (this.table && this.table.fixedColIds) {
          for (const id of this.table.fixedColIds) {
            const col = this.table.normalColumns.find((c) => c.id === id);
            if (col) this.currFixedCols.push(col);
          }
        } else {
          this.currFixedCols = [];
        }
      },
    },
    "table.optBtnsPosition": {
      immediate: false,
      async handler(newVal, oldVal) {
        if (this.currView === "table" && oldVal !== undefined) {
          await this.$nextTick();
          this.doTableLayout(); // 用于使操作按钮栏显示和隐藏之间切换时，行可以对齐
        }
      },
    },
    isLastColShrink: {
      immediate: false,
      async handler(newVal, oldVal) {
        if (this.currView === "table") {
          await this.$nextTick();
          this.doTableLayout(); // 用于使操作按钮栏显示和隐藏之间切换时，行可以对齐
        }
      },
    },
    // 如果操作栏自动隐藏，则需要调整表格的布局
    isOptBtnsPositionAutoHide: {
      immediate: true,
      async handler(isAutoHide: boolean) {
        // this.handleOptBtnsPositionAutoHide(isAutoHide);
      },
    },
  },

  async mounted() {
    await this.initObservers();
  },

  updated() {
    // console.log("FlexibleTable updated");
  },

  beforeDestroy() {
    this.disconnectObservers();
  },

  methods: {
    // 当表头筛选器的值发生变化时，需要调用该方法
    $onTableColumnFilterChange(col: Column) {
      if (!this.hasSideFilter) return;
      // if (!this.isFiltersSiderVisible) return;

      const refSelectionFilters = this.$refs["SelectionFilters"] as any;
      // if (refSelectionFilters) {
      refSelectionFilters.$onTableColumnFilterChange(col);
      // }
    },

    /**
     * 调用 ele table 的原生重置布局的方法
     */
    doTableLayout() {
      (this.$refs["table"] as any).doLayout();
    },

    /**
     * 添加一行新的表单行数据(暂时不支持子节点行的新增)
     * @param prevRow 行对象，表示在哪一行下方添加新行
     * @param op 操作类型，"new" 表示新建，"copy" 表示复制
     * @returns 如果添加成功，则返回新行对象，否则返回 undefined
     */
    addFormRow(
      prevRow: IBaseTableData,
      op: "new" | "copy" = "new"
    ): FormRow | void {
      if (this.data && this.data.length > 0) {
        const newRow = new FormRow(this.table.formColumns, {
          $level: prevRow.$level,
          copyFrom: op === "copy" ? prevRow : undefined,
        });

        const index = this.data.findIndex(
          (_row) => _row === prevRow || _row.id === prevRow.id
        );
        if (index !== -1) {
          this.data.splice(index + 1, 0, newRow);
          return newRow;
        }
      }
    },

    /**
     * 添加一或多行新的普通数据行
     * @param prevRow 在哪一行下方添加新行(如果该行找不到，则新数据添加到第一行)
     * @param newRows 新的行对象数组
     */
    addNewRows(prevRow: IBaseTableData, newRows: IBaseTableData[]): void {
      if (this.data && this.data.length > 0) {
        const index = this.data.findIndex(
          (_row) => _row === prevRow || _row.id === prevRow.id
        );
        if (index !== -1) {
          this.data.splice(index + 1, 0, ...newRows);
        } else {
          this.data.unshift(...newRows);
        }
      }
    },

    /**
     * 设置多选列的选中状态
     * @param rowIds 需要选中的行的id
     * @param selected 是否选中，默认为 true
     */
    setRowMultiSelection(rowIds: string[], selected: boolean = true): void {
      if (this.data && rowIds.length > 0) {
        const rowMultiSelection = this.data.filter(
          (row) => typeof row.id === "string" && rowIds.includes(row.id)
        );

        const setSelection = () => {
          const refTable = this.$refs["table"] as any;
          for (const row of rowMultiSelection) {
            refTable.toggleRowSelection(row, selected);
          }
        };

        if (this.tableRendered) {
          setSelection();
        } else {
          const unwatch = this.$watch(
            "tableRendered",
            async (isTableRendered: boolean) => {
              if (isTableRendered) {
                await this.$nextTick();
                setSelection();
                unwatch(); // 取消观察
              }
            },
            { immediate: false }
          );
        }
      }
    },

    /**
     * 设置单选列的选中状态
     * @param rowId 需要选中的行的id
     * @param selected 是否选中，默认为 true
     */
    setRowSingleSelection(rowId: string, selected: boolean = true): void {
      if (this.data && rowId) {
        const rowSingleSelection = this.data.find(
          (row) => typeof row.id === "string" && row.id === rowId
        );

        if (rowSingleSelection) {
          const setSelection = () => {
            const refColumn = this.$refs["refSingleSelectionColumn"] as any;
            refColumn.$select(rowSingleSelection, selected);
          };

          if (this.tableRendered) {
            setSelection();
          } else {
            const unwatch = this.$watch(
              "tableRendered",
              async (isTableRendered: boolean) => {
                if (isTableRendered) {
                  await this.$nextTick();
                  setSelection();
                  unwatch(); // 取消观察
                }
              },
              { immediate: false }
            );
          }
        }
      }
    },

    onFormat(
      col:
        | Column<IBaseTableData>
        | IconColumn
        | ButtonColumn
        | IndexColumn
        | PercentColumn,
      cellValue: any
    ): string | undefined {
      if (col instanceof Column) {
        return cellValueFormatter(col, cellValue);
      } else {
        return undefined;
      }
    },

    // -------------------------------------

    // 懒加载子树节点方法
    async onLoad(
      row: IBaseTableData,
      treeNode: any,
      resolve: (data: IBaseTableData[]) => void
    ) {
      if (typeof this.loadMethod === "function") {
        const subRows = await this.loadMethod(row, treeNode);
        if (Array.isArray(subRows)) {
          for (const subRow of subRows) {
            (subRow as IBaseTableData).$parent = row;
          }

          if (this.isMergeable) {
            if (this.tableDataRecord) {
              this.tableDataRecord.insertChildren(row, subRows);
              // const allFlatData =
              //   this.tableDataRecord.getAllExpandedFlatData() as IBaseTableData[];
              // this.calcColMergeableInfo(allFlatData);
            }
          }

          resolve(subRows);
        } else {
          throw Error("loadMethod 的返回值必须为数组");
        }
      } else {
        throw Error("loadMethod 必须是函数");
      }
    },

    // 自定义箭头的点击事件处理
    async onCellTreeArrowClick(row: IBaseTableData, expanded: boolean) {
      if (typeof this.loadMethod === "function") {
        if (row.hasChildren && (!row.children || row.children.length === 0)) {
          const subRows = await this.loadMethod(row, null);

          if (Array.isArray(subRows) && subRows.length > 0) {
            row.children = subRows;
            if (this.isMergeable) {
              if (this.tableDataRecord) {
                this.tableDataRecord.insertChildren(row, subRows);
              }
            }

            // console.log("row :>> ", row, expanded);
          }
        }
      }

      (this.$refs["table"] as any).toggleRowExpansion(row, expanded);
      // -------------------------------------

      // if (typeof this.loadMethod === "function") {
      //   const subRows = await this.loadMethod(row, null);
      //   if (Array.isArray(subRows) && subRows.length > 0) {
      //     const index = this.data.findIndex((_row) => row === _row);
      //     if (index > -1) {
      //       this.data.splice(index + 1, 0, ...subRows);
      //     }
      //   }
      // }
    },

    // 如果是第一次展开父节点，则在获取到子树节点后，才会触发
    onExpandChange(row: IBaseTableData, expanded: boolean) {
      if (this.tableDataRecord) {
        this.tableDataRecord.setTreeNodeExpanded(row, expanded);

        const allFlatData =
          this.tableDataRecord.getAllExpandedFlatData() as IBaseTableData[];

        this.calcColMergeableInfo(allFlatData);
      }
    },

    // 第一级节点并且没有下级节点的节点
    isRootNodeWithNoChildren(row: IBaseTableData) {
      return !(row.$level > 0) && !row["hasChildren"];
    },

    // 是不是第一列(含固定列)
    isFirstCol(col: Column, colIndex: number) {
      if (this.currFixedCols.length === 0) {
        return colIndex === 0;
      } else {
        return col === this.currFixedCols[0];
      }
    },

    // 是否是普通列
    isNormalColumn(col: ColumnType<IBaseTableData>): boolean {
      return col instanceof Column;
    },

    // 是否树形表格箭头列(不考虑 isTree 和 treeMode 的情况)
    isTreeArrowCol(col: Column, colIndex: number): boolean {
      const arrowColIds = this.table.treeArrowColIds;

      if (arrowColIds && arrowColIds.length > 0) {
        return arrowColIds.includes(col.id);
      } else {
        // 没指定那就是第一列
        return this.isFirstCol(col, colIndex);
      }
    },

    tableColumnType(
      col: ColumnType<IBaseTableData>,
      colIndex: number
    ): string | undefined {
      if (this.isTree) {
        if (this.treeMode === "original") {
          return undefined;
        } else if (this.treeMode === "original+") {
          if (col instanceof Column && this.isTreeArrowCol(col, colIndex)) {
            return undefined; // 本列会出现树形的展开箭头
          } else {
            return ""; // 本列不会出现树形的展开箭头
          }
        } else {
          return ""; // 本列不会出现树形的展开箭头
        }
      } else {
        return undefined;
      }
    },

    // -------------------------------------

    onMerge(params: {
      row: any;
      column: any;
      rowIndex: number;
      columnIndex: number;
    }) {
      if (this.table.mergeableColIds && this.table.mergeableColIds.length > 0) {
        const { row, column, rowIndex, columnIndex } = params;

        const condition1 = this.mapColMergeableInfo.has(column.property); // 本列就是主列

        if (condition1) {
          // console.log("onMerge :>> ", row.$id, row.type_row_id, columnIndex);

          const cellGroupMap = this.mapColMergeableInfo.get(column.property);

          // const cellGroup = cellGroupMap![rowIndex];
          const cellGroup = cellGroupMap![row.$id];

          if (cellGroup) {
            let spans: { rowspan: number; colspan: number };
            if (cellGroup.length === 1) {
              spans = {
                rowspan: 1,
                colspan: 1,
              };
            } else {
              // if (cellGroup[0] === rowIndex) {
              if (cellGroup[0] === row.$id) {
                spans = {
                  rowspan: cellGroup.length,
                  colspan: 1,
                };
              } else {
                spans = {
                  rowspan: 0,
                  colspan: 0,
                };
              }
            }

            return spans;
          } else {
            // 收起的子行
            // console.log("onMerge :>> ", row);
          }
        }
      }
    },

    // 把相邻的值相等的节点进行合并
    calcColMergeableInfo(allData: IBaseTableData[]) {
      if (
        this.table &&
        this.table.mergeableColIds &&
        this.table.mergeableColIds.length > 0
      ) {
        const tableMergeableColIds = this.table.mergeableColIds.map((info) => {
          if (typeof info === "string") return info;
          else return info.colId;
        });

        const mergeableCols = this.table.normalColumns.filter((col) => {
          return tableMergeableColIds.includes(col.id);
        });

        if (mergeableCols.length === 0) return;

        this.mapColMergeableInfo.clear();

        for (const col of mergeableCols) {
          const cellGroupMap: { [key: string]: string[] } = {};
          let cellGroup: string[] = [];

          for (let i = 0; i < allData.length; i++) {
            const currRow = allData[i];

            if (i > 0) {
              const prevRow = allData[i - 1];

              // 如果上一行是 FormRow 类型的行，直接认为不等
              if (currRow instanceof FormRow || prevRow instanceof FormRow) {
                // cellGroup = [i]; // 不等
                cellGroup = [currRow.$id];
              } else {
                // 看看是否跟上一个一致
                if (
                  this.isCellValEquivalent(
                    currRow[col.id],
                    prevRow[col.id],
                    col.type
                  )
                ) {
                  // cellGroup.push(i); // 相等
                  cellGroup.push(currRow.$id);
                } else {
                  // cellGroup = [i]; // 不等
                  cellGroup = [currRow.$id];
                }
              }
            } else {
              // 第一行数据，直接放到数组
              // cellGroup.push(0);
              cellGroup.push(currRow.$id);
            }

            // cellGroupMap[i] = cellGroup;
            cellGroupMap[currRow.$id] = cellGroup;
          }

          this.mapColMergeableInfo.set(col.id, cellGroupMap);
        }

        // console.log(" this.mapColMergeableInfo :>> ", this.mapColMergeableInfo);
      }
    },

    // 判断两个单元格的内容是否相等
    isCellValEquivalent(
      val1: any,
      val2: any,
      normalColumnType: TColumnType
    ): boolean {
      let isEquivalent;

      switch (normalColumnType) {
        case "string":
        case "rich-text":
        case "number": {
          isEquivalent = val1 === val2;
          break;
        }

        case "time":
        case "date":
        case "datetime": {
          const date1 = val1 as Date | null | undefined;
          const date2 = val2 as Date | null | undefined;
          isEquivalent = this.isDateEquivalent(date1, date2);
          break;
        }

        case "time-frame":
        case "date-frame":
        case "datetime-frame": {
          const date01 = val1[0] as Date | null | undefined;
          const date02 = val2[0] as Date | null | undefined;

          const date11 = val1[1] as Date | null | undefined;
          const date12 = val2[1] as Date | null | undefined;

          isEquivalent =
            this.isDateEquivalent(date01, date02) &&
            this.isDateEquivalent(date11, date12);
          break;
        }

        case "key-str-value": {
          const kv1 = val1 as KeyStrValuePair;
          const kv2 = val2 as KeyStrValuePair;
          isEquivalent = kv1.key === kv2.key;
          break;
        }

        default:
          throw new Error("normalColumnType 的值超出取值范围");
      }

      return isEquivalent;
    },

    // -------------------------------------

    onSizeChange(size: number) {
      this.$emit("update:pageSize", size);
      this.$emit("page-size-change", size);
    },

    onCurrPageChange(page: number) {
      this.$emit("update:currPage", page);
      this.$emit("curr-page-change", page);
    },

    // -------------------------------------

    onRowClassName(params: { row: IBaseTableData; rowIndex: number }) {
      const { row, rowIndex } = params;

      if (row instanceof FormRow) {
        // return "form-row form-row-" + row.$id;
        return `${this.formRowFlag} ${this.formRowFlag}-${row.$id}`;
      }
    },

    onFormCellInput(row: FormRow, col: TFormColumn, value: any) {
      this.$emit("form-cell-input", row, col, value);
    },

    // -------------------------------------

    onHeaderDropdownCommand(
      command: "asc" | "desc" | "freeze" | "unfreeze-all",
      col: Column<IBaseTableData>
    ) {
      if (!this.table) return;
      switch (command) {
        case "asc":
        case "desc": {
          const sortIndex = this.currSorts.findIndex(
            (sort) => sort.columnId === col.id
          );

          let _sort;

          if (sortIndex > -1) {
            // 已有此排序规则
            _sort = this.currSorts[sortIndex];

            if (_sort.order === command) {
              // 取消本排序规则
              this.currSorts.splice(sortIndex, 1);
            } else {
              // 修改本排序规则
              _sort.order = command;
            }
          } else {
            // 没有此排序规则
            // 新建排序规则，并放置到最后
            _sort = new Sort({
              columnId: col.id,
              sortName: col.sortName ? col.sortName : String(col.id),
              order: command,
            });

            this.currSorts.push(_sort);
          }

          this.$emit("sort-change", _sort);
          break;
        }
        case "freeze": {
          const i = this.table.columns.findIndex((column) => column === col);
          this.currFixedCols = this.table.normalColumns.slice(0, i + 1);
          break;
        }
        case "unfreeze-all": {
          this.currFixedCols = [];
          break;
        }
      }
    },

    // -------------------------------------

    onHeaderFilterSubmit(column: Column<IBaseTableData>) {
      this.$emit("column-filter-submit", column);
    },
    onHeaderFilterCancel(column: Column<IBaseTableData>) {
      this.$emit("column-filter-cancel", column);
    },
    onHeaderFilterChange(column: Column<IBaseTableData>) {
      this.$emit("column-filter-change", column);
    },

    onSelectionFilterChange(filter: Filter | MultiFilter | "view") {
      this.$emit("selection-filter-change", filter);
    },
    onSelectionListCheckboxChange(
      items: IFilterListItem[][],
      targetItem: IFilterListItem,
      isChecked: boolean
      // filterMode: "single" | "multiple" | "view"
    ) {
      this.$emit(
        "selection-list-checkbox-change",
        items,
        targetItem,
        isChecked /* , filterMode */
      );
    },
    onSelectionListSingleClick(
      items: IFilterListItem[][],
      targetItem: IFilterListItem
    ) {
      this.$emit("selection-list-single-click", items, targetItem);
    },

    onViewEditorShow() {
      this.$emit("view-editor-show");
    },
    onViewEditorClose() {
      this.$emit("view-editor-close");
    },
    // 如果有内置icon按钮列，col 为普通列并且 insideBtnCol 为该内置按钮列，否则 col 为按钮列
    onColumnButtonClick(
      row: IBaseTableData,
      col: Column | ButtonColumn,
      insideBtnCol?: ButtonColumn
    ) {
      this.$emit("column-button-click", row, col, insideBtnCol);
    },

    onCornerTipClick(row: any, column: Column) {
      this.$emit("cell-corner-tip-click", row, column);
    },

    // -------------------------------------

    // 当自动隐藏列显示时，触发此事件
    async onShowAutoHideColumn() {
      this.isAutoHideVisible = true;

      await this.$nextTick();

      // 触发一下 ElTable 的滚动事件，滚动 1px
      // 因为如果表格往下滚动过，再显示隐藏列，会出现隐藏列的单元格与非隐藏列的单元格不对齐的问题
      // 滚动事件会触发重新计算单元格的位置
      const elTable = this.$refs["table"] as Vue;
      if (elTable) {
        const elTableBodyWrapper = elTable.$el.querySelector(
          ".el-table__body-wrapper"
        );

        if (elTableBodyWrapper) {
          // 触发滚动
          elTableBodyWrapper.scrollTop -= 1;
          elTableBodyWrapper.scrollTop += 1;
        }
      }
    },

    // 当自动隐藏列隐藏时，触发此事件
    onHideAutoHideColumn() {},

    // -------------------------------------
    // 多选框列相关
    onMultiSelectionChange(selection: IBaseTableData[]) {
      this.$emit("row-multi-select", selection);
    },

    // 单选框列相关
    onSingleSelectionChange(row: IBaseTableData) {
      this.$emit("row-single-select", row);
    },

    onHeaderDragend(
      newWidth: number,
      oldWidth: number,
      column: any,
      event: MouseEvent
    ) {
      this.isHeaderDragEnd = true;
    },

    // -------------------------------------

    isIndexColumn(col: BaseColumn): boolean {
      return col instanceof IndexColumn;
    },

    isIconColumn(col: BaseColumn) {
      return col instanceof IconColumn;
    },

    isDateEquivalent(
      date1: Date | null | undefined,
      date2: Date | null | undefined
    ) {
      if (date1 instanceof Date && date2 instanceof Date) {
        return date1.getTime() === date2.getTime();
      } else {
        return date1 == date2; // 弱比较
      }
    },

    // 是否是表单行
    isFormRow(row: IBaseTableData) {
      return row instanceof FormRow;
    },

    // ----------------------------------------------------

    // 以下用于解决表头高度在加载时会出现忽高忽低的问题修复

    headerWidth(colWidth?: number): string | undefined {
      if (this.isHeaderDragEnd) return;
      else if (colWidth) {
        const width = colWidth - this.HEADER_MARGIN * 2 - 1;
        if (width > 0) return `${width}px`;
      }
    },

    headerMinWidth(colMinWidth?: number): string | undefined {
      if (this.isHeaderDragEnd) return;
      else if (colMinWidth) {
        const width = colMinWidth - this.HEADER_MARGIN * 2 - 1;
        if (width > 0) return `${width}px`;
      }
    },

    headerMargin(): string | undefined {
      return `0 ${HEADER_MARGIN}px`;
    },

    // ----------------------------------------------------

    getCellInsideContentStyle(
      col: Column,
      colIndex: number,
      row: IBaseTableData,
      treeNode?: any
    ): { width?: string } {
      let width;

      if (this.isTree) {
        if (this.treeMode === "original") {
          const indent = this.treeArrowIndent;
          if (this.isFirstCol(col, colIndex)) {
            let totalIndent;
            if (treeNode && treeNode.level > 0) {
              // 除第一级外的树节点
              totalIndent = indent * (treeNode.level + 1);

              width = `calc(100% - ${totalIndent}px)`; // 占据剩余宽度
            } else {
              // 第一级树节点
              totalIndent = indent;

              if (row.hasChildren)
                width = `calc(100% - ${totalIndent}px)`; // 占据剩余宽度
              else width = `100%`;
            }
          }
        } else if (this.treeMode === "original+") {
          const indent = this.treeArrowIndent;
          if (this.isTreeArrowCol(col, colIndex)) {
            let totalIndent;
            if (treeNode && treeNode.level > 0) {
              // 除第一级外的树节点
              totalIndent = indent * (treeNode.level + 1);

              width = `calc(100% - ${totalIndent}px)`; // 占据剩余宽度
            } else {
              // 第一级树节点
              totalIndent = indent;

              if (row.hasChildren)
                width = `calc(100% - ${totalIndent}px)`; // 占据剩余宽度
              else width = `100%`;
            }
          }
        } else {
          if (this.isTreeArrowCol(col, colIndex)) {
            let totalIndent;
            if (row.$level > 0) {
              // 除第一级外的树节点
              totalIndent =
                this.treeArrowWidth + this.treeArrowIndent * row.$level;
            } else {
              // 第一级树节点
              totalIndent = this.treeArrowWidth;
            }
            width = `calc(100% - ${totalIndent}px)`; // 占据剩余宽度
          }
        }
      }

      return { width };
    },

    // ----------------------------------------------------

    // 根据给定的数据设置多选列的选中状态
    setRowMultiSelectionBaseOnGivenData(rows: IBaseTableData[]) {
      const rowIdsTrue: string[] = [],
        rowIdsFalse: string[] = [];
      for (const row of rows) {
        if (row.id) {
          if (row.selected) rowIdsTrue.push(row.id);
          else rowIdsFalse.push(row.id);
        }
      }
      this.setRowMultiSelection(rowIdsTrue, true);
      // this.setRowMultiSelection(rowIdsFalse, false);
    },

    // 根据给定的数据设置单选列的选中状态
    setRowSingleSelectionBaseOnGivenData(rows: IBaseTableData[]) {
      const rowId = rows.find((row) => row.selected)?.id;
      if (rowId) this.setRowSingleSelection(rowId, true);
    },

    // ----------------------------------------------------

    handleOptBtnsPositionAutoHide(isAutoHide: boolean) {
      if (isAutoHide) {
        const style = document.createElement("style");
        style.type = "text/css";
        // 由 ContainerForAutoHide.vue 的 scss 样式编译而来
        style.innerHTML = `
            #${this.uniqueId}.flexible-table .el-table__fixed-right {
              display: none;
              box-shadow: 0 0 10px rgba(0, 0, 0, 0.12) !important;
              border-right: 1px solid #ebeef5 !important;
            }
            
            #${this.uniqueId}.flexible-table
              .el-table__body-wrapper
              table.el-table__body
              colgroup
              col:nth-last-child(2) {
              display: none;
            }
            
            #${this.uniqueId}.flexible-table
              .el-table__body-wrapper
              table.el-table__body
              tbody
              tr.el-table__row
              td.el-table__cell.auto-hide {
              display: none;
            }
            
            #${this.uniqueId}.flexible-table
              .el-table__header-wrapper
              table.el-table__header
              colgroup
              col:nth-last-child(3) {
              display: none;
            }
            
            #${this.uniqueId}.flexible-table .el-table__header-wrapper thead tr th.el-table__cell.auto-hide {
              display: none;
            }
          `;
        document.head.appendChild(style);
        this.optBtnsPosAutoHideStyle = style;
      } else {
        if (this.optBtnsPosAutoHideStyle) {
          document.head.removeChild(this.optBtnsPosAutoHideStyle);
        }
      }
    },

    // 用于调试
    consoleScope(scope: any) {
      if (scope.column.property === "type_row_id") {
        console.log("scope :>> ", scope.row["type_row_id"], scope);
      }
    },

    // ----------------------------------------------------

    async initObservers() {
      await this.$nextTick();

      // await new Promise((resolve) => setTimeout(resolve, 3000));

      const refContainer = this.$refs["cards-and-table-container"];

      if (refContainer instanceof HTMLElement) {
        // 页码第一次加载后，就会激发一次
        this.containerResizeObserver = new ResizeObserver((entries) => {
          requestAnimationFrame(async () => {
            const entry = entries[0];
            const contentBoxSize = Array.isArray(entry.contentBoxSize)
              ? entry.contentBoxSize[0]
              : entry.contentBoxSize;
            const width = contentBoxSize.inlineSize as number;
            const height = contentBoxSize.blockSize as number;

            this.containerWidth = width;

            // await new Promise((resolve) => setTimeout(resolve, 3000));

            // 马上设置 el-table 的 height 会导致表格的高度不对
            // (el-table__body-wrapper 的高度会被 el-table 的内部逻辑计算错误)，所以延迟设置

            this.setElTableHeightAfterUpdate(height);
            // this.containerHeight = height;
          });
        });

        this.containerResizeObserver.observe(refContainer);
      }

      // -------------------------------------

      const observeTableHeader = () => {
        const refElTable = this.$refs["table"] as Vue;
        // 找出 el-table 的表头 dom 元素
        const elTableHeaderEle = refElTable.$el.querySelector(
          ".el-table__header-wrapper"
        );

        if (elTableHeaderEle) {
          this.headerResizeObserver = new ResizeObserver((entries) => {
            requestAnimationFrame(() => {
              // 获取 elTableHeader 的高度
              const height = entries[0].contentRect.height;

              if (this.currTableHeaderHeight !== height) {
                // 如果高度变化了，表格 fixed 的列会发生错位，所以需要 doLayout
                this.doTableLayout();

                if (this.currView === "table") {
                  this.currTableHeaderHeight = height;
                }
              }
            });
          });

          this.headerResizeObserver.observe(elTableHeaderEle);
        }
      };

      if (this.tableRendered) {
        observeTableHeader();
      } else {
        const unwatch = this.$watch(
          "tableRendered",
          async (isTableRendered: boolean) => {
            if (isTableRendered) {
              await this.$nextTick();
              observeTableHeader();
              unwatch(); // 取消观察
            }
          },
          { immediate: false }
        );
      }
    },

    disconnectObservers() {
      this.containerResizeObserver?.disconnect();
      this.containerResizeObserver = null;

      this.headerResizeObserver?.disconnect();
      this.headerResizeObserver = null;
    },

    // 马上设置 el-table 的 height 会导致表格的高度不对
    // (el-table__body-wrapper 的高度会被 el-table 的内部逻辑计算错误)，所以延迟设置
    async setElTableHeightAfterUpdate(height: number) {
      if (!this.loadingTableFormatOrRows) {
        // 数据从后端获取完了
        this.containerHeight = height;
      } else {
        // 数据还在从后端加载中
        const unwatch = this.$watch(
          "loadingTableFormatOrRows",
          (isLoading: boolean) => {
            if (!isLoading) {
              this.containerHeight = height;
              unwatch(); // 取消观察
            }
          },
          { immediate: false }
        );
      }
    },
  },
});
</script>

<style lang="scss" scoped>
@import "src/flexible-table-module/scss/_variables.scss";

$btn-area-min-height: 40px;

.flexible-table {
  box-sizing: border-box;
  width: 100%;
  padding: 8px;
  overflow-y: hidden;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;

  .icon-btn-single-fold {
    display: flex;
  }

  // &.has-side-filter-bar-shrink-btn {
  //   &.side-filter-bar-shrinked {
  //     &.no-columms-fixed {
  //       ::v-deep {
  //         .el-table__fixed {
  //           box-shadow: none !important;
  //         }
  //       }
  //     }
  //   }
  // }

  // &.has-operation-col-shrink-btn {
  //   &.operation-col-shrinked {
  //     ::v-deep {
  //       .el-table__fixed-right {
  //         box-shadow: none !important;
  //       }
  //     }
  //   }
  // }

  .side {
    height: 100%;
    flex-shrink: 0;
    overflow: auto;

    &.narrow {
      // width: 24px;
      .header {
        width: 100%;
        height: $btn-area-min-height;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }

  .main {
    // width: 100%;
    height: 100%;

    .table-container {
      width: 100%;
      overflow: auto;
      position: relative;

      &__fixed-btns {
        position: absolute;
        top: 0;
        right: 0;
        min-height: $btn-area-min-height;
        z-index: 1999;
        background-color: $background-base;
        display: flex;
        justify-content: center;
        align-items: center;

        .icon-btn-columns-setting {
        }
      }

      &__auto-hide-agent {
        position: absolute;
        top: 0;
        right: 0;
        z-index: 1999;
      }

      .table {
        &.el-table {
          font-size: $fs-el-table;
          color: $clr-el-table;

          $vertical-border-color: transparent;

          .header-operation {
            color: $clr-el-table-header;
          }

          &.no-vertical-border {
            &::before {
              background-color: $vertical-border-color; // 下边框
            }

            &.el-table--border {
              border-color: $vertical-border-color; // 上边框，左边框
              &::after {
                background-color: $vertical-border-color; // 右边框
              }

              ::v-deep {
                .el-table__cell {
                  border-right-color: $vertical-border-color;
                }
              }
            }

            ::v-deep {
              .el-table__fixed {
                &::before {
                  background-color: $vertical-border-color; // 左固定列下边框
                }
              }
              .el-table__fixed-right {
                &::before {
                  background-color: $vertical-border-color; // 右固定列下边框
                }
              }
            }
          }

          ::v-deep {
            .el-table__body {
              .el-table__row {
                .el-table__cell {
                  .cell {
                    // 每个单元格的定位(如果要调整居中居左居右，请设置 .cell-inside-row 的样式)
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
                    line-height: 24px;

                    $arrow-width: 12px;

                    .el-table__placeholder {
                      flex-shrink: 0;
                      margin-right: 3px; // 箭头有这3px，所以无箭头的placeholder要跟箭头同步
                      width: $arrow-width;
                    }

                    .el-table__expand-icon {
                      flex-shrink: 0;
                      width: $arrow-width;
                    }
                  }

                  &.el-table-column--selection {
                    .cell {
                      justify-content: center; // 多选列
                      padding: 0 10px;
                    }
                  }
                }
              }
            }

            .el-table__header {
              .el-table__cell {
                background-color: $background-base;

                .cell {
                  // height: unset; // 只有固定列的表头才是 unset，这里统一都设置为 unset
                  line-height: 24px;
                  padding: 0;
                }

                &.el-table-column--selection {
                  .cell {
                    display: flex;
                    justify-content: center; // 多选列
                  }
                }
              }
            }
          }
        }
      }
    }

    .pagination {
      box-sizing: border-box;
      width: 100%;
      padding-top: 8px;
      display: flex;
      justify-content: flex-end;
      align-items: center;

      .other-info {
        height: 100%;
        min-width: 320px;
      }

      .el-pagination {
      }
    }
  }

  &.opt-btns-position-auto-hide {
    ::v-deep {
      .el-table__fixed-right {
        display: none; // 默认隐藏操作列
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.12) !important;
        border-right: 1px solid #ebeef5 !important;
      }

      .el-table__body-wrapper {
        table.el-table__body {
          colgroup {
            col {
              &:nth-last-child(1) {
                display: none; // 隐藏操作列内容单元格布局用的 col 的倒数第1列
              }

              // &:nth-last-child(1) {
              //   display: none; // setting 齿轮的占位列
              // }
            }
          }

          tbody {
            tr.el-table__row {
              td.el-table__cell {
                &.auto-hide {
                  display: none; // 隐藏操作列的内容单元格(tip: 单元格有 border，隐藏单元格后，border 也会消失)
                }

                // &.right-occupying-column {
                //   display: none; // 隐藏 setting 齿轮的占位列
                // }
              }
            }
          }
        }
      }

      .el-table__header-wrapper {
        table.el-table__header {
          colgroup {
            col {
              // 倒数第2个
              &:nth-last-child(2) {
                display: none;
              }

              // &:nth-last-child(2) {
              //   display: none; // setting 齿轮的占位列
              // }
            }
          }
        }

        thead {
          tr {
            th.el-table__cell {
              &.auto-hide {
                display: none;
              }

              // &.right-occupying-column {
              //   display: none; // 隐藏 setting 齿轮的占位列
              // }
            }
          }
        }
      }
    }
  }
}
</style>