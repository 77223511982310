const local = {
  state: {
    token: "",
    userInfo: {},
    isAdmin: false, // 是否是管理员
    menuList: [],
    activeMenu: null,
    Moduleclassification: [], //模块分类列表
    Viewstatus: 1,//1展示，2演示，3编辑
    sidesmenulist:[],//首页左侧模块列表
  },
  getters: {
    token: state => state.token,
    userInfo: state => state.userInfo,
    isAdmin: state => state.isAdmin,
    activeMenu: state => state.activeMenu,
    menuList: state => state.menuList,
    Moduleclassification: state => state.Moduleclassification,
    Viewstatus: state => state.Viewstatus,
    sidesmenulist: state => state.sidesmenulist,
  },
  mutations: {
    saveToken(state, n = "") {
      state.token = n;
    },
    saveUserInfo(state, payload = {}) {
      state.userInfo = payload;
    },
    saveIsAdmin(state, n = false) {
      state.isAdmin = n;
    },
    saveMenuList(state, payload = []) {
      state.menuList = payload;
    },
    saveActiveMenu(state, payload = null) {
      state.activeMenu = payload;
    },
    saveModuleclassification(state, payload = []) {
      state.Moduleclassification = payload;
    },
    saveViewstatus(state, payload = 1) {
      state.Viewstatus = payload;
    },
    saveSidesMenuList(state, payload = []) {
      state.sidesmenulist = payload;
    },
  }
};
export default local