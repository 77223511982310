<template>
  <el-dropdown
    :hide-on-click="false"
    trigger="click"
    ref="nameFilter"
    @visible-change="visibleChange"
    placement="bottom-start"
  >
    <div class="one-line name">
      {{ name }}
    </div>
    <el-dropdown-menu slot="dropdown">
      <el-dropdown-item>
        <el-input
          type="textarea"
          v-model="subjectName"
          @keydown.enter.native="askSure"
          :placeholder="'请输入名称'"
        ></el-input>
      </el-dropdown-item>
      <el-dropdown-item>
        <div class="buttons">
          <el-button @click="askCancel" type="primary">重置</el-button>
          <el-button @click="askSure" type="primary">确定</el-button>
        </div>
      </el-dropdown-item>
    </el-dropdown-menu>
  </el-dropdown>
</template>

<script>
import { ProductLibrary } from "@/api/ProductLibrary";
export default {
  props: {
    name: String,
    subject: Object,
  },
  components: {},
  data() {
    return {
      subjectName: "",
    };
  },
  watch: {},
  computed: {
    actualName: {
      get() {
        return this.name;
      },
      set(val) {
        this.$emit("update:name", val);
      },
    },
  },
  methods: {
    askCancel() {
      this.subjectName = null;
    },
    async askSure() {
      if (!this.subjectName || !this.subjectName.trim()) {
        this.$message.error("名称不能为空");
        return;
      }
      let item = _.pick(this.subject, [
        "coverRemark",
        "id",
        "mirrorId",
        "moduleId",
        "name",
        "versionId",
        "shelfFlag",
      ]);
      let queryData = item;
      queryData.demoLinks = _.cloneDeep(this.subject.subjectDemoLinks);
      queryData.subjectName = this.subjectName;
      queryData.editType = 1;
      this.actualName = this.subjectName;
      const res = await ProductLibrary.editSubject(queryData);
      this.$refs.nameFilter.hide();
    },
    visibleChange(val) {
      this.subjectName = _.cloneDeep(this.name);
    },
  },
  created() {
    // this.subjectName = _.cloneDeep(this.name);
  },
};
</script>

<style scoped lang="scss">
.name {
  height: 100%;
  line-height: 40px;
  max-width: 230px;
  color: #fff;
  // font-size: 12px;
}
.buttons {
  text-align: right;
  .el-button {
    padding: 1px 5px;
  }
}
::v-deep .el-dropdown-menu__item:not(.is-disabled):hover {
  background-color: transparent;
}
::v-deep .el-dropdown-link.el-dropdown-selfdefine {
  outline: none;
}
</style>
