import { Message as ElementMessage } from 'element-ui';
export const isNewVersion = () => {
  const version = require('../../package.json').version
  const localVueVersion = localStorage.getItem('version')
  // console.log('版本',version,'缓存版本',localVueVersion);
  if (localVueVersion && localVueVersion != version) {
    localStorage.setItem('version', version);
    // ElementMessage.info('获取到有新版本，即将刷新页面获取最新版本')
    setTimeout(() => {
      window.location.reload();
    }, 600);
    return;
  } else {
    localStorage.setItem('version', version);
  }
}