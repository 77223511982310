import request from "@/utils/appServer";
import common from "../utils/common"
class Contacts {
  // 好友分类-列表
  static async getFriendCategoryTree() {
    try {
      const res = await request.post('/sales-show-4.0/friend/category/treeList');
      return res.data.code === 200 && res.data.result ? res.data.result : [];
    } catch (error) {
      return [];
    }
  }
  // 好友分类-新增、编辑
  static async updateFriendCategory(url, data) {
    try {
      const res = await request.post(`/sales-show-4.0/friend/category/${url}Category`, data);
      return res.data.code === 200 && res.data.result ? res.data.result : false;
    } catch (error) {
      return false;
    }
  }
  // 好友分类-删除
  static async deleteFriendCategory(id) {
    try {
      const res = await request.post('/sales-show-4.0/friend/category/deleteCategory?categoryId=' + id, null, true);
      return res.data.code === 200;
    } catch (error) {
      return false;
    }
  }
  // 潜在好友列表
  static async latentFriendList() {
    try {
      const res = await request.get("/sales-show-4.0/friend/latentFriendList");
      return res.data.code === 200 && res.data.result ? res.data.result : [];
    } catch (error) {
      return [];
    }
  }
  // 好友认证-添加好友
  static async addFriend(data) {
    try {
      const res = await request.post("/sales-show-4.0/friend/addFriend", data, true);
      return res.data.code === 200;
    } catch (error) {
      return false;
    }
  }
  // 拒绝好友添加消息
  static async refuseAdd(friendMessageId) {
    try {
      const res = await request.get("/sales-show-4.0/friend/refuseAdd", { params: { friendMessageId } });
      return res.data.code === 200;
    } catch (error) {
      return false;
    }
  }
  // 根据好友分组查询好友 type => 不分组:NoGroup 企业名称:GroupByCompany 负责人:GroupByUser
  static async getFriendList(type, data) {
    try {
      const res = await request.post(`/sales-show-4.0/friend/friendListByCategoryId${type}`, data);
      return res.data.code === 200 && res.data.result ? res.data.result : new common.Pagination();
    } catch (error) {
      return new common.Pagination();
    }
  }
  // 自建好友
  static async addCustomFriend(data) {
    try {
      const res = await request.post("/sales-show-4.0/friend/addCustomFriend", data, true);
      return res.data.code === 200;
    } catch (error) {
      return false;
    }
  }
  // 编辑好友
  static async editFriend(data) {
    try {
      const res = await request.post("/sales-show-4.0/friend/editFriend", data, true);
      return res.data.code === 200;
    } catch (error) {
      return false;
    }
  }
  // 删除好友
  static async deleteFriend(friendId) {
    try {
      const res = await request.get("/sales-show-4.0/friend/deleteFriend", { params: { friendId } }, true);
      return res.data.code === 200;
    } catch (error) {
      return false;
    }
  }
  // 修改好友标签
  static async editFriendTag(data) {
    try {
      const res = await request.post("/sales-show-4.0/friend/editFriendTag", data, true);
      return res.data.code === 200;
    } catch (error) {
      return false;
    }
  }
  // 根据电话号码查询用户
  static async queryUserByPhone(data) {
    try {
      const res = await request.get("/sales-show-4.0/sys/user/queryUserByPhone", { params: data });
      return res.data.code === 200 && res.data.result ? res.data.result : false;
    } catch (error) {
      return false;
    }
  }
  // 发送添加好友消息
  static async sendAddFriendMessage(data) {
    try {
      const res = await request.post("/sales-show-4.0/friend/sendAddFriendMessage", data, true);
      return res.data.code === 200;
    } catch (error) {
      return false;
    }
  }
  // 好友移出企业
  static async removeFriendFromCompany(friendId) {
    try {
      const res = await request.get("/sales-show-4.0/friend/removeFriendCompany", { params: { friendId } }, true);
      return res.data.code === 200;
    } catch (error) {
      return false;
    }
  }
  // 查询好友企业信息集合
  static async searchFriendCompanyList(companyName) {
    try {
      const res = await request.get("/sales-show-4.0/friend/searchFriendCompanyList", { params: { companyName } });
      return res.data.code === 200 && res.data.result ? res.data.result : [];
    } catch (error) {
      return [];
    }
  }
  // 通过租户id查看公司名片详情
  static async getCompanyCardDetailByTenantId(tenantId) {
    try {
      const res = await request.get("/sales-show-4.0/companyCard/getCompanyCardDetailByTenantId", { params: { tenantId } });
      return res.data.code === 200 && res.data.result ? res.data.result : {};
    } catch (error) {
      return {};
    }
  }
  // 客户详情-企业信息查询接口
  static async queryFriendCompanyDetail(friendCompanyId) {
    try {
      const res = await request.get("/sales-show-4.0/friend/queryFriendCompanyDetail", { params: { friendCompanyId } });
      return res.data.code === 200 && res.data.result ? res.data.result : false;
    } catch (error) {
      return false;
    }
  }
  // 客户详情-企业信息保存接口
  static async saveFriendCompanyDetail(data) {
    try {
      const res = await request.post("/sales-show-4.0/friend/saveFriendCompanyDetail", data, true);
      return res.data.code === 200;
    } catch (error) {
      return false;
    }
  }
  // 客户详情-查询联系人列表
  static async queryFriendByCompanyId(friendCompanyId) {
    try {
      const res = await request.get("/sales-show-4.0/friend/queryFriendByCompanyId", { params: { friendCompanyId } });
      return res.data.code === 200 && res.data.result ? res.data.result : [];
    } catch (error) {
      return [];
    }
  }
  // 获取租户部门及其成员树
  static async queryTreeList() {
    try {
      const res = await request.get("/sales-show-4.0/sys/depart/queryTreeList");
      return res.data.code === 200 || res.data.code === 0 && res.data.result ? res.data.result : [];
    } catch (error) {
      return [];
    }
  }
  // 企业维度-分享历史-我分享给好友的
  static async getShareLogByCompanyId(data) {
    try {
      const res = await request.get("/sales-show-4.0/friend/shareLogToFriendListByCompanyId", { params: data });
      return res.data.code === 200 || res.data.code === 0 && res.data.result ? res.data.result : new common.Pagination();
    } catch (error) {
      return new common.Pagination();
    }
  }
  // 取消分享
  static async updateShareStatus(shareId) {
    try {
      const res = await request.get("/sales-show-4.0/share/updateStatus", { params: { shareId } });
      return res.data.code === 200 || res.data.code === 0;
    } catch (error) {
      return false;
    }
  }
  // 根据好友企业id查看当前公司下所有好友浏览记录
  static async queryListByCompanyId(data) {
    try {
      const res = await request.post("/sales-show-4.0/userView/queryListByFriendCompanyId", data);
      return res.data.code === 200 || res.data.code === 0 && res.data.result ? res.data.result : new common.Pagination();
    } catch (error) {
      return new common.Pagination();
    }
  }
  // 初始化好友默认分类
  static async initFriendDefaultCategory() {
    try {
      const res = await request.post("/sales-show-4.0/friend/category/initFriendDefaultCategory");
      return res.data.success;
    } catch (error) {
      return false;
    }
  }
}

export { Contacts }