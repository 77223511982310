<template>
  <ElTableColumn
    class-name="auto-hide"
    fixed="right"
    label="操作"
    :width="width"
    :header-align="OPERATION_COLUMN_TITLE_ALIGN"
    :type="''"
  >
    <!-- 当第一列的原生树箭头被隐藏时，本列会出现树箭头，故要隐藏 -->
    <template v-slot:header>
      <slot name="header"></slot>
    </template>
    <template v-slot="{ row }">
      <slot name="operation" v-bind="{ row }"></slot>
    </template>
  </ElTableColumn>
</template>
<script lang="ts">
import Vue, { PropType } from "vue";
import { TableColumn as ElTableColumn } from "element-ui";
import { OPERATION_COLUMN_TITLE_ALIGN } from "@/flexible-table-module/constants";

export default Vue.extend({
  inheritAttrs: false,
  name: "ContainerForAutoHide",
  components: { ElTableColumn },
  props: {
    // 表格唯一标识
    tableUniqueKey: { type: String, required: true },
    // 操作列的宽度
    width: { type: Number },
    // 是否显示操作列
    isVisible: { type: Boolean, required: false },
    // 隐藏模式(鼠标移出时隐藏/点击其他区域时隐藏)
    hidingMode: {
      type: String as PropType<"move-out" | "click-outside">,
      default: "move-out",
    },
  },
  data: () => ({
    OPERATION_COLUMN_TITLE_ALIGN,
    rawTbReszObsv: null as ResizeObserver | null,
  }),
  computed: {
    tableOptBtnsColumnSelector(): string {
      return `#${this.tableUniqueKey}.flexible-table .el-table__fixed-right`;
    },

    rawTablesSelector(): string {
      return `#${this.tableUniqueKey}.flexible-table .el-table table.el-table__body`;
    },

    mainRawTableSelector(): string {
      return `#${this.tableUniqueKey}.flexible-table .el-table .el-table__body-wrapper table.el-table__body`;
    },

    mainRawHeaderSelector(): string {
      return `#${this.tableUniqueKey}.flexible-table .el-table .el-table__header-wrapper table.el-table__header`;
    },
  },
  watch: {
    isVisible: {
      immediate: true,
      handler(isVisible: boolean) {
        this.toggleVisible(isVisible);

        if (this.hidingMode === "move-out") {
          const el = this.getOptBtnsCol();

          if (!el) return;

          if (isVisible) {
            el.addEventListener("mouseleave", this.onMouseMoveOut);
          } else {
            el.removeEventListener("mouseleave", this.onMouseMoveOut);
          }
        } else if (this.hidingMode === "click-outside") {
          if (isVisible) {
            document.addEventListener("click", this.onMouseClickDocument);
          } else {
            document.removeEventListener("click", this.onMouseClickDocument);
          }
        }
      },
    },

    hidingMode: {
      immediate: true,
      handler(hidingMode: string) {
        const el = this.getOptBtnsCol();

        if (el) el.removeEventListener("mouseleave", this.onMouseMoveOut); // 防止重复绑定事件

        document.removeEventListener("click", this.onMouseClickDocument); // 防止重复绑定事件

        if (hidingMode === "move-out") {
          if (!el) return;

          if (this.isVisible) {
            el.addEventListener("mouseleave", this.onMouseMoveOut);
          }
        } else if (hidingMode === "click-outside") {
          if (this.isVisible) {
            document.addEventListener("click", this.onMouseClickDocument);
          }
        }
      },
    },
  },
  async mounted() {
    // 这个貌似不需要也行
    // 而且在网盘项目，本方法会导致表格的宽度有”闪动“，原因不明
    // this.initObservers();
  },
  beforeDestroy() {
    document.removeEventListener("click", this.onMouseClickDocument);

    const el = this.getOptBtnsCol();

    if (el) {
      el.removeEventListener("mouseleave", this.onMouseMoveOut);
    }

    this.toggleVisible(true); // 组件销毁时，还原操作列的显示

    // -------------------

    // this.disconnectObservers();
  },
  methods: {
    // 根据传参隐藏或显示操作列
    toggleVisible(isVisible: boolean) {
      // 找到 .el-table__fixed-right 的元素，使其 display: none
      const el = this.getOptBtnsCol();

      if (el) {
        el.style.display = isVisible ? "block" : "none";
      }
    },

    // 鼠标移出操作列时，隐藏操作列
    onMouseMoveOut() {
      this.$emit("update:isVisible", false);
    },

    // 当鼠标点击除操作栏以外的页面时，隐藏操作列
    onMouseClickDocument(event: MouseEvent) {
      const el = this.getOptBtnsCol();

      if (el && event.target !== el && !el.contains(event.target as any)) {
        this.$emit("update:isVisible", false);
      }
    },

    // 获取操作列的真实 div 元素
    getOptBtnsCol(): HTMLElement | null {
      return document.querySelector(this.tableOptBtnsColumnSelector);
    },

    // 初始化观察者
    async initObservers() {
      await this.$nextTick();
      // 等待1秒
      // await new Promise((resolve) => setTimeout(resolve, 1000));
      // const rawTables = document.querySelectorAll(this.rawTablesSelector);

      const mainRawTable = document.querySelector(
        this.mainRawTableSelector
      ) as HTMLElement | null;
      const mainRawHeader = document.querySelector(
        this.mainRawHeaderSelector
      ) as HTMLElement | null;

      if (mainRawTable) {
        this.rawTbReszObsv = new ResizeObserver(() => {
          requestAnimationFrame(() => {
            // 读取 mainRawTable 的内联 style 里的 width 属性
            const tbStyleWidth = mainRawTable.style.width;

            // 如果 width 有值且值不是 calc( 开头的，设置为 `calc(${tbStyleWidth} - ${this.width}px)`
            // hack: el-table 的宽度是由其内部编码通过内联 style 设置的，并且不是 calc( 开头的，所以这里要 hack 一下
            // 目的是使得 table 的宽度是 table 的原宽度(指 el-table 的内部编码计算出来的宽度)减去操作列的宽度
            if (tbStyleWidth && !tbStyleWidth.startsWith("calc(")) {
              mainRawTable.style.width = `calc(${tbStyleWidth} - ${this.width}px)`;
              // 设置 mainRawHeader 的宽度
              if (mainRawHeader)
                mainRawHeader.style.width = `calc(${tbStyleWidth} - ${this.width}px)`;
            }
          });
        });

        this.rawTbReszObsv.observe(mainRawTable);
      }
    },

    // 断开观察者
    disconnectObservers() {
      const mainRawTable = document.querySelector(
        this.mainRawTableSelector
      ) as HTMLElement | null;
      const mainRawHeader = document.querySelector(
        this.mainRawHeaderSelector
      ) as HTMLElement | null;

      if (mainRawTable) {
        if (mainRawTable.style.width.startsWith("calc(")) {
          const tbWidth = mainRawTable.getBoundingClientRect().width;

          mainRawTable.style.width = `${tbWidth}px`;

          if (mainRawHeader) mainRawHeader.style.width = `${tbWidth}px`;
        }
      }

      this.rawTbReszObsv?.disconnect();
      this.rawTbReszObsv = null;
    },
  },
});
</script>