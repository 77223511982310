<template>
  <ElTableColumn
    :class-name="columnClassName"
    :fixed="fixed === 'none' ? undefined : fixed"
    label="占位"
    :width="width"
    header-align="center"
    :type="''"
  >
    <!-- 当第一列的原生树箭头被隐藏时，本列会出现树箭头，故要隐藏 -->
    <template v-slot:header>
      <div class="occupying-column-header">
        <slot name="header"></slot>
      </div>
    </template>
    <template v-slot="{ row }">
      <slot name="occupying" v-bind="{ row }"></slot>
    </template>
  </ElTableColumn>
</template>
<script lang="ts">
import Vue, { PropType } from "vue";
import { TableColumn as ElTableColumn } from "element-ui";

export default Vue.extend({
  name: "OccupyingColumn",
  components: { ElTableColumn },
  props: {
    width: { type: Number, default: 36 },
    fixed: {
      type: String as PropType<"left" | "right" | "none">,
      default: "none",
    },
    className: { type: String },
  },
  computed: {
    columnClassName(): string {
      return this.className
        ? `occupying-column ${this.className}`
        : "occupying-column";
    },
  },
});
</script>
<style lang="scss" scoped>
@import "src/flexible-table-module/scss/_variables.scss";

.occupying-column-header {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: $clr-table-default;
}
</style>