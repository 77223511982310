import request from "@/utils/appServer";
import store from '@/store';

class Recycle {
  // 查询相关操作-查询回收列表
  static async queryRecyclePageList(data) {
    try {
      const res = await request.get('/sales-show-4.0/recycle/queryRecyclePageList', { params: data });
      return res.data.code === 200 && res.data.result ? res.data.result : {};
    } catch (error) {
      return {};
    }
  }
  // 彻底删除
  static async deleteComplete(payload) {
    try {
      const response = await fetch(`${process.env.VUE_APP_URL}/sales-show-4.0/recycle/deleteComplete`, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          'X-Access-Token': store.state.local.token ? store.state.local.token : "",
          'Tenant-Id': store.state.local.userInfo ? store.state.local.userInfo.tenantIdNow : ""
        },
        body: JSON.stringify(payload)
      });
      const data = await response.json();
      return data.success;
    } catch (error) {
      return false;
    }
  }
  // 还原
  static async restore(data) {
    try {
      const res = await request.post(`/sales-show-4.0/recycle/restore`, data, true);
      return res.data.code === 200 && res.data.success;
    } catch (error) {
      return false;
    }
  }
}

export { Recycle }