<template>
  <div class="text-editor w-h-100 app-scheme-wrapper flex">
    <div class="app-scheme-left">
      <div class="scheme-title flex-between align-items-center">
        <div class="scheme-title-name fw-700">{{ activeColumn.columnName }}</div>
        <div class="flex align-items-center">
          <svg-icon
            v-if="!ispre && !isedit"
            class="mr-10 pointer"
            icon="mind-add"
            color="#5E6C84"
            :height="12"
            :width="12"
            @click.native="handleCreatOrUpdateCategory(null)"
          ></svg-icon>
        </div>
      </div>
      <div class="scheme-title-list">
        <div class="scheme-title-grouplist-noline" style="overflow-y: auto">
          <div class="scheme-title-group" v-for="(i, index) in tableData" :key="i.id">
            <div
              class="scheme-title-group-parent flex align-items-center"
              @click="loadChild(i, index)"
            >
              <i
                v-if="i.showchildren && i.children.length"
                class="el-icon-caret-bottom"
              ></i>
              <i v-if="!i.showchildren" class="el-icon-caret-right"></i>
              <div class="one-line group-parent-name">{{ i.name }}</div>
              <el-popover
                v-if="!ispre && !isedit"
                popper-class="toolbar-item-popover popover"
                placement="right-start"
                width="80"
                trigger="click"
                :ref="'toolbar-' + i.id"
                :visible-arrow="false"
              >
                <div class="toolbar-more" slot="reference" @click.stop></div>
                <div
                  v-if="i.level == 1"
                  class="popover-item"
                  @click.prevent.stop="handleCreatChild(i, null)"
                >
                  添加
                </div>
                <div class="popover-item" @click.prevent.stop="handleCreatOrUpdate(i)">
                  编辑
                </div>
                <div class="popover-divider"></div>
                <div class="popover-item" @click.prevent.stop="handleDelete(i)">删除</div>
              </el-popover>
            </div>
            <div v-show="i.showchildren" class="scheme-title-group-children">
              <div
                v-for="child in i.children"
                :key="child.id"
                class="scheme-title-group-child flex-between align-items-center"
                :class="{
                  'active-child': clickitem && clickitem.id == child.id,
                  'active-selcect-child': selectids.includes(child.id),
                }"
                @click="handleClick(child)"
              >
                <div
                  class="scheme-title-group-child-name one-line flex-between align-items-center"
                >
                  <img
                    class="img-icon scheme-items-icon"
                    :src="
                      child.columnIcon && child.columnIcon.startsWith('http')
                        ? child.columnIcon
                        : require('@/assets/images/sample-5.png')
                    "
                    alt=""
                  />
                  <div :title="child.columnName" class="one-line">
                    {{ child.columnName }}
                  </div>
                </div>
                <el-popover
                  v-if="!ispre && !isedit"
                  popper-class="toolbar-item-popover popover"
                  placement="right-start"
                  width="80"
                  trigger="click"
                  :ref="'toolbar-' + child.id"
                  :visible-arrow="false"
                >
                  <div class="toolbar-more" slot="reference" @click.stop></div>
                  <div
                    v-if="child.level == 1"
                    class="popover-item"
                    @click.prevent.stop="handleCreatChild(child, null)"
                  >
                    添加
                  </div>
                  <div
                    class="popover-item"
                    @click.prevent.stop="handleCreatOrUpdate(child)"
                  >
                    编辑
                  </div>
                  <div class="popover-divider"></div>
                  <div class="popover-item" @click.prevent.stop="handleDelete(child)">
                    删除
                  </div>
                </el-popover>
                <svg-icon
                  v-if="isedit"
                  @click.native.prevent.stop="handleContentSelect(child)"
                  class="mr-24 select-icon pointer"
                  icon="check-fill"
                  color="#5E6C84"
                  :height="15"
                  :width="15"
                ></svg-icon>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="app-scheme-main text-main">
      <ColumnchildView
        v-if="iseditText"
        :visible.sync="iseditText"
        :parentcolumn="activeColumn"
        :clickitem="clickitem"
        :query-ids="queryIds"
        :ispre="isPreview"
      />
    </div>
    <ColumnChild
      v-if="creatDialogVisible"
      :visible.sync="creatDialogVisible"
      :active-column="activeColumn"
      :query-ids="queryIds"
      :clickitem="edititem"
      :columntypeid="columnTypeId"
      @finish="getUpdateTableData"
    />
    <ColumnCategory
      v-if="creatCategoryDialogVisible"
      :visible.sync="creatCategoryDialogVisible"
      :active-column="activeColumn"
      :query-ids="queryIds"
      :clickitem="Categoryedititem"
      @finish="getTableData"
    />
  </div>
</template>

<script>
import { ProductLibrary } from "@/api/ProductLibrary";
import TinyMce from "@/components/TinyMce.vue";
import common from "@/utils/common";
import ColumnChild from "../dialog/ColumnChild.vue";
import ColumnchildView from "./ColumnchildView.vue";
import ColumnCategory from "../dialog/ColumnCategory.vue";
export default {
  name: "RichTextEditor",
  components: { TinyMce, ColumnChild, ColumnchildView, ColumnCategory },
  props: {
    ispre: Boolean,
    // richText: String,
    moduleId: String,
    subjectId: String,
    activeColumn: Object,
    versionId: String,
    isedit: {
      type: Boolean,
      default: false,
    },
    scheme: Object,
    alreadychose: Array,
  },
  data() {
    return {
      // richText1: "", //富文本
      queryData: {
        columnName: "",
        columnId: "",
        pageNo: 1,
        pageSize: 99,
        subjectVersionId: "",
      },
      tableData: [],
      pagingData: new common.Pagination(),
      creatDialogVisible: false,
      queryIds: {
        moduleId: "",
        subjectId: "",
        versionId: "",
      },
      clickitem: null, //点击项
      edititem: null, //编辑项
      Categoryedititem: null, //编辑项
      iseditText: false,
      creatCategoryDialogVisible: false,
      columnTypeId: "", //分类id
      selectids: [], //方案选择的数据id合集
      selectTemData: [], //方案选择的数据合集
    };
  },
  watch: {
    activeColumn(val) {
      this.queryData.columnName = "";
      this.queryData.columnId = val.id;
      this.getTableData(1);
      // this.getTypeList();
    },
    // isedit(val){
    //   val&&this.getdefaultTableData()
    // },
    scheme(val) {
      val && this.getdefaultTableData();
    },
    // selectids(val) {
    //   const selectdata = {
    //     dataIds: val,
    //     dataType: 1,
    //     schemeId: "",
    //     subjectColumnId: this.activeColumn.id,
    //     subjectId: this.subjectId,
    //   };
    //   this.$emit("handleselect", selectdata);
    // },
  },
  computed: {
    isPreview() {
      return this.ispre;
    },
  },
  mounted() {
    this.queryIds = {
      moduleId: this.moduleId,
      subjectId: this.subjectId,
      versionId: this.versionId,
    };
    this.queryData.subjectVersionId = this.versionId;
    this.queryData.columnId = this.activeColumn.id;
    this.getTableData();
    // 关闭资源
    if (!this.isPreview) {
      this.$Pubsub.publish("update-resource-select-data", []);
      this.$Pubsub.publish("update-resource-visible", { visible: false, type: [] });
    }
  },
  methods: {
    /**
     * @description:获取子栏目分类列表
     * @return {void}
     */
    async getTableData() {
      const res = await ProductLibrary.columnTypequeryList(this.activeColumn.id);
      res.forEach((element) => {
        element.columnName = element.name;
        element.level = 1;
        element.children = [];
        element.showchildren = false;
      });
      this.tableData = res;
      if (this.tableData.length) {
        this.loadChild(this.tableData[0], 0);
      }
      if (this.scheme) {
        if (this.alreadychose.length) {
          this.selectids = this.alreadychose[0].dataIds;
        } else {
          this.getdefaultTableData();
        }
      }
    },
    async loadChild(value, index) {
      if (value.children.length) {
        this.tableData[index].showchildren = !this.tableData[index].showchildren;
        return;
      }
      this.queryData.columnTypeId = value.id;
      const res = await ProductLibrary.getSubjectColumnChildList(this.queryData);
      this.tableData[index].children = res.records;
      this.tableData[index].showchildren = true;
      if (res.records.length) {
        this.handleClick(res.records[0]);
      } else {
        this.iseditText = false;
      }
    },
    async getUpdateTableData() {
      const parent = this.tableData.findIndex((item) => item.id == this.columnTypeId);
      if (parent != -1) {
        this.queryData.columnTypeId = this.columnTypeId;
        const res = await ProductLibrary.getSubjectColumnChildList(this.queryData);
        this.tableData[parent].children = res.records;
        this.tableData[parent].showchildren = true;
      }
    },
    /**
     * @description:删除
     * @return {void}
     */
    handleDelete(value) {
      if (value.level != 1) {
        this.$showMessage(
          {
            message: `是否确认删除 <span style="font-weight: 1000;">${value.columnName}</span> ?`,
            ...this.$global.deleteMessage,
          },
          async (done) => {
            const payload = {
              columnCategoryId: value.columnCategoryId,
              columnIcon: value.columnIcon,
              columnId: value.id,
              columnName: value.columnName,
              id: this.subjectId,
              sortNo: value.sortNo,
              versionId: value.versionId,
            };
            const res = await ProductLibrary.deleteColumn(payload);
            done(res);
            if (res) {
              this.getTableData(1);
            }
          }
        );
      } else {
        this.$showMessage(
          {
            message: `是否确认删除分类 <span style="font-weight: 1000;">${value.columnName}</span> ?`,
            ...this.$global.deleteMessage,
          },
          async (done) => {
            const res = await ProductLibrary.deleteCategoryById(value.id);
            done(res);
            if (res) {
              this.getTableData(1);
            }
          }
        );
      }
    },
    /**
     * @description:编辑栏目或者分类
     * @param {Object} value 点击项
     * @return {void}
     */
    handleCreatOrUpdate(value) {
      if (value.level != 1) {
        this.edititem = value;
        this.creatDialogVisible = true;
      } else {
        this.Categoryedititem = value;
        this.creatCategoryDialogVisible = true;
      }
    },
    /**
     * @description:编辑/预览 产品
     * @param {Object} value 点击项
     * @return {void}
     */
    handleClick(value) {
      if (value && value.level == 1) return;
      this.clickitem = value;
      this.iseditText = true;
    },
    /**
     * @description:新建分类
     * @return {void}
     */
    handleCreatOrUpdateCategory(value) {
      this.Categoryedititem = value;
      this.creatCategoryDialogVisible = true;
    },
    /**
     * @description:新建子栏目
     * @return {void}
     */
    handleCreatChild(value, item) {
      this.edititem = item;
      this.columnTypeId = value.id;
      this.creatDialogVisible = true;
    },
    /**
     * @description:获取已选列表
     * @return {void}
     */
    async getdefaultTableData() {
      let payload = {
        schemeId: this.scheme.id,
        pageNo: 1,
        pageSize: 999,
        subjectVersionId: this.versionId,
        subjectColumnId: this.activeColumn.id,
      };
      const res = await ProductLibrary.getSchemeSubjectColumnChildPageList(payload);
      if (res.records.length) {
        this.selectids = res.records.map((item) => item.id);
      }
      this.selectids = Array.from(new Set(this.selectids));
    },
    /**
     * @description:添加选择
     * @return {void}
     */
    handleContentSelect(value) {
      if (this.selectids.includes(value.id)) {
        this.selectids = this.selectids.filter((item) => item != value.id);
        this.selectTemData.push(value);
      } else {
        this.selectids.push(value.id);
        this.selectTemData = this.selectTemData.filter((item) => item.id == value.id);
      }
      this.$emit("selectdata", this.selectTemData);
      const selectdata = {
        dataIds: this.selectids,
        dataType: 1,
        schemeId: "",
        subjectColumnId: this.activeColumn.id,
        subjectId: this.subjectId,
      };
      this.$emit("handleselect", selectdata);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@/scss/common.scss";
.text-editor {
  background-color: #fff;
  .img-icon {
    width: 26px;
    height: 20px;
  }
}
.is-edit {
  padding: 0;
}
.scheme-title-grouplist-noline {
  height: 100%;
  width: 100%;
  box-sizing: border-box;
  overflow-y: auto;
  font-size: 12px;
  color: var(--color-text-primary);
  .scheme-title-group {
    // position: relative;
    .scheme-title-group-parent {
      height: 32px;
      line-height: 32px;
      width: 100%;
      padding: 0 4px;
      position: relative;
      box-sizing: border-box;
      &:hover {
        .toolbar-more {
          display: block;
        }
        background-color: #fcfdfe;
      }
      .toolbar-more {
        display: none;
        position: absolute;
        top: 50%;
        right: 16px;
        width: 12px;
        height: 12px;
        transform: translateY(-50%);
        background-image: url("@/assets/images/common/more_vertical.png");
        background-size: 12px 12px;
        background-repeat: no-repeat;
      }
    }
    .scheme-title-group-children {
      width: 100%;
    }
    .scheme-title-group-child {
      height: 32px;
      line-height: 32px;
      margin-left: 16px;
      position: relative;
      padding: 0;

      &:hover {
        .toolbar-more {
          display: block;
        }
        background-color: #fcfdfe;
      }
      .scheme-title-group-child-name {
        padding-left: 5px;
      }
      img {
        width: 20px;
        height: 16px;
        margin-right: 5px;
        flex-shrink: 0;
      }
      .toolbar-more {
        display: none;
        position: absolute;
        top: 50%;
        right: 16px;
        width: 12px;
        height: 12px;
        transform: translateY(-50%);
        background-image: url("@/assets/images/common/more_vertical.png");
        background-size: 12px 12px;
        background-repeat: no-repeat;
      }
      .select-icon {
        flex-shrink: 0;
      }
    }

    .active-child {
      background-color: #f1ebff;
      &:hover {
        background-color: #f1ebff;
      }
    }
    .active-selcect-child {
      .svg-icon {
        color: #43cf7c !important;
      }
    }
    .border-none {
      border: none;
    }
    .mr-24 {
      margin-right: 24px;
    }
    .mr-8 {
      margin-right: 8px;
    }
  }
}
.fw-700 {
  font-weight: 700 !important;
}
.text-main {
  overflow-x: auto;
}
</style>
