<template>
  <div
    class="auto-hide-agent"
    :style="agentStyle"
    @mouseenter="handleMouseEnter"
    @mouseleave="handleMouseLeave"
  >
    <div class="btn-group">
      <!-- <div class="btn btn-up"></div> -->
      <!-- <div class="btn btn-main"></div> -->
      <!-- <div class="btn btn-down"></div> -->
    </div>
  </div>
</template>

<script lang="ts">
import Vue from "vue";
export default Vue.extend({
  name: "AutoHideAgent",
  props: {
    offsetTop: { type: Number, default: 0 },
  },
  computed: {
    agentStyle() {
      return {
        top: `${this.offsetTop}px`,
        height: `calc(100% - ${this.offsetTop}px)`,
      };
    },
  },
  methods: {
    handleMouseEnter() {
      this.$emit("show-auto-hide-column");
    },
    handleMouseLeave() {
      // this.$emit("hide-auto-hide-column");
    },
  },
});
</script>

<style lang="scss" scoped>
.auto-hide-agent {
  width: 48px;
  height: 100%;
  position: absolute;
  top: 0;
  right: 0;
  // background-color: yellow;

  .btn-group {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
  }

  // .btn-main {
  //   width: 20px;
  //   height: 20px;
  //   background-color: #f00;
  //   border-radius: 50%;
  // }

  // .btn-up {
  //   width: 40px;
  //   height: 40px;
  //   background-color: #f00;
  //   border-radius: 50%;
  // }

  // .btn-down {
  //   width: 40px;
  //   height: 40px;
  //   background-color: #f00;
  //   border-radius: 50%;
  // }
}
</style>