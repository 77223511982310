export class TreeHelper {
    public childrenPropName: string;
    public isCheckedPropName: string;

    constructor(params: {
        childrenPropName?: string;
        isCheckedPropName?: string;
    } = {}) {
        const {
            childrenPropName = "children",
            isCheckedPropName = "isChecked",
        } = params;

        this.childrenPropName = childrenPropName;
        this.isCheckedPropName = isCheckedPropName;
    }

    /**
     * 递归地获取所有下级的被勾选的节点
     * @returns 节点数组
     */
    public recursionGetChecked(nodes: any[]): any[] {
        if (nodes && nodes.length > 0) {
            const nodesChecked: any[] = [];

            for (const node of nodes) {
                const subChildren = node[this.childrenPropName];

                if (subChildren && subChildren.length > 0) {
                    // 存在子级节点
                    const result = this.recursionGetChecked(subChildren);
                    nodesChecked.push(...result);
                } else {
                    // 不存在子级节点
                    if (node[this.isCheckedPropName]) nodesChecked.push(node);
                }
            }

            return nodesChecked;
        } else return [];
    }

    /**
     * 递归地获取所有下级的被勾选的节点(包括父节点链)
     * @returns 节点及父节点数组的数组(例如：[被勾选的节点, 被勾选的节点的父节点, 被勾选的节点的父节点的父节点])
     */
    public recursionGetCheckedWithAncestor(nodes: any[], ...parentNodes: any[]): any[][] {
        if (nodes && nodes.length > 0) {
            const nodesChecked: any[][] = [];

            for (const node of nodes) {
                const subChildren = node[this.childrenPropName];

                if (subChildren && subChildren.length > 0) {
                    // 存在子级节点
                    const result = this.recursionGetCheckedWithAncestor(
                        subChildren,
                        node,
                        ...parentNodes
                    );
                    nodesChecked.push(...result);
                } else {
                    // 不存在子级节点(即本身是叶子节点)
                    if (node[this.isCheckedPropName])
                        nodesChecked.push([node, ...parentNodes]);
                }
            }

            return nodesChecked;
        } else return [];
    }
}

