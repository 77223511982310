<template>
  <div class="header-cell-normal">
    <div class="title">{{ title }}</div>
  </div>
</template>
<script lang="ts">
import Vue from "vue";
export default Vue.extend({
  inheritAttrs: false,
  props: {
    title: { type: String, required: true },
  },
});
</script>
<style lang="scss" scoped>
@import "src/flexible-table-module/scss/_variables.scss";

.header-cell-normal {
  .title {
    color: $clr-el-table-header;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
}
</style>