<template>
  <div>
    <el-upload
      ref="upload"
      :on-change="changFile"
      :auto-upload="false"
      :show-file-list="false"
      action
      accept=".doc, .docx, .pdf, .xls, .xlsx, .ppt,.pptx"
    >
      <div slot="trigger" style="display: inline-block">
        <!-- <el-button style="font-size: 14px" icon="el-icon-upload2" size="small" type="text"
          >导入</el-button
        > -->
        <svg-icon
          class="mr-10 pointer"
          icon="edit-import"
          color="#5E6C84"
          :height="12"
          :width="12"
        ></svg-icon>
      </div>
    </el-upload>
    <el-dialog
      append-to-body
      class="ss-dialog"
      width="312px"
      :visible.sync="loading"
      :title="uploadNow ? '正在上传……' : '正在解析中……'"
    >
      <div v-if="loading" class="custom-loading">
        <div class="custom-loading-spinner">
          <el-progress
            :text-inside="true"
            :stroke-width="uploadNow ? 26 : 10"
            :percentage="per"
            :width="150"
            style="width: 150px; left: 50%; transform: translateX(-50%)"
            :type="uploadNow ? 'line' : 'circle'"
          ></el-progress>
          <!-- <p class="custom-loading-text pointer">
            {{ uploadNow ? "正在上传" : "正在解析中" }}...
          </p> -->
          <p
            v-if="controller"
            class="custom-loading-text pointer"
            style="margin-top: 16px; text-align: center"
            @click="abortImport"
          >
            取消{{ uploadNow ? "上传" : "解析" }}
          </p>
        </div>
      </div>
    </el-dialog>
    <ImportArticle
      v-if="dialogVisible"
      :visible.sync="dialogVisible"
      :text="richText"
      @finish="handleImport"
    />
  </div>
</template>
<script>
import { File } from "../../../api/File";
import ImportArticle from "./ImportArticle.vue";
export default {
  components: { ImportArticle },
  data() {
    return {
      per: 0, //进度
      loading: false,
      uploadNow: false, //正在上传
      dialogVisible: false, // 导入视频
      controller: null, // 控制取消导入
      richText: "",
    };
  },
  methods: {
    // 上传office文件
    async changFile(file) {
      let uploadForm = new FormData();
      this.controller = new AbortController();
      this.key = (
        new Date().getTime().toString(16) + Math.random().toString(16).substring(2)
      ).substring(2, 18);
      uploadForm.append("file", file.raw);
      uploadForm.append("fileKey", this.key);
      this.loading = true;
      this.per = 0;
      const res = await File.importOfficeFile(uploadForm, {
        signal: this.controller.signal,
        onUploadProgress: (progressEvent) => {
          this.uploadNow = true;
          const percent = Math.round((progressEvent.loaded / progressEvent.total) * 100);
          this.per = percent;
        },
      });
      if (res && this.loading) {
        this.per = 0;
        this.uploadNow = false;
        this.filename = file.name;
        this.time = setInterval(() => {
          this.Progress(this.key);
        }, 1000);
        setTimeout(() => {
          if (this.per == 0) {
            this.cancel();
            this.$message.error("解析失败");
          }
        }, 30000);
      }
    },
    /**
     * @description:  轮询使用，查询进度
     * @param {String} key 查询id
     * @return {void}
     */ async Progress(key) {
      let res2 = await File.getPareFileProgress(key);
      if (res2) {
        if (res2.split("<")[1]) {
          this.per = 100;
          setTimeout(() => {
            this.cancel();
            const fileExtension = this.filename.split(".").pop();
            res2 = this.getdivContent(res2);
            // 文件类型判断
            if (fileExtension === "docx") {
              res2 = res2.replace(/margin-left:90.0pt;/g, "");
            }
            // if (this.demo) {
            if (
              fileExtension === "pdf" ||
              fileExtension === "ppt" ||
              fileExtension === "pptx"
            ) {
              res2 = res2.replace(/<img/g, function (match, className) {
                return `<img style="max-width: 100%;height:100%"`;
              });
              res2 = res2.replace(/<img([\s\S]*?)>/g, function (match, className) {
                return `<div style=" width: 100%;text-align: center;height:100vh;margin: 0;padding: 0;">${match}</div>`;
              });
              // }
            }
            this.richText = res2;
            this.dialogVisible = true;
            this.$message.success("导入成功！");
          }, 400);
        } else {
          this.per = Number(res2);
        }
      }
    },
    /**
     * @description:  获取doc文件内容处理
     * @param {String} docContent 返回的doc内容
     * @return {void}
     */
    getdivContent(docContent) {
      var bodyContent = docContent.match(/<body[^>]*>([\s\S]*?)<\/body>/i);
      if (bodyContent && bodyContent.length > 1) {
        bodyContent = bodyContent[1];
      } else {
        bodyContent = "";
      }
      // 将class对应的style详情替换进标签内
      bodyContent = bodyContent.replace(/class="([^"]+)"/g, function (match, className) {
        var styleDetails = "";
        className.split(" ").forEach(function (cls) {
          var styleMatch = docContent.match(new RegExp(cls + "\\s*{([^}]*)}"));
          if (styleMatch && styleMatch.length > 1) {
            styleDetails += styleMatch[1];
          }
        });
        return 'style="' + styleDetails + '"';
      });
      return bodyContent;
    },
    /**
     * @description:  取消轮询
     * @return {void}
     */
    cancel() {
      this.controller = null;
      this.loading = false;
      clearInterval(this.time);
    },
    /**
     * @description: 取消导入
     * @return {void}
     */
    abortImport() {
      if (!this.uploadNow) {
        this.cancel();
        this.$message.error("已取消");
      } else {
        if (this.controller) {
          this.controller.abort();
          this.controller = null;
          this.loading = false;
        }
      }
    },
    /**
     * @description: 获取新增文章id
     * @param {String} id 文章id
     * @return {void}
     */
    handleImport(value) {
      this.$emit("import", value);
    },
  },
};
</script>
<style lang="scss" scoped>
.custom-loading {
  width: 100%;
  padding: 20px;
  .custom-loading-spinner {
    width: 100%;
    position: relative;
  }
}
.mr-10 {
  margin-right: 10px;
}
</style>
