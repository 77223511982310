<template>
  <el-dialog append-to-body class="ss-dialog no-header" width="420px" :close-on-click-modal="false"
    :close-on-press-escape="false" :visible.sync="dialogVisible">
    <div class="content">
      <div class="filename">{{ filename }}</div>
      <el-progress color="#4ed4cb" :percentage="progress"></el-progress>
    </div>
    <div class="text-center" slot="footer">
      <el-button type="primary" @click="handleCancel">取消上传</el-button>
    </div>
  </el-dialog>
</template>

<script>
export default {
  name: "UploadProgress",
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    filename: {
      type: String,
      default: '',
    },
    percentage: {
      type: Number,
      default: 0
    },
  },
  data() {
    return {
      progress: 0, // 进度条
    }
  },
  computed: {
    dialogVisible: {
      get() {
        return this.visible;
      },
      set(val) {
        this.$emit('update:visible', val);
      }
    },
  },
  watch: {
    percentage(newValue) {
      this.progress = newValue;
    }
  },
  mounted() {
    this.progress = this.percentage;
  },
  methods: {
    /**
     * @description: 取消上传
     * @return {void}
     */
    handleCancel() {
      this.dialogVisible = false;
      this.$emit('cancel');
    },
  }
}
</script>

<style lang="scss" scoped>
.content {
  padding: 32px;

  .filename {
    padding: 0 20px 20px;
    font-size: 16px;
    font-weight: 500;
    text-align: center;
    word-break: break-all;
  }
}
</style>