<template>
  <div class="main-multiple" v-loading="!multiFilter">
    <div class="header">
      <div class="title-edit">
        <ElInput
          v-model="title"
          size="small"
          placeholder="请输入内容"
          @focus="onTitleFocus"
          @blur="onTitleBlur"
        ></ElInput>
      </div>
    </div>
    <div class="btns" v-if="history.hasHistory || history.hasFuture">
      <LastAndNextStepBtns
        :hasHistory="history.hasHistory"
        :hasFuture="history.hasFuture"
        @last-step-click="onLastStepClick"
        @next-step-click="onNextStepClick"
      />
    </div>
    <div
      class="body"
      :class="{ 'under-step-btns': history.hasHistory || history.hasFuture }"
    >
      <template v-if="treeNodes.length > 0">
        <SimpleTree
          :nodes="treeNodes"
          :props="TREE_PROPS"
          :hasCheckbox="false"
          :isNodeClickSingleCheckable="true"
          :draggable="true"
        />
      </template>
      <template v-else>
        <ElEmpty description="暂无数据"></ElEmpty>
      </template>
      <div
        class="drop-mask"
        v-if="dropMaskActive"
        @dragover.prevent
        @drop="onDrop"
      ></div>
    </div>
  </div>
</template>
<script lang="ts">
import Vue, { type DirectiveOptions, PropType } from "vue";
import { Input as ElInput, Empty as ElEmpty } from "element-ui";
import PubSub from "pubsub-js";

import { Loading } from "@/flexible-table-module/components/Common/Loading";

import SimpleTree from "@/flexible-table-module/components/Common/Tree/SimpleTree.vue";
import LastAndNextStepBtns from "./LastAndNextStepBtns.vue";
import { Filter, MultiFilter } from "@/flexible-table-module/entity/Filter";
import { ITreeNode, TreeNode } from "@/flexible-table-module/entity/TreeNode";
import { FILTER_DRAG_DROP_FORMAT } from "@/flexible-table-module/constants";
import { debounce } from "@/flexible-table-module/util/InputOptimize";
import History from "@/flexible-table-module/components/Common/History/History";
import HistoryStep from "@/flexible-table-module/components/Common/History/HistoryStep";

import { TREE_PROPS } from "@/flexible-table-module/constants";

export default Vue.extend({
  inheritAttrs: false,
  name: "MainMultiple",
  components: { ElInput, ElEmpty, SimpleTree, LastAndNextStepBtns },
  directives: { loading: Loading.directive },
  props: {
    multiFilter: { type: Object as PropType<MultiFilter>, required: true },
  },
  data(): {
    treeNodes: TreeNode[];
    TREE_PROPS: typeof TREE_PROPS;
    onTitleChange: (newTitle: string, oldTitle: string) => void;
    isTitleEditing: boolean;
    history: History;
    lastCommittedTitle: string | undefined;
    dropMaskActive: boolean;
  } {
    return {
      treeNodes: [],
      TREE_PROPS,
      onTitleChange: () => {},
      isTitleEditing: false, // 标题是否正处于编辑中
      history: new History(),
      lastCommittedTitle: undefined,
      dropMaskActive: false,
    };
  },
  computed: {
    title: {
      get(): string {
        if (this.multiFilter && this.multiFilter.title)
          return this.multiFilter.title;
        else return "";
      },
      set(v: string) {
        if (this.multiFilter) this.multiFilter.title = v;
      },
    },
  },
  watch: {
    title: {
      immediate: true,
      handler(newVal, oldVal) {
        if (this.isTitleEditing) {
          this.onTitleChange(newVal, oldVal);
        }
      },
    },

    multiFilter: {
      immediate: true,
      handler(newVal: MultiFilter, oldVal) {
        this.treeNodes = [];
        if (newVal) {
          for (const filter of this.multiFilter.filters) {
            this.addTreeNodes(filter.list, filter);
          }

          // if (this.lastCommittedTitle === undefined) {
          this.lastCommittedTitle = newVal.title; // 初始化
          this.history.clear();
          // }
        }
      },
    },
  },
  mounted() {
    this.onTitleChange = debounce((newTitle: string, oldTitle: string) => {
      if (this.multiFilter) {
        const title = this.lastCommittedTitle + "";

        const titleHisStep = new HistoryStep({
          onUndo: () => {
            this.title = title;
            this.$emit("edit-title-multiple-filter", this.multiFilter);
          },
          onRedo: () => {
            this.title = newTitle;
            this.$emit("edit-title-multiple-filter", this.multiFilter);
          },
        });
        this.history.add(titleHisStep);

        this.lastCommittedTitle = this.multiFilter.title;
        this.$emit("edit-title-multiple-filter", this.multiFilter);
      }
    }, 500);

    // 订阅侧边栏单个项拖拽开始事件
    PubSub.subscribe(
      "side-single-item-drag-start",
      this.onSideSingleItemDragStart
    );

    PubSub.subscribe("side-single-item-drag-end", this.onSideSingleItemDragEnd);
  },
  methods: {
    onDrop(ev: DragEvent) {
      ev.preventDefault();
      if (ev.dataTransfer) {
        ev.dataTransfer.dropEffect = "copy";
        const data = ev.dataTransfer.getData(FILTER_DRAG_DROP_FORMAT);
        const filterObj = JSON.parse(data);
        const filterCopy = new Filter(filterObj);
        this.addTreeNodes(filterCopy.list, filterCopy);

        if (this.multiFilter) {
          this.multiFilter.filters.push(filterCopy);

          const dropHisStep = new HistoryStep({
            onUndo: () => {
              this.multiFilter.filters.pop();

              this.treeNodes = [];
              for (const filter of this.multiFilter.filters) {
                this.addTreeNodes(filter.list, filter);
              }

              this.$emit("edit-filter-multiple-filter", this.multiFilter);
            },
            onRedo: () => {
              this.addTreeNodes(filterCopy.list, filterCopy);

              this.multiFilter.filters.push(filterCopy);
              this.$emit("edit-filter-multiple-filter", this.multiFilter);
            },
          });
          this.history.add(dropHisStep);

          this.$emit("edit-filter-multiple-filter", this.multiFilter);
        }
      }
    },

    onDragOver(ev: DragEvent) {
      ev.preventDefault();
      // Get the id of the target and add the moved element to the target's DOM
      if (ev.dataTransfer) {
        // const data = ev.dataTransfer.getData("text/plain");
      }
    },

    onTitleFocus() {
      this.isTitleEditing = true;
    },
    onTitleBlur() {
      this.isTitleEditing = false;
    },

    addTreeNodes(newTreeNodes?: ITreeNode[], filter?: Filter) {
      if (newTreeNodes && newTreeNodes.length > 0) {
        if (this.treeNodes.length === 0) {
          // 如果数组为空
          const newTns = newTreeNodes.map(
            (tn) => new TreeNode({ ...tn, attachment: filter })
          );
          this.treeNodes.push(...newTns);
        } else {
          // 如果数组已有至少一个树
          this.treeNodes.forEach((tn) => {
            tn.traverse((subTn) => {
              if (!subTn.children) {
                // 如果没有 children，证明是末端节点
                const newTns = newTreeNodes.map(
                  (tn) => new TreeNode({ ...tn, attachment: filter })
                );
                subTn.children = newTns;
              }
            });
          });
        }
      }
    },

    onLastStepClick() {
      this.history.undo();
    },

    onNextStepClick() {
      this.history.redo();
    },

    onSideSingleItemDragStart() {
      this.dropMaskActive = true;
    },

    onSideSingleItemDragEnd() {
      this.dropMaskActive = false;
    },
  },
});
</script>
<style lang="scss" scoped>
.main-multiple {
  width: 100%;
  height: 100%;

  @import "./MainCommon.scss";

  .body {
    position: relative;
    .drop-mask {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      // background-color: rgba(0, 0, 0, 0.1);
      z-index: 1;
    }
  }
}
</style>