<template>
  <ElTableColumn
    type="selection"
    :width="SELECTION_COLUMN_WIDTH"
    fixed
    :reserve-selection="isSelectionReserved"
  ></ElTableColumn>
</template>
<script lang="ts">
import Vue from "vue";
import { TableColumn as ElTableColumn } from "element-ui";
import { SELECTION_COLUMN_WIDTH } from "@/flexible-table-module/constants";

export default Vue.extend({
  inheritAttrs: false,
  name: "MultiSelectionColumn",
  components: { ElTableColumn },
  data() {
    return { SELECTION_COLUMN_WIDTH };
  },
  props: {
    isSelectionReserved: { type: Boolean, default: false }, // 换页时，是否保持之前的选择
  },
});
</script>