<template>
  <div
    class="filelist"
    v-if="dialogVisible"
    element-loading-background="rgba(40, 47, 57,0.8)"
  >
    <div class="file-left" v-show="close && haslist">
      <div class="file-left-title">
        <div>{{ activeMenu.name }}文件</div>
        <div @click="closeList">
          <img src="@/assets/images/productLibrary/preview-close2.png" alt="" />
        </div>
      </div>
      <div class="file-left-list" v-loading="loading">
        <el-tree ref="filetree" :data="treeData" node-key="id" style="margin-top: 10px">
          <div
            class="custom-tree-node flex align-items-center"
            :class="{ 'active-tree-node': currentItem && currentItem.id == data.id }"
            slot-scope="{ node, data }"
          >
            <svg-icon :icon="data.icon"></svg-icon>
            <div
              class="one-line file-label"
              :title="node.label"
              @click="handleClick(data)"
              @dblclick.prevent.stop="handledbClick(data, node)"
            >
              <span v-if="!data.showprogress">{{ node.label }}</span>
              <el-progress
                v-else
                color="#4ed4cb"
                :percentage="data.progress"
                style="width: 180px; margin-top: 10px"
              ></el-progress>
            </div>
          </div>
        </el-tree>
      </div>
    </div>
    <div class="file-right">
      <div v-if="!close" class="file-right-closeicon" @click="closeList">
        <img src="@/assets/images/productLibrary/preview-expand.png" alt="" />
      </div>
      <FileTemPreview
        id="file_preview"
        v-if="preview"
        :visible.sync="preview"
        :haslist="false"
        :currentitem.sync="currentItem"
        :color="'#ffffff'"
        :loginrequired="loginrequired"
      />
      <div class="file-close" @click="handleClose">
        <img src="@/assets/images/common/close.png" alt="" />
      </div>
      <div class="file-close detail-full" @click="fullScreenButton">
        <div class="el-icon-full-screen"></div>
      </div>
      <PlugInDialog v-if="plugvisible" :visible.sync="plugvisible" />
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import FileTemPreview from "../../../components/filecompanies/FileTemPreview.vue";
import PlugInDialog from "../../../components/dialog/PlugIn.vue";
import { ProductLibrary } from "@/api/ProductLibrary";
import screenfull from "screenfull";
export default {
  // props: {
  //   subject: Object, // 主题详情
  //   scheme: Object,
  // },
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    subject: Object,
    haslist: {
      type: Boolean,
      default: true,
    }, //是否显示左侧列表
    currentitem: Object,
    activecolum: Object, //选择的栏目
    activeMenu: Object, //当前模块
    loginrequired:{
      type:Boolean,
      default:true//是否需要登录
    },
  },
  components: {
    FileTemPreview,
    PlugInDialog,
  },
  data() {
    return {
      showfile: true,
      treeData: [],
      tableData: [],
      previewpayload: {},
      preview: false,
      selectList: [], // 选择的文件列表
      currentItem: null, //点击项
      loading: false,
      plugvisible: false,
      close: true, //左侧列表的显示
    };
  },
  watch: {
    isconnected(val) {
      if (val) {
        this.init();
      }
    },
    subject(val) {
      if (val) {
        this.breadcrumbList = [];
        this.previewpayload = {
          moduleId: this.subject.moduleId,
          schemeId: this.subject.schemeId,
          subjectId: this.subject.id,
          fileName: "",
        };
        this.getFirstTableData();
      }
    },
  },
  computed: {
    dialogVisible: {
      get() {
        return this.visible;
      },
      set(val) {
        this.$emit("update:visible", val);
      },
    },
    ...mapGetters(["token", "userInfo", "isconnected"]),
  },
  mounted() {
    if (this.subject) {
      this.previewpayload = {
        moduleId: this.subject.moduleId,
        schemeId: this.subject.schemeId,
        subjectId: this.subject.id,
        fileName: "",
      };
      if(!this.loginrequired){
        this.previewpayload.shareId = this.subject.shareId;
      }
      this.getFirstTableData();
      this.init();
    }
  },
  beforeDestroy() {
    this.$messageService.$off("websocketMessage");
  },
  methods: {
    /**
     * @description: 关闭左侧列表
     * @return {void}
     */
    closeList() {
      this.close = !this.close;
    },
    /**
     * @description:获取列表
     * @return {void}
     */
    async getFirstTableData() {
      this.loading = true;
      const res = await ProductLibrary[this.loginrequired ?"queryFileBySubjectScheme":"queryFileBySubjectSchemeNoToken"](this.previewpayload);
      res.forEach((element) => {
        if (element.fileType != 2) {
          element.fileSize = this.common.formatFileSize(element.fileSize);
          element.aliasType = this.common.getFileType(
            element.contentType,
            element.fileSavePath
          );
          element.aliasStringType = this.getFileType(element.fileSavePath);
        } else {
          element.aliasType = "folder";
          element.aliasStringType = "文件夹";
        }
        element.icon = this.getFileIcon(element.aliasStringType);
        element.showprogress = false;
        element.progress = 0;
      });
      this.tableData = res;
      const tree = this.organizeByAliasType(this.tableData);
      this.treeData = tree.filter((item) => item.label != "文件夹");
      this.loading = false;
    },
    organizeByAliasType(data) {
      const result = [];
      const typeMap = {};

      data.forEach((item) => {
        const type = item.aliasStringType;
        item.label = item.folderName ? item.folderName : item.fileName;

        if (!typeMap[type]) {
          typeMap[type] = {
            label: type,
            isparent: true,
            id: type,
            icon: this.getFileIcon(type),
            children: [],
          };
          result.push(typeMap[type]);
        }

        typeMap[type].children.push(item);
      });

      return result;
    },
    /**
     * @description: 根据 url 获取文件类型
     * @param {String} url 文件地址
     * @return {String} 返回的文件类型
     */
    getFileType(url) {
      const suffix = "." + url.split(".").pop();
      if (suffix == ".pptx" || suffix == ".ppt") {
        return "PPT";
      } else if (suffix == ".docx" || suffix == ".wps" || suffix == ".doc") {
        return "文档";
      } else if (suffix == ".pdf") {
        return "PDF";
      } else if (suffix == ".xlsx" || suffix == ".xls") {
        return "表格";
      } else if (this.$global.modelType.indexOf(suffix) !== -1) {
        return "模型";
      } else if (this.$global.videoType.indexOf(suffix) !== -1) {
        return "视频";
      } else if (this.$global.ImageType.indexOf(suffix) !== -1) {
        return "图片";
      } else {
        return "其他";
      }
    },
    /**
     * @description: 根据文件类型返回图标
     * @param {String} 文件类型
     * @return {String} 返回的文件类型图标
     */
    getFileIcon(type) {
      if (type == "PPT") {
        return "filetype-ppt";
      } else if (type == "文档") {
        return "filetype-doc";
      } else if (type == "PDF") {
        return "filetype-pdf";
      } else if (type == "表格") {
        return "filetype-excel";
      } else if (type == "模型") {
        return "filetype-model";
      } else if (type == "视频") {
        return "filetype-video";
      } else if (type == "图片") {
        return "filetype-image";
      } else {
        return "filetype-unknown";
      }
    },
    /**
     * @description: 预览
     * @param {Object} value 点击项
     * @return {void}
     */
    handleClick(data) {
      if (data.isparent) {
        return;
      }
      if(!this.loginrequired){
        data.shareId = this.subject.shareId;
      }
      this.currentItem = data;
      this.preview = true;
    },
    /**
     * @description: 全屏
     * @return {void}
     */
    fullScreenButton() {
      const elementById = document.getElementById("file_preview");
      if (screenfull.isEnabled && elementById) {
        screenfull.request(elementById);
      }
    },
    /**
     * @description: 初始化消息
     * @return {void}
     */
    init() {
      this.$messageService.$on("websocketMessage", (data) => {
        // 处理接收到的消息
        const parsedMessage = JSON.parse(data);
        if (parsedMessage.funcname == "downloadingFile") {
          let percentage = Number(parsedMessage.data[0].progress.replace("%", ""));
          this.UpdatePercent(parsedMessage.msgId, true, percentage);
          if (parsedMessage.data[0].status === "DONE" && parsedMessage.data[0].progress) {
            this.isDownloading = false;
            this.UpdatePercent(parsedMessage.msgId, false, percentage);
          }
        }
      });
    },
    /**
     * @description: 更新节点下载的百分比
     * @param {String} id 点击项
     * @param {Boolean} showprogress 是否显示进度条
     * @param {Number} percentage 百分比
     * @return {void}
     */
    UpdatePercent(id, showprogress, percentage) {
      this.treeData.forEach((item) => {
        let index = item.children.findIndex((item) => item.id == id);
        if (index != "-1") {
          item.children[index].progress = percentage;
          item.children[index].showprogress = showprogress;
        }
      });
    },
    /**
     * @description: 行双击下载文件并在本地打开
     * @param {Object} value 点击项
     * @return {void}
     */
    handledbClick(value) {
      if (value.isparent) {
        return;
      }
      if (!this.isconnected) {
        this.plugvisible = true;
        return;
      }
      this.percentage = 0;
      if (this.$websocket.readyState === WebSocket.OPEN) {
        // 发送消息
        let queryDownloadData = {
          msgId: value.id,
          funcname: "downloadfile",
          downloadType: "1", //下载消息类型，1:开始，2:暂停,3:取消
          systemType: "1",
          token: this.token ? this.token : "",
          tenantId: this.userInfo ? this.userInfo.tenantIdNow : "",
          baseurl: process.env.VUE_APP_URL_NETDISK,
          fileSysBaseUrl: process.env.VUE_APP_URL_NETDISK,
          fileId: value.id,
          isCurrent: "1", //是否下载当前(1:是当前，2：是历史)(如果是历史版本必填)
          sysToken: "JqjdLEtaFOas5whS8hro2w==",
        };
        this.$nextTick(() => {
          this.$websocket.send(JSON.stringify(queryDownloadData));
        });
      }
    },
    /**
     * @description: 关闭弹窗
     * @return {void}
     */
    handleClose() {
      this.dialogVisible = false;
      this.$emit("close");
    },
  },
};
</script>

<style lang="scss" scoped>
.filelist {
  height: 100%;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 122;
  background-color: rgba(40, 47, 57, 0.8);
  display: flex;
  padding: 80px;
  color: var(--color-text-primary);
  box-sizing: border-box;

  .file-left {
    width: 300px;
    padding: 0 20px 0 26px;
    // background-color: rgb(40, 47, 57);
    background-color: #fff;
    font-size: 16px;

    .file-left-title {
      height: 60px;
      line-height: 60px;
      border-bottom: 1px solid #c3cdde;
      display: flex;
      font-size: 16px;
      justify-content: space-between;

      img {
        height: 24px;
        width: 24px;
        vertical-align: middle;
      }
    }

    .file-left-list {
      height: calc(100% - 120px);
      overflow-y: auto;
      margin-top: 16px;
      color: var(--color-text-primary);

      .file-label {
        max-width: 198px;
      }

      .active {
        color: #fff;
        border-radius: 5px;
        color: var(--color-primary);
      }
    }
  }

  .file-right {
    flex: 1;
    height: 100%;
    // width: 100%;
    display: flex;
    align-items: center;
    position: relative;
    background-color: #ccc;
    overflow: hidden;

    .file-right-closeicon {
      position: absolute;
      left: 11px;
      top: 16px;
      z-index: 10;

      img {
        height: 24px;
        width: 24px;
      }
    }
  }

  .file-close {
    height: 30px;
    width: 30px;
    position: absolute;
    top: 10px;
    right: 10px;
    border-radius: 5px;
    z-index: 14;

    img {
      height: 20px;
      width: 20px;
      margin-left: 5px;
      margin-top: 5px;
    }

    &:hover {
      background-color: rgba(0, 0, 0, 0.3);
    }
  }

  .detail-full {
    color: #c6c6c6;
    font-size: 20px;
    text-align: center;
    line-height: 30px;
    right: 50px !important;
  }
}

.video-js[tabindex="-1"] {
  outline: none;
  height: 100%;
  width: 100%;
}

::v-deep .el-breadcrumb__inner {
  color: #fff;
}

.file-label {
  max-width: 100px;
  margin-left: 5px;
  line-height: 34px;
}

.custom-tree-node {
  padding-left: 5px;
  width: 100%;

  .svg-icon {
    flex-shrink: 0;
  }
}

.active-tree-node {
  background-color: #f1ebff;
  font-weight: 700;
  color: var(--color-primary);
  border-radius: 5px;
}

::v-deep .el-tree-node__content {
  height: 34px;
}
</style>
