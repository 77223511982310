<template>
  <el-dialog
    append-to-body
    class="ss-dialog"
    width="420px"
    :visible.sync="dialogVisible"
    :title="clickitem ? '编辑组' : '新建组'"
  >
    <div class="dialog-body">
      <el-form
        class="add-form"
        label-position="right"
        label-width="60px"
        :model="queryData"
      >
        <el-form-item label="名称" required>
          <el-input v-model="queryData.name" maxlength="10" show-word-limit></el-input>
        </el-form-item>
      </el-form>
    </div>
    <span slot="footer">
      <div class="dialog-footer">
        <span class="info"></span>
        <span>
          <el-button type="info" @click="dialogVisible = false">取&nbsp;消</el-button>
          <el-button type="primary" :loading="confirmLoading" @click="confirmSave"
            >确&nbsp;定</el-button
          >
        </span>
      </div>
    </span>
  </el-dialog>
</template>
<script>
import { mapGetters } from "vuex";
import { ProductLibrary } from "@/api/ProductLibrary";
export default {
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    activeColumn: Object,
    queryIds: Object,
    clickitem: Object,
  },
  data() {
    return {
      showList: [],
      queryData: {
        columnId: "",
        // createAt: "",
        // createBy: "",
        // createName: "",
        // delFlag: 0,
        id: "",
        name: "",
        parentId: "-1",
        // sortNo: 0,
        tenantId: "",
      },
      confirmLoading: false,
    };
  },
  computed: {
    dialogVisible: {
      get() {
        return this.visible;
      },
      set(val) {
        this.$emit("update:visible", val);
      },
    },
    ...mapGetters(["token", "userInfo"]),
  },
  mounted() {
    if (this.clickitem) {
      this.queryData = _.cloneDeep(this.clickitem);
      this.$delete(this.queryData, "columnName");
      this.$delete(this.queryData, "level");
    } else {
      this.queryData.columnId = this.activeColumn.id;
      this.queryData.tenantId = this.userInfo.tenantIdNow;
    }
  },
  methods: {
    /**
     * @description: 保存
     * @return {void}
     */
    async confirmSave() {
      if (!this.queryData.name) {
        return this.$message.warning("请输入名称");
      }
      this.confirmLoading = true;
      let res;
      if (this.clickitem && this.clickitem.id) {
        const props = ["columnId", "id", "name", "parentId", "sortNo", "tenantId"];
        let payload = _.pick(this.queryData, props);
        res = await ProductLibrary.editCategory(payload);
      } else {
        res = await ProductLibrary.saveCategory(this.queryData);
      }
      if (res) {
        this.dialogVisible = false;
        this.$emit("finish");
      }
      this.confirmLoading = false;
    },
  },
};
</script>
<style lang="scss" scoped>
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 100px;
  height: 80px;
  line-height: 80px;
  text-align: center;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.avatar {
  width: 100px;
  height: 80px;
  display: block;
  border-radius: 5px;
}

.add-form {
  padding: 20px 30px;
}
</style>
