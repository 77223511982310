<template>
  <div class="demofile-contain demo" >
    <div class="preview-top flex-between" v-if="!isunit">
      <div class="top-left">
        <ProductEdittype :type="producteditType"/>
      </div>
      <div class="top-right flex align-items-center">
        <div class="preview-top-button flex flex-between">
          <div v-if="!ispre && !isPreview" type="primary" v-loading="confirmLoading"
            element-loading-background="rgba(255,255,255,0.3)" @click="tosaving">保存</div>
          <div v-if="isPreview" type="primary" @click="fullScreenButton">全屏显示</div>
          <div v-if="!ispre && !isPreview" icon="el-icon-s-promotion" type="primary" v-loading="publishconfirmLoading"
            element-loading-background="rgba(255,255,255,0.3)">
            <img class="release" src="@/assets/images/common/release.png" alt="">
            <span>发布</span>
          </div>
        </div>
        <div class="top-right-name flex align-items-center">
          <div class="top-right-cover">
            <img style="width:28px;height:20px;" :src="coverList[0] && coverList[0].coverUrl" alt="" />
            <el-upload accept=".jpg,.jpeg,.png" action="" ref="upload" :auto-upload="false" :multiple="false"
              :on-change="onChange" :show-file-list="false">
              <div class="cover-tip">更换</div>
            </el-upload>
          </div>
          <div class="one-line" v-if="subject">
            <ProductName :name.sync="subject.subjectName" />
          </div>
        </div>
      </div>
    </div>
    <div class="mydemo flex" :class="{'h-100':isunit}" ref="mydemo" v-if="subject">
      <div class="mydemo-left">
        <FileTable :subject="subject" @fileclick="handleFilerowClick" :readonly="readonly" :crumblist="crumblist" :loginrequired="loginrequired"/>
      </div>
      <div class="mydemo-right" v-if="!showAttribute && !isPreview && !readonly">
        <ColudDiskTable />
      </div>
      <div class="mydemo-right" v-if="showAttribute && !isPreview && attributevisible">
        <FileAttribute :netdisk="'enterpriseNetdisk'" :visible.sync="showAttribute" :current="clickrow"
          :module-id="queryIds.moduleId"></FileAttribute>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import API from "@/utils/apiRequest"
import { ProductLibrary } from "@/api/ProductLibrary";
import { File } from "@/api/File";
import screenfull from "screenfull";
import FileTable from './components/FileTable.vue';
import FileAttribute from './components/FileAttribute.vue';
import ColudDiskTable from '@/components/filecompanies/ColudDiskTable.vue';
import ProductName from '@/components/ProductName.vue';
import ProductEdittype from '@/components/ProductEdittype.vue';
export default {
  components: { FileTable, ColudDiskTable, FileAttribute, ProductName, ProductEdittype },
  props:{
    isunit:{
      type:Boolean,
      default:false//是否作为组件
    },
    subjectdata:Object,
    readonly:{
      type:Boolean,
      default:false//只读
    },
    attributevisible:{
      type:Boolean,
      default:true//显示右侧属性
    },
    crumblist: Array,//传入的面包屑
    loginrequired:{
      type:Boolean,
      default:true//是否需要登录
    },
  },
  data() {
    return {
      subject: null,
      moduleId: "",
      queryIds: {
        moduleId: "",
        subjectId: "",
        versionId: "",
      },
      isPreview: false,
      ispre: false,
      publishconfirmLoading: false,
      confirmLoading: false,
      producteditType: 'file',//编辑模式
      coverList: [],
      showinput: false,
      requestPayload: null,
      showAttribute: false,
      clickrow: null,//点击的文件/文件夹
    };
  },
  computed: {
    ...mapGetters(["moduleTypeDict", "hasNopowerList"]),
  },
  mounted() {
    this.queryIds.subjectId = this.$route.query.subjectId;
    this.queryIds.versionId = this.$route.query.versionId;
    // this.getSubjectDetail()
    if(this.isunit){
      this.subject = _.cloneDeep(this.subjectdata);
      this.queryIds.moduleId = this.subject.moduleId;
    }else{
      this.getSubjectDetail();
    }
    // 关闭资源
    if (!this.isPreview) {
      this.$Pubsub.publish("update-resource-select-data", []);
      this.$Pubsub.publish("update-resource-visible", { visible: false, type: [] });
    }
  },
  watch: {},
  methods: {
    handlePreview() {
      this.isPreview = !this.isPreview
    },
    /**
     * @description: 全屏显示
     * @return {void}
     */
    fullScreenButton() {
      let elementById = document.getElementById("fullscreen_preview");
      screenfull.toggle(elementById);
      this.isfullscreen = true;
    },
    init() {
      if (screenfull.isEnabled) {
        // 判断是否支持全屏
        screenfull.on("change", this.change);
      }
    },
    // 保存
    async tosaving() {
      if (!this.subject.subjectName.trim()) {
        this.$message.error('名称不能为空');
        return
      }
      //-- 保存主题--
      // 草稿/下架状态直接覆盖当前版本
      this.requestPayload = _.pick(this.subject, ["dataAuths", "subjectColumnVos", "demoLinks", "downloadAuth", "editType", "id", "mirrorId", "moduleId", "remark", "coverRemark", "shareAuth", "shelfFlag", "subjectFiles", "subjectName", "tags", "templateId", "tenantId", "versionId"]);
      this.requestPayload.editType = 1;
      this.requestPayload.coverVO = { coverList: [], delCoverId: [] };
      if (this.subject.shelfFlag !== 1 || ispublish) {
        // 草稿/下架状态直接覆盖当前版本
        this.requestPayload.editType = 1;
        let readyList = [];
        this.coverList.forEach(element => {
          element.type === "delete" && this.requestPayload.coverVO.delCoverId.push(element.id); // 删除的封面
          if (element.type === "ready") {
            let formData = new FormData();
            formData.append("photo", element.file.raw);
            readyList.push(API.Post("/sales-show-4.0/subject/file/uploadCoverPhoto", formData));
          }
        })
        if (readyList.length) {
          const resList = await Promise.all(readyList).catch(() => {
            // this.loading = false;
          });
          resList.forEach(respnose => {
            if (respnose.data.code === 200) {
              this.requestPayload.coverVO.coverList.push(respnose.data.result)
            }
          })
        }
        this.requestPayload.coverVO.delCoverId = readyList.length && this.subject.coverList[0] && this.subject.coverList[0].id ? [this.subject.coverList[0].id] : []
        const res = await ProductLibrary.editSubject(this.requestPayload);
      }
      // 如果是上架状态就重新上架
      if (this.subject.shelfFlag == 1) {
        await ProductLibrary.updateShelfFlag({ subjectId: this.requestPayload.id, ShelfFlag: 1 });
      }
    },
    handleBlur() {
      if (!this.subject.subjectName.trim()) {
        this.$message.error('名称不能为空');
        return
      }
      this.showinput = false
    },
    /**
     * @description: 返回上一页
     * @return {void}
     */
    goback() {
      this.$router.back();
    },
    /**
     * @description: 获取产品详情
     * @return {void}
     */
    async getSubjectDetail() {
      const res = await ProductLibrary.getSubjectDetail(this.$route.query);
      this.subject = res;
      this.queryIds.moduleId = res.moduleId;
      this.coverList = res.coverList;
      if (this.coverList.length) {
        this.coverList.forEach(element => element.type = "success");
      } else {
        const index = this.common.getLastChar(res.id);
        const objectURL = await this.common.convertImageToBase64(require(`@/assets/images/sample-${index}.png`));
        this.coverList.push({
          coverType: 1,
          coverUrl: objectURL,
          id: Date.now(),
          file: { raw: this.common.dataURLtoFile(objectURL, "默认封面.png") },
          type: "ready",
        });
      }
    },
    /**
     * @description: 文件状态改变时的钩子，添加文件、上传成功和上传失败时都会被调用
     * @param {Object} file 文件
     * @return {void}
     */
    async onChange(file) {
      if (file.raw.type.indexOf("image") !== -1) {
        if (file.size > 2 * 1024 * 1024) {
          this.$message.error("封面图片大小不能超过 2MB!");
          this.$refs.upload.handleRemove(file);
          return
        }
      }
      let cover = {
        coverType: null,
        coverUrl: URL.createObjectURL(file.raw),
        id: file.uid,
        file: file,
        type: "ready",
      }
      if (file.raw.type.indexOf("image") !== -1) {
        cover.coverType = 1;
        this.coverList = [cover];
      }
    },
    handleFilerowClick(val,isfloderopen) {
      if(isfloderopen){
        this.showAttribute = false;
        return
      }
      this.clickrow = val
      this.showAttribute = true;
    }
  },
};
</script>
<style lang="scss" scoped>
@import "~@/scss/demo.scss";

.demofile-contain {
  height: 100%;
  width: 100%;
  background-color: #ebeef2;
}

.mydemo {
  // margin-top: 20px;
  height: calc(100% - 40px);
  width: 100%;
  overflow-x: auto;

  .mydemo-left {
    flex: 1;
    min-width: 950px;
    height: 100%;
    background-color: #fff;
    margin-right: 14px;
  }

  .mydemo-right {
    width: 480px;
    height: 100%;
  }
}
.h-100{
  height: 100%;
}
</style>