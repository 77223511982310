<template>
  <el-dialog
    append-to-body
    class="ss-dialog"
    width="900px"
    :visible.sync="dialogVisible"
    title="导入文章"
    top="10vh"
  >
    <div class="ass-content">
      <div class="basic-info w-h-100 groupmultiple">
        <div class="basic-form">
          <div class="secondtext">基础信息</div>
          <el-form ref="form" :model="queryData" label-width="180px" :rules="rules">
            <el-form-item label="文章名称" prop="articleName">
              <el-input
                v-model="queryData.articleName"
                maxlength="30"
                show-word-limit
                style="width: 400px"
              ></el-input>
            </el-form-item>
            <el-form-item label="所属模块">
              <el-select
                style="width: 400px"
                v-model="ArticlemoduleId"
                placeholder="请选择模块"
              >
                <el-option
                  v-for="item in assmenuList"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="所属分类">
              <el-select
                style="width: 400px"
                multiple
                v-model="category"
                placeholder="请选择分类"
              >
                <el-option-group
                  v-for="group in categoryList"
                  :key="group.id"
                  :label="group.categoryName"
                >
                  <el-option
                    v-for="item in group.children"
                    :key="item.id"
                    :label="item.categoryName"
                    :value="item.id"
                  >
                  </el-option>
                </el-option-group>
              </el-select>
            </el-form-item>
            <el-form-item label="内容简介">
              <el-input
                style="width: 400px"
                v-model="queryData.introduce"
                maxlength="300"
                show-word-limit
                type="textarea"
              ></el-input>
            </el-form-item>
            <el-form-item label="缩略图">
              <div class="cover">
                <!-- <img src="@/assets/images/sample-1.png" alt="" /> -->
                <el-upload
                  accept=".jpg,.jpeg,.png"
                  action=""
                  ref="upload"
                  :auto-upload="false"
                  :multiple="false"
                  :on-change="onChange"
                  :show-file-list="false"
                >
                  <img v-if="showList[0]" :src="showList[0].coverUrl" alt="" />
                </el-upload>
              </div>
            </el-form-item>
            <el-form-item label="模板选择">
              <el-select v-model="queryData.templateType" placeholder="请选择分类">
                <el-option label="一" :value="1"></el-option>
                <el-option label="二" :value="2"></el-option>
              </el-select>
            </el-form-item>
          </el-form>
        </div>
        <div class="basic-text">
          <div class="secondtext">详细信息</div>
          <TinyMce
            :readonly="isPreview"
            :value.sync="queryData.detailContent"
            :module-id="queryData.moduleId"
            :subject-id="subjectId"
            :datatype="1"
          />
        </div>
      </div>
    </div>
    <span slot="footer">
      <el-button type="info" @click="dialogVisible = false">取消</el-button>
      <el-button type="primary" :loading="confirmLoading" @click="handleSave"
        >导入</el-button
      >
    </span>
  </el-dialog>
</template>

<script>
import TinyMce from "../../../components/TinyMce.vue";
import { mapGetters } from "vuex";
import { ProductLibrary } from "../../../api/ProductLibrary";
import { Article } from "../../../api/Article";
import API from "@/utils/apiRequest";
export default {
  components: { TinyMce },
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    current: Object,
    isPreview: {
      type: Boolean,
      default: false,
    },
    text: String,
  },
  data() {
    return {
      activeName: "first",
      rules: {
        articleName: {
          required: true,
          message: "请输入名称",
          trigger: ["blur", "change"],
        },
      },
      queryData: {
        articleName: "",
        coverRemark: "",
        templateType: 1, //模板(1.左右栏，2.通栏)
        detailContent: "",
        moduleId: "",
        introduce: "",
        coverVO: {
          coverList: [],
          delCoverId: [],
        },
        delFlag: 0,
      },
      showList: [], //显示的图片列表
      fileList: [],
      category: [], //选择的分类
      subjectId: "",
      categoryList: [],
      checkList: [],
      assmenuList: [], //文章模块列表
      ArticlemoduleId: "", //选择的文章模块id
      confirmLoading: false,
    };
  },
  computed: {
    ...mapGetters(["token", "menuList", "Moduleclassification", "userInfo"]),
    dialogVisible: {
      get() {
        return this.visible;
      },
      set(val) {
        this.$emit("update:visible", val);
      },
    },
  },
  mounted() {
    this.queryData.detailContent = this.text;
    this.getModuleList();
    this.getCover();
  },
  methods: {
    /**
     * @description: 获取模块列表
     * @return {void}
     */
    async getModuleList() {
      this.assmenuList = [];
      let selectType = this.Moduleclassification.filter((item) => item.type == 5);
      selectType = selectType.map((item) => item.id);
      this.menuList.forEach((item) => {
        const type = this.Moduleclassification.filter(
          (menu) => menu.id == item.categoryId
        );
        type.length && (item.categoryType = type[0].type);
      });
      this.assmenuList = this.menuList.filter((item) =>
        selectType.includes(item.categoryId)
      );
      this.ArticlemoduleId = this.assmenuList[0].id;
      this.queryData.moduleId = this.ArticlemoduleId;
      this.getCategoryList();
    },
    /**
     * @description: 文件状态改变时的钩子，添加文件、上传成功和上传失败时都会被调用
     * @param {Object} file 文件
     * @return {void}
     */
    async onChange(file) {
      if (file.raw.type.indexOf("image") !== -1) {
        if (file.size > 2 * 1024 * 1024) {
          this.$message.error("封面图片大小不能超过 2MB!");
          this.$refs.upload.handleRemove(file);
          return;
        }
      }
      let cover = {
        coverType: null,
        coverUrl: URL.createObjectURL(file.raw),
        id: file.uid,
        file: file,
        type: "ready",
      };
      if (file.raw.type.indexOf("image") !== -1) {
        cover.coverType = 1;
        this.fileList.push(cover);
        this.showList = [cover];
      }
    },
    /**
     * @description:获取默认封面
     * @return {void}
     */
    async getCover() {
      const index = 1;
      const regex = /^http/;
      if (
        this.detail &&
        this.detail.article.coverList.length &&
        regex.test(this.detail.article.coverList[0].coverUrl)
      ) {
        this.fileList = _.cloneDeep(this.detail.article.coverList);
        this.fileList.forEach((element) => (element.type = "success"));
      } else {
        const objectURL = await this.common.convertImageToBase64(
          require(`@/assets/images/sample-${index}.png`)
        );
        this.fileList.push({
          coverType: 1,
          coverUrl: objectURL,
          id: Date.now(),
          file: { raw: this.common.dataURLtoFile(objectURL, "默认封面.png") },
          type: "ready",
        });
      }
      this.showList = this.fileList;
    },
    /**
     * @description: 获取分类
     * @return {void}
     */
    async getCategoryList() {
      const res = await ProductLibrary.getSubjectCategoryList({
        moduleId: this.queryData.moduleId,
        tenantId: this.userInfo.tenantIdNow,
      });
      let list = [];
      const props = [
        "moduleId",
        "parentId",
        "id",
        "categoryName",
        "privateFlag",
        "sortNo",
        "collectionCount",
        "subjectCount",
      ];
      const listSortBy = _.sortBy(res, "sortNo"); // 按 sortNo 排序
      listSortBy.forEach((element) => {
        let primary = _.pick(element, props);
        primary.children = [];
        if (element.children && element.children.length) {
          const inListSortBy = _.sortBy(element.children, "sortNo"); // 按 sortNo 排序
          inListSortBy.forEach((item) => {
            let secondary = _.pick(item, props);
            primary.children.push(secondary);
          });
        }
        list.push(primary);
      });
      this.categoryList = _.cloneDeep(list);
    },
    /**
     * @description:保存
     * @return {void}
     */
    async handleSave() {
      if (!this.queryData.articleName.trim()) {
        return this.$message.error("名称不能为空");
      }
      this.confirmLoading = true;
      let coverVO = [];
      let readyList = [];
      this.showList.forEach((item) => {
        if (item.type == "ready") {
          let formData = new FormData();
          formData.append("photo", item.file.raw);
          readyList.push(
            API.Post("/sales-show-4.0/subject/file/uploadCoverPhoto", formData)
          );
        }
      });
      if (readyList.length) {
        const resList = await Promise.all(readyList).catch(() => {});
        resList.forEach((respnose) => {
          if (respnose.data.code === 200) {
            coverVO.push(respnose.data.result);
          }
        });
      }
      this.queryData.editType = 1;
      this.queryData.coverVO = {
        coverList: coverVO,
        delCoverId:
          readyList.length &&
          this.detail &&
          this.detail.article.coverList.length &&
          this.detail.article.coverList[0].id
            ? this.detail.article.coverList.map((item) => item.id)
            : [],
      };
      if (!this.detail) {
        const res = await Article.addArticle(this.queryData);
        if (res) {
          let payload = {
            categoryIds: this.category,
            id: res,
          };
          await Article.saveArticleCategory(payload);
          this.$emit("finish", res);
          this.confirmLoading = false;
          this.dialogVisible = false;
        }
      }
      this.confirmLoading = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.ass-content {
  padding: 0 30px;
  max-height: 60vh;
  overflow-y: auto;

  .el-button--text {
    padding: 0;
  }
  .basic-info {
    overflow: auto;
    .cover {
      height: 41px;
      width: 60px;
      border-radius: 5px;
      margin-left: 10px;
      img {
        width: 60px;
        height: 40px;
      }
    }
    .basic-text {
      height: 600px;
      width: 100%;
    }
    .basic-btn {
      width: 100%;
      margin-top: 40px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
  .secondtext {
    color: #ccc;
  }
}
</style>
