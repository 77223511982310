<template>
  <div class="form-cell-4-number">
    <ElInputNumber
      :class="{ highlight }"
      v-model="cValue"
      :size="FORM_ITEM_SIZE"
      class="form-cell-4-number__input"
      :controls="false"
    ></ElInputNumber>
  </div>
</template>
<script lang="ts">
import Vue from "vue";
import { mixin } from "./mixin";

import { InputNumber as ElInputNumber } from "element-ui";

export default Vue.extend({
  inheritAttrs: false,
  name: "FormCell4Number",
  components: { ElInputNumber },
  mixins: [mixin],
});
</script>
<style lang="scss" scoped>
@import "src/flexible-table-module/scss/common.scss";
.form-cell-4-number {
  @include form-cell-highlight;

  &__input {
    width: 100%;
  }
}
</style>