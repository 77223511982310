<template>
  <!-- 溢出显示下拉框tabs -->
  <div class="category-top" ref="categorytabs">
    <div class="category-primary__wrapper" :class="{ 'is-overflow': sliceIndex > -1 }">
      <div v-if="defaultTabs" class="category-primary"
        :class="{ 'is-active': tableTab && tableTab.id == defaultTabs.id }" @click="selectDefault">
        {{ defaultTabs.homeName }}
      </div>
      <div v-for="i in filterCategoryList" class="category-primary"
        :class="{ 'is-active': primaryCategory && tableTab.id === i.id }" :key="i.id" @click="selectPrimaryCategory(i)">
        {{ i.homeName }}
        <el-popover v-if="Viewstatus==3" popper-class="toolbar-item-popover popover" placement="right-start" width="60"
          trigger="click" :ref="'toolbar-' + i.id" :visible-arrow="false">
          <div class="toolbar-more" slot="reference" @click.stop></div>
          <div class="popover-item" @click="handleUpdate(i)">编辑</div>
          <div class="popover-divider"></div>
          <div class="popover-item" @click="handleDelete(i)">删除</div>
        </el-popover>
      </div>
    </div>
    <el-popover v-if="sliceIndex > -1" popper-class="category-popover" placement="bottom" width="96" trigger="click"
      :visible-arrow="false">
      <div class="category-arrow-down arrow-down" slot="reference"></div>
      <div v-for="(i, index) in filterCategoryCloseList" class="category-popover-item" :key="i.id"
        @click="selectRemainCategory(i, index)">
        {{ i.homeName }}
      </div>
    </el-popover>
    <div v-if="Viewstatus == 3" class="category-setting el-icon-plus" @click="handleUpdate(null)"></div>
    <TabsSelector v-if="categoryDialogVisible" :visible.sync="categoryDialogVisible" @finishcreat="handleFinish"
      :updatetabs="updatetabs" />
  </div>
</template>
<script>
import TabsSelector from "../dialog/TabsSelector.vue";
import { Homecontents } from "../../../api/Homecontents";
import { mapGetters } from "vuex";
export default {
  components: { TabsSelector },
  data() {
    return {
      categoryList: [], // 分类列表
      defaultTabs: null, // 默认的那个通用的tab
      primaryCategory: null, //当前选择tab
      secondaryCategory: null,
      resizeObserver: null,
      sliceIndex: -2,
      wrapperwidth: 200, // 容器宽度
      checkdefault: false, //是否选择默认的那个通用
      categoryDialogVisible: false, //创建弹窗
      updatetabs: null, //需要编辑的tab
      newTab:null,//新的页签id
    };
  },
  computed: {
    ...mapGetters(["tableTab","Viewstatus"]),
    filterCategoryList() {
      if (this.sliceIndex === -2) {
        return [];
      } else {
        if (this.sliceIndex === -1) {
          return this.categoryList;
        } else {
          return this.categoryList.slice(0, this.sliceIndex);
        }
      }
    },
    // 这里是让收起来的popover里面过滤掉当前以显示的，如果不需要过滤，就把上面的filterCategoryCloseList换成categoryList，把下面的selectRemainCategory换成注释掉的那个
    filterCategoryCloseList() {
      return this.categoryList.filter(
        (item1) => !this.filterCategoryList.some((item2) => item1.id === item2.id)
      );
    },
  },
  mounted() {
    this.getCategoryList();
    this.resizeObserver = new ResizeObserver(this.calculateItemsPerRow);
    this.resizeObserver.observe(this.$refs.categorytabs);
  },
  methods: {
    /**
     * @description: 计算放置元素个数
     * @return {void}
     */
    calculateItemsPerRow() {
      if (!this.$refs.categorytabs) {
        return;
      }
      // const containerWidth = this.$refs.categorytabs.offsetWidth; // 获取容器宽度
      this.wrapperwidth = this.$refs.categorytabs.offsetWidth - 100; // 获取容器宽度
      this.sliceIndex = this.getSliceIndex(this.categoryList);
    },
    /**
     * @description: 获取主题文件类栏目文件分类列表
     * @return {void}
     */
    async getCategoryList() {
      const res = await Homecontents.getHomeContentsOneList();
      this.defaultTabs = res.find((item) => item.createType == 1);
      this.categoryList = res.filter((item) => item.createType != 1);
      if(this.newTab){
        const slecttab = this.categoryList.find(item=>item.id==this.newTab);
        this.selectRemainCategory(slecttab)
      }else{
        this.selectDefault(); //选择默认通用tab
      }
      this.$nextTick(()=>{ this.newTab = null; })
      if (this.categoryList.length) {
        // this.primaryCategory = this.categoryList[0];//选择列表第一个tab
        // this.selectDefault(); //选择默认通用tab
        this.sliceIndex = this.getSliceIndex(this.categoryList);
      }
    },
    /**
     * @description: 选择一级分类
     * @param {Object} value 点击项
     * @return {void}
     */
    selectPrimaryCategory(value) {
      this.checkdefault = false;
      if (this.primaryCategory && this.primaryCategory.id === value.id) {
        return;
      }
      this.primaryCategory = value;
      this.$store.commit("changetableTab", value);
    },
    /**
     * @description: 选择默认通用
     * @return {void}
     */
    selectDefault() {
      this.primaryCategory = null;
      this.checkdefault = true;
      this.$store.commit("changetableTab", this.defaultTabs);
    },
    /**
     * @description: 选择展开的一级分类
     * @param {Object} value
     * @return {void}
     */
    selectRemainCategory(value, index) {
      this.primaryCategory = value;
      this.$store.commit("changetableTab", value);
      const list = this.categoryList.filter((item) => item.id !== value.id);
      list.unshift(value);
      this.categoryList = list;
      this.$nextTick(() => {
        this.sliceIndex = this.getSliceIndex(this.categoryList);
      });
    },
    // selectRemainCategory(value, index) {
    //   this.primaryCategory = value;
    //   if (this.sliceIndex <= index) {
    //     const list = this.categoryList.filter((item) => item.id !== value.id);
    //     list.unshift(value);
    //     this.categoryList = list;
    //     this.$nextTick(() => {
    //       this.sliceIndex = this.getSliceIndex(this.categoryList);
    //     });
    //   }
    // },
    /**
     * @description: 获取分类收起时显示的数组长度
     * @param {Array} array 一级分类数组
     * @return {Number} 数组长度
     */
    getSliceIndex(array) {
      let sum = 0;
      for (let index = 0; index < array.length; index++) {
        const element = array[index];
        const width = (this.getElementWidth(element.homeName) * 100 + 1600) / 100; // 16 = 左右 padding 3 + margin-right 10
        // 238: 一级分类的容器宽度 212: 显示展开 icon 时一级分类的容器宽度
        if (sum + width > this.wrapperwidth) {
          return sum > 212 ? index - 1 : index;
        }
        sum += width;
      }
      return -1;
    },
    /**
     * @description: 获取文本元素宽度
     * @param {String} text 文本
     * @param {String} id 文本id
     * @return {Number} 文本元素宽度
     */
    getElementWidth(text, id = "virtualSpan") {
      let el = document.createElement("span");
      el.setAttribute("id", id);
      el.innerHTML = text;
      el.style.setProperty("font-size", "14px");
      el.style.setProperty("font-weight", 400);
      el.style.setProperty("height", 0);
      el.style.setProperty("padding", 0);
      el.style.setProperty("border", "none");
      el.style.setProperty("margin", 0);
      document.body.appendChild(el);
      let el2 = document.getElementById(id);
      const width =
        Math.ceil(parseInt(el2.getBoundingClientRect().width * 1000) / 10) / 100;
      document.body.removeChild(el);
      return width;
    },
    /**
     * @description: 新建完成
     * @param {Boolean} hasnewTab 是否新建的
     * @param {String} id 页签id
     * @return {void}
     */
    handleFinish(hasnewTab, id) {
      this.newTab = hasnewTab? id : null;
      this.getCategoryList();
    },
    /**
     * @description: 删除
     * @return {void}
     */
    handleDelete(value) {
      this.$showMessage(
        {
          message: `确定删除 <span style="font-weight: 1000;">${value.homeName}</span> 吗?<br>此次操作会将所选数据永久删除，请谨慎操作！`,
          ...this.$global.deleteMessage,
        },
        async (done) => {
          const res = await Homecontents.deleteHomeContents(value.id);
          if (res) {
            this.$message.success("删除成功");
            this.getCategoryList();
          }
          done(true);
        }
      );
    },
    /**
     * @description: 编辑/新建页签
     * @return {void}
     */
    handleUpdate(value) {
      this.updatetabs = value;
      this.categoryDialogVisible = true;
    },
  },
};
</script>
<style lang="scss">
.category-top {
  // border-bottom: 1px solid #e6ebf2;
  margin: 0 14px;
  position: relative;
  display: flex;
  align-items: center;

  .category-primary__wrapper {
    box-sizing: border-box;
    height: 47px;
    padding-right: 16px;
    display: flex;

    &.is-overflow {
      padding-right: 42px;
    }

    .category-primary {
      width: fit-content;
      height: 45px;
      line-height: 45px;
      padding: 0 3px;
      border-bottom: 2px solid #fff;
      margin-right: 15px;
      font-size: 14px;
      font-weight: 400;
      color: #344563;
      white-space: nowrap;
      cursor: pointer;
      position: relative;

      &.is-active {
        border-bottom-color: #1664ff;
      }
      .toolbar-more {
        display: none;
        position: absolute;
        top: 16px;
        right: -10px;
        width: 12px;
        height: 12px;
        background-image: url("@/assets/images/common/more_vertical.png");
        background-size: 12px 12px;
        background-repeat: no-repeat;
      }
      &:hover {
        .toolbar-more {
          display: block;
        }
      }
    }
  }

  .category-arrow-down {
    //position: absolute;
    // right: 100px;
    // top: 15px;
  }
}
.arrow-down {
  width: 16px;
  height: 16px;
  background-image: url("@/assets/images/productLibrary/arrow-right.png");
  background-size: 16px 16px;
  background-repeat: no-repeat;
  cursor: pointer;

  &:hover {
    background-image: url("@/assets/images/productLibrary/arrow-right__hover.png");
  }
}
</style>
<style lang="scss">
.category-popover {
  @include popover(96px);
  width: fit-content !important;
}

.category-popover-item {
  @include popover-item;
  box-sizing: border-box;
  text-align: left;
  padding: 0 12px;
  font-size: 14px;
  cursor: pointer;
}
</style>
