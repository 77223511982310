<template>
  <div class="text-editor w-h-100 app-scheme-wrapper flex">
    <div class="app-scheme-left" :class="{ 'content-preview-aside': isfull }">
      <div class="scheme-title-list">
        <div
          v-for="child in tableData"
          :key="child.id"
          class="list-items flex align-items-center"
          :class="{
            'active-child': clickitem && clickitem.id == child.id,
          }"
          @click="getDetail(child)"
        >
          <img
            class="img-icon"
            :src="
              child.columnIcon && child.columnIcon.startsWith('http')
                ? child.columnIcon
                : require('@/assets/images/sample-5.png')
            "
            alt=""
          />
          <div :title="child.columnName" class="one-line">
            {{ child.columnName }}
          </div>
        </div>
      </div>
    </div>
    <div class="content-select-preview" :class="{ 'content-full-preview': isfull }">
      <div class="w-h-100" v-if="!isfull" :style="{ width: `${isfull ? 1080 : 700}px` }">
        <!-- <TinyMce :readonly="true" :value="richText" /> -->
        <iframe id="myiframe" src="" frameborder="0" height="100%" width="100%"></iframe>
      </div>
      <SplitPaneCenter
        v-if="isfull"
        :initialrightWidth="isfull ? 1080 : 700"
        :dividerPosition="2"
        :minPaneWidth="180"
      >
        <template v-slot:right>
          <div class="w-h-100">
            <!-- <TinyMce :readonly="true" :value="richText" /> -->
            <iframe
              id="myiframe"
              src=""
              frameborder="0"
              height="100%"
              width="100%"
            ></iframe>
          </div>
        </template>
      </SplitPaneCenter>
    </div>
  </div>
</template>

<script>
import { ProductLibrary } from "@/api/ProductLibrary";
import TinyMce from "@/components/TinyMce.vue";
import SplitPaneCenter from "@/components/SplitPaneCenter.vue";
export default {
  name: "RichTextEditor",
  components: { TinyMce, SplitPaneCenter },
  props: {
    activeColumn: Object,
    subject: Object,
    isfull: {
      type: Boolean,
      default: false,
    }, //是否为全屏
    loginrequired: {
      //是否需要登录查看
      type: Boolean,
      default: true,
    },
    shareId: String, //分享id
  },
  data() {
    return {
      // richText1: "", //富文本
      queryData: {
        schemeId: "",
        pageNo: 1,
        pageSize: 999,
        subjectVersionId: "",
        subjectColumnId: "",
        subjectVersionId: "",
      },
      tableData: [],
      creatDialogVisible: false,
      clickitem: null, //点击项
      edititem: null, //编辑项
      Categoryedititem: null, //编辑项
      iseditText: false,
      creatCategoryDialogVisible: false,
      columnTypeId: "", //分类id
      selectids: [], //方案选择的数据id合集
      selectTemData: [], //方案选择的数据合集
      richText: "",
    };
  },
  watch: {
    activeColumn(val) {
      this.queryData.subjectColumnId = this.activeColumn.id;
      this.queryData.subjectVersionId = this.subject.versionId;
      this.queryData.schemeId = this.activeColumn.schemeId;
      this.getTableData();
    },
    isfull() {
      this.$nextTick(() => {
        let iframe = document.getElementById("myiframe");
        iframe.src = "about:blank";
        setTimeout(() => {
          iframe && iframe.contentDocument.write(this.richText);
        }, 100);
      });
    },
  },
  computed: {
    isPreview() {
      return this.ispre;
    },
  },
  mounted() {
    if (this.activeColumn) {
      this.queryData.subjectColumnId = this.activeColumn.id;
      this.queryData.subjectVersionId = this.subject.versionId;
      this.queryData.schemeId = this.activeColumn.schemeId;
      this.getTableData();
    }
  },
  methods: {
    /**
     * @description:获取子栏目列表
     * @return {void}
     */
    async getTableData(pageNo) {
      if (!this.loginrequired) {
        this.queryData.shareId = this.shareId;
      }
      const res = await ProductLibrary[
        this.loginrequired
          ? "getSchemeSubjectColumnChildPageList"
          : "getSchemeSubjectColumnChildPageListNoLogin"
      ](this.queryData);
      if (res.records.length) {
        this.tableData = this.uniqueObjects(res.records);
        this.getDetail(this.tableData[0]);
      } else {
        this.tableData = [];
      }
    },
    uniqueObjects(arr) {
      const seen = new Map();
      return arr.filter((obj) => {
        // 将对象的所有属性作为唯一性的标识
        const key = Object.keys(obj)
          .map((key) => obj[key])
          .join("|");
        return seen.has(key) ? false : seen.set(key, true);
      });
    },
    /**
     * @description: 预览介绍
     * @param {Object} value 介绍
     * @return {void}
     */
    async getDetail(value) {
      this.clickitem = value;
      let res;
      if (this.loginrequired) {
        res = await ProductLibrary.getSettingSubjectColumnContent(value.id);
      } else {
        res = await ProductLibrary.getSettingSubjectColumnContentNoLogin({
          shareId: this.shareId,
          subjectColumnId: value.id,
        });
      }

      this.richText = res[0].content;
      this.$nextTick(() => {
        let iframe = document.getElementById("myiframe");
        iframe.src = "about:blank";
        setTimeout(() => {
          iframe && iframe.contentDocument.write(this.richText);
        }, 100);
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@/scss/common.scss";

.text-editor {
  background-color: #fff;
}

.is-edit {
  padding: 0;
}

.content-select-preview {
  flex: 1;
  // background-color: #cccccc;
  padding: 10px;
  overflow-y: auto;
  display: flex;
  justify-content: center;
}
.content-full-preview {
  background-color: #cccccc;
  padding: 0;
  .w-h-100 {
    background-color: #fff;
  }
}
.content-preview-aside {
  position: absolute;
  top: 0;
  left: 0;
  width: 8px !important;
  opacity: 0;
  z-index: 10;
  overflow: hidden;
  transition: width 0.3s ease;

  &:hover {
    position: relative;
    opacity: 1;
    width: 180px !important;
  }
}

.list-items {
  padding: 8px;
  cursor: pointer;
  border-radius: 5px;
  .img-icon {
    width: 26px;
    height: 20px;
    flex-shrink: 0;
    margin-right: 10px;
  }
}

.active-child {
  background-color: #f1ebff;

  &:hover {
    background-color: #f1ebff;
  }
}
</style>
