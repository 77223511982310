import { ApiDataOverview } from "../api/DataOverview";
import { DataOverview } from "../entity/DataOverview";
import { DataOverviewItem } from "../entity/DataOverview/DataOverviewItem";

export class BizDataOverview {

    private apiDataOverview: ApiDataOverview;

    constructor(baseURL?: string) {
        this.apiDataOverview = new ApiDataOverview(baseURL);
    }

    public async getOverviewDetails(
        moduleName: string,
        tabName: string,
    ): Promise<DataOverview> {
        const result = await this.apiDataOverview.getOverviewDetails(moduleName, tabName) as any;

        const allItems = result.allItems ? result.allItems.map((it: any) => new DataOverviewItem(it)) : undefined;
        const activeItems = result.activeItems ? result.activeItems.map((it: any) => new DataOverviewItem(it)) : undefined;
        return new DataOverview({ defaultFold: result.defaultFold, allItems, activeItems });
    }

    public async updateSettings(
        moduleName: string,
        tabName: string,
        params: {
            defaultFold: boolean,
            allItems: DataOverviewItem[],
        }
    ): Promise<{
        defaultFold: boolean,
        allItems: DataOverviewItem[],
    }> {
        const result = await this.apiDataOverview.updateOverviewSettings(moduleName, tabName, params) as any;
        return result;
    }
}