<template>
  <OverflowTooltip :content="value">
    <div class="body-normal-cell">{{ value }}</div>
  </OverflowTooltip>
</template>
<script lang="ts">
import Vue from "vue";
import OverflowTooltip from "@/flexible-table-module/components/Common/OverflowTooltip.vue";

export default Vue.extend({
  inheritAttrs: false,
  name: "BodyNormalCell",
  components: { OverflowTooltip },
  props: {
    value: { type: String, default: "" },
  },
});
</script>