import { ApiTable } from "../api/Table";
import PageResult from "../entity/PageResultTs";
import { OptBtnsPositionType, Table } from "../entity/Table";
import { BaseColumn } from "../entity/Table/BaseColumn";
import { ButtonColumn } from "../entity/Table/ButtonColumn";
import { Column } from "../entity/Table/Column";
import { ColumnFilter, DateTimeFilter, KeyStrValueFilter, NumberFilter, StringFilter } from "../entity/Table/ColumnFilter";
import { TColumnType } from "../entity/Table/ColumnTypes";
import { IBaseTableData } from "../entity/Table/IBaseTableData";
import { IndexColumn } from "../entity/Table/IndexColumn";
import { KeyStrValuePair, KeyValuePair } from "../entity/KeyValuePair";
import { Sort } from "../entity/Table/Sort";
import { ITreeNode } from "../entity/TreeNode";
import { DataStatistic } from "../entity/Table/DataStatistic";
import { IconColumn } from "../entity/Table/IconColumn";
import { PercentColumn } from "../entity/Table/PercentColumn";
import { ParamsForGetTableRows } from "../entity/DTO/ParamsForGetTableRows";
import { ParamsForGetTableFormat } from "../entity/DTO/ParamsForGetTableFormat";
import { ParamsForGetColumnFilter } from "../entity/DTO/ParamsForGetColumnFilter";
import { ParamsForCreateOrUpdateRowCell } from "../entity/DTO/ParamsForCreateOrUpdateRowCell";

export class BizTable {

    private apiTable: ApiTable;

    constructor(baseURL?: string) {
        this.apiTable = new ApiTable(baseURL);
    }

    public async getTableFormat(
        moduleName: string,
        tabName: string,
        params?: ParamsForGetTableFormat
    ): Promise<Table<IBaseTableData>> {

        let _params: ParamsForGetTableFormat;
        if (params) _params = params;
        else _params = { filterMode: "none" };

        const result = await this.apiTable.getTableFormat(moduleName, tabName, _params) as any;

        let optBtnsTarget: BaseColumn | undefined = undefined;
        if (result.optBtnsPosition !== "last-col" && result.optBtnsTarget) {
            optBtnsTarget = new BaseColumn(result.optBtnsTarget);
        }

        const table = new Table({
            columns: [],
            fixedColIds: result.fixedColIds,
            mergeableColIds: result.mergeableColIds,
            treeArrowColIds: result.treeArrowColIds,
            optBtnsPosition: result.optBtnsPosition,
            optBtnsTarget: optBtnsTarget,
        });

        for (const col of result.columns) {
            let column: BaseColumn | undefined = undefined;
            switch (col.columnType) {
                case "normal": {
                    const filter = this.rawColumnFilterConverter(col.filter);
                    column = new Column({ ...col, filter });
                    break;
                }
                case "button": {
                    column = new ButtonColumn(col);
                    break;
                }
                case "index": {
                    column = new IndexColumn(col);
                    break;
                }
                case "icon": {
                    column = new IconColumn(col);
                    break;
                }
                case "percent": {
                    column = new PercentColumn(col);
                    break;
                }
            }
            if (column) table.columns.push(column);
        }

        return table;
    }

    public async getTableRows(
        moduleName: string,
        tabName: string,
        params: ParamsForGetTableRows
    ): Promise<PageResult<IBaseTableData>> {
        const { columns } = params;
        const result = await this.apiTable.getTableRows(moduleName, tabName, params) as any;
        const _rows = this.rawRowsToTableDataConverter(result.records, columns, 0);
        return new PageResult(_rows, result.total);
    }

    public async getTableSubRows(
        moduleName: string,
        tabName: string,
        rowData: { id: string, $level: number, $parentId: string },
        params: {
            // filters: Filter[],
            columns: BaseColumn[],
            sorts?: Sort[],
            filterMode: "single" | "multiple" | "view" | "none", // 分别表示单列条件、多列条件、视图、无过滤条件
            filterListItems?: ITreeNode[][],
        }
    ): Promise<IBaseTableData[]> {
        const { columns } = params;
        const result = await this.apiTable.getTableSubRows(moduleName, tabName, rowData.id, params) as any;
        const _rows = this.rawRowsToTableDataConverter(result, columns, rowData.$level + 1, rowData.$parentId);
        return _rows;
    }

    public async getCellSelectList(
        moduleName: string,
        tabName: string,
        params: {
            rowId?: string, // 后端未提供 id 或者新增行时，rowId 为空
            columnId: string,
        }
    ): Promise<KeyStrValuePair[]> {
        const result = await this.apiTable.getCellSelectList(moduleName, tabName, params) as any;
        return result.map(({ key, value }: { key: string, value: string }) => { return { key, value } });
    }

    public async updateCellSelect(
        moduleName: string,
        tabName: string,
        params: {
            rowId: string,
            columnId: string,
            newKeyValue: KeyValuePair<string>
        }
    ) {
        const result = await this.apiTable.updateCellSelect(moduleName, tabName, params) as any;
        return result;
    }

    public async updateColumnOrder(
        moduleName: string,
        tabName: string,
        columnIds: string[]
    ) {
        const result = await this.apiTable.updateColumnOrder(moduleName, tabName, columnIds);
        return result;
    }

    public async updateOperationBtnsPosition(
        moduleName: string,
        tabName: string,
        params: {
            optBtnsPosition: OptBtnsPositionType,
            optBtnsTarget?: BaseColumn,
        }
    ) {
        const result = await this.apiTable.updateOperationBtnsPosition(moduleName, tabName, params);
        return result;
    }

    public async updateColumnActive(
        moduleName: string,
        tabName: string,
        columns: BaseColumn[],
    ) {
        const result = await this.apiTable.updateColumnActive(moduleName, tabName, columns);
        return result;
    }

    public async updateColumnCellAlign(
        moduleName: string,
        tabName: string,
        columns: BaseColumn[],
    ) {
        const result = await this.apiTable.updateColumnCellAlign(moduleName, tabName, columns);
        return result;
    }

    public async getDataStatistics(
        moduleName: string,
        tabName: string,
        params: ParamsForGetTableRows
    ): Promise<DataStatistic[]> {
        const result = await this.apiTable.getDataStatistics(moduleName, tabName, params) as any;
        return result.map((ds: any) => new DataStatistic(ds));
    }

    public async updateDataStatisticsActive(
        moduleName: string,
        tabName: string,
        dataStatistics: DataStatistic[],
    ) {
        const result = await this.apiTable.updateDataStatisticsActive(moduleName, tabName, dataStatistics);
        return result;
    }

    public async getDownloadDataUrl(
        moduleName: string,
        tabName: string,
        params: ParamsForGetTableRows
    ) {
        const result = await this.apiTable.getDownloadDataUrl(moduleName, tabName, params) as any;
        return result;
    }

    public async getColumnFilterByColumn(
        moduleName: string,
        tabName: string,
        params: ParamsForGetColumnFilter,
    ): Promise<ColumnFilter | undefined> {
        const result = await this.apiTable.getColumnFilterByColumn(moduleName, tabName, params) as any;
        const filter = this.rawColumnFilterConverter(result);
        return filter;
    }

    public async createOrUpdateRowCells(
        moduleName: string,
        tabName: string,
        rowId: string | null | undefined,
        params: ParamsForCreateOrUpdateRowCell[],
        columns: BaseColumn[], // 全部列，前端内部使用，用于转换数据
    ): Promise<IBaseTableData> {
        const result = await this.apiTable.createOrUpdateRowCells(moduleName, tabName, rowId, params) as any;

        if (rowId) {
            return result as IBaseTableData;
        } else {
            const row = this.rawRowsToTableDataConverter([result], columns, 0)[0];
            console.log('row :>> ', row);
            return row;
        }
    }

    public rawRowsToTableDataConverter(
        rows: any[],
        columns: BaseColumn[],
        level: number = 0, // 针对树形数据，表示第几层
        $parentId?: string,
    ): IBaseTableData[] {
        // 列格式数据集里有指定的列，才放到 idTypeMap 中
        const idTypeMap = new Map<string, TColumnType | 'icon' | 'button' | 'percent'>();
        for (const col of columns) {
            if (col instanceof Column) {
                idTypeMap.set(col.id, col.type);
                if (col.innerColumn) {
                    idTypeMap.set(col.innerColumn.id, col.innerColumn.type);
                } else if (col.iconColumn) {
                    idTypeMap.set(col.iconColumn.id, 'icon');
                } else if (col.buttonColumns && col.buttonColumns.length > 0) {
                    col.buttonColumns.forEach(bc => idTypeMap.set(bc.id, 'button'));
                }
            } else if (col instanceof IconColumn) {
                idTypeMap.set(col.id, 'icon');
            } else if (col instanceof ButtonColumn) {
                idTypeMap.set(col.id, 'button');
            } else if (col instanceof PercentColumn) {
                idTypeMap.set(col.id, 'percent');
            }
        }

        const _rows: any[] = [];

        for (let i = 0; i < rows.length; i++) {
            const row = rows[i];

            // let $id = `${level}-${i}-${getRamdomStr('****')}`;
            let $id = `${level}-${i}`;
            if ($parentId)
                $id = `${$parentId}-${$id}`;

            const _row: IBaseTableData = {
                hasChildren: !!row.hasChildren,
                $level: level,
                $id,
                $expanded: false
            };

            if (row.id) _row.id = row.id;
            if (_row.hasChildren) _row.children = [];
            if (row.selected) _row.selected = row.selected;

            if (row.attachment) _row.attachment = row.attachment;
            else _row.attachment = {};

            for (const key in row) {
                if (idTypeMap.has(key)) {
                    switch (idTypeMap.get(key)) {
                        case "string":
                        case "number": {
                            _row[key] = row[key];
                            break;
                        }
                        case "time":
                        case "date":
                        case "datetime":
                        case "short-datetime": {
                            _row[key] = this.isNotEmpty(row[key]) ? new Date(row[key]) : row[key];
                            break;
                        }
                        case "time-frame":
                        case "date-frame":
                        case "datetime-frame": {
                            _row[key] = [
                                this.isNotEmpty(row[key][0]) ? new Date(row[key][0]) : row[key][0],
                                this.isNotEmpty(row[key][1]) ? new Date(row[key][1]) : row[key][1],
                            ];
                            break;
                        }
                        case "key-str-value": {
                            _row[key] = new KeyStrValuePair(row[key].key, row[key].value);
                            break;
                        }
                        case "rich-text": {
                            _row[key] = row[key];
                            break;
                        }
                        default:
                            _row[key] = row[key];
                            break;
                    }
                }
            }

            _rows.push(_row);
        }

        return _rows;
    }

    private rawColumnFilterConverter(columnFilter?: any): ColumnFilter | undefined {
        let filter: ColumnFilter | undefined = undefined;

        if (columnFilter) {
            const id = columnFilter.id ?? undefined;
            const type = columnFilter.type;
            const target = columnFilter.target ?? undefined;

            switch (type) {
                case 'string-search': {
                    filter = new StringFilter({ id, type, target });
                    break;
                }
                case 'string-select': {
                    filter = new StringFilter({ id, type, target, selectList: columnFilter.selectList });
                    break;
                }
                case 'date-time-frame':
                case 'date-time-single': {
                    let _target: Date | [Date, Date] | undefined;
                    if (target) {
                        if (Array.isArray(target)) {
                            _target = [new Date(target[0]), new Date(target[1])];
                        } else {
                            _target = new Date(target);
                        }
                    }

                    filter = new DateTimeFilter({ id, type, target: _target });
                    break;
                }
                case 'number': {
                    const { min, max } = columnFilter;

                    let _target: [number | null, number | null] | undefined;
                    if (target) {
                        _target = [target[0] ?? null, target[1] ?? null];
                    }

                    filter = new NumberFilter({ id, min: min ?? undefined, max: max ?? undefined, target: _target });
                    break;
                }
                case 'key-str-value-search': {
                    filter = new KeyStrValueFilter({ id, type, target });
                    break;
                }
                case 'key-str-value-select': {
                    filter = new KeyStrValueFilter({ id, type, target, selectList: columnFilter.selectList });
                    break;
                }
            }
        }

        return filter;
    }

    private isNotEmpty(val: any) {
        return val !== undefined && val !== null;
    }
}