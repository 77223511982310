import { Filter, MultiFilter } from "./Filter";
import { ITreeNode } from "./TreeNode";

export type FilterType = Filter | MultiFilter | "view";
export type SelectionType = { filter: FilterType, twoDTreeNodes: ITreeNode[][] };

export class HistorySelections {
    public selections: SelectionType[] = [];

    public setTwoDTreeNodes(filter: FilterType, twoDTreeNodes: ITreeNode[][]) {
        // 如果有相同的filter，就覆盖，否则就push
        let index;
        if (filter === "view") {
            index = this.selections.findIndex(selection => selection.filter === "view");
        } else {
            index = this.selections.findIndex(selection =>
                (selection.filter instanceof Filter || selection.filter instanceof MultiFilter) &&
                selection.filter.id === filter.id
            );
        }

        if (index !== -1) {
            if (twoDTreeNodes.length === 0) {
                this.selections.splice(index, 1); // 删除
            } else {
                this.selections[index].twoDTreeNodes = twoDTreeNodes; // 覆盖
            }
        } else {
            if (twoDTreeNodes.length === 0) return;
            else this.selections.push({ filter, twoDTreeNodes }); // 添加
        }
    }

    public getTwoDTreeNodes(filter: FilterType): ITreeNode[][] | undefined {
        let selection;
        if (filter === "view") {
            selection = this.selections.find(selection => selection.filter === "view");
        } else {
            selection = this.selections.find(selection =>
                (selection.filter instanceof Filter || selection.filter instanceof MultiFilter) &&
                selection.filter.id === filter.id
            );
        }

        return selection ? selection.twoDTreeNodes : undefined;
    }

    // 删除 filter 对应的一组历史筛选项中的由 oneDTreeNodesIndex 指定下标的一个历史筛选项
    public delSelectionItem(
        filter: FilterType,
        oneDTreeNodesIndex: number,
        // twoDTreeNodes: ITreeNode[][],
    ): ITreeNode[] {
        const index = this.selections.findIndex(selection => selection.filter === filter);
        if (index === -1) return [];
        const twoDTreeNodes = this.selections[index].twoDTreeNodes;

        const [oneDTreeNodes] = twoDTreeNodes.splice(oneDTreeNodesIndex, 1);
        oneDTreeNodes[0].isChecked = false;

        if (twoDTreeNodes.length === 0) {
            const index = this.selections.findIndex(selection => selection.filter === filter);
            this.selections.splice(index, 1);
        }

        return oneDTreeNodes;
    }

    // 根据 filter 删除一组历史筛选项
    public delSelection(filter: FilterType): ITreeNode[][] {
        const index = this.selections.findIndex(selection => selection.filter === filter);
        if (index === -1) return [];

        const twoDTreeNodes = this.selections[index].twoDTreeNodes;
        this.selections.splice(index, 1);

        return twoDTreeNodes;
    }

    // 清空所有的历史筛选项
    public clear(): ITreeNode[][] {
        const tatalTwoDTreeNodes = [];

        for (const selection of this.selections) {
            for (const oneDTreeNodes of selection.twoDTreeNodes) {
                oneDTreeNodes[0].isChecked = false;
                tatalTwoDTreeNodes.push(oneDTreeNodes);
            }
        }

        this.selections = [];

        return tatalTwoDTreeNodes;
    }
}