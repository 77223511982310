<template>
  <div class="main-view" v-loading="!viewFilter">
    <div class="header">
      <div class="title-edit">
        <ElInput
          v-model="title"
          size="small"
          placeholder="请输入内容"
          @focus="onTitleFocus"
          @blur="onTitleBlur"
        ></ElInput>
      </div>
    </div>
    <div class="btns" v-if="history.hasHistory || history.hasFuture">
      <LastAndNextStepBtns
        :hasHistory="history.hasHistory"
        :hasFuture="history.hasFuture"
        @last-step-click="onLastStepClick"
        @next-step-click="onNextStepClick"
      />
    </div>
    <div
      class="body"
      :class="{ 'under-step-btns': history.hasHistory || history.hasFuture }"
      @dragover="onDragOver"
      @drop="onDrop"
    >
      <MainViewTrees
        :filters="viewFilter.filters"
        @tree-checkbox-change="onTreeCheckboxChange"
      />
    </div>
  </div>
</template>
<script lang="ts">
import Vue, { type DirectiveOptions, PropType } from "vue";
import { Input as ElInput } from "element-ui";

import { Loading } from "@/flexible-table-module/components/Common/Loading";

import { ViewFilter } from "@/flexible-table-module/entity/Filter";
import MainViewTrees from "./MainViewTrees.vue";
import LastAndNextStepBtns from "./LastAndNextStepBtns.vue";
import { debounce } from "@/flexible-table-module/util/InputOptimize";
import History from "@/flexible-table-module/components/Common/History/History";
import HistoryStep from "@/flexible-table-module/components/Common/History/HistoryStep";

interface IProps {
  title?: string;
  children?: string;
  isChecked?: string;
  isHalfChecked?: string;
}

export default Vue.extend({
  inheritAttrs: false,
  name: "MainView",
  components: { ElInput, MainViewTrees, LastAndNextStepBtns },
  directives: { loading: Loading.directive },
  props: {
    viewFilter: { type: Object as PropType<ViewFilter>, required: true },
  },
  data(): {
    onTitleEdited: (newTitle: string, oldTitle: string) => void;
    isTitleEditing: boolean;
    history: History;
    lastCommittedTitle: string | undefined;
  } {
    return {
      onTitleEdited: () => {},
      isTitleEditing: false, // 标题是否正处于编辑中
      history: new History(),
      lastCommittedTitle: undefined,
    };
  },
  computed: {
    title: {
      get(): string {
        if (this.viewFilter) return this.viewFilter.title;
        else return "";
      },
      set(v: string) {
        if (this.viewFilter) this.viewFilter.title = v;
      },
    },
  },
  watch: {
    title: {
      immediate: true,
      handler(newVal, oldVal) {
        if (this.isTitleEditing) {
          this.onTitleEdited(newVal, oldVal);
        }
      },
    },

    viewFilter: {
      immediate: true,
      handler(newVal: ViewFilter, oldVal) {
        // if (this.lastCommittedTitle === undefined) {
        this.lastCommittedTitle = newVal.title; // 初始化
        this.history.clear();
        // }
      },
    },
  },
  mounted() {
    this.onTitleEdited = debounce((newTitle: string, oldTitle: string) => {
      const title = this.lastCommittedTitle + "";

      const titleHisStep = new HistoryStep({
        onUndo: () => {
          this.title = title;
          this.$emit("edit-title-view-filter", this.viewFilter);
        },
        onRedo: () => {
          this.title = newTitle;
          this.$emit("edit-title-view-filter", this.viewFilter);
        },
      });
      this.history.add(titleHisStep);

      this.lastCommittedTitle = this.viewFilter.title;
      this.$emit("edit-title-view-filter", this.viewFilter);
    }, 500);
  },
  methods: {
    onDrop(ev: DragEvent) {},
    onDragOver(ev: DragEvent) {},

    onTitleFocus() {
      this.isTitleEditing = true;
    },
    onTitleBlur() {
      this.isTitleEditing = false;
    },

    onTreeCheckboxChange(node: any, isChecked: boolean) {
      const checkboxHisStep = new HistoryStep({
        onUndo: () => {
          node["isChecked"] = !isChecked;
          this.$emit("edit-title-view-filter", this.viewFilter, node);
        },
        onRedo: () => {
          node["isChecked"] = isChecked;
          this.$emit("edit-title-view-filter", this.viewFilter, node);
        },
      });
      this.history.add(checkboxHisStep);

      this.$emit("edit-filter-view-filter", this.viewFilter, node);
    },

    onLastStepClick() {
      this.history.undo();
    },

    onNextStepClick() {
      this.history.redo();
    },
  },
});
</script>
<style lang="scss" scoped>
// @import "src/flexible-table-module/scss/_variables.scss";

.main-view {
  width: 100%;
  height: 100%;

  @import "./MainCommon.scss";

  // .body {
  //   box-sizing: border-box;
  //   // padding: 0 8px 8px;
  // }
}
</style>