<template>
  <div>
    <div class="custom-body">
      <div class="flex-between permission-header">
        <div>权限控制</div>
        <el-button type="text" @click="selectMembersVisible = true" icon="el-icon-plus"
          >添加</el-button
        >
      </div>
      <div class="permission-wrapper">
        <div class="permission-wrapper__item" v-for="i in 4" :key="i">
          <div>
            <i class="el-icon-user-solid fs-18"></i>
            <span>权限组1</span>
            <span style="padding-left: 10px">成员：XXX，XXX2</span>
          </div>
          <div class="flex permission-wrapper__item-type">
            <el-popover placement="top" trigger="click">
              <div class="permission-wrapper__item-tips pointer" slot="reference">
                {{ permissionList[i - 1].label }}：
                {{ permissionList[i - 1].tips }}
              </div>
              <ul class="ul-permission">
                <li v-for="per in permissionList" class="li-permission" :key="per.key">
                  <div class="flex">
                    <svg-icon
                      v-if="permissionList[i - 1].tips==per.tips"
                      icon="check-clip"
                      color="#5a34b3"
                      width="13"
                      height="10"
                    ></svg-icon>
                    <span :class="{ 'label-active': true }">{{ per.label }}</span>
                  </div>
                  <div class="description">{{ per.tips }}</div>
                </li>
                <el-divider></el-divider>
                <li class="li-permission delete" @click="handleDelete(per)">删除</li>
              </ul>
            </el-popover>
          </div>
        </div>
      </div>
    </div>
    <UpdateFilePermission
      v-if="selectMembersVisible"
      :visible.sync="selectMembersVisible"
      @finish="finish"
      @getselectman="getSelectuser"
      :userlist="userData"
    />
  </div>
</template>

<script>
import UpdateFilePermission from "./UpdateFilePermission.vue";
import { FILE_PERMISSIONS } from "@/const.js";
export default {
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    UpdateFilePermission,
  },
  data() {
    return {
      selectMembersVisible: false,
      permissionList: FILE_PERMISSIONS,
      userData: [
        {
          id: "ff80808188dd574e0188f68d08a401f4",
          nickName: "陈",
          isChecked: false,
        },
      ],
    };
  },
  computed: {
    dialogVisible: {
      get() {
        return this.visible;
      },
      set(val) {
        this.$emit("update:visible", val);
      },
    },
  },
  methods: {
    finish() {},
    /**
     * @description: 添加成员
     * @param value 选择的成员列表
     * @return {void}
     */
    async getSelectuser(value) {
      let list = [];
      value.forEach((element) => {
        list.push(element.id);
      });
      this.userData = value;
    },
    /**
     * @description: 删除权限组
     * @param value 选择的组
     * @return {void}
     */
    handleDelete(value) {
      this.$showMessage(
        {
          message: `是否确认删除该权限组 <span style="font-weight: 1000;"></span> ?`,
          ...this.$global.deleteMessage,
          title: "确定取消上传？",
          confirmButtonText: "确认取消",
        },
        async (done) => {
          done(true);
        }
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.custom-body {
  // padding: 20px 30px;
  color: var(--color-text-primary);
  .permission-item {
    width: fit-content;
    height: 30px;
    line-height: 30px;
    position: relative;
  }

  .permission-count {
    font-size: 12px;
    color: var(--color-text-secondary);
    margin: 10px 0;
  }

  .mt-10 {
    margin-top: 10px;
  }
  .permission-header {
    padding: 0 10px;
    height: 40px;
    line-height: 40px;
    .permission-header-add {
      margin-left: 16px;
    }
  }
  .permission-wrapper {
    // height: 320px;
    overflow-y: auto;
    .permission-wrapper__item {
      padding: 10px 0;
    }
    .permission-wrapper__item-type {
      padding-right: 20px;
      display: flex;
      justify-content: right;
      position: relative;
      color: var(--color-text-secondary);
    }
    .permission-wrapper__item-tips {
      color: var(--color-text-secondary);
      &::before {
        content: "";
        position: absolute;
        right: 7px;
        top: 8px;
        width: 6px;
        height: 5px;
        background-image: url("@/assets/images/common/triangle_down.png");
        background-size: 6px 5px;
        background-repeat: no-repeat;
        pointer-events: none;
        transition: all 0.3s;
      }
    }
  }
}
.ul-permission {
  list-style: none;
  margin: 0;

  .li-permission {
    list-style-type: none;
    padding: 10px;
    border-radius: 5px;
    cursor: pointer;

    &:hover {
      background-color: #e6efff;
    }

    &.delete {
      &:hover {
        color: var(--color-danger-hover);
      }
    }

    .description {
      font-size: 12px;
      color: var(--color-text-secondary);
    }
  }

  .label-active {
    color: var(--color-primary);
  }

  .el-divider {
    margin: 4px 0;
  }
}
.fs-18 {
  font-size: 18px;
}
</style>
