import axios from "axios";
import store from '../store';
import common from "../utils/common"

const baseAxios = axios.create({
  baseURL: process.env.VUE_APP_URL_NETDISK,
});
baseAxios.interceptors.request.use(
  request => {
    // request.headers.portSource = "web";
    request.headers['X-Access-Token'] = store.state.local.token ? store.state.local.token : "";
    request.headers['Tenant-Id'] = store.state.local.userInfo ? store.state.local.userInfo.tenantIdNow : "";
    request.headers['Sys-Access-Token'] = "JqjdLEtaFOas5whS8hro2w==";
    return request;
  },
  error => {
    return Promise.reject(error);
  }
);

// 对外接口-删除目标权限
async function deletePermission() {
  try {
    const response = await baseAxios.delete("/public-file-system/fs/permission/deletePermission", { params: { username } });
    return response.data.code === 200 || response.data.code === 0;
  } catch (error) {
    return false;
  }
}

// 对外接口-给目标授权
async function addPermission(data) {
  try {
    const response = await baseAxios.post("/public-file-system/fs/permission/addPermission", data);
    return response.data.code === 200 || response.data.code === 0 ? response.data.result : false;
  } catch (error) {
    return false;
  }
}

// 对外接口-根据目标id获取登录人权限码
async function queryPermissionByTargetId(data) {
  try {
    const response = await baseAxios.post("/public-file-system/fs/permission/queryPermissionByTargetId", data);
    return response.data.code === 200 || response.data.code === 0 ? response.data.result : false;
  } catch (error) {
    return false;
  }
}

// 对外接口-修改目标权限
async function updatePermission(data) {
  try {
    const response = await baseAxios.post("/public-file-system/fs/permission/updatePermission", data);
    return response.data.code === 200 || response.data.code === 0 ? response.data.result : false;
  } catch (error) {
    return false;
  }
}

// 文件-恢复版本(设为主版本)
async function resumeFileVersion(data) {
  try {
    const response = await baseAxios.post("/public-file-system/fs/file/resumeFileVersion", data);
    return response.data.code === 200 && response.data.success;
  } catch (error) {
    return false;
  }
}
// 文件-获取个人网盘目录
async function getMyNetDiskDirectory() {
  try {
    const response = await baseAxios.get("/public-file-system/fs/file/getMyNetDiskDirectory");
    return response.data.code === 200 && response.data.result ? response.data.result : new common.Pagination();
  } catch (error) {
    return new common.Pagination();
  }
}
// 文件-获取企业网盘目录
async function getTenantNetDiskDirectory() {
  try {
    const response = await baseAxios.get("/public-file-system/fs/file/getTenantNetDiskDirectory");
    return response.data.code === 200 || response.data.code === 0 ? response.data.result : [];
  } catch (error) {
    return [];
  }
}
// 文件-文件上传-传输记录
async function transferFileList(data) {
  try {
    const response = await baseAxios.get("/public-file-system/fs/transfer/transferFileList", { params: data });
    return response.data.code === 200 || response.data.code === 0 ? response.data.result : new common.Pagination();
  } catch (error) {
    return new common.Pagination();
  }
}
// 文件-文件上传-清除传输记录
async function deleteTransferRecord(id) {
  try {
    const response = await baseAxios.delete("/public-file-system/fs/transfer/deleteTransferRecord", { params: { id } });
    return response.data.code === 200 && response.data.success;
  } catch (error) {
    return false;
  }
}
// 文件-根据文件夹id获取上级文件夹集合
async function getParentFolderListById(folderId) {
  try {
    const response = await baseAxios.post("/public-file-system/fs/file/getParentFolderListById?folderId=" + folderId);
    return response.data.code === 200 || response.data.code === 0 ? response.data.result : [];
  } catch (error) {
    return [];
  }
}

// 文件-文件预览1(onlyoffice)
async function viewOrEditV1(data) {
  try {
    const response = await baseAxios.get("/public-file-system/fs/file/preview/viewOrEdit", { params: data });
    return response.data;
  } catch (error) {
    return false;
  }
}

// 文件-文件预览
async function viewOrEditV2(data) {
  try {
    const response = await baseAxios.get("/public-file-system/fs/file/viewOrEditV2", { params: data });
    return response.data;
  } catch (error) {
    return false;
  }
}

export default { deletePermission, addPermission, queryPermissionByTargetId, updatePermission, resumeFileVersion, getMyNetDiskDirectory, getTenantNetDiskDirectory, transferFileList, deleteTransferRecord, getParentFolderListById, viewOrEditV2, viewOrEditV1 }