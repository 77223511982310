var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"operation-btn-outside",style:({
    minWidth: `${
      this.fontSize * this.maxTitleCount +
      (this.hasIcon ? this.fontSize + this.iconMarginRight : 0) +
      this.btnPadding * 2
    }px`,
    fontSize: `${this.fontSize}px`,
  })},[_c('div',{staticClass:"operation-btn",style:({ padding: `0 ${this.btnPadding}px` }),on:{"click":_vm.onBtnClick}},[_c('div',{staticClass:"operation-btn-inside"},[(_vm.hasIcon)?[_c('span',{staticClass:"icon",style:({ marginRight: `${this.iconMarginRight}px` })},[_vm._t("icon")],2)]:_vm._e(),_c('span',{staticClass:"title"},[_vm._v(_vm._s(_vm.title))])],2)])])
}
var staticRenderFns = []

export { render, staticRenderFns }