<template>
  <div class="content-select w-h-100 flex">
    <div class="content-select-list">
      <div class="container">
        <div
          class="content-item"
          :class="{ 'active-item': clickitem.dataId === item.dataId }"
          v-for="item in tableData"
          :key="item.id"
          @click="getDetail(item)"
        >
          <div class="product-item-img">
            <img
              class="w-h-100"
              :src="
                item.toSubjectCoverVos[0]
                  ? item.toSubjectCoverVos[0].coverUrl
                  : require(`@/assets/images/sample-${common.getLastChar(
                      item.dataId
                    )}.png`)
              "
              alt=""
            />
          </div>
          <div class="product-item-name one-line pd-10">{{ item.toSubjectName }}</div>
          <div class="product-item-date one-line pd-10">
            最新更新：{{ Object.dayjs(item.updateAt || item.createAt) }}
          </div>
        </div>
      </div>
    </div>
    <!-- <div class="content-select-preview flex-1"></div> -->
  </div>
</template>

<script>
import { ProductLibrary } from "../../../../api/ProductLibrary";
export default {
  props: {
    activeColumn: Object,
    subject: Object,
    scheme: Object,
    ispre: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      selectids: [], //已经选择的id合集
      tableData: [],
      richText: "",
      clickitem: {},
    };
  },
  watch: {
    scheme() {
      this.getTableData();
    },
  },
  mounted() {
    this.scheme && this.getTableData();
  },
  methods: {
    /**
     * @description:获取列表
     * @return {void}
     */
    async getTableData() {
      const res = await ProductLibrary.getSchemeToContentList({
        schemeId: this.scheme.id,
        subjectColumnId: this.activeColumn.id,
      });
      this.tableData = res;
    },
    /**
     * @description: 预览介绍
     * @param {Object} value 介绍id
     * @return {void}
     */
    async getDetail(value) {
      this.clickitem = value;
      const payload = {
        subjectId: value.dataId,
        mirrorId: value.toMirrorId,
        versionId: value.toVersionId,
      };
      // 如果有演示模式就跳转到演示模式的预览，否则预览栏目
      const routeData = this.$router.resolve({
        path: "/column-preview",
        query: payload,
      });
      window.open(routeData.href);
    },
  },
};
</script>
<style lang="scss" scoped>
.content-select-list {
  // width: 434px;
  width: 100%;
  height: 100%;
  overflow-y: auto;
  padding: 14px;
  box-sizing: border-box;
  border-right: 1px solid #ccc;
  .container {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    overflow-y: auto;
    // padding: 0 16px;
    .content-item {
      margin-right: 14px;
      margin-top: 10px;
      width: 194px;
      height: 180px;
      border: 1px solid #cfd7e6;
      border-radius: 5px;
      .product-item-img {
        width: 100%;
        height: 113px;
      }
      .product-item-name {
        width: 100%;
        line-height: 34px;
        font-weight: 700;
        font-size: 14px;
        color: var(--color-text-primary);
      }
      .product-item-date {
        font-size: 12px;
        color: #8997b0;
        line-height: 22px;
      }
    }
    .active-item {
      border: 2px solid #8b6bff;
      position: relative;
    }
  }
}
.content-select-preview {
  background-color: #fff;
  padding: 10px;
  overflow-y: auto;
}
.pd-10 {
  padding: 0 10px;
  box-sizing: border-box;
}
</style>
