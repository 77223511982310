import request from "@/utils/appServer";

class Summary {
  //新增概要
  static async addCommentInfo(data) {
    try {
      const res = await request.post("/sales-show-4.0/summary/addCommentInfo", data);
      return res.data.code === 200 && res.data.success ? res.data.success : false;
    } catch (error) {
      return false;
    }
  }
  //新增回复
  static async addReplyInfo(data) {
    try {
      const res = await request.post("/sales-show-4.0/summary/addReplyInfo", data);
      return res.data.code === 200 && res.data.success ? res.data.success : false;
    } catch (error) {
      return false;
    }
  }
  //删除概要
  static async deleteCommentInfo(id) {
    try {
      const res = await request.get("/sales-show-4.0/summary/deleteCommentInfo", { params: { id } }, true);
      return res.data.code === 200 && res.data.success ? res.data.success : false;
    } catch (error) {
      return false;
    }
  }
  //删除回复
  static async deleteReplyInfo(id) {
    try {
      const res = await request.get("/sales-show-4.0/summary/deleteReplyInfo", { params: { id } }, true);
      return res.data.code === 200 && res.data.success ? res.data.success : false;
    } catch (error) {
      return false;
    }
  }
  //查询主题概要列表
  static async queryCommentInfoList(data) {
    try {
      const res = await request.get("/sales-show-4.0/summary/queryCommentInfoList", { params: data });
      return res.data.code === 200 && res.data.result ? res.data.result : [];
    } catch (error) {
      return [];
    }
  }
  //置顶
  static async topReplyInfo(data) {
    try {
      const res = await request.get("/sales-show-4.0/summary/topReplyInfo", { params: data }, true);
      return res.data.code === 200 && res.data.success ? res.data.success : false;
    } catch (error) {
      return false;
    }
  }
}

export {
  Summary
}