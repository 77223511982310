<template>
  <span class="side-icon" :style="iconSize">
    <Icon :size="size" :iconBaseUrl="iconUrl" />
  </span>
</template>
<script lang="ts">
import Vue from "vue";
import Icon from "@/flexible-table-module/components/Common/Icon.vue";

export default Vue.extend({
  inheritAttrs: false,
  name: "SideIcon",
  components: { Icon },
  props: {
    size: { type: Number },
    iconUrl: { type: String, required: true },
  },
  computed: {
    iconSize: function () {
      return `width: ${this.size}px; height: ${this.size}px`;
    },
  },
});
</script>
<style lang="scss" scoped>
.side-icon {
  display: inline-block;
  margin-right: 8px;
  flex-shrink: 0;
}
</style>