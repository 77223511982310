import Vue, { PropType } from 'vue';
import { FORM_ITEM_SIZE } from "./constant";
import { KeyStrValuePair } from '@/flexible-table-module/entity/KeyValuePair';

export const mixin = Vue.extend({
    inheritAttrs: false,
    props: {
        value: [String, Number, Date, Array, Object,] as PropType<string | number | Date | [Date, Date] | KeyStrValuePair>, // 值
        required: { type: Boolean, default: false }, // 是否必填
    },
    data() {
        return { FORM_ITEM_SIZE };
    },
    computed: {
        cValue: {
            get(): string | number | Date | [Date, Date] | KeyStrValuePair | undefined {
                return this.value;
            },
            set(v: string | number | Date | [Date, Date] | KeyStrValuePair) {
                this.$emit("input", v);
            },
        },
        // 是否高亮，以示必填(只有空格不算)
        highlight(): boolean {
            return (
                this.required &&
                (this.value === undefined ||
                    this.value === null ||
                    (typeof this.value === "string" && this.value.trim() === ""))
            );
        },
    }
});