<template>
  <el-dialog class="ss-dialog" title="分享配置" top="188px" width="840px" :close-on-click-modal="false"
    :visible.sync="dialogVisible" append-to-body>
    <div class="content">
      <div class="qr-code-wrapper">
        <div class="qr-code">
          <QrCode :id="'QrCode'" :text="codeUrl" />
        </div>
        <div class="qr-code-tip">APP或微信扫码直接浏览</div>
        <span class="expires">
          <span class="expires-label">有效期：</span>
          <el-popover v-show="!expiresCustomVisible" v-model="expiresVisible" popper-class="share-popover expires-popover"
            placement="bottom-end" width="103" trigger="click" :visible-arrow="false">
            <span class="expires-value" slot="reference">
              {{ expiresItem ? expiresItem.realName || expiresItem.name : "" }}
            </span>
            <div v-for="item in expiresList" class="share-popover-item" :class="{
              'is-active': expiresItem && expiresItem.id === item.id,
            }" :key="item.id" @click="selectExpires(item)">
              <span>{{ item.name }}</span>
            </div>
          </el-popover>
          <template v-if="expiresCustomVisible">
            <el-input v-model="expiresTime" class="expires-input" size="small"
              @input="expiresTime = expiresTime.replace(/[^0-9]/g, '')"></el-input>
            <el-popover v-model="expiresTypeVisible" popper-class="share-popover" placement="bottom-end" width="94"
              trigger="click" :visible-arrow="false">
              <div class="expires-type" slot="reference">
                <span class="expires-type-label">{{
                  expiresTypeItem ? expiresTypeItem.name : ""
                }}</span>
                <img class="expires-type-icon" src="@/assets/images/common/triangle_down.png" />
              </div>
              <div v-for="item in expiresTypeList" class="share-popover-item" :key="item.id"
                @click="selectExpiresType(item)">
                <div class="share-popover-item__label">{{ item.name }}</div>
                <img v-if="expiresTypeItem && expiresTypeItem.id === item.id" class="share-popover-item__icon"
                  src="@/assets/images/common/check_blue.png" />
              </div>
            </el-popover>
            <img class="expires-check" src="@/assets/images/common/check_blue.png" @click="setselftime" />
          </template>
        </span>
      </div>
      <div class="content-right">
        <!-- <div class="content-right-title">
          <div>分享内容</div>
        </div>
        <div v-for="(i, index) in shareinfo.shareDetails" :key="i.id">
          {{ `${index + 1}. ${i.fileName}` }}
        </div> -->
        <div class="content-right-title" style="padding-top:20px">浏览权限</div>
        <el-radio-group v-model="radio">
          <el-radio :label="1">需要登录系统</el-radio>
          <el-radio :label="2">无须安全验证</el-radio>
          <el-radio :label="3">
            指定访问码
            <el-input v-model="pwd" class="pwd-input" placeholder="请输入4位数密码" size="small" :maxlength="4"
              @input="pwd = pwd.replace(/[^0-9a-zA-Z]/g, '')" :disabled="radio != 3"></el-input>
            <span v-if="pwd.length < 4" style="color:var(--color-danger-hover)">仅可输入4位字母和数字</span>
            <!-- <img class="pwd-check" src="@/assets/images/common/check_blue.png" alt="" @click="confirmPwd" /> -->
          </el-radio>
        </el-radio-group>
      </div>
    </div>
    <div class="dialog-footer">
      <el-input v-model="codeUrl2" class="footer-input" placeholder="请输入内容" @click.native="inputClick"
        @focus="handleFocus" readonly>
        <template slot="append">复制链接</template>
      </el-input>
      <div class="footer-btn wechat" @click="seddToWechat">
        <img class="footer-btn-icon_wechat" src="@/assets/images/common/wechat.png" alt="" />
        <span class="footer-btn-label">发送到聊天</span>
        <img class="footer-btn-arrow" src="@/assets/images/common/arrow_right.png" alt="" />
      </div>
      <div class="footer-btn i3v" @click="seedToFriends">
        <img class="footer-btn-icon_i3v" src="@/assets/images/common/i3v.png" alt="" />
        <span class="footer-btn-label">指定好友</span>
        <img class="footer-btn-arrow" src="@/assets/images/common/arrow_right.png" alt="" />
      </div>
    </div>
    <!-- <SelectFriends v-if="friendsVisible" :visible.sync="friendsVisible" :shareinfo="sharedetail" :sharename="subjectName"
      @Done="finish" /> -->
  </el-dialog>
</template>

<script>
import QrCode from "@/components/QrCode.vue";
// import SelectFriends from "./SelectFriends.vue";
import { Share } from "@/api/Share";
export default {
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    subjectId: String,
    shareinfo: Object,
  },
  components: { QrCode },
  data() {
    return {
      codeUrl: "", //访问链接,二维码使用
      codeUrl2: "", //访问链接
      expiresVisible: false,
      expiresList: [
        { id: "1", name: "3天", time: 3, timeUnit: "h" },
        { id: "2", name: "7天", time: 7, timeUnit: "d" },
        { id: "3", name: "1个月", time: 1, timeUnit: "m" },
        { id: "4", name: "6个月", time: 6, timeUnit: "m" },
        { id: "5", name: "长期有效", time: 0, timeUnit: "y" },
        { id: "6", name: "自定义", realName: "自定义", timestamp: -1 },
      ],
      expiresItem: null,

      expiresCustomVisible: false,
      expiresTime: "",
      expiresTypeVisible: false,
      expiresTypeList: [
        { id: "1", name: "小时", timeUnit: "h" },
        { id: "2", name: "天", timeUnit: "d" },
        { id: "3", name: "月", timeUnit: "m" },
        { id: "4", name: "年", timeUnit: "y" },
      ],
      expiresTypeItem: null,
      checkboxGroup: [],
      contentList: [],
      checkboxAllGroup: ["1"],
      indeterminate: false,
      radio: 2,
      pwd: "WWWW",
      friendsVisible: false,
      bizType: 1, // 分享类型(1.默认分享，2、vip分享)
      sharedata: {
        // accessCode: "",
        // authWay: 2,
        // bizType: 2,
        // delFlag: 0,
        // expireTime: "",
        // id: "",
        // mirrorId: this.shareinfo.mirrorId,
        // shareDetails: [],
        // shareWay: 1,
        // status: 1,
        // subjectId: this.shareinfo.id,
        // timeUnit: "d",
        // validDays: 3,
        // versionId: this.shareinfo.versionId,
      },
      // 选择的栏目
      selectColums: [],
      subjectName: this.shareinfo.name,
      // 分享详情
      sharedetail: null,
    };
  },
  computed: {
    dialogVisible: {
      get() {
        return this.visible;
      },
      set(val) {
        this.$emit("update:visible", val);
      },
    },
  },
  watch: {
    /**
     * @description: 改变链接的访问方式
     * @return {void}
     */
    async radio(newval) {
      this.sharedata.authWay = newval;
      if (newval === 3) {
        this.sharedata.accessCode = this.pwd;
      }
      await Share.editShare(this.sharedata);
      const res2 = await Share.getInfoById(this.sharedata.id);
    },
    /**
     * @description: 改变分享配置，默认or VIP
     * @return {void}
     */
    async bizType(newval) {
      this.sharedata.bizType = newval;
      await Share.editShare(this.sharedata);
    },
    /**
     * @description: 自定义的时间数值
     * @return {void}
     */
    expiresTime(val) {
      this.sharedata.validDays = val;
    },

  },
  mounted() {
    this.sharedata = _.cloneDeep(this.shareinfo)
    this.pwd = this.randomStr();
    this.expiresItem = this.expiresList[0];
    this.expiresTypeItem = this.expiresTypeList[0];
    this.codeUrl = `${process.env.VUE_APP_URL_SHARE}getdetailbyshareid?shareId=${this.shareinfo.id}&scanFlag=true&fileview=true`;
    this.codeUrl2 = `${process.env.VUE_APP_URL_SHARE}getdetailbyshareid?shareId=${this.shareinfo.id}&fileview=true`
    // this.toShare();
  },
  methods: {
    /**
     * @description: 关闭分享弹窗
     * @return {void}
     */
    finish() {
      this.dialogVisible = false;
    },
    /**
     * @description: 选择时间
     * @return {void}
     */
    async selectExpires(value) {
      if (this.expiresItem && this.expiresItem.id === value.id) {
        if (value.id === "6") {
          this.expiresCustomVisible = true;
        } else {
          return;
        }
      }
      this.expiresItem = value;
      this.expiresVisible = false;
      if (value.id === "6") {
        this.expiresCustomVisible = true;
        this.sharedata.timeUnit = this.expiresTypeList[0].timeUnit;
        this.sharedata.validDays = this.expiresTime;
        return;
      }
      this.sharedata.validDays = this.expiresItem.time;
      this.sharedata.timeUnit = this.expiresItem.timeUnit;
      await Share.editShare(this.sharedata);
      const res2 = await Share.getInfoById(this.sharedata.id);
    },
    /**
     * @description: 自定义时间选择时间类型
     * @return {void}
     */
    selectExpiresType(value) {
      if (this.expiresTypeItem && this.expiresTypeItem.id === value.id) {
        return;
      }
      this.expiresTypeItem = value;
      this.expiresTypeVisible = false;
      this.sharedata.timeUnit = value.timeUnit;
    },
    randomNumber(max, min) {
      return Math.round(Math.random() * (max - min) + min);
    },
    randomStr() {
      //创建一个空字符，用于存放随机数/字母
      let strData = "";
      //生成随机字符库 (验证码四位，随机数三种，取公倍数12,所以循环4次。也可以是120次，1200次。)
      for (let i = 0; i < 4; i++) {
        let num = this.randomNumber(0, 9); //生成0-9的随机数
        let az = String.fromCharCode(this.randomNumber(97, 122)); //生成a-z
        let AZ = String.fromCharCode(this.randomNumber(65, 90)); //生成A-Z
        strData = strData + num + az + AZ; //将生成的字符进行字符串拼接
      }
      // 开始真正的随机(从随机字符库中随机取出四个)
      let str = "";
      for (let i = 0; i < 4; i++) {
        str += strData[this.randomNumber(0, strData.length - 1)];
      }
      return str;
    },
    confirmPwd() {
      // console.log(1);
    },
    // 自定义时间完成
    async setselftime() {
      if (!this.expiresTime) {
        this.$message.warning("时间不能为空");
        return;
      }
      this.expiresCustomVisible = false;
      await Share.editShare(this.sharedata);
      const res2 = await Share.getInfoById(this.sharedata.id);
    },
    /**
     * @description: 底部输入框点击事件，用于复制链接
     * @param {Event} event
     * @return {void}
     */
    async inputClick(event) {
      this.sharedetail = await Share.getInfoById(this.shareinfo.id);
      let list = []
      this.sharedetail.shareDetails.forEach(element => {
        list.push({
          fileCreateTime: element.fileCreateTime,
          fileId: element.subjectColumnId,
          fileName: element.fileName,
          fileType: element.fileType,
          fileUpdateTime: element.fileUpdateTime,
          fileUrl: element.fileUrl,
          size: element.size
        })
      });
      let data = {
        bizType: this.sharedetail.bizType,//1.产品，2、文件
        delFlag: this.sharedetail.delFlag,
        expireTime: this.sharedetail.expireTime,
        shareDetailSaveRequests: list,
        shareId: this.sharedetail.id,
        status: 1,
        subjectName: '文件',
        targetUsers: [
          {
            userId: "",
            userName: "",
          },
        ],
      };
      await Share.addShare(data);//添加到我的分享列表里面
      if (event.target.className === "el-input-group__append") {
        // navigator clipboard 需要https等安全上下文
        if (navigator.clipboard && window.isSecureContext) {
          return navigator.clipboard.writeText(this.codeUrl2).then(() => {
            this.$message.success("复制成功");
          });
        } else {
          // 创建text area
          let textArea = document.createElement("textarea");
          textArea.value = this.codeUrl2;
          // 使text area不在viewport，同时设置不可见
          textArea.style.position = "absolute";
          textArea.style.opacity = 0;
          textArea.style.left = "-999999px";
          textArea.style.top = "-999999px";
          document.body.appendChild(textArea);
          textArea.focus();
          textArea.select();
          return new Promise((res, rej) => {
            // 执行复制命令并移除文本框
            document.execCommand("copy") ? res() : rej();
            textArea.remove();
          }).then(() => {
            this.$message.success("复制成功");
          });
        }
      }
    },
    /**
     * @description: 发送到微信
     * @return {void}
     */
    seddToWechat() {
      // do something
    },
    /**
     * @description: 指定好友
     * @return {void}
     */
    async seedToFriends() {
      // this.sharedetail = await Share.getInfoById(this.sharedata.id);
      // this.friendsVisible = true;
    },
    /**
     * @description: 链接框聚焦时全选
     * @param {FocusEvent} event 聚焦事件
     * @return {void}
     */
    handleFocus(event) {
      event.currentTarget.select();
    },
  },
};
</script>

<style lang="scss">
.share-popover {
  @include popover(94px);

  .share-popover-item {
    @include popover-item;
    padding: 0 18px 0 6px;
    cursor: pointer;
    text-align: left;
    position: relative;

    &.is-active {
      &::before {
        content: "";
        position: absolute;
        top: 11px;
        right: 0;
        width: 13px;
        height: 10px;
        background-image: url("@/assets/images/common/check_blue.png");
        background-size: 13px 10px;
        background-repeat: no-repeat;
      }
    }

    .share-popover-item__icon {
      width: 13px;
      position: absolute;
      right: 8px;
      top: 11px;
    }
  }
}

.expires-popover {
  margin-top: 13px !important;
}
</style>

<style lang="scss" scoped>
.expires {
  font-size: 14px;
  font-weight: 500;
  // margin-left: 16px;
  display: flex;
  justify-content: center;

  .expires-label {
    line-height: 32px;
    color: var(--color-text-regular);
  }

  .expires-value {
    line-height: 32px;
    color: #ff5733;
    font-weight: 700;
    text-decoration: underline dashed;
    cursor: pointer;
  }
}

.expires-input {
  width: 72px;
  margin-left: 10px;
}

.expires-type {
  width: 75px;
  height: 32px;
  margin-left: 10px;
  border-radius: 5px;
  background: #fff;
  border: 1px solid #d3dae6;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  cursor: pointer;

  .expires-type-label {
    font-size: 14px;
    font-weight: 500;
    color: #344563;
  }

  .expires-type-icon {
    width: 6px;
    height: 5px;
    padding: 0 12px;
  }
}

.expires-check {
  width: 13px;
  height: 10px;
  margin-top: 10px;
  margin-left: 12px;
  cursor: pointer;
}

.configuration {
  box-sizing: border-box;
  min-width: 92px;
  height: 26px;
  padding: 0 6px;
  margin-left: 12px;
  border-radius: 5px;
  background: #fff;
  border: 1px solid #d3dae6;
  display: flex;
  align-items: center;
  cursor: pointer;

  .configuration-label {
    flex: 1;
    text-align: center;
    font-size: 14px;
    font-weight: 500;
    color: #344563;
  }

  .configuration-icon {
    width: 6px;
    height: 5px;
    padding-left: 12px;
  }
}

.content {
  margin: 30px 0 30px 44px;
  display: flex;

  .qr-code-wrapper {
    width: 295px;
    height: 309px;
    border-radius: 10px;
    background: #fff;
    box-shadow: 0px 0px 6px 6px rgba(199, 199, 199, 0.1);

    .qr-code {
      width: 221px;
      height: 242px;
      padding: 18px 37px 0;
    }

    .qr-code-tip {
      font-size: 12px;
      font-weight: 500;
      color: #5e6c84;
      text-align: center;
      padding: 8px 0;
    }
  }

  .content-right {
    margin-left: 43px;
    flex: 1;
    text-align: left;
    .content-right-title {
      font-size: 16px;
      font-weight: 700;
      color: #344563;
      margin-bottom: 19px;
      display: flex;
      align-items: center;
    }

    .share-checkbox {
      ::v-deep .el-checkbox {
        // width: 120px;
        margin-right: 18px;
        margin-bottom: 14px;
        margin-left: 0 !important;
      }
    }

    .pwd-input {
      width: 128px;
      color: #344563;
      margin: 16px;
    }

    .pwd-check {
      width: 13px;
      height: 10px;
      margin-left: 12px;
    }
  }
}

.dialog-footer {
  height: 90px;
  background-color: #f5f7fa;
  border-radius: 0 0 10px 10px;
  display: flex;
  align-items: center;
  justify-content: center;

  .footer-input {
    width: 390px;

    ::v-deep .el-input__inner {
      font-size: 14px;
      font-weight: 500;
      color: #c5d0e3;
    }

    ::v-deep .el-input-group__append {
      width: 92px;
      padding: 0;
      text-align: center;
      background-color: #fff;
      font-size: 14px;
      font-weight: 500;
      color: #344563;
      cursor: pointer;
    }
  }

  .footer-btn {
    width: 154px;
    height: 40px;
    border-radius: 5px;
    margin-left: 18px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    .footer-btn-icon_wechat {
      width: 30px;
      height: 30px;
    }

    .footer-btn-icon_i3v {
      width: 23px;
      height: 23px;
    }

    .footer-btn-label {
      font-size: 14px;
      font-weight: 500;
      color: #fff;
      padding: 0 2px 0 6px;
    }

    .footer-btn-arrow {
      width: 16px;
      height: 16px;
    }

    &.wechat {
      background-color: #00BAAD;
    }

    &.i3v {
      background-color: var(--color-primary);
    }
  }
}
</style>