<template>
  <div class="icon-btn-single" :style="iconBtnStyle" @click.stop="onClick">
    <div class="icon-core">
      <slot></slot>
    </div>
  </div>
</template>
<script lang="ts">
import Vue from "vue";

export default Vue.extend({
  name: "IconBtnSingle",
  props: {
    iconSize: { type: Number, default: 20 },
  },
  computed: {
    iconBtnStyle() {
      const size = this.iconSize;
      return {
        width: `${size}px`,
        height: `${size}px`,
      };
    },
  },
  methods: {
    onClick() {
      this.$emit("btn-click");
    },
  },
});
</script>

<style lang="scss" scoped>
.icon-btn-single {
  box-sizing: border-box;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  flex-shrink: 0;

  .icon-core {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
</style>