import { render, staticRenderFns } from "./ArticlePreview.vue?vue&type=template&id=2332a410&scoped=true"
import script from "./ArticlePreview.vue?vue&type=script&lang=js"
export * from "./ArticlePreview.vue?vue&type=script&lang=js"
import style0 from "./ArticlePreview.vue?vue&type=style&index=0&id=2332a410&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2332a410",
  null
  
)

export default component.exports