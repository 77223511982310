<template>
  <component
    :is="currIconComponent"
    class="jh-svg-icon"
    :class="`jh-${sys}-${name}`"
    :style="style"
  ></component>
</template>

<script lang="ts">
import Vue, { VueConstructor } from "vue";
import { compileToFunctions } from "vue-template-compiler";
import { IconsHelper } from "../../util/IconsHelper";

export default Vue.extend({
  name: "SvgIcon",
  props: {
    name: { type: String, required: true },
    sys: { type: String, default: "default" },
    size: { type: [String, Number], default: 16 },
    color: { type: String },
    rotation: { type: Number, default: 0 },
  },
  data(): {
    svgIcon: string;
    icons: { systemName: string; iconName: string; svgContent: string }[];
    currIconComponent: VueConstructor<Vue> | null;
  } {
    return {
      svgIcon: "",
      icons: [],
      currIconComponent: null,
    };
  },
  computed: {
    style(): {
      width: string;
      height: string;
      fill: string;
      transform: string;
    } {
      const _size =
        typeof this.size === "number" ? `${this.size}px` : this.size;
      return {
        width: `${_size}`,
        height: `${_size}`,
        fill: this.color,
        transform: `rotate(${this.rotation}deg)`,
      };
    },
  },
  watch: {
    name: {
      immediate: false,
      async handler() {
        await this.getIconHtml();
      },
    },
    sys: {
      immediate: false,
      async handler() {
        await this.getIconHtml();
      },
    },
  },

  async beforeMount() {
    await this.getIconHtml();
  },

  methods: {
    async getIconHtml() {
      const currIconHtml = await IconsHelper.getIconHtml(this.sys, this.name);
      // console.log("currIconHtml :>> ", currIconHtml);
      this.currIconComponent = Vue.component(
        "dynamic-icon",
        compileToFunctions(currIconHtml ?? "")
      );
    },
  },
});
</script>

<style lang="scss" scoped>
.jh-svg-icon {
  fill: currentColor;
}
</style>