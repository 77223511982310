<!--
 * @Author: ChileeHong
 * @Date: 2021-09-22 15:04:19
 * @LastEditTime: 2023-12-27 18:07:40
 * @LastEditors: HuiMinQi
 * @Description: 一个搜索文本框,开始显示图标，点击弹出文本输入框
-->

<template>
  <div
    class="text-searcher-animate"
    :style="{ width }"
    v-click-outside="onClickOutside"
  >
    <ElButton
      v-if="!isInputVisible"
      type="plain"
      :size="size"
      icon="el-icon-search"
      @click="onBtnClick"
    >
    </ElButton>
    <ElButton
      v-else
      type="text"
      ref="input"
      prefix-icon="el-icon-search"
      v-model.trim="cValue"
      :size="size"
      :placeholder="placeholder"
      :clearable="clearable"
      @input="onInput"
      @keyup.enter.native="onEnterPressed"
    >
    </ElButton>
  </div>
</template>

<script lang="ts">
import Vue, { PropType } from "vue";
import { Button as ElButton } from "element-ui";

import { debounce } from "@/flexible-table-module/util/InputOptimize";
// @ts-ignore
import vClickOutside from "v-click-outside";

type SizeType = "large" | "medium" | "small" | "mini";

export default Vue.extend({
  inheritAttrs: false,
  name: "TextSearcherAnimate",
  components: { ElButton },
  directives: {
    clickOutside: vClickOutside.directive,
  },
  props: {
    value: { type: String, default: "" },
    size: { type: String as PropType<SizeType>, default: "small" }, // 输入框的大小 large / medium / small / mini
    placeholder: { type: String, default: "搜索关键词" },
    clearable: { type: Boolean, default: true },
    inputWidth: { type: Number, default: 200 },
  },
  data(): {
    dValue: string;
    isInputVisible: boolean;
    onInput: () => void;
    valueOnEnterPressed?: string;
  } {
    return {
      dValue: this.value,
      isInputVisible: false, // 是否显示input
      onInput: () => {},
      valueOnEnterPressed: undefined,
    };
  },
  computed: {
    cValue: {
      get(): string {
        return this.dValue;
      },
      set(val: string) {
        this.dValue = val;
      },
    },
    width(): string | undefined {
      return this.isInputVisible ? `${this.inputWidth}px` : undefined;
    },
  },
  methods: {
    toSearch() {
      this.$emit("update:value", this.dValue);
      this.$emit("text-search", this.dValue);
    },
    onEnterPressed() {
      this.valueOnEnterPressed = this.dValue;
      this.toSearch();
    },
    onClickOutside() {
      if (!this.dValue) {
        this.isInputVisible = false;
      }
    },
    async onBtnClick() {
      this.isInputVisible = true;
      await this.$nextTick();
      (this.$refs["input"] as any)?.focus();
    },
  },
  mounted() {
    this.onInput = debounce(() => {
      if (
        this.valueOnEnterPressed !== undefined &&
        this.valueOnEnterPressed === this.dValue
      ) {
        this.valueOnEnterPressed = undefined;
      } else {
        this.toSearch();
      }
    }, 800);
  },
});
</script>
<style lang="scss" scoped>
.text-searcher-animate {
  flex-shrink: 0;
}
</style>
