<template>
  <!-- 概要评论页面 -->
  <div class="summary-comments">
    <div class="summary-add">
      <el-button icon="el-icon-plus" size="mini" @click="summaryinputVisible = true"
        >概要</el-button
      >
    </div>
    <el-input
      v-if="summaryinputVisible"
      type="textarea"
      :rows="2"
      placeholder="请输入内容"
      v-model="querysummary.content"
    >
    </el-input>
    <div class="summary-btn" v-if="summaryinputVisible">
      <i class="el-icon-close pointer" @click="summaryinputVisible = false"></i>
      <i class="el-icon-check pointer" @click="handleSummaryAdd"></i>
    </div>
    <div class="logs-item" v-for="(i, index) in summaryList" :key="i.id">
      <div class="item-top">
        <div class="item-top-left">
          <!-- {{ i.createAt.replace("-", ".").replace("-", ".") }} -->
        </div>
      </div>
      <div class="item-bottom">
        <div class="item-bottom-card">
          <div class="flex-between item-bottom-card-name">
            <div class="one-line">
              <el-tag size="mini" v-if="i.topFlag">置顶</el-tag>
              <span>{{ i.createName }}</span>
              <i
                v-if="poweradmin"
                :class="i.topFlag ? 'el-icon-download' : 'el-icon-upload2'"
                @click="handleTop(i)"
              ></i>
              <i
                v-if="i.createBy == userInfo.id || poweradmin"
                class="el-icon-delete"
                @click="handleDelete(i)"
              ></i>
            </div>
          </div>
          <div class="item-bottom-card-detail">
            {{ i.content }}
          </div>
          <el-divider></el-divider>
          <div class="pointer summary-total flex-between align-items-center">
            <div @click="handleLoadMore(i, index)">
              <span v-if="!i.showmore">{{ i.total }}</span>
              <i class="el-icon-chat-dot-square"></i>
              <template v-if="i.showmore">
                <span>&nbsp;收起</span>
                <i class="el-icon-arrow-up"></i>
              </template>
            </div>
            <span
              class="pointer"
              :class="
                i.replyInputVisible
                  ? 'el-icon-remove-outline'
                  : 'el-icon-circle-plus-outline'
              "
              @click="
                summaryList[index].replyInputVisible = !summaryList[index]
                  .replyInputVisible
              "
              >评论</span
            >
          </div>
          <template v-if="i.replyInputVisible">
            <el-input
              type="textarea"
              :rows="2"
              placeholder="请输入内容"
              v-model="i.replyInputContent"
            >
            </el-input>
            <div class="summary-btn">
              <i
                class="el-icon-close pointer"
                @click="summaryList[index].replyInputVisible = false"
              ></i>
              <i class="el-icon-check pointer" @click="handleReplyAdd(i, index)"></i>
            </div>
          </template>
          <template v-if="i.replyInfoList.length">
            <div v-if="!i.showmore" class="item-bottom-card-comments one-line">
              <span>{{ i.replyInfoList[0].createName }}&nbsp;评论：</span>
              <span>{{ i.replyInfoList[0].replyContent }}</span>
            </div>
            <template v-if="i.showmore">
              <div
                class="item-bottom-card-comments normal"
                v-for="child in i.replyInfoList"
                :key="child.id"
              >
                <span>{{ child.createName }}&nbsp;评论：</span>
                <span>{{ child.replyContent }}</span>
                <i
                  v-if="i.createBy == userInfo.id || poweradmin"
                  class="el-icon-delete pointer"
                  @click="handleDelete(child)"
                ></i>
              </div>
            </template>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { Summary } from "../../api/Summary";
export default {
  name: "AttributeDialog",
  props: {
    subjectType: {
      type: Number,
      default: 1,
    },
    current: Object,
  },
  data() {
    return {
      textarea: "",
      querysummary: {
        content: "",
        subjectId: "",
        subjectType: 1,
        tenantId: "",
      },
      queryreply: {
        commentId: "",
        // id: "",
        parentId: "-1",
        replyContent: "",
        subjectId: "",
        tenantId: "",
      },
      queryData: {
        subjectId: "",
        subjectType: 1, //主题类型(1.主题，2.文件)
      },
      summaryinputVisible: false,
      commentsinputVisible: false,
      summaryList: [],
    };
  },
  computed: {
    ...mapGetters(["userInfo", "poweradmin"]),
  },
  watch: {
    current(val) {},
  },
  created() {},
  mounted() {
    this.queryData.subjectId = this.current.id;
    this.queryData.subjectType = this.subjectType;
    this.querysummary.subjectId = this.current.id;
    this.querysummary.tenantId = this.userInfo.tenantIdNow;
    this.querysummary.subjectType = this.subjectType;
    this.queryreply.subjectId = this.current.id;
    this.queryreply.tenantId = this.userInfo.tenantIdNow;
    this.getList();
  },
  methods: {
    /**
     * @description: 获取概要列表
     * @return {void}
     */
    async getList() {
      const res = await Summary.queryCommentInfoList(this.queryData);
      res.forEach((element) => {
        element.showmore = false;
        element.type = "parent";
        element.replyInputVisible = false;
        element.replyInputContent = "";
        element.total = element.replyInfoList.length;
      });
      this.summaryList = res;
    },
    /**
     * @description: 删除
     * @return {void}
     */
    handleDelete(value) {
      this.$showMessage(
        {
          message: `确定删除 <span style="font-weight: 1000;">${
            value.createName
          }</span> 的${value.type == "parent" ? "概要" : "评论"}吗?`,
          ...this.$global.deleteMessage,
        },
        async (done) => {
          let res;
          if (value.type == "parent") {
            res = await Summary.deleteCommentInfo(value.id);
          } else {
            res = await Summary.deleteReplyInfo(value.id);
          }
          res && this.getList();
          done(true);
        }
      );
    },
    /**
     * @description: 加载更多评论
     * @return {void}
     */
    handleLoadMore(value, index) {
      this.summaryList[index].showmore = !this.summaryList[index].showmore;
    },
    /**
     * @description: 添加概要
     * @return {void}
     */
    async handleSummaryAdd() {
      const res = await Summary.addCommentInfo(this.querysummary);
      if (res) {
        this.querysummary.content = "";
        this.summaryinputVisible = false;
        this.getList();
      }
    },
    /**
     * @description: 添加评论
     * @param {Object} value 要评论的概要
     * @param {Number} index 当前概要下标
     * @return {void}
     */
    async handleReplyAdd(value, index) {
      this.queryreply.commentId = value.id;
      this.queryreply.replyContent = value.replyInputContent;
      const res = await Summary.addReplyInfo(this.queryreply);
      if (res) {
        this.getList();
        this.$nextTick(() => {
          this.summaryList[index].showmore = true;
        });
      }
    },
    /**
     * @description: 置顶概要
     * @param {Object} value 要置顶的概要
     * @return {void}
     */
    async handleTop(value) {
      this.$showMessage(
        {
          message: `确定${
            value.topFlag ? "取消" : ""
          }置顶 <span style="font-weight: 1000;">${value.createName}</span> 的${
            value.type == "parent" ? "概要" : "评论"
          }吗?`,
        },
        async (done) => {
          const res = await Summary.topReplyInfo({
            id: value.id,
            topFlag: value.topFlag ? 0 : 1, //	是否置顶 0：未置顶 1：置
          });
          res && this.getList();
          done(true);
        }
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.summary-comments {
  height: 100%;
  width: 100%;
  font-size: 12px;
  padding-top: 16px;
  padding-left: 16px;
  box-sizing: border-box;
  color: var(--color-text-primary);
  overflow-y: auto;
  .summary-add {
    padding: 0 10px 10px 10px;
    font-size: 14px;
  }
  .summary-btn {
    font-size: 18px;
    text-align: right;
    font-weight: 700;
    .el-icon-check {
      color: var(--color-primary);
    }
    .el-icon-close {
      color: var(--color-danger);
    }
  }
  .logs-item {
    width: 100%;
    .item-top {
      width: 100%;
      display: flex;
      justify-content: space-between;

      .item-top-left {
        position: relative;
        padding-left: 14px;
        box-sizing: border-box;
        color: #809fb8;

        &::before {
          content: "";
          width: 16px;
          height: 16px;
          background: url("@/assets/images/productLibrary/logs-time.png") no-repeat;
          background-size: 16px;
          position: absolute;
          top: 0;
          left: -7px;
        }
      }
    }

    .item-bottom {
      padding: 0px 24px 13px 13px;
      border-left: 1px solid #cfd7e6;

      div {
        line-height: 24px;
      }
      .item-bottom-card {
        padding: 0px 10px 10px 10px;
        box-sizing: border-box;
        background-color: rgba(247, 249, 252, 0.5);
        .item-bottom-card-name {
          i {
            text-indent: 6px;
            cursor: pointer;
          }
          .el-icon-upload2 {
            color: var(--color-primary);
          }
          .el-icon-delete {
            color: var(--color-danger);
          }
        }
        .item-bottom-card-detail {
          span {
            color: #ccc;
            &:hover {
              color: var(--color-primary);
            }
          }
        }
        .summary-total {
          padding-right: 10px;
          box-sizing: border-box;
          flex-shrink: 0;
          i {
            font-size: 13px;
          }
        }
        ::v-deep .el-divider--horizontal {
          margin: 10px 0;
        }
        .item-bottom-card-comments {
          font-size: 12px;
          color: var(--color-text-placeholder);
        }
        .normal {
          margin-bottom: 10px;
          color: #5e6c84;
          i {
            text-indent: 6px;
            color: var(--color-danger);
          }
        }
      }
    }
  }
}
</style>
