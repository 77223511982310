import { TColumnType, TColumnaAlign } from "./entity/Table/ColumnTypes";
import { ITreeNode } from "./entity/TreeNode";
import { type TLayout, type TypeTablePageSize } from "./interface/view/data";

export const DATE_FORMAT = 'yyyy/MM/dd';
export const TIME_FORMAT = 'HH:mm'; // 全部不要秒
export const DATETIME_FORMAT = `${DATE_FORMAT} ${TIME_FORMAT}`;
export const SWITCH_ACTIVE_COLOR = "#1664ff";
export const SWITCH_INACTIVE_COLOR = '#bababa';
export const FILTER_DRAG_DROP_FORMAT = 'filter';

export const DEFAULT_LAYOUT: TLayout = {
    classificationHeight: 36,
    dataOverviewBoardHeight: 150,
    middleBarHeight: 36,
}

export const TABLE_PAGE_SIZES: TypeTablePageSize[] = [5, 10, 15, 20, 30, 50];
export const DEFAULT_PAGE_SIZE: TypeTablePageSize = 20;

export const SELECTION_COLUMN_WIDTH = 55;

export const STORAGE_NAMESPACE = 'flexible-table';

const TREE_PROPS_TITLE = 'title';
export const TREE_PROPS_CHILDREN = 'children';
export const TREE_PROPS_ISCHECKED = 'isChecked';
const TREE_PROPS_ISHALFCHECKED = 'isHalfChecked';

export const TREE_PROPS = {
    title: TREE_PROPS_TITLE,
    children: TREE_PROPS_CHILDREN,
    isChecked: TREE_PROPS_ISCHECKED,
    isHalfChecked: TREE_PROPS_ISHALFCHECKED,
}

const DEFAULT_CELL_ALIGN: TColumnaAlign = 'center';

export function getDefaultCellAlign(colType: TColumnType): TColumnaAlign {
    if (colType === "rich-text") return 'left';
    else return DEFAULT_CELL_ALIGN;
}

export const OPERATION_COLUMN_TITLE_ALIGN: TColumnaAlign = 'left';

export const ROOT_TREE_NODE_TEMPLATE: ITreeNode = {
    id: "root",
    title: "全部",
};

// 左侧筛选栏的选中事件的触发延迟时间
export const DELAY_AFTER_SELECTION_LIST_CHANGE = 1100;

export const ICON_BTN_SIZE = 24;
export const ICON_BTN_ICON_SIZE = 16;

