<template>
  <ShortenTooltip :content="wholeDatetime">
    <span class="short-date">{{ shortenDatetime }}</span>
  </ShortenTooltip>
</template>
<script lang="ts">
import Vue, { PropType } from "vue";
import ShortenTooltip from "@/flexible-table-module/components/Common/ShortenTooltip.vue";
import { datetimeFormatter } from "@/flexible-table-module/util/CellHelper";
import {
  DATETIME_FORMAT,
  DATE_FORMAT,
} from "@/flexible-table-module/constants";

export default Vue.extend({
  inheritAttrs: false,
  name: "BodyShortDateTimeCell",
  components: { ShortenTooltip },
  props: {
    value: { type: Date as PropType<Date> },
  },
  computed: {
    shortenDatetime(): string {
      return datetimeFormatter(this.value, DATE_FORMAT);
    },
    wholeDatetime(): string {
      return datetimeFormatter(this.value, DATETIME_FORMAT);
    },
  },
});
</script>
<style lang="scss" scoped>
.short-date {
  cursor: default;
}
</style>