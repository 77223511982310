<template>
  <div class="classification" :style="rootStyle">
    <template v-for="{ name, label } in tabs">
      <div
        class="tab"
        :class="{ 'is-active': name === activeTabName }"
        :key="name"
        :id="name"
        :style="itemStyle"
        @click="onTabClick(name)"
      >
        {{ label }}
      </div>
    </template>
  </div>
</template>
<script lang="ts">
import Vue, { PropType } from "vue";
export default Vue.extend({
  inheritAttrs: false,
  name: "Classification",
  props: {
    height: { type: Number, default: 36 },
    tabs: {
      type: Array as PropType<{ name: string; label: string }[]>,
    },
    activeTabName: { type: String },
  },
  data() {
    return {};
  },
  computed: {
    rootStyle(): { height: string } {
      return { height: `${this.height}px` };
    },
    itemStyle(): { height: string; "line-height": string } {
      return {
        height: `${this.height}px`,
        "line-height": `${this.height}px`,
      };
    },
    cActiveTabName: {
      get(): string {
        return this.activeTabName;
      },
      set(v: string) {
        this.$emit("update:activeTabName", v);
      },
    },
  },
  methods: {
    onTabClick(name: string) {
      this.cActiveTabName = name;
      this.$emit("classification-click", name);
    },
  },
});
</script>
<style lang="scss" scoped>
@import "src/flexible-table-module/scss/_variables.scss";

.classification {
  width: 100%;
  overflow: hidden;
  // padding: 0 16px;
  // background-color: $color-reverse;

  .tab {
    float: left;
    padding: 0 16px;
    cursor: pointer;

    &.is-active {
      color: $color-brand;
    }

    &:hover {
      color: $color-normal;
    }
  }
}
</style>