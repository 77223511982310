<template>
  <div class="content-select w-h-100 flex">
    <div class="content-select-list">
      <div class="container" ref="containwrapper" :style="dynamicStyle">
        <div
          class="content-item"
          :class="{ 'active-item': selectids.includes(item.id) }"
          v-for="item in tableData"
          :key="item.id"
          @click="handleContentSelect(item)"
        >
          <div class="product-item-img">
            <img
              class="w-h-100"
              :draggable="false"
              :src="
                item.columnIcon.startsWith('http')
                  ? item.columnIcon
                  : require('@/assets/images/sample-5.png')
              "
              alt=""
            />
          </div>
          <div class="product-item-name one-line pd-10">{{ item.columnName }}</div>
          <div class="product-item-date one-line pd-10">
            最新更新：{{ Object.dayjs(item.updateAt || item.createAt) }}
          </div>
          <div
            class="product-item-previewicon flex align-items-center pointer"
            @click.prevent.stop="getDetail(item)"
          >
            <svg-icon icon="preview-solid" :width="16" :height="16"></svg-icon>
          </div>
        </div>
      </div>
    </div>
    <ColumnChildDetail
      v-if="previewdetail"
      :visible.sync="previewdetail"
      :activeColumn="clickitem"
    />
  </div>
</template>

<script>
import { ProductLibrary } from "../../../../api/ProductLibrary";
import ColumnChildDetail from "./ColumnChildDetail.vue";
export default {
  props: {
    activeColumn: Object,
    subject: Object,
    ispre: {
      type: Boolean,
      default: false,
    },
    alreadychose: Array,
    scheme: Object,
  },
  components: { ColumnChildDetail },
  data() {
    return {
      selectids: [], //已经选择的id合集
      tableData: [],
      queryData: {
        columnName: "",
        columnId: "",
        pageNo: 1,
        pageSize: 999,
        subjectVersionId: "",
      },
      richText: "",
      clickitem: null,
      itemsPerRow: 0, // 每行放置的元素个数
      resizeObserver: null,
      previewdetail: false,
    };
  },
  watch: {
    selectids(val) {
      const selectdata = {
        dataIds: val,
        dataType: 1,
        schemeId: "",
        subjectColumnId: this.activeColumn.id,
        subjectId: this.subject.id,
      };
      this.$emit("handleselect", selectdata);
    },
  },
  computed: {
    dynamicStyle() {
      return {
        gridTemplateColumns: `repeat(${this.itemsPerRow}, 1fr)`, // 根据每行放置的元素个数动态设置 Grid 布局的列数
      };
    },
  },
  mounted() {
    this.queryData.columnId = this.activeColumn.id;
    this.queryData.subjectVersionId = this.subject.versionId;
    this.getTableData();
    if (this.alreadychose.length) {
      this.selectids = this.alreadychose[0].dataIds;
    } else {
      this.getdefaultTableData();
    }
    this.resizeObserver = new ResizeObserver(this.calculateItemsPerRow);
    this.resizeObserver.observe(this.$refs.containwrapper);
  },
  methods: {
    /**
     * @description: 计算放置元素个数
     * @return {void}
     */
    calculateItemsPerRow() {
      if (!this.$refs.containwrapper) {
        return;
      }
      const containerWidth = this.$refs.containwrapper.offsetWidth; // 获取容器宽度
      const itemWidth = 200;
      this.itemsPerRow = Math.floor(containerWidth / itemWidth); // 计算每行放置的元素个数
    },
    /**
     * @description:获取已选列表
     * @return {void}
     */
    async getdefaultTableData() {
      let payload = {
        schemeId: this.scheme.id,
        pageNo: 1,
        pageSize: 999,
        subjectVersionId: this.subject.versionId,
        subjectColumnId: this.activeColumn.id,
      };
      const res = await ProductLibrary.getSchemeSubjectColumnChildPageList(payload);
      if (res.records.length) {
        this.selectids = res.records.map((item) => item.id);
      }
    },
    /**
     * @description:获取子栏目列表
     * @return {void}
     */
    async getTableData(pageNo) {
      pageNo && (this.queryData.pageNo = pageNo);
      const res = await ProductLibrary.getSubjectColumnChildList(this.queryData);
      this.tableData = res.records;
    },
    handleContentSelect(value) {
      if (this.selectids.includes(value.id)) {
        this.selectids = this.selectids.filter((item) => item != value.id);
      } else {
        this.selectids.push(value.id);
      }
    },
    /**
     * @description: 预览介绍
     * @param {Object} value 介绍id
     * @return {void}
     */
    async getDetail(value) {
      this.clickitem = value;
      this.previewdetail = true;
    },
  },
};
</script>
<style lang="scss" scoped>
.content-select-list {
  // width: 434px;
  width: 100%;
  height: 100%;
  overflow-y: auto;
  padding: 14px;
  box-sizing: border-box;
  border-right: 1px solid #ccc;
  .container {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(6, minmax(0, 1fr));
    gap: 14px; /* 设置列之间的间隙 */
    overflow-y: auto;
    // padding: 0 16px;
    .content-item {
      width: 100%;
      height: 180px;
      border: 1px solid #cfd7e6;
      border-radius: 5px;
      position: relative;
      &:hover {
        .product-item-previewicon {
          display: flex;
        }
      }
      .product-item-img {
        width: 100%;
        height: 113px;
      }
      .product-item-name {
        width: 100%;
        line-height: 34px;
        font-weight: 700;
        font-size: 14px;
        color: var(--color-text-primary);
      }
      .product-item-date {
        font-size: 12px;
        color: #8997b0;
        line-height: 22px;
      }
      .product-item-previewicon {
        justify-content: center;
        position: absolute;
        top: 2px;
        left: 2px;
        width: 20px;
        height: 20px;
        opacity: 0.6;
        border-radius: 5px;
        background: rgba(1, 2, 3, 1);
        display: none;
      }
    }
    .active-item {
      border: 2px solid #8b6bff;
      position: relative;
      &::before {
        content: "";
        z-index: 2;
        position: absolute;
        top: -2px;
        right: -2px;
        width: 22px;
        height: 22px;
        background-image: url("@/assets/images/productLibrary/column_checked.png");
        background-size: 22px;
        background-repeat: no-repeat;
      }
    }
  }
}
.content-select-preview {
  background-color: #fff;
  padding: 10px;
  overflow-y: auto;
}
.pd-10 {
  padding: 0 10px;
  box-sizing: border-box;
}
</style>
