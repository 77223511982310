<template>
  <div class="main-view-trees">
    <template v-for="(filter, index) in filters">
      <div class="main-view-tree" :key="index">
        <template v-if="filter.list && filter.list.length > 0">
          <div class="filter-title">{{ filter.title }}</div>
          <div class="filter-tree">
            <SimpleTree
              :nodes="filter.list"
              :props="TREE_PROPS"
              :hasCheckbox="true"
              :isCheckboxReadonly="isCheckboxReadonly"
              :isNodeClickSingleCheckable="true"
              @checkbox-change="onTreeCheckboxChange"
            />
          </div>
        </template>
      </div>
    </template>
  </div>
</template>
<script lang="ts">
import Vue, { PropType } from "vue";
// import TreeNodes from "@/flexible-table-module/components/Common/TreeNodes.vue";
import SimpleTree from "@/flexible-table-module/components/Common/Tree/SimpleTree.vue";
import { Filter } from "@/flexible-table-module/entity/Filter";

import { TREE_PROPS } from "@/flexible-table-module/constants";

export default Vue.extend({
  inheritAttrs: false,
  name: "MainViewTrees",
  components: { SimpleTree },
  props: {
    filters: { type: Array as PropType<Filter[]>, required: true },
    isCheckboxReadonly: { type: Boolean, default: false }, // 多选框是否只读
  },
  data(): {
    TREE_PROPS: typeof TREE_PROPS;
  } {
    return {
      TREE_PROPS,
    };
  },
  methods: {
    onTreeCheckboxChange(node: any, isChecked: boolean) {
      this.$emit("tree-checkbox-change", node, isChecked);
    },
  },
});
</script>
<style lang="scss" scoped>
@import "src/flexible-table-module/scss/_variables.scss";

.main-view-trees {
  box-sizing: border-box;
  // width: 100%;
  height: 100%;
  overflow-x: auto;

  display: flex;
  justify-content: flex-start;
  align-items: stretch;

  padding: 8px;

  .main-view-tree {
    box-sizing: border-box;
    min-width: 200px;
    // height: 100%;
    border: 1px solid $color-info;

    &:not(:first-child) {
      margin-left: 8px;
    }

    $title-height: 32px;
    .filter-title {
      box-sizing: border-box;
      height: $title-height;
      line-height: $title-height;
      padding: 0 8px;
      border-bottom: 1px solid $color-info;
    }

    .filter-tree {
      height: calc(100% - $title-height);
    }
  }
}
</style>