<template>
  <div class="column-icon">
    <Icon :size="24" :iconBaseUrl="iconUrl" />
  </div>
</template>
<script lang="ts">
import Vue from "vue";
import Icon from "@/flexible-table-module/components/Common/Icon.vue";

export default Vue.extend({
  inheritAttrs: false,
  name: "ColumnIcon",
  components: { Icon },
  props: {
    // scope: { type: Object, required: true },
    iconUrl: { type: String, required: true },
  },
});
</script>
<style lang="scss" scoped>
.column-icon {
  // width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>