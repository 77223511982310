<template>
  <el-dialog class="ss-dialog" width="670px" :title="currentModule ? '编辑模块' : '添加模块'" :visible.sync="dialogVisible">
    <div v-loading="loading"  v-if="haspower" class="ss-dialog__content">
      <div class="content-top">
        <el-input v-model="moduleInfo.moduleName" show-word-limit class="content-top-input" placeholder="自定义模块"
          :maxlength="10"></el-input>
        <div class="content-top-icon__wrapper">
          <div v-loading="iconLoading" class="content-top-icon" ref="iconWrapper" :class="{ 'is-open': isOpen }">
            <div class="xs-icon-caret" @click="changeCollapse"></div>
            <div v-for="(i, index) in iconList" class="module-icon"
              :class="{ 'is-active': moduleInfo.moduleIcon === i.url, 'deleting': isDetete }" :key="i.id"
              @click="handleSelectIcon(i)">
              <svg-icon v-if="i.iconType === 'local'" width="20" height="20" :icon="i.url"
                :color="moduleInfo.moduleIcon === i.url ? '#6a87fc' : '#344563'"></svg-icon>
              <svg-icon-2 v-if="i.iconType === 'online'" color="#344563" activeColor="#6a87fc" :icon="i.url"
                :is-active="moduleInfo.moduleIcon === i.url" />
              <div v-if="i.iconType === 'online' && isDetete" class="module-icon__delete" @click="deleteIcon(i, index)">
                <img src="@/assets/images/common/module-icon-close.png">
              </div>
            </div>
            <el-upload accept=".svg,.png" name="photo" :action="action" :disabled="isDetete" :headers="headers"
              :show-file-list="false" :on-success="handleIconSuccess">
              <div class="icon-button" title="上传图标,仅支持SVG格式的图标,推荐大小为20x20" :class="{ 'disabled': isDetete }">
                <svg-icon width="8" height="8" icon="module-icon-plus"></svg-icon>
              </div>
            </el-upload>
            <div v-if="showDeleteIcon && !isDetete" class="icon-button" title="删除图标" @click="isDetete = !isDetete">
              <svg-icon width="8" height="2" icon="module-icon-subtract"></svg-icon>
            </div>
            <div v-if="isDetete" class="icon-button" @click="isDetete = !isDetete">
              <svg-icon width="8" height="7" icon="module-icon-check"></svg-icon>
            </div>
          </div>
        </div>
      </div>
      <div v-if="!currentModule" class="category-list">
        <div v-for="(item, index) in categoryList" class="category-item" :class="setCategoryItemClass(item, index)"
          :key="item.id" :title="item.disabled ? '功能正在开发中...' : ''" @click="selectCategory(item)">{{ item.categoryName }}
        </div>
      </div>
      <div v-if="!currentModule" class="content">
        <TinyMce v-if="currentCategory" :readonly="isPreview" :value="currentCategory.categoryDesc || ''" :module-id="moduleId" /> 
      </div>
    </div>
    <span slot="footer"  v-if="haspower">
      <div class="dialog-footer">
        <span class="dialog-footer-tip">联系客服定制板式</span>
        <span>
          <el-button type="info" @click="dialogVisible = false">取&nbsp;消</el-button>
          <el-button type="primary" :loading="confirmLoading" @click="handleConfirm">确&nbsp;定</el-button>
        </span>
      </div>
    </span>
    <div v-if="!haspower" class="ss-dialog__content">
      <el-empty description="暂无权限，请联系客服获取权限"></el-empty>
    </div>
    <span v-if="!haspower" slot="footer">
      <div class="dialog-footer">
        <span class="dialog-footer-tip">联系客服定制板式</span>
        <span>
          <el-button type="info" @click="dialogVisible = false">取&nbsp;消</el-button>
          <el-button type="primary" @click="dialogVisible = false">确&nbsp;定</el-button>
        </span>
      </div>
    </span>
  </el-dialog>
</template>

<script>
import { mapGetters } from 'vuex';
import { ProductLibrary } from '@/api/ProductLibrary';
import { Module } from '@/api/Module';
import TinyMce from '@/components/TinyMce.vue';
export default {
  name: "AddModuleDialog",
  components: { TinyMce },
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    moduleId: String, // 模块 id
    currentModule: Object
  },
  data() {
    return {
      loading: true,
      isOpen: false, // 是否展开图标面板
      isDetete: false, // 删除状态
      iconLoading: false, // 图标面板loading
      showDeleteIcon: false, // 是否显示图标面板的删除图标
      action: "",
      headers: {
        "X-Access-Token": "",
        "Tenant-Id": "",
      },
      moduleInfo: {
        id: "",
        moduleName: "",
        moduleIcon: "",
        categoryId: "",
        createType: 2,
        status: 1,
      },
      iconList: [
        { id: "module-icon-1", url: "module-icon-1", iconType: "local" },
        { id: "module-icon-2", url: "module-icon-2", iconType: "local" },
        { id: "module-icon-3", url: "module-icon-3", iconType: "local" },
        { id: "module-icon-4", url: "module-icon-4", iconType: "local" },
        { id: "module-icon-5", url: "module-icon-5", iconType: "local" },
        { id: "module-icon-6", url: "module-icon-6", iconType: "local" },
        { id: "module-icon-7", url: "module-icon-7", iconType: "local" },
        { id: "module-icon-8", url: "module-icon-8", iconType: "local" },
        { id: "module-icon-9", url: "module-icon-9", iconType: "local" },
        { id: "module-icon-10", url: "module-icon-10", iconType: "local" },
      ],
      categoryList: [],
      currentCategory: null,
      confirmLoading: false,
      isPreview:true,
      haspower:true
    }
  },
  computed: {
    dialogVisible: {
      get() {
        return this.visible;
      },
      set(val) {
        this.$emit('update:visible', val);
      }
    },
    ...mapGetters(["token", "userInfo"])
  },
  created() {
    this.action = process.env.VUE_APP_URL + "/sales-show-4.0/module/uploadIcon";
    this.headers['X-Access-Token'] = this.token;
    this.headers['Tenant-Id'] = this.userInfo.tenantIdNow;
  },
  mounted() {
    document.addEventListener("click", this.clickEvent, false);
    if (this.currentModule) {
      this.moduleInfo.id = this.currentModule.id;
      this.moduleInfo.moduleName = this.currentModule.name;
      this.moduleInfo.moduleIcon = this.currentModule.icon;
      this.moduleInfo.categoryId = this.currentModule.categoryId;
    } else {
      this.moduleInfo.moduleIcon = "module-icon-1";
    }
    this.getModuleCategoryList();
  },
  beforeDestroy() {
    document.removeEventListener("click", this.clickEvent, false);
  },
  methods: {
    /**
     * @description: 获取模块分类列表
     * @return {void}
     */
    async getModuleCategoryList() {
      const res = await ProductLibrary.getCategoryList();
      this.categoryList = res.map(item => {
        return { disabled: !(item.id === "1665910853276692481" || item.id === "1665916240717934594"), ...item }
      })
      if(!this.categoryList.length&&!this.currentModule){
        this.haspower=false
        return
      }
      if (this.categoryList.length && !this.currentModule) {
        this.currentCategory = this.categoryList[0];
        this.moduleInfo.categoryId = this.categoryList[0].id;
      }
      this.getModuleIconList();
    },
    /**
     * @description: 设置样式
     * @param {*} item
     * @param {*} index
     * @return {*}
     */
    setCategoryItemClass(item, index) {
      let className = this.currentCategory.id === item.id ? `category-item-${index + 1} is-active` : `category-item-${index + 1}`;
      return item.disabled ? `${className} disabled` : className;
    },
    /**
     * @description: 选择模块分类
     * @param {Object} value 选择项
     * @return {void}
     */
    selectCategory(value) {
      if (value.disabled) {
        return
      }
      this.currentCategory = value;
      this.moduleInfo.categoryId = value.id;
    },
    /**
     * @description: 获取上传的图标列表
     * @return {void}
     */
    async getModuleIconList(url) {
      this.iconLoading = true;
      const res = await Module.queryModuleIconList();
      this.showDeleteIcon = Boolean(res.length);
      if (url) {
        const item = res.find(i => i.url === url);
        item && this.iconList.push({ id: item.id, url: item.url, iconType: "online" })
      } else {
        const list = res.map(item => {
          return { id: item.id, url: item.url, iconType: "online" }
        })
        this.iconList = [...this.iconList, ...list]
        const index = this.iconList.findIndex(item => item.url === this.moduleInfo.moduleIcon);
        if (index > 2) {
          const select = this.iconList[index];
          this.iconList.splice(index, 1);
          this.iconList.unshift(select);
        }
      }
      this.loading = false;
      this.iconLoading = false;
    },
    /**
     * @description: 删除模块图标
     * @return {void}
     */
    async deleteIcon(value, index) {
      this.iconLoading = true;
      const res = await Module.deleteModuleIcon(value.id);
      if (res) {
        this.iconList.splice(index, 1);
        const onlineIconList = this.iconList.filter(item => item.url.startsWith("http"));
        this.showDeleteIcon = Boolean(onlineIconList.length);
        // 删除已选中的图标则重新选中第一个图标
        if (value.url === this.moduleInfo.moduleIcon) {
          this.moduleInfo.moduleIcon = this.iconList[0].url;
        }
      }
      this.iconLoading = false;
    },
    /**
     * @description: 文件上传成功时的钩子
     * @param {Object} response 接口返回的参数
     * @return {void}
     */
    handleIconSuccess(response) {
      if (response.code === 200) {
        this.$message.success("图标上传成功");
        this.getModuleIconList(response.result.src);
      }
    },
    /**
     * @description: 点击事件
     * @param {PointerEvent} event
     * @return {void}
     */
    clickEvent(event) {
      if (this.isOpen && !this.$refs.iconWrapper.contains(event.target)) {
        this.changeCollapse();
      }
    },
    /**
     * @description: 打开、关闭图标面板
     * @return {void}
     */
    changeCollapse() {
      if (this.isOpen) {
        this.$refs.iconWrapper.scrollTo(0, 0); // 滚动到顶部
        // 选中图标索引大于 2 则将它至于数组开头
        const index = this.iconList.findIndex(item => item.url === this.moduleInfo.moduleIcon);
        if (index > 2) {
          const select = this.iconList[index];
          this.iconList.splice(index, 1);
          this.iconList.unshift(select);
        }
        this.isDetete = false;
      }
      this.isOpen = !this.isOpen;
    },
    /**
     * @description: 选择图标
     * @param {Object} value 图标
     * @return {void}
     */
    handleSelectIcon(value) {
      this.moduleInfo.moduleIcon = value.url;
    },
    /**
     * @description: 确定
     * @return {void}
     */
    async handleConfirm() {
      this.confirmLoading = true;
      if (!this.moduleInfo.moduleName) {
        this.moduleInfo.moduleName = "自定义模块";
      }
      const res = await ProductLibrary.updateModule(this.currentModule ? "edit" : "add", this.moduleInfo);
      this.confirmLoading = false;
      if (res) {
        this.$emit("finish");
        this.dialogVisible = false;
      }
    },
  }
}
</script>

<style lang="scss" scoped>
.content-top {
  display: flex;

  .content-top-input {
    flex: 1;

    ::v-deep .el-input__inner {
      height: 46px;
      line-height: 46px;
    }
  }

  .content-top-icon__wrapper {
    width: 165px;
    height: 46px;
    margin-left: 12px;
    position: relative;

    .content-top-icon {
      position: absolute;
      z-index: 1;
      top: 0;
      left: 0;
      box-sizing: border-box;
      width: 165px;
      max-height: 46px;
      // height: 46px;
      padding: 0 10px;
      border: 1px solid #c5d0e3;
      border-radius: 5px;
      background-color: #fff;
      display: flex;
      flex-wrap: wrap;
      align-content: flex-start;
      overflow-y: hidden;
      // transition: all .3s;

      &.is-open {
        // height: 178px;
        max-height: 178px;
        overflow-y: auto;

        .xs-icon-caret {
          transform: rotate(180deg);
        }
      }

      .xs-icon-caret {
        box-sizing: content-box;
        width: 6px;
        height: 5px;
        padding: 4px;
        background-image: url("@/assets/images/common/triangle_down.png");
        background-position: 4px 4px;
        background-size: 6px 5px;
        background-repeat: no-repeat;
        transition: all .3s;
        // position: absolute;
        // top: 16px;
        // right: 12px;
        position: fixed;
        margin-top: 16px;
        margin-left: 128px;
        cursor: pointer;
      }

      .module-icon {
        box-sizing: border-box;
        width: 30px;
        height: 30px;
        border-radius: 5px;
        border: 1px solid #fff;
        margin: 7px 5px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        position: relative;

        .module-icon__delete {
          position: absolute;
          z-index: 1;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
          border-radius: 5px;
          background-color: rgba(52, 69, 99, .8);
          display: flex;
          align-items: center;
          justify-content: center;

          img {
            width: 14px;
            height: 14px;
          }
        }

        &.is-active {
          background-color: #f2f7ff;
          border-color: #f2f7ff;
        }

        &:not(.is-active, .deleting):hover {
          border-color: var(--color-primary);
        }
      }

      .icon-button {
        box-sizing: border-box;
        width: 20px;
        height: 20px;
        border-radius: 3px;
        border: 1px solid var(--color-text-regular);
        margin: 12px 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;

        .svg-icon {
          color: var(--color-text-regular) !important;
        }

        &:not(.disabled):hover {
          background-color: var(--color-primary);
          border-color: var(--color-primary);

          .svg-icon {
            color: #fff !important;
          }
        }

        &.disabled {
          border-color: #dae0eb;
          cursor: not-allowed;

          .svg-icon {
            color: #dae0eb !important;
          }
        }
      }
    }
  }
}

.cu-input {
  width: 435px;
  height: 46px;

  ::v-deep .el-input__inner {
    height: 46px;
    line-height: 46px;
  }
}

.icon-container {
  box-sizing: border-box;
  width: 165px;
  height: 46px;
  padding: 7px 16px;
  border-radius: 5px;
  background: #fff;
  border: 1px solid #c5d0e3;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .icon-block {
    box-sizing: border-box;
    width: 30px;
    height: 30px;
    border-radius: 5px;
    border: 1px solid #fff;
    cursor: pointer;

    &.is-active {
      background-color: #fff;
      border-color: #8997b0;
    }

    &:hover {
      background-color: #f2f7ff;
    }

    &:not(.is-active):hover {
      border-color: #f2f7ff;
    }

    .icon {
      width: 20px;
      height: 20px;
      padding: 4px;
    }
  }

  .arrow-down {
    width: 6px;
    height: 5px;
    background-image: url("@/assets/images/common/triangle_down.png");
    background-size: 6px 5px;
    background-repeat: no-repeat;
    cursor: pointer;
  }
}

.category-list {
  display: flex;
  flex-wrap: nowrap;
  margin: 8px 0 30px;

  .category-item {
    flex-shrink: 0;
    width: 142px;
    height: 70px;
    line-height: 70px;
    font-size: 14px;
    font-weight: 400;
    // color: var(--color-text-regular);
    color: #fff;
    text-align: center;
    background-size: 142px 70px;
    background-repeat: no-repeat;
    cursor: pointer;

    &+.category-item {
      margin-left: 14px;
    }

    &.disabled {
      filter: grayscale(100%);
      cursor: default;
    }

    &.category-item-1 {
      background-image: url("@/assets/images/module/module-category-1.png");

      &.is-active {
        color: #fff;
        background-image: url("@/assets/images/module/module-category-1-hover.png");
      }

      &:not(.disabled):hover {
        color: #fff;
        background-image: url("@/assets/images/module/module-category-1-hover.png");
      }
    }

    &.category-item-2 {
      background-image: url("@/assets/images/module/module-category-2.png");

      &.is-active {
        color: #fff;
        background-image: url("@/assets/images/module/module-category-2-hover.png");
      }

      &:not(.disabled):hover {
        color: #fff;
        background-image: url("@/assets/images/module/module-category-2-hover.png");
      }
    }

    &.category-item-3 {
      background-image: url("@/assets/images/module/module-category-3.png");

      &.is-active {
        color: #fff;
        background-image: url("@/assets/images/module/module-category-3-hover.png");
      }

      &:not(.disabled):hover {
        color: #fff;
        background-image: url("@/assets/images/module/module-category-3-hover.png");
      }
    }

    &.category-item-4 {
      background-image: url("@/assets/images/module/module-category-4.png");

      &.is-active {
        color: #fff;
        background-image: url("@/assets/images/module/module-category-4-hover.png");
      }

      &:not(.disabled):hover {
        color: #fff;
        background-image: url("@/assets/images/module/module-category-4-hover.png");
      }
    }
  }
}

.content {
  width: 100%;
  height: 200px;
  border-radius: 10px;
  background: #f5f7fa;
}

.dialog-footer {
  display: flex;
  align-items: center;
  justify-content: space-between;

  .dialog-footer-tip {
    font-size: 14px;
    font-weight: 400;
    color: #abb4c4;
    cursor: pointer;

    &:hover {
      color: var(--color-primary);
    }
  }
}
</style>