<template>
  <div
    v-if="computedVisible"
    class="column-button"
    @mouseenter="onMouseenter"
    @mouseleave="onMouseleave"
    @click="onClick"
  >
    <slot v-bind="{ iconClass: icon }"></slot>
    <!-- <svg-icon
      class="column-button__svg-icon"
      :iconClass="icon"
      :iconStyle="iconStyle"
    ></svg-icon> -->
  </div>
</template>
<script lang="ts">
import Vue from "vue";
const ICON_DEFAULT_SIZE = 16;

export default Vue.extend({
  inheritAttrs: false,
  name: "ColumnButton",
  props: {
    icon: { type: String, required: true },
    visibleRef: { type: [Boolean, Object, Array] },
  },
  data(): { size: number } {
    return {
      size: ICON_DEFAULT_SIZE,
    };
  },
  computed: {
    iconStyle(): object {
      return { width: `${this.size}px`, height: `${this.size}px` };
    },
    // visible 如果是 undefined 则当成是 true，是 false 或 null 则当成是 false，剩下的情况都当成是 true
    computedVisible(): boolean {
      const result =
        this.visibleRef === undefined ||
        (this.visibleRef !== false && this.visibleRef !== null);
      return result;
    },
  },
  methods: {
    onMouseenter() {
      // this.size = 20;
    },
    onMouseleave() {
      // this.size = ICON_DEFAULT_SIZE;
    },
    onClick() {
      this.$emit("btn-click");
    },
  },
});
</script>
<style lang="scss" scoped>
.column-button {
  // width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  &:hover {
    cursor: pointer;

    .column-button__svg-icon {
      transform: scale(1.25);
    }
  }
}
</style>