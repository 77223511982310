<template>
  <div
    class="icon-switch"
    :class="{ hovering: isHovering, pressing: isPressing, disabled: disabled }"
    :style="iconBtnStyle"
    @mouseenter="onMouseenter"
    @mouseleave="onMouseleave"
    @mousedown="onMousedown"
    @mouseup="onMouseup"
    @click="onClick"
  >
    <!-- 如果用 v-if 和 v-else 来切换两个图标的显示状态，当点击了本组件，在图标还在切换之际，鼠标马上离开本组件，hovering 状态会被保留，原因未知 -->
    <div v-show="value" class="icon-core" :style="iconCoreStyle">
      <slot name="active"></slot>
    </div>

    <div v-show="!value" class="icon-core" :style="iconCoreStyle">
      <slot name="inactive"></slot>
    </div>
  </div>
</template>

<script lang="ts">
import Vue from "vue";
export default Vue.extend({
  props: {
    value: { type: Boolean, default: true },
    iconSize: { type: Number, required: true },
    padding: { type: Number, default: 4 },
    disabled: { type: Boolean, default: false },
    activeColor: { type: String },
    inactiveColor: { type: String },
  },
  data() {
    return {
      isHovering: false,
      isPressing: false,
    };
  },
  computed: {
    iconBtnStyle(): {
      width: string;
      height: string;
      padding: string;
      borderRadius: string;
    } {
      const size = Number(this.iconSize) + Number(this.padding) * 2;
      return {
        width: `${size}px`,
        height: `${size}px`,
        padding: `${this.padding}px`,
        borderRadius: `${this.padding}px`,
      };
    },
    iconCoreStyle(): { width: string; height: string; fill: string } {
      const size = this.iconSize;
      return {
        width: `${size}px`,
        height: `${size}px`,
        fill: this.value ? this.activeColor : this.inactiveColor,
      };
    },
  },
  methods: {
    onClick() {
      if (this.disabled) return;
      this.$emit("input", !this.value);
      this.$emit("change", !this.value);
      console.log("change");
    },
    onMouseenter() {
      this.isHovering = true;
    },
    onMouseleave() {
      this.isHovering = false;
      this.isPressing = false;
    },
    onMousedown() {
      this.isPressing = true;
    },
    onMouseup() {
      this.isPressing = false;
    },
  },
});
</script>

<style lang="scss" scoped>
@import "src/flexible-table-module/scss/_variables.scss";

.icon-switch {
  box-sizing: border-box;
  cursor: pointer;
  display: inline-block;

  .icon-core {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &.hovering {
    background-color: $btn-hover-color;

    .icon-core {
      fill: white !important;
    }
  }

  &.pressing {
    background-color: #999;

    .icon-core {
      fill: white !important;
    }
  }

  &.disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }
}
</style>