import { TColumnaAlign } from "./ColumnTypes";
// import { getDefaultCellAlign } from "@/flexible-table-module/constants";

export class BaseColumn<T = string> {

    // public columnType: string = "base";

    /** 列的唯一名称 */
    public id: T;
    /** 是否激活 */
    public active: boolean;
    /** 是否总是激活 */
    public alwaysActivated: boolean;
    /** 用于和后端返回的数据对应起来，从而设置字段的顺序和显隐 */
    public settingName?: string;
    /** 列的标题 */
    public title?: string;
    /** 列的排列顺序 */
    public fieldOrder: number;
    /** 从后端返回的数据 */
    public atachment?: any;
    /** 列表头的定位 */
    public headerAlign: TColumnaAlign;
    /** 列表单元格的定位 */
    public cellAlign: TColumnaAlign;
    /** 固定宽度 */
    public width?: number;
    /** 最小宽度 */
    public minWidth?: number;

    constructor(params: {
        id: T,
        active?: boolean,
        alwaysActivated?: boolean,
        settingName?: string,
        title?: string,
        fieldOrder?: number,
        atachment?: any,
        headerAlign?: TColumnaAlign,
        cellAlign?: TColumnaAlign,
        width?: number,
        minWidth?: number,
    }) {
        const {
            id,
            active = true,
            alwaysActivated = false,
            settingName,
            title,
            fieldOrder = -1,
            atachment,
            headerAlign = 'center',
            cellAlign = 'center',
            width,
            minWidth,
        } = params;

        this.id = id;
        if (alwaysActivated) this.active = true; // 如果总是激活，那么激活状态必然是 true
        else this.active = active;
        this.alwaysActivated = alwaysActivated;
        this.settingName = settingName;
        this.title = title;
        this.fieldOrder = fieldOrder;
        this.atachment = atachment;
        this.headerAlign = headerAlign;
        this.cellAlign = cellAlign;
        this.width = width;
        this.minWidth = minWidth;
    }
}