<template>
  <el-dialog
    append-to-body
    class="ss-dialog no-header"
    :modal="false"
    width="1200px"
    top="5vh"
    :visible.sync="dialogVisible"
  >
    <!-- 产品快捷功能预览页 -->
    <div class="dialog-body">
      <div class="preview-detail" element-loading-background="rgba(40, 47, 57,0.8)">
        <div class="detail-main" id="product_preview">
          <ContentPreview
            v-if="activecolumn.columnType === 1"
            :active-column="activecolumn"
            :subject="subject"
            :isfull="isfullscreenstatus"
            :loginrequired="loginrequired"
            :share-id="shareId"
          />
          <ArticlePreview
            v-if="activecolumn.columnType === 5"
            :active-column="activecolumn"
            :subject="subject"
            :loginrequired="loginrequired"
            :share-id="shareId"
          />
          <ProductPreview
            v-if="activecolumn.columnType === 3"
            :active-column="activecolumn"
            :subject="subject"
            :loginrequired="loginrequired"
            :share-id="shareId"
          />
          <DemoPreview
            v-if="activecolumn.columnType === 'demo'"
            :active-column="activecolumn"
            :subject="subject"
            :loginrequired="loginrequired"
            :share-id="shareId"
          />
          <div class="detail-close" @click="dialogVisible = false">
            <img src="@/assets/images/common/close.png" alt="Close" />
          </div>
          <div class="detail-close detail-full" @click="fullScreenButton">
            <div class="el-icon-full-screen"></div>
          </div>
        </div>
      </div>
    </div>
  </el-dialog>
</template>

<script>
import { mapGetters } from "vuex";
import { ProductLibrary } from "@/api/ProductLibrary";
import screenfull from "screenfull";
import ContentPreview from "./ContentPreview.vue";
import ArticlePreview from "./ArticlePreview.vue";
import ProductPreview from "./ProductPreview.vue";
import DemoPreview from "./DemoPreview.vue";

export default {
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    activeid: String,
    subject: Object,
    activecolumn: Object,
    loginrequired: {//是否需要登录查看
      type: Boolean,
      default: true,
    },
    shareId: String,//分享id
  },
  components: { ContentPreview, ArticlePreview, ProductPreview, DemoPreview },
  data() {
    return {
      isPreview: true,
      activeColumn: null,
      isfullscreenstatus: false,
    };
  },
  computed: {
    dialogVisible: {
      get() {
        return this.visible;
      },
      set(val) {
        this.$emit("update:visible", val);
      },
    },
    ...mapGetters(["token", "userInfo", "columnCategoryTypeDict"]),
  },
  mounted() {
    if (screenfull.isEnabled) {
      screenfull.on("change", this.handleFullscreenChange);
    }
  },
  beforeDestroy() {
    if (screenfull.isEnabled) {
      screenfull.off("change", this.handleFullscreenChange);
    }
  },
  methods: {
    /**
     * @description:点击全屏显示
     * @return {void}
     */
    fullScreenButton() {
      const elementById = document.getElementById("product_preview");
      if (screenfull.isEnabled && elementById) {
        screenfull.request(elementById);
      }
    },
    /**
     * @description:检测全屏状态
     * @return {void}
     */
    handleFullscreenChange() {
      if (screenfull.isFullscreen) {
        this.isfullscreenstatus = true;
      } else {
        this.isfullscreenstatus = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.preview-detail {
  height: 80vh;
  width: 1200px;
  z-index: 12;
  display: flex;
  padding: 20px;
  box-sizing: border-box;
  border-radius: 5px;

  .detail-main {
    height: 100%;
    width: 100%;
    position: relative;
    background-color: #fff;

    ::v-deep .editor {
      width: 100%;
    }
  }

  .detail-full {
    color: #ccc;
    font-size: 18px;
    text-align: center;
    line-height: 30px;
    right: 50px !important;
  }

  .detail-close {
    height: 30px;
    width: 30px;
    position: absolute;
    top: 10px;
    right: 10px;
    border-radius: 5px;
    z-index: 14;

    img {
      height: 20px;
      width: 20px;
      margin-left: 5px;
      margin-top: 5px;
    }

    &:hover {
      background-color: rgba(0, 0, 0, 0.3);
    }
  }
}

.text {
  height: 100%;
  color: var(--color-text-primary);
  overflow: auto;
}
</style>
