<template>
  <div class="content-select w-h-100 flex">
    <div class="content-select-list">
      <div class="container">
        <div
          class="content-item"
          :class="{ 'active-item': selectids.includes(item.contentId) }"
          v-for="item in tableData"
          :key="item.id"
          @click="getDetail(item)"
        >
          <div class="product-item-img">
            <img
              class="w-h-100"
              :src="
                item.toSubjectCoverVos[0]
                  ? item.toSubjectCoverVos[0].coverUrl
                  : require(`@/assets/images/sample-${common.getLastChar(
                      item.contentId
                    )}.png`)
              "
              alt=""
            />
          </div>
          <div class="product-item-name one-line pd-10">{{ item.toSubjectName }}</div>
          <div class="product-item-date one-line pd-10">
            最新更新：{{ Object.dayjs(item.updateAt || item.createAt) }}
          </div>
        </div>
      </div>
    </div>
    <div class="content-select-preview flex-1"></div>
  </div>
</template>

<script>
import { ProductLibrary } from "../../../../api/ProductLibrary";
export default {
  props: {
    activeColumn: Object,
    subject: Object,
    ispre: {
      type: Boolean,
      default: false,
    },
    alreadychose:Array,
    scheme: Object,
  },
  data() {
    return {
      selectids: [], //已经选择的id合集
      tableData: [],
      richText: "",
      clickitem: {},
    };
  },
  watch: {
    selectids(val) {
      const selectdata = {
        dataIds: val,
        dataType: 3,
        // id: "",
        schemeId: "",
        // sortNo: 0,
        subjectColumnId: this.activeColumn.id,
        subjectId: this.subject.id,
      };
      this.$emit("handleselect", selectdata);
    },
  },
  mounted() {
    this.getTableData();
    if(this.alreadychose.length){
      this.selectids=this.alreadychose[0].dataIds
    }else{
      this.getdefaultTableData()
    }
  },
  methods: {
    /**
     * @description:获取已选列表
     * @return {void}
     */
    async getdefaultTableData() {
      const res = await ProductLibrary.getSchemeToContentList({
        schemeId: this.scheme.id,
        subjectColumnId: this.activeColumn.id,
      });
      this.selectids=res.map(item=>item.dataId);
    },
    /**
     * @description:获取列表
     * @return {void}
     */
    async getTableData() {
      const res = await ProductLibrary.getSubjectToContentList({
        columnId: this.activeColumn.id,
        subjectId: this.subject.id,
      });
      this.tableData = res;

    },
    /**
     * @description: 预览产品
     * @param {Object} value 产品
     * @return {void}
     */
    async getDetail(value) {
      if (this.selectids.includes(value.contentId)) {
        this.selectids = this.selectids.filter((item) => item != value.contentId);
      } else {
        this.selectids.push(value.contentId);
      }
      this.clickitem = value;
    },
  },
};
</script>
<style lang="scss" scoped>
.content-select-list {
  width: 434px;
  height: 100%;
  overflow-y: auto;
  padding: 14px;
  box-sizing: border-box;
  border-right: 1px solid #ccc;
  .container {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 14px; /* 设置列之间的间隙 */
    overflow-y: auto;
    // padding: 0 16px;
    .content-item {
      width: 194px;
      height: 180px;
      border: 1px solid #cfd7e6;
      border-radius: 5px;
      .product-item-img {
        width: 100%;
        height: 113px;
      }
      .product-item-name {
        width: 100%;
        line-height: 34px;
        font-weight: 700;
        font-size: 14px;
        color: var(--color-text-primary);
      }
      .product-item-date {
        font-size: 12px;
        color: #8997b0;
        line-height: 22px;
      }
    }
    .active-item {
      border: 2px solid #8b6bff;
      position: relative;
      &::before {
        content: "";
        z-index: 2;
        position: absolute;
        top: 0;
        right: 0;
        width: 22px;
        height: 22px;
        background-image: url("@/assets/images/productLibrary/column_checked.png");
        background-size: 22px;
        background-repeat: no-repeat;
      }
    }
  }
}
.content-select-preview {
  background-color: #fff;
  padding: 10px;
  overflow-y: auto;
}
.pd-10 {
  padding: 0 10px;
  box-sizing: border-box;
}
</style>
