<template>
  <el-dialog append-to-body class="ss-dialog" title="修改密码" width="500px" :visible.sync="dialogVisible" >
    <div class="content" >
      <el-form label-width="120px" status-icon  ref="ruleForm" :model="queryData"  :rules="rules">
        <el-form-item label="原密码">
          <el-input show-password style="width: 240px" v-model.trim="queryData.password" placeholder="请输入原始密码" show-word-limit></el-input>
        </el-form-item>
        <el-form-item label="新密码" prop="pass">
          <el-input show-password style="width: 240px" v-model.trim="queryData.newPassword" placeholder="请输入新密码"></el-input>
        </el-form-item>
        <el-form-item label="请再次输入密码" prop="newPasswordRe">
          <el-input show-password style="width: 240px" v-model.trim="queryData.newPasswordRe" placeholder="请再次输入新密码"></el-input>
        </el-form-item>
      </el-form>
    </div>
    <div slot="footer">
      <el-button type="info" @click="dialogVisible = false">取 消</el-button>
      <el-button type="primary" :loading="confirmLoading" @click="handleConfirm" >确 定</el-button >
    </div>
  </el-dialog>
</template>

<script>
import { mapGetters } from "vuex";
import {Users} from '@/api/Users';
export default {
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
  },
  data() {
     var validatePass = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请输入密码'));
      } else {
        if (this.queryData.newPassword !== '') {
          this.$refs.ruleForm.validateField('newPasswordRe');
        }
        callback();
      }
    };
    var validatePass2 = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请再次输入密码'));
      } else if (value !== this.queryData.newPassword) {
        callback(new Error('两次输入密码不一致!'));
      } else {
        callback();
      }
    };
    return {
      queryData:{
        "newPassword": "",
        "newPasswordRe": "",
        "password": "",
        "userId": ""
      },
      confirmLoading:false,
      rules: {
        pass: [
          { validator: validatePass, trigger: 'blur' }
        ],
        newPasswordRe: [
          { validator: validatePass2, trigger: 'blur' }
        ]
      }
    };
  },
  computed: {
    dialogVisible: {
      get() {
        return this.visible;
      },
      set(val) {
        this.$emit("update:visible", val);
      },
    },
     ...mapGetters({ userInfo: "userInfo"})
  },
  mounted() {},
  methods: {
    /**
     * @description: 确定
     * @return {void}
     */
    async handleConfirm() {
      this.$refs.ruleForm.validate(async(valid) => {
        if (valid) {
          this.confirmLoading = true;
          this.queryData.userId=this.userInfo.id
          const res = await Users.updateUserPwd(this.queryData)
          this.confirmLoading = false;
          if(res){
            this.$router.push('/login')
            this.dialogVisible = false;
          }
        } else {
          this.$message.error('提交失败')
          return false;
        }
      });
      
    },
  }
};
</script>

<style lang="scss" scoped>
.content {
  padding: 20px 30px 0;
  max-height: 60vh;
  // overflow-y: auto;
  overflow: hidden;
  .username{
    color: var( --color-primary);
    padding: 0 5px;
  }

}
</style>