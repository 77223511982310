// import store from './../store';
import API from './apiRequest';

const server = {
  /**
   * 系统Post调用
   * @param {string} url 系统API
   * @param {object} param 参数
   * @param {boolean} showcode msg提示
   * @param {boolean} layout 加载层
   */
  post(url, param, showcode = false, layout = false, options = {}) {
    return API.Post(url, param, showcode, layout, options);
  },

  /**
   * 系统Post调用
   * @param {string} url 系统API
   * @param {object} param 参数
   */
  postFile(url, param) {
    return API.PostFile(url, param);
  },

  /**
   * 系统Get调用
   * @param {string} url 系统API
   * @param {object} param 参数
   * @param {boolean} showcode msg提示
   * @param {boolean} layout 加载层
   */
  get(url, param, showcode = false, layout = false) {
    return API.Get(url, param, showcode, layout);
  },

  /**
   * 系统Put调用
   * @param {string} url 系统API
   * @param {object} param 参数
   * @param {boolean} showcode msg提示
   * @param {boolean} layout 加载层
   */
  put(url, param, showcode = false, layout = false) {
    return API.Put(url, param, showcode, layout);
  },

  /**
   * 系统Delete调用
   * @param {string} url 系统API
   * @param {object} param 参数
   * @param {boolean} showcode msg提示
   * @param {boolean} layout 加载层
   */
  delete(url, param, showcode = false, layout = false) {
    return API.Delete(url, param, showcode, layout);
  },

  /**
   * 文件上传
   * @param {string} url 系统API
   * @param {FormData} form FormData对象
   * @param {boolean} showcode msg提示
   * @param {boolean} layout 加载层
   */
  upload(url, form, showcode = false, layout = false) {
    API.Upload(url, form, showcode, layout);
  },

  /**
   * 获取二进制文件流
   * @param {string} url 系统API
   * @param {object} param 参数
   * @param {boolean} showcode msg提示
   * @param {boolean} layout 加载层
   */
  buffer(url, param, showcode = false, layout = false) {
    return API.buffer(url, param, showcode, layout);
  },
  /**
   * 退出系统清空登录信息
   */
  // logOut() {
  //   store.commit('saveCacheList');
  //   store.commit('clearMenuTab');
  //   store.commit('saveUserInfo');
  // }
};
export default server;
