import BaseAxios from "./_BaseAxios";
// @ts-ignore
import ResHelper from "./_ResponseHelper.js";

export class ApiTable {

    private PRIFIX: string;

    constructor(private baseURL?: string) {
        if (baseURL) this.PRIFIX = '/table';
        else this.PRIFIX = '/public/table/table';
    }

    public getTableFormat(
        moduleName: string,
        tabName: string,
        reqBody: any,
    ) {
        return BaseAxios({
            baseURL: this.baseURL,
            url: `${this.PRIFIX}/getTableFormat`,
            method: "post",
            params: { moduleName, tabName },
            data: reqBody,
        }).then(ResHelper.handler);
    }

    public getTableRows(
        moduleName: string,
        tabName: string,
        reqBody: any,
    ) {
        return BaseAxios({
            baseURL: this.baseURL,
            url: `${this.PRIFIX}/getTableRows`,
            method: "post",
            params: { moduleName, tabName },
            data: reqBody,
        }).then(ResHelper.handler);
    }

    public getTableSubRows(
        moduleName: string,
        tabName: string,
        parendId: string,
        reqBody: any,
    ) {
        return BaseAxios({
            baseURL: this.baseURL,
            url: `${this.PRIFIX}/getTableSubRows`,
            method: "post",
            params: { moduleName, tabName, parendId },
            data: reqBody,
        }).then(ResHelper.handler);
    }

    public getCellSelectList(
        moduleName: string,
        tabName: string,
        reqBody: any,
    ) {
        return BaseAxios({
            baseURL: this.baseURL,
            url: `${this.PRIFIX}/getCellSelectList`,
            method: "post",
            params: { moduleName, tabName },
            data: reqBody,
        }).then(ResHelper.handler);
    }

    public updateCellSelect(
        moduleName: string,
        tabName: string,
        reqBody: any,
    ) {
        return BaseAxios({
            baseURL: this.baseURL,
            url: `${this.PRIFIX}/updateCellSelect`,
            method: "post",
            params: { moduleName, tabName },
            data: reqBody,
        }).then(ResHelper.handler);
    }

    public updateColumnOrder(
        moduleName: string,
        tabName: string,
        reqBody: any,
    ) {
        return BaseAxios({
            baseURL: this.baseURL,
            url: `${this.PRIFIX}/updateColumnOrder`,
            method: "post",
            params: { moduleName, tabName },
            data: reqBody,
        }).then(ResHelper.handler);
    }

    public updateOperationBtnsPosition(
        moduleName: string,
        tabName: string,
        reqBody: any,
    ) {
        return BaseAxios({
            baseURL: this.baseURL,
            url: `${this.PRIFIX}/updateOperationBtnsPosition`,
            method: "post",
            params: { moduleName, tabName },
            data: reqBody,
        }).then(ResHelper.handler);
    }

    public updateColumnActive(
        moduleName: string,
        tabName: string,
        reqBody: any,
    ) {
        return BaseAxios({
            baseURL: this.baseURL,
            url: `${this.PRIFIX}/updateColumnActive`,
            method: "post",
            params: { moduleName, tabName },
            data: reqBody,
        }).then(ResHelper.handler);
    }

    public updateColumnCellAlign(
        moduleName: string,
        tabName: string,
        reqBody: any,
    ) {
        return BaseAxios({
            baseURL: this.baseURL,
            url: `${this.PRIFIX}/updateColumnCellAlign`,
            method: "post",
            params: { moduleName, tabName },
            data: reqBody,
        }).then(ResHelper.handler);
    }

    public getDataStatistics(
        moduleName: string,
        tabName: string,
        reqBody: any,
    ) {
        return BaseAxios({
            baseURL: this.baseURL,
            url: `${this.PRIFIX}/getDataStatistics`,
            method: "post",
            params: { moduleName, tabName },
            data: reqBody,
        }).then(ResHelper.handler);
    }

    public updateDataStatisticsActive(
        moduleName: string,
        tabName: string,
        reqBody: any,
    ) {
        return BaseAxios({
            baseURL: this.baseURL,
            url: `${this.PRIFIX}/updateDataStatisticsActive`,
            method: "post",
            params: { moduleName, tabName },
            data: reqBody,
        }).then(ResHelper.handler);
    }

    public getDownloadDataUrl(
        moduleName: string,
        tabName: string,
        reqBody: any,
    ) {
        return BaseAxios({
            baseURL: this.baseURL,
            url: `${this.PRIFIX}/getDownloadDataUrl`,
            method: "post",
            params: { moduleName, tabName },
            data: reqBody,
        }).then(ResHelper.handler);
    }

    public getColumnFilterByColumn(
        moduleName: string,
        tabName: string,
        reqBody: any,
    ) {
        return BaseAxios({
            baseURL: this.baseURL,
            url: `${this.PRIFIX}/getColumnFilterByColumn`,
            method: "post",
            params: { moduleName, tabName },
            data: reqBody,
        }).then(ResHelper.handler);
    }

    public createOrUpdateRowCells(
        moduleName: string,
        tabName: string,
        rowId: string | null | undefined,
        reqBody: any[],
    ) {
        return BaseAxios({
            baseURL: this.baseURL,
            url: `${this.PRIFIX}/createOrUpdateRowCells`,
            method: "post",
            params: { moduleName, tabName, rowId },
            data: reqBody,
        }).then(ResHelper.handler);
    }
}