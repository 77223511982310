<template>
  <div class="selection-tag-item">
    <ElTag
      size="small"
      closable
      @close="
        () => {
          this.$emit('tag-close');
        }
      "
    >
      <slot></slot>
    </ElTag>
  </div>
</template>
<script lang="ts">
import Vue from "vue";
import { Tag as ElTag } from "element-ui";

export default Vue.extend({
  components: { ElTag },
});
</script>

<style lang="scss" scoped>
.selection-tag-item {
  display: inline-block;

  // 设置标签的文字不可选中
  .el-tag {
    user-select: none;
  }
}
</style>