<template>
  <div class="card-template" :style="{ height: `${height}px` }">
    <div class="card-template__header">
      <slot name="header"></slot>
    </div>
    <div class="card-template__body">
      <div class="card-template__pic">
        <img class="image" :src="imgSrc || DEFAULT_PIC_SRC" />
      </div>
      <div class="card-template__details">
        <slot name="details"></slot>
      </div>
    </div>
  </div>
</template>
<script lang="ts">
import Vue, { PropType } from "vue";
import { IBaseTableData } from "../entity/Table/IBaseTableData";
// @ts-ignore
import DEFAULT_PIC_SRC from "../assets/images/default-project-thumbnail.jpg";

export default Vue.extend({
  inheritAttrs: false,
  name: "CardTemplate",
  props: {
    height: { type: Number, default: 320 },
    imgSrc: { type: String },
    // row: { type: Object as PropType<IBaseTableData> }, // 表格行数据
  },
  data() {
    return { DEFAULT_PIC_SRC };
  },
});
</script>
<style lang="scss" scoped>
@import "src/flexible-table-module/scss/_variables.scss";

$header-height: 32px;

.card-template {
  box-sizing: border-box;
  width: 100%;
  padding: 0 12px;

  .card-template__header {
    width: 100%;
    height: $header-height;
    line-height: $header-height;
  }

  .card-template__body {
    width: 100%;
    height: calc(100% - $header-height);

    .card-template__pic {
      box-sizing: border-box;
      width: 100%;
      height: 60%;
      border: 1px solid $border-color;
      border-radius: 8px;
      overflow: hidden;
      background-color: $color-base;
      display: flex;
      justify-content: center;
      align-items: center;

      img {
        display: block;
        width: 100%;
      }
    }

    .card-template__details {
      width: 100%;
      height: 40%;
    }
  }
}
</style>