var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"filter-detail"},[(_vm.currFilter !== null)?[(_vm.treeData.length > 0)?[_c('SimpleTree',{ref:"simple-tree",attrs:{"isFirstLevelAlignSecondLevel":true,"nodes":_vm.treeData,"nodeKey":_vm.TREE_NODE_KEY,"props":_vm.TREE_PROPS,"isLeafCheckableOnly":_vm.currFilter === 'view',"isLeafSingleCheckable":_vm.currFilter === 'view',"isNodeClickSingleCheckable":true,"disabled":_vm.disableTree},on:{"checkbox-change":_vm.onTreeNodeCheck,"node-click":_vm.onTreeNodeClick},scopedSlots:_vm._u([{key:"default",fn:function({ node }){return [_c('div',{staticClass:"node-content",attrs:{"id":node[_vm.TREE_NODE_KEY]}},[_c('div',{staticClass:"title"},[_c('span',[_vm._v(_vm._s(node[_vm.TREE_PROPS.title]))]),(
                  node.attachment &&
                  node.attachment['dataCount'] !== undefined &&
                  node.attachment['dataCount'] !== null
                )?[_c('span',[_vm._v("("+_vm._s(node.attachment["dataCount"])+")")])]:_vm._e()],2),(
                _vm.currFilter === 'view' &&
                node.attachment &&
                node.attachment['filter']
              )?[_c('ElPopover',{staticClass:"btn-preview-popover",attrs:{"placement":"right","title":`${node[_vm.TREE_PROPS.title]} 详情`,"trigger":"click","visible-arrow":true}},[_c('IconBtn',{staticClass:"btn-preview",attrs:{"slot":"reference","iconSize":_vm.iconSize,"padding":_vm.iconPadding},on:{"btn-click":function($event){return _vm.onBtnPreviewClick(node)}},slot:"reference",scopedSlots:_vm._u([{key:"active",fn:function(){return [_c('svg-icon',{attrs:{"sys":"common-table","name":"ColumnsSetting/eye","size":_vm.iconSize,"color":'#666'}})]},proxy:true}],null,true)}),_c('MainViewTrees',{attrs:{"filters":node.attachment.filter.filters,"isCheckboxReadonly":true},on:{"tree-checkbox-change":_vm.onMainViewTreesCheckboxChange}})],1)]:_vm._e()],2)]}}],null,false,3255983992)})]:[_c('ElEmpty',{attrs:{"description":"暂无数据"}})]]:_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }