<template>
  <el-dialog
    append-to-body
    top="5vh"
    class="ss-dialog"
    width="1220px"
    :visible.sync="dialogVisible"
    :title="'预览'"
  >
    <div class="dialog-body preview-content">
      <ShowProject2
        :subject="subject"
        :ispre="true"
        :showaside="showList"
        :scheme="scheme"
      />
    </div>
  </el-dialog>
</template>
<script>
import { mapGetters } from "vuex";
import ShowProject2 from "@/views/ProdudtEdito/components/ShowProject2.vue";
export default {
  components: { ShowProject2 },
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    ispre: {
      type: Boolean,
      default: false,
    },
    subject: Object,
    scheme: Object,
  },
  data() {
    return {
      showList: false,
    };
  },
  computed: {
    dialogVisible: {
      get() {
        return this.visible;
      },
      set(val) {
        this.$emit("update:visible", val);
      },
    },
    ...mapGetters(["userInfo"]),
  },
  mounted() {},
  methods: {},
};
</script>
<style lang="scss" scoped>
.preview-content{
  width: 100%;
  height: 70vh;
  overflow: auto;
}
</style>
