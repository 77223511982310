/*
 * @Author: Billy
 * @Date: 2020-09-20 01:12:40
 * @LastEditors: Billy
 * @LastEditTime: 2023-06-08 17:28:20
 * @Description: 加密功能函数集
 */

/**
 * 生成uuid/guid
 * @see https://stackoverflow.com/questions/105034/how-to-create-guid-uuid
 * @returns {string} uuid 字符串
 */
function uuidv4(): string {
    return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
        const r = Math.random() * 16 | 0,
            v = c == "x" ? r : (r & 0x3 | 0x8);
        return v.toString(16);
    });
}

/**
 * 生成随机字符串
 * @param format 字符串格式(*为占位符)
 * @returns 
 */
function getRamdomStr(format: string = '********'): string {
    return format.replace(/[*]/g, function (c) {
        const r = Math.random() * 16 | 0,
            v = c == "*" ? r : (r & 0x3 | 0x8);
        return v.toString(16);
    });
}

export {
    uuidv4,
    getRamdomStr,
};