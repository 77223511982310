export class DataOverviewItem {
    /** 唯一标识 */
    public id: string;
    /** 显示的名称 */
    public name: string;
    /** 显示的数值 */
    public value?: number;
    /** 是否激活(界面上是否显示) */
    public active: boolean;
    /** 字体颜色 */
    public color: string;

    /** 从后端返回的数据 */
    public atachment?: any;

    constructor(params: {
        id: string,
        name: string,
        value?: number,
        active?: boolean,
        color?: string,
        atachment?: any,
    }) {
        const { id, name, value, active = true, color = '#347EFF', atachment } = params;

        this.id = id;
        this.name = name;
        this.value = value;
        this.active = active;
        this.color = color;
        this.atachment = atachment;
    }
}
