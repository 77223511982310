<template>
  <div class="scheme-edit-dialog">
    <div class="scheme-edit-main">
      <div class="preview demo w-h-100">
        <div class="w-100 app-edit-header">
          <div class="header-firstfloor flex align-items-center">
            <div class="w-h-100 flex align-items-center">
              <div class="header-title flex align-items-center top-left-name">
                <img
                  style="width: 37px; height: 22px; margin-right: 9px"
                  :src="subject.coverList[0] && subject.coverList[0].coverUrl"
                  alt=""
                />
                <div class="one-line top-name" v-if="subject">
                  {{ subject.subjectName }}
                </div>
              </div>
              <div
                class="header-columns flex align-items-center"
                id="columntabs"
                v-if="activeColumn || isfile"
              >
                <div
                  class="header-columns-fixed-left"
                  :class="{ 'header-columns-fixed-left-active': activeColumnIndex == 0 }"
                ></div>
                <div
                  v-for="(i, index) in columnList"
                  :key="i.id"
                  class="header-columns-item header-columns-item-normal"
                  :class="{
                    'header-columns-item-first': index == 0,
                    'header-columns-item-active':
                      activeColumn && i.id === activeColumn.id,
                    'header-columns-item-between-left':
                      (activeColumnIndex == 0 && index == activeColumnIndex + 1) ||
                      (activeColumnIndex && index == activeColumnIndex + 1),
                    'header-columns-item-between-right':
                      activeColumnIndex && index == activeColumnIndex - 1,
                  }"
                  @click="changeColumn(i, index)"
                >
                  <div class="child one-line" :title="i.columnName">
                    <span>{{ i.columnName }}</span>
                  </div>
                </div>
                <div
                  class="header-columns-item header-columns-item-normal exclude-sort"
                  :class="{
                    'header-columns-item-first': columnList.length == 0,
                    'header-columns-item-active': isfile,
                    'header-columns-item-between-left':
                      !isfile &&
                      activeColumnIndex >= 0 &&
                      columnList.length == activeColumnIndex + 1,
                    'header-columns-item-between-right':
                      !isfile &&
                      activeColumnIndex >= 0 &&
                      columnList.length == activeColumnIndex - 1,
                  }"
                  @click="handleFile"
                >
                  <div
                    class="child one-line"
                    :title="subject ? subject.moduleName + '文件' : '文件'"
                  >
                    {{ subject ? subject.moduleName : "" }}文件
                  </div>
                </div>
                <div
                  class="header-columns-fixed-left"
                  :class="{
                    'header-columns-fixed-right-active':
                      activeColumnIndex == columnList.length,
                  }"
                ></div>
              </div>
            </div>
            <div class="fixed-oprate flex-between align-items-center">
              <div class="preview-right-button flex flex-between">
                <div
                  type="primary"
                  :disabled="columnList.length === 0"
                  v-loading="confirmLoading"
                  element-loading-background="rgba(255,255,255,0.3)"
                  @click="dialogVisible = false"
                >
                  取消
                </div>
                <div
                  type="primary"
                  :disabled="columnList.length === 0"
                  v-loading="confirmLoading"
                  element-loading-background="rgba(255,255,255,0.3)"
                  @click="handleSave"
                >
                  保存
                </div>
              </div>
            </div>
          </div>
          <div class="header-interlayer"></div>
        </div>
        <!-- </div> -->
        <div class="preview-main">
          <!-- 栏目详情 -->
          <ArticleSelect
            v-if="
              activeColumn &&
              columnCategoryTypeDict[activeColumn.columnCategoryId] === '5'
            "
            :active-column="activeColumn"
            :subject="subject"
            @handleselect="handleselect"
            :alreadychose="
              queryData.filter((item) => item.subjectColumnId == activeColumn.id)
            "
            :scheme="scheme"
          />
          <LinkSelect
            v-if="
              activeColumn &&
              columnCategoryTypeDict[activeColumn.columnCategoryId] === '4'
            "
            :active-column="activeColumn"
            :subject="subject"
            @handleselect="handleselect"
            :alreadychose="
              queryData.filter((item) => item.subjectColumnId == activeColumn.id)
            "
            :scheme="scheme"
          />
          <ContentSelect
            v-if="
              activeColumn &&
              columnCategoryTypeDict[activeColumn.columnCategoryId] === '1'
            "
            :active-column="activeColumn"
            :subject="subject"
            @handleselect="handleselect"
            :alreadychose="
              queryData.filter((item) => item.subjectColumnId == activeColumn.id)
            "
            :scheme="scheme"
          />
          <ProductSelect
            v-if="
              activeColumn &&
              columnCategoryTypeDict[activeColumn.columnCategoryId] === '3'
            "
            :active-column="activeColumn"
            :subject="subject"
            @handleselect="handleselect"
            :alreadychose="
              queryData.filter((item) => item.subjectColumnId == activeColumn.id)
            "
            :scheme="scheme"
          />
          <FileSelect
            v-if="isfile && !activeColumn"
            :subject="subject"
            @handleselect="handleselect"
            @handlesavetemporaryselect="handleSavetemporaryselect"
            :alreadychose="queryData.filter((item) => item && item.dataType == 2)"
            :scheme="scheme"
            :temporaryfile="temporaryfile"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import ArticleSelect from "../components/scheme/ArticleSelect.vue";
import LinkSelect from "../components/scheme/LinkSelect.vue";
import ContentSelect from "../components/scheme/ContentSelect.vue";
import FileSelect from "../components/scheme/FileSelect.vue";
import ProductSelect from "../components/scheme/ProductSelect.vue";
import { ProductLibrary } from "@/api/ProductLibrary";
export default {
  components: { ArticleSelect, LinkSelect, ContentSelect, FileSelect, ProductSelect },
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    subject: Object,
    scheme: Object,
  },
  data() {
    return {
      tableData: [],
      columnList: [],
      activeColumn: null,
      activeColumnIndex: 0,
      confirmLoading: false,
      isfile: false,
      queryData: [],
      temporaryfile: [],
    };
  },
  computed: {
    dialogVisible: {
      get() {
        return this.visible;
      },
      set(val) {
        this.$emit("update:visible", val);
      },
    },
    ...mapGetters(["token", "userInfo", "hasNopowerList", "columnCategoryTypeDict"]),
  },
  mounted() {
    this.columnList = _.sortBy(this.subject.subjectColumnVos, "sortNo");
    // 过滤文件栏目
    this.columnList = this.columnList.filter(
      (item) => this.columnCategoryTypeDict[item.columnCategoryId] != "2"
    );
    if (this.columnList.length) {
      this.activeColumn = this.columnList[0];
    } else {
      this.handleFile();
    }
  },
  methods: {
    /**
     * @description: 切换栏目
     * @return {void}
     */
    changeColumn(value, index) {
      this.activeColumnIndex = index;
      this.isfile = false;
      if (this.activeColumn && this.activeColumn.id === value.id) {
        return;
      }
      this.activeColumn = value;
    },
    handleFile() {
      this.activeColumnIndex = this.columnList.length;
      this.activeColumn = null;
      this.isfile = true;
    },
    handleClose() {
      this.dialogVisible = false;
    },
    /**
     * @description: 获取每个栏目选择的数据
     * @return {void}
     */
    handleselect(val) {
      this.queryData = this.updateDataArray(this.queryData, val);
    },
    /**
     * @description: 暂存一下文件的选择数据详情
     * @return {void}
     */
    handleSavetemporaryselect(val) {
      this.temporaryfile = val;
    },
    /**
     * @description: 数据筛选
     * @return {void}
     */
    updateDataArray(dataArray, newData) {
      const foundItem = dataArray.filter(
        (item) => item.subjectColumnId === newData.subjectColumnId
      )[0];
      if (foundItem) {
        Object.assign(foundItem, newData);
      } else {
        dataArray.push(newData);
      }
      return dataArray;
    },
    async handleSave() {
      if (this.queryData.length) {
        this.queryData.forEach((item) => {
          item.schemeId = this.scheme.id;
        });
        const res = await ProductLibrary.addrelateBatch(this.queryData);
        if (res) {
          this.$message.success("成功");
          this.$emit("finish");
          this.dialogVisible = false;
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@/scss/demo.scss";
@import "~@/scss/common.scss";
.scheme-edit-dialog {
  height: 100%;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 12;
  background-color: rgba(40, 47, 57, 0.8);
  padding: 28px 42px;
  color: var(--color-text-primary);
  box-sizing: border-box;
}

.scheme-edit-main {
  height: 100%;
  width: 100%;
  background-color: #fff;
  overflow: hidden;
  border-radius: 10px;
  .top-left-name {
    padding-left: 9px;
    box-sizing: border-box;
    display: flex;
    color: #fff;

    .top-name {
      max-width: 250px;
      margin-right: 26px;
    }
  }
  .preview-right-button {
    div {
      padding: 0 12px;
      font-weight: 500;
      cursor: pointer;
    }
    :nth-child(1) {
      color: rgba(255, 255, 255, 0.5);
      &:hover {
        color: #fff;
      }
    }
  }
}

.preview-main {
  height: calc(100% - 46px);
}
</style>
