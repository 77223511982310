import request from "@/utils/appServer";
import common from "../utils/common"
class Article {
  // 添加文章
  static async addArticle(data) {
    try {
      const res = await request.post('/sales-show-4.0/article/addArticle', data);
      return res.data.code === 200 ? res.data.result : false;
    } catch (error) {
      return false;
    }
  }

  // 添加空白文章
  static async addblankArticle(id) {
    const data = {
      articleName: "未命名",
      coverRemark: "",
      templateType: 1, //模板(1.左右栏，2.通栏)
      detailContent: "",
      moduleId: id,
      introduce: "",
    }
    try {
      const res = await request.post('/sales-show-4.0/article/addArticle', data);
      return res.data.code === 200 ? res.data.result : false;
    } catch (error) {
      return false;
    }
  }

  // 删除文章
  static async deleteArticle(articleIds) {
    try {
      const res = await request.delete('/sales-show-4.0/article/deleteArticle', { params: { articleIds } });
      return res.data.code === 200;
    } catch (error) {
      return false;
    }
  }

  // 编辑文章
  static async editArticle(data) {
    try {
      const res = await request.post('/sales-show-4.0/article/editArticle', data);
      return res.data.code === 200 ? res.data.result : false;
    } catch (error) {
      return false;
    }
  }

  // 编辑文章其他信息
  static async editArticleOtherInfo(data) {
    try {
      const res = await request.post('/sales-show-4.0/article/editArticleOtherInfo', data);
      return res.data.code === 200 ? res.data.result : false;
    } catch (error) {
      return false;
    }
  }

  // 编辑文章SEO
  static async editArticleSEO(data) {
    try {
      const res = await request.post('/sales-show-4.0/article/editArticleSEO', data, true);
      return res.data.code === 200 ? res.data.result : false;
    } catch (error) {
      return false;
    }
  }

  // 提取网页描述
  static async extractWebDesc(articleId) {
    try {
      const res = await request.get('/sales-show-4.0/article/extractWebDesc', { params: { articleId } });
      return res.data.result;
    } catch (error) {
      return "";
    }
  }

  // 提取网页关键字
  static async extractWebKeywords(articleId) {
    try {
      const res = await request.get('/sales-show-4.0/article/extractWebKeywords', { params: { articleId } });
      return res.data.result;
    } catch (error) {
      return "";
    }
  }

  // 提取网页标题
  static async extractWebTitle(articleId) {
    try {
      const res = await request.get('/sales-show-4.0/article/extractWebTitle', { params: { articleId } });
      return res.data.result;
    } catch (error) {
      return "";
    }
  }

  // 获取文章详情
  static async queryArticleDetail(articleId) {
    try {
      const res = await request.get('/sales-show-4.0/article/queryArticleDetail', { params: { articleId } });
      return res.data.result;
    } catch (error) {
      return false;
    }
  }

  // 获取文章详情(非登录)
  static async queryArticleDetailNoLogin(data) {
    try {
      const res = await request.get('/sales-show-4.0/article/queryArticleDetailNoLogin', { params: data });
      return res.data.result;
    } catch (error) {
      return false;
    }
  }

  // 获取文章分页列表
  static async queryArticlePageList(data) {
    try {
      const res = await request.post('/sales-show-4.0/article/queryArticlePageList', data);
      return res.data.code === 200 && res.data.result ? res.data.result : new common.Pagination();
    } catch (error) {
      return new common.Pagination();
    }
  }

  // 获取文章分页列表(后台管理用)
  static async queryArticleBackPageList(data) {
    try {
      const res = await request.post('/sales-show-4.0/article/queryArticlePageList', data);
      if (res.data.code === 200 && res.data.result) {
        res.data.result.records.forEach(element => {
          element.name = element.articleName;
          element.categoryIds = element.subjectCategories.map(item => item.id);
        });
      }
      return res.data.code === 200 && res.data.result ? res.data.result : new common.Pagination();
    } catch (error) {
      return new common.Pagination();
    }
  }

  // 获取文章推送列表
  static async queryToPublishList() {
    try {
      const res = await request.get('/sales-show-4.0/article/queryToPublishList');
      return res.data.code === 200 && res.data.result ? res.data.result : [];
    } catch (error) {
      return [];
    }
  }

  // 文章推送
  static async saveArticlePublish(data) {
    try {
      const res = await request.get('/sales-show-4.0/article/saveArticlePublish', { params: data });
      return res.data.code === 200 && res.data.success;
    } catch (error) {
      return false;
    }
  }

  // 保存文章关联文章
  static async saveArticleToArticle(data) {
    try {
      const res = await request.post('/sales-show-4.0/article/saveArticleToArticle', data);
      return res.data.code === 200 ? res.data.result : false;
    } catch (error) {
      return false;
    }
  }

  // 保存文章关联产品
  static async saveArticleToSubject(data) {
    try {
      const res = await request.post('/sales-show-4.0/article/saveArticleToSubject', data);
      return res.data.code === 200 ? res.data.result : false;
    } catch (error) {
      return false;
    }
  }

  // 保存文章分类
  static async saveArticleCategory(data) {
    try {
      const res = await request.post('/sales-show-4.0/article/saveArticleCategory', data, true);
      return res.data.code === 200;
    } catch (error) {
      return false;
    }
  }

  // 获取文章分类列表
  static async queryArticleCategoryList(data) {
    try {
      const res = await request.get('/sales-show-4.0/subject/category/queryArticleCategoryList', { params: data });
      return res.data.code === 200 && res.data.result ? res.data.result : [];
    } catch (error) {
      return [];
    }
  }

  // 获取文章列表数量
  static async getArticleListNumber(data) {
    try {
      const res = await request.post('/sales-show-4.0/article/queryArticlePageList', data);
      return res.data.code === 200 && res.data.result ? res.data.result.total : 0;
    } catch (error) {
      return 0;
    }
  }

}

export { Article }