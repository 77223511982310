/*
 * @Author: CiFong
 * @Date: 2020-06-16 18:20:21
 * @LastEditors: Billy
 * @LastEditTime: 2020-10-08 12:43:01
 * @Description: 请输入
 */

/**
 * @description
 *  将 Date 转化为指定格式的String
 *  月(M)、日(d)、小时(h)、分(m)、秒(s)、季度(q) 可以用 1-2 个占位符，
 *  年(y)可以用 1-4 个占位符，毫秒(S)只能用 1 个占位符(是 1-3 位的数字)
 * @param {Date} date 要格式化的日期时间对象
 * @param {String} fmt 描述格式的字符串
 * @returns {String} 格式化后的日期字符串
 * @see https://www.haorooms.com/post/js_date_format_func
 * @example
 *  (new Date()).Format("yyyy-MM-dd hh:mm:ss.S") ==> 2006-07-02 08:09:04.423
 *  (new Date()).Format("yyyy-M-d h:m:s.S")      ==> 2006-7-2 8:9:4.18
 */
export function format(date: Date, fmt: string): string {
  const o: { [key: string]: any } = {
    'M+': date.getMonth() + 1, //月份
    'd+': date.getDate(), //日
    'h+': date.getHours(), //小时
    'H+': date.getHours(), //小时
    'm+': date.getMinutes(), //分
    's+': date.getSeconds(), //秒
    'q+': Math.floor((date.getMonth() + 3) / 3), //季度
    'S': date.getMilliseconds() //毫秒
  }

  const yearPattern = /(y+)/;
  const yearMatch = yearPattern.exec(fmt);
  if (yearMatch) {
    fmt = fmt.replace(yearMatch[0], `${date.getFullYear()}`.substr(4 - yearMatch[0].length))
  }

  for (const k in o) {
    const pattern = new RegExp(`(${k})`);
    const match = pattern.exec(fmt);
    if (match) {
      fmt = fmt.replace(match[0], match[0].length == 1 ? o[k] : (`00${o[k]}`).substr(`${o[k]}`.length))
    }
  }

  return fmt;
}

export default {
  format,
}