<template>
  <div
    class="text-searcher"
    :style="{ width }"
    v-click-outside="onClickOutside"
  >
    <ElButton
      v-if="!isInputVisible"
      class="text-searcher__button-visible"
      type="plain"
      :size="size"
      icon="el-icon-search"
      @click="onBtnVisibleClick"
    >
    </ElButton>

    <template v-else>
      <ElInput
        class="text-searcher__input"
        type="text"
        ref="input"
        v-model.trim="dValue"
        :size="size"
        :placeholder="placeholder"
        :clearable="true"
        @clear="onClear"
        @keyup.enter.native="onEnterPressed"
      >
      </ElInput>
      <ElButton
        class="text-searcher__button-submit"
        type="primary"
        :size="size"
        icon="el-icon-search"
        @click="onBtnSubmitClick"
      >
      </ElButton>
    </template>
  </div>
</template>

<script lang="ts">
import Vue, { PropType } from "vue";
import { Button as ElButton, Input as ElInput } from "element-ui";
// @ts-ignore
import vClickOutside from "v-click-outside";

type SizeType = "large" | "medium" | "small" | "mini";

export default Vue.extend({
  inheritAttrs: false,
  name: "TextSearcher",
  components: { ElButton, ElInput },
  directives: {
    clickOutside: vClickOutside.directive,
  },
  props: {
    value: { type: String, default: "" },
    size: { type: String as PropType<SizeType>, default: "small" }, // 输入框的大小 large / medium / small / mini
    placeholder: { type: String, default: "搜索关键词" },
    maxWidth: { type: Number, default: 200 }, // 整个组件的最大宽度
  },
  data(): {
    dValue: string;
    lastValueSubmit: string;
    isInputVisible: boolean;
  } {
    return {
      dValue: this.value,
      lastValueSubmit: this.value,
      isInputVisible: false, // 是否显示input
    };
  },
  computed: {
    width(): string | undefined {
      return this.isInputVisible ? `${this.maxWidth}px` : undefined;
    },
  },
  methods: {
    toSearch() {
      if (this.dValue !== this.lastValueSubmit) {
        this.$emit("update:value", this.dValue);
        this.$emit("text-search", this.dValue);
        this.lastValueSubmit = this.dValue;
      }
    },

    onBtnSubmitClick() {
      this.toSearch();
    },

    onEnterPressed() {
      this.toSearch();
    },

    onClear() {
      this.toSearch();
    },

    async onBtnVisibleClick() {
      this.isInputVisible = true;

      this.dValue = this.lastValueSubmit;

      await this.$nextTick();
      (this.$refs["input"] as any)?.focus();
    },

    onClickOutside() {
      if (!this.dValue) {
        this.isInputVisible = false;
      }
    },
  },
});
</script>

<style lang="scss" scoped>
.text-searcher {
  flex-shrink: 0;

  display: flex;
  justify-content: flex-start;
  align-items: center;

  .text-searcher__button-visible {
  }

  .text-searcher__input {
    margin-right: 4px;
  }

  .text-searcher__button-submit {
  }
}
</style>