<template>
  <svg class="svg-icon" :style="{
    fontSize: size + 'px',
    width: width + 'px',
    height: height + 'px',
    color: color
  }">
    <use :xlink:href="iconName"></use>
  </svg>
</template>

<script>
export default {
  name: 'SvgIcon',
  props: {
    icon: {
      //svg图标名称
      type: String,
      required: true
    },
    color: {
      type: String,
      default: '#8997b0'
    },
    size: {
      //svg尺寸
      type: [Number, String],
    },
    width: {
      //svg宽度
      type: [Number, String],
      default: 20
    },
    height: {
      //svg高度
      type: [Number, String],
      default: 20
    }
  },
  data() {
    return {}
  },
  computed: {
    iconName() {
      return `#icon-${this.icon}`
    }
  }
}
</script>

<style lang="scss" scoped>
.svg-icon {
  display: block;
  fill: currentColor;
  overflow: hidden;
}
</style>