<template>
  <el-dialog
    append-to-body
    class="ss-dialog"
    title="添加成员"
    top="188px"
    width="840px"
    :close-on-click-modal="false"
    :visible.sync="dialogVisible"
  >
    <div class="content">
      <div class="content-left">
        <el-input
          v-model="queryData.keyWord"
          class="tree-input"
          placeholder="姓名、企业名称..."
          prefix-icon="el-icon-search"
          @keyup.enter.native="getTableData(true)"
        ></el-input>
        <div class="tree-name">
          <div class="tree-name__line"></div>
          <div class="tree-name__label">企业组织架构</div>
        </div>
        <div class="tree-container">
          <el-tree
            highlight-current
            class="tree"
            :data="categoryTree"
            :props="defaultProps"
            @node-click="handleNodeClick"
            ref="tree"
            node-key="id"
            :expand-on-click-node="false"
          ></el-tree>
        </div>
      </div>
      <div class="content-mid">
        <div class="content-mid-top">
          <span>成员列表</span>
          <div
            class="content-mid-top-check"
            :class="{ 'is-checked': isChecked }"
            @click="checkAll"
          >
            <div class="content-mid-top-check__icon"></div>
            <div class="content-mid-top-check__label">全选</div>
          </div>
        </div>
        <div class="content-mid-member">
          <div
            class="content-mid-member-item"
            v-for="(i, index) in friendList"
            :class="isSelected(i) ? 'is-active' : ''"
            :key="i.id2"
            @click="chosefriend(i, index)"
          >
            {{ i.nickName }}
          </div>
        </div>
      </div>
      <div class="content-right">
        <div style="color: #8997b0">选中人员/部门</div>
        <div
          class="content-right-item"
          v-for="(i, index) in addedFriends"
          :key="i.id2"
          @click="removeFriend(i, index)"
        >
          <div class="content-right-item__label">{{ i.nickName }}</div>
          <div class="content-right-item__icon"></div>
        </div>
      </div>
    </div>
    <span slot="footer">
      <div class="custom-footer">
        <span class="summary">已选择 {{ addedFriends.length }} 个成员</span>
        <div>
          <el-button class="custom-footer-btn" @click="dialogVisible = false"
            >取消</el-button
          >
          <el-button class="custom-footer-btn" type="primary" @click="handleConfirm"
            >确定</el-button
          >
        </div>
      </div>
    </span>
  </el-dialog>
</template>

<script>
import store from "@/store";
import { Contacts } from "@/api/Contacts";
import { Management } from "@/api/Management";
export default {
  name: "share",
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    userlist: Array,
    type: String,
    selectuser: Array, //添加成员已选的成员
  },
  data() {
    return {
      currentCategory: null,
      tableData: new this.common.Pagination(),
      categoryTree: [],
      queryData: {
        departId: "",
        isChild: true,
        keyWord: "",
        pageNo: 1,
        pageSize: 99,
        // "sortAsc": "",
        sortDesc: "update_at",
      },
      name: "",
      defaultProps: {
        children: "children",
        label: "name",
      },
      isChecked: false, // 全选按钮的状态
      friendList: [], // 选中的好友
      addedFriends: [], // 已添加的好友
    };
  },
  created() {
    this.getTree();
    if (this.userlist) {
      this.userlist.forEach((item) => {
        this.addedFriends.push({
          id: item.id,
          nickName: item.realname,
          isChecked: false,
        });
      });
    }
    if (this.selectuser) {
      this.selectuser.forEach((item) => {
        this.addedFriends.push({
          id: item.id,
          nickName: item.nickName,
          isChecked: false,
        });
      });
    }
  },
  watch: {
    addedFriends(val) {
      this.isChecked =
        this.friendList.length &&
        this.friendList.every((friend) => this.isSelected(friend));
    },
  },
  computed: {
    dialogVisible: {
      get() {
        return this.visible;
      },
      set(val) {
        this.$emit("update:visible", val);
      },
    },
  },
  methods: {
    handleNodeClick(data) {
      this.queryData.departId = data.id;
      this.getTableData();
    },
    /**
     * @description: 选择好友
     * @param {value}:下标
     * @return {void}
     */
    chosefriend(friend, index) {
      if (this.isSelected(friend)) {
        this.removeFriend(friend);
      } else {
        this.addFriend(friend);
      }
    },

    /**
     * @description: 获取分类树
     * @return {void}
     */
    async getTree() {
      this.categoryTree = await Management.departTreeByTenantId({
        tenantId: store.state.local.userInfo.tenantIdNow,
      });
      this.$nextTick(() => {
        if (this.currentCategory) {
          this.$refs.tree.setCurrentKey(this.currentCategory.id);
        } else {
          if (this.categoryTree.length) {
            this.currentCategory = this.categoryTree[0];
            this.$refs.tree.setCurrentKey(this.currentCategory.id);
            this.queryData.departId = this.currentCategory.id;
            this.getTableData();
          }
        }
      });
    },
    /**
     * @description: 获取成员列表
     * @return {void}
     */
    async getTableData(issearch = false) {
      issearch ? (this.queryData.isChild = true) : (this.queryData.isChild = false);
      if (issearch) {
        this.queryData.isChild = true;
        this.currentCategory = this.categoryTree[0];
        this.queryData.departId = this.categoryTree[0].id;
      }
      let res = await Management.queryUserPageByDepartId(this.queryData);
      this.tableData = [];
      res.records.forEach((item) => {
        this.tableData.push({
          id: item.id,
          nickName: item.realname,
          isChecked: false,
          // id2: item.id
        });
      });
      this.friendList = _.cloneDeep(this.tableData);
      this.isChecked =
        this.friendList.length &&
        this.friendList.every((friend) => this.isSelected(friend));
    },
    // 是否选中
    isSelected(friend) {
      return this.addedFriends.some(
        (obj) => JSON.stringify(obj) === JSON.stringify(friend)
      );
    },
    /**
     * @description: 添加选择成员
     * @return {void}
     */
    addFriend(friend) {
      this.addedFriends.push(friend);
    },
    /**
     * @description: 删除成员
     * @return {void}
     */
    removeFriend(friend) {
      this.addedFriends = this.addedFriends.filter(
        (f) => JSON.stringify(f) !== JSON.stringify(friend)
      );
    },
    /**
     * @description: 确定
     * @return {void}
     */
    async handleConfirm() {
      // this.$emit('getselectman',this.addedFriends)
      if (this.type == "data") {
        this.$emit("getdatapowerman", this.addedFriends);
      } else {
        this.$emit("getselectman", this.addedFriends);
      }
      this.dialogVisible = false;
    },
    /**
     * @description: 全选
     * @return {void}
     */
    checkAll() {
      this.isChecked = !this.isChecked;
      if (this.isChecked) {
        this.friendList.forEach((friend) => {
          if (!this.isSelected(friend)) {
            this.addFriend(friend);
          }
        });
      } else {
        this.friendList.forEach((friend) => {
          if (this.isSelected(friend)) {
            this.removeFriend(friend);
          }
        });
      }
    },
  },
  /**
   * @description: 判断是否全选
   * @param {Array} array 数组
   * @param {Map} map 已选数据 MAP
   * @return {Boolean} 是否全选
   */
  isSelectAll(array, map) {
    if (array.length === 0) {
      return false;
    }
    for (let index = 0; index < array.length; index++) {
      const element = array[index];
      if (!map.has(element.id)) {
        return false;
      }
    }
    return true;
  },
  /**
   * @description: 搜索
   * @return {void}
   */
  handleSearch() {
    this.queryData.isChild = true;
    this.getTableData();
  },
};
</script>

<style lang="scss" scoped>
.friends-title {
  width: fit-content;
  height: 24px;
  cursor: pointer;

  .friends-title__icon {
    width: 16px;
    height: 16px;
    vertical-align: middle;
  }

  .friends-title__label {
    margin-left: 5px;
    font-size: 14px;
    vertical-align: middle;
  }
}

.content {
  display: flex;

  .content-left {
    width: 322px;
    height: 370px;
    padding: 16px 22px 16px 40px;
    box-sizing: border-box;
    // box-shadow: 2px 0px 6px 0px rgba(52, 69, 99, 0.08);
    border-right: 1px solid #e6ebf2;
    display: flex;
    flex-direction: column;

    .tree-input {
      width: 240px;

      ::v-deep .el-input__inner {
        height: 42px;
        line-height: 42px;
        border: none;
        border-radius: 10px;
        background: #f5f7fa;
      }
    }

    .tree-name {
      padding: 20px 0 8px;
      display: flex;
      align-items: center;

      .tree-name__line {
        width: 2px;
        height: 14px;
        background: #abb4c4;
      }

      .tree-name__label {
        font-size: 14px;
        font-weight: 500;
        color: #344563;
        margin-left: 5px;
      }
    }

    .tree-container {
      flex: 1;
      overflow: auto;

      .tree {
        color: #344563 !important;

        ::v-deep .el-tree-node__content {
          height: 43px;
          border-radius: 5px;

          &:hover {
            background-color: #fdfdfe;
            color: var(--color-primary) !important;
          }
        }

        ::v-deep .is-current > .el-tree-node__content {
          background-color: var(--color-primary);
          color: #fff;
          &:hover .el-tree-node__label {
            color: #fff;
          }
        }
      }
    }
  }

  .content-mid {
    box-sizing: border-box;
    width: 360px;
    padding: 20px 0 20px 20px;
    border-right: 1px solid #e6ebf2;
    height: 370px;
    overflow-y: auto;
    .content-mid-top {
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-size: 14px;
      color: #8997b0;
      margin-left: 5px;
      margin-right: 26px;
      margin-bottom: 20px;

      .content-mid-top-check {
        display: flex;
        align-items: center;
        cursor: pointer;

        .content-mid-top-check__icon {
          width: 18px;
          height: 18px;
          background-image: url("@/assets/images/common/success.png");
          background-size: 18px 18px;
          background-repeat: no-repeat;
        }

        .content-mid-top-check__label {
          margin-left: 6px;
          user-select: none;
        }

        &.is-checked {
          color: #344563;

          .content-mid-top-check__icon {
            background-image: url("@/assets/images/common/success_colored.png");
          }
        }
      }
    }

    .content-mid-member {
      display: flex;
      flex-wrap: wrap;
      align-content: flex-start;

      .content-mid-member-item {
        width: fit-content;
        height: 30px;
        line-height: 30px;
        padding: 0 15px;
        margin: 0 5px 10px 5px;
        border-radius: 5px;
        background-color: #fff;
        border: 1px solid #e6ebf2;
        position: relative;
        cursor: pointer;

        &:hover {
          background-color: #fcfdfe;
        }

        &.is-active {
          background-color: #d8f2f0;
          border-color: #00baad;

          &::before {
            position: absolute;
            top: 0;
            right: 0;
            content: "";
            width: 11px;
            height: 8px;
            background-image: url("@/assets/images/common/check_tag.png");
            background-size: 11px 8px;
            background-repeat: no-repeat;
          }
        }
      }
    }
  }

  .content-right {
    padding: 20px 0 20px 20px;
    width: 243px;
    box-sizing: border-box;
    height: 370px;
    overflow-y: auto;
    .content-right-item {
      width: fit-content;
      box-sizing: border-box;
      height: 32px;
      padding: 0 8px 0 16px;
      border-radius: 5px;
      background: #f5f7fa;
      display: flex;
      align-items: center;
      background-color: #f5f7fa;
      color: var(--color-primary);
      margin-top: 18px;
      cursor: pointer;

      &:hover {
        .content-right-item__icon {
          background-image: url("@/assets/images/common/close_circle_purple.png");
        }
      }

      & + .content-right-item {
        margin-top: 18px;
      }

      .content-right-item__label {
        height: 32px;
        line-height: 32px;
        font-size: 14px;
        font-weight: 500;
        padding-right: 8px;
        max-width: 74px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .content-right-item__icon {
        width: 16px;
        height: 16px;
        background-image: url("@/assets/images/common/close_purple.png");
        background-size: 16px 16px;
        background-repeat: no-repeat;
      }
    }
  }
}

.custom-footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  .summary {
    font-size: 14px;
    color: #8997b0;
    font-weight: 500;
  }

  .custom-footer-btn {
    width: 112px;
  }
}
</style>
