<template>
  <div class="link-select flex w-h-100">
    <div class="link-list">
      <div v-for="item in linklist" class="link-list-items-wrapper" :key="item.id">
        <el-checkbox v-model="checkedItems" :label="item.id">
          <div class="link-list-items">
            <div class="label one-line">{{ item.name }}</div>
            <div class="items-detail">
              <div
                class="url"
                :class="{ active: activelink && activelink.id == item.id }"
                @click.prevent="handleClick(item)"
              >
                {{ item.demoLink }}
              </div>
              <div class="username">
                <span
                  >账号:
                  <span v-if="!item.loginAccount">无</span>
                  <span v-else>{{ item.loginAccount }}</span>
                </span>
                <svg-icon
                  v-if="item.loginAccount"
                  icon="copy"
                  width="16"
                  height="16"
                  :color="'#fff'"
                  @click.native.prevent="handleCopy(item.loginAccount)"
                ></svg-icon>
              </div>
              <div class="username">
                <span
                  >密码:
                  <span v-if="!item.loginPassword">无</span>
                  <span v-if="item.loginPassword">{{ item.loginPassword }}</span>
                </span>
                <svg-icon
                  v-if="item.loginPassword"
                  icon="copy"
                  width="16"
                  height="16"
                  :color="'#fff'"
                  @click.native.prevent="handleCopy(item.loginPassword)"
                ></svg-icon>
              </div>
            </div>
          </div>
        </el-checkbox>
      </div>
    </div>
    <div class="netweb">
      <iframe v-if="activeurl" class="w-h-100" :src="activeurl" frameborder="0"></iframe>
      <el-empty v-else :image-size="200"></el-empty>
    </div>
  </div>
</template>

<script>
import { ProductLibrary } from "../../../../api/ProductLibrary";
import UpanddownAround from "../../../ArticleView/template/UpanddownAround.vue";
import LayoutAround from "../../../ArticleView/template/LayoutAround.vue";
export default {
  components: { UpanddownAround, LayoutAround },
  props: {
    activeColumn: Object,
    subject: Object,
    alreadychose:Array,
    ispre: {
      type: Boolean,
      default: false,
    },
    scheme: Object,
  },
  data() {
    return {
      queryData: {
        pageNo: 1,
        pageSize: 99,
        subjectColumnId: "",
        subjectId: "",
        name: "",
      },
      linklist: [],
      checkedItems: [],
      activeurl: "",
      activelink: null,
    };
  },
  watch: {
    checkedItems(val) {
      const selectdata = {
        dataIds: val,
        dataType: 4,
        schemeId: "",
        subjectColumnId: this.activeColumn.id,
        subjectId: this.subject.id,
      };
      this.$emit("handleselect", selectdata);
    },
    activeColumn() {
      this.queryData.subjectColumnId = this.activeColumn.id;
      this.queryData.subjectId = this.subject.id;
      this.getList();
    },
  },
  mounted() {
    this.queryData.subjectColumnId = this.activeColumn.id;
    this.queryData.subjectId = this.subject.id;
    this.getList();
    if(this.alreadychose.length){
      this.checkedItems=this.alreadychose[0].dataIds
    }else{
      this.gedefaulttList()
    }
  },
  methods: {
    /**
     * @description:获取已选链接列表
     * @return {void}
     */
    async gedefaulttList() {
      const res = await ProductLibrary.getSchemeToLinkList({
        schemeId: this.scheme.id,
        subjectColumnId: this.activeColumn.id,
      });
      if(res.length){
        this.checkedItems=res.map(item=>item.dataId)
      }
    },
    /**
     * @description:获取链接列表
     * @return {void}
     */
    async getList() {
      const res = await ProductLibrary.queryLinkPageList(this.queryData);
      this.linklist = res.records;
      if (this.linklist.length) {
        this.handleClick(this.linklist[0]);
      }
    },
    /**
     * @description: 复制
     * @param {String} text 复制项
     * @return {void}
     */
    handleCopy(text) {
      const res = this.common.handleCopy(text);
      res && this.$message.success("复制成功");
    },
    /**
     * @description:预览
     * @param {Object} value 点击项
     * @return {void}
     */
    handleClick(value) {
      if (this.activelink && this.activelink.id == value.id) {
        return;
      }
      this.activelink = value;
      this.activeurl = `${value.demoLink.startsWith("http") ? "" : "//"}${
        value.demoLink
      }${value.loginAccount || value.loginPassword ? "?" : ""}${
        value.loginAccount ? `account=${value.loginAccount}` : ""
      }${value.loginPassword ? `&password=${value.loginPassword}` : ""}`;
    },
  },
};
</script>
<style lang="scss" scoped>
.link-select {
  .link-list {
    width: 434px;
    height: 100%;
    overflow-y: auto;
    padding: 0 20px 0 28px;
    box-sizing: border-box;
    border-right: 1px solid #ccc;
    .link-list-items-wrapper {
      overflow: hidden;
      padding-top: 20px;
      height: 150px;
      box-sizing: border-box;
      border-bottom: 1px solid #ebeef2;
      ::v-deep .el-checkbox__input {
        vertical-align: top;
      }
    }
    .link-list-items {
      margin-top: 10px;
      // height: 150px;
      border-bottom: 1px solid #ebeef2;
      position: relative;

      .label {
        width: 350px;
        font-weight: 700;
        position: absolute;
        top: -16px;
      }

      .items-detail {
        position: absolute;
        top: 10px;
        line-height: 28px;
      }
      .url {
        color: var(--color-text-primary);
      }
      .active {
        color: var(--color-primary);
      }
      .username {
        color: var(--color-text-primary);
        display: flex;
        align-items: center;
      }
    }
  }
  .netweb {
    flex: 1;
  }
}
</style>
