/** 统计数据项 */
export class DataStatistic {
    /** 统计数据项 id */
    public id: string;
    /** 统计数据项的标题 */
    public title: string;
    /** 统计数据项的数值(当 active 为 false 时，本值为性能考虑可以为 null) */
    public value: string;
    /** 是否激活 */
    public active: boolean;

    constructor(params: {
        id: string;
        title: string;
        value: string;
        active: boolean;
    }) {
        const { id, title, value, active } = params;

        this.id = id;
        this.title = title;
        this.value = value;
        this.active = active;
    }
}