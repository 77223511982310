<template>
  <div class="within-col">
    <slot name="operation"></slot>
  </div>
</template>
<script lang="ts">
import Vue from "vue";
export default Vue.extend({
  inheritAttrs: false,
  name: "ContainerForWithinCol",
  props: {
    row: { type: Object, required: true },
  },
});
</script>
<style lang="scss" scoped>
.within-col {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
</style>