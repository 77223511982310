<template>
  <div class="fd-main">
    <div class="preview-detail" element-loading-background="rgba(40, 47, 57,0.8)">
      <div class="detail-main">
        <div class="file-card">
          <div class="file-breadcrumb">
            <span v-if="breadcrumbList.length" class="pointer" @click="jumpTo(null, -1)">全部</span>
            <template v-for="(item, index) in breadcrumbList">
              <span :key="item.id">&nbsp;/&nbsp;</span>
              <span class="pointer" :key="'breadcrumb' + item.id" @click="jumpTo(item, index)">{{ item.folderName
                }}</span>
            </template>
          </div>
          <div class="file-content-table" v-loading="loading">
            <el-table :key="tablekey" highlight-current-row class="file-table" header-cell-class-name="app-header-cell" ref="table"
              row-class-name="file-table-row" row-key="id" :data="tableData" height="100%"
              @selection-change="handleSelectionChange" @select-all="handleSelectAll" @select="handleSelectData" @row-dblclick="rowdbClick">
              <el-table-column type="selection" :selectable="selectableRow" width="55"></el-table-column>
              <el-table-column label="名称" prop="folderName" min-width="350px">
                <template slot-scope="{ row }">
                  <div class="file-name__wrapper">
                    <svg-icon class="file-type file-icon" :icon="row.icon" width="48" height="48"></svg-icon>
                    <div>
                      <div v-if="!row.showprogress" class="file-name">{{ row.folderName }}</div>
                      <el-progress v-else color="#4ed4cb" :percentage="row.progress" style="width: 300px"></el-progress>
                      <div class="file-information ">
                        <span v-if="row.fileType == 1">{{ row.fileSize }}&nbsp;&nbsp;|&nbsp;&nbsp;</span>
                        <span>{{ row.createBy ? row.createBy : '' }}&nbsp;于&nbsp;{{
              Object.dayjs(row.createTime) }}&nbsp;{{ row.fileType == 1 ? "上传" : "创建" }}</span>
                      </div>
                      <div class="file-operation">
                        <div v-if="row.fileType == 2" class="file-operation-item" @click.stop="handleOpenfolder(row)">
                          <svg-icon icon="file-open-16" color="#344563" width="16" height="16"></svg-icon>
                          <span>打开</span>
                        </div>
                        <div v-if="row.fileType == 1" class="file-operation-item"
                          @click.stop="handleView(row)">
                          <svg-icon icon="file-preview-16" color="#344563" width="14" height="14"></svg-icon>
                          <span>预览</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </template>
              </el-table-column>
              <el-table-column label="版本" min-width="50">
                <template slot-scope="{ row }">
                  <span>{{ row.fileType == 1 ? row.fileVersion : "-" }}</span>
                </template>
              </el-table-column>
              <el-table-column label="文件类型" width="100">
                <template slot-scope="{ row }">
                  <span>{{ row.fileType == 1 ? row.contentType : "-" }}</span>
                </template>
              </el-table-column>
              <el-table-column label="创建人" min-width="120">
                <template slot-scope="{ row }">
                  <span>{{ row.createBy ? row.createBy : '' }}</span>
                </template>
              </el-table-column>
              <el-table-column label="说明" min-width="100" prop="describes" show-overflow-tooltip>
                <template slot-scope="{ row }">
                  <span style="line-height: 20px;">
                    <span>{{ row.describes }}</span>
                  </span>
                </template>
              </el-table-column>
            </el-table>
          </div>
          <FilePreview v-if="preview" :visible.sync="preview" :haslist="false" :currentitem.sync="currentItem" />
        </div>
      </div>
      <!-- <div class="detail-select">
        <p>已选{{defaulSelectData.length}}:</p>
        <div class="detail-select-wrapper">
          <div class="select-items flex-between" v-for="(item,index) in defaulSelectData" :key="item.id">
            <div class="one-line" :title="item.folderName?item.folderName:item.fileName">{{index+1}}.{{item.folderName?item.folderName:item.fileName}}</div>
            <div class="el-icon-error items-remove pointer" @click="handleRemove(item)"></div>
          </div>
        </div>
      </div> -->
    </div>
    <PlugInDialog v-if="plugvisible" :visible.sync="plugvisible" @cancel="uploading = 'false'" />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { File } from "@/api/File";
import { ProductLibrary } from "@/api/ProductLibrary";
import { DownloadFile, officeView, checkFileIcon } from '@/utils/file';
import FilePreview from "@/components/dialog/FilePreview.vue";
import PlugInDialog from "@/components/dialog/PlugIn.vue";
export default {
  components: {
    FilePreview,
    PlugInDialog,
  },
  props: {
    subject: Object, // 主题详情
    alreadychose:Array,
    scheme: Object,
    temporaryfile:Array,
    schemeid: String,
    updatekey: Number,
    crumblist: Array,//传入的面包屑
  },
  data() {
    return {
      loading: false,
      direction: "rtl",
      queryData: {
        dictItemIds: [],
        pageNo: 1,
        pageSize: 9999,
        name: '',
        showLevel: 0,//展示层级数(0：一层,1:二层，2：三层...)
        sort: null,
        sortType: 'desc',//排序的类型（asc: 正序 desc: 倒序)
        sort: "createTime",
      },
      tableData: [],
      subjecuFilePayload: {
        moduleId: "",
        subjectId: "",
        fileName: "",
        pageNo: 1,
        pageSize: 9999,
      },
      queryId: {
        moduleId: "",
        subjectId: "",
      },
      queryFile: {
        dictItemIds: "", // 二级分类id
        folderId: "",
        name: "",
        pageNo: 1,
        pageSize: 9999,
      },
      breadcrumbList: [],
      preview: false,
      selectList: [], // 选择的文件列表	
      sharedata: {
        accessCode: "",
        authWay: 2,//（1、需要登录，2、无需安全验证，3、指定访问码
        bizType: 2,//1、主题产品，2文件,3、公司名片,4、个人名片，5、演示方案
        delFlag: 0,
        expireTime: "",
        id: "",
        shareDetails: [],
        shareWay: 1,
        status: 1,
        timeUnit: "d",
        validDays: 3,
      },
      viewType: 'list',
      clickItem: null,//点击项
      dragFile: null,
      isDownloading: false,
      // localupload: false,// 插件上传弹窗
      uploading: false,// 插件是否有正在上传的文件
      localfilename: "",
      plugvisible: false,
      filereceiveId: (new Date().getTime().toString(16) + Math.random().toString(16).substring(2)).substring(2, 18),
      defaulSelect:[],//已选的id合集
      defaulSelectData:[],//已选的数据合集
      tablekey:1
    };
  },
  watch: {
    isconnected(val) {
      if (val) {
        this.init();
      }
    },
    // defaulSelectData(val) {
    //   let ids = val.map(item => item.id)
    //   ids = Array.from(new Set(ids))
    //   const selectdata = {
    //     dataIds: ids,
    //     dataType: 2,
    //     schemeId: this.schemeid,
    //     subjectColumnId: null,
    //     subjectId: this.subject.id,
    //   };
    //   this.$emit("handleselect", selectdata);
    //   this.$emit("handlesavetemporaryselect", val);
    // },
    updatekey(){
      this.schemeid && this.getdefaultData();
    },
    crumblist(val){
      if(val.length){
        this.breadcrumbList = val;
        this.queryFile.folderId = val[val.length-1].id;
        this.queryFile.name = "";
        this.getTableData(1);
      }else{
        this.getFirstTableData();
      }
    }
  },
  computed: {
    ...mapGetters(["token", "userInfo", "isconnected"]),
  },
  created() {
    this.queryId.moduleId = this.subject.moduleId;
    this.queryId.subjectId = this.subject.id;
    this.subjecuFilePayload.moduleId = this.subject.moduleId;
    this.subjecuFilePayload.subjectId = this.subject.id;
  },
  mounted() {
    this.getdefaultData()
    if(this.crumblist.length){
      this.breadcrumbList = this.crumblist;
      this.queryFile.folderId = this.crumblist[this.crumblist.length-1].id;
      this.queryFile.name = "";
      this.getTableData(1);
    }else{
      this.getFirstTableData();
    }
    // this.getFirstTableData();
    this.init();
    this.queryFile.folderId = this.subject.fileSubjectFolderId;
  },
  methods: {
    selectableRow(row, index) {
      return row.fileType !== "2";
    },
    /**
     * @description:获取列表
     * @return {void}
     */
    async getdefaultData() {
      if(!this.schemeid) return
      let previewpayload = {
        moduleId: this.subject.moduleId, 
        schemeId: this.schemeid,
        subjectId: this.subject.id,
        fileName: "",
      };
      const res = await ProductLibrary.queryFileBySubjectScheme(previewpayload);
      res.forEach((element) => {
        if (element.fileType == 1) {
          element.fileSize = this.common.formatFileSize(element.fileSize);
          element.aliasType = this.common.getFileType(
            element.contentType,
            element.fileSavePath
          );
          element.icon = checkFileIcon(element.fileSavePath);
        } else {
          element.aliasType = "folder";
          element.icon = "filetype-floder"
        }
        element.fileName = element.folderName?element.folderName:element.fileName
        element.showprogress = false;
        element.progress = 0;
      });
      this.defaulSelect = res.map(item=>item.id);
      this.defaulSelectData = res;
      this.$refs.table && this.$refs.table.clearSelection();
      const rows=this.tableData.filter(item=>this.defaulSelect.includes(item.id))
      rows.forEach(row => {
        this.$refs.table && this.$refs.table.toggleRowSelection(row,true);
      });
    },
    /**
     * @description:获取列表
     * @return {void}
     */
    async getFirstTableData() {
      this.$emit('fileclick', null, true)
      this.loading = true;
      const res = await File.queryFileBySubject(this.subjecuFilePayload)
      res.records.forEach(element => {
        if (element.fileType == 1) {
          element.fileSize = this.common.formatFileSize(element.fileSize);
          element.aliasType = this.common.getFileType(element.contentType, element.fileSavePath);
          element.icon = checkFileIcon(element.fileSavePath);
        } else {
          element.aliasType = "folder";
          element.icon = "filetype-floder"
        }
        element.showprogress = false;
        element.progress = 0;
      })
      this.loading = false;
      this.tableData = res.records;
      this.$nextTick(()=>{
        this.getdefaultData()
      })
    },
    /**
     * @description: 获取文件目录下的文件列表
     * @return {void}
     */
    async getTableData(pageNo) {
      this.$emit('fileclick', null, true)
      this.loading = true;
      pageNo && (this.queryFile.pageNo = pageNo);
      this.showAttribute = false;
      this.currentItem = null;
      this.$refs.table && this.$refs.table.setCurrentRow();
      let payload = _.omit(this.queryFile, [this.breadcrumbList.length ? "dictItemIds" : "folderId"]);
      payload = _.omit(this.queryFile, ["dictItemIds"]);
      const res = await File.queryFileByFolderId(payload);
      res.records.forEach(element => {
        if (element.fileType == 1) {
          element.fileSize = this.common.formatFileSize(element.fileSize);
          element.aliasType = this.common.getFileType(element.contentType, element.fileSavePath);
          element.icon = checkFileIcon(element.fileSavePath);
        } else {
          element.aliasType = "folder";
          element.icon = "filetype-floder"
        }
        element.showprogress = false;
        element.progress = 0;
      })
      this.tableData = res.records;
      this.defaulSelect = this.defaulSelectData.map(item=>item.id);
      const rows=this.tableData.filter(item=>this.defaulSelect.includes(item.id))
      this.$nextTick(()=>{
        rows.forEach(row => {
          this.$refs.table && this.$refs.table.toggleRowSelection(row,true);
        });
      })
      this.loading = false;
    },
    /**
     * @description: 打开文件夹
     * @param {Object} value 文件夹
     * @return {void}
     */
    handleOpenfolder(value) {
      if (value.fileType != 2) {
        this.handleView(value)
        return
      }
      const index = this.breadcrumbList.findIndex(item => item.id === value.id);
      index === -1 && this.breadcrumbList.push(value);
      this.queryFile.folderId = value.id;
      this.queryFile.name = "";
      this.getTableData(1);
      this.$emit('fileclick', value, true)
    },
    /**
     * @description: 点击面包屑获取文件列表
     * @param {Object} value 点击项
     * @param {Number} index 索引
     * @return {void}
     */
    jumpTo(value, index) {
      if (index === this.breadcrumbList.length - 1) {
        return
      }
      if (value) {
        this.breadcrumbList.splice(index + 1);
        this.queryFile.folderId = value.id;
        this.queryFile.name = "";
        this.getTableData(1);
      } else {
        this.breadcrumbList = [];
        this.queryFile.folderId = "";
        this.getFirstTableData()
      }
    },
    /**
     * @description:清空搜索字段
     * @return {void}
     */
    handleClear() {
      this.queryData.name = '';
      this.getList(1)
    },
    checkSelect(value) {
      const index = this.selectList.findIndex(item => item.id === value.id);
      return index != '-1'
    },
    /**
     * @description: 预览
     * @param {Object} value 点击项
     * @return {void}
     */
    handleView(value) {
      if (value.aliasType === "image") {
        this.$elImageViewer({ urlList: [value.fileSavePath], initialIndex: 0 });
      }else{
        this.currentItem = value
        this.preview = true
      }
    },
    handleSelectionChange(val) {
      // this.selectList = val
    },
    /**
     * @description: 选择框改变时触发
     * @param {Array} selection 当前页所有选择项
     * @param {Object} row 点击项
     * @return {void}
     */
    handleSelectData(selection, row) {
      const ischeck=selection.some(item=>item.id == row.id)
      if(ischeck){
        this.defaulSelectData.push(row);
      }else{
        this.defaulSelectData=this.defaulSelectData.filter(item=>item.id!=row.id)
      }
      this.handleSelect()
    },
    handleSelectAll(selection){
      if(selection.length){
        selection.forEach(row=>{
          const ischeck=this.defaulSelectData.some(item=>item.id == row.id)
          if(!ischeck){
            this.defaulSelectData.push(row);
          }
        })
      }else{
        this.tableData.forEach(row=>{
          const ischeck=this.defaulSelectData.some(item=>item.id == row.id)
          if(ischeck){
            this.defaulSelectData=this.defaulSelectData.filter(item=>item.id!=row.id)
          }
        })
      }
      this.handleSelect()
    },
    /**
     * @description: 移除选择
     * @param {Object} row 点击项
     * @return {void}
     */
    handleRemove(row){
      this.defaulSelectData=this.defaulSelectData.filter(item=>item.id!=row.id);
      this.breadcrumbList.length?this.getTableData(1):this.getFirstTableData();
    },
    /**
     * @description: 初始化消息
     * @return {void}
     */
    init() {
      this.$messageService.$on('websocketMessage', (data) => {
        // 处理接收到的消息
        const parsedMessage = JSON.parse(data);
        if (parsedMessage.funcname == "downloadingFile") {
          this.isDownloading = true;
          const index = this.tableData.findIndex(i => i.id === parsedMessage.msgId);
          this.$set(this.tableData, index, { ...this.tableData[index], showprogress: true });
          // this.percentage=Number(parsedMessage.data[0].progress.replace('%',''))
          let percentage = Number(parsedMessage.data[0].progress.replace('%', ''))
          this.$set(this.tableData, index, { ...this.tableData[index], progress: percentage });
          if (parsedMessage.data[0].status === "DONE" && parsedMessage.data[0].progress) {
            this.isDownloading = false;
            this.$set(this.tableData[index], 'showprogress', false)
          }
        }
      });
    },
    /**
    * @description: 行双击下载文件并在本地打开
    * @param {Object} row 点击项
    * @return {void}
    */
    rowdbClick(row) {
      if (row.fileType == 2) {
        return;
      }
      if (!this.isconnected) {
        this.plugvisible = true;
        return;
      }
      this.percentage = 0
      if (this.$websocket.readyState === WebSocket.OPEN) {
        this.clickItem = row
        // 发送消息
        let queryDownloadData = {
          msgId: row.id,
          funcname: "downloadfile",
          downloadType: "1", //下载消息类型，1:开始，2:暂停,3:取消
          systemType: "1",
          token: this.token ? this.token : "",
          tenantId: this.userInfo ? this.userInfo.tenantIdNow : "",
          baseurl: process.env.VUE_APP_URL_NETDISK,
          fileSysBaseUrl: process.env.VUE_APP_URL_NETDISK,
          fileId: row.id,
          isCurrent: "1", //是否下载当前(1:是当前，2：是历史)(如果是历史版本必填)
          sysToken:"JqjdLEtaFOas5whS8hro2w=="
        };
        this.$websocket.send(JSON.stringify(queryDownloadData));
      }
    },
    /**
    * @description: 选择数据
    * @return {void}
    */
    handleSelect(){
      let ids = this.defaulSelectData.map(item => item.id)
      ids = Array.from(new Set(ids))
      const selectdata = {
        dataIds: ids,
        dataType: 2,
        schemeId: this.schemeid,
        subjectColumnId: null,
        subjectId: this.subject.id,
      };
      this.$emit("handleselect", selectdata);
    }
  },
};
</script>

<style lang="scss" scoped>
@import "~@/scss/common.scss";

.fd-main {
  min-width: 800px;
  height: 100%;
}

.preview-detail {
  height: 100%;
  width: 100%;
  z-index: 12;
  display: flex;
  padding: 20px;
  // color: #fff;
  box-sizing: border-box;
  border-radius: 5px;

  .detail-main {
    height: 100%;
    width: 100%;
    position: relative;
    background-color: #fff;
    overflow: hidden;

    .file-default {
      height: 48px;
      width: 48px;
      overflow: hidden;

      .svg-icon {
        display: block;
        position: relative;
        top: 0;
        right: 0;
      }
    }
  }
  .detail-select{
    width: 200px;
    height: 100%;
    border-left: 1px solid #e6ebf2;
    padding: 10px;
    flex-shrink: 0;
    font-size: 14px;
    p{
      font-weight: 700;
    }
    .detail-select-wrapper{
      width: 100%;
      height: calc(100% - 20px);
      overflow-y: auto;
    }
    .select-items{
      height: 24px;
      line-height: 24px;
      text-align: center;
      padding: 0 5px;
      &:hover{
        background-color: var(--color-editor-light-color);
        border-radius: 5px;
        .items-remove{
          display: block;
        }
      }
      .items-remove{
        flex-shrink: 0;
        line-height: 24px;
        color: var(--color-danger);
        display: none;
      }
    }
  }
}

.file-content-table {
  flex: 1;
  height: calc(100% - 80px);
  overflow-y: auto;
  margin-top: 10px;

  .describes-icon {
    display: none;
  }

  .file-table-row {

    &:hover {
      .file-information {
        display: none;
      }

      .file-operation {
        display: flex;
      }

      .describes-icon {
        display: inline-block;
      }
    }
  }

  ::v-deep .el-table {
    &::before {
      height: 0;
    }

    .el-table__row {
      cursor: pointer;
    }

    .el-table__body tr:hover,
    tr.current-row {
      td {
        background-color: #f5f7fa;
      }
    }
  }
}

.file-name__wrapper {
  font-size: 14px;
  font-weight: 500;
  display: flex;
  align-items: center;

  .file-icon {
    width: 48px;
    height: 48px;
    margin-right: 16px;
    flex-shrink: 0;
  }

  .file-name {
    line-height: 20px;
    padding-bottom: 4px;
    color: var(--color-text-regular);
  }

  .file-information {
    line-height: 20px;
    color: #cfd7e6;
    margin-top: 6px;
    font-size: 12px;
  }

  .file-operation {
    font-size: 12px;
    display: none;

    .file-operation-item {
      display: flex;
      align-items: center;
      line-height: 26px;
      padding: 0 6px;
      border-radius: 5px;
      color: #8997B0;
      cursor: pointer;

      &.disabled {
        cursor: not-allowed;
      }

      &:not(.disabled):hover {
        color: var(--color-primary);

        .svg-icon {
          color: var(--color-primary) !important;
        }
      }
    }
  }
}


.disabled {
  cursor: not-allowed;
}
</style>