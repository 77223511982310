export class IconsHelper {

    /**
     * 缓存图标的 HTML 内容
     */
    private static mapIcons: Map<string, Promise<string>> = new Map();

    /**
     * 获取指定图标的 HTML 内容
     * @param systemName icons 文件夹目录下的第一级子文件夹的名称
     * @param iconName icons 文件夹目录下的二级及以下子文件夹的名称 + 图标名称(不包含后缀)
     * @example 
     * 例如：systemName = "ant-design", iconName = "icon1"，则对应的图标文件为：icons/ant-design/icon1.svg
     * 例如：systemName = "ant-design", iconName = "icon2/icon3"，则对应的图标文件为：icons/ant-design/icon2/icon3.svg
     * @returns 
     */
    public static async getIconHtml(systemName: string, iconName: string): Promise<string | null> {

        const requireContext = require.context('../svg', true, /\.svg$/);

        const iconKey = `./${systemName}/${iconName}.svg`;

        if (IconsHelper.mapIcons.has(iconKey)) {
            return IconsHelper.mapIcons.get(iconKey) ?? null;
        } else {
            const svgImporter = requireContext.keys().find(key => key === iconKey);

            if (svgImporter) {
                const iconPromise = (async () => {
                    const svgContent = requireContext(svgImporter);
                    const response = await fetch(svgContent);
                    const svgHtmlStr = await response.text();

                    return svgHtmlStr;
                })();

                IconsHelper.mapIcons.set(iconKey, iconPromise);

                return await iconPromise;
            } else {
                console.error(`图标文件不存在(sys: '${systemName}', icon name: '${iconName}')`);

                return null;
            }
        }
    }
}