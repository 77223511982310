<template>
  <div class="icon-alert-sign">
    <!-- <IconAlert class="icon-alert" /> -->
    <svg-icon class="icon-alert" sys="common-table" name="ColumnButton/alert" />
    <span class="text">{{ text }}</span>
  </div>
</template>
<script>
// import Vue from "vue";
import SvgIcon from "@/flexible-table-module/svg-icon-importer";
// import IconAlert from "@/flexible-table-module/icons/ColumnButton/IconAlert.vue";
export default {
  inheritAttrs: false,
  name: "IconAlertSign",
  components: {
    SvgIcon,
    // IconAlert,
  },
  props: {
    text: { type: String, default: "" },
  },
};
</script>
<style lang="scss" scoped>
@import "src/flexible-table-module/scss/_variables.scss";

.icon-alert-sign {
  font-size: $fs-icon-alert-sign;

  // 橙色圆角长方形
  $color: #ff9800;
  $size: 20px;
  box-sizing: border-box;
  height: $size;
  min-width: $size;
  border-radius: 4px;
  padding: 0 8px;
  background-color: $color;

  display: flex;
  justify-content: flex-start;
  align-items: center;

  .icon-alert {
    margin-right: 4px;
    fill: white !important;
  }

  .text {
    font-size: inherit;
    color: white;
    line-height: $size;
    // 垂直居中
    display: inline-block;
    vertical-align: middle;
    // 不换行
    white-space: nowrap;
    // 超出部分隐藏
    overflow: hidden;
    text-overflow: ellipsis;
    user-select: none;
  }
}
</style>