<template>
  <ElTableColumn
    class-name="next-to-col"
    :fixed="fixed"
    label="操作"
    :width="width"
    header-align="center"
  >
    <template v-slot:header>
      <slot name="header"></slot>
    </template>
    <template v-slot="{ row }">
      <slot name="operation" v-bind="{ row }"></slot>
    </template>
  </ElTableColumn>
</template>
<script lang="ts">
import Vue, { PropType } from "vue";
import { TableColumn as ElTableColumn } from "element-ui";

export default Vue.extend({
  inheritAttrs: false,
  name: "ContainerForNextToCol",
  components: { ElTableColumn },
  props: {
    width: { type: Number, required: true },
    fixed: {
      type: [String, Boolean] as PropType<false | true | "left" | "right">,
      default: false,
    },
  },
});
</script>