<template>
  <div class="mini-select" v-click-outside="onClickOutside">
    <div class="select-inner" :class="{ expanded: restVisible }">
      <div class="option-preference" ref="pref" @click="onPrefClick">
        <div class="option">{{ preferenceOption.label }}</div>
      </div>
      <div class="option-rest" ref="rest" v-if="restVisible" :style="restStyle">
        <template v-for="option in restOptions">
          <div class="option" :key="option.key" @click="onRestClick(option)">
            {{ option.label }}
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import Vue, { PropType } from "vue";
// @ts-ignore
import vClickOutside from "v-click-outside";

type Option = { key: string; label: string; value: string | number | object };

const DEFAULT_OFFSET = 1000;
const CHECK_INTERVAL = 500;

export default Vue.extend({
  directives: {
    clickOutside: vClickOutside.directive,
  },
  props: {
    options: {
      type: Array as PropType<Option[]>,
      required: true,
    },
    defaultValue: {
      type: [String, Number, Object] as PropType<string | number | object>,
      required: true,
    },
    value: {
      type: [String, Number, Object] as PropType<string | number | object>,
    }, // 如果 value 是 undefined，那么默认选中 defaultValue 对应的选项
  },
  data() {
    return {
      restVisible: false,
      debouncedSetListPos: null as (() => void) | null,

      restTop: -DEFAULT_OFFSET,
      restLeft: -DEFAULT_OFFSET,

      intervalId: null as number | null,
    };
  },
  computed: {
    // 首选项
    preferenceOption(): Option {
      return (
        this.options.find((o) => o.value === this.value) ||
        this.options.find((o) => o.value === this.defaultValue) ||
        this.options[0]
      );
    },
    // 剩余的选项
    restOptions(): Option[] {
      return this.options.filter(
        (o) => o.value !== this.preferenceOption.value
      );
    },
    // 下拉列表的位置
    restStyle(): { top: string; left: string } {
      return {
        top: `${this.restTop}px`,
        left: `${this.restLeft}px`,
      };
    },
  },
  async mounted() {
    // // 等待两秒
    // await new Promise((resolve) => setTimeout(resolve, 2000));

    this.debouncedSetListPos = this.debounce(this.setListPos, 10);
    window.addEventListener("resize", this.debouncedSetListPos);
  },
  beforeDestroy() {
    if (this.debouncedSetListPos) {
      window.removeEventListener("resize", this.debouncedSetListPos);
    }
  },
  methods: {
    async onPrefClick() {
      this.restVisible = !this.restVisible;

      if (this.restVisible) {
        this.setListPos();

        // 启动定时器，每隔1秒检查一次 this.$refs.pref 元素的位置
        this.intervalId = setInterval(() => {
          this.setListPos();
        }, CHECK_INTERVAL);
      } else {
        // 清除定时器
        if (this.intervalId) {
          clearInterval(this.intervalId);
          this.intervalId = null;
        }

        this.restTop = -DEFAULT_OFFSET;
        this.restLeft = -DEFAULT_OFFSET;
      }
    },

    onRestClick(option: Option) {
      this.$emit("input", option.value);
      this.$emit("change", option.value);
      this.restVisible = false;
    },

    // ---------------------------

    // 设置下拉列表的位置
    setListPos() {
      if (!this.restVisible) return;
      // if (!this.$refs.rest) return;

      const preference = this.$refs.pref as HTMLElement | undefined;
      if (!preference) return;

      const rect = preference.getBoundingClientRect();
      // const rest = this.$refs.rest! as HTMLElement;
      // const style = rest.style;

      this.restTop = rect.bottom - 1 + window.scrollY; // 1px 是边框的宽度
      this.restLeft = rect.left + window.scrollX;

      // style.setProperty("--top", `${this.restTop}px`);
      // style.setProperty("--left", `${this.restLeft}px`);
    },

    // 防抖函数
    debounce(func: () => void, wait: number) {
      let timeout: number | null = null;
      return () => {
        if (timeout !== null) window.clearTimeout(timeout);
        timeout = window.setTimeout(func, wait);
      };
    },

    onClickOutside() {
      this.restVisible = false;
    },
  },
});
</script>

<style lang="scss" scoped>
@import "src/flexible-table-module/scss/_variables.scss";

.mini-select {
  font-size: $fs-mini-select;

  .select-inner {
    width: 100%;
    cursor: pointer;

    &.expanded {
      .option-preference {
        border-radius: 4px 4px 0 0;
        border-bottom-color: transparent;
      }
      .option-rest {
        border-radius: 0 0 4px 4px;
      }
    }

    .option-preference {
      border: 1px solid $color-light;
      overflow: hidden;
      background-color: #fff;
      border-radius: 4px;
    }

    .option-rest {
      border: 1px solid $color-light;
      overflow: hidden;
      background-color: #fff;
      border-top: none;
      position: fixed;
      // top: var(--top);
      // left: var(--left);
      z-index: 2001;
    }

    .option {
      box-sizing: border-box;
      cursor: pointer;
      text-align: center;
      padding: 0 8px;

      height: 20px;
      line-height: 20px;

      &:hover {
        background-color: $btn-hover-color;
      }
    }
  }
}
</style>

