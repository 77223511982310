/*
 * @Author: Billy
 * @Date: 2020-09-07 13:34:39
 * @LastEditors: ma
 * @LastEditTime: 2023-09-20 18:00:23
 * @Description: v1.2
 */
class SysCode {
    static SUCCESS = 200;
    static SYNC_PROCESS_NOT_FINISHIED = 1014;
    static TOKEN_MISSING = '103'; // token没有传递
    static TOKEN_EXPIRED = 405; // token过期
    static TOKEN_ERROR = '105'; // token有错误
    static TOKEN_FORCED_INVALID = '106' // token被强制作废
}

export default SysCode;