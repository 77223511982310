import Vue from 'vue';
import DynamicPopup from './DynamicPopup.vue';
import store from '../../store'; // 导入 store
import router from '../../router'
function JhDialog(props, clickHandler) {
  const vm = new Vue({
    store,
    router,
    render: h => h(DynamicPopup, {
      props,
      on: {
        confirm: () => {
          if (clickHandler) {
            clickHandler((success = true) => {
              vm.$children[0]["loading"] = false;
              if (success) {
                const vDom = vm.$children[0];
                vDom.$destroy();
                document.getElementById('app').removeChild(vm.$el);
              }
            });
          } else {
            vm.$children[0]["loading"] = false;
            const vDom = vm.$children[0];
            vDom.$destroy();
            document.getElementById('app').removeChild(vm.$el);
          }
        },
        close: () => {
          const vDom = vm.$children[0];
          vDom.$destroy();
          document.getElementById('app').removeChild(vm.$el);
        }
      },
    }),
  }).$mount();

  // Ensure Vue instance is fully mounted before appending to #app
  vm.$nextTick(() => {
    document.getElementById('app').appendChild(vm.$el);
  });
}

export default JhDialog;
