const hometable = {
  state: {
    tableTab: null,
    islocked: false,
    batchshare: false,//产品里是否进行批量分享
  },
  getters: {
    tableTab: state => state.tableTab,
    islocked: state => state.islocked,
    batchshare: state => state.batchshare,
  },
  mutations: {
    changetableTab(state, n = "") {
      state.tableTab = n;
    },
    changeislockedStatus(state, n) {
      state.islocked = n;
    },
    changebatchshareStatus(state, n) {
      state.batchshare = n;
    },
  }
};
export default hometable