<template>
  <div class="side-item" :class="{ active: isActive }">
    <div class="title" @click="$emit('title-click')">
      <span>{{ title }}</span>
    </div>
    <div class="btn-del" @click="onBtnDelClick">
      <!-- <IconDelete :size="12" /> -->
      <svg-icon sys="common-table" name="ViewEditor/delete" :size="12" />
    </div>
  </div>
</template>
<script lang="ts">
import Vue from "vue";
import SvgIcon from "@/flexible-table-module/svg-icon-importer";

// import IconDelete from "@/flexible-table-module/icons/ViewEditor/IconDelete.vue";

export default Vue.extend({
  inheritAttrs: false,
  name: "SideItem",
  components: {
    SvgIcon,
    // IconDelete,
  },
  props: {
    isActive: { type: Boolean, default: false },
    title: { type: String, required: true },
  },
  methods: {
    onBtnDelClick() {
      this.$emit("btn-del-click");
    },
  },
});
</script>
<style lang="scss" scoped>
@import "src/flexible-table-module/scss/_variables.scss";

.side-item {
  box-sizing: border-box;
  width: 100%;
  height: 24px;
  line-height: 24px;
  border: solid 1px $color-info;
  border-radius: 4px;
  overflow: hidden;

  &.active {
    border-color: $color-primary;
  }

  &:not(:last-child) {
    margin-bottom: 4px;
  }

  $btn-size: 24px;

  .title {
    box-sizing: border-box;
    float: left;
    width: calc(100% - $btn-size);
    height: 100%;
    padding-left: 4px;

    &:hover {
      cursor: pointer;
      background-color: $border-color;
    }
  }

  .btn-del {
    box-sizing: border-box;
    float: right;
    width: $btn-size;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    &:hover {
      cursor: pointer;
      background-color: $border-color;
    }
  }
}
</style>