<template>
  <div
    class="operation-btn-outside"
    :style="{
      minWidth: `${
        this.fontSize * this.maxTitleCount +
        (this.hasIcon ? this.fontSize + this.iconMarginRight : 0) +
        this.btnPadding * 2
      }px`,
      fontSize: `${this.fontSize}px`,
    }"
  >
    <div
      class="operation-btn"
      :style="{ padding: `0 ${this.btnPadding}px` }"
      @click="onBtnClick"
    >
      <div class="operation-btn-inside">
        <template v-if="hasIcon">
          <span
            class="icon"
            :style="{ marginRight: `${this.iconMarginRight}px` }"
            ><slot name="icon"></slot
          ></span>
        </template>
        <span class="title">{{ title }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
export default {
  inheritAttrs: false,
  name: "OperationButton",
  props: {
    name: { type: String, required: true },
    title: { type: String },
    row: { type: Object }, // 所在行数据
    hasIcon: { type: Boolean, default: false },
    maxTitleCount: { type: Number, default: 4 }, // 按钮标题的最大字数(默认为4)
    fontSize: { type: Number, default: 12 },
  },
  data() {
    return {
      iconMarginRight: 2,
      btnPadding: 4,
    };
  },
  methods: {
    onBtnClick() {
      this.$emit("btn-click", this.row, this.name);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "src/flexible-table-module/scss/_variables.scss";
@import "src/flexible-table-module/scss/common.scss";

.operation-btn-outside {
  @include operation-btn-outside;

  .operation-btn {
    @include operation-btn;

    .operation-btn-inside {
      @include operation-btn-inside;

      .icon {
        // margin-right: 2px;
      }
    }
  }
}
</style>