import { type VNode, type VNodeDirective } from "vue";
import { Loading as LoadingSign } from "loading-sign";

const loadingOptions = {
    body: false,
    fullscreen: false,
    lock: true,
    background: 'hsla(0,0%,100%,.9)',
    size: 32,
};

class LoadingDirective {

    // public appendToBody: boolean;

    public unitMap: Map<HTMLElement, any> = new Map();

    constructor(params?: { appendToBody?: boolean }) {
        // if (!params) params = {};
        // const { appendToBody = true } = params;
    }

    public bind(el: HTMLElement, binding: any, vnode: VNode, oldVnode: VNode) { }

    public inserted(el: HTMLElement, binding: any, vnode: VNode, oldVnode: VNode) {

        const loading = binding.def as LoadingDirective;

        if (binding.value) {
            const unit = LoadingSign.service({
                target: el,
                ...loadingOptions,
            });
            loading.unitMap.set(el, unit);
        }
    }

    public update(el: HTMLElement, binding: any, vnode: VNode, oldVnode: VNode) {

        const loading = binding.def as LoadingDirective;

        const unit = loading.unitMap.get(el);

        if (binding.value) {
            if (!unit) {
                const newUnit = LoadingSign.service({
                    target: el,
                    ...loadingOptions,
                });
                loading.unitMap.set(el, newUnit);
            } else {
                if (!unit.isVisible) unit.show();
            }
        } else {
            if (unit) {
                unit.close();
                // loading.unitMap.delete(el);
            }
        }
    }

    public componentUpdated(el: HTMLElement, binding: any, vnode: VNode, oldVnode: VNode) { }

    public unbind(el: HTMLElement, binding: any, vnode: VNode, oldVnode: VNode) {

        const loading = binding.def as LoadingDirective;

        const unit = loading.unitMap.get(el);

        if (unit) {
            unit.close();
            loading.unitMap.delete(el);
        }
    }
}

export class Loading {

    public static install(Vue: any) {
        Vue.directive("loading", new LoadingDirective());
    }

    public static directive = new LoadingDirective(); // 单例模式
}

