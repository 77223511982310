<template>
  <svg
    t="1716447825153"
    class="icon"
    viewBox="0 0 1024 1024"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    p-id="3029"
    :width="size"
    :height="size"
    xmlns:xlink="http://www.w3.org/1999/xlink"
  >
    <path
      d="M541.988571 146.285714c197.485714 0 356.644571 159.085714 356.644572 356.571429S739.474286 859.428571 541.988571 859.428571c-87.771429 0-172.836571-32.914286-238.665142-93.257142a29.403429 29.403429 0 0 1-2.706286-38.4c10.971429-10.971429 27.428571-10.971429 38.4-2.706286C393.947429 774.436571 467.968 804.571429 541.988571 804.571429a300.836571 300.836571 0 0 0 301.714286-301.714286A300.836571 300.836571 0 0 0 541.988571 201.142857a298.057143 298.057143 0 0 0-296.228571 249.124572l84.992-43.373715c13.750857-8.265143 30.208-2.779429 38.4 10.971429 8.265143 13.677714 2.779429 30.134857-10.971429 38.326857l-131.657142 68.608c-5.485714 2.779429-13.677714 2.779429-19.163429 2.779429-5.485714 0-8.265143 0-10.971429-2.779429a43.739429 43.739429 0 0 1-24.722285-19.236571l-65.828572-131.657143c-5.485714-13.677714 0-30.134857 13.750857-35.620572 13.677714-5.485714 30.134857 0 35.620572 13.750857l38.034286 75.922286 0.804571-4.022857A356.205714 356.205714 0 0 1 542.061714 146.285714z"
      p-id="3030"
    ></path>
  </svg>
</template>
<script lang="ts">
import Vue from "vue";
import Mixin from "../IconMixin";
export default Vue.extend({
  inheritAttrs: false,
  mixins: [Mixin],
});
</script>