<template>
  <div class="filelist" v-if="dialogVisible"  v-loading="confirmLoading" element-loading-background="rgba(40, 47, 57,0.8)">
    <div class="file-left"  v-show="close&&haslist">
      <div class="file-left-title">
        <div @click="getfirstlist">{{ dialogTitle }}</div>
        <div @click="closeList"><img src="@/assets/images/productLibrary/preview-close.png" alt=""></div>
      </div>
      <div class="file-left-breadcrumb">
        <!-- <template v-for="(item, index) in breadcrumbList">
          <span class="pointer" :key="'breadcrumb' + item.id" @click="jumpTo(item, index, 'video')">{{ item.folderName
          }}</span>
          <span class="fw-400" :key="item.id">&nbsp;/&nbsp;</span>
        </template> -->
        <el-breadcrumb separator-class="el-icon-arrow-right">
          <el-breadcrumb-item @click.native="getfirstlist">全部文件</el-breadcrumb-item>
          <el-breadcrumb-item v-for="(item, index) in breadcrumbList" :key="item.id"
            @click.native="jumpTo(item, index, 'video')">{{ item.folderName }}</el-breadcrumb-item>
        </el-breadcrumb>
      </div>
      <div class="file-left-list">
        <div class="list-item" :class="{'active':item.id===clickitem.id}" v-for="item in visibleList" :key="item.id" @click="handleClick(item)">
          <div v-if="item.fileType == 2">
            <svg-icon icon="preview-floder" width="20" height="20"></svg-icon>
          </div>
          <div v-if="item.fileType == 1">
            <img src="@/assets/images/productLibrary/preview-video.png" alt="" style=" width:20px; height:20px;margin-top:11px;margin-right: 10px;" v-if="dialogTitle === '演示视频'">
            <!-- <svg-icon icon="preview-video" width="19" height="20" v-if="dialogTitle==='演示视频'"></svg-icon> -->
            <!-- <svg-icon icon="preview-file" width="19" height="20"  v-if="dialogTitle==='演示文档'"></svg-icon> -->
            <svg-icon icon="preview-model" width="20" height="20"  v-if="dialogTitle==='演示模型'"></svg-icon>
            <svg-icon :icon="formatFileType(item.fileSavePath)" width="20" height="20"  v-if="dialogTitle === '演示文档'"></svg-icon>
          </div>
          <div class="list-item-name" :title="item.folderName">
            {{ item.folderName }}
          </div>
        </div>
      </div>
    </div>
    <div class="file-right">
      <FileTemPreview 
        :haslist="false"
        :currentitem.sync="currentitem"
        :color="'#344563'"
        :loginrequired="loginrequired"/>
      <div class="file-close" @click="dialogVisible = false">
        <img src="@/assets/images/common/close.png" alt="" />
      </div>
    </div>
  </div>
</template>

<script>
import 'video.js/dist/video-js.css';
import videojs from 'video.js';
import { mapGetters } from "vuex";
import { File } from "@/api/File";
import axios from "axios";
import FileTemPreview from "../filecompanies/FileTemPreview.vue";
export default {
  name: "ProductFileList",
  components:{FileTemPreview},
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    subject: Object,
    dialogTitle: String,
    haslist: {
      type: Boolean,
      default: true,
    },
    currentitem:Object,
    loginrequired:{
      type:Boolean,
      default:true//是否需要登录，非登录的currentitem里面传参多加一个shareId
    },
  },
  data() {
    return {
      // loading: true,
      queryCategoryData: {
        dataType: 1,
        moduleId: "",
        subjectId: "",
        subjectColumnId: "",
        pageNo: 1,
        pageSize: 99,
      },
      breadcrumbList: [],
      queryData: {
        folderId: "",
        name: "",
        sort: "createTime", // 排序字段名称
        sortType: "desc", // 排序的类型（asc: 正序 desc: 倒序)
        pageNo: 1,
        pageSize: 10,
      }, // 文件列表查询参数
      firstlist:[],//缓存一开始进来的列表
      confirmLoading: false,
      videoUrl: "",
      isvideo: false,//视频类预览
      isfile:false,//文档类预览
      isimg:false,
      visibleList: [],//用来显示的文件列表
      type: '',
      fileUrl:'',
      close:true,//左侧列表的显示
      unsupported:false,//是否为不支持的播放类型
      clickitem:{id:'-1'},//当前项
      player:null,
      filesrc:null,
    };
  },
  computed: {
    dialogVisible: {
      get() {
        return this.visible;
      },
      set(val) {
        this.$emit("update:visible", val);
      },
    },
    ...mapGetters(["token", "userInfo"]),
  },
  watch:{
    visibleList(newval){
      // if(newval.length){
      //   let item=newval.filter(i=>i.fileType == 1)
      //   if(item.length){
      //     this.previewFile(item[0])
      //   }
      // }else{
      //   this.isvideo=false;
      //   this.isfile=false;
      //   this.unsupported=true
      // }
    }
  },
  beforeDestroy() {
    if (this.player) {
      this.player.dispose();
    }
  },
  mounted() {
    // this.player = videojs(this.$refs.videoPlayer, {
    //   controls: true, // 启用默认播放控件
    //   muted: true,//静音
    // });
    // if(!this.haslist){
    //   this.handleClick(this.currentitem)
    // }else{
    //   this.queryCategoryData.moduleId = this.subject.moduleId;
    //   this.queryCategoryData.subjectId = this.subject.id;
    //   this.queryCategoryData.subjectColumnId = this.subject.fileColumnIds[0];
    //   // this.getCategoryList();
    //   this.getFilelist();
    // }
  },
  methods: {
    playVideo(url) {
      // this.player.src([
      //   {
      //     src: url,
      //     type: "application/x-mpegURL", // 告诉videojs,这是一个hls流
      //   },
      // ]);
      this.player.src(url);
      this.player.play();
    },
    /**
     * @description: 获取文件列表
     * @return {void}
     */
    async getFilelist() {
      if (!this.subject.fileColumnIds[0]) {
        return;
      }
      this.confirmLoading = true;
      await File.queryFileByFirstCategory(this.queryCategoryData).then(
        async (res) => {
          res.forEach(item=>{
            if(item.dictName==='演示材料'){
              this.queryCategoryData.dictId = item.id
            }
          })
          this.filelistarray = await File.queryFileBySecondCategory(
            this.queryCategoryData
          );
        }
      );
      let data2 = {
        dataType: 1,
        moduleId: this.subject.moduleId,
        subjectId: this.subject.id,
        subjectColumnId: this.subject.fileColumnIds[0],
        dictItemId: "",
        name: "",
        pageNo: 1,
        pageSize: 10,
      };
      this.filelistarray.forEach(async (element, index) => {
        if (element.itemText === "演示视频" && this.dialogTitle === "演示视频") {
          data2.dictItemIds = element.id;
          let res1 = await File.queryFileByCategory(data2);
          this.visibleList = res1.records;
        } else if (element.itemText === "演示文档" && this.dialogTitle === "演示文档") {
          data2.dictItemIds = element.id;
          let res1 = await File.queryFileByCategory(data2);
          this.visibleList = res1.records;
        } else if (element.itemText === "演示模型" && this.dialogTitle === "演示模型") {
          data2.dictItemIds = element.id;
          let res1 = await File.queryFileByCategory(data2);
          this.visibleList = res1.records;
        }
        this.firstlist = this.visibleList
      });
       
      this.confirmLoading = false;
    },
    getfirstlist(){
      this.visibleList=this.firstlist
      this.breadcrumbList=[]
    },
    /**
     * @description: 判断点击时的文件类型，文件or文件夹
     * @param {Object} value 点击项
     * @return {void}
     */
    handleClick(value){
      value.fileType == 2?this.openfolder(value):this.previewFile(value)
    },
    /**
     * @description: 预览文件
     * @param {Object} value 点击项
     * @return {void}
     */
    async previewFile(value) {
      this.clickitem=value
      const res=await File.downLoadFile({
        fileId:value.id,
        isCurrent:1,
        optType:2,//1.下载，2.预览
      })
      let url=res.downUrl
      let type = url.split(".").pop();
      // if(value.fileSavePath.split(".").pop()=='mp4'){
      //   url = value.fileSavePath
      //   type = "mp4"
      // }
      if (type == "mp4"||type=="webm") {
        this.isvideo = true;
        this.isfile=false;
        this.unsupported=false;
        this.isimg = false;
        this.playVideo(url)
      }else if (type == 'png' || type === 'jpg' || type == "svg" || type === 'jpeg') {
        this.isvideo = false;
        this.isfile = false;
        this.unsupported = false;
        this.isimg=true;
      } else{
        this.isimg = false;
        this.isvideo=false;
        this.isfile=true;
        this.filesrc = res.downUrl;
      }
    },
    /**
     * @description:展开文件夹
     * @param {Object} value 点击项
     * @return {void}
     */
    async openfolder(value) {
      let data2 = {
        folderId: value.id,
        name: "",
        sort: 'createTime',
        sortType: 'desc',
        pageNo: 1,
        pageSize: 99,
      }
      this.breadcrumbList.push(value)
      let res1 = await File.queryFileByFolderId(data2)
      this.visibleList = res1.records;
    },
    // /**
    //  * @description: currentPage 改变时会触发
    //  * @param {Number} value 当前页
    //  * @return {void}
    //  */
    // handleCurrentChange(value) {
    //   this.queryData.pageNo = value;
    //   this.getTableData();
    // },
    /**
     * @description: 点击面包屑获取文件列表
     * @param {Object} value 点击项
     * @param {Number} index 索引
     * @return {void}
     */
    jumpTo(value, index, type) {
      if (index === this.breadcrumbList.length - 1) {
        return;
      }
      if (value) {
        this.breadcrumbList.splice(index);
        this.queryData.folderId = value.id;
      } else {
        this.breadcrumbList = [];
        this.queryData.folderId = this.folder.id;
      }
      this.queryData.name = "";
      this.openfolder(value, type)
    },
    /**
     * @description: 打开文件夹
     * @param {Object} value 文件夹
     * @return {void}
     */
    handleOpenfolder(value) {
      const index = this.breadcrumbList.findIndex(
        (item) => item.id === value.id
      );
      index === -1 && this.breadcrumbList.push(value);
      this.queryData.folderId = value.id;
      this.queryData.name = "";
      this.getTableData(1);
    },
    /**
     * @description: 预览
     * @param {Object} value 点击项
     * @return {void}
     */
    handleView(value) {
      if (value.isOffice) {
        this.officeView(value.id);
      }
    },
    // 文件预览
    officeView(id) {
      const url = `${process.env.VUE_APP_URL_NETDISK}/public-file-system/fs/file/preview/viewOrEdit?fileId=${id}&optType=view`;
      // 发送请求
      axios(url, {
        headers: {
          "Tenant-Id": this.userInfo.tenantIdNow,
          "Sys-Access-Token": "JqjdLEtaFOas5whS8hro2w==",
          "X-Access-Token": this.token,
        },
      }).then((response) => {
        if (response.status == 200) {
          this.fileUrl= response.data
        }
      });
    },
    /**
     * @description: 关闭左侧列表
     * @return {void}
     */
    closeList(){
      this.close=!this.close
    },
    formatFileType(url) {
      let type = url.split(".").pop();
      if (type == 'mp4' || type == "avi" || type == "rmvb") {
        return 'preview-video'
      } else if (type == 'png' || type === 'jpg' || type == "svg" || type === 'jpeg') {
        return 'preview-image'
      } else if (type == 'pak' || type == 'rvt' || type == '3dxml' || type == 'gim' || type == 'ifc' || type == 'igms' || type == 'fbx' || type == '3dm' || type == 'stp' || type == 'gltf' || type == 'skp' || type == 'glb' || type == 'dgn' || type == 'nwd' || type == 'nwc') {
        return 'preview-model'
      } else {
        return 'preview-file'
      }
    }
  },
  filters: {
    /**
      * @description: 类型
      * @return {String} 类型
    */
    formatFileType(type) {
      if(type == 'mp4' || type == "avi" || type == "rmvb" ){
        return 'preview-video'
      }else if(type == 'png' || type === 'jpg' || type == "svg" || type === 'jpeg'){
        return 'preview-image'
      }else if(type =='pak'||type =='rvt'||type =='3dxml'||type =='gim'||type =='ifc'||type =='igms'||type =='fbx'||type =='3dm'||type =='stp'||type =='gltf'||type =='skp'||type =='glb'||type =='dgn'||type =='nwd'||type =='nwc'){
        return 'preview-model'
      }else{
        return 'preview-file'
      }
    }
  },
};
</script>

<style lang="scss" scoped>
.filelist {
  height: 100%;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 12;
  background-color: rgba(40, 47, 57, 0.8);
  display: flex;
  padding: 80px;
  color: #fff;
  box-sizing: border-box;

  .file-left {
    width: 300px;
    padding: 0 20px 0 26px;
    background-color: rgb(40, 47, 57);

    .file-left-title {
      height: 60px;
      line-height: 60px;
      border-bottom: 1px solid #35424d;
      display: flex;
      font-size: 16px;
      justify-content: space-between;
      img{
        height: 24px;
        width: 24px;
        vertical-align: middle;
      }
    }

    .file-left-breadcrumb {
      flex: 1;
      margin-top: 15px;
      color: #8f99a8;
      overflow: hidden;
      // height: 20px;
      font-size: 16px;
      ::v-deep .el-breadcrumb {
        overflow-x: auto;
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        -ms-overflow-style: none;
        overflow: -moz-scrollbars-none;

        &::-webkit-scrollbar {
          display: none;
        }

        .el-breadcrumb__item {
          float: none;
          flex-shrink: 0;
          .el-breadcrumb__inner {
            // font-weight: 700;
            color: #485875;
            cursor: pointer;
            line-height: 24px;
            font-size: 16px;
            &:hover{
              text-decoration: underline;
              color: #fff;
            }
          }
          .el-icon-arrow-right:before{
            color: #485875;
          }
          .el-breadcrumb__separator[class*=icon] {
            margin: 0;
          }

          &:last-child {
            .el-breadcrumb__inner {
              font-weight: 400;
              cursor: not-allowed;
              &:hover{
                color: #485875;
                text-decoration: none;
              }
            }
          }
        }
      }
    }

    .file-left-list {
      margin-top: 16px;
      color: #8f99a8;

      .list-item {
        display: flex;
        width: 100%;
        height: 42px;
        line-height: 42px;
        font-size: 16px;
        padding: 0 10px;
        // overflow: hidden;

        .list-item-name {
          width: 200px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          position: relative;
        }

        .svg-icon {
          margin-top: 11px;
          margin-right: 10px;
        }

        &:hover {
          border-radius: 5px;
          background: #39414d;
        }
      }
      .active{
        border-radius: 5px;
        background: #39414d;
      }
    }
  }

  .file-right {
    flex: 1;
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    position: relative;
    background-color: #838c93;

    video {
      max-height: 900px;
    }
    .file-right-closeicon{
      position: absolute;
      left: 11px;
      top: 16px;
      z-index: 10;
      img{
        height: 24px;
        width: 24px;
      }
    }
  }

  .file-close {
    height: 30px;
    width: 30px;
    position: absolute;
    top: 10px;
    right: 10px;
    border-radius: 5px;
    z-index: 14;
    img {
      height: 20px;
      width: 20px;
      margin-left: 5px;
      margin-top: 5px;
    }

    &:hover {
      background-color: rgba(0, 0, 0, 0.3);
    }
  }
  .unsupported{
    height: 100%;
    width: 100%;
    position: relative;
    div{
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%,-50%);
    }
  }
}
.video-js[tabindex="-1"] {
    outline: none;
    height: 100%;
    width: 100%;
}
::v-deep .el-breadcrumb__inner {
  color: #fff;
}
</style>