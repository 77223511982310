<template>
  <el-dialog append-to-body class="ss-dialog no-header-bottom" title="邀请成员" width="560px" :visible.sync="dialogVisible">
    <div v-loading="loading" class="dialog-body">
      <iframe v-if="ready" style="width: 100%; height: 100%;border: 0;" :src="src" />
    </div>
  </el-dialog>
</template>

<script>
import { Login } from "@/api/Login";
export default {
  props: {
    visible: {
      type: Boolean,
      default: false
    },
  },
  data() {
    return {
      loading: true,
      ready: false,
      productId: "", // 产品 id
    }
  },
  computed: {
    dialogVisible: {
      get() {
        return this.visible;
      },
      set(val) {
        this.$emit('update:visible', val);
      }
    },
    src() {
      return `${process.env.VUE_APP_WEBSITE_SAAS}inviteMembers?redirect=true&product=${this.productId}`
    },
  },
  mounted() {
    this.getProductId();
    window.addEventListener("message", this.getMessageFromIframe, false);
  },
  beforeDestroy() {
    window.removeEventListener("message", this.getMessageFromIframe, false);
  },
  methods: {
    /**
     * @description: 获取产品id
     * @return {void}
     */
    async getProductId() {
      this.productId = await Login.getSystemProductId();
      this.ready = true;
      this.loading = false;
    },
    /**
     * @description: 获取 iframe 返回的信息
     * @param {Object} data
     * @return {void}
     */
    getMessageFromIframe({ data }) {
      if (data.back) {
        this.dialogVisible = false;
      }
    },
  }
}
</script>

<style lang="scss" scoped>
.dialog-body {
  height: 600px;
  padding: 0 30px;
  border-radius: 0 0 10px 10px;
  background-color: #fff;
}
</style>