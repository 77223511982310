import request from "@/utils/appServer";
class Management {
  /* -------模块分组-------- */
  // 添加分组
  static async addGroup(data = {}) {
    try {
      const res = await request.post('/sales-show-4.0/moduleGroup/addGroup', data);
      return res.data.code === 200 && res.data.result ? res.data.result : false;
    } catch (error) {
      return false;
    }
  }
  // 编辑分组
  static async editGroup(data = {}) {
    try {
      const res = await request.post('/sales-show-4.0/moduleGroup/editGroup', data);
      return res.data.code === 200 && res.data.result ? res.data.result : false;
    } catch (error) {
      return false;
    }
  }
  // 编辑分组
  static async updateGroup(data = {}, type) {
    try {
      const res = await request.post(`/sales-show-4.0/moduleGroup/${type}Group`, data, true);
      return res.data.code === 200 && res.data.result ? res.data.result : false;
    } catch (error) {
      return false;
    }
  }
  // 删除分组
  static async deleteGroup(id) {
    try {
      const res = await request.get('/sales-show-4.0/moduleGroup/deleteGroup', { params: { id } }, true);
      return res.data.code === 200;
    } catch (error) {
      return false;
    }
  }
  // 获取分组列表
  static async getGroupList() {
    try {
      const res = await request.get('/sales-show-4.0/moduleGroup/queryGroupList');
      return res.data.code === 200 && res.data.result ? res.data.result : [];
    } catch (error) {
      return [];
    }
  }

  /* -------用户管理-------- */
  // 获取租户下的所有用户
  static async getTenantUserList(data) {
    try {
      const res = await request.get('/sales-show-4.0/sys/tenant/getTenantUserList', { params: data });
      return res.data.code === 200 && res.data.result ? res.data.result : false;
    } catch (error) {
      return false;
    }
  }
  // 编辑租户下用户信息
  static async editUserInfo(data) {
    try {
      const res = await request.put('/sales-show-4.0/sys/user/editUserInfo',data);
      return res.data.code === 200 && res.data.result ? res.data.result : false;
    } catch (error) {
      return false;
    }
  }
  // 新增租户下用户
  static async addUser(data) {
    try {
      const res = await request.post('/sales-show-4.0/sys/user/addUser',data,true);
      return res.data.code === 200 && res.data.result ? res.data.result : false;
    } catch (error) {
      return false;
    }
  }
  // 编辑租户下用户状态变更
  static async updateUserStatus(data) {
    try {
      const res = await request.put('/sales-show-4.0/sys/user/updateUserStatus',data,true);
      return res.data.code === 200 && res.data.result ? res.data.result : false;
    } catch (error) {
      return false;
    }
  }
  // 移除企业成员
  static async removeUser(data) {
    try {
      const res = await request.delete('/sales-show-4.0/sys/tenant/removeUser',{ params: data },true);
      return res.data.code === 200
    } catch (error) {
      return false;
    }
  }
  // 根据租户Id获取组织结构树
  static async departTreeByTenantId(data) {
    try {
      const res = await request.get('/sales-show-4.0/sys/depart/departTreeByTenantId', { params: data });
      return res.data.code === 200 && res.data.result ? res.data.result : false;
    } catch (error) {
      return false;
    }
  }
  // 添加部门
  static async addDepart(data) {
    try {
      const res = await request.post('/sales-show-4.0/sys/depart/add',data,true );
      return res.data.code === 200 && res.data.result ? res.data.result : false;
    } catch (error) {
      return false;
    }
  }
  // 删除部门
  static async deleteDepart(id) {
    try {
      const res = await request.delete('/sales-show-4.0/sys/depart/delete',{ params: {id} });
      return res.data.code === 200
    } catch (error) {
      return false;
    }
  }
  // 根据部门id获取用户分页列表
  static async queryUserPageByDepartId(data) {
    try {
      const res = await request.post('/sales-show-4.0/sys/depart/queryUserPageByDepartId', data);
      return res.data.code === 200 && res.data.result ? res.data.result : false;
    } catch (error) {
      return false;
    }
  }

}

export { Management }