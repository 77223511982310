import { IBaseTableData } from "./IBaseTableData";
import { TColumnType, TColumnaAlign } from "./ColumnTypes";
import { BaseColumn } from "./BaseColumn";
import { IconColumn } from "./IconColumn";
import { ButtonColumn } from "./ButtonColumn";
import { ColumnFilter, StringFilter, NumberFilter, DateTimeFilter, KeyStrValueFilter } from "./ColumnFilter";
import { KeyStrValuePair } from "../KeyValuePair";
import { getDefaultCellAlign } from "@/flexible-table-module/constants";

/** 列的定义 */
export class Column<T extends IBaseTableData = IBaseTableData> extends BaseColumn<keyof T & string> {

    // public columnType: "normal" = "normal";

    /** 传给后台用于排序的字段(如果本字段没有指定或为空字符串，而且本列又被用于排序的话，则用 id 字段的值作为排序字段) */
    public sortName?: string;
    /** 列数据的类型 */
    public type: TColumnType;
    /** 单元格是否可点击 */
    public clickable: boolean;
    /** 是否可排序 */
    public sortable: boolean;
    /** 是否可筛选 */
    public filterable: boolean;
    /** 是否异步加载筛选的内容 */
    public filterAsync: boolean;
    /** 筛选的内容 */
    public filter?: ColumnFilter;
    /** 单元格内是否有下拉框 */
    public selectable: boolean;
    /** 获取单元格下拉框的选项(同一列每个单元格下拉框的选项一致时，本字段才有使用的意义) */
    public selectList?: KeyStrValuePair[];
    /** 内嵌的列(单元格右上角提示) */
    public innerColumn?: Column;
    /** 内嵌的头像列(文字内容左边的头像图标) */
    public iconColumn?: IconColumn;
    /** 内嵌的按钮列 */
    public buttonColumns?: ButtonColumn[];

    /** 是否必填项(用于前端新建表单时做非空判断) */
    public required: boolean;
    /** 是否非修改项(如果是，则前端不可提交或修改本项，本项的值由后端生成) */
    public unmodifiable: boolean;

    public isColumnFilterLoading: boolean = false; // 前端内部使用

    public setFilter(v: ColumnFilter | null | undefined) {
        this.filter = this.geneFilter(v);
    }

    constructor(params: {
        id: keyof T & string,
        sortName?: string,
        type: TColumnType,
        headerAlign?: TColumnaAlign,
        cellAlign?: TColumnaAlign,
        width?: number,
        minWidth?: number,
        clickable?: boolean,
        sortable?: boolean,
        filterable?: boolean,
        filterAsync?: boolean,
        filter?: ColumnFilter,
        selectable?: boolean,
        selectList?: KeyStrValuePair[],
        innerColumn?: Column,
        iconColumn?: IconColumn,
        buttonColumns?: ButtonColumn[],
        buttonPosition?: 'left' | 'right',

        required?: boolean,
        unmodifiable?: boolean,

        active?: boolean,
        alwaysActivated?: boolean,
        settingName?: string,
        title?: string,
        fieldOrder?: number,
    }) {
        const {
            id,
            sortName,
            type,
            clickable = false,
            sortable = true,
            filterable = true,
            filterAsync = false, // 默认不异步加载，在加载 Column 时就确定下来不变，需后端特别指定，才异步加载
            filter,
            selectable = false,
            selectList,
            innerColumn,
            iconColumn,
            buttonColumns,

            required = false,
            unmodifiable = false,

            active,
            alwaysActivated,
            settingName,
            title,
            fieldOrder,
            headerAlign,
            cellAlign,
            width, // 默认值 undefined
            minWidth, // 默认值 undefined
        } = params;

        let _cellAlign: TColumnaAlign;
        if (!cellAlign) {
            _cellAlign = getDefaultCellAlign(type);
        } else _cellAlign = cellAlign;

        super({ id, active, alwaysActivated, settingName, title, fieldOrder, headerAlign, cellAlign, width, minWidth });

        this.sortName = sortName;
        this.type = type;
        this.clickable = clickable;
        this.sortable = sortable;
        this.filterable = filterable;
        this.filterAsync = filterAsync;
        this.selectable = selectable;
        this.selectList = selectList;

        this.required = required;
        this.unmodifiable = unmodifiable;

        if (innerColumn) {
            if (innerColumn instanceof Column) this.innerColumn = innerColumn;
            else this.innerColumn = new Column(innerColumn);
        }

        if (iconColumn) {
            if (iconColumn instanceof IconColumn) this.iconColumn = iconColumn;
            else this.iconColumn = new IconColumn(iconColumn);
        }

        if (buttonColumns && buttonColumns.length > 0) {
            this.buttonColumns = [];
            for (const buttonColumn of buttonColumns) {
                if (buttonColumn instanceof ButtonColumn) this.buttonColumns.push(buttonColumn);
                else this.buttonColumns.push(new ButtonColumn(buttonColumn));
            }
        }

        if (this.filterAsync) this.filter = undefined;
        else this.filter = this.geneFilter(filter);

        // 如果最小宽度未设置，则根据 type 设置最小宽度的默认值

        if (!this.minWidth) {
            switch (this.type) {
                // case "string": {
                //     this.minWidth = 50;
                //     break;
                // }
                // case "number": {
                //     this.minWidth = 50;
                //     break;
                // }
                case "time": {
                    this.minWidth = 100;
                    break;
                }
                case "date": {
                    this.minWidth = 110;
                    break;
                }
                case "datetime": {
                    this.minWidth = 150;
                    break;
                }
                case "time-frame": {
                    this.minWidth = 150;
                    break;
                }
                case "date-frame": {
                    this.minWidth = 180;
                    break;
                }
                case "datetime-frame": {
                    this.minWidth = 280;
                    break;
                }
                default:
                    break;
            }
        }
    }

    private geneFilter(v: ColumnFilter | null | undefined) {
        let filter;
        if (v) {
            filter = v;
        } else {
            if (this.type) {
                switch (this.type) {
                    case 'string':
                    case 'rich-text': {
                        filter = new StringFilter({ type: "string-search" });
                        break;
                    }
                    case 'number': {
                        filter = new NumberFilter({});
                        break;
                    }
                    case 'time':
                    case 'time-frame':
                    case 'date':
                    case 'date-frame':
                    case 'datetime':
                    case 'datetime-frame':
                    case 'short-datetime': {
                        filter = new DateTimeFilter({ type: 'date-time-frame' });
                        break;
                    }
                    case 'key-str-value': {
                        filter = new KeyStrValueFilter({ type: "key-str-value-search" });
                        break;
                    }
                }
            } else {
                throw new Error("filter 赋值为空时，type 字段必须已赋值")
            }
        }
        return filter;
    }
}