<template>
  <el-dialog append-to-body class="ss-dialog" title="添加目录" width="460px" :visible.sync="dialogVisible">
    <div class="content">
      <el-form label-width="100px" ref="form" :model="folder" :rules="rules">
        <el-form-item label="文件夹名称" prop="folderName">
          <el-input v-model.trim="folder.folderName" placeholder="请输入文件夹名称"></el-input>
        </el-form-item>
        <el-form-item label="说明" prop="folderDescribe">
          <el-input v-model="folder.folderDescribe" show-word-limit class="textarea" placeholder="请输入说明" type="textarea"
            :autosize="{ minRows: 6 }" :maxlength="100"></el-input>
        </el-form-item>
      </el-form>
    </div>
    <div slot="footer">
      <el-button type="info" @click="dialogVisible = false">取 消</el-button>
      <el-button type="primary" :loading="confirmLoading" @click="handleConfirm">确 定</el-button>
    </div>
  </el-dialog>
</template>

<script>
import { File } from "@/api/File";
export default {
  name: "NewFolderDialog",
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    item: Object, // 事项
    parentId: String, // 父文件夹id
    queryId: Object, // 模块、主题、栏目id
    categoryId: Object, // 一级、二级分类id
    api:String,//请求
  },
  data() {
    return {
      folder: {
        dataType: 1, // 类型(1.主题，2.模板)
        dictDTO: {
          dictId: "",
          dictItemId: "",
        },
        moduleId: "", // 模块id	
        subjectId: "", // 主题id
        subjectColumnId: "", // 栏目id
        parentId: "", // 父文件夹id
        folderName: "",
        folderDescribe: "",
      },
      rules: {
        folderName: { required: true, message: "请输入文件夹名称", trigger: ["blur", "change"] },
      },
      confirmLoading: false,
    }
  },
  computed: {
    dialogVisible: {
      get() {
        return this.visible;
      },
      set(val) {
        this.$emit('update:visible', val);
      }
    },
  },
  mounted() {
    this.folder = { ...this.folder, ...this.queryId };
    this.folder.parentId = this.parentId;
  },
  methods: {
    /**
     * @description: 确定
     * @return {void}
     */
    handleConfirm() {
      this.$refs.form.validate(async valid => {
        if (!valid) {
          return
        }
        this.confirmLoading = true;
        const res = await File[this.api](this.folder);
        this.confirmLoading = false;
        if (res) {
          this.dialogVisible = false;
          this.$emit("finish");
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.content {
  padding: 20px 30px 0;

  .textarea {
    ::v-deep .el-textarea__inner {
      font-size: 14px;
      font-weight: 500;
      color: #344563;

      &::placeholder {
        color: #c3cdde;
      }
    }
  }
}
</style>