<template>
  <div class="datetime-single">
    <template v-if="type === 'time'">
      <ElTimePicker
        class="picker"
        v-model="value"
        placeholder="选择时间点"
        :format="TIME_FORMAT"
      >
      </ElTimePicker>
    </template>
    <template v-else-if="type === 'date'">
      <ElDatePicker
        class="picker"
        v-model="value"
        type="date"
        placeholder="选择日期"
        :format="DATE_FORMAT"
      >
      </ElDatePicker>
    </template>
    <template v-else-if="type === 'datetime' || type === 'short-datetime'">
      <ElDatePicker
        class="picker"
        v-model="value"
        type="datetime"
        placeholder="选择日期时间点"
        :format="DATE_FORMAT"
      >
      </ElDatePicker>
    </template>

    <SubmitAndCancel @submit="onSubmit" @cancel="onCancel" />
  </div>
</template>
<script lang="ts">
import Vue from "vue";
import {
  TimePicker as ElTimePicker,
  DatePicker as ElDatePicker,
} from "element-ui";

import SubmitAndCancel from "../../Common/SubmitAndCancel.vue";
import { DATE_FORMAT, TIME_FORMAT } from "@/flexible-table-module/constants";

export default Vue.extend({
  inheritAttrs: false,
  name: "DateTimeSingle",
  components: { ElTimePicker, ElDatePicker, SubmitAndCancel },
  props: {
    datetime: { type: Date },
    type: { type: String },
  },
  data() {
    return {
      value: this.datetime,
      DATE_FORMAT,
      TIME_FORMAT,
    };
  },
  methods: {
    onSubmit() {
      this.$emit("submit", this.value);
    },
    onCancel() {
      this.value = this.datetime;
      this.$emit("cancel");
    },
  },
});
</script>
<style lang="scss" scoped>
.datetime-single {
  .picker {
    width: 100%;
  }
}
</style>