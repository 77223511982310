<template>
  <div class="product-association-article">
    <div class="association-article-list">
      <div class="association-article-list-main">
        <el-tree ref="Categoriestree" :data="assarticleList" :props="articleprops" :expand-on-click-node="false"
          :default-expand-all="true" :show-checkbox="true" node-key="articleId" :default-checked-keys="selectids"
          @check="getCheckedKeys">
          <span class="custom-tree-node" @click="handleClick(data.articleId)" slot-scope="{ node, data }">
            <span>{{ node.label }}</span>
          </span>
        </el-tree>
      </div>
    </div>
    <div class="association-article-preview">
      <UpanddownAround v-if="currentItem && currentItem.article.templateType == '2'" :current="currentItem"
        :readonly="true" />
      <LayoutAround v-else :current="currentItem" :readonly="true" />
    </div>
  </div>
</template>

<script>
import { ProductLibrary } from "../../../../api/ProductLibrary";
import { Article } from "../../../../api/Article";
import UpanddownAround from "../../../ArticleView/template/UpanddownAround.vue";
import LayoutAround from "../../../ArticleView/template/LayoutAround.vue";
export default {
  components: { UpanddownAround, LayoutAround },
  props: {
    activeColumn: Object,
    subject: Object,
    alreadychose: Array,
    ispre: {
      type: Boolean,
      default: false,
    },
    scheme: Object,
  },
  data() {
    return {
      articleprops: {
        label: "toArticleName",
        children: "children",
      },
      dialogVisible: false,
      assarticleList: [],
      currentItem: null,
      selectids: [], //已经选择的文章id合集
    };
  },
  watch: {
    selectids(val) {
      const selectdata = {
        dataIds: val,
        dataType: 5,
        schemeId: "",
        subjectColumnId: this.activeColumn.id,
        subjectId: this.subject.id,
      };
      this.$emit("handleselect", selectdata);
    },
    activeColumn(){
      this.getList();
    },
  },
  mounted() {
    this.getList();
    if(this.alreadychose.length){
      this.selectids=this.alreadychose[0].dataIds
    }else{
      this.getdefaultList()
    }
  },
  methods: {
    /**
     * @description: 获取已关联文章列表
     * @return {void}
     */
    async getdefaultList() {
      const res = await ProductLibrary.getSchemeToArticleList({
        schemeId: this.scheme.id,
        subjectColumnId: this.activeColumn.id,
      });
      if (res.length) {
        this.selectids=res.map(item=>item.dataId)
      }
    },
    /**
     * @description: 获取文章列表
     * @return {void}
     */
    async getList() {
      const res = await ProductLibrary.getSubjectToArticleList({
        columnId: this.activeColumn.id,
        subjectId: this.subject.id,
      });
      this.assarticleList = res;
      if (this.assarticleList.length) {
        this.handleClick(this.assarticleList[0].articleId);
      }
    },
    /**
     * @description: 预览文章
     * @param {Object} value 文章id
     * @return {void}
     */
    async handleClick(id) {
      const res = await Article.queryArticleDetail(id);
      res && (this.currentItem = res);
    },
    getCheckedKeys(data, checkdata) { 
      this.selectids = checkdata.checkedKeys
    },
    handleLabelClick() { },
  },
};
</script>
<style lang="scss" scoped>
.product-association-article {
  display: flex;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  padding: 1px 0;
  background-color: #fff;
  overflow: hidden;

  .association-article-list {
    height: 100%;
    width: 223px;
    border-right: 1px solid #EBEEF2;
    box-sizing: border-box;
    overflow-y: auto;

    .association-article-list-main {
      padding: 0 10px;
      box-sizing: border-box;

      ::v-deep .el-tree-node__content {
        margin-top: 16px;
        font-weight: 700;
        font-size: 14px;
      }
    }
  }

  .association-article-preview {
    flex: 1;
    overflow-y: auto;
  }
}

.active-color {
  color: var(--color-primary);
}
</style>
