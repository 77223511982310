<template>
  <div class="w-h-100" @drop="handleDrop" @dragover="allowDrop">
    <div class="view-name" v-if="clickitem">{{ clickitem.name }}</div>
    <div class="view-main">
      <ShowProject :key="projectkey" v-if="subject" :subject="subject" :ispre="true" />
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { Playeditor } from "@/api/Playeditor";
import { ProductLibrary } from "@/api/ProductLibrary";
import ShowProject from "@/views/ProdudtEdito/components/ShowProject.vue";
export default {
  components: { ShowProject },
  props: {
    ispre: {
      type: Boolean,
      default: false,
    },
    nodeid: String,
    demoId: String,
    // subject: Object,
  },
  data() {
    return {
      clickitem: null,
      visible: false,
      payload: null,
      subject: null,
      projectkey: 1,
    };
  },
  computed: {
    ...mapGetters(["seleceassData"]),
  },
  watch: {
    nodeid() {
      this.getNodeDetail();
    },
    seleceassData(val) {
      if (val) {
        this.addSubject(val);
      }
    },
  },
  mounted() {
    this.getNodeDetail();
    this.$Pubsub.publish("update-resource-visible", { visible: true, type: [1] });
  },
  methods: {
    /**
     * @description: 获取节点关联详情
     * @return {void}
     */
    async getNodeDetail() {
      const res = await Playeditor.querySubjectDemoModeNodeDetail(
        this.demoId,
        this.nodeid,
        1
      );
      if (res && res[0]) {
        this.payload = {
          subjectId: res[0].subjectId,
          mirrorId: res[0].mirrorId,
          versionId: res[0].versionId,
        };
        this.getDetail();
        this.clickitem = res[0];
        this.visible = true;
        this.$Pubsub.publish("update-resource-select-data", [res[0].subjectId]);
      } else {
        this.clickitem = null;
      }
    },
    /**
     * @description: 获取主题详情
     * @return {void}
     */
    async getDetail() {
      let res = await ProductLibrary.getSubjectDetail(this.payload);
      if (res) {
        this.subject = res;
      }
      this.projectkey++;
    },
    /**
     * @description: 添加拖拽过来的产品
     * @return {void}
     */
    async handleDrop(event) {
      if (this.ispre) {
        return;
      }
      event.preventDefault();
      const subject = JSON.parse(event.dataTransfer.getData("dragsubject"));
      this.addSubject(subject);
    },
    // 拖拽事件
    allowDrop(event) {
      event.preventDefault();
    },
    async addSubject(subject) {
      let asspayload = {
        saveOutlineToResource: {
          name: subject.name,
          outlineNodeId: this.nodeid,
          type: 1,
          subjectId: subject.id,
          versionId: subject.versionId,
          mirrorId: subject.mirrorId,
        },
      };
      if (this.clickitem) {
        this.$showMessage(
          {
            message: `已经有关联产品，此操作将会覆盖之前的选择`,
            ...this.$global.deleteMessage,
            title: "确定覆盖？",
            confirmButtonText: "确定",
          },
          async (done) => {
            asspayload.saveOutlineToResource.id = this.clickitem.id;
            const res = await Playeditor.subjectDemoModeToResource(asspayload);
            if (res) {
              this.getNodeDetail();
              this.$Pubsub.publish("update-mind-name-data", {
                id: this.nodeid,
                name: asspayload.saveOutlineToResource.name + `(${subject.moduleName})`,
              });
            }
            done(true);
          }
        );
      } else {
        const res = await Playeditor.subjectDemoModeToResource(asspayload);
        if (res) {
          this.getNodeDetail();
          this.$Pubsub.publish("update-mind-name-data", {
            id: this.nodeid,
            name: asspayload.saveOutlineToResource.name + `(${subject.moduleName})`,
          });
        }
      }
    },
    handleDelete(value) {
      this.$showMessage(
        {
          message: `是否确认删除该链接 ?`,
          ...this.$global.deleteMessage,
        },
        async (done) => {
          const res = await Playeditor.deleteShowSubjectDemoLink(value.linkId); 
          res && this.getNodeDetail();
          done(true);
        }
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.view-name {
  color: var(--color-text-primary);
  height: 40px;
  line-height: 40px;
  padding-left: 18px;
  font-weight: 700;
  box-sizing: border-box;
  border-bottom: 1px solid #d3dbeb;
}
.view-main {
  width: 100%;
  height: calc(100% - 40px);
}
</style>
