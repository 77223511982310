<template>
  <div
    class="selection-tags"
    :class="{ shrinked: isShrinked }"
    @mouseenter="onSelectionTagsMouseenter"
    @mouseleave="onSelectionTagsMouseleave"
  >
    <div class="selection-tags__inner" :style="{ borderColor }">
      <span class="selection-tags__title" ref="title">
        <slot name="title"></slot>
      </span>
      <div class="selection-tags__content" ref="content">
        <template v-for="(tag, index) in tags">
          <SelectionTagItem
            :key="index"
            @tag-close="onTagClose(tag, index)"
            ref="tagItems"
            >{{ tag.text }}</SelectionTagItem
          >
        </template>
      </div>
      <div
        class="selection-tags__clear"
        v-if="tags.length > 1"
        :style="{ visibility: isBtnClearVisible ? 'visible' : 'hidden' }"
      >
        <div class="btn-clear" @click.stop="onBtnClearClick">
          <svg-icon
            sys="common-table"
            name="FilterConditions/delete"
            :size="20"
          />
        </div>
      </div>
    </div>
    <!-- <slot name="suffix"></slot> -->
  </div>
</template>

<script lang="ts">
import Vue, { PropType } from "vue";

import SvgIcon from "@/flexible-table-module/svg-icon-importer";

import SelectionTagItem from "@/flexible-table-module/components/Common/SelectionTags/SelectionTagItem.vue";
import { ResizeObserverWrapper } from "@/flexible-table-module/util/ResizeObserverWrapper";

export default Vue.extend({
  name: "SelectionTags",
  components: { SvgIcon, SelectionTagItem },
  props: {
    isShrinked: { type: Boolean }, // 是否收缩(水平方向)，收缩的话会把一行放不下的内容隐藏
    tags: {
      type: Array as PropType<{ text: string; attachment?: any }[]>,
      default: () => [],
    }, // 标签数组
    borderColor: { type: String, default: "white" }, // 边框颜色(默认透明)
  },
  data() {
    const ellipsisStr = '<div class="ellipsis">...</div>';
    const ellipsisEl = document.createElement("div");
    ellipsisEl.innerHTML = ellipsisStr;

    return {
      reszObsvWrapper: null as ResizeObserverWrapper | null,
      ellipsis: ellipsisEl.firstChild as HTMLElement,
      isBtnClearVisible: false,
    };
  },
  computed: {},
  watch: {
    isShrinked: {
      immediate: false,
      async handler(isShrinked: boolean) {
        if (isShrinked) this.hideNonFirstRows();
        else this.showAllRows();
      },
    },
    "tags.length": {
      immediate: true,
      async handler(length: number) {
        if (this.isShrinked) this.hideNonFirstRows();
      },
    },
  },
  mounted() {
    const content = this.$refs.content as HTMLElement;
    this.reszObsvWrapper = new ResizeObserverWrapper(content);
    this.reszObsvWrapper.afterResized(() => {
      // const tagEls = this.getTagEls();
      // console.log("content resized", tagEls);
      if (this.isShrinked) this.hideNonFirstRows();
    });
  },
  methods: {
    onTagClose(tag: { text: string; attachment?: any }, index: number) {
      this.$emit("tag-close", tag, index);
    },

    onBtnClearClick() {
      this.$emit("tags-clear");
    },

    // 当鼠标进入 SelectionTags 时，显示组删除按钮
    onSelectionTagsMouseenter() {
      this.isBtnClearVisible = true;
    },

    // 当鼠标离开 SelectionTags 时，隐藏组删除按钮
    onSelectionTagsMouseleave() {
      this.isBtnClearVisible = false;
    },

    // 隐藏非第一行的标签
    async hideNonFirstRows() {
      await this.$nextTick();

      const tagEls = this.getTagEls();

      if (tagEls.length === 0) return;

      const firstTagTop = tagEls[0].getBoundingClientRect().top;
      for (let i = 1; i < tagEls.length; i++) {
        if (tagEls[i].getBoundingClientRect().top > firstTagTop) {
          // 如果标签在第二行或后面的行，就隐藏它
          tagEls[i].style.visibility = "hidden";
        } else {
          // 如果标签在第一行，则显示
          tagEls[i].style.visibility = "";
        }
      }
    },

    // 显示所有标签
    async showAllRows() {
      await this.$nextTick();

      const tagEls = this.getTagEls();

      for (let i = 0; i < tagEls.length; i++) {
        tagEls[i].style.visibility = "";
      }
    },

    // 获取所有的 SelectionTagItem 对应的 DOM 元素
    getTagEls(): HTMLElement[] {
      const refTagItems = this.$refs.tagItems;
      if (refTagItems === undefined) return [];
      const tagItems = refTagItems as Vue[];
      return tagItems.map((item) => item.$el as HTMLElement);
    },
  },
});
</script>

<style lang="scss" scoped>
@import "src/flexible-table-module/scss/_variables.scss";

$size: 32px;

.selection-tags {
  font-size: $fs-selection-tags;

  box-sizing: border-box;
  padding: 1px 4px 1px 0;
  color: $color-base;
  display: inline-flex;
  align-items: center;
  justify-content: flex-start;

  &.shrinked {
    height: 100%;
  }

  &__inner {
    box-sizing: border-box;
    padding: 0 4px;
    width: 100%;
    height: 100%;

    // border: 1px solid $border-color;
    border-width: 1px;
    border-style: solid;
    border-radius: 4px;

    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
  }

  &__title {
    margin-right: 4px;
    line-height: $size;
    flex-shrink: 0;
    user-select: none;
  }

  &__content {
    padding: 4px 0;
    flex-grow: 1;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;
    gap: 4px;
  }

  &__clear {
    width: $size;
    height: $size;
    flex-shrink: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    // padding: 0 4px;
    cursor: pointer;

    .btn-clear {
      width: $size - 8px;
      height: $size - 8px;
      display: flex;
      align-items: center;
      justify-content: center;
      svg {
        fill: $color-base;
      }
    }
  }
}
</style>