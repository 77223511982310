<template>
  <div class="w-h-100 flex app-scheme-wrapper">
    <div
      v-show="demoList.length && showdemo"
      class="app-scheme-left"
      :style="{ width: leftWidth + 'px', minWidth: '20px ' }"
      ref="leftPanel"
    >
      <div class="scheme-title flex-between align-items-center">
        <div class="scheme-title-name">方案提纲</div>
        <div class="flex align-items-center">
          <svg-icon
            class="mr-10 pointer"
            icon="xs-icon-close"
            color="#5E6C84"
            :height="12"
            :width="12"
            @click.native="showdemo = false"
          ></svg-icon>
        </div>
      </div>
      <div class="scheme-left-outline">
        <MindView
          @editornode="editornode"
          :clickoutlineid="clickoutlineid"
          :ispreview="isPreview"
          :active-demo="activeDemo"
        />
      </div>
    </div>
    <!-- <div v-if="demoList.length" class="separator" @mousedown="startResize"></div> -->
    <div v-if="demoList.length" class="app-scheme-main demo-main">
      <div
        v-if="!showdemo"
        class="el-icon-caret-right retract-icon pointer"
        @click="showdemo = true"
      ></div>
      <ContentView
        v-if="
          activeNode &&
          (activeNode.data.outlineType == 1 ||
            activeNode.data.outlineType == 0 ||
            activeNode.isroot)
        "
        :nodeid="clickoutlineid"
        :ispre="isPreview"
      />
      <ProductView
        v-if="activeNode && activeNode.data.outlineType == 2"
        :nodeid="clickoutlineid"
        :demoId="activeDemo.id"
        :ispre="isPreview"
      />
      <ColumnView
        v-if="activeNode && activeNode.data.outlineType == 3"
        :nodeid="clickoutlineid"
        :demoId="activeDemo.id"
        :ispre="isPreview"
      />
      <ArticleView
        v-if="activeNode && activeNode.data.outlineType == 4"
        :nodeid="clickoutlineid"
        :demoId="activeDemo.id"
        :ispre="isPreview"
      />
      <LinkView
        v-if="activeNode && activeNode.data.outlineType == 5"
        :nodeid="clickoutlineid"
        :demoId="activeDemo.id"
        :query-ids="queryIds"
        :ispre="isPreview"
      />
    </div>
    <UpdateDialog
      v-if="updatevisible"
      :visible.sync="updatevisible"
      :current="currentmode"
      :query-ids="queryIds"
      :type="authType"
      @finish="getColumnList"
    />
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import API from "@/utils/apiRequest";
import MindView from "./component/MindView.vue";
import TinyMce from "@/components/TinyMce.vue";
import { Playeditor } from "@/api/Playeditor";
import { ProductLibrary } from "@/api/ProductLibrary";
import UpdateDialog from "./dialog/UpdateDialog.vue";
import ProductName from "@/components/ProductName.vue";
import ContentView from "./component/ContentView.vue";
import ColumnView from "./component/ColumnView.vue";
import ArticleView from "./component/ArticleView.vue";
import LinkView from "./component/LinkView.vue";
import ProductView from "./component/ProductView.vue";
export default {
  props: {
    isunit: {
      type: Boolean,
      default: false, //是否作为组件
    },
    readonly: {
      type: Boolean,
      default: false, //是否只读
    },
    unitqueryids: Object,
    unitdemoid: String,
  },
  components: {
    MindView,
    TinyMce,
    UpdateDialog,
    ProductName,
    ContentView,
    ColumnView,
    ArticleView,
    LinkView,
    ProductView,
  },
  data() {
    return {
      moduleId: "",
      queryIds: {
        moduleId: "",
        subjectId: "",
        versionId: "",
      },
      authType: 0, //演示模式授权 (0.租户共有，1.个人所有)
      outlineTree: {}, // 演示提纲树
      clickoutlineid: "", // 点击的节点id
      demoList: [], //演示模式列表
      activeDemo: null,
      isPreview: true,
      ispre: false,
      updatevisible: false, //编辑/添加弹窗
      currentmode: null,
      temporaryTree: {}, //暂存的演示树
      isfullscreen: false,
      sortData: {}, // 拖拽数据
      isResizing: false,
      startX: 0,
      leftWidth: 300, // 初始左侧div的宽度
      showscheme: true,
      showdemo: true,
      activeNode: null,
      demoId: "",
    };
  },
  computed: {
    // isPreview() {
    //   return this.readonly;
    // },
    ...mapGetters(["moduleTypeDict", "hasNopowerList"]),
  },
  mounted() {
    if (this.unitqueryids) {
      this.queryIds.subjectId = this.unitqueryids.subjectId;
      this.queryIds.versionId = this.unitqueryids.versionId;
    } else {
      this.queryIds.subjectId = this.$route.query.subjectId;
      this.queryIds.versionId = this.$route.query.versionId;
    }
    if (this.unitdemoid) {
      this.demoId = this.unitdemoid;
    } else {
      this.demoId = this.$route.query.demoId;
    }
    
    this.$nextTick(() => {
      this.getColumnList();
    });
  },
  watch: {},
  methods: {
    /**
     * @description: 切换演示
     * @return {void}
     */
    async handleCurrent(value) {
      this.activeDemo = value;
      const res = await Playeditor.querySubjectDemoModeDetail(value.id);
      if (res && res.showProjectOutlineVos) {
        this.outlineTree = res.showProjectOutlineVos[0];
        this.temporaryTree = _.pick(
          res,
          "demoName",
          "subjectId",
          "versionId",
          "moduleId"
        );
        this.temporaryTree.authType = value.authType;
        this.temporaryTree.showProjectOutlineVo = res.showProjectOutlineVos[0];
        this.temporaryTree.demoModeId = value.id;
        this.clickoutlineid = res.showProjectOutlineVos[0].id;
      }
    },
    /**
     * @description: 添加/编辑演示
     * @return {void}
     */
    handleUpdate(value, type) {
      this.authType = type;
      this.currentmode = value;
      this.updatevisible = true;
    },
    /**
     * @description: 删除
     * @param {Object} value 删除项
     * @param {Number} index
     * @return {void}
     */
    handleDelete(value, index) {
      this.$showMessage(
        {
          message: `是否确认删除 <span style="font-weight: 1000;">${value.demoModeName}</span> ?`,
          ...this.$global.deleteMessage,
        },
        async (done) => {
          const res = await Playeditor.deleteSubjectDemoMode({ demoId: value.id });
          done(res);
          if (res) {
            if (this.activeDemo && this.activeDemo.id === value.id) {
              this.activeDemo = index > 0 ? this.demoList[index - 1] : null;
            }
            this.demoList.splice(index, 1);
          }
        }
      );
    },
    /**
     * @description: 更改演示模式状态
     * @param value 点击项
     * @param type 状态
     * @return {void}
     */
    async handleChangeStatus(value, type) {
      let payload = {
        demoModeId: value.id,
        isDefault: value.isDefault,
        isShow: value.isShow,
      };
      payload[type] = value[type] ? 0 : 1;
      value[type] = value[type] ? 0 : 1;
      await Playeditor.settingSubjectDemoMode(payload);
    },
    /**
     * @description: 获取演示列表
     * @return {void}
     */
    async getColumnList() {

      const res = await ProductLibrary.getSubjectDetail(this.unitqueryids);
      this.demoList = res.demoModes ? res.demoModes : [];
      if (this.demoList && this.demoList.length) {
        let index = this.demoList.findIndex((item) => item.isDefault == 1);
        if (this.demoId) {
          index = this.demoList.findIndex((item) => item.id == this.demoId);
        }
        index == "-1"
          ? (this.activeDemo = this.demoList[0])
          : (this.activeDemo = this.demoList[index]);
        this.handleCurrent(this.demoList[index == "-1" ? 0 : index]);
      }
    },
    /**
     * @description:  编辑提纲
     * @param {Object} value 接收的编辑类型及节点信息
     * @param {Object} node 接收的节点详情
     * @return {void}
     */
    editornode(value, node) {
      if (value.evt === "select_node") {
        this.clickoutlineid = value.node;
        this.activeNode = node;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
@import "~@/scss/demo.scss";
@import "~@/scss/common.scss";
.app-scheme-wrapper {
  background-color: #fff;
}
.demo-main {
  position: relative;
  overflow-x: hidden;
}
.retract-icon {
  position: absolute;
  left: -11px;
  top: 50%;
  width: 22px;
  height: 22px;
  line-height: 22px;
  border-radius: 50%;
  color: var(--color-text-primary);
  background-color: #fff;
  text-align: right;
  z-index: 3;
  // background: url('@/assets/images/playeditor/allow-right.png') no-repeat;
}
</style>
