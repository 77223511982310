<template>
  <div class="cell-inside-col" :class="col.id">
    <div
      class="cell-inside-row"
      :class="scope.row.id"
      :style="getCellInsideRowStyle(index, col, scope.row)"
    >
      <slot name="cell-left" v-bind="{ row: scope.row, col }"></slot>

      <!-- 单元格左边的内嵌按钮 -->
      <template v-for="btnCol in buttonColumnsOnLeft">
        <!-- <template v-if="scope.row[btnCol.id]"> -->
        <ColumnButton
          class="col-btn-inside-left"
          :class="{
            'next-to-blank': !isNotBlank(scope.row[scope.column.property]),
          }"
          :key="btnCol.id"
          :icon="btnCol.iconClass"
          :visibleRef="scope.row[btnCol.id]"
          @btn-click="onColumnButtonClick(scope.row, col, btnCol)"
        >
          <template v-slot="{ iconClass }">
            <slot
              name="button-column-icon"
              v-bind="{ iconClass, data: scope.row[btnCol.id] }"
            ></slot>
          </template>
        </ColumnButton>
        <!-- </template> -->
      </template>

      <!-- {{ consoleScope(scope) }} -->
      <!-- 单元格左边的用户头像 -->
      <template v-if="col.iconColumn && scope.row[col.iconColumn.id]">
        <SideIcon :iconUrl="scope.row[col.iconColumn.id]" :size="24" />
      </template>

      <!-- 特殊按钮列 -->
      <ColumnButton
        v-if="isButtonColumn(col)"
        :icon="col.iconClass"
        :visibleRef="scope.row[scope.column.property]"
        @btn-click="onColumnButtonClick(scope.row, col)"
      >
        <template v-slot="{ iconClass }">
          <slot
            name="button-column-icon"
            v-bind="{ iconClass, data: scope.row[scope.column.property] }"
          ></slot>
        </template>
      </ColumnButton>

      <!-- 序号列 -->
      <ColumnIndex v-else-if="isIndexColumn(col)" :scope="scope" />

      <!-- 头像列 -->
      <ColumnIcon
        v-else-if="isIconColumn(col)"
        :iconUrl="scope.row[scope.column.property]"
      />

      <!-- 百分比列 -->
      <ColumnPercent
        v-else-if="isPercentColumn(col)"
        :color="col.color"
        :percentage="scope.row[scope.column.property]"
      />

      <template v-else-if="isClickableColumn(col)">
        <!-- 可点击按钮列 -->
        <BodyClickableCell
          :scope="scope"
          :column="col"
          :row="scope.row"
          :cellValueBeforeFormat="scope.row[scope.column.property]"
          :cellValueAfterFormat="
            onFormat(col, scope.row[scope.column.property])
          "
          :isRichText="isRichTextColumn(col)"
          @cell-click="onClickableCellClick"
        />
      </template>

      <template v-else-if="isSelectableColumn(col)">
        <!-- 可下拉选择的列 -->
        <BodySelectableCell
          :scope="scope"
          :column="col"
          :row="scope.row"
          :cellValueBeforeFormat="scope.row[scope.column.property]"
          :cellValueAfterFormat="
            onFormat(col, scope.row[scope.column.property])
          "
          :isFreezed="isFreezed"
          :getDropdownData="getCellDropdownData"
          :confirmSelection="confirmCellSelection"
        />
      </template>

      <template v-else-if="isRichTextColumn(col)">
        <!-- 富文本单元格 -->
        <OverflowTooltip
          :popperClass="`rich-text-cell-tooltip tooltip-${col.id} tooltip-${scope.row.id}`"
          :content="scope.row[scope.column.property]"
          :isRichText="true"
        >
          <BodyRichTextCell
            class="rich-text-cell"
            :richtext="scope.row[scope.column.property]"
          />
        </OverflowTooltip>
      </template>

      <template v-else-if="isDateTimeShortColumn(col)">
        <BodyShortDateTimeCell :value="scope.row[scope.column.property]" />
      </template>

      <template v-else>
        <!-- 普通单元格 -->

        <BodyNormalCell
          :value="onFormat(col, scope.row[scope.column.property])"
        />
      </template>

      <!-- 单元格右上角的提示信息 -->
      <template
        v-if="col.innerColumn && isNotEmpty(scope.row[col.innerColumn.id])"
      >
        <CornerTip
          :column="col"
          :row="scope.row"
          :cellValueBeforeFormat="scope.row[col.innerColumn.id]"
          :cellValueAfterFormat="
            onFormat(col.innerColumn, scope.row[col.innerColumn.id])
          "
          @click="onCornerTipClick"
        />
      </template>

      <!-- {{ consoleScope(scope) }} -->
      <!-- 单元格右边的内嵌按钮 -->
      <template v-for="btnCol in buttonColumnsOnRight">
        <!-- <template v-if="scope.row[btnCol.id]"> -->
        <ColumnButton
          class="col-btn-inside-right"
          :class="{
            'next-to-blank': !isNotBlank(scope.row[scope.column.property]),
          }"
          :key="btnCol.id"
          :icon="btnCol.iconClass"
          :visibleRef="scope.row[btnCol.id]"
          @btn-click="onColumnButtonClick(scope.row, col, btnCol)"
        >
          <template v-slot="{ iconClass }">
            <slot
              name="button-column-icon"
              v-bind="{ iconClass, data: scope.row[btnCol.id] }"
            ></slot>
          </template>
        </ColumnButton>
        <!-- </template> -->
      </template>
    </div>
    <!-- 操作按钮特殊列(内嵌在某一列的单元格内) -->
    <template
      v-if="
        hasOperation &&
        table &&
        table.optBtnsPosition === 'within-col' &&
        table.optBtnsTarget &&
        table.optBtnsTarget.id === col.id
      "
    >
      <ContainerForWithinCol :row="scope.row">
        <template v-slot:operation>
          <slot name="operation"></slot>
        </template>
      </ContainerForWithinCol>
    </template>
    <slot name="cell-bottom" v-bind="{ row: scope.row, col }"></slot>
  </div>
</template>
<script lang="ts">
import Vue, { PropType } from "vue";

import SideIcon from "@/flexible-table-module/components/FlexibleTable/CellInsideContent/SideIcon.vue";
import CornerTip from "@/flexible-table-module/components/FlexibleTable/CellInsideContent/CornerTip.vue";
import ColumnButton from "@/flexible-table-module/components/FlexibleTable/CellInsideContent/ColumnButton.vue";
import ColumnIndex from "@/flexible-table-module/components/FlexibleTable/CellInsideContent/ColumnIndex.vue";
import ColumnIcon from "@/flexible-table-module/components/FlexibleTable/CellInsideContent/ColumnIcon.vue";
import ColumnPercent from "@/flexible-table-module/components/FlexibleTable/CellInsideContent/ColumnPercent.vue";
import BodyNormalCell from "@/flexible-table-module/components/FlexibleTable/CellInsideContent/BodyNormalCell.vue";
import BodyClickableCell from "@/flexible-table-module/components/FlexibleTable/CellInsideContent/BodyClickableCell.vue";
import BodySelectableCell from "@/flexible-table-module/components/FlexibleTable/CellInsideContent/BodySelectableCell.vue";
import BodyRichTextCell from "@/flexible-table-module/components/FlexibleTable/CellInsideContent/BodyRichTextCell.vue";
import BodyShortDateTimeCell from "@/flexible-table-module/components/FlexibleTable/CellInsideContent/BodyShortDateTimeCell.vue";
import ContainerForWithinCol from "@/flexible-table-module/components/FlexibleTable/OperationBtnsContainer/ContainerForWithinCol.vue";
import OverflowTooltip from "@/flexible-table-module/components/Common/OverflowTooltip.vue";

import { cellValueFormatter } from "@/flexible-table-module/util/CellHelper";

import { ColumnType, Table } from "@/flexible-table-module/entity/Table";
import { BaseColumn } from "@/flexible-table-module/entity/Table/BaseColumn";
import { ButtonColumn } from "@/flexible-table-module/entity/Table/ButtonColumn";
import { Column } from "@/flexible-table-module/entity/Table/Column";
import { IBaseTableData } from "@/flexible-table-module/entity/Table/IBaseTableData";
import { IconColumn } from "@/flexible-table-module/entity/Table/IconColumn";
import { IndexColumn } from "@/flexible-table-module/entity/Table/IndexColumn";
import { PercentColumn } from "@/flexible-table-module/entity/Table/PercentColumn";
import { KeyValuePair } from "@/flexible-table-module/entity/KeyValuePair";

import {
  DATE_FORMAT,
  TIME_FORMAT,
  DATETIME_FORMAT,
  // DEFAULT_CELL_ALIGN,
  getDefaultCellAlign,
} from "@/flexible-table-module/constants";
import { TColumnaAlign } from "@/flexible-table-module/entity/Table/ColumnTypes";

export default Vue.extend({
  inheritAttrs: false,
  components: {
    SideIcon,
    CornerTip,
    ColumnButton,
    ColumnIndex,
    ColumnIcon,
    ColumnPercent,
    BodyNormalCell,
    BodyClickableCell,
    BodySelectableCell,
    BodyRichTextCell,
    BodyShortDateTimeCell,
    ContainerForWithinCol,
    OverflowTooltip,
  },
  props: {
    table: { type: Object as PropType<Table<IBaseTableData>> }, // 表格定义
    col: {
      type: Object as PropType<ColumnType<IBaseTableData>>,
      required: true,
    },
    index: { type: Number, required: true },
    scope: { type: Object, required: true },
    isTree: { type: Boolean, default: false }, // 是否树状数据表格
    isTreeArrowCol: { type: Boolean, default: false },
    isFreezed: { type: Boolean, default: false }, // 是否被冻结
    hasOperation: { type: Boolean, default: false }, // 是否有操作按钮
    getCellDropdownData: {
      type: Function as PropType<
        (
          row: IBaseTableData,
          column: Column,
          resolve: (data: KeyValuePair<string>[]) => void
        ) => void
      >,
    },
    confirmCellSelection: {
      type: Function as PropType<
        (
          row: IBaseTableData,
          column: Column,
          item: KeyValuePair<string>,
          resolve: (isConfirm: boolean) => void
        ) => void
      >,
    },
  },
  computed: {
    buttonColumnsOnLeft(): ButtonColumn[] {
      // 左侧按钮列
      return this.col instanceof Column && this.col.buttonColumns
        ? this.col.buttonColumns.filter((btnCol) => btnCol.position === "left")
        : [];
    },
    buttonColumnsOnRight(): ButtonColumn[] {
      // 右侧按钮列
      return this.col instanceof Column && this.col.buttonColumns
        ? this.col.buttonColumns.filter((btnCol) => btnCol.position === "right")
        : [];
    },
  },
  data() {
    return {};
  },
  methods: {
    // 如果有内置icon按钮列，col 为普通列并且 insideBtnCol 为该内置按钮列，否则 col 为按钮列
    onColumnButtonClick(
      row: IBaseTableData,
      col: Column | ButtonColumn,
      insideBtnCol?: ButtonColumn
    ) {
      this.$emit("column-button-click", row, col, insideBtnCol);
    },

    onCornerTipClick(row: any, column: Column) {
      this.$emit("cell-corner-tip-click", row, column);
    },

    onClickableCellClick(row: IBaseTableData, column: Column) {
      this.$emit("cell-click", row, column);
    },

    getCellInsideRowStyle(
      colIndex: number,
      col: Column,
      row: IBaseTableData
    ): { justifyContent: string } {
      let styleJustifyContent;

      // if (this.isTreeArrowCol) {
      //   styleJustifyContent = "flex-start"; // 箭头列内容必须靠左
      // } else {
      styleJustifyContent = this.getCellInsideAlign(col);
      // }

      const style = { justifyContent: styleJustifyContent };

      return style;
    },

    // 决定单元格内部的内容的对齐方式(居左、居中、居右)
    getCellInsideAlign(col: Column): "center" | "flex-start" | "flex-end" {
      const colCellAlign: TColumnaAlign =
        col.cellAlign || getDefaultCellAlign(col.type);

      // 内容大于12个字(中文)/24个字母(英文)居左，小于等于就居中(表头跟内容都一样)

      switch (colCellAlign) {
        case "center":
          return "center";
        case "left":
          return "flex-start";
        case "right":
          return "flex-end";
      }
    },

    isIndexColumn(col: BaseColumn): boolean {
      return col instanceof IndexColumn;
    },

    isButtonColumn(col: BaseColumn): boolean {
      return col instanceof ButtonColumn;
    },

    isIconColumn(col: BaseColumn) {
      return col instanceof IconColumn;
    },

    isPercentColumn(col: BaseColumn) {
      return col instanceof PercentColumn;
    },

    isClickableColumn(col: Column<IBaseTableData>) {
      return col instanceof Column && col.clickable;
    },

    isSelectableColumn(col: Column<IBaseTableData>) {
      return col instanceof Column && col.selectable;
    },

    isRichTextColumn(col: Column<IBaseTableData>) {
      return col instanceof Column && col.type === "rich-text";
    },

    isDateTimeShortColumn(col: Column<IBaseTableData>) {
      return col instanceof Column && col.type === "short-datetime";
    },

    isNotEmpty(val: any) {
      return val !== undefined && val !== null;
    },

    isNotBlank(val: any) {
      return val !== undefined && val !== null && val !== "";
    },

    onFormat(col: Column<IBaseTableData>, cellValue: any): string | undefined {
      return cellValueFormatter(col, cellValue);
    },
  },
});
</script>
<style lang="scss" scoped>
.cell-inside-col {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;

  .cell-inside-row {
    width: 100%;
    display: flex;
    align-items: center;

    .col-btn-inside-left {
      margin-right: 8px;

      &.next-to-blank {
        margin-right: 0;
      }
    }

    .col-btn-inside-right {
      margin-left: 8px;

      &.next-to-blank {
        margin-left: 0;
      }
    }
  }
}
</style>