import { ApiFilter } from "../api/Filter";
import { IFilterListItem } from "../entity/BaseFilter";
import { Filter, MultiFilter, ViewFilter } from "../entity/Filter";

export class BizFilter {

    private apiFilter: ApiFilter;

    constructor(baseURL?: string) {
        this.apiFilter = new ApiFilter(baseURL);
    }

    public async getFilters(
        moduleName: string,
        tabName: string,
        includeList: boolean,
    ): Promise<Filter[]> {
        const result = await this.apiFilter.getFilters(moduleName, tabName, includeList) as any;
        return result.map((f: any) => new Filter(f));
    }

    // public async getFilterList(
    //     moduleName: string,
    //     tabName: string,
    //     filterId: string
    // ): Promise<IFilterListItem[]> {
    //     const result = await this.apiFilter.getFilterList(moduleName, tabName, filterId) as any;
    //     return result;
    // }

    public async getMultiFilters(
        moduleName: string,
        tabName: string,
    ): Promise<MultiFilter[]> {
        const result = await this.apiFilter.getMultiFilters(moduleName, tabName) as any;
        return result.map((mf: any) => new MultiFilter(mf));
    }

    public async getViewFilters(
        moduleName: string,
        tabName: string,
    ): Promise<ViewFilter[]> {
        const result = await this.apiFilter.getViewFilters(moduleName, tabName) as any;
        return result.map((vf: any) => {
            const filters: Filter[] = [];
            if (vf.filters && vf.filters.length > 0) {
                for (const _filter of vf.filters) {
                    let list: IFilterListItem[] = [];
                    if (_filter.list && _filter.list.length > 0) list = _filter.list;

                    const filter = new Filter({ ..._filter, list })

                    if (!filter.archived)
                        filters.push(filter);
                }
            }

            return new ViewFilter({ ...vf, filters })
        });
    }

    public async getFilterListByFilter(
        moduleName: string,
        tabName: string,
        filter: Filter
    ): Promise<IFilterListItem[]> {
        const result = await this.apiFilter.getFilterListByFilter(moduleName, tabName, filter) as any;
        return result;
    }

    public async updateFilterActive(
        moduleName: string,
        tabName: string,
        filter: Filter,
    ): Promise<Filter> {
        const result = await this.apiFilter.updateFilterActive(moduleName, tabName, filter) as any;
        return new Filter(result);
    }

    public async updateFilterArchive(
        moduleName: string,
        tabName: string,
        filters: Filter[],
    ): Promise<Filter> {
        const result = await this.apiFilter.updateFilterArchive(moduleName, tabName, filters) as any;
        return new Filter(result);
    }

    public async createOrUpdateMultiFilter(
        moduleName: string,
        tabName: string,
        multiFilter: MultiFilter,
    ): Promise<MultiFilter> {
        const result = await this.apiFilter.createOrUpdateMultiFilter(moduleName, tabName, multiFilter) as any;
        return new MultiFilter(result);
    }

    public async createOrUpdateViewFilter(
        moduleName: string,
        tabName: string,
        viewFilter: ViewFilter,
    ): Promise<ViewFilter> {
        const result = await this.apiFilter.createOrUpdateViewFilter(moduleName, tabName, viewFilter) as any;
        return new ViewFilter(result);
    }

    public async deleteMultiFilter(
        moduleName: string,
        tabName: string,
        multiFilterId: string,
    ): Promise<boolean> {
        const result = await this.apiFilter.deleteMultiFilter(moduleName, tabName, multiFilterId) as any;
        return result;
    }

    public async deleteViewFilter(
        moduleName: string,
        tabName: string,
        viewFilterId: string,
    ): Promise<boolean> {
        const result = await this.apiFilter.deleteViewFilter(moduleName, tabName, viewFilterId) as any;
        return result;
    }
}