/*
 * @Author: Billy
 * @Date: 2021-07-10 20:49:58
 * @LastEditors: HuiMinQi
 * @LastEditTime: 2024-03-21 17:26:14
 * @Description: 针对服务器返回值的一些处理方法
 */

// import SysCode from "../sys/SysCode.js";
import { SysError } from "../sys/SysError.js";
// import Pubsub from "@/util/Pubsub";
// 一般处理方法
function handler(res) {
  if (
    res?.headers["content-type"].includes("download") ||
    res?.headers["content-type"].includes("text/html")
  ) {
    return res.data;
  } else if (res?.data?.code === 500 || res?.data?.code === 1002) {
    // Pubsub.publish("stop-loading");
    throw new SysError(
      res.data.code,
      res.data.message,
      res.data.result,
      res.data.success,
      res.data.timestamp
    );
  } else {
    return res.data.result;
  }
}

export default {
  handler,
};
