<template>
  <el-dialog
    append-to-body
    class="ss-dialog no-header"
    top="188px"
    width="540px"
    :visible.sync="dialogVisible"
  >
    <div class="content">连接插件异常，请确认是否已经安装插件……</div>
    <span slot="footer">
      <div class="custom-footer">
        <el-link style="margin-right: 20px" @click="handleDownload"
          >点击此处下载插件(版本:2.5.8966.17317)<i class="el-icon-download"></i>
        </el-link>
        <el-button class="custom-footer-btn" type="primary" @click="handleConfirm"
          >关&nbsp;闭</el-button
        >
      </div>
    </span>
  </el-dialog>
</template>

<script>
import { mapGetters } from "vuex";
import { handleDownloadI3VMews } from "@/utils/file";

export default {
  name: "share",
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters(["isconnected"]),
    dialogVisible: {
      get() {
        return this.visible;
      },
      set(val) {
        this.$emit("update:visible", val);
      },
    },
  },
  watch: {
    isconnected(val) {
      if (val) {
        this.dialogVisible = false;
      }
    },
  },
  mounted() {},
  methods: {
    /**
     * @description: 确定
     * @return {void}
     */
    handleConfirm() {
      this.dialogVisible = false;
    },
    /**
     * @description: 下载插件
     * @return {void}
     */
    handleDownload() {
      handleDownloadI3VMews();
    },
  },
};
</script>

<style lang="scss" scoped>
.content {
  // height: 80px;
  padding: 40px 20px;
  font-size: 16px;
}
</style>
