<template>
  <div class="w-h-100">
    <div class="file-breadcrumb">
      <span v-if="breadcrumbList.length" class="pointer" @click="getLocalfloder"
        >全部</span
      >
      <span v-for="(item, index) in breadcrumbList" :key="item.id">
        <span>&nbsp;/&nbsp;</span>
        <span class="pointer" @click="jumpTo(item, index)">{{ item.name }}</span>
      </span>
    </div>
    <el-table
      v-if="isconnected"
      :data="tableData"
      style="width: 100%"
      height="calc(100% - 80px)"
      row-class-name="draggable-row"
      header-cell-class-name="app-header-cell"
    >
      <el-table-column prop="name" label="名称" min-width="180">
        <template slot-scope="{ row }">
          <div
            class="filename pointer"
            :draggable="row.isfolder ? false : true"
            @dragstart="(e) => dragstart(e, row)"
            @click.prevent="getFileList(row)"
          >
            <svg-icon :icon="row.icon" :color="row.color" class="pointer"></svg-icon>
            <div class="one-line" :title="row.name">{{ row.name }}</div>
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="size" label="大小" width="100">
        <template slot-scope="{ row }">
          <div class="flex">
            <div>{{ row.size ? common.formatFileSize(row.size) : "--" }}</div>
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="createtime" label="创建时间" width="180"> </el-table-column>
    </el-table>
    <div v-if="!isconnected">
      连接插件异常，请确认是否已经安装插件，
      <el-link style="margin-right: 20px" @click="handleDownload">
        <i class="el-icon-download"></i>下载插件
      </el-link>
    </div>
    <FloatPanel
      :canResize="false"
      :canMove="true"
      :hasModal="false"
      :top="top + 'px'"
      :left="left + 'px'"
      v-if="showlist"
    >
      <UploadList :visible.sync="showlist" />
    </FloatPanel>
    <svg-icon
      v-if="!showlist"
      @click.native="showlist = true"
      class="fileicon pointer"
      icon="file-open"
      :width="40"
      :height="40"
    ></svg-icon>
  </div>
</template>

<script>
import store from "@/store";
import { mapGetters } from "vuex";
import { handleDownloadI3VMews, checkFileIcon } from "@/utils/file";
import UploadList from "./UploadList.vue";
import FloatPanel from "../FloatPanel.vue";
export default {
  components: { UploadList, FloatPanel },
  data() {
    return {
      tableData: [],
      queryFiledata: {
        msgId: store.state.local.userInfo.id,
        funcname: "getLocalFileList",
        systemType: "1", //系统类型，1：销售展示，2：Teamco
        path: null,
      },
      synchronization: {
        msgId: store.state.local.userInfo.id,
        funcname: "syncfolders",
        systemType: "1",
        token: store.state.local.token ? store.state.local.token : "",
        tenantId: store.state.local.userInfo
          ? store.state.local.userInfo.tenantIdNow
          : "",
        baseurl: process.env.VUE_APP_URL_NETDISK,
        fileSysBaseUrl: process.env.VUE_APP_URL_NETDISK,
      },
      breadcrumbList: [],
      showlist: false,
      left: 0,
      top: 0,
    };
  },
  computed: {
    ...mapGetters(["isconnected"]),
  },
  watch: {
    isconnected(val) {
      if (val) {
        this.init();
      }
    },
    showlist(val) {
      if (val) {
        const viewportWidth = window.innerWidth;
        const viewportHeight = window.innerHeight;
        this.left = viewportWidth - 460;
        this.top = viewportHeight - 320;
      }
    },
  },
  created() {
    this.init();
    this.getLocalfloder();
  },
  beforeDestroy() {
    this.$messageService.$off("websocketMessage");
  },
  methods: {
    /**
     * @description: 检测是否为json数据
     * @return {void}
     */
    isJSON(str) {
      try {
        JSON.parse(str);
        return true;
      } catch (error) {
        return false;
      }
    },
    /**
     * @description: 处理接收到的消息
     * @return {void}
     */
    init() {
      this.$messageService.$on("websocketMessage", (data) => {
        const parsedMessage = JSON.parse(data);
        let parsedMessagedata = null;
        if (this.isJSON(parsedMessage.data)) {
          parsedMessagedata = JSON.parse(parsedMessage.data);
        }
        if (parsedMessage.funcname == "receivedMsg" && parsedMessagedata) {
          // 如果接收到上传文件的消息就显示上传列表
          if (parsedMessagedata.funcname == "uploadfile") {
            this.showlist = true;
          }
        }
        if (parsedMessage.funcname == "getLocalDriveList") {
          this.tableData = parsedMessage.data.map((item) => {
            return {
              name: item.drivenumber.replace(":/", ""),
              path: item.drivenumber,
              isfolder: true,
              children: [],
              aliasType: "folder",
              icon: "filetype-floder",
              color: this.common.getFileColor("folder"),
            };
          });
        } else if (parsedMessage.funcname == "getLocalFileList") {
          parsedMessage.data.forEach((element) => {
            element.children = [];
            if (!element.isfolder) {
              element.aliasType = this.common.getFileType(null, element.path);
              element.icon = checkFileIcon(element.path);
            } else {
              element.aliasType = "folder";
              element.icon = "filetype-floder";
            }
            element.color = this.common.getFileColor(element.aliasType);
          });
          this.tableData = parsedMessage.data;
        }
      });
    },
    /**
     * @description: 获取本地盘符列表
     * @return {void}
     */
    getLocalfloder() {
      // 检查WebSocket连接状态
      if (this.$websocket.readyState === WebSocket.OPEN) {
        // 发送消息
        const message = {
          msgId: store.state.local.userInfo.id,
          funcname: "getLocalDriveList",
          systemType: "1",
        };
        this.$websocket.send(JSON.stringify(message));
        this.breadcrumbList = [];
      } else {
        this.$websocket = new WebSocket("ws://127.0.0.1:21662");
      }
    },
    /**
     * @description: 获取文件列表
     * @param {Object} value 点击项
     * @return {void}
     */
    getFileList(data) {
      if (!data.isfolder) {
        return;
      }
      // 检查WebSocket连接状态
      if (this.$websocket.readyState === WebSocket.OPEN) {
        this.queryFiledata.path = data.path;
        this.$websocket.send(JSON.stringify(this.queryFiledata));
        this.breadcrumbList.push(data);
      }
    },
    /**
     * @description: 点击面包屑获取文件列表
     * @param {Object} value 点击项
     * @param {Number} index 索引
     * @return {void}
     */
    jumpTo(value, index) {
      if (index === this.breadcrumbList.length - 1) {
        return;
      }
      if (value) {
        this.breadcrumbList.splice(index);
      } else {
        this.breadcrumbList = [];
      }
      this.getFileList(value);
    },
    /**
     * @description: 拖拽赋值
     * @param {Object} row 点击项
     * @return {void}
     */
    dragstart(e, row) {
      e.dataTransfer.setData("localpath", row.path);
      e.dataTransfer.setData("localname", row.name);
    },
    /**
     * @description: 下载插件
     * @return {void}
     */
    handleDownload() {
      handleDownloadI3VMews();
    },
  },
};
</script>
<style lang="scss" scoped>
.file-breadcrumb {
  line-height: 18px;
  color: #5e6c84;
  font-size: 12px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;

  .fw-400 {
    font-weight: 400;
  }

  .fw-500 {
    font-weight: 500;
  }

  .fw-700 {
    font-weight: 700;
  }
}
.filename {
  display: grid;
  grid-template-columns: 20px auto;
  gap: 7px;
}
.fileicon {
  position: absolute;
  right: 0;
  top: 80%;
}
</style>
