/*
 * @Author: Billy
 * @Date: 2020-06-23 08:44:33
 * @LastEditors: DiaoZhengJie
 * @LastEditTime: 2022-04-24 11:39:51
 * @Description: 基础api的Axios的基类
 */

import /* axios, */ { type AxiosInstance, type AxiosRequestConfig } from "axios";
// // @ts-ignore
// import AxiosInterceptors from '@/api/_AxiosInterceptors.js';
// // @ts-ignore
// import { BASE_URL, TIMEOUT } from '@/const.js';

// const requestConfig = {
//     baseURL: `${BASE_URL}`, // `baseURL` will be prepended to `url` unless `url` is absolute. 
//     timeout: TIMEOUT
// }

// const baseAxios = axios.create(requestConfig);
// AxiosInterceptors.setInterceptors(baseAxios);

let _baseAxios: AxiosInstance | undefined = undefined;

function setBaseAxios(axiosInstance: AxiosInstance) {
    _baseAxios = axiosInstance;
}

function baseAxiosPlus(config: AxiosRequestConfig) {
    if (_baseAxios) return _baseAxios(config);
    else throw new Error('请通过 setBaseAxios 设置通用表格的 baseAxios');
}

export default baseAxiosPlus;
export { setBaseAxios };