import request from "@/utils/appServer";
import common from "../utils/common"
class Homecontents {
  // 获取首页用户栏目列表
  static async getHomeContentsByUserList(data) {
    try {
      const res = await request.get('/sales-show-4.0/homeContents/getHomeContentsByUserList', { params: data });
      return res.data.code === 200 && res.data.result ? res.data.result : [];
    } catch (error) {
      return [];
    }
  }
  // 获取首页表头菜单列表
  static async getHomeContentsOneList() {
    try {
      const res = await request.get('/sales-show-4.0/homeContents/getHomeContentsOneList');
      return res.data.code === 200 && res.data.result ? res.data.result : [];
    } catch (error) {
      return [];
    }
  }
  // 获取首页表头栏目列表
  static async getHomeContentsTwoList() {
    try {
      const res = await request.get('/sales-show-4.0/homeContents/getHomeContentsTwoList');
      return res.data.code === 200 && res.data.result ? res.data.result : [];
    } catch (error) {
      return [];
    }
  }
  // 新增首页
  static async saveHomeContents(data) {
    try {
      const res = await request.post('/sales-show-4.0/homeContents/saveHomeContents', data);
      return res.data.code === 200 && res.data.result ? res.data.result : false;
    } catch (error) {
      return false;
    }
  }
  // 修改首页用户栏目列表勾选
  static async updateHomeContentsByUser(data) {
    try {
      const res = await request.post('/sales-show-4.0/homeContents/updateHomeContentsByUser', data);
      return res.data.code === 200 && res.data.success;
    } catch (error) {
      return false;
    }
  }
  // 删除首页
  static async deleteHomeContents(id) {
    try {
      const res = await request.get('/sales-show-4.0/homeContents/deleteHomeContents', { params: { id } });
      return res.data.code === 200 && res.data.success;
    } catch (error) {
      return false;
    }
  }
  // 编辑首页
  static async updateHomeContents(data) {
    try {
      const res = await request.post('/sales-show-4.0/homeContents/updateHomeContents', data);
      return res.data.code === 200 && res.data.success;
    } catch (error) {
      return false;
    }
  }
  // 新增首页与数据关联
  static async saveHomeContentsToData(data) {
    try {
      const res = await request.post('/sales-show-4.0/homeContents/saveHomeContentsToData', data);
      return res.data.code === 200 && res.data.success;
    } catch (error) {
      return false;
    }
  }
  // 获取已经关联的数据id
  static async getHomeContentsIdsByModuleId(data) {
    try {
      const res = await request.get('/sales-show-4.0/homeContents/getHomeContentsIdsByModuleId', { params: data });
      return res.data.code === 200 && res.data.result ? res.data.result : [];
    } catch (error) {
      return [];
    }
  }
  // 首页查询通用列表(排除文章的)
  static async getHomeContentsList(data) {
    try {
      const res = await request.post('/sales-show-4.0/homeContents/getHomeContentsList', data);
      return res.data.code === 200 && res.data.result ? res.data.result : new common.Pagination();
    } catch (error) {
      return new common.Pagination();
    }
  }
  // 首页查询通用文章列表
  static async getArticlHomeContentsList(data) {
    try {
      const res = await request.post('/sales-show-4.0/homeContents/getArticlHomeContentsList', data);
      return res.data.code === 200 && res.data.result ? res.data.result : new common.Pagination();
    } catch (error) {
      return new common.Pagination();
    }
  }
  //推送关联的数据id
  static async addContentsToData(data) {
    try {
      const res = await request.get('/sales-show-4.0/homeContents/addContentsToData', { params: data }, true);
      return res.data.code === 200 && res.data.success;
    } catch (error) {
      return false;
    }
  }

  // -----自定义类
  //布局添加内容
  static async addDisplayLayoutContent(data) {
    try {
      const res = await request.post("/sales-show-4.0/homeContents/addDisplayLayoutContent", data);
      return res.data.code === 200 && res.data.success ? res.data.success : false;
    } catch (error) {
      return false;
    }
  }
  //根据模块类型添加布局
  static async batchAddDisplayLayout(data) {
    try {
      const res = await request.post("/sales-show-4.0/homeContents/batchAddDisplayLayout", data);
      return res.data.code === 200 && res.data.success ? res.data.success : false;
    } catch (error) {
      return false;
    }
  }
  //批量修改布局
  static async batchUpdateDisplayLayout(data) {
    try {
      const res = await request.post("/sales-show-4.0/homeContents/batchUpdateDisplayLayout", data);
      return res.data.code === 200 && res.data.success ? res.data.success : false;
    } catch (error) {
      return false;
    }
  }
  //获取展示详情
  static async queryDisplayDetail(data) {
    try {
      const res = await request.get("/sales-show-4.0/homeContents/queryDisplayDetail", { params: data });
      return res.data.code === 200 && res.data.result ? res.data.result : false;
    } catch (error) {
      return false;
    }
  }
  //布局移除关联内容
  static async removeDisplayLayoutContent(data) {
    try {
      const res = await request.delete("/sales-show-4.0/homeContents/removeDisplayLayoutContent", { params: data }, true);
      return res.data.code === 200 && res.data.success ? res.data.success : false;
    } catch (error) {
      return false;
    }
  }
  //布局移除
  static async removeDisplayLayout(data) {
    try {
      const res = await request.delete("/sales-show-4.0/homeContents/removeDisplayLayout", { params: data }, true);
      return res.data.code === 200 && res.data.success ? res.data.success : false;
    } catch (error) {
      return false;
    }
  }
  //根据模块id获取模块类型
  static async getTypeByModuleId(moduleId) {
    try {
      const res = await request.get("/sales-show-4.0/homeContents/getTypeByModuleId", { params: { moduleId } });
      return res.data.code === 200 && res.data.result ? res.data.result : false;
    } catch (error) {
      return false;
    }
  }
}

export { Homecontents }