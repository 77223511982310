interface ITreeFlatData {
    [key: string | number]: any;
    children?: any[];
}

/** 特定树形数据格式 */
class TreeNode {
    public $data: ITreeFlatData;
    public $children: TreeNode[];
    public $expanded: boolean; // 是否已展开(默认不展开)

    constructor(data: any, children: TreeNode[], expanded = false) {
        this.$data = data;
        this.$children = children;
        this.$expanded = expanded;
    }
}

export class TreeRecord<T extends { children?: T[] }> {
    public tree: TreeNode[];

    constructor(datas: ITreeFlatData[]) {
        this.tree = convertTree(datas);
    }

    // 把懒加载所得的子节点数据加入到本模块特定树形数据
    public insertChildren(
        parent: ITreeFlatData,
        children: ITreeFlatData[],
        expanded: boolean = true, // 默认展开
    ) {
        traverseTreeNodeArray(this.tree, (treeNode: TreeNode) => {
            if (treeNode.$data === parent) {
                treeNode.$expanded = expanded;
                treeNode.$children = convertTree(children);
                return false;
            }
        });
    }

    // 返回普通扁平数据的扁平数组
    public getAllExpandedFlatData(): ITreeFlatData[] {
        const flatData: ITreeFlatData[] = [];

        traverseTreeNodeArray(this.tree, (treeNode: TreeNode) => {
            flatData.push(treeNode.$data);
        }, true);

        return flatData;
    }

    public setTreeNodeExpanded(flatData: ITreeFlatData, expanded: boolean) {
        traverseTreeNodeArray(this.tree, (treeNode: TreeNode) => {
            if (treeNode.$data === flatData) {
                treeNode.$expanded = expanded;
                return false;
            }
        });
    }
}

// 普通树形数据转换为本模块特定树形数据
function convertTree(datas?: ITreeFlatData[]): TreeNode[] {
    const convertedNodes: TreeNode[] = [];

    if (datas && datas.length > 0) {
        for (const data of datas) {
            const childNodes = convertTree(data.children);
            const newNode: TreeNode = new TreeNode(data, childNodes);
            convertedNodes.push(newNode);
        }
    }

    return convertedNodes;
}

/**
 * 递归遍历树形数据
 * @param treeNodes TreeNode 格式树形数据
 * @param callback 针对遍历到的每个节点的回调(当返回 false 时，将中断接下来的所有遍历)
 * @param onlyExpanded 是否只遍历展开的节点的子节点
 * @param parent 内部使用，父节点
 */
function traverseTreeNodeArray(
    treeNodes: TreeNode[],
    callback: (treeNode: TreeNode, parent?: TreeNode) => void | boolean,
    onlyExpanded: boolean = false,
    parent?: TreeNode | null
) {
    for (const treeNode of treeNodes) {
        const cbResult = callback(treeNode, parent ?? undefined);

        if (cbResult === false) return; // 中断循环，并不再往下递归

        if (onlyExpanded && !treeNode.$expanded) continue; // 如果要求只遍历展开的节点的子节点，并且当前节点是收缩状态，跳过本次循环

        if (treeNode.$children) {
            traverseTreeNodeArray(treeNode.$children, callback, onlyExpanded, treeNode);
        }
    }
}

// export const complexTree: ITreeFlatData[] = [
//     {
//         value: 'A',
//         children: [
//             {
//                 value: 'B',
//                 children: [
//                     { value: 'D' },
//                     { value: 'E' }
//                 ]
//             },
//             {
//                 value: 'C',
//                 children: [
//                     {
//                         value: 'F',
//                         children: [
//                             { value: 'G' },
//                             { value: 'H' }
//                         ]
//                     },
//                     { value: 'I' }
//                 ]
//             }
//         ]
//     },
//     {
//         value: 'J',
//         children: [
//             {
//                 value: 'K',
//                 children: [
//                     { value: 'L' }
//                 ]
//             }
//         ]
//     }
// ];

