<template>
  <ElDialog
    class="view-editor"
    title="视图编辑器"
    :visible.sync="cVisible"
    width="60%"
    :before-close="onDialClose"
  >
    <div class="body">
      <div class="side">
        <div class="part single">
          <div class="title" :class="{ active: currMode === 'single' }">
            <div class="text" @click="onTitleTextClick('single')">
              单列条件选择
            </div>
            <div class="btn" @click="onBtnEditSingleConditionsClick">
              <!-- <IconAdd :size="BTN_ICON_SIZE" /> -->
              <svg-icon
                sys="common-table"
                name="ColumnButton/add"
                :size="BTN_ICON_SIZE"
              />
            </div>
          </div>
          <!-- 如果为空数组，则不显示"content" -->
          <template v-if="!(filters && filters.length === 0)">
            <!-- 如果为 undefined，则显示加载动画 -->
            <div class="content" v-loading="filters === undefined">
              <template v-if="filters && filters.length > 0">
                <template v-for="filter in filters">
                  <SideSingleItem
                    :filter="filter"
                    :key="filter.name"
                    @switch-change="onSingleItemSwitchChange"
                    @delete="onSingleItemDelete"
                  />
                </template>
              </template>
            </div>
          </template>
        </div>
        <div class="part multiple">
          <div class="title" :class="{ active: currMode === 'multiple' }">
            <div class="text" @click="onTitleTextClick('multiple')">
              多列条件选择
            </div>
            <div class="btn" @click="onBtnAddMultiConditionsClick">
              <!-- <IconAdd :size="BTN_ICON_SIZE" /> -->
              <svg-icon
                sys="common-table"
                name="ColumnButton/add"
                :size="BTN_ICON_SIZE"
              />
            </div>
          </div>
          <!-- 如果为空数组，则不显示"content" -->
          <template v-if="!(multiFilters && multiFilters.length === 0)">
            <!-- 如果为 undefined，则显示加载动画 -->
            <div class="content" v-loading="multiFilters === undefined">
              <template v-if="multiFilters && multiFilters.length > 0">
                <SideItem
                  v-for="(mf, index) in multiFilters"
                  :key="index"
                  :title="String(mf.title)"
                  :isActive="currMultiFilter && currMultiFilter.id === mf.id"
                  @title-click="onMultipleItemClick(mf)"
                  @btn-del-click="onMultipleItemBtnDelClick(mf)"
                />
              </template>
            </div>
          </template>
        </div>
        <div class="part view">
          <div class="title" :class="{ active: currMode === 'view' }">
            <div class="text" @click="onTitleTextClick('view')">视图列表</div>
            <div class="btn" @click="onBtnAddViewClick">
              <!-- <IconAdd :size="BTN_ICON_SIZE" /> -->
              <svg-icon
                sys="common-table"
                name="ColumnButton/add"
                :size="BTN_ICON_SIZE"
              />
            </div>
          </div>
          <!-- 如果为空数组，则不显示"content" -->
          <template v-if="!(viewFilters && viewFilters.length === 0)">
            <!-- 如果为 undefined，则显示加载动画 -->
            <div class="content" v-loading="viewFilters === undefined">
              <template v-if="viewFilters && viewFilters.length > 0">
                <SideItem
                  v-for="(vf, index) in viewFilters"
                  :key="index"
                  :title="String(vf.title)"
                  :isActive="currViewFilter && currViewFilter.id === vf.id"
                  @title-click="onViewItemClick(vf)"
                  @btn-del-click="onViewItemBtnDelClick(vf)"
                />
              </template>
            </div>
          </template>
        </div>
      </div>
      <div class="main" v-loading="currMode === 'loading'">
        <template v-if="currMode === 'init'">
          <template v-if="initViewFilter">
            <div class="init-view">
              <MainViewTrees
                :filters="initViewFilter.filters"
                @tree-checkbox-change="onInitViewTreeCheckboxChange"
              />
            </div>
          </template>
        </template>
        <template v-else-if="currMode === 'single'">
          <template v-if="activeFilters">
            <div class="main-single">
              <div
                class="main-col"
                v-for="filter in activeFilters"
                :key="filter.name"
              >
                <div class="col-title">{{ filter.title }}</div>
                <div class="col-tree">
                  <SimpleTree
                    :nodes="filter.list"
                    :props="TREE_PROPS"
                    :hasCheckbox="false"
                    :isNodeClickSingleCheckable="true"
                  />
                </div>
              </div>
            </div>
          </template>
        </template>
        <template v-else-if="currMode === 'multiple'">
          <template v-if="currMultiFilter">
            <MainMultiple
              :multiFilter="currMultiFilter"
              @edit-title-multiple-filter="
                (multiFilter) =>
                  $emit('edit-title-multiple-filter', multiFilter)
              "
              @edit-filter-multiple-filter="
                (multiFilter) =>
                  $emit('edit-filter-multiple-filter', multiFilter)
              "
            />
          </template>
        </template>
        <template v-else-if="currMode === 'view'">
          <template v-if="currViewFilter">
            <MainView
              :viewFilter="currViewFilter"
              @edit-title-view-filter="
                (viewFilter) => $emit('edit-title-view-filter', viewFilter)
              "
              @edit-filter-view-filter="
                (viewFilter, node) =>
                  $emit('edit-filter-view-filter', viewFilter, node)
              "
            />
          </template>
        </template>
      </div>
    </div>

    <ElDialog
      class="single-conditions-editor"
      title="单列条件选择器"
      :visible.sync="singleConditionsDialVisible"
      :append-to-body="true"
      width="40%"
    >
      <SingleConditionsEditor
        ref="singleConditionsEditor"
        :filtersArchived="filtersArchived"
        :filtersAll="filtersAll"
        @submit="onSingleConditionsEditorSubmit"
        @cancel="
          () => {
            singleConditionsDialVisible = false;
          }
        "
      />
    </ElDialog>
  </ElDialog>
</template>
<script lang="ts">
import Vue, { type DirectiveOptions, PropType } from "vue";
import { Dialog as ElDialog, MessageBox as ElMessageBox } from "element-ui";
import SvgIcon from "@/flexible-table-module/svg-icon-importer";

// import IconAdd from "@/flexible-table-module/icons/ViewEditor/IconAdd.vue";

import { Loading } from "@/flexible-table-module/components/Common/Loading";

import SimpleTree from "@/flexible-table-module/components/Common/Tree/SimpleTree.vue";
import MainMultiple from "@/flexible-table-module/components/FlexibleTable/ViewEditor/MainMultiple.vue";
import MainView from "@/flexible-table-module/components/FlexibleTable/ViewEditor/MainView.vue";
import SideSingleItem from "@/flexible-table-module/components/FlexibleTable/ViewEditor/SideSingleItem.vue";
import SideItem from "@/flexible-table-module/components/FlexibleTable/ViewEditor/SideItem.vue";
import MainViewTrees from "./ViewEditor/MainViewTrees.vue";
import SingleConditionsEditor from "./ViewEditor/SingleConditionsEditor.vue";

import {
  Filter,
  MultiFilter,
  ViewFilter,
} from "@/flexible-table-module/entity/Filter";

import { TREE_PROPS } from "@/flexible-table-module/constants";

/**
 * loading 数据加载状态
 * init 打开窗口的初始状态
 * single 单列条件选择状态
 * multiple 多列条件选择状态
 * view 视图列表选择状态
 */
type ModeType = "loading" | "init" | "single" | "multiple" | "view";

const TEMP_ID_PREFIX = "temp_id_";

const BTN_ICON_SIZE = 16;

export default Vue.extend({
  inheritAttrs: false,
  name: "ViewEditor",
  components: {
    ElDialog,
    SvgIcon,
    // IconAdd,
    SimpleTree,
    MainMultiple,
    MainView,
    SideSingleItem,
    SideItem,
    MainViewTrees,
    SingleConditionsEditor,
  },
  directives: {
    loading: Loading.directive,
  },
  props: {
    visible: { type: Boolean, default: false }, // 是否显示本弹窗
    filters: { type: Array as PropType<Filter[]> }, // 未归档的单列条件选择的数据(异步获取数据的话，初始值是 undefined)
    filtersArchived: { type: Array as PropType<Filter[]> }, // 已归档的单列条件选择的数据(异步获取数据的话，初始值是 undefined)
    filtersAll: { type: Array as PropType<Filter[]> }, // 全部的单列条件选择的数据(异步获取数据的话，初始值是 undefined)
    multiFilters: { type: Array as PropType<MultiFilter[]> }, // 多列条件选择的数据(异步获取数据的话，初始值是 undefined)
    viewFilters: { type: Array as PropType<ViewFilter[]> }, // 视图列表的数据(异步获取数据的话，初始值是 undefined)
  },
  data(): {
    TREE_PROPS: typeof TREE_PROPS;
    currMode: ModeType; // 当前的显示内容的模式
    initViewFilter?: ViewFilter; // 初始的视图式筛选器
    currMultiFilter?: MultiFilter; // 当前选中的或新建的"多列条件筛选器"
    currViewFilter?: ViewFilter; // 当前选中的或新建的"视图式筛选器"
    singleConditionsDialVisible: boolean; // 单列条件选择器的弹窗是否显示
    BTN_ICON_SIZE: number;
  } {
    return {
      TREE_PROPS,
      currMode: "loading",
      initViewFilter: undefined,
      currMultiFilter: undefined,
      currViewFilter: undefined,
      singleConditionsDialVisible: false,
      BTN_ICON_SIZE,
    };
  },
  computed: {
    cVisible: {
      get(): boolean {
        return this.visible;
      },
      set(v: boolean) {
        this.$emit("update:visible", v);
      },
    },

    activeFilters(): Filter[] | undefined {
      if (this.filters !== undefined)
        return this.filters.filter((f) => f.active);
      else return undefined;
    },
  },
  watch: {
    visible: {
      immediate: false,
      handler(newVal, oldVal) {
        if (newVal) {
          if (this.currMode !== "loading") this.backToInit();
          this.$emit("show");
        } else {
          this.$emit("close");
        }
      },
    },
    filters: {
      immediate: false,
      handler(newVal, oldVal) {
        if (newVal) {
          this.geneInitViewFilter();
          this.currMode = "init";
          this.currMultiFilter = undefined;
          this.currViewFilter = undefined;
        }
      },
    },
  },
  methods: {
    // 当弹窗关闭时
    onDialClose(done: () => void) {
      done();
    },

    // 当“单列条件选择”的编辑按钮被点击时
    onBtnEditSingleConditionsClick() {
      // 弹窗
      this.singleConditionsDialVisible = true;
    },

    // 当“多列条件选择”的添加按钮被点击时
    onBtnAddMultiConditionsClick() {
      this.currMode = "multiple";

      const title = this.geneMultiFilterTitle();

      const newMultiFilter = new MultiFilter({
        // id: TEMP_ID_PREFIX + Date.now(),
        id: "",
        title,
        filters: [],
      });
      this.currMultiFilter = newMultiFilter;
      this.multiFilters.push(newMultiFilter);
      this.currViewFilter = undefined;

      // 2024-04-11 新需求
      this.$emit("add-new-multiple-filter", newMultiFilter);
    },

    // 当“视图列表”的添加按钮被点击时
    onBtnAddViewClick() {
      this.currMode = "view";

      const title = this.geneViewFilterTitle();
      const filters = this.filters.map((f) => f.clone(true));

      const newViewFilter = new ViewFilter({
        // id: TEMP_ID_PREFIX + Date.now(),
        id: "",
        title,
        filters,
      });
      this.currViewFilter = newViewFilter;
      this.viewFilters.push(newViewFilter);
      this.currMultiFilter = undefined;

      this.$emit("add-new-view-filter", newViewFilter);
    },

    onTitleTextClick(mode: ModeType) {
      // this.currMode = mode;
    },

    // 当 单列条件选择 的单列项的开关被打开或关闭时
    onSingleItemSwitchChange(filter: Filter, val: boolean) {
      this.currMode = "single";

      this.$emit("single-filter-switch", filter, val);
    },

    // 当 单列条件选择 的某一项被关闭(归档)时
    onSingleItemDelete(filter: Filter) {
      filter.archived = true;
      this.$emit("single-filter-archive", filter);

      const index = this.filters.findIndex((f) => f === filter);
      this.filters.splice(index, 1);

      // 如果 singleConditionsEditor 组件存在，则刷新一下，如果还没弹过窗，则不会有这个组件
      const refEditor = this.$refs["singleConditionsEditor"];
      if (refEditor) (refEditor as any).$refreshFiltersAll();

      // this.filtersArchived.push(filter);
    },

    // 多列条件选择的某一项被点击时
    onMultipleItemClick(multiFilter: MultiFilter) {
      this.currMode = "multiple";
      // this.currMultiFilter = multiFilter.clone();
      this.currMultiFilter = multiFilter;
      this.currViewFilter = undefined;
    },

    // 多列条件选择的删除按钮被点击时
    onMultipleItemBtnDelClick(multiFilter: MultiFilter) {
      ElMessageBox.confirm(
        `是否确定删除多列条件：${multiFilter.title}?`,
        "提示",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }
      )
        .then(() => {
          this.$emit("del-multiple-filter", multiFilter);

          const index = this.multiFilters.findIndex((mf) => mf === multiFilter);
          this.multiFilters.splice(index, 1);

          // --------------------------
          // this.currMode = "init";
          this.backToInit();
        })
        .catch(() => {});
    },

    // 视图列表的某一项被点击时
    onViewItemClick(viewFilter: ViewFilter) {
      this.currMode = "view";
      // this.currViewFilter = viewFilter.clone();
      this.currViewFilter = viewFilter;
      this.currMultiFilter = undefined;
    },

    // 视图列表的删除按钮被点击时
    onViewItemBtnDelClick(viewFilter: ViewFilter) {
      ElMessageBox.confirm(`是否确定删除视图：${viewFilter.title}?`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.$emit("del-view-filter", viewFilter);

          const index = this.viewFilters.findIndex((vf) => vf === viewFilter);
          this.viewFilters.splice(index, 1);

          // --------------------------
          // this.currMode = "init";
          this.backToInit();
        })
        .catch(() => {});
    },

    onInitViewTreeCheckboxChange(node: any, isChecked: boolean) {
      if (this.initViewFilter) {
        this.currMode = "view";

        const newViewFilter = this.initViewFilter;
        newViewFilter.title = this.geneViewFilterTitle();
        this.currViewFilter = newViewFilter;
        // this.newViewFilters.push(newViewFilter);
        this.viewFilters.push(newViewFilter);

        // 再初始化一下 initViewFilter
        this.geneInitViewFilter();
      }
    },

    geneMultiFilterTitle() {
      const oldTitles = this.multiFilters.map((filter) => filter.title);
      // const newTitles = this.newMultiFilters.map((filter) => filter.title);
      const titles = [...oldTitles /* ...newTitles */];

      let index = 0;
      let newTitle;
      do {
        index++;
        newTitle = `多列条件${index}`;
      } while (titles.includes(newTitle));
      return newTitle;
    },

    // 生成视图选择器的标题
    geneViewFilterTitle() {
      const oldTitles = this.viewFilters.map((filter) => filter.title);
      // const newTitles = this.newViewFilters.map((filter) => filter.title);
      const titles = [...oldTitles /* , ...newTitles */];

      let index = 0;
      let newTitle;
      do {
        index++;
        newTitle = `视图${index}`;
      } while (titles.includes(newTitle));
      return newTitle;
    },

    geneInitViewFilter() {
      const filters = this.filters.map((f) => f.clone(true));
      const initViewFilter = new ViewFilter({
        id: TEMP_ID_PREFIX + Date.now(),
        title: "",
        filters,
      });
      this.initViewFilter = initViewFilter;
    },

    backToInit() {
      this.currMode = "init";
      this.currMultiFilter = undefined;
      this.currViewFilter = undefined;
    },

    // 当单列条件选择器提交时
    onSingleConditionsEditorSubmit(filtersChanged: Filter[]) {
      filtersChanged.forEach((filter) => {
        if (filter.archived) {
          const index = this.filters.findIndex((f) => f === filter);
          this.filters.splice(index, 1);
        } else {
          this.filters.push(filter);
        }
      });

      const callback = () => {
        this.singleConditionsDialVisible = false;
      };

      this.$emit("single-conditions-editor-submit", filtersChanged, callback);
    },
  },
});
</script>
<style lang="scss" scoped>
@import "src/flexible-table-module/scss/_variables.scss";

.view-editor {
  .body {
    box-sizing: content-box;
    min-height: 40vh;
    max-height: 60vh;

    border: solid 1px $color-info;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: stretch;

    $side-width: 200px;
    .side {
      min-height: inherit;
      max-height: inherit;
      box-sizing: border-box;
      width: $side-width;
      border-right: solid 1px $color-info;
      padding: 0 8px;
      user-select: none;
      overflow: auto;

      .part {
        width: 100%;

        $title-height: 32px;

        .title {
          height: $title-height;
          line-height: $title-height;
          // color: $color-light;
          display: flex;
          justify-content: space-between;
          align-items: center;

          &.active {
            color: $color-primary;
          }

          .text {
            flex-grow: 1;

            // &:hover {
            //   cursor: pointer;
            // }
          }

          .btn {
            width: $title-height * 0.75;
            height: $title-height * 0.75;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 4px;

            &:hover {
              cursor: pointer;
              fill: $color-reverse;
              background-color: $color-info;
            }
          }
        }

        .content {
          margin-bottom: 8px;
          width: 100%;
          min-height: 32px;
        }
      }
    }
    .main {
      min-height: inherit;
      max-height: inherit;
      box-sizing: border-box;
      width: calc(100% - $side-width);
      // height: 100%;
      // overflow-x: auto;

      .init-view {
        box-sizing: border-box;
        height: 100%;
        // padding: 8px;
      }

      .main-single {
        box-sizing: border-box;
        height: 100%;
        overflow-x: auto;

        display: flex;
        justify-content: flex-start;
        align-items: stretch;

        padding: 8px;

        .main-col {
          box-sizing: border-box;
          min-width: 150px;
          border: solid 1px $color-info;

          &:not(:first-child) {
            margin-left: 8px;
          }

          $col-title-height: 32px;
          .col-title {
            box-sizing: border-box;
            height: $col-title-height;
            line-height: $col-title-height;
            padding: 0 8px;
            border-bottom: solid 1px $color-info;
          }

          .col-tree {
            height: calc(100% - $col-title-height);
          }
        }
      }

      .loading {
        width: 100%;
        height: 100%;
      }
    }
  }
}
</style>