<template>
  <div v-if="suffix === 'svg'" v-html="svgData" class="online-svg"
    :style="`width: ${width}px;height: ${height}px;color: ${isActive ? activeColor : color};`"></div>
  <div v-else class="online-img" :style="`width: ${width}px;height: ${height}px;`">
    <img :src="icon"
      :style="`width: ${width}px;height: ${height}px;${isActive ? setActiveStyle(width, activeColor) : ''}`" alt="">
  </div>
</template>

<script>
export default {
  // 加载在线 SVG(支持变色) PNG(使用滤镜达成变色效果)
  name: 'SvgIcon2',
  props: {
    // svg图标在线地址
    icon: {
      type: String,
      required: true
    },
    // 默认显示颜色
    color: {
      type: String,
      default: '#8997b0'
    },
    // 选中时显示的颜色
    activeColor: {
      type: String,
      default: '#1644ff'
    },
    // svg宽度
    width: {
      type: [Number, String],
      default: 20
    },
    // svg高度
    height: {
      type: [Number, String],
      default: 20
    },
    isActive: {
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {
      svgData: "",
      suffix: "", // 图片文件后缀 svg png
    }
  },
  mounted() {
    this.suffix = this.icon.split(".").pop();
    this.suffix === "svg" && this.getSvgData(this.icon, this.width, this.height);
  },
  watch: {
    icon(newValue) {
      this.suffix = newValue.split(".").pop();
      this.suffix === "svg" && this.getSvgData(newValue, this.width, this.height);
    }
  },
  methods: {
    /**
     * @description: 获取 svg 数据
     * @param {String} icon svg 在线地址
     * @param {Number|String} width svg 宽度
     * @param {Number|String} height svg 高度
     * @return {String} svg 数据
     */
    async getSvgData(icon, width, height) {
      const svgResponse = await fetch(icon);
      const text = await svgResponse.text();
      this.svgData = this.common.getSvgData(text, width, height);
    },
    /**
     * @description: 设置 png 的滤镜样式
     * @param {Number} width 偏移宽度
     * @param {String} activeColor 显示颜色
     * @return {String} 样式
     */
    setActiveStyle(width, activeColor) {
      return `filter: drop-shadow(${activeColor} ${width}px 0); transform: translateX(-${width}px);`
    }
  }
}
</script>

<style lang="scss" scoped>
.online-svg {
  &.is-active {
    color: #1644ff !important;
  }
}

.online-img {
  overflow: hidden;
}
</style>

