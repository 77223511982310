import Vue from 'vue'
import Vuex from 'vuex'
import createVuexAlong from 'vuex-along'
import session from './session'
import local from './local'
import dict from './dict';
import share from "./share";
import back from "./backmanage";
import power from "./power";
import localfile from "./localfile";
import resouece from "./resouece";
import hometable from "./hometable";
Vue.use(Vuex)

export default new Vuex.Store({
  state: {
  },
  getters: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    session,
    local,
    share,
    dict,
    back,
    power,
    localfile,
    resouece,
    hometable
  },
  plugins: [
    createVuexAlong({
      name: "sale-show4",
      session: [session, dict],
      local: [local],
      back: [back],
      power: [power],
      localfile: [localfile],
      resouece: [resouece],
      hometable: [hometable]
      // justSession: true,
    })
  ]
})
