import { BaseColumn } from "./BaseColumn";
import { TColumnaAlign } from "./ColumnTypes";

export class PercentColumn extends BaseColumn {
    public color: string | { greaterThan: number; color: string }[];
    /** 是否必填项(用于前端新建表单时做非空判断) */
    public required: boolean;

    constructor(params: {
        id: string,
        color: string,
        required?: boolean,
        headerAlign?: TColumnaAlign,
        cellAlign?: TColumnaAlign,
        width?: number,
        minWidth?: number,
        active?: boolean,
        settingName?: string,
        title?: string,
        fieldOrder?: number,
    }) {
        const {
            id,

            color,
            required = false,

            headerAlign,
            cellAlign,
            width, // 默认值 undefined
            minWidth,

            active,
            settingName,
            title,
            fieldOrder,
        } = params;


        super({ id, active, settingName, title, fieldOrder, headerAlign, cellAlign, width, minWidth });

        if (!this.width && !this.minWidth) {
            this.minWidth = 120;
        }

        this.color = color;
        this.required = required;
    }
}