<template>
  <div class="content-select w-h-100 flex">
    <div class="content-select-preview flex-1">
      <UpanddownAround
        v-if="currentItem && currentItem.article.templateType == '2'"
        :current="currentItem"
        :loginrequired="loginrequired"
      />
      <LayoutAround v-else :current="currentItem" :loginrequired="loginrequired" />
    </div>
    <div class="preview-bottom flex">
      <div
        class="content-item pointer"
        :class="{ 'active-item': currentItem && currentItem.article.id == item.dataId }"
        v-for="item in assarticleList"
        :key="item.id"
        @click="handleClick(item.dataId)"
      >
        {{ item.toArticleName }}
      </div>
    </div>
  </div>
</template>

<script>
import { ProductLibrary } from "../../../../api/ProductLibrary";
import { Article } from "../../../../api/Article";
import UpanddownAround from "../../../ArticleView/template/UpanddownAround.vue";
import LayoutAround from "../../../ArticleView/template/LayoutAround.vue";
export default {
  components: { UpanddownAround, LayoutAround },
  props: {
    activeColumn: Object,
    subject: Object,
    scheme: Object,
    ispre: {
      type: Boolean,
      default: false,
    },
    loginrequired: {
      type: Boolean,
      default: true, //是否需要登录
    },
    shareId: String, //分享id，用于未登录的查看使用
  },
  data() {
    return {
      articleprops: {
        label: "toArticleName",
        children: "children",
      },
      dialogVisible: false,
      assarticleList: [],
      currentItem: null,
    };
  },
  watch: {
    activeColumn() {
      this.getList();
    },
    scheme() {
      this.getList();
    },
  },
  mounted() {
    if (this.scheme) {
      this.getList();
    }
  },
  methods: {
    /**
     * @description: 关联文章列表
     * @return {void}
     */
    async getList() {
      const payload = {
        schemeId: this.scheme.id,
        subjectColumnId: this.activeColumn.id,
      };
      if (!this.loginrequired) payload.shareId = this.shareId;
      const res = await ProductLibrary[ this.loginrequired ? "getSchemeToArticleList" : "getSchemeToArticleListNoLogin"](payload);
      this.assarticleList = res;
      if (this.assarticleList.length) {
        this.handleClick(this.assarticleList[0].dataId);
      }
    },
    /**
     * @description: 预览文章
     * @param {Object} value 文章id
     * @return {void}
     */
    async handleClick(id) {
      let res;
      if (this.loginrequired) {
        res = await Article.queryArticleDetail(id);
      } else {
        res = await Article.queryArticleDetailNoLogin({
          articleId: id,
          shareId: this.shareId,
        });
      }
      res && (this.currentItem = res);
    },
  },
};
</script>
<style lang="scss" scoped>
.content-select {
  flex-direction: column;
  overflow: hidden;
}

.content-select-preview {
  flex: 1;
  background-color: #fff;
  padding: 10px;
  overflow-y: auto;
}

.preview-bottom {
  height: 24px;
  line-height: 24px;
  font-size: 12px;
  background-color: #f7f9fc;
  color: #a7b4cc;
  justify-content: center;

  .content-item {
    padding: 0 20px;
  }

  .active-item {
    font-weight: 700;
    background-color: #fff;
    color: var(--color-text-primary);
  }
}

.pd-10 {
  padding: 0 10px;
  box-sizing: border-box;
}
</style>
