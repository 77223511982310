<template>
  <el-dialog
    append-to-body
    class="ss-dialog"
    width="524px"
    :title="current ? '编辑演示模式' : '新增演示模式'"
    :close-on-click-modal="false"
    :visible.sync="dialogVisible"
  >
    <div class="content">
      <el-form label-width="110px" ref="form" :model="queryData">
        <el-form-item label="演示模式名称：" prop="columnName">
          <el-input
            v-model="queryData.demoName"
            placeholder="请输入演示模式名称"
          ></el-input>
        </el-form-item>
        <el-form-item label="是否显示：" prop="columnName">
          <el-select v-model="queryData.isShow" placeholder="请选择">
            <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="是否默认：" prop="columnName" v-if="type == 0">
          <el-select v-model="queryData.isDefault" placeholder="请选择">
            <el-option
              v-for="item in options2"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
    </div>
    <span slot="footer">
      <el-button type="info" @click="dialogVisible = false">取消</el-button>
      <el-button type="primary" :loading="confirmLoading" @click="handleConfirm"
        >确定</el-button
      >
    </span>
  </el-dialog>
</template>

<script>
import { mapGetters } from "vuex";
import { ProductLibrary } from "@/api/ProductLibrary";
import { Playeditor } from "@/api/Playeditor";
export default {
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    current: Object,
    queryIds: Object,
    type: Number,
  },
  data() {
    return {
      options: [
        {
          value: 0,
          label: "否",
        },
        {
          value: 1,
          label: "是",
        },
      ],
      options2: [
        {
          value: 0,
          label: "否",
        },
        {
          value: 1,
          label: "是",
        },
      ],
      queryData: {
        authType: 0, //0.租户共有，1.个人所有
        isShow: 1, //是否显示(0.否，1.是)
        isDefault: 0, //是否默认(0.否，1.是)
        demoModeId: null,
        demoName: "",
        moduleId: "",
        showProjectOutlineVo: {
          content: "演示模式提纲",
          contentVos: [
            {
              content: "",
              refVo: null,
            },
          ],
          parentId: "-1",
          sortNo: 0,
          versionId: "",
          children: [],
          outlineType: 1,//提纲类型(0.默认,1.网页，2.产品，3.栏目，4.文章，5.链接)
        },
        subjectId: "",
        versionId: "",
      },
      confirmLoading: false,
    };
  },
  computed: {
    dialogVisible: {
      get() {
        return this.visible;
      },
      set(val) {
        this.$emit("update:visible", val);
      },
    },
    ...mapGetters(["activeMenu"]),
  },
  mounted() {
    if (this.current) {
      this.getDemoDetail();
    } else {
      this.queryData.subjectId = this.queryIds.subjectId;
      this.queryData.versionId = this.queryIds.versionId;
      this.queryData.moduleId = this.queryIds.moduleId;
    }
    this.queryData.authType = this.type;
  },
  methods: {
    /**
     * @description: 确定
     * @return {void}
     */
    async handleConfirm() {
      const res = await Playeditor.saveSubjectDemoMode(this.queryData);
      if (res) {
        this.$message.success("成功");
        this.$emit("finish");
        this.dialogVisible = false;
      }
    },
    /**
     * @description: 获取演示详情
     * @return {void}
     */
    async getDemoDetail() {
      const res = await Playeditor.querySubjectDemoModeDetail(this.current.id);
      this.queryData = _.pick(res, "demoName", "subjectId", "versionId", "moduleId");
      this.queryData.showProjectOutlineVo = res.showProjectOutlineVos[0];
      this.queryData.demoModeId = this.current.id;
      this.queryData.isShow = this.current.isShow;
      this.queryData.isDefault = this.current.isDefault;
      this.queryData.authType = this.type;
    },
  },
};
</script>

<style lang="scss" scoped>
.content {
  padding: 30px;
}
</style>
