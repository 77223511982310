<template>
  <div class="myfile-disk">
    <div class="myfile-disk-item cloud-disk">
      <el-tabs v-model="activeName"  @tab-click="handleClick">
        <el-tab-pane label="应用空间" name="1"></el-tab-pane>
        <el-tab-pane label="企业空间" name="2"></el-tab-pane>
        <el-tab-pane label="个人空间" name="3"></el-tab-pane>
        <el-tab-pane label="我的电脑" name="second"></el-tab-pane>
      </el-tabs>
      <div class="myfile-disk-item__content" ref="cloudTableWrapper" v-if="activeName != 'second'"
        v-loading="TableLoading">
        <div class="file-breadcrumb">
          <span v-if="breadcrumbList.length" class="pointer" @click="getCloudDiskList">全部</span>
          <span v-for="(item, index) in breadcrumbList" :key="item.id">
            <span>&nbsp;/&nbsp;</span>
            <span class="pointer" @click="jumpTo(item, index)">{{ item.folderName
            }}</span>
          </span>
        </div>
        <el-table v-if="cloudTableLoading" class="cloud-table" lazy header-cell-class-name="app-header-cell"
          row-class-name="draggable-row" row-key="id" :data="cloudTable" :height="cloudTableHeight">
          <el-table-column label="名称" prop="folderName" min-width="150px">
            <template slot-scope="{ row }">
              <div class="filename pointer" @click.prevent="openfolder(row)">
                <svg-icon :icon="row.icon" :color="row.color"></svg-icon>
                <div :title="row.folderName" class="cloud-file-name one-line">{{ row.folderName }}</div>
              </div>
            </template>
          </el-table-column>
          <el-table-column label="修改时间" prop="updateTime" min-width="150px">
            <template slot-scope="{ row }">
              <span>{{ Object.dayjs(row.updateTime ? row.updateTime : row.createTime) }}</span>
            </template>
          </el-table-column>
          <el-table-column label="修改人" min-width="90px">
            <template slot-scope="{ row }">
              <div :title="row.updateBy ? row.updateBy : row.createBy" class="one-line">{{ row.updateBy ? row.updateBy :
                row.createBy }}</div>
            </template>
          </el-table-column>
          <el-table-column label="大小" prop="fileSize" min-width="90px">
            <template slot-scope="{ row }">
              <span>{{ row.fileSize }}</span>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div v-if="activeName == 'second'" style="height: 100%;width: 100%;">
        <LocalNetwork />
        <!-- <div class="myfile-disk-item__content flex-center" style="lineHeight:60px">功能正在开发中...</div> -->
      </div>
    </div>
    <FilePreview v-if="preview" :visible.sync="preview" :haslist="false" :currentitem.sync="currentItem" />
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import { File } from "@/api/File";
import NETSYSTEM from "@/api/NETSYSTEM";
import { officeView, checkFileIcon } from "@/utils/file";
import FilePreview from '@/components/dialog/FilePreview.vue';
import LocalNetwork from '@/components/LocalFile/index.vue';
export default {
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    // subject: Object, // 主题详情
  },
  components: { FilePreview, LocalNetwork },
  data() {
    return {
      cloudTableLoading: false,
      TableLoading: false,
      cloudTable: [],
      cloudTableHeight: 600,
      activeName: '1',
      flattenCloudTable: [],
      breadcrumbList: [],
      currentItem: null,
      preview: false,
      activetag:  '1'
    };
  },
  watch: {},
  computed: {
    ...mapGetters(["token", "userInfo", "activeMenu"]),
    showdialog: {
      get() {
        return this.visible;
      },
      set(val) {
        this.$emit("update:visible", val);
      },
    },
    filterFirstCategoryList() {
      return this.firstCategoryList.filter((item) => item.show);
    },
  },
  created() {
  },
  mounted() {
    this.$nextTick(() => {
      this.cloudTableHeight = this.$refs.cloudTableWrapper.offsetHeight - 60;
    });
    this.getCloudDiskList()
  },
  methods: {
    /**
     * @description: 获取租户下文件系统目录列表(右侧云盘)
     * @return {void}
     */
    async getCloudDiskList() {
      this.cloudTableLoading = false;
      // this.cloudTable = await File.queryFileSystemList();
      // const res = await File.queryFileSystemList();
      let res = []
      if (this.activetag === "1") {
        res = await File.queryFileSystemList();
      } else if (this.activetag === "2") {
        res = await NETSYSTEM.getTenantNetDiskDirectory();
      } else {
        const res2 = await NETSYSTEM.getMyNetDiskDirectory();
        res= res2.records
      }
      res.forEach(element => {
        if (element.fileType == 1) {
          element.fileSize = this.common.formatFileSize(element.fileSize);
          element.aliasType = this.common.getFileType(element.contentType, element.fileSavePath);
          element.icon = checkFileIcon(element.fileSavePath);
        } else {
          element.fileSize = '--';
          element.aliasType = "folder";
          element.icon = "filetype-floder"
        }
        element.color = this.common.getFileColor(element.aliasType);
      })
      this.cloudTable = res;
      this.cloudTableLoading = true;
      this.$forceUpdate();
      this.breadcrumbList = [];
      await this.$nextTick();
      let newArr = [];
      this.flatten(this.cloudTable, newArr);
      this.flattenCloudTable = newArr;
      this.setDraggable(newArr);
    },
    async loadChildren(tree, treeNode, resolve) {
      const res = await File.queryFileByFolderId({
        folderId: tree.id,
        pageNo: 1,
        pageSize: 9999,
      });
      res.records.forEach(element => {
        // element.hasChild = element.fileType == 2 && element.hasChild == 1;
        element.hasChild = element.fileType == 2;
        if (element.fileType == 1) {
          element.fileSize = this.common.formatFileSize(element.fileSize);
          element.aliasType = this.common.getFileType(element.contentType, element.fileSavePath);
          element.icon = checkFileIcon(element.fileSavePath);
        } else {
          element.fileSize = '--';
          element.aliasType = "folder";
          element.icon = "filetype-floder"
        }
        element.color = this.common.getFileColor(element.aliasType);
      })
      tree.children = res.records;
      resolve(res.records);
      await this.$nextTick();
      let newArr = [];
      this.flatten(this.cloudTable, newArr);
      this.flattenCloudTable = newArr;
      this.setDraggable(newArr);
    },
    /**
   * @description: 云盘表格一维化
   * @param {*} array
   * @param {*} newArray
   * @return {*}
   */
    flatten(array, newArray) {
      array.forEach(element => {
        newArray.push(_.pick(element, ["id", "parentId", "folderName", "fileType", "fileSize"]));
        if (element.children && element.children.length) {
          this.flatten(element.children, newArray);
        }
      })
    },
    /**
     * @description: 设置企业云盘表格可拖拽
     * @param {Array} tableData 云盘表格数据(一维数组)
     * @return {void}
     */
    setDraggable(tableData) {
      const el = document.querySelectorAll(".draggable-row");
      // 企业云盘表格每行设置为可拖拽
      el.forEach((element, index) => {
        element.setAttribute("draggable", true);
        element.setAttribute("id", `${tableData[index].id}-${tableData[index].fileType}`);
        element.ondragstart = function (event) {
          event.dataTransfer.setData("Text", event.target.id);
        }
      })
    },
    /**
     * @description: 点击面包屑获取文件列表
     * @param {Object} value 点击项
     * @param {Number} index 索引
     * @return {void}
     */
    jumpTo(value, index) {
      if (index === this.breadcrumbList.length - 1) {
        return;
      }
      if (value) {
        this.breadcrumbList.splice(index);
      } else {
        this.breadcrumbList = [];
      }
      this.openfolder(value)
    },
    /**
     * @description:展开文件夹
     * @param {Object} value 点击项
     * @return {void}
     */
    async openfolder(value) {
      if (!value.hasChild) {
        this.handleView(value)
        return
      }
      let data2 = {
        folderId: value.id,
        name: "",
        sort: 'createTime',
        sortType: 'desc',
        pageNo: 1,
        pageSize: 9999,
      }
      this.TableLoading = true;
      let res1 = await File.queryFileByFolderId(data2)
      this.breadcrumbList.push(value);
      res1.records.forEach(element => {
        if (element.fileType == 1) {
          element.fileSize = this.common.formatFileSize(element.fileSize);
          element.aliasType = this.common.getFileType(element.contentType, element.fileSavePath);
          element.icon = checkFileIcon(element.fileSavePath);
        } else {
          element.fileSize = '--';
          element.aliasType = "folder";
          element.icon = "filetype-floder"
        }
        element.color = this.common.getFileColor(element.aliasType);
      })
      this.cloudTable = res1.records;
      await this.$nextTick();
      this.TableLoading = false;
      this.setDraggable(this.cloudTable);
    },
    /**
     * @description: 预览
     * @param {Object} value 点击项
     * @return {void}
     */
    handleView(value) {
      if (value.aliasType === "image") {
        this.$elImageViewer({ urlList: [value.fileSavePath], initialIndex: 0 });
      }else{
        this.currentItem = value;
        this.preview = true;
      }
      
      // if (value.aliasType === "office") {
      //   officeView(value.id);
      // }
      // if (value.aliasType === "image") {
      //   this.$elImageViewer({ urlList: [value.fileSavePath], initialIndex: 0 });
      // }
      // if (value.aliasType === "video") {
      //   this.currentItem = value
      //   this.preview = true
      // }
    },
    handleClick() {
      this.activetag = this.activeName;
      this.getCloudDiskList()
    }
  },
};
</script>
<style scoped lang="scss">
.myfile-disk {
  box-sizing: border-box;
  height: 100%;
  max-width: 560px;
  position: relative;
  overflow: hidden;
  padding: 20px;
  box-sizing: border-box;
  background-color: #fff;
  border-radius: 5px 5px 0px 0px;

  .myfile-disk-item {
    box-sizing: border-box;
    height: 100%;
    border-radius: 5px;
    background-color: #fff;

    ::v-deep .el-tabs__nav-wrap::after {
      background-color: transparent;
    }

    .myfile-disk-item__title {
      line-height: 20px;
      margin-bottom: 16px;
      font-size: 14px;
      font-weight: 700;
      color: var(--color-text-regular);
    }

    .myfile-disk-item__content {
      height: calc(100% - 36px);
      overflow-y: auto;
    }
  }
}

.cloud-table {
  &::before {
    height: 0;
  }

  .el-table__expand-icon {
    margin-right: 0;
  }
}

.cloud-file-name {
  word-break: break-all;
  line-height: 20px;
}

.file-breadcrumb {
  // height: 18px;
  line-height: 18px;
  margin-bottom: 16px;
  color: #5e6c84;
  font-size: 12px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  
  span {
    &:hover {
      color: #6A87FC;
    }
  }
}

.mr-10 {
  margin-right: 10px;
}

.filename {
  display: grid;
  grid-template-columns: 20px auto;
  gap: 7px;
}
</style>