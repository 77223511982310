<template>
  <div class="form-cell-4-date-time-frame">
    <template v-if="columnType === 'time-frame'">
      <ElTimePicker
        :class="{ highlight }"
        v-model="cValue"
        :size="FORM_ITEM_SIZE"
        class="form-cell-4-date-time-frame__input"
        is-range
      ></ElTimePicker>
    </template>
    <template
      v-else-if="columnType === 'date-frame' || columnType === 'datetime-frame'"
    >
      <ElDatePicker
        :class="{ highlight }"
        v-model="cValue"
        :size="FORM_ITEM_SIZE"
        class="form-cell-4-date-time-frame__input"
        :type="rangeType"
      ></ElDatePicker>
    </template>
  </div>
</template>
<script lang="ts">
import Vue from "vue";
import { mixin } from "./mixin";

import {
  TimePicker as ElTimePicker,
  DatePicker as ElDatePicker,
} from "element-ui";

export default Vue.extend({
  inheritAttrs: false,
  name: "FormCell4DateTimeFrame",
  components: { ElTimePicker, ElDatePicker },
  mixins: [mixin],
  props: {
    columnType: {
      type: String,
      required: true,
      validator(value: "time-frame" | "date-frame" | "datetime-frame") {
        return ["time-frame", "date-frame", "datetime-frame"].includes(value);
      },
    },
  },
  computed: {
    rangeType(): "daterange" | "datetimerange" {
      switch (this.columnType) {
        case "date-frame":
          return "daterange";
        case "datetime-frame":
          return "datetimerange";
        default:
          throw new Error(`Invalid columnType: ${this.columnType}`);
      }
    },
  },
});
</script>
<style lang="scss" scoped>
@import "src/flexible-table-module/scss/common.scss";
.form-cell-4-date-time-frame {
  @include form-cell-highlight;

  &__input {
    width: 100% !important;
  }
}
</style>