<template>
  <div class="column-index">{{ scope.$index + 1 }}</div>
</template>

<script lang="ts">
import Vue from "vue";

export default Vue.extend({
  inheritAttrs: false,
  name: "ColumnIndex",
  props: {
    scope: { type: Object, required: true },
  },
});
</script>
<style lang="scss" scoped>
.column-index {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>