<template>
  <div class="flex w-h-100 file-preview">
    <SplitPaneRight
      :initialrightWidth="showfile ? 250 : 0"
      :dividerPosition="2"
      :minPaneWidth="180"
    >
      <template v-slot:left>
        <div class="flex align-items-center retract-icon" v-if="!showfile">
          <svg-icon
            class="pointer"
            icon="icon-close-floder"
            color="#5E6C84"
            :height="36"
            :width="48"
            @click.native="showfile = true"
          ></svg-icon>
        </div>
        <FileTemPreview
          v-if="preview"
          :visible.sync="preview"
          :haslist="false"
          :currentitem.sync="currentItem"
          :color="'#344563'"
          :loginrequired="loginrequired"
        />
      </template>
      <template v-slot:right>
        <div class="app-scheme-left file-list" v-if="showfile">
          <div class="scheme-title flex-between align-items-center">
            <div class="scheme-title-name">展示文件</div>
            <div class="flex align-items-center">
              <svg-icon
                class="mr-10 pointer"
                icon="close-side"
                color="#344563"
                :height="16"
                :width="16"
                @click.native="showfile = false"
              ></svg-icon>
            </div>
          </div>

          <div class="scheme-title-list file-list" v-loading="loading">
            <el-tree
              :data="treeData"
              ref="filetree"
              node-key="id"
              style="margin-top: 10px"
            >
              <div
                class="custom-tree-node flex align-items-center"
                :class="{ 'active-tree-node': currentItem && currentItem.id == data.id }"
                slot-scope="{ node, data }"
              >
                <svg-icon :icon="data.icon"></svg-icon>
                <div
                  class="one-line file-label"
                  :title="node.label"
                  @click="handleClick(data)"
                  @dblclick.prevent.stop="handledbClick(data, node)"
                >
                  <!-- {{ node.label }} -->
                  <span v-if="!data.showprogress">{{ node.label }}</span>
                  <el-progress
                    v-else
                    color="#4ed4cb"
                    :percentage="data.progress"
                    style="width: 140px; margin-top: 10px; font-size: 12px"
                  ></el-progress>
                </div>
              </div>
            </el-tree>
          </div>
        </div>
      </template>
    </SplitPaneRight>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import { File } from "@/api/File";
import { DownloadFile, officeView } from "@/utils/file";
import FileTemPreview from "../../../../components/filecompanies/FileTemPreview.vue";
import PlugInDialog from "../../../../components/dialog/PlugIn.vue";
import { ProductLibrary } from "@/api/ProductLibrary";
import SplitPaneRight from "@/components/SplitPaneRight.vue";

export default {
  props: {
    subject: Object, // 主题详情
    scheme: Object,
    loginrequired: {
      type: Boolean,
      default: true, //是否需要登录
    },
    shareId: String, //分享id，用于未登录的查看使用
  },
  components: {
    FileTemPreview,
    PlugInDialog,
    SplitPaneRight,
  },
  data() {
    return {
      showfile: true,
      treeData: [],
      tableData: [],
      previewpayload: {},
      preview: false,
      selectList: [], // 选择的文件列表
      currentItem: null, //点击项
      loading: false,
    };
  },
  watch: {
    isconnected(val) {
      if (val) {
        this.init();
      }
    },
    scheme(val) {
      if (val) {
        this.breadcrumbList = [];
        this.previewpayload = {
          moduleId: this.subject.moduleId,
          schemeId: this.scheme.id,
          subjectId: this.subject.id,
          fileName: "",
        };
        this.getFirstTableData();
      }
    },
  },
  computed: {
    defaultProps() {
      return {
        children: "children",
        label: "label",
      };
    },
    ...mapGetters(["token", "userInfo", "isconnected"]),
  },
  mounted() {
    if (this.scheme) {
      this.previewpayload = {
        moduleId: this.subject.moduleId,
        schemeId: this.scheme.id,
        subjectId: this.subject.id,
        fileName: "",
      };
      if(!this.loginrequired){
        this.previewpayload.shareId = this.shareId;
      }
      this.getFirstTableData();
      this.init();
    }
  },
  beforeDestroy() {
    this.$messageService.$off("websocketMessage");
  },
  methods: {
    /**
     * @description:获取列表
     * @return {void}
     */
    async getFirstTableData() {
      this.loading = true;
      const res = await ProductLibrary[this.loginrequired ?"queryFileBySubjectScheme":"queryFileBySubjectSchemeNoToken"](this.previewpayload);
      res.forEach((element) => {
        if (element.fileType != 2) {
          element.fileSize = this.common.formatFileSize(element.fileSize);
          element.aliasType = this.common.getFileType(
            element.contentType,
            element.fileSavePath
          );
          element.aliasStringType = this.getFileType(
            element.contentType,
            element.fileSavePath
          );
          element.icon = this.getFileIcon(element.aliasStringType);
        } else {
          element.aliasType = "folder";
          element.aliasStringType = "文件夹";
        }
        element.showprogress = false;
        element.progress = 0;
      });
      this.tableData = res;
      const tree = this.organizeByAliasType(this.tableData);
      this.treeData = tree.filter((item) => item.label != "文件夹");
      this.loading = false;
    },
    organizeByAliasType(data) {
      const result = [];
      const typeMap = {};

      data.forEach((item) => {
        const type = item.aliasStringType;
        item.label = item.folderName ? item.folderName : item.fileName;

        if (!typeMap[type]) {
          typeMap[type] = {
            label: type,
            isparent: true,
            id: type,
            icon: this.getFileIcon(type),
            children: [],
          };
          result.push(typeMap[type]);
        }

        typeMap[type].children.push(item);
      });

      return result;
    },
    /**
     * @description: 根据 contentType 和 url 获取文件类型
     * @param {String} contentType 后端返回的类型
     * @param {String} url 文件地址
     * @return {String} 返回的文件类型
     */
    getFileType(contentType, url) {
      const suffix = "." + url.split(".").pop();
      if (suffix == ".pptx" || suffix == ".ppt") {
        return "PPT";
      } else if (suffix == ".docx" || suffix == ".wps" || suffix == ".doc") {
        return "文档";
      } else if (suffix == ".pdf") {
        return "PDF";
      } else if (suffix == ".xlsx" || suffix == ".xls") {
        return "表格";
      } else if (this.$global.modelType.indexOf(suffix) !== -1) {
        return "模型";
      } else if (this.$global.videoType.indexOf(suffix) !== -1) {
        return "视频";
      } else if (this.$global.ImageType.indexOf(suffix) !== -1) {
        return "图片";
      } else {
        return "其他";
      }
    },
    /**
     * @description: 根据文件类型返回图标
     * @param {String} 文件类型
     * @return {String} 返回的文件类型图标
     */
    getFileIcon(type) {
      if (type == "PPT") {
        return "filetype-ppt";
      } else if (type == "文档") {
        return "filetype-doc";
      } else if (type == "PDF") {
        return "filetype-pdf";
      } else if (type == "表格") {
        return "filetype-excel";
      } else if (type == "模型") {
        return "filetype-model";
      } else if (type == "视频") {
        return "filetype-video";
      } else if (type == "图片") {
        return "filetype-image";
      } else {
        return "filetype-unknown";
      }
    },
    /**
     * @description: 预览
     * @param {Object} value 点击项
     * @return {void}
     */
    handleClick(data) {
      if (data.isparent) {
        return;
      }
      if(!this.loginrequired){
        data.shareId = this.shareId;
      }
      this.currentItem = data;
      this.preview = true;
    },
    /**
     * @description: 行双击下载文件并在本地打开
     * @param {Object} value 点击项
     * @return {void}
     */
    handledbClick(value) {
      if (value.isparent) {
        return;
      }
      if (!this.isconnected) {
        this.plugvisible = true;
        return;
      }
      this.percentage = 0;
      if (this.$websocket.readyState === WebSocket.OPEN) {
        // 发送消息
        let queryDownloadData = {
          msgId: value.id,
          funcname: "downloadfile",
          downloadType: "1", //下载消息类型，1:开始，2:暂停,3:取消
          systemType: "1",
          token: this.token ? this.token : "",
          tenantId: this.userInfo ? this.userInfo.tenantIdNow : "",
          baseurl: process.env.VUE_APP_URL_NETDISK,
          fileSysBaseUrl: process.env.VUE_APP_URL_NETDISK,
          fileId: value.id,
          isCurrent: "1", //是否下载当前(1:是当前，2：是历史)(如果是历史版本必填)
          sysToken:"JqjdLEtaFOas5whS8hro2w=="
        };
        this.$nextTick(() => {
          this.$websocket.send(JSON.stringify(queryDownloadData));
        });
      }
    },
    /**
     * @description: 初始化消息
     * @return {void}
     */
    init() {
      this.$messageService.$on("websocketMessage", (data) => {
        // 处理接收到的消息
        const parsedMessage = JSON.parse(data);
        if (parsedMessage.funcname == "downloadingFile") {
          let percentage = Number(parsedMessage.data[0].progress.replace("%", ""));
          this.UpdatePercent(parsedMessage.msgId, true, percentage);
          if (parsedMessage.data[0].status === "DONE" && parsedMessage.data[0].progress) {
            this.isDownloading = false;
            this.UpdatePercent(parsedMessage.msgId, false, percentage);
          }
        }
      });
    },
    /**
     * @description: 更新节点下载的百分比
     * @param {String} id 点击项
     * @param {Boolean} showprogress 是否显示进度条
     * @param {Number} percentage 百分比
     * @return {void}
     */
    UpdatePercent(id, showprogress, percentage) {
      this.treeData.forEach((item) => {
        let index = item.children.findIndex((item) => item.id == id);
        if (index != "-1") {
          item.children[index].progress = percentage;
          item.children[index].showprogress = showprogress;
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
@import "~@/scss/common.scss";
.file-preview {
  flex: 1;
}
.file-main {
  flex: 1;
  position: relative;
  overflow: hidden;
  height: 100%;
  width: 100% !important;
}
.retract-icon {
  justify-content: center;
  width: 54px;
  height: 54px;
  position: absolute;
  right: 18px;
  top: 50%;
  line-height: 22px;
  color: var(--color-text-primary);
  text-align: right;
  z-index: 3;
  box-shadow: 0px 4px 4px 4px rgba(241, 235, 255, 0.4);
  background-color: #fff;
}
.active {
  font-weight: 700;
  color: var(--color-primary);
}
.file-label {
  // max-width: 100px;
  max-width: calc(100% - 50px);
  margin-left: 5px;
  line-height: 30px;
  overflow: hidden;
}
.custom-tree-node {
  padding-left: 5px;
  width: 100%;
  .svg-icon {
    flex-shrink: 0;
  }
}
.active-tree-node {
  background-color: #f1ebff;
  font-weight: 700;
  color: var(--color-primary);
  border-radius: 5px;
}
::v-deep .el-tree-node__content {
  height: 30px;
}
.file-list {
  width: 100% !important;
  flex-shrink: 0;
  border-left: 1px solid #ebeef2;
}
</style>
