export class ResizeObserverWrapper {
    private resizeObserver: ResizeObserver | null;
    private target: Element | null;
    private callbacks: ((target: Element) => void)[] = [];

    /**
     * 构造函数
     * @param target 监听目标
     */
    constructor(target: Element) {
        if (!target) throw new Error("检测目标不能为空");
        this.target = target;

        this.resizeObserver = new ResizeObserver((entries) => {
            this.callbacks.forEach(callback => {
                if (this.target) callback(this.target)
            });
        });

        this.resizeObserver.observe(this.target);
    }

    // 监听目标变化
    public afterResized(callback: (target: Element) => void) {
        if (this.resizeObserver === null)
            throw new Error("ResizeObserverWrapper已销毁");

        this.callbacks.push(callback);
    }

    // 销毁
    public destroy(beforDestroy?: (target: Element) => void) {
        if (beforDestroy) {
            if (this.target) beforDestroy(this.target);
        }
        this.callbacks = [];
        this.resizeObserver?.disconnect();
        this.resizeObserver = null;
        this.target = null;
    }
}