<template>
  <div class="datetime-frame">
    <template v-if="isTime">
      <ElTimePicker
        class="picker"
        is-range
        v-model="value"
        range-separator="至"
        start-placeholder="开始时间"
        end-placeholder="结束时间"
        placeholder="选择时间范围"
        :format="TIME_FORMAT"
      >
      </ElTimePicker>
    </template>
    <template v-else-if="isDate">
      <ElDatePicker
        class="picker"
        v-model="value"
        type="daterange"
        range-separator="至"
        start-placeholder="开始日期"
        end-placeholder="结束日期"
        :format="DATE_FORMAT"
      >
      </ElDatePicker>
    </template>
    <template v-else-if="isDatetime">
      <ElDatePicker
        class="picker"
        v-model="value"
        type="datetimerange"
        range-separator="至"
        start-placeholder="开始日期时间"
        end-placeholder="结束日期时间"
        :format="DATETIME_FORMAT"
      >
      </ElDatePicker>
    </template>

    <SubmitAndCancel @submit="onSubmit" @cancel="onCancel" />
  </div>
</template>
<script lang="ts">
import Vue from "vue";
import {
  TimePicker as ElTimePicker,
  DatePicker as ElDatePicker,
} from "element-ui";

import SubmitAndCancel from "../../Common/SubmitAndCancel.vue";
import {
  DATE_FORMAT,
  TIME_FORMAT,
  DATETIME_FORMAT,
} from "@/flexible-table-module/constants";

export default Vue.extend({
  inheritAttrs: false,
  name: "DateTimeFrame",
  components: { ElTimePicker, ElDatePicker, SubmitAndCancel },
  props: {
    datetime: { type: Array },
    type: { type: String },
  },
  data() {
    return {
      value: this.datetime,
      DATE_FORMAT,
      TIME_FORMAT,
      DATETIME_FORMAT,
    };
  },
  computed: {
    isTime(): boolean {
      return this.type === "time-frame" || this.type === "time";
    },
    isDate(): boolean {
      return this.type === "date-frame" || this.type === "date";
    },
    isDatetime(): boolean {
      return (
        this.type === "datetime-frame" ||
        this.type === "datetime" ||
        this.type === "short-datetime"
      );
    },
  },
  methods: {
    onSubmit() {
      this.$emit("submit", this.value);
    },
    onCancel() {
      this.value = this.datetime;
      this.$emit("cancel");
    },
  },
});
</script>
<style lang="scss" scoped>
.datetime-frame {
  .picker {
    width: 100%;
  }
}
</style>