<template>
  <el-dialog v-if="dialogVisible" append-to-body class="ss-dialog no-header-bottom" title="演示链接" width="600px"
    :visible.sync="dialogVisible">
    <div class="demo-dialog-body">
      <el-form label-width="100px" ref="demoLink" :model="mylink">
        <el-form-item label="名称" prop="name" :rules="setRules('请输入名称')">
          <el-input v-model="mylink.name" placeholder="请输入名称"></el-input>
        </el-form-item>
        <el-form-item label="登录方式" prop="loginType" :rules="setRules('请选择登录方式')">
          <el-select v-model="mylink.loginType" placeholder="请选择登录方式" style="width: 100%;">
            <el-option disabled label="SaaS单点登录" value="1"></el-option>
            <el-option label="账号密码登录" value="2"></el-option>
            <el-option disabled label="不支持单点登录" value="3"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="链接" prop="demoLink" :rules="setRules('请输入链接')">
          <el-input v-model="mylink.demoLink" placeholder="请输入链接"></el-input>
        </el-form-item>
        <el-form-item label="用户名" prop="loginAccount">
          <el-input v-model="mylink.loginAccount" placeholder="请输入用户名"></el-input>
        </el-form-item>
        <el-form-item label="密码" prop="loginPassword">
          <el-input v-model="mylink.loginPassword" placeholder="请输入密码"></el-input>
        </el-form-item>
      </el-form>
    </div>
    <div slot="footer">
      <el-button type="info" @click="dialogVisible = false">取&nbsp;消</el-button>
      <el-button style="width: 112px;margin-left: 24px;" type="primary" @click="confirmDemoLink">确&nbsp;定</el-button>
    </div>
  </el-dialog>
</template>

<script>
export default {
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    demoLink: Object,
  },
  data() {
    return {
      mylink: {},
    };
  },
  computed: {
    dialogVisible: {
      get() {
        return this.visible;
      },
      set(val) {
        this.$emit("update:visible", val);
      },
    },
  },
  mounted() {
    if (!this.demoLink || !this.demoLink.id) {
      this.mylink = {
        id: "",
        name: "",
        loginType: "2",
        demoLink: "",
        loginAccount: "",
        loginPassword: "",
        subjectId: "",
      };
    } else {
      this.mylink = _.cloneDeep(this.demoLink);
    }
  },
  methods: {
    /**
     * @description: 确定添加、编辑演示链接
     * @return {void}
     */
    confirmDemoLink() {
      this.$refs.demoLink.validate((valid) => {
        if (!valid) {
          return;
        }
        this.$emit("finish", this.mylink);
        this.dialogVisible = false;
      });
    },
    /**
     * @description: 设置表单验证规则
     * @param {String} message 校验错误信息
     * @return {Object} 表单验证规则
     */
    setRules(message) {
      return { required: true, message, trigger: ["blur", "change"] };
    },
  },
};
</script>

<style lang="scss" scoped>
.demo-dialog-body {
  padding: 0 30px;
}
</style>