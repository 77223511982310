<template>
  <div class="column-percent">
    <ElProgress :percentage="percentage" :color="fineColor"></ElProgress>
  </div>
</template>
<script lang="ts">
import Vue, { PropType } from "vue";
import { Progress as ElProgress } from "element-ui";

export default Vue.extend({
  inheritAttrs: false,
  name: "ColumnPercent",
  components: { ElProgress },
  props: {
    percentage: { type: Number, required: true },
    color: {
      type: [String, Array] as PropType<
        string | { greaterThan: number; color: string }[]
      >,
    },
  },
  computed: {
    fineColor() {
      if (Array.isArray(this.color)) {
        // 对 this.color 数组进行排序，使得 greaterThan 值较大的项在前
        const sortedColors = [...this.color].sort(
          (a, b) => b.greaterThan - a.greaterThan
        );
        for (const item of sortedColors) {
          if (this.percentage > item.greaterThan) {
            return item.color;
          }
        }
        // 如果没有匹配的 greaterThan 值，则返回 undefined
        return undefined;
      } else if (typeof this.color === "string") {
        return this.color;
      } else return undefined;
    },
  },
});
</script>
<style lang="scss" scoped>
@import "src/flexible-table-module/scss/_variables.scss";

.column-percent {
  width: 100%; // 百分比不应受居左居右居中影响

  ::v-deep .el-progress {
    .el-progress__text {
      font-size: 14px !important;
      color: $clr-el-table !important;
    }
  }
}
</style>