import request from "@/utils/appServer";
class Share {
  // 分享空间 - 更新已读未读、已下载状态
  static async getShare(data = {}) {
    try {
      const res = await request.post('/sales-show-4.0/shareLog/updateStatus', data);
      return res.data.code === 200 && res.data.result ? res.data.result : [];
    } catch (error) {
      return [];
    }
  }
  // 分享空间 - 新增
  static async addShare(data = {}) {
    try {
      const res = await request.post('/sales-show-4.0/shareLog/addShareLog', data);
      return res.data.code === 200 && res.data.result ? res.data.result : [];
    } catch (error) {
      return [];
    }
  }
  // 分享空间 - 收录
  static async IncludeShare(id) {
    try {
      const res = await request.get('/sales-show-4.0/shareLog/include?shareId=' + id);
      return res.data.code === 200 && res.data.result ? res.data.result : "";
    } catch (error) {
      return [];
    }
  }
  // 分享空间 - 我分享的
  static async ShareFromMeList(data = {}) {
    try {
      const res = await request.post('/sales-show-4.0/shareLog/queryShareFromMeList', data);
      return res.data.code === 200 && res.data.result ? res.data.result : [];
    } catch (error) {
      return [];
    }
  }
  // 分享空间 - 分享给我的
  static async ShareToMeList(data = {}) {
    try {
      const res = await request.post('/sales-show-4.0/shareLog/queryShareToMeList', data);
      return res.data.code === 200 && res.data.result ? res.data.result : [];
    } catch (error) {
      return [];
    }
  }
  // 分享空间 - 删除分享
  static async deleteMyShare(id) {
    try {
      const res = await request.get('/sales-show-4.0/shareLog/deleteShareLog?shareLogId=' + id, true);
      return res.data.code === 200 && res.data.success ? res.data : [];
    } catch (error) {
      return [];
    }
  }
  // 分享空间 - 更新已读未读、已下载状态
  static async updateStatus(data = {}) {
    try {
      const res = await request.post('/sales-show-4.0/shareLog/updateStatus', data);
      return res.data.code === 200 && res.data.success ? res.data : [];
    } catch (error) {
      return [];
    }
  }
  // 分享 - 删除分享
  static async cancelMyShare(id) {
    try {
      const res = await request.get('/sales-show-4.0/share/deleteShare?shareId=' + id);
      return res.data.code === 200 && res.data.result ? res.data.result : [];
    } catch (error) {
      return [];
    }
  }
  // 分享 - 新增
  static async addMyShare(data = {}) {
    try {
      const res = await request.post('/sales-show-4.0/share/addShare', data);
      return res.data.code === 200 && res.data.result ? res.data.result : [];
    } catch (error) {
      return [];
    }
  }
  // 分享 - 编辑分享
  static async editShare(data = {}) {
    try {
      const res = await request.post('/sales-show-4.0/share/editShare', data);
      return res.data.code === 200 && res.data.result ? res.data.result : [];
    } catch (error) {
      return [];
    }
  }
  // 分享 -查看分享链接访问的认证方式
  static async getShareAuthWay(id) {
    try {
      const res = await request.get('/sales-show-4.0/share/getShareAuthWay?shareId=' + id, true);
      return res.data.code === 200 && res.data.result ? res.data.result : false;
    } catch (error) {
      return false;
    }
  }
  // 根据分享id查看分享
  static async getInfoById(id) {
    try {
      const res = await request.get('/sales-show-4.0/share/getInfoById?shareId=' + id);
      return res.status === 200 && res.data.result ? res.data.result : [];
    } catch (error) {
      return [];
    }
  }
  // 通过用户id查询用户名
  static async getNameById(tenantId, userId) {
    try {
      const res = await request.get('/sales-show-4.0/sys/user/queryById?tenantId=' + tenantId + '&&userId=' + userId,);
      return res.data.code === 200 && res.data.result ? res.data.result : [];
    } catch (error) {
      return [];
    }
  }
  //通过分享id查看文件详情-无需安全认证
  static async getDetailByShareIdNOCheck(data) {
    try {
      const res = await request.get('/sales-show-4.0/share/getDetailByShareIdNOCheck', {
        params: data
      });
      return res.data.code === 200 && res.data.result ? res.data.result : false;
    } catch (error) {
      return [];
    }
  }
  //通过分享id查看文件详情-指定访问码
  static async getDetailByShareIdNeedCode(data) {
    try {
      const res = await request.get('/sales-show-4.0/share/getDetailByShareIdNeedCode', {
        params: data
      });
      return res.data.code === 200 && res.data.result ? res.data.result : false;
    } catch (error) {
      return false;
    }
  }
  //通过分享id查看文件详情-需要登录系统
  static async getDetailByShareIdNeedLogin(data) {
    try {
      const res = await request.get('/sales-show-4.0/share/getDetailByShareIdNeedLogin', {
        params: data
      });
      return res.data.code === 200 && res.data.result ? res.data.result : false;
    } catch (error) {
      return false;
    }
  }
  //取消分享
  static async Cancelshare(data) {
    try {
      const res = await request.get('/sales-show-4.0/share/updateStatus', {
        params: data
      });
      return res.data.code === 200 && res.data.success ? res.data.success : false;
    } catch (error) {
      return false;
    }
  }

  // 根据租户id获取文件目录下的文件列表
  static async queryFileByFolderIdTenantId(data) {
    try {
      const res = await request.get('/sales-show-4.0/file/queryFileByFolderIdTenantId', {
        params: data
      });
      return res.data.code === 200 && res.data.result ? res.data.result : false;
    } catch (error) {
      return false;
    }
  }
  // 根据租户id获取分类下文件列表
  static async queryFileByCategoryTenantId(data) {
    try {
      const res = await request.get('/sales-show-4.0/file/queryFileByCategoryTenantId', {
        params: data
      });
      return res.data.code === 200 && res.data.result ? res.data.result : false;
    } catch (error) {
      return false;
    }
  }
}

export {
  Share
}