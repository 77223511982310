/*
 * @Author: Billy
 * @Date: 2020-09-16 08:55:25
 * @LastEditors: chileehong 239479@qq.com
 * @LastEditTime: 2023-11-15 12:01:16
 * @Description: 下载工具函数
 */

/**
 * @description 弹窗下载文件方法
 * @param {string} filePath 将要下载的文件的网址
 * @param {string} fileName 下载的文件保存本地时的文件名(含后缀)
 */
function downloadByAnchorTag(filePath, fileName = "") {
  let anchorTag = document.createElement("a");
  anchorTag.setAttribute("href", filePath);
  anchorTag.setAttribute("download", fileName);
  anchorTag.click();
  anchorTag.remove()
}

function downloadBlob(fileName, binData, type = "application/vnd.ms-excel") {
  const blob = new Blob([binData], { type });
  const url = window.URL.createObjectURL(blob);
  downloadByAnchorTag(url, fileName);
  window.URL.revokeObjectURL(url);
}
export default {
  downloadByAnchorTag,
  downloadBlob,
};
