import axios from "axios";
import store from '../store';

const baseAxios = axios.create({
  baseURL: process.env.VUE_APP_URL_SAAS,
});
baseAxios.interceptors.request.use(
  request => {
    request.headers.portSource = "web";
    request.headers['X-Access-Token'] = store.state.local.token ? store.state.local.token : "";
    request.headers['Tenant-Id'] = store.state.local.userInfo ? store.state.local.userInfo.tenantIdNow : "";
    return request;
  },
  error => {
    return Promise.reject(error);
  }
);

// 用户退出
async function logout() {
  try {
    const response = await baseAxios.post("/sys/platform-enterprise/logout");
    return response.data.code === 200 || response.data.code === 0;
  } catch (error) {
    return false;
  }
}

// 子系统-判断用户是否是管理员
async function getUserIsAdmin(username) {
  try {
    const response = await baseAxios.get("/sys/user/getUserIsAdmin", { params: { username } });
    return response.data.code === 200 || response.data.code === 0 ? response.data.result : false;
  } catch (error) {
    return false;
  }
}

// 子系统-查询机器人账号
async function getRobotList() {
  try {
    const response = await baseAxios.get("/sys/user/getRobotList");
    return response.data.code === 200 || response.data.code === 0 ? response.data.result : [];
  } catch (error) {
    return [];
  }
}

// 获取验证码
async function getPhoneCode(data) {
  try {
    const response = await baseAxios.post("/sys/user/sendNewSmsCode",data);
    return response.data.code === 200 || response.data.code === 0 || response.data.code === 500 ? response.data : false;
  } catch (error) {
    return false;
  }
}

export default { logout, getUserIsAdmin, getRobotList, getPhoneCode }