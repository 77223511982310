/*
 * @Author: ChileeHong
 * @Date: 2022-01-14 13:34:15
 * @LastEditTime: 2023-08-02 15:20:37
 * @LastEditors: Saving
 * @Description:
 * 订阅：
 * 在组件的 mounted() 中调用 var pubsubToken = subscribe()
 * 在组件的 beforeDestroy() 中调用 unsubscribe(pubsubToken)
 * 发布：PubSub.publish(topic, data)
 * 接收: Pubsub.subscribe(topic, callback)
 * callback=(topic,data)=>{}
 *
 */
import PubSub from "pubsub-js";

function publish(topic, data) {
  return PubSub.publish(topic, data);
}

function subscribe(topic, callback) {
  return PubSub.subscribe(topic, (_, data) => callback(data));
}
// unsubscribe this subscriber from this topic
function unsubscribe(token) {
  return PubSub.unsubscribe(token);
}
// unsubscribe mySubscriber from ALL topics
function unsubscribeSubscriber(callback) {
  return PubSub.unsubscribe(callback);
}
// all subscriptions are removed
function clearAllSubscriptions() {
  return PubSub.clearAllSubscriptions();
}
// subscriptions by token from all topics
function getSubscriptions(token) {
  return PubSub.getSubscriptions(token);
}
// count by token from all topics
function countSubscriptions(token) {
  return PubSub.countSubscriptions(token);
}
const pubsubs = {
  subscribe,
  publish,
  unsubscribe,
  unsubscribeSubscriber,
  clearAllSubscriptions,
  getSubscriptions,
  countSubscriptions,
};

export default pubsubs;
