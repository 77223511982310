<template>
  <div class="my-quote">
    <ViewType v-if="showtype == 1" :modelname="title" :view.sync="viewType" />
    <div class="file-breadcrumb">
      <span v-if="breadcrumbList.length" class="pointer" @click="jumpTo(null, -1)"
        >全部</span
      >
      <template v-for="(item, index) in breadcrumbList">
        <span :key="item.id">&nbsp;/&nbsp;</span>
        <span
          class="pointer"
          :key="'breadcrumb' + item.id"
          @click="jumpTo(item, index)"
          >{{ item.folderName }}</span
        >
      </template>
    </div>
    <div class="file-content-table" style="height: auto" v-if="viewType != 'grid'">
      <el-table
        class="cloud-table"
        ref="fileTable"
        header-cell-class-name="app-header-cell"
        row-key="id"
        :data="tableData"
        row-class-name="file-table-row"
        @selection-change="handleSelectionChange"
      >
        <el-table-column
          v-if="showtype == 2 && !breadcrumbList.length"
          type="selection"
          width="55"
        >
        </el-table-column>
        <el-table-column label="名称" prop="folderName" min-width="300px">
          <template slot-scope="{ row }">
            <div class="file-name__wrapper">
              <svg-icon
                class="file-icon pointer"
                :icon="row.icon"
                color="#ffdc53"
                width="48"
                height="48"
              ></svg-icon>
              <!-- <img class="file-icon" :src="require(`@/assets/images/file/file-${row.aliasType}.png`)" /> -->
              <div>
                <div
                  class="file-name"
                  :title="row.folderName ? row.folderName : row.fileName"
                >
                  {{ row.folderName ? row.folderName : row.fileName }}
                </div>
                <div class="file-information">
                  <span v-if="row.fileType == 1"
                    >{{ row.fileSize }}&nbsp;&nbsp;|&nbsp;&nbsp;</span
                  >
                  <span
                    >{{ row.createBy ? row.createBy : "" }}&nbsp;于&nbsp;{{
                      Object.dayjs(row.createTime)
                    }}&nbsp;{{ row.fileType == 1 ? "上传" : "创建" }}</span
                  >
                </div>
                <div class="file-operation">
                  <div
                    v-if="row.fileType == 2"
                    class="file-operation-item"
                    @click.stop="handleOpenfolder(row)"
                  >
                    打开
                  </div>
                  <div
                    v-if="row.fileType == 1"
                    class="file-operation-item"
                    :class="{
                      disabled: row.aliasType === 'other' && row.contentType != 'video',
                    }"
                    @click.stop="handleView(row)"
                  >
                    预览
                  </div>
                  <div
                    v-if="row.fileType == 1"
                    class="file-operation-item"
                    @click.stop="handlesingleDownload(row)"
                  >
                    下载
                  </div>
                </div>
              </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="说明" min-width="190px">
          <template slot-scope="{ row }">
            <span>{{ row.describes ? row.describes : "--" }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="address" label="操作" v-if="showtype == 2">
          <template slot-scope="{ row }">
            <div class="product-card__info-toolbar flex">
              <div class="product-card__info-toolbar-left flex">
                <el-tooltip
                  v-if="showtype == 2"
                  effect="dark"
                  placement="bottom"
                  :enterable="false"
                  content="恢复"
                >
                  <div
                    class="product-card__info-toolbar-item"
                    @click="handleRecover(row)"
                  >
                    <svg-icon icon="arrow-back" color="#344563"></svg-icon>
                  </div>
                </el-tooltip>
                <el-tooltip
                  v-if="showtype == 2"
                  effect="dark"
                  placement="bottom"
                  :enterable="false"
                  content="删除"
                >
                  <div class="product-card__info-toolbar-item" @click="handleDelete(row)">
                    <svg-icon icon="delete16" color="#344563"></svg-icon>
                  </div>
                </el-tooltip>
              </div>
            </div>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div v-if="viewType === 'grid'">
      <div class="card-main">
        <div
          v-for="item in tableData"
          :key="item.id"
          class="card-item flex-column align-items-center flex-between card-item-file"
          :class="{ 'card-item-file-active': checkSelect(item) }"
        >
          <!-- <img class="file-icon pointer" @click="handleOpenfolder(item)"
            :src="require(`@/assets/images/file/file-${item.aliasType}.png`)" style="width: 48px; height: 48px" /> -->
          <svg-icon
            @click.native="handleOpenfolder(item)"
            class="file-type pointer"
            :icon="item.icon"
            color="#ffdc53"
            width="48"
            height="48"
          ></svg-icon>
          <div
            class="one-line item-name"
            :title="item.folderName ? item.folderName : item.fileName"
          >
            {{ item.folderName ? item.folderName : item.fileName }}
          </div>
          <div class="item-info two-lines">
            <span class="item-info-text"
              >{{ item.createBy ? item.createBy : "" }}&nbsp;{{
                item.fileType == 1 ? "上传" : "创建"
              }}于{{ Object.dayjs(item.createTime) }}</span
            >
            <span class="item-info-oprate">
              <span v-if="item.fileType == 2" @click="handleOpenfolder(item)">打开</span
              >&nbsp;&nbsp;
              <span v-if="item.fileType != 2" @click="handleView(item)">预览</span
              >&nbsp;&nbsp;<span
                v-if="item.fileType != 2"
                @click="handlesingleDownload(item)"
                >下载</span
              >
            </span>
          </div>
          <svg-icon
            class="icon-check"
            v-if="showtype == 2 && !breadcrumbList.length"
            icon="check-purple"
            :color="checkSelect(item) ? '#365EFF' : '#ccc'"
            @click.native="handleSelect(item)"
            width="22"
            height="18"
          ></svg-icon>
        </div>
      </div>
    </div>
    <FilePreview
      v-if="preview"
      :visible.sync="preview"
      :haslist="false"
      :currentitem.sync="currentItem"
    />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { ProductLibrary } from "@/api/ProductLibrary";
import { File } from "@/api/File";
import { filedata } from "../data";
import { DownloadFile, officeView, checkFileIcon } from "@/utils/file";
import ViewType from "./ViewType.vue";
import { Recycle } from "@/api/Recycle";
import FilePreview from "@/components/dialog/FilePreview.vue";
export default {
  name: "quote",
  props: {
    view: String, //当前视图模式grid/list
    showtype: {
      type: Number,
      default: 1, //1不需要选框，2有选择框
    },
    selectlist: Array,
    filelist: Array,
    title: {
      type: String,
      default: "文件",
    },
  },
  components: { ViewType, FilePreview },
  data() {
    return {
      tableData: [],
      preview: false,
      currentItem: null,
      viewType: "grid",
      breadcrumbList: [],
      queryFile: {
        dictItemIds: "", // 二级分类id
        folderId: "",
        name: "",
        pageNo: 1,
        pageSize: 9999,
      },
      fisrtList: [], //暂存初始的文件列表
      tableLoading: false,
      selectData: [],
    };
  },
  computed: {
    ...mapGetters(["userInfo"]),
  },
  watch: {
    view(val) {
      this.viewType = val;
      if (val != "grid") {
        this.$nextTick(() => {
          this.handleManualCheck();
        });
      }
    },
    filelist: {
      immediate: true,
      deep: true,
      handler(val) {
        this.filelist.forEach((element) => {
          if (element.fileType == 1) {
            if (/^[0-9]+$/.test(element.fileSize.toString())) {
              element.fileSize = this.common.formatFileSize(Number(element.fileSize));
            }
            element.aliasType = this.common.getFileType(
              element.contentType,
              element.fileSavePath
            );
            element.icon = checkFileIcon(element.fileSavePath);
          } else {
            element.aliasType = "folder";
            element.icon = "filetype-floder";
          }
        });
        this.tableData = _.cloneDeep(this.filelist);
        this.fisrtList = this.filelist;
        this.view && (this.viewType = this.view);
        this.selectlist && (this.selectData = _.cloneDeep(this.selectlist));
      },
    },
    selectlist(val) {
      this.selectData = val;
    },
  },
  created() {
    this.filelist.forEach((element) => {
      if (element.fileType == 1) {
        if (/^[0-9]+$/.test(element.fileSize.toString())) {
          element.fileSize = this.common.formatFileSize(Number(element.fileSize));
        }
        // element.fileSize = this.common.formatFileSize(element.fileSize);
        element.aliasType = this.common.getFileType(
          element.contentType,
          element.fileSavePath
        );
        element.icon = checkFileIcon(element.fileSavePath);
      } else {
        element.aliasType = "folder";
        element.icon = "filetype-floder";
      }
    });
    this.tableData = _.cloneDeep(this.filelist);
    this.fisrtList = this.filelist;
    this.view && (this.viewType = this.view);
    this.selectlist && (this.selectData = _.cloneDeep(this.selectlist));
  },
  mounted() {},
  methods: {
    /**
     * @description: 单个文件下载
     * @return {void}
     */
    handlesingleDownload(value) {
      DownloadFile(value, 1);
    },
    /**
     * @description: 预览
     * @param {Object} value 点击项
     * @return {void}
     */
    handleView(value) {
      // if (value.aliasType === "office") {
      //   officeView(value.id);
      // }
      // if (value.aliasType === "image") {
      //   this.$elImageViewer({ urlList: [value.fileSavePath], initialIndex: 0 });
      // }
      // if (value.aliasType === "video") {
      //   this.currentItem = value;
      //   this.preview = true;
      // } 
      if (value.aliasType === "image") {
        this.$elImageViewer({ urlList: [value.fileSavePath], initialIndex: 0 });
      }else{
        this.currentItem = value;
        this.preview = true;
      }
    },
    /**
     * @description: 获取文件目录下的文件列表
     * @return {void}
     */
    async getTableData(pageNo) {
      this.tableLoading = true;
      pageNo && (this.queryFile.pageNo = pageNo);
      this.currentItem = null;
      let payload = _.omit(this.queryFile, ["dictItemIds"]);
      const res = await File.queryFileByFolderId(payload);
      res.records.forEach((element) => {
        if (element.fileType == 1) {
          if (/^[0-9]+$/.test(element.fileSize.toString())) {
            element.fileSize = this.common.formatFileSize(Number(element.fileSize));
          }
          element.aliasType = this.common.getFileType(
            element.contentType,
            element.fileSavePath
          );
          element.icon = checkFileIcon(element.fileSavePath);
        } else {
          element.aliasType = "folder";
          element.icon = "filetype-floder";
        }
      });
      this.tableData = res.records;
      this.tableLoading = false;
    },
    /**
     * @description: 打开文件夹
     * @param {Object} value 文件夹
     * @return {void}
     */
    handleOpenfolder(value) {
      if (value.fileType != 2) {
        this.handleView(value);
        return;
      }
      const index = this.breadcrumbList.findIndex((item) => item.id === value.id);
      index === -1 && this.breadcrumbList.push(value);
      this.queryFile.folderId = value.id;
      this.queryFile.name = "";
      this.getTableData(1);
    },
    /**
     * @description: 点击面包屑获取文件列表
     * @param {Object} value 点击项
     * @param {Number} index 索引
     * @return {void}
     */
    jumpTo(value, index) {
      if (index === this.breadcrumbList.length - 1) {
        return;
      }
      if (value) {
        this.breadcrumbList.splice(index + 1);
        this.queryFile.folderId = value.id;
      } else {
        this.breadcrumbList = [];
        this.queryFile.folderId = "";
        this.tableData = this.fisrtList;
        return;
      }
      this.queryFile.name = "";
      this.getTableData(1);
    },
    /**
     * @description: 是否有选中
     * @param {Object} value 当前项
     * @return {void}
     */
    checkSelect(value) {
      const index = this.selectData.findIndex((item) => item.id === value.id);
      return index != "-1";
    },
    /**
     * @description:当选择项发生变化时会触发该事件
     * @param {Object} value 选择的所有项
     * @return {void}
     */
    handleSelectionChange(value) {
      this.selectData = value;
      let seledata = _.cloneDeep(value);
      seledata.forEach((item) => {
        item.id = item.deleteid;
      });
      this.$emit("select", seledata);
    },
    /**
     * @description: 选择
     * @param {Object} value 选择项
     * @return {void}
     */
    handleSelect(value) {
      const index = this.selectData.findIndex((item) => item.id === value.id);
      if (index !== -1) {
        this.selectData.splice(index, 1);
      } else {
        this.selectData.push(value);
      }
      this.$emit("select", this.selectData);
    },
    /**
     * @description:手动更新选择项
     * @return {void}
     */
    handleManualCheck() {
      let multipleSelection = [];
      this.tableData.forEach((item) => {
        if (this.selectData.findIndex((i) => i.id == item.id) != -1) {
          multipleSelection.push(item);
        }
      });
      if (multipleSelection) {
        multipleSelection.forEach((row) => {
          this.$refs.fileTable.toggleRowSelection(row);
        });
      } else {
        this.$refs.fileTable.clearSelection();
      }
    },
    /**
     * @description: 删除
     * @return {void}
     */
    handleDelete(value) {
      this.$showMessage(
        {
          message: `确定删除 <span style="font-weight: 1000;">${value.folderName}</span> 吗?<br>此次操作会将所选数据永久删除，请谨慎操作！`,
          ...this.$global.deleteMessage,
        },
        async (done) => {
          // let res = true;
          let payload = {
            idList: [value.deleteid],
            sourceType: 2,
          };
          const res = await Recycle.deleteComplete(payload);
          this.$emit("update");
          done(res);
        }
      );
    },
    /**
     * @description: 恢复产品
     * @return {void}
     */
    handleRecover(value) {
      this.$showMessage(
        {
          title: "提示",
          message: `确定恢复 <span style="font-weight: 1000;">${value.folderName}</span> 吗?`,
        },
        async (done) => {
          let payload = {
            idList: [value.deleteid],
            sourceType: 2,
          };
          const res = await Recycle.restore(payload);
          this.$emit("update");
          done(res);
        }
      );
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@/scss/demo.scss";

.my-quote {
  // height: 100%;
  width: 100%;
  padding: 16px 7px;
  // overflow-y: auto;
  box-sizing: border-box;

  .quote-item {
    height: 62px;
    width: 100%;
    margin-bottom: 16px;

    .item-cover {
      width: 107px;
      height: 62px;
      border-radius: 5px;
      margin-right: 15px;

      img {
        width: 100%;
        height: 100%;
        border-radius: 5px;
      }
    }

    .item-text {
      font-size: 12px;
      color: var(--color-text-primary);
      line-height: 18px;

      .text-title {
        width: 180px;
        height: 18px;
        margin-top: 14px;
      }

      .text-model {
        margin-top: 5px;
        color: #b2b7bf;
      }
    }
  }
}

.card-main {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  grid-gap: 10px;

  .card-item {
    height: 120px;
    padding-top: 10px;
    box-sizing: border-box;
    position: relative;

    // border: 1px solid #ccc;
    .item-info {
      width: 110px;
      font-size: 12px;
      color: #cfd7e6;
      text-align: center;

      &:hover {
        .item-info-text {
          display: none;
        }

        .item-info-oprate {
          display: block;
        }
      }

      .item-info-oprate {
        line-height: 30px;
        display: none;

        span {
          cursor: pointer;

          &:hover {
            color: var(--color-primary);
          }
        }
      }
    }

    .item-name {
      width: 120px;
      text-align: center;
    }

    .icon-check {
      display: none;
      position: absolute;
      top: 6px;
      right: 8px;
      width: 25px;
      height: 18px;
    }
  }

  .card-item-file {
    &:hover {
      border: 1px dashed #cccccc;
      border-radius: 5px;

      .icon-check {
        display: block;
      }
    }
  }

  .card-item-file-active {
    border: 1px dashed #cccccc;
    border-radius: 5px;

    .icon-check {
      display: block;
    }
  }
}

.file-breadcrumb {
  // height: 18px;
  line-height: 18px;
  margin-top: 16px;
  margin-left: 10px;
  color: #5e6c84;
  font-size: 12px;
  display: flex;
  align-items: center;

  span {
    &:hover {
      color: #6a87fc;
    }
  }
}

.product-card__info-toolbar {
  .product-card__info-toolbar-item {
    width: 30px;
    height: 30px;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    margin-top: 7px;
    box-sizing: border-box;

    &.disabled {
      cursor: default;

      .svg-icon {
        color: #c4cbd7 !important;
      }
    }

    &:not(.disabled):hover {
      background-color: #e4ecf7;
    }
  }
}
</style>
