import HistoryStep from "./HistoryStep";

export default class History {
    private static readonly MAX_HISTORY_STEPS_COUNT: number = 100;
    private historySteps: Array<HistoryStep> = [];
    private futureSteps: Array<HistoryStep> = [];

    /** 是否至少有一个历史步骤 */
    public get hasHistory(): boolean {
        return this.historySteps.length > 0;
    }

    /** 是否至少有一个重做步骤 */
    public get hasFuture(): boolean {
        return this.futureSteps.length > 0;
    }

    /**
     * 添加一个历史步骤
     * @param step 步骤对象
     * @returns 此历史步骤的唯一id
     */
    public add(step: HistoryStep): string {
        this.futureSteps = [];
        this.historySteps.push(step);

        if (this.historySteps.length > History.MAX_HISTORY_STEPS_COUNT) {
            this.historySteps.shift();
        }

        return step.id;
    }

    /** 清除历史列表和重做列表 */
    public clear() {
        this.historySteps = [];
        this.futureSteps = [];
    }

    /**
     * 回滚历史
     */
    public undo() {
        if (this.historySteps.length > 0) {
            const step = this.historySteps[this.historySteps.length - 1];
            const result = step.onUndo();
            if (result !== false) {
                this.futureSteps.push(step);
                this.historySteps.pop();
            }
        }
    }

    /**
     * 去往未来
     */
    public redo() {
        if (this.futureSteps.length > 0) {
            const step = this.futureSteps[this.futureSteps.length - 1];
            const result = step.onRedo();
            if (result !== false) {
                this.historySteps.push(step);
                this.futureSteps.pop();
            }
        }
    }
}