<template>
  <div class="icon-text-circle" :class="{ active }">
    <span class="text">{{ text }}</span>
  </div>
</template>
<script>
import Vue from "vue";
export default {
  inheritAttrs: false,
  name: "IconTextCircle",
  props: {
    text: { type: String },
    active: { type: Boolean, default: false },
  },
};
</script>
<style lang="scss" scoped>
@import "src/flexible-table-module/scss/_variables.scss";

.icon-text-circle {
  font-size: $fs-icon-text-circle;

  $color: #00bfa5;
  $size: 24px;

  box-sizing: border-box;
  height: $size;
  min-width: $size;
  border-radius: calc($size / 2);
  background-color: #f0f0f0;
  border: 2px solid $color;

  display: flex;
  justify-content: center;
  align-items: center;

  color: $color;

  cursor: pointer;

  // 如何有多个本组件，从第二个开始，margin-left 4px
  & + & {
    margin-left: 4px;
  }

  &.active {
    background-color: $color;
    color: white;
  }

  .text {
    padding: 0 4px;
    font-size: inherit;
    font-weight: bold;
    // 不换行
    white-space: nowrap;
    user-select: none;
  }
}
</style>