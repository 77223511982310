import "../scss/DomResizingHelper.scss";

export type DragArea =
    'left' | 'right' | 'top' | 'bottom' |
    'top-left' | 'top-right' | 'bottom-left' | 'bottom-right';

const DEFAULT_MIN_SIZE = 32;
const DEFAULT_MAX_SIZE = Infinity;
const DEFAULT_DRAG_AREA_SIZE = 16;

const RESIZING_CLASS = 'DomResizingHelper_resizing';
const DOC_RESIZING_CLASS = 'DOC_DomResizingHelper_resizing';

// 通过鼠标在 dom 的四个边框上拖动，改变 dom 的大小
export class DomResizingHelper {
    private readonly dom: HTMLElement;
    private _disabled: boolean = false;
    private _isResizing: boolean = false;

    private minWidth: number;
    private minHeight: number;
    private maxWidth: number;
    private maxHeight: number;

    private dragAreas: DragArea[];
    private dragAreaSize: number;

    private currDragArea: DragArea | null = null;

    constructor(
        dom: HTMLElement,
        options?: {
            disabled?: boolean,
            minWidth?: number,
            minHeight?: number,
            maxWidth?: number,
            maxHeight?: number,
            dragAreas?: DragArea[],
            dragAreaSize?: number,
        }) {

        this.dom = dom;

        const {
            disabled = false,
            minWidth = DEFAULT_MIN_SIZE,
            minHeight = DEFAULT_MIN_SIZE,
            maxWidth = DEFAULT_MAX_SIZE,
            maxHeight = DEFAULT_MAX_SIZE,
            dragAreas = [
                'left', 'right', 'top', 'bottom',
                'top-left', 'top-right', 'bottom-left', 'bottom-right'
            ],
            dragAreaSize = DEFAULT_DRAG_AREA_SIZE,
        } = options || {};

        this._disabled = disabled;
        this.minWidth = minWidth;
        this.minHeight = minHeight;
        this.maxWidth = maxWidth;
        this.maxHeight = maxHeight;
        this.dragAreas = dragAreas;
        this.dragAreaSize = dragAreaSize;

        this.init();
    }

    public get disabled() {
        return this._disabled;
    }

    public set disabled(value: boolean) {
        this._disabled = value;
    }

    public get isResizing() {
        return this._isResizing;
    }

    private init() {
        this.dom.addEventListener('mousedown', this.onMouseDown);
        this.dom.addEventListener('mousemove', this.onMouseHover);
        this.dom.addEventListener('mouseout', this.onMouseOut);
    }

    private getDragArea = (e: MouseEvent): DragArea | null => {
        if (this.dragAreas.includes('top-left') && this.isTopLeft(e)) {
            return 'top-left';
        } else if (this.dragAreas.includes('top-right') && this.isTopRight(e)) {
            return 'top-right';
        } else if (this.dragAreas.includes('bottom-left') && this.isBottomLeft(e)) {
            return 'bottom-left';
        } else if (this.dragAreas.includes('bottom-right') && this.isBottomRight(e)) {
            return 'bottom-right';
        } else if (this.dragAreas.includes('left') && this.isLeft(e)) {
            return 'left';
        } else if (this.dragAreas.includes('right') && this.isRight(e)) {
            return 'right';
        } else if (this.dragAreas.includes('top') && this.isTop(e)) {
            return 'top';
        } else if (this.dragAreas.includes('bottom') && this.isBottom(e)) {
            return 'bottom';
        } else {
            return null;
        }
    }

    private onMouseHover = (e: MouseEvent) => {
        if (this.disabled) {
            return;
        }

        // // 如果 e.target 不是 dom，不处理
        // if (e.target !== this.dom) {
        //     return;
        // }

        const dragArea = this.getDragArea(e);

        if (dragArea) {
            this.dom.classList.add(RESIZING_CLASS + '_' + dragArea);
        } else {
            this.clearCursor();
        }
    }

    private onMouseOut = (e: MouseEvent) => {
        if (this.disabled) {
            return;
        }

        this.clearCursor();
    }

    private clearCursor = () => {
        this.dom.classList.remove(RESIZING_CLASS + '_left');
        this.dom.classList.remove(RESIZING_CLASS + '_right');
        this.dom.classList.remove(RESIZING_CLASS + '_top');
        this.dom.classList.remove(RESIZING_CLASS + '_bottom');
        this.dom.classList.remove(RESIZING_CLASS + '_top-left');
        this.dom.classList.remove(RESIZING_CLASS + '_top-right');
        this.dom.classList.remove(RESIZING_CLASS + '_bottom-left');
        this.dom.classList.remove(RESIZING_CLASS + '_bottom-right');
    }

    private onMouseDown = (e: MouseEvent) => {
        if (this.disabled) {
            return;
        }

        // // 如果 e.target 不是 dom，不处理
        // if (e.target !== this.dom) {
        //     return;
        // }

        const dragArea = this.getDragArea(e);

        if (dragArea) {
            e.preventDefault();
            this.currDragArea = dragArea;
        } else {
            this.currDragArea = null;
            return;
        }

        this._isResizing = true;

        const { dom } = this;
        const { clientX, clientY } = e;
        const { offsetWidth, offsetHeight, offsetLeft, offsetTop } = dom;

        const startX = clientX;
        const startY = clientY;
        let startWidth = offsetWidth;
        let startHeight = offsetHeight;

        // 识别 dom 的 box-sizing 属性
        const boxSizing = window.getComputedStyle(dom).boxSizing;

        // 如果 box-sizing 为 content-box，需要减去 border 和 padding 的宽度
        if (boxSizing === 'content-box') {
            // 获取 border 和 padding 的宽度
            const borderLeftWidth = parseFloat(window.getComputedStyle(dom).borderLeftWidth);
            const borderTopWidth = parseFloat(window.getComputedStyle(dom).borderTopWidth);
            const borderRightWidth = parseFloat(window.getComputedStyle(dom).borderRightWidth);
            const borderBottomWidth = parseFloat(window.getComputedStyle(dom).borderBottomWidth);

            const paddingLeft = parseFloat(window.getComputedStyle(dom).paddingLeft);
            const paddingTop = parseFloat(window.getComputedStyle(dom).paddingTop);
            const paddingRight = parseFloat(window.getComputedStyle(dom).paddingRight);
            const paddingBottom = parseFloat(window.getComputedStyle(dom).paddingBottom);

            startWidth -= borderLeftWidth + borderRightWidth + paddingLeft + paddingRight;
            startHeight -= borderTopWidth + borderBottomWidth + paddingTop + paddingBottom;
        }

        const onDocumentMouseMove = (e: MouseEvent) => {
            e.preventDefault()

            const { clientX, clientY } = e;
            const offsetX = clientX - startX;
            const offsetY = clientY - startY;

            const mvLeft = () => {
                const newWidth = startWidth - offsetX;
                if (newWidth >= this.minWidth && newWidth <= this.maxWidth) {
                    dom.style.width = newWidth + 'px';
                    dom.style.left = offsetLeft + offsetX + 'px';
                }
            }

            const mvRight = () => {
                const newWidth = startWidth + offsetX;
                if (newWidth >= this.minWidth && newWidth <= this.maxWidth) {
                    dom.style.width = newWidth + 'px';
                }
            }

            const mvTop = () => {
                const newHeight = startHeight - offsetY;
                if (newHeight >= this.minHeight && newHeight <= this.maxHeight) {
                    dom.style.height = newHeight + 'px';
                    dom.style.top = offsetTop + offsetY + 'px';
                }
            }

            const mvBottom = () => {
                const newHeight = startHeight + offsetY;
                if (newHeight >= this.minHeight && newHeight <= this.maxHeight) {
                    dom.style.height = newHeight + 'px';
                }
            }

            switch (this.currDragArea) {
                case 'left': {
                    mvLeft();
                    break;
                }
                case 'right': {
                    mvRight();
                    break;
                }
                case 'top': {
                    mvTop();
                    break;
                }
                case 'bottom': {
                    mvBottom();
                    break;
                }
                case 'top-left': {
                    mvTop();
                    mvLeft();
                    break;
                }
                case 'top-right': {
                    mvTop();
                    mvRight();
                    break;
                }
                case 'bottom-left': {
                    mvBottom();
                    mvLeft();
                    break;
                }
                case 'bottom-right': {
                    mvBottom();
                    mvRight();
                    break;
                }
                default:
                    break;
            }
        }

        const onDocumentMouseUp = (e: MouseEvent) => {
            document.removeEventListener('mousemove', onDocumentMouseMove);
            document.removeEventListener('mouseup', onDocumentMouseUp);

            // 还原全局的鼠标的 cursor 样式
            document.body.classList.remove(DOC_RESIZING_CLASS + '_' + dragArea);
            // 还原全局的文字选中设置
            document.body.classList.remove(DOC_RESIZING_CLASS);

            this.currDragArea = null;
            this._isResizing = false;
        }

        // 先移除事件监听
        document.removeEventListener('mousemove', onDocumentMouseMove);
        document.removeEventListener('mouseup', onDocumentMouseUp);

        // 再添加事件监听
        document.addEventListener('mousemove', onDocumentMouseMove);
        document.addEventListener('mouseup', onDocumentMouseUp);

        // 全局控制鼠标的 cursor 样式
        document.body.classList.add(DOC_RESIZING_CLASS + '_' + dragArea);

        // 使全局的文字不可选中
        document.body.classList.add(DOC_RESIZING_CLASS);
    }

    // #region 判断鼠标是否在 dom 的四个边框上

    private isLeft(e: MouseEvent) {
        const { offsetX } = this.getRelativeOffsets(e);
        return offsetX < this.dragAreaSize;
    }

    private isRight(e: MouseEvent) {
        const { offsetX } = this.getRelativeOffsets(e);
        return offsetX > this.dom.offsetWidth - this.dragAreaSize;
    }

    private isTop(e: MouseEvent) {
        const { offsetY } = this.getRelativeOffsets(e);
        return offsetY < this.dragAreaSize;
    }

    private isBottom(e: MouseEvent) {
        const { offsetY } = this.getRelativeOffsets(e);
        return offsetY > this.dom.offsetHeight - this.dragAreaSize;
    }

    // #endregion

    // #region 判断鼠标是否在 dom 的四个角上

    private isTopLeft(e: MouseEvent) {
        return this.isTop(e) && this.isLeft(e);
    }

    private isTopRight(e: MouseEvent) {
        return this.isTop(e) && this.isRight(e);
    }

    private isBottomLeft(e: MouseEvent) {
        return this.isBottom(e) && this.isLeft(e);
    }

    private isBottomRight(e: MouseEvent) {
        return this.isBottom(e) && this.isRight(e);
    }

    // #endregion

    private getRelativeOffsets(e: MouseEvent): { offsetX: number, offsetY: number } {
        const domRect = this.dom.getBoundingClientRect();
        return {
            offsetX: e.pageX - domRect.left,
            offsetY: e.pageY - domRect.top
        };
    }

    public destroy() {
        this.dom.removeEventListener('mousedown', this.onMouseDown);
        this.dom.removeEventListener('mousemove', this.onMouseHover);
        this.dom.removeEventListener('mouseout', this.onMouseOut);
    }
}