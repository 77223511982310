<template>
  <div class="string-search">
    <ElInput
      v-model="dKeyword"
      :clearable="true"
      placeholder="请输入搜索关键词"
      @keypress.enter.native="onKeyEnterPress"
    ></ElInput>
    <SubmitAndCancel @submit="onSubmit" @cancel="onCancel" />
  </div>
</template>
<script lang="ts">
import Vue from "vue";
import { Input as ElInput } from "element-ui";

import SubmitAndCancel from "../../Common/SubmitAndCancel.vue";
export default Vue.extend({
  inheritAttrs: false,
  name: "StringSearch",
  components: { ElInput, SubmitAndCancel },
  props: {
    keyword: {
      type: String,
    },
  },
  data() {
    return {
      dKeyword: this.keyword,
    };
  },
  watch: {
    keyword: {
      immediate: false,
      handler(newVal, oldVal) {
        this.dKeyword = newVal;
      },
    },
  },
  methods: {
    onSubmit() {
      this.$emit("submit", this.dKeyword);
    },
    onCancel() {
      this.dKeyword = this.keyword;
      this.$emit("cancel");
    },
    onKeyEnterPress() {
      this.$emit("submit", this.dKeyword);
    },
  },
});
</script>