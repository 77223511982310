<template>
  <div class="middle-bar" :style="rootStyle">
    <div class="left" :style="leftStyle">
      <IconBtn
        v-if="hasShrinkBtn"
        :isActive="isShrink"
        :iconSize="iconSize"
        :padding="iconPadding"
        @btn-click="onBtnShrinkClick"
      >
        <template v-slot:active>
          <svg-icon
            sys="common-table"
            name="MiddleBar/shrink-right"
            :size="iconSize"
          />
        </template>
        <template v-slot:inactive>
          <svg-icon
            sys="common-table"
            name="MiddleBar/shrink-left"
            :size="iconSize"
          />
        </template>
      </IconBtn>
    </div>
    <div class="center">
      <div class="center-inner">
        <slot name="center"></slot>
      </div>
    </div>
    <div class="right">
      <slot name="right"></slot>
    </div>
  </div>
</template>
<script lang="ts">
import Vue, { PropType } from "vue";
import SvgIcon from "@/flexible-table-module/svg-icon-importer";
import IconBtn from "@/flexible-table-module/components/Common/IconBtn.vue";
import { Filter } from "@/flexible-table-module/entity/Filter";

export default Vue.extend({
  inheritAttrs: false,
  name: "MiddleBar",
  components: {
    SvgIcon,
    IconBtn,
  },
  props: {
    hasShrinkBtn: { type: Boolean, default: false }, // 是否有收缩按钮
    // hasSideFilter: { type: Boolean, default: true }, // 是否有侧边筛选栏
    isShrink: { type: Boolean, required: true }, // 是否收缩侧边筛选栏
    height: { type: Number, default: 36 }, // 本组件的高度
    leftWidth: { type: Number, default: 200 }, // 左侧侧边筛选栏宽度
    filters: { type: Array as PropType<Filter[]> }, // 过滤器列表
  },
  data() {
    return {
      iconSize: 16,
      iconPadding: 4,
      sidePadding: 8,
    };
  },
  computed: {
    rootStyle(): { height: string; padding: string } {
      return { height: `${this.height}px`, padding: `0 ${this.sidePadding}px` };
    },
    leftStyle(): { width?: string } {
      if (this.hasShrinkBtn && this.isShrink) return {};
      else return { width: `${this.leftWidth}px` };
    },
  },
  methods: {
    onBtnShrinkClick() {
      this.$emit("btn-shrink-click");
    },
  },
});
</script>
<style lang="scss" scoped>
@import "src/flexible-table-module/scss/_variables.scss";

.middle-bar {
  box-sizing: border-box;
  width: 100%;

  display: flex;
  flex-wrap: nowrap;
  // justify-content: space-between;
  // align-items: center;

  overflow: hidden;

  .left {
    min-width: 32px;
    height: 100%;
    flex-shrink: 0;
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
  .center {
    width: 100%;
    // max-width: 66.67%;
    height: 100%;

    .center-inner {
      height: 100%;
      width: 80%;
    }
  }
  .right {
    min-width: 32px;
    height: 100%;
    flex-shrink: 0;
    display: flex;
    justify-content: center;
    align-items: center;

    margin-left: auto;
  }
}
</style>