<template>
  <div
    class="inline-form-cell"
    :class="{ unmodifiable: isCellValueUnmodifiable }"
    :style="getCellInsideRowStyle(column)"
  >
    <template v-if="!isCellValueUnmodifiable">
      <slot v-bind="{ col: column, row: formRow }">
        <!-- #region 以插槽后备内容的形式提供默认编辑器 -->
        <template v-if="isStringType">
          <FormCell4String
            v-model="formRow[column.id]"
            :required="isCellValueRequired"
            @input="onFormCellInput"
          />
        </template>
        <template v-else-if="isNumberColumn">
          <FormCell4Number
            v-model="formRow[column.id]"
            :required="isCellValueRequired"
            @input="onFormCellInput"
          />
        </template>
        <template v-else-if="isKeyStrValue">
          <FormCell4Selection
            v-model="formRow[column.id]"
            :required="isCellValueRequired"
            :column="column"
            :getDropdownData="getCellDropdownData"
            @input="onFormCellInput"
          />
        </template>
        <template v-else-if="isDateTimeSingle">
          <FormCell4DateTimeSingle
            v-model="formRow[column.id]"
            :required="isCellValueRequired"
            :columnType="column.type"
            @input="onFormCellInput"
          />
        </template>
        <template v-else-if="isDateTimeFrame">
          <FormCell4DateTimeFrame
            v-model="formRow[column.id]"
            :required="isCellValueRequired"
            :columnType="column.type"
            @input="onFormCellInput"
          />
        </template>
        <template v-else-if="isPercentColumn">
          <FormCell4PercentColumn
            v-model="formRow[column.id]"
            :required="isCellValueRequired"
            @input="onFormCellInput"
          />
        </template>
        <!-- #endregion -->
      </slot>
    </template>
  </div>
</template>
<script lang="ts">
import Vue, { PropType } from "vue";
import { debounce } from "@/flexible-table-module/util/InputOptimize";

import FormCell4String from "./InlineFormCell/FormCell4String.vue";
import FormCell4Number from "./InlineFormCell/FormCell4Number.vue";
import FormCell4Selection from "./InlineFormCell/FormCell4Selection.vue";
import FormCell4DateTimeSingle from "./InlineFormCell/FormCell4DateTimeSingle.vue";
import FormCell4DateTimeFrame from "./InlineFormCell/FormCell4DateTimeFrame.vue";
import FormCell4PercentColumn from "./InlineFormCell/FormCell4PercentColumn.vue";

import { type FormRow } from "@/flexible-table-module/entity/Table/FormRow";
import { Column } from "@/flexible-table-module/entity/Table/Column";
import { PercentColumn } from "@/flexible-table-module/entity/Table/PercentColumn";
import { TFormColumn } from "@/flexible-table-module/entity/Table/TFormColumn";
import { type IBaseTableData } from "@/flexible-table-module/entity/Table/IBaseTableData";
import { type KeyStrValuePair } from "@/flexible-table-module/entity/KeyValuePair";
import { Button } from "element-ui";
import { ButtonColumn } from "@/flexible-table-module/entity/Table/ButtonColumn";
import { IndexColumn } from "@/flexible-table-module/entity/Table/IndexColumn";
// import { cellValueFormatter } from "@/flexible-table-module/util/CellHelper";

export default Vue.extend({
  inheritAttrs: false,
  name: "InlineFormCell",
  components: {
    FormCell4String,
    FormCell4Number,
    FormCell4Selection,
    FormCell4DateTimeSingle,
    FormCell4DateTimeFrame,
    FormCell4PercentColumn,
  },
  props: {
    formRow: { type: Object as PropType<FormRow>, required: true },
    column: {
      type: Object as PropType<TFormColumn>,
      required: true,
    },
    getCellDropdownData: {
      type: Function as PropType<
        (
          row: IBaseTableData | null | undefined,
          column: Column,
          resolve: (data: KeyStrValuePair[]) => void
        ) => void
      >,
    },
  },
  data(): {
    onFormCellInput: (value: any) => void;
  } {
    return {
      onFormCellInput: () => {},
    };
  },
  computed: {
    // 是否必填
    isCellValueRequired(): boolean {
      return this.column instanceof Column && this.column.required;
    },

    // 是否不可编辑
    isCellValueUnmodifiable(): boolean {
      return (
        (this.column instanceof Column && this.column.unmodifiable) ||
        this.column instanceof ButtonColumn ||
        this.column instanceof IndexColumn
      );
    },

    // selectionCurrValue(): string | undefined {
    //   const value = this.formRow[this.column.id];
    //   if (this.column instanceof Column) {
    //     switch (this.column.type) {
    //       case "string": {
    //         return typeof value === "string" ? String(value) : "";
    //       }
    //       case "key-str-value": {
    //         if (value === null || value === undefined) return "";
    //         else {
    //           const keyStrValue = value as KeyStrValuePair;
    //           return typeof keyStrValue.value === "string"
    //             ? String(keyStrValue.value)
    //             : "";
    //         }
    //       }
    //       default:
    //         throw new Error("column type is not supported");
    //     }
    //   } else throw new Error("column is not instance of Column");
    // },

    // ----------------------------------------------------------------

    isStringType(): boolean {
      return (
        this.column instanceof Column &&
        this.column.type === "string" &&
        !this.column.selectable
      );
    },
    isNumberColumn(): boolean {
      return this.column instanceof Column && this.column.type === "number";
    },
    isKeyStrValue(): boolean {
      if (this.column instanceof Column) {
        return (
          (this.column.type === "string" && this.column.selectable) ||
          this.column.type === "key-str-value"
        );
      } else return false;
    },
    isDateTimeSingle(): boolean {
      return (
        this.column instanceof Column &&
        (this.column.type === "time" ||
          this.column.type === "date" ||
          this.column.type === "datetime")
      );
    },
    isDateTimeFrame(): boolean {
      return (
        this.column instanceof Column &&
        (this.column.type === "time-frame" ||
          this.column.type === "date-frame" ||
          this.column.type === "datetime-frame")
      );
    },
    isPercentColumn(): boolean {
      return this.column instanceof PercentColumn;
    },
  },
  mounted() {
    this.onFormCellInput = debounce((value: any) => {
      this.$emit("form-cell-input", this.formRow, this.column, value);
    }, 800);
  },
  methods: {
    getCellInsideRowStyle(col: Column): { justifyContent: string } {
      let styleJustifyContent;

      styleJustifyContent = this.getCellInsideAlign(col);

      const style = { justifyContent: styleJustifyContent };

      return style;
    },

    getCellInsideAlign(col: Column): "center" | "flex-start" | "flex-end" {
      switch (col.cellAlign) {
        case "center":
          return "center";
        case "left":
          return "flex-start";
        case "right":
          return "flex-end";
        default:
          return "center";
      }
    },
  },
});
</script>
<style lang="scss" scoped>
@import "src/flexible-table-module/scss/_variables.scss";

.inline-form-cell {
  // background-color: $background-base;

  width: 100%;
  display: flex;
  align-items: center;
}
</style>