<template>
  <el-dropdown @command="handleCommand">
    <span class="top-left-dropdown">
      {{ editType }}
      <i class="el-icon-caret-bottom"></i>
    </span>
    <el-dropdown-menu slot="dropdown" class="more-dropdown">
      <el-dropdown-item command="column">
        <div class="edit-dropdown-item">展示</div>
      </el-dropdown-item>
      <el-dropdown-item command="demo">
        <div class="edit-dropdown-item">演示</div>
      </el-dropdown-item>
      <el-dropdown-item command="file" v-if="!ispreview">
        <div class="edit-dropdown-item">产品文件</div>
      </el-dropdown-item>
    </el-dropdown-menu>
  </el-dropdown>
</template>

<script>
export default {
  props: {
    type: String,
    ispreview: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    editType: {
      get() {
        if (this.type == "column") {
          return "展示";
        } else if (this.type == "demo") {
          return "演示";
        } else {
          return "产品文件";
        }
      },
      set(val) {
        this.$emit("update:name", val);
      },
    },
  },
  data() {
    return {};
  },
  methods: {
    /**
     * @description: 切换演示/栏目编辑
     * @return {void}
     */
    handleCommand(value) {
      if (value == "column") {
        this.$router.push({
          path: this.ispreview ? "/column-preview" : "/product-edit",
          query: this.$route.query,
        });
      } else if (value == "file") {
        this.$router.push({
          path: "/product-file",
          query: this.$route.query,
        });
      } else if (value == "demo") {
        this.$router.push({
          path: this.ispreview ? "/product-preview" : "/demo-mode",
          query: this.$route.query,
        });
      }
    },
  },
};
</script>

<style scoped lang="scss">
.top-left-dropdown {
  padding: 0 19px;
  color: #fff;
  font-size: 14px;

  i {
    font-size: 12px;
  }
}
.edit-dropdown-item {
  text-align: center;
}
</style>
