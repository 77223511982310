import BaseAxios from "./_BaseAxios";
// @ts-ignore
import ResHelper from "./_ResponseHelper.js";

export class ApiDataOverview {
    private PRIFIX: string;

    constructor(private baseURL?: string) {
        if (baseURL) this.PRIFIX = '/overview';
        else this.PRIFIX = '/public/table/dataOverview';
    }

    public getOverviewDetails(
        moduleName: string,
        tabName: string,
    ) {
        return BaseAxios({
            baseURL: this.baseURL,
            url: `${this.PRIFIX}/getOverviewDetails`,
            method: "post",
            params: { moduleName, tabName },
        }).then(ResHelper.handler);
    }

    public updateOverviewSettings(
        moduleName: string,
        tabName: string,
        reqBody: any,
    ) {
        return BaseAxios({
            baseURL: this.baseURL,
            url: `${this.PRIFIX}/updateOverviewSettings`,
            method: "post",
            params: { moduleName, tabName },
            data: reqBody,
        }).then(ResHelper.handler);
    }
}
