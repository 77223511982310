<template>
  <div class="data-statistics-view">
    <template v-if="dataStatistics && dataStatistics.length > 0">
      <template v-for="(statistic, index) in activeDataStatistics">
        <span :key="index">
          <span :key="index + 'comma'" v-if="index > 0">，</span>
          <span :key="index + 'title'">{{ statistic.title }}</span>
          <span :key="index + 'colon'">：</span>
          <span :key="index + 'value'">{{ statistic.value }}</span>
        </span>
      </template>
    </template>
    <!-- <template v-else>
      <span>暂无统计数据</span>
    </template> -->
  </div>
</template>
<script lang="ts">
import { DataStatistic } from "@/flexible-table-module/entity/Table/DataStatistic";
import Vue, { PropType } from "vue";
export default Vue.extend({
  inheritAttrs: false,
  name: "DataStatisticsView",
  props: {
    dataStatistics: { type: Array as PropType<DataStatistic[]> },
  },
  computed: {
    activeDataStatistics() {
      if (this.dataStatistics) {
        return this.dataStatistics.filter((st: DataStatistic) => st.active);
      } else return [];
    },
  },
});
</script>
<style lang="scss" scoped>
.data-statistics-view {
  line-height: 32px;
  padding-right: 16px;

  // 以下用于使得未加载到数据时，依然有宽度和高度，以便于 Loading 组件的定位
  box-sizing: border-box;
  width: 100%;
  height: 100%;

  span {
    display: inline-block;
    line-height: 32px;
    vertical-align: middle;
  }
}
</style>