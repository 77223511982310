// 默认的布局"type": null,
const layouts = [
  [],
  [
    {
      "x": 15,
      "y": 0,
      "w": 15,
      "h": 26,
      "i": "0",
      "type": null,
      "typeId": "",
      "moved": false
    },
    {
      "x": 0,
      "y": 0,
      "w": 15,
      "h": 26,
      "i": "1",
      "type": null,
      "typeId": "",
      "moved": false
    }
  ],
  [
    {
      "x": 0,
      "y": 0,
      "w": 30,
      "h": 11,
      "i": "0",
      "type": null,
      "typeId": "",
      "moved": false
    },
    {
      "x": 0,
      "y": 11,
      "w": 30,
      "h": 17,
      "i": "1",
      "type": null,
      "typeId": "",
      "moved": false
    }
  ],
  [
    {
      "x": 0,
      "y": 0,
      "w": 15,
      "h": 11,
      "i": "0",
      "type": null,
      "typeId": "",
      "moved": false
    },
    {
      "x": 15,
      "y": 0,
      "w": 15,
      "h": 11,
      "i": "1",
      "type": null,
      "typeId": "",
      "moved": false
    },
    {
      "x": 0,
      "y": 11,
      "w": 15,
      "h": 10,
      "i": "2",
      "type": null,
      "typeId": "",
      "moved": false
    },
    {
      "x": 15,
      "y": 11,
      "w": 15,
      "h": 10,
      "i": "3",
      "type": null,
      "typeId": "",
      "moved": false
    }
  ]
]
export default layouts
