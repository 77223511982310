import Vue from 'vue';
import { Image } from 'element-ui';

function showImageViewer(props) {
  const div = document.createElement("div");
  document.body.appendChild(div);
  const vm = new Vue({
    render: h => h(Image.components.ImageViewer, {
      props: {
        urlList: props.urlList,
        initialIndex: props.initialIndex,
        onClose() {
          vm.$destroy();
          div.remove();
        }
      },
    }),
  }).$mount(div);
}

export default showImageViewer;