<template>
  <div v-loading="!data" class="data-overview-board" :style="rootStyle">
    <div
      v-if="!isFolded"
      class="btn-fold-area"
      :style="{
        width: `${btnFoldSize}px`,
        height: `${btnFoldSize}px`,
        top: `${containerPadding}px`,
        right: `${containerPadding}px`,
      }"
    >
      <div class="btn" @click="onBtnFoldClick">
        <svg-icon
          class="svg-icon"
          sys="common-table"
          name="DataOverview/fold"
          :size="btnFoldSize"
        />
      </div>
    </div>

    <div
      v-if="isFolded"
      class="btn-unfold-area"
      :style="{
        width: `${btnFoldSize}px`,
        height: `${btnFoldSize}px`,
        top: `${containerPadding}px`,
        right: `${containerPadding}px`,
      }"
    >
      <div class="btn" type="text" @click="onBtnFoldClick">
        <svg-icon
          class="svg-icon"
          sys="common-table"
          name="DataOverview/unfold"
          :size="btnFoldSize"
        />
      </div>
    </div>

    <div class="inner" v-show="!isFolded">
      <div class="header">
        <div class="title">
          <span>数据概览</span>
        </div>
        <div class="setting">
          <!-- <el-button type="text" @click="onBtnOrgClick"
            >指定部门/人员</el-button
          > -->
          <!-- <UserAndOrganizationSelector
            class="user-organization-selector"
            addType="text"
            searcherType=""
            addButtonText="指定部门/人员"
            v-model="userAndOrgs"
            :showInput="false"
            :showOrgCheckBox="true"
            :userAndOrgsList="userAndOrgsList"
            @get-confirmed="getUserList"
          /> -->
          <DateFramePicker
            v-model="cTimeFrame"
            @date-frame-change="onDateFrameChange"
          />
          <ElButton type="text" @click="onBtnSettingClick">配置</ElButton>
        </div>
      </div>
      <div class="body">
        <template v-for="activeItem in activeItems">
          <OverviewUnit
            :key="activeItem.id"
            :title="activeItem.name"
            :number="activeItem.value"
            :color="activeItem.color"
            @unit-click="onUnitClick(activeItem)"
          />
        </template>
      </div>
    </div>

    <DialogSetting
      :visible.sync="dialogSettingVisible"
      :data="dialSettingData"
      @dialog-setting-show="onDialogSettingShow"
      @default-fold-change="onDefaultFoldChange"
      @item-active-change="onItemActiveChange"
      @setting-change="onSettingChange"
    />

    <DialogDetail
      :visible.sync="dialogDetailVisible"
      :dataOverviewItem="clickedItem"
      :suffix="dialDetailSuffix"
    >
      <slot></slot>
    </DialogDetail>
  </div>
</template>
<script lang="ts">
import Vue, { type DirectiveOptions, PropType } from "vue";
import { Button as ElButton, Loading as ElLoading } from "element-ui";
// import SvgIcon from "@/flexible-table-module/svg-icon-importer";
import SvgIcon from "@/flexible-table-module/svg-icon-importer";

import { Loading } from "@/flexible-table-module/components/Common/Loading";

import DateFramePicker from "./DataOverviewBoard/DateFramePicker.vue";
import OverviewUnit from "./DataOverviewBoard/OverviewUnit.vue";
import DialogSetting from "./DataOverviewBoard/DialogSetting.vue";
import DialogDetail from "./DataOverviewBoard/DialogDetail.vue";
// import UserAndOrganizationSelector from "@/components/TaskCenter/TaskList/Dialog/Widgets/UserAndOrganizationSelector.vue";

import { DataOverview } from "@/flexible-table-module/entity/DataOverview";
import { DataOverviewItem } from "@/flexible-table-module/entity/DataOverview/DataOverviewItem";

type DialSettingDataType = {
  defaultFold: boolean;
  allItems: DataOverviewItem[];
};

export default Vue.extend({
  inheritAttrs: false,
  name: "DataOverviewBoard",
  components: {
    ElButton,
    SvgIcon,
    DateFramePicker,
    OverviewUnit,
    DialogSetting,
    DialogDetail,
    // UserAndOrganizationSelector,
  },
  directives: { loading: Loading.directive },
  props: {
    height: { type: Number, default: 150 },
    data: { type: Object as PropType<DataOverview> },
    timeFrame: { type: Array, default: undefined },
    dialDetailSuffix: { type: String, default: "" },
  },
  data(): {
    isFolded: boolean; // 当前数据概览是否处于折叠
    btnFoldSize: number; // 按钮大小
    containerPadding: number;
    dialogSettingVisible: boolean; // 配置的弹窗是否可见

    activeItems: DataOverviewItem[];

    // timeFrame?: [Date, Date]; // 日期段

    userAndOrgs: any[]; // 组织架构和人员
    userAndOrgsList: any[]; // 组织架构和人员

    dialogDetailVisible: boolean;
    clickedItem?: DataOverviewItem;
  } {
    return {
      isFolded: false,
      btnFoldSize: 20,
      containerPadding: 8,
      dialogSettingVisible: false,
      activeItems: [],
      // timeFrame: undefined,
      userAndOrgs: [],
      userAndOrgsList: [],
      dialogDetailVisible: false,
      clickedItem: undefined,
    };
  },
  beforeMount() {},
  watch: {
    data: {
      immediate: true,
      handler(newVal: DataOverview, oldVal: DataOverview) {
        if (this.data) {
          this.isFolded = this.data.defaultFold;
          // 浅度复制
          this.activeItems = [...this.data.activeItems];
        }
      },
    },
  },
  computed: {
    dialSettingData: {
      get(): DialSettingDataType | undefined {
        if (this.data && this.data.allItems) {
          return {
            defaultFold: this.data.defaultFold,
            allItems: this.data.allItems,
          };
        } else {
          return undefined;
        }
      },
    },

    cTimeFrame: {
      get(): [Date, Date] | undefined {
        return this.timeFrame as [Date, Date] | undefined;
      },
      set(val?: [Date, Date]) {
        this.$emit("update:timeFrame", val);
      },
    },

    rootStyle(): {
      height: string;
      padding?: string;
      overflow: string;
    } {
      if (this.isFolded) {
        return {
          height: "32px",
          overflow: "visible",
        };
      } else {
        return {
          height: `${this.height}px`,
          padding: `${this.containerPadding}px`,
          overflow: "hidden",
        };
      }
    },
  },
  methods: {
    // 折叠/解除折叠按钮被点击
    onBtnFoldClick() {
      this.isFolded = !this.isFolded;
    },

    // 当指定部门/人员按钮被点击
    // onBtnOrgClick() {},

    getUserList(val: any) {
      // console.log("val :>> ", val);
      this.userAndOrgsList = val.userList;
    },

    // 当配置弹窗按钮被点击
    onBtnSettingClick() {
      // this.freshTempData();

      this.dialogSettingVisible = true;
    },

    onDialogSettingShow() {
      this.$emit("dialog-setting-show");
    },

    onDefaultFoldChange(fold: boolean) {
      this.data.defaultFold = fold;
      this.isFolded = fold;
      this.$emit("default-fold-change", fold);
    },

    onItemActiveChange(items: DataOverviewItem[]) {
      this.$emit("item-active-change", items);
    },

    onSettingChange(data: {
      defaultFold: boolean;
      allItems: DataOverviewItem[];
    }) {
      const { defaultFold, allItems } = data;
      this.$emit("setting-change", { defaultFold, allItems });
    },

    onDateFrameChange(dateFrame?: [Date, Date]) {
      this.$emit("date-frame-change", dateFrame);
    },

    onUnitClick(activeItem: DataOverviewItem) {
      this.$emit("overview-unit-click", activeItem);

      this.dialogDetailVisible = true;
      this.clickedItem = activeItem;
    },
  },
});
</script>
<style lang="scss" scoped>
@import "src/flexible-table-module/scss/_variables.scss";

.data-overview-board {
  box-sizing: border-box;
  position: relative;
  width: 100%;
  // background-color: $color-reverse;

  .btn {
    &:hover {
      cursor: pointer;
    }
  }

  .btn-fold-area {
    position: absolute;
  }

  .btn-unfold-area {
    float: right;
  }

  .inner {
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    border-radius: 4px;
    box-shadow: 0px 0px 6px 2px rgba(0, 0, 0, 0.05);

    $header-height: 32px;

    .header {
      box-sizing: border-box;
      overflow: hidden;
      width: 100%;
      height: $header-height;
      line-height: $header-height;
      padding-right: 32px;

      .title {
        float: left;
        width: 100px;
        height: 100%;
        padding: 0 16px;
      }

      .setting {
        float: right;
        display: flex;
        justify-content: flex-end;
        align-items: center;

        .user-organization-selector {
          margin-right: 16px;
        }
      }
    }

    .body {
      width: 100%;
      height: calc(100% - $header-height);
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }
}
</style>