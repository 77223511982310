<template>
  <ElTooltip
    class="overflow-tooltip"
    :placement="placement"
    :disabled="disabled"
    :visible-arrow="true"
    :open-delay="500"
    :manual="manual"
    :popper-class="popperClass"
    @mouseenter.native="onMouseenter"
    @click.alt.native="onAltClick"
  >
    <template v-slot:content>
      <div v-if="isRichText" v-html="content"></div>
      <span v-else v-text="content"></span>
    </template>

    <slot></slot>
  </ElTooltip>
</template>
<script lang="ts">
import Vue from "vue";
import { Tooltip as ElTooltip } from "element-ui";

export default Vue.extend({
  inheritAttrs: false,
  name: "OverflowTooltip",
  components: { ElTooltip },
  props: {
    content: { type: String, default: "" }, // 提示文字
    placement: { type: String, default: "top" }, // 提示文字的气泡弹出方向
    isRichText: { type: Boolean, default: false }, // 提示文字是否是富文本格式的字符串
    popperClass: { type: String }, // 为 Tooltip 的 popper 添加类名
  },
  data() {
    return {
      disabled: false,
      manual: false,
    };
  },
  methods: {
    onMouseenter(e: MouseEvent) {
      if (e.target) {
        const target = e.target as HTMLElement;
        this.disabled = target.clientWidth >= target.scrollWidth;
      }
    },

    // 主要用于开发调试气泡弹窗的样式(点击一下固定气泡，再点击一下取消固定)
    onAltClick() {
      this.manual = !this.manual;
    },
  },
});
</script>
<style lang="scss" scoped>
.overflow-tooltip {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
</style>