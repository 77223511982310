<template>
  <el-dialog
    append-to-body
    class="ss-dialog"
    title="添加权限"
    width="460px"
    :visible.sync="dialogVisible"
  >
    <div class="custom-body">
      <el-form label-position="right" label-width="80px" :model="queryData">
        <el-form-item label="权限组">
          <el-input v-model="queryData.name"></el-input>
        </el-form-item>
        <el-form-item label="选择权限">
          <el-select v-model="powervalue" placeholder="请选择">
            <el-option
              v-for="item in permissionList"
              :key="item.key"
              :label="item.label"
              :value="item.key"
            >
              <span style="float: left">{{ item.label }}</span>
              <span style="float: right; color: #8492a6; font-size: 13px">{{
                item.tips
              }}</span>
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="选择人员">
          <el-input
            style="width: 320px"
            :title="selectUserList"
            v-model="selectUserList"
            @focus="selectMembersVisible = true"
          ></el-input>
          <!-- <el-button type="primary" @click="showaddman=true">添加成员</el-button> -->
          <AddMenbers
            @getselectman="getManList"
            v-if="selectMembersVisible"
            :visible.sync="selectMembersVisible"
            :type="'user'"
            :selectuser="selectUser"
          />
        </el-form-item>
      </el-form>
    </div>
    <div slot="footer">
      <el-button type="info" @click="dialogVisible = false">取 消</el-button>
      <el-button type="primary" :loading="confirmLoading" @click="handleConfirm" >确 定</el-button >
    </div>
  </el-dialog>
</template>

<script>
import AddMenbers from "@/components/dialog/AddMenbers.vue";
import { FILE_PERMISSIONS } from "@/const.js";
export default {
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    AddMenbers,
  },
  data() {
    return {
      selectMembersVisible: false,
      selectDepartmentsVisible: false,
      selectRolesVisible: false,
      show: false,
      permissionList: FILE_PERMISSIONS,
      userData: [],
      queryData: {
        name: "",
        region: "",
        type: "",
        userIdList: [],
      },
      selectUserList: "",
      selectUser: [],
      powervalue: "",
    };
  },
  computed: {
    dialogVisible: {
      get() {
        return this.visible;
      },
      set(val) {
        this.$emit("update:visible", val);
      },
    },
  },
  methods: {
    finish() {},
    /**
     * @description: 添加成员
     * @param value 选择的成员列表
     * @return {void}
     */
    async getSelectuser(value) {
      let list = [];
      value.forEach((element) => {
        list.push(element.id);
      });
      this.userData = value;
    },

    /**
     * @description: 获取添加的成员列表
     * @param value 列表
     * @return {void}
     */
    getManList(value) {
      this.queryData.userIdList = [];
      this.selectUser = value;
      this.selectUserList = "";
      value.forEach((item) => {
        this.selectUserList = this.selectUserList + "," + item.nickName;
      });
      this.selectUserList = this.selectUserList.replace(",", "");
      value.forEach((element) => {
        this.queryData.userIdList.push(element.id);
      });
    },
    handleConfirm(){
      
    }
  },
};
</script>

<style lang="scss" scoped>
.custom-body {
  padding: 20px 30px;

  .permission-item {
    width: fit-content;
    height: 30px;
    line-height: 30px;
    margin-left: 40px;
    position: relative;

    &::before {
      content: "";
      position: absolute;
      left: -40px;
      top: 0;
      width: 30px;
      height: 30px;
      background-image: url("@/assets/images/file/permission.png");
      background-size: 30px 30px;
      background-repeat: no-repeat;
      pointer-events: none;
    }
  }
}
::v-deep .el-input--suffix .el-input__inner {
  padding-right: 0;
  width: 320px;
}
</style>
