<template>
  <!-- 上下布局 -->
  <div class="web-page">
    <div class="web-page-top flex" v-if="current">
      <img
        :src="
          current.article.coverList[0]
            ? current.article.coverList[0].coverUrl
            : require('@/assets/images/sample-1.png')
        "
        alt=""
      />
      <div class="top-text">
        <h2>{{ current.article.articleName }}</h2>
        <div>
          {{ current.article.introduce }}
        </div>
      </div>
    </div>
    <div class="web-page-main" v-if="current">
      <div>
        <h3 class="title-text">产品简介</h3>
        <p class="title-underline"></p>
        <div v-html="current.article.detailContent" class="web-page-main-richtext"></div>
      </div>
      <template v-if="loginrequired">
        <div v-if="current.articleToSubjects && current.articleToSubjects.length">
          <h3 class="title-text">相关产品</h3>
          <p class="title-underline"></p>
          <div class="web-page-main-relevant">
            <div
              class="relavant-card"
              v-for="item in current.articleToSubjects"
              @click="handleClickSubject(item)"
              :key="item.id"
            >
              <img
                style="width: 100%"
                :src="
                  item.coverList && item.subjectCoverVos[0].coverUrl
                    ? item.subjectCoverVos[0].coverUrl
                    : require(`@/assets/images/sample-${common.getLastChar(
                        item.subjectId
                      )}.png`)
                "
                alt=""
              />
              <div class="one-line">{{ item.subjectName }}</div>
            </div>
          </div>
        </div>
        <div v-if="current.articleToArticles && current.articleToArticles.length">
          <h3 class="title-text">相关文章</h3>
          <p class="title-underline"></p>
          <div class="web-page-main-relevant">
            <div
              class="relavant-card"
              v-for="item in current.articleToArticles"
              @click="handleClickArticle(item)"
              :key="item.id"
            >
              <img
                style="width: 100%"
                :src="
                  item.coverList[0].coverUrl
                    ? item.coverList[0].coverUrl
                    : require('@/assets/images/sample-1.png')
                "
                alt=""
              />
              <div class="one-line">{{ item.toArticleName }}</div>
            </div>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>
<script>
import { ProductLibrary } from "../../../api/ProductLibrary";

export default {
  props: {
    current: Object,
    readonly: {
      type: Boolean,
      default: false,
    },
    loginrequired: {
      type: Boolean,
      default: true, //是否需要登录
    },
  },
  data() {
    return {
      currentItem: null,
    };
  },
  watch: {},
  mounted() {},
  methods: {
    /**
     * @description: 点击主题
     * @param {Object} value 点击项
     * @return {void}
     */
    /**
     * @description: 点击主题
     * @param {Object} value 点击项
     * @return {void}
     */
    async handleClickSubject(value) {
      if (this.readonly) {
        return;
      }
      const payload = {
        subjectId: value.subjectId,
        mirrorId: value.mirrorId,
        versionId: value.versionId,
      };
      // 预览展示方案
      const routeData = this.$router.resolve({
        path: "/column-preview",
        query: payload,
      });
      window.open(routeData.href);
    },
    /**
     * @description: 点击文章
     * @param {Object} value 点击项
     * @return {void}
     */
    handleClickArticle(value) {
      if (this.readonly) {
        return;
      }
      // this.$router.push({
      //   path: `/articlepreview/${value.toArticleId}`,
      //   query: {
      //     preveiew: true,
      //   },
      // });
      const routeData = this.$router.resolve({
        path: `/articlepreview/${value.toArticleId}`,
        query: {
          preveiew: true,
        },
      });
      window.open(routeData.href);
    },
  },
};
</script>
<style lang="scss">
.web-page {
  height: 100%;
  width: 100%;
  padding: 20px;
  box-sizing: border-box;
  overflow-y: auto;
  .web-page-top {
    margin: 0 auto;

    img {
      width: 30%;
      margin-right: 20px;
    }

    .top-text {
      // width: 50%;
    }
  }

  .web-page-main {
    padding-bottom: 40px;
    box-sizing: border-box;

    .title-text {
      text-align: center;
    }

    .title-underline {
      width: 42px;
      height: 2px;
      margin: 0 auto;
      border-bottom: 3px solid #1f65e7;
    }

    .web-page-main-richtext {
      margin-top: 30px;
    }

    .web-page-main-relevant {
      display: flex;
      flex-wrap: wrap;
      .relavant-card {
        width: 200px;
        box-shadow: 0px 0px 13px -2px #ccc;
        border-radius: 5px;
        margin: 18px;
        cursor: pointer;

        div {
          padding: 20px 0 20px 10px;
          font-weight: 500;
        }
      }
    }
  }
}
</style>
