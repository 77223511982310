export default [
  {
    path: 'userinfo',
    component: () => import('../views/BackgroundManagement/UserInformation/index.vue'),
    meta: {
      title: '后台管理',
      iscache: false,
      isframe: true,
    }
  },
  {
    path: 'member',
    component: () => import('../views/BackgroundManagement/MemberManagement/index.vue'),
    meta: {
      title: '后台管理',
      iscache: false,
      isframe: true,
    }
  },
  ,
  {
    path: 'role',
    component: () => import('../views/BackgroundManagement/RolePermission/index.vue'),
    meta: {
      title: '后台管理',
      iscache: false,
      isframe: true,
    }
  },
  {
    path: 'model',
    component: () => import('../views/BackgroundManagement/ModelGroup/index.vue'),
    meta: {
      title: '后台管理',
      iscache: false,
      isframe: true,
    },
  },
  {
    path: 'friend',
    component: () => import('../views/BackgroundManagement/FriendClassification/index.vue'),
    meta: {
      title: '后台管理',
      iscache: false,
      isframe: true,
    }
  },
  {
    path: 'cloud-storage',
    component: () => import('../views/BackgroundManagement/NetworkConfiguration/index.vue'),
    meta: {
      title: '后台管理',
      iscache: false,
      isframe: true,
    },
  },
  {
    path: 'inventory',
    component: () => import('../views/BackgroundManagement/ProductInventory/index.vue'),
    meta: {
      title: '后台管理',
      iscache: false,
      isframe: true,
    },
  },
  {
    path: 'keyboard',
    component: () => import('../views/BackgroundManagement/KeyboardConfigutation/index.vue'),
    meta: {
      title: '后台管理',
      iscache: false,
      isframe: true,
    },
  },
];