<template>
  <div class="header-cell-icon-btn" :style="{ 'justify-content': align }">
    <div class="icon-btn" :style="sortDropdownIconStyle">
      <ElDropdown
        placement="bottom"
        trigger="click"
        @visible-change="onBtnArrowDropdownVisibleChange"
        @command="onBtnArrowDropdownCommand"
      >
        <div class="icon-btn-inner">
          <svg-icon
            class="svg-icon"
            sys="common-table"
            name="HeaderCellIconBtn/arrow"
            :size="8"
            slot="reference"
          />
        </div>
        <ElDropdownMenu
          class="header-cell-icon-btn-dropdown-menu"
          slot="dropdown"
        >
          <template v-if="column.sortable">
            <ElDropdownItem command="asc" :class="{ active: isAscItemActive }"
              >顺序排列</ElDropdownItem
            >
            <ElDropdownItem command="desc" :class="{ active: isDescItemActive }"
              >倒序排列</ElDropdownItem
            >
          </template>
          <template v-if="isFreezed">
            <ElDropdownItem command="unfreeze-all">解除冻结</ElDropdownItem>
          </template>
          <template v-else>
            <ElDropdownItem command="freeze">冻结至此列</ElDropdownItem>
          </template>
        </ElDropdownMenu>
      </ElDropdown>
    </div>

    <div class="title">{{ column.title }}</div>

    <div
      class="icon-btn"
      :class="{ 'not-empty': !isFilterTargetEmpty }"
      v-if="isFilterable && column.filterable"
      :style="filterPopoverIconStyle"
    >
      <ElPopover
        ref="popoverFilter"
        placement="bottom"
        trigger="click"
        v-model="filterPopoverVisible"
        :width="filterPopoverWidth"
        :disabled="column.isColumnFilterLoading"
      >
        <template v-if="isStringSearch">
          <StringSearch
            :keyword="column.filter.target"
            @submit="onFilterSubmit"
            @cancel="onFilterCancel"
          />
        </template>
        <template v-else-if="isStringSelect">
          <StringSelect
            :visible="filterPopoverVisible"
            :selectList="column.filter.selectList"
            :selections="column.filter.target"
            @submit="onFilterSubmit"
            @cancel="onFilterCancel"
          />
        </template>
        <template v-else-if="isNumberFrame">
          <NumberFrame
            :min="column.filter.min"
            :max="column.filter.max"
            :currMin="
              column.filter.target ? column.filter.target[0] : undefined
            "
            :currMax="
              column.filter.target ? column.filter.target[1] : undefined
            "
            @submit="onFilterSubmit"
            @cancel="onFilterCancel"
          />
        </template>
        <template v-else-if="isDateTimeSingle">
          <DateTimeSingle
            :type="column.type"
            :datetime="column.filter.target"
            @submit="onFilterSubmit"
            @cancel="onFilterCancel"
          />
        </template>
        <template v-else-if="isDateTimeFrame">
          <DateTimeFrame
            :type="column.type"
            :datetime="column.filter.target"
            @submit="onFilterSubmit"
            @cancel="onFilterCancel"
          />
        </template>
        <template v-else-if="isKeyStrValueSearch">
          <StringSearch
            :keyword="column.filter.target"
            @submit="onFilterSubmit"
            @cancel="onFilterCancel"
          />
        </template>
        <template v-else-if="isKeyStrValueSelect">
          <StringSelect
            :visible="filterPopoverVisible"
            :selectList="column.filter.selectList"
            :selections="column.filter.target"
            @submit="onFilterSubmit"
            @cancel="onFilterCancel"
          />
        </template>
      </ElPopover>
      <div class="icon-btn-inner" v-popover:popoverFilter>
        <svg-icon
          v-show="!column.isColumnFilterLoading"
          class="svg-icon"
          sys="common-table"
          name="HeaderCellIconBtn/filter"
          :size="16"
          slot="reference"
        />
        <svg-icon
          v-show="column.isColumnFilterLoading"
          class="svg-icon loading"
          sys="common-table"
          name="HeaderCellIconBtn/loading"
          :size="16"
          slot="reference"
        />
      </div>
    </div>
  </div>
</template>
<script lang="ts">
import Vue from "vue";
import {
  Dropdown as ElDropdown,
  DropdownMenu as ElDropdownMenu,
  DropdownItem as ElDropdownItem,
  Popover as ElPopover,
} from "element-ui";
import SvgIcon from "@/flexible-table-module/svg-icon-importer";

import IconFilter from "@/flexible-table-module/icons/HeaderCellIconBtn/IconFilter.vue";
import IconLoading from "@/flexible-table-module/icons/HeaderCellIconBtn/IconLoading.vue";

import StringSearch from "./HeaderCellIconBtn/StringSearch.vue";
import StringSelect from "./HeaderCellIconBtn/StringSelect.vue";
import NumberFrame from "./HeaderCellIconBtn/NumberFrame.vue";
import DateTimeSingle from "./HeaderCellIconBtn/DateTimeSingle.vue";
import DateTimeFrame from "./HeaderCellIconBtn/DateTimeFrame.vue";

import { Sort } from "@/flexible-table-module/entity/Table/Sort";
import { Column } from "@/flexible-table-module/entity/Table/Column";

import {
  DateTimeFilter,
  KeyStrValueFilter,
  NumberFilter,
  StringFilter,
} from "@/flexible-table-module/entity/Table/ColumnFilter";

export default Vue.extend({
  inheritAttrs: false,
  name: "HeaderCellIconBtn",
  components: {
    ElDropdown,
    ElDropdownMenu,
    ElDropdownItem,
    ElPopover,
    SvgIcon,
    IconFilter,
    IconLoading,
    StringSearch,
    StringSelect,
    NumberFrame,
    DateTimeSingle,
    DateTimeFrame,
  },
  props: {
    scope: { type: Object },
    column: { type: Column },
    isFreezed: { type: Boolean, default: false }, // 是否被冻结
    sort: { type: Sort }, // 当前的排序状态，为 undefined 时表示本列不参与排序
    isFilterable: { type: Boolean, default: true }, // 全局性设置，不是针对具体某列
  },
  data() {
    return {
      isIconsVisible: false as boolean, // 是否显示图标
      // mouseEnterHandler: null as (() => void) | null, // 鼠标移入事件处理函数
      // mouseLeaveHandler: null as (() => void) | null, // 鼠标移出事件处理函数
      sortDropdownVisible: false as boolean, // 显示排序的下拉框是否可见
      filterPopoverVisible: false as boolean, // 是否显示筛选下拉框
      elTableCellElement: null as HTMLElement | null, // el-table__cell 元素，用于监听鼠标移入移出事件
    };
  },
  computed: {
    align(): string {
      switch (this.column.headerAlign) {
        case "center":
          return "center";
        case "left":
          return "flex-start";
        case "right":
          return "flex-end";
        default:
          return "center";
      }
    },
    sortDropdownIconStyle() {
      if (
        this.isIconsVisible ||
        this.filterPopoverVisible ||
        this.sortDropdownVisible
      ) {
        return {};
      } else {
        return { visibility: "hidden" };
      }
    },
    filterPopoverIconStyle() {
      if (
        this.isIconsVisible ||
        !this.isFilterTargetEmpty ||
        this.column.isColumnFilterLoading ||
        this.filterPopoverVisible ||
        this.sortDropdownVisible
      ) {
        return {};
      } else {
        return { visibility: "hidden" };
      }
    },
    // 顺序排列按钮是否高亮
    isAscItemActive(): boolean {
      if (this.sort && this.sort.order === "asc") return true;
      else return false;
    },
    // 倒序排列按钮是否高亮
    isDescItemActive(): boolean {
      if (this.sort && this.sort.order === "desc") return true;
      else return false;
    },

    // 下拉框的宽度
    filterPopoverWidth(): number {
      if (
        this.isDateTimeFrame &&
        (this.column.type === "time" ||
          this.column.type === "date" ||
          this.column.type === "time-frame" ||
          this.column.type === "date-frame")
      ) {
        return 400;
      } else if (
        this.isDateTimeFrame &&
        (this.column.type === "datetime-frame" ||
          this.column.type === "datetime" ||
          this.column.type === "short-datetime")
      ) {
        return 400;
      } else if (this.isNumberFrame) {
        return 280;
      } else {
        return 220;
      }
    },

    isFilterTargetEmpty(): boolean {
      if (
        !this.column.filter ||
        this.column.filter.target === undefined ||
        this.column.filter.target === null ||
        (Array.isArray(this.column.filter.target) &&
          this.column.filter.target.length === 0)
      ) {
        return true;
      } else {
        return false;
      }
    },

    // --------------------------------------------------------
    isStringSearch(): boolean {
      return (
        this.column.filter instanceof StringFilter &&
        this.column.filter.type === "string-search"
      );
    },
    isStringSelect(): boolean {
      return (
        this.column.filter instanceof StringFilter &&
        this.column.filter.type === "string-select"
      );
    },
    isNumberFrame(): boolean {
      return (
        this.column.filter instanceof NumberFilter &&
        this.column.type === "number"
      );
    },
    isDateTimeSingle(): boolean {
      return (
        this.column.filter instanceof DateTimeFilter &&
        this.column.filter.type === "date-time-single" &&
        (this.column.type === "time" ||
          this.column.type === "date" ||
          this.column.type === "datetime" ||
          this.column.type === "short-datetime")
      );
    },
    isDateTimeFrame(): boolean {
      return (
        this.column.filter instanceof DateTimeFilter &&
        this.column.filter.type === "date-time-frame" &&
        (this.column.type === "time-frame" ||
          this.column.type === "date-frame" ||
          this.column.type === "datetime-frame" ||
          this.column.type === "time" ||
          this.column.type === "date" ||
          this.column.type === "datetime" ||
          this.column.type === "short-datetime")
      );
    },
    isKeyStrValueSearch(): boolean {
      return (
        this.column.filter instanceof KeyStrValueFilter &&
        this.column.filter.type === "key-str-value-search"
      );
    },
    isKeyStrValueSelect(): boolean {
      return (
        this.column.filter instanceof KeyStrValueFilter &&
        this.column.filter.type === "key-str-value-select"
      );
    },
  },
  watch: {
    isFreezed: {
      immediate: false,
      handler(isFreezed: boolean) {
        // 因为 el-table__cell 元素 DOM 结构可能会变化，所以每次冻结状态变化时都重新查找 el-table__cell 元素
        this.rmMouseMovementListener();
        this.addMouseMovementListener();
      },
    },
  },
  mounted() {
    this.addMouseMovementListener();
  },
  beforeDestroy() {
    this.rmMouseMovementListener();
  },
  methods: {
    // 当箭头下拉框的可见性发生变化时
    onBtnArrowDropdownVisibleChange(visible: boolean) {
      this.sortDropdownVisible = visible;
    },

    // 当箭头下拉框中的按钮被点击时
    onBtnArrowDropdownCommand(
      command: "asc" | "desc" | "freeze" | "unfreeze-all"
    ) {
      // "asc" | "desc" 的取消和确定的逻辑，交由上级父组件编写
      this.$emit("header-dropdown-command", command, this.column);
    },

    onFilterSubmit(data: any) {
      if (this.column.filter) {
        this.filterPopoverVisible = false;

        const setVal = () => {
          const before = JSON.stringify(this.column.filter!.target);
          const after = JSON.stringify(data);

          if (before !== after) {
            this.column.filter!.target = data;
            this.$emit("filter-change", this.column);
          }
        };

        const clear = (flag?: number) => {
          if (this.column.filter!.target !== undefined) {
            this.column.filter!.target = undefined;
            this.$emit("filter-change", this.column);
          }
        };

        switch (typeof data) {
          case "string": {
            // string
            if (data.length > 0) setVal();
            else clear(1);
            break;
          }
          case "number": {
            // number
            setVal();
            break;
          }
          case "object": {
            if (Array.isArray(data)) {
              if (
                data.length === 2 &&
                (data[0] instanceof Date ||
                  data[1] instanceof Date ||
                  typeof data[0] === "number" ||
                  typeof data[1] === "number")
              ) {
                // [Date, Date] | [number, number]
                setVal();
              } else if (data.length > 0 && typeof data[0] === "string") {
                // string[]
                setVal();
              } else {
                clear(2);
              }
            } else if (data instanceof Date) {
              // Date
              setVal();
            } else {
              clear(3);
            }
            break;
          }

          default:
            clear(4);
            break;
        }

        this.$emit("filter-submit", this.column);
      }
    },

    onFilterCancel() {
      this.filterPopoverVisible = false;
      this.$emit("filter-cancel", this.column);
    },

    // 鼠标移入事件处理函数
    mouseEnterHandler() {
      this.isIconsVisible = true;
    },

    // 鼠标移出事件处理函数
    mouseLeaveHandler() {
      this.isIconsVisible = false;
    },

    // 为 el-table__cell 元素添加鼠标移入移出事件监听
    addMouseMovementListener() {
      this.elTableCellElement = this.findElTableCellElement();
      // 如果找到了 el-table__cell 元素，则监听鼠标移入移出事件
      if (this.elTableCellElement) {
        this.elTableCellElement.addEventListener(
          "mouseenter",
          this.mouseEnterHandler
        );
        this.elTableCellElement.addEventListener(
          "mouseleave",
          this.mouseLeaveHandler
        );
      }
    },

    // 为 el-table__cell 元素移除鼠标移入移出事件监听
    rmMouseMovementListener() {
      if (this.elTableCellElement) {
        this.elTableCellElement.removeEventListener(
          "mouseenter",
          this.mouseEnterHandler
        );
        this.elTableCellElement.removeEventListener(
          "mouseleave",
          this.mouseLeaveHandler
        );
      }
    },

    // 通过递归查找父元素，找到 el-table__cell 元素
    findElTableCellElement(): HTMLElement | null {
      let parentElement = this.$el.parentNode;
      while (parentElement instanceof HTMLElement) {
        if (
          parentElement.classList.contains("el-table__cell") &&
          !parentElement.classList.contains("is-hidden")
        ) {
          return parentElement;
        }
        parentElement = parentElement.parentNode;
      }
      return null;
    },
  },
});
</script>
<style lang="scss" scoped>
@import "src/flexible-table-module/scss/_variables.scss";

.header-cell-icon-btn {
  display: flex;
  // justify-content: center;
  align-items: center;

  .icon-btn {
    width: 20px;
    height: 20px;
    flex-shrink: 0;

    .el-dropdown {
      width: 100%;
      height: 100%;
      display: block;
    }

    .icon-btn-inner {
      width: 100%;
      height: 100%;

      display: flex;
      justify-content: center;
      align-items: center;

      color: $color-info;

      .svg-icon {
        display: block;
        fill: #666;

        @keyframes spin {
          from {
            transform: rotate(0deg);
          }
          to {
            transform: rotate(360deg);
          }
        }

        &.loading {
          animation: spin 1s linear infinite;
        }
      }

      &:hover {
        cursor: pointer;
        background-color: $btn-hover-color;
        // color: black;
        border-radius: 25%;
      }
    }

    &.not-empty {
      .icon-btn-inner {
        .svg-icon {
          fill: $color-brand;
        }
      }
    }
  }

  .title {
    color: $clr-el-table-header;
    padding: 0 2px;
    // text-overflow: ellipsis;
    // white-space: nowrap;
    // overflow: hidden;
  }
}
</style>
<style lang="scss">
@import "src/flexible-table-module/scss/_variables.scss";

.header-cell-icon-btn-dropdown-menu {
  &.el-dropdown-menu {
    .active {
      color: $color-brand;
    }
  }
}
</style>