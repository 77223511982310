<template>
  <div class="root flexible-table-core">
    <Classification
      v-if="hasClassification"
      :height="layout.classificationHeight"
      :tabs="allTabs"
      :activeTabName.sync="currTabName"
      @classification-click="onClassificationClick"
    />
    <template v-if="isContentVisible">
      <template v-if="hasDataOverview">
        <DataOverviewBoard
          :height="layout.dataOverviewBoardHeight"
          :data="overview"
          @default-fold-change="onDefaultFoldChange"
          @item-active-change="onItemActiveChange"
          @setting-change="onSettingChange"
          @dialog-setting-show="onDialogSettingShow"
          @date-frame-change="onDateFrameChange"
          @overview-unit-click="onOverviewUnitClick"
        >
          <FlexibleTable
            :table="tableInfo.table"
            :data="dataOverviewTableInfo.data"
            :totalCount="dataOverviewTableInfo.totalCount"
            :loading="dataOverviewTableInfo.loading"
            :currPage.sync="queryDataOverview.currPage"
            :pageSize.sync="queryDataOverview.pageSize"
            :isColumnFilterable="false"
          ></FlexibleTable>
        </DataOverviewBoard>
      </template>

      <MiddleBar
        :hasShrinkBtn="
          hasSideFilter && sideFilterBarShrinkBtnMode === 'middleBar'
        "
        :height="layout.middleBarHeight"
        :isShrink="!isFiltersSiderVisible"
        @btn-shrink-click="onMidBarBtnShrinkClick"
      >
        <template v-slot:center>
          <FilterConditions
            :autoExpand="isFilterConditionAutoExpand"
            :historySelections="historySelections"
            :columnsWithFilterTarget="columnsWithFilterTarget"
            @condition-close="onFilterConditionClose"
            @conditions-clear="onFilterConditionsClear"
          />
        </template>
        <template v-slot:right>
          <!-- <TextSearcherAnimate
            class="clickable text-search"
            :value.sync="query.textSearchVal"
            @text-search="onTextSearch"
          /> -->
          <TextSearcher
            class="clickable text-search"
            :value.sync="query.textSearchVal"
            @text-search="onTextSearch"
          />

          <div class="clickable midbar">
            <slot name="midbar"></slot>
          </div>

          <template v-if="hasExportData">
            <ElButton class="clickable" size="small" @click="onExportData"
              >导出</ElButton
            >
          </template>

          <ViewSwitcher
            v-if="hasViewSwitcher"
            v-model="currView"
            class="clickable view-switch"
          />

          <!-- <IconBtnSingle
            class="clickable"
            :iconSize="iconSize + 4 * 2"
            @btn-click="isColumnSettingVisible = true"
          >
            <svg-icon
              class="svg-icon"
              :sys="'common-table'"
              :name="'ColumnsSetting/gear'"
              :size="iconSize"
            />
          </IconBtnSingle> -->

          <template
            v-if="
              hasOperation &&
              tableInfo.table &&
              tableInfo.table.optBtnsPosition === 'last-col'
            "
          >
            <IconBtnSingle
              v-if="
                operationColShrinkBtnMode === 'middleBar' && isLastColShrink
              "
              class="shrink-btn in-mid-bar"
              :iconSize="iconSize + 4 * 2"
              @btn-click="onBtnOperationColShrinkClick"
            >
              <svg-icon
                sys="common-table"
                name="OccupyingColumn/fold"
                :size="iconSize"
                :rotation="180"
              />
            </IconBtnSingle>
          </template>
        </template>
      </MiddleBar>

      <FlexibleTable
        ref="flexibleTable"
        :height="flexibleTableHeight"
        :table="tableInfo.table"
        :sorts="tableInfo.sorts"
        :filters4ViewEditor="filterInfo.filters4ViewEditor"
        :filters4ViewEditorArchived="filterInfo.filters4ViewEditorArchived"
        :filters4ViewEditorAll="filterInfo.filters4ViewEditorAll"
        :activeFilters="activeFilters"
        :currFilter="currFilter"
        :data="tableInfo.data"
        :isTree="true"
        :hasOperation="hasOperation"
        :operationWidth="operationWidth"
        :isFiltersSiderVisible="isFiltersSiderVisible"
        :isLastColShrink="isLastColShrink"
        :hasCheckboxColumn="hasCheckboxColumn"
        :hasRadioColumn="hasRadioColumn"
        :isSelectionReserved="isSelectionReserved"
        :hasSideFilter="hasSideFilter"
        :formRowFlag="formRowFlag"
        :hasVerticalBorder="hasVerticalBorder"
        :loadingTableFormat="loading.tableFormat"
        :loadingTableRows="loading.tableRows"
        :loadingFilters="isLoadingFilters"
        :currPage.sync="query.currPage"
        :pageSize.sync="query.pageSize"
        :pageSizes="TABLE_PAGE_SIZES"
        :totalCount="tableInfo.totalCount"
        :loadMethod="lazyLoadTableSubRows"
        :getCellDropdownData="getSelectableCellDropdownData"
        :confirmCellSelection="confirmCellSelection"
        :multiFilters="filterInfo.multiFilters"
        :viewFilters="filterInfo.viewFilters"
        :historySelections="historySelections"
        :currView="currView"
        :cardWidth="cardWidth"
        :hasSideFilterBarShrinkBtn="
          hasSideFilter && sideFilterBarShrinkBtnMode === 'firstCol'
        "
        :hasOperationColumnShrinkBtn="
          operationColShrinkBtnMode === 'lastCol' &&
          tableInfo.table &&
          tableInfo.table.optBtnsPosition === 'last-col'
        "
        @btn-shrink-click="onMidBarBtnShrinkClick"
        @curr-page-change="onCurrPageChange"
        @page-size-change="onPageSizeChange"
        @cell-click="onBodyCellClick"
        @cell-corner-tip-click="onBodyCellCornerTipClick"
        @sort-change="onSortChange"
        @column-filter-submit="onColumnFilterSubmit"
        @column-filter-cancel="onColumnFilterCancel"
        @column-filter-change="onColumnFilterChange"
        @selection-filter-change="onSelectionFilterChange"
        @selection-list-checkbox-change="onSelectionListCheckboxChange"
        @selection-list-single-click="onSelectionListSingleClick"
        @view-editor-show="onViewEditorShow"
        @view-editor-close="onViewEditorClose"
        @column-button-click="onColumnButtonClick"
        @single-filter-switch="onSingleFilterSwitch"
        @single-filter-archive="onSingleFilterArchive"
        @add-new-multiple-filter="onAddNewMultipleFilter"
        @edit-title-multiple-filter="onEditTitleMultipleFilter"
        @edit-filter-multiple-filter="onEditFilterMultipleFilter"
        @add-new-view-filter="onAddNewViewFilter"
        @edit-title-view-filter="onEditTitleViewFilter"
        @edit-filter-view-filter="onEditFilterViewFilter"
        @del-multiple-filter="onDelMultipleFilter"
        @del-view-filter="onDelViewFilter"
        @row-multi-select="onRowMultiSelect"
        @row-single-select="onRowSingleSelect"
        @form-cell-input="onFormCellInput"
        @single-conditions-editor-submit="onSingleConditionsEditorSubmit"
        @operation-column-shrink-btn-click="onBtnOperationColShrinkClick"
        @columns-setting-btn-click="onColumnsSettingBtnClick"
      >
        <template
          v-slot:operation-shrink-btn
          v-if="operationColShrinkBtnMode === 'middleBar'"
        >
          <IconBtnSingle
            class="shrink-btn inline"
            :iconSize="iconSize + 4 * 2"
            @btn-click="onBtnOperationColShrinkClick"
          >
            <svg-icon
              sys="common-table"
              name="OccupyingColumn/fold"
              :size="iconSize"
            />
          </IconBtnSingle>
        </template>

        <template v-slot:operation="row">
          <slot name="operation" v-bind="row">
            <OperationButton
              :name="'op1'"
              :title="'操作1'"
              :row="row"
              :hasIcon="true"
              @btn-click="onOperationBtnClick"
            >
              <template v-slot:icon>
                <!-- <IconFilter :size="12" /> -->
                <svg-icon
                  sys="common-table"
                  name="HeaderCellIconBtn/filter"
                  :size="12"
                />
              </template>
            </OperationButton>
            <OperationButton
              :name="'op2'"
              :title="'操作2'"
              :row="row"
              :hasIcon="false"
              @btn-click="onOperationBtnClick"
            >
            </OperationButton>
            <OperationDropdown :title="'更多'">
              <OperationDropdownItem
                :name="'op3'"
                :title="'操作3'"
                :row="row"
                :hasIcon="false"
                @item-click="onOperationBtnClick"
              ></OperationDropdownItem>
              <OperationDropdownItem
                :name="'op4'"
                :title="'操作4'"
                :row="row"
                :hasIcon="true"
                @item-click="onOperationBtnClick"
              >
                <template v-slot:icon>
                  <!-- <IconFilter :size="12" /> -->
                  <svg-icon
                    sys="common-table"
                    name="HeaderCellIconBtn/filter"
                    :size="12"
                  />
                </template>
              </OperationDropdownItem>
            </OperationDropdown>
          </slot>
        </template>

        <template v-slot:otherInfo>
          <template v-if="hasDataStatistics">
            <OverflowTooltip :content="dataStatisticsStr">
              <DataStatisticsView
                v-loading="loading.dataStatistics"
                class="data-sta-view"
                :dataStatistics="tableInfo.dataStatistics"
              />
            </OverflowTooltip>
          </template>
        </template>

        <template v-slot:card="slotProps">
          <slot name="card" v-bind="slotProps"></slot>
        </template>

        <template v-slot:button-column-icon="{ iconClass, data }">
          <slot name="button-column-icon" v-bind="{ iconClass, data }"></slot>
        </template>

        <template v-slot:cell-bottom="{ row, col }">
          <slot name="cell-bottom" v-bind="{ row, col }"></slot>
        </template>

        <template v-slot:cell-left="{ row, col }">
          <slot name="cell-left" v-bind="{ row, col }"></slot>
        </template>

        <template v-slot:form-cell="{ row, col }">
          <slot name="form-cell" v-bind="{ row, col }"></slot>
        </template>
      </FlexibleTable>

      <ColumnsSetting
        v-if="currView === 'table'"
        class="col-setting"
        :drawerVisible.sync="isColumnSettingVisible"
        :table="tableInfo.table"
        :dataStatistics="tableInfo.dataStatistics"
        :hasDataStatistics="hasDataStatistics"
        :hasOperation="hasOperation"
        @column-sort="onColumnSort"
        @opt-btns-modify="onOptBtnsModify"
        @column-active-change="onColumnActiveChange"
        @statistic-active-change="onStatisticActiveChange"
        @column-cell-align-change="onColumnCellAlignChange"
      ></ColumnsSetting>
    </template>
  </div>
</template>
<script lang="ts">
import Vue, { type DirectiveOptions, PropType } from "vue";
import { Button as ElButton } from "element-ui";
import SvgIcon from "@/flexible-table-module/svg-icon-importer";

import { Loading } from "@/flexible-table-module/components/Common/Loading";

// import IconFilter from "@/flexible-table-module/icons/HeaderCellIconBtn/IconFilter.vue";
// import IconShrinkLeft from "@/flexible-table-module/icons/MiddleBar/IconShrinkLeft.vue";
// import IconShrinkRight from "@/flexible-table-module/icons/MiddleBar/IconShrinkRight.vue";

import FlexibleTable from "@/flexible-table-module/components/FlexibleTable.vue";
import OperationButton from "@/flexible-table-module/components/FlexibleTable/OperationButtons/OperationButton.vue";
import OperationDropdown from "@/flexible-table-module/components/FlexibleTable/OperationButtons/OperationDropdown.vue";
import OperationDropdownItem from "@/flexible-table-module/components/FlexibleTable/OperationButtons/OperationDropdownItem.vue";
import DataStatisticsView from "@/flexible-table-module/components/FlexibleTable/DataStatisticsView.vue";
import Classification from "@/flexible-table-module/components/Classification.vue";
import DataOverviewBoard from "@/flexible-table-module/components/DataOverviewBoard.vue";
import MiddleBar from "@/flexible-table-module/components/MiddleBar.vue";
import ColumnsSetting from "@/flexible-table-module/components/MiddleBar/ColumnsSetting.vue";
import TextSearcherAnimate from "@/flexible-table-module/components/MiddleBar/TextSearcherAnimate.vue";
import TextSearcher from "@/flexible-table-module/components/MiddleBar/TextSearcher.vue";
import ViewSwitcher from "@/flexible-table-module/components/MiddleBar/ViewSwitcher.vue";
import FilterConditions from "@/flexible-table-module/components/MiddleBar/FilterConditions.vue";
import IconBtn from "@/flexible-table-module/components/Common/IconBtn.vue";
import IconBtnSingle from "@/flexible-table-module/components/Common/IconBtnSingle.vue";
import OverflowTooltip from "@/flexible-table-module/components/Common/OverflowTooltip.vue";

import {
  DEFAULT_LAYOUT,
  DEFAULT_PAGE_SIZE,
  DELAY_AFTER_SELECTION_LIST_CHANGE,
  ROOT_TREE_NODE_TEMPLATE,
  STORAGE_NAMESPACE,
  TABLE_PAGE_SIZES,
  // --------------------
  TREE_PROPS_CHILDREN,
  TREE_PROPS_ISCHECKED,
} from "@/flexible-table-module/constants";

import {
  Table,
  OptBtnsPositionType,
} from "@/flexible-table-module/entity/Table";
import { BaseColumn } from "@/flexible-table-module/entity/Table/BaseColumn";
import { Column } from "@/flexible-table-module/entity/Table/Column";
import { ButtonColumn } from "@/flexible-table-module/entity/Table/ButtonColumn";
import { TFormColumn } from "../entity/Table/TFormColumn";
import { Sort } from "@/flexible-table-module/entity/Table/Sort";
import { ITreeNode, TreeNode } from "@/flexible-table-module/entity/TreeNode";
import {
  Filter,
  MultiFilter,
  TypeFilterConditions,
  ViewFilter,
} from "@/flexible-table-module/entity/Filter";
import { IBaseTableData } from "@/flexible-table-module/entity/Table/IBaseTableData";
import { DataOverviewItem } from "@/flexible-table-module/entity/DataOverview/DataOverviewItem";
import { DataStatistic } from "@/flexible-table-module/entity/Table/DataStatistic";
import { KeyValuePair } from "@/flexible-table-module/entity/KeyValuePair";

import {
  IData,
  TLayout,
  type TypeTablePageSize,
} from "@/flexible-table-module/interface/view/data";

// ---------------------------------------------------------------------------------------

import { BizTable } from "@/flexible-table-module/biz/table";
import { BizDataOverview } from "@/flexible-table-module/biz/data-overview";
import { BizFilter } from "@/flexible-table-module/biz/filter";

import { ParamsForGetTableRows } from "@/flexible-table-module/entity/DTO/ParamsForGetTableRows";
import { ParamsForGetTableFormat } from "@/flexible-table-module/entity/DTO/ParamsForGetTableFormat";
import { ParamsForGetColumnFilter } from "@/flexible-table-module/entity/DTO/ParamsForGetColumnFilter";

// @ts-ignore
import DownloadHelper from "@/flexible-table-module/util/DownloadHelper.js";
import { TreeHelper } from "@/flexible-table-module/util/TreeHelper";
import { debounce } from "@/flexible-table-module/util/InputOptimize";

import { FormRow } from "@/flexible-table-module/entity/Table/FormRow";
import {
  FilterType,
  HistorySelections,
} from "@/flexible-table-module/entity/HistorySelections";

// ---------------------------------------------------------------------------------------
let bizTable: BizTable;
let bizDataOverview: BizDataOverview;
let bizFilter: BizFilter;
// ---------------------------------------------------------------------------------------

type TypeView = "cards" | "table";

const STORAGE_PAGE_SIZE_NAME = `${STORAGE_NAMESPACE}__page-size`;
const STORAGE_OPERATION_BTN_POSITION_NAME = `${STORAGE_NAMESPACE}__operation-btn-position`;

export default Vue.extend({
  inheritAttrs: false,
  name: "FlexibleTableIntegration",
  components: {
    ElButton,
    SvgIcon,
    FlexibleTable,
    OperationButton,
    OperationDropdown,
    OperationDropdownItem,
    DataStatisticsView,
    Classification,
    DataOverviewBoard,
    MiddleBar,
    ColumnsSetting,
    TextSearcherAnimate,
    TextSearcher,
    ViewSwitcher,
    FilterConditions,
    IconBtn,
    IconBtnSingle,
    OverflowTooltip,
  },
  directives: { loading: Loading.directive },
  props: {
    // 后端地址(指定本值，主要用于测试用途)
    baseURL: { type: String, required: false },
    // 标签列表
    normalTabs: {
      type: Array as PropType<{ name: string; label: string }[]>,
      default: () => [],
    },
    // 不显示本页内容的标签
    extraTabs: {
      type: Array as PropType<{ name: string; label: string }[]>,
      default: () => [],
    },
    // 默认显示的标签的序号
    defaultTabIndex: { type: Number, default: 0 },

    // ----------------------------------------------------

    // 操作按钮列的宽度
    operationWidth: { type: Number, default: 200 },
    // 卡片视图下卡片的宽度
    cardWidth: { type: Number },

    // ----------------------------------------------------

    // 模块名称(传给后端)
    moduleName: { type: String, required: true },
    // 是否有数据概览
    hasDataOverview: { type: Boolean, default: false },
    // 是否有标签栏
    hasClassification: { type: Boolean, default: false },
    // 默认打开第几个单列条件选择器(从0开始)
    defaultFilterIndex: { type: Number, default: 0 },
    // 是否有多选列
    hasCheckboxColumn: { type: Boolean, default: false },
    // 是否有单选列
    hasRadioColumn: { type: Boolean, default: false },
    // 换页时，是否保持之前的选择
    isSelectionReserved: { type: Boolean, default: false },
    // 是否有统计数据
    hasDataStatistics: { type: Boolean, default: true },
    // 是否有导出表格数据的功能按钮
    hasExportData: { type: Boolean, default: false },
    // 是否有操作按钮
    hasOperation: { type: Boolean, default: true },
    // 操作按钮的位置是否本地记录
    isOperationBtnsPositionLocal: { type: Boolean, default: true },
    // 是否有表格视图和卡片视图的切换功能
    hasViewSwitcher: { type: Boolean, default: false },
    // 是否有侧边筛选栏(永不显示)
    hasSideFilter: { type: Boolean, default: true },
    // 默认侧边筛选栏是否显示
    defaultSideFilterVisible: { type: Boolean, default: true },
    // 筛选历史条件是否自动展开
    isFilterConditionAutoExpand: {
      type: Boolean,
      default:
        process.env.NODE_ENV === "dev" || process.env.NODE_ENV === "development"
          ? false
          : true,
    },
    // 是否有竖向边框
    hasVerticalBorder: { type: Boolean, default: false },

    // 表格型单元格的更新类型
    formCellUpdateType: {
      type: String as PropType<"whenInput" | "whenBlur">,
      default: "whenBlur",
      validator: function (value: string) {
        return ["whenInput", "whenBlur"].includes(value);
      },
    },

    // 默认的视图
    defaultView: {
      type: String as PropType<TypeView>,
      default: "table",
      validator: function (value: string) {
        return ["cards", "table"].includes(value);
      },
    },

    // 表格中的下拉框单元格 -> 下拉列表的某一项被选中时的回调。resolve(true) 代表确认选择，resolve(false) 代表取消选择
    confirmCellSelection: {
      type: Function as PropType<
        (
          row: IBaseTableData,
          column: Column,
          item: KeyValuePair<string>,
          resolve: (isConfirm: boolean) => void
        ) => void
      >,
    },
  },
  data(): IData<IBaseTableData, string, string> & {
    layout: TLayout; // 各组件布局尺寸
    iconSize: number;
    TABLE_PAGE_SIZES: number[]; // 可选的分页大小
    currFilter: Filter | MultiFilter | "view" | null; // 当前被选中的过滤器
    isFiltersSiderVisible: boolean; // 过滤侧边栏是否显示
    isLastColShrink: boolean; // ”最后一列“（操作栏列）是否收起
    rowMultiSelection: IBaseTableData[]; // 被多选的行; 用于多选列
    rowSingleSelection: IBaseTableData | undefined; // 被单选的行; 用于单选列
    currView: TypeView;
    historySelections: HistorySelections;
    treeHelper: TreeHelper;
    formRowFlag: string;
    onRootClickBindThis: (e: MouseEvent) => void;
    debounceGetTableDataAndSoOn: () => void;
    sideFilterBarShrinkBtnMode: "middleBar" | "firstCol";
    operationColShrinkBtnMode: "middleBar" | "lastCol";
    isColumnSettingVisible: boolean;
  } {
    return {
      currTabName: "",

      query: {
        currPage: 1,
        pageSize: DEFAULT_PAGE_SIZE,
        textSearchVal: "",
      },

      queryDataOverview: {
        currPage: 1,
        pageSize: 10,
      },

      dataOverviewTableInfo: {
        data: undefined,
        totalCount: undefined,
        loading: false,
      },

      tableInfo: {
        table: undefined,
        sorts: [],
        dataStatistics: undefined,
        data: undefined,
        totalCount: undefined,
      },

      filterInfo: {
        filters: undefined,
        filters4ViewEditor: undefined,
        filters4ViewEditorArchived: undefined,
        filters4ViewEditorAll: undefined,
        multiFilters: undefined,
        viewFilters: undefined,
      },

      overview: undefined,

      loading: {
        tableFormat: false, // 表头
        tableRows: false, // 表格内容
        dataStatistics: false, // 统计数据
        filters: false, // 单列条件过滤器
        currFilterAsyncList: false,
        multiFilters: false, // 多列条件过滤器
        viewFilters: false, // 视图过滤器
      },

      // ------------------------

      layout: DEFAULT_LAYOUT, // 各组件布局尺寸
      iconSize: 16,
      TABLE_PAGE_SIZES, // 可选的分页大小
      // defaultFilter: null, // 默认选中的过滤器
      currFilter: null,
      isFiltersSiderVisible: true, // 过滤器侧边栏是否显示
      isLastColShrink: false, // ”最后一列“（操作栏列）是否收起
      rowMultiSelection: [],
      rowSingleSelection: undefined,
      currView: this.defaultView as TypeView,
      historySelections: new HistorySelections(),
      treeHelper: new TreeHelper({
        childrenPropName: TREE_PROPS_CHILDREN,
        isCheckedPropName: TREE_PROPS_ISCHECKED,
      }),
      formRowFlag: "form-row",
      onRootClickBindThis: () => {},
      debounceGetTableDataAndSoOn: () => {},

      // ------------------------
      sideFilterBarShrinkBtnMode: "firstCol",
      operationColShrinkBtnMode: "lastCol",
      isColumnSettingVisible: false,
    };
  },
  computed: {
    allTabs(): { name: string; label: string }[] {
      return [...this.normalTabs, ...this.extraTabs];
    },
    // 在特殊标签下，本页是不显示任何内容的
    isContentVisible(): boolean {
      if (this.hasClassification && this.allTabs.length > 0)
        return this.normalTabs.map((t) => t.name).includes(this.currTabName);
      else return true;
    },
    flexibleTableHeight(): string {
      let other = this.layout.middleBarHeight;

      if (this.hasClassification) other += this.layout.classificationHeight;
      if (this.hasDataOverview) other += this.layout.dataOverviewBoardHeight;

      return `calc(100% - ${other}px)`;
    },
    // 已激活(没有被禁用)的过滤器
    activeFilters(): Filter<string>[] {
      if (this.filterInfo.filters)
        return this.filterInfo.filters.filter((f) => f.active);
      else return [];
    },
    dataStatisticsStr(): string {
      let str = "";
      if (this.tableInfo.dataStatistics) {
        for (let i = 0; i < this.tableInfo.dataStatistics.length; i++) {
          const dataSta = this.tableInfo.dataStatistics[i];
          if (i > 0) str += "，";
          str += dataSta.title;
          str += "：";
          str += dataSta.value;
        }
      }
      return str;
    },
    isLoadingFilters(): boolean {
      return (
        this.loading.filters ||
        this.loading.multiFilters ||
        this.loading.viewFilters ||
        this.loading.currFilterAsyncList
      );
    },
    // 获取列过滤器被设置过的列
    columnsWithFilterTarget(): Column[] {
      if (this.tableInfo.table) {
        return this.tableInfo.table.columns.filter((column) => {
          if (column instanceof Column) {
            if (
              !column.filter ||
              column.filter.target === undefined ||
              column.filter.target === null ||
              (Array.isArray(column.filter.target) &&
                column.filter.target.length === 0) ||
              // 如果 this.filterInfo.filters 中有 title 等于 column 的 title 的 filter，则不显示
              (this.filterInfo.filters &&
                this.filterInfo.filters.some(
                  (filter) =>
                    (column.filter?.type === "string-select" ||
                      column.filter?.type === "key-str-value-select") &&
                    filter.title === column.title
                ))
            ) {
              return false;
            } else {
              return true;
            }
          } else return false;
        }) as Column[];
      } else return [];
    },
  },
  watch: {
    moduleName: {
      immediate: false,
      handler() {
        this.refresh();
      },
    },
    defaultTabIndex: {
      immediate: true,
      handler(index: number) {
        const defaultTab = this.allTabs[index];
        if (defaultTab) {
          this.currTabName = defaultTab.name;
        }
      },
    },
  },
  async created() {
    bizTable = new BizTable(this.baseURL);
    bizDataOverview = new BizDataOverview(this.baseURL);
    bizFilter = new BizFilter(this.baseURL);

    // 读取上次保存在 localStorage 的默认页面大小
    const pageSize = Number(
      window.localStorage.getItem(STORAGE_PAGE_SIZE_NAME)
    );

    if (Number.isInteger(pageSize) && pageSize > 0) {
      // 如果是合法的值，则设置为当前页面大小
      if (TABLE_PAGE_SIZES.includes(pageSize as TypeTablePageSize)) {
        this.query.pageSize = pageSize as TypeTablePageSize;
      }
    }

    // 根据默认值设置过滤器侧边栏是否显示
    this.isFiltersSiderVisible = this.defaultSideFilterVisible;

    await this.init();
  },
  async beforeMount() {},
  mounted() {
    this.onRootClickBindThis = this.onRootClick.bind(this);
    window.document.body.addEventListener("click", this.onRootClickBindThis, {
      capture: true, // 使父元素的处理器在子元素的处理器之前被触发
    });

    this.debounceGetTableDataAndSoOn = debounce(() => {
      // 筛选条件有变化，页面重置到第一页
      this.query.currPage = 1;
      this.getTableDataAndAllAsyncColumnFiltersAndStat();
    }, DELAY_AFTER_SELECTION_LIST_CHANGE);
  },
  beforeDestroy() {
    window.document.body.removeEventListener("click", this.onRootClickBindThis);
  },
  methods: {
    // -------------------- 外部调用 --------------------

    doTableLayout() {
      (this.$refs["flexibleTable"] as any).doTableLayout();
    },

    // 刷新表格内容数据
    async refreshTableRows() {
      await this.getTableData();
    },

    // 重置当前页码到第一页，并刷新表格内容数据
    async resetCurrPageAndRefreshTableRows() {
      this.query.currPage = 1;
      await this.getTableData();
    },

    /**
     * 添加一行新的表单行
     * @param prevRow 行对象，表示在那一行下方添加新行
     * @param op 操作类型，"new" 表示新建，"copy" 表示复制
     * @param options 额外选项
     * @param options.submissionExceptionClassNames 鼠标点击应忽略的元素的类名
     * @returns 新的行对象
     */
    addFormRow(
      prevRow: IBaseTableData,
      op: "new" | "copy" = "new",
      options: {
        submissionExceptionClassNames?: string[];
      } = {}
    ): FormRow | void {
      const formRow = (this.$refs["flexibleTable"] as any).addFormRow(
        prevRow,
        op
      ) as FormRow | undefined;
      if (formRow && options.submissionExceptionClassNames) {
        formRow.$submissionExceptionClassNames =
          options.submissionExceptionClassNames;
      }
      return formRow;
    },

    /**
     * 添加一或多行新的普通数据行
     * @param prevRow 在哪一行下方添加新行(如果该行找不到，则新数据添加到第一行)
     * @param newRows 新的行对象数组
     */
    addNewRows(prevRow: IBaseTableData, newRows: IBaseTableData[]): void {
      if (this.tableInfo.table) {
        const _newRows = bizTable.rawRowsToTableDataConverter(
          newRows,
          this.tableInfo.table.columns
        );
        (this.$refs["flexibleTable"] as any).addNewRows(prevRow, _newRows);
      }
    },

    /**
     * 获取所有被多选列勾选的数据
     * @returns 所有被多选列勾选的数据
     */
    getRowMultiSelection(): IBaseTableData[] {
      return this.rowMultiSelection;
    },

    /**
     * 设置多选列的选中状态
     * @param rowIds 需要选中的行的id
     * @param selected 是否选中，默认为 true
     */
    setRowMultiSelection(rowIds: string[], selected: boolean = true): void {
      (this.$refs["flexibleTable"] as any).setRowMultiSelection(
        rowIds,
        selected
      );
    },

    /**
     * 获取被单选列选中的数据
     * @returns 被单选列选中的数据
     */
    getRowSingleSelection(): IBaseTableData | undefined {
      return this.rowSingleSelection;
    },

    /**
     * 设置单选列的选中状态
     * @param rowId 需要选中的行的id
     */
    setRowSingleSelection(rowId: string, selected: boolean = true): void {
      (this.$refs["flexibleTable"] as any).setRowSingleSelection(
        rowId,
        selected
      );
    },

    // -------------------- 外部调用 --------------------

    // #region 获取数据相关
    // 初始化数据
    async init(doTableLayout: boolean = false) {
      await this.getTableFormat();

      // 计算 getTableData() 的运行耗时
      // const start = new Date().getTime();
      // await this.getTableData();
      // const end = new Date().getTime();
      // console.log("getTableData() 耗时：", end - start);

      const pmGetTableData = this.getTableData();

      if (doTableLayout) {
        pmGetTableData.then(() => {
          this.$nextTick(() => {
            // 重新计算表格布局，主要因为表格 fixed 的列会发生错位
            this.doTableLayout();
          });
        });
      }

      if (this.hasDataStatistics) this.getDataStatistics();

      if (this.hasSideFilter) {
        await this.getFilters(); // 为提高加载效率，不强制要求后端返回 filter 的 list
        this.getMultiFilters();
        this.getViewFilters();
      }

      this.getAllAsyncColumnFilters();

      // this.getTableDataAndAllAsyncColumnFiltersAndStat();

      if (this.hasDataOverview) this.getOverview();
    },

    // 刷新所有数据
    async refresh() {
      this.currFilter = null;
      this.rowMultiSelection = [];
      this.rowSingleSelection = undefined;
      this.historySelections = new HistorySelections();

      // ----------------------------------

      this.currTabName = "";

      this.query.currPage = 1;
      this.query.textSearchVal = "";
      this.queryDataOverview.currPage = 1;

      this.dataOverviewTableInfo.data = undefined;
      this.dataOverviewTableInfo.totalCount = undefined;
      this.dataOverviewTableInfo.loading = false;

      this.tableInfo.table = undefined;
      this.tableInfo.sorts = [];
      this.tableInfo.dataStatistics = undefined;
      this.tableInfo.data = undefined;
      this.tableInfo.totalCount = undefined;

      this.filterInfo.filters = undefined;
      this.filterInfo.filters4ViewEditor = undefined;
      this.filterInfo.multiFilters = undefined;
      this.filterInfo.viewFilters = undefined;

      this.overview = undefined;

      // ----------------------------------

      await this.init(true);
    },

    // 获取或刷新表格数据，所有的异步列过滤器，以及（如果存在的话）数据统计，并重新计算表格布局
    async getTableDataAndAllAsyncColumnFiltersAndStat() {
      // this.loading.tableRows = true;

      if (this.hasDataStatistics) this.getDataStatistics();
      this.getAllAsyncColumnFilters();
      await this.getTableData();

      if (this.currView === "table") {
        await this.$nextTick();
        this.doTableLayout();
      }
    },

    // 获取表格格式
    async getTableFormat() {
      this.loading.tableFormat = true;

      const result = await bizTable.getTableFormat(
        this.moduleName,
        this.currTabName,
        this.geneParamsForGetTableFormat(this.tableInfo.table)
      );

      this.tableInfo.table = result;

      // 如果被设置为本地记录操作按钮列的位置，则读取上次保存在 localStorage 的操作按钮列的位置
      if (this.isOperationBtnsPositionLocal) {
        const operationBtnPosition =
          (window.localStorage.getItem(
            STORAGE_OPERATION_BTN_POSITION_NAME
          ) as OptBtnsPositionType) || null;

        const optBtnsPositionValues: OptBtnsPositionType[] = [
          "last-col",
          "auto-hide",
        ];

        if (optBtnsPositionValues.includes(operationBtnPosition)) {
          this.tableInfo.table.optBtnsPosition = operationBtnPosition;
          this.tableInfo.table.optBtnsTarget = undefined;
        }
      }

      this.loading.tableFormat = false;
    },

    // 获取所有需要异步加载的列的过滤器
    async getAllAsyncColumnFilters() {
      if (this.tableInfo.table) {
        // 获取所有可过滤并需异步加载过滤条件的列对象
        const filterAsyncColumns = this.tableInfo.table.columns
          .filter((col) => col instanceof Column)
          .filter(
            (col) => (col as Column).filterable && (col as Column).filterAsync
          ) as Column[];

        for (const fCol of filterAsyncColumns) {
          fCol.isColumnFilterLoading = true;

          const params: ParamsForGetColumnFilter = {
            ...this.geneParamsForGetTableFormat(this.tableInfo.table),
            column: fCol,
          };

          const newColFilter = await bizTable.getColumnFilterByColumn(
            this.moduleName,
            this.currTabName,
            params
          );

          // await new Promise((r) => setTimeout(r, 3000));

          if (newColFilter === undefined || newColFilter === null) {
            if (process.env.NODE_ENV === "development") {
              console.warn(
                `异步加载表头筛选器的时候后端返回了 ${newColFilter}，表头对象为：`,
                fCol
              );
            }
          }

          if (fCol.filter && newColFilter) {
            newColFilter.target = fCol.filter.target;
          }

          fCol.setFilter(newColFilter);

          // setTimeout(() => {
          fCol.isColumnFilterLoading = false;
          // }, 2000);
        }
      }
    },

    // 获取表格业务数据
    async getTableData() {
      this.loading.tableRows = true;

      if (this.tableInfo.table) {
        const result = await bizTable.getTableRows(
          this.moduleName,
          this.currTabName,
          this.geneParamsForGetTableRows(this.tableInfo.table)
        );

        // await new Promise((r) => setTimeout(r, 300000));

        this.tableInfo.data = result.records;
        this.tableInfo.totalCount = result.total;

        // // 以下代码是为了测试表格
        // this.tableInfo.data = [];
        // this.tableInfo.totalCount = 0;
      }

      this.loading.tableRows = false;
    },

    // 获取数据统计集
    async getDataStatistics() {
      if (this.tableInfo.table) {
        this.loading.dataStatistics = true;

        const result = await bizTable.getDataStatistics(
          this.moduleName,
          this.currTabName,
          this.geneParamsForGetTableRows(this.tableInfo.table)
        );

        this.tableInfo.dataStatistics = result;

        this.loading.dataStatistics = false;
      }
    },

    // 获取数据概览的数据
    async getOverview() {
      const result = await bizDataOverview.getOverviewDetails(
        this.moduleName,
        this.currTabName
      );

      this.overview = result;
    },

    // 获取筛选栏下拉框中的所有单列条件筛选项(为提高加载效率，不强制要求后端返回 filter 的 list)
    async getFilters() {
      this.loading.filters = true;

      const filters = await bizFilter.getFilters(
        this.moduleName,
        this.currTabName,
        false // 为提高加载效率，不强制要求后端返回 filter 的 list
      );

      // 未归档的单列条件过滤器(含已激活和未激活的)
      const filtersUnarchived = filters.filter((f) => !f.archived);

      this.filterInfo.filters = filtersUnarchived;

      if (this.defaultFilterIndex >= 0 && this.activeFilters.length > 0) {
        // 找出默认选中的过滤器
        const defaultFilter = filters[this.defaultFilterIndex];
        if (defaultFilter) {
          await this.getAsyncFilterList(defaultFilter);
          this.currFilter = defaultFilter;

          this.initHistorySelections();
        }
      }

      this.loading.filters = false;
    },

    // 获取视图编辑器的过滤器
    async getFilters4ViewEditor() {
      const filters = await bizFilter.getFilters(
        this.moduleName,
        this.currTabName,
        true
      );
      // 未归档的单列条件过滤器(含已激活和未激活的)
      const filters4ViewEditor = filters.filter((f) => !f.archived);
      // 已归档的单列条件过滤器(含已激活和未激活的)
      const filters4ViewEditorArchived = filters.filter((f) => f.archived);

      this.filterInfo.filters4ViewEditor = filters4ViewEditor;
      this.filterInfo.filters4ViewEditorArchived = filters4ViewEditorArchived;
      this.filterInfo.filters4ViewEditorAll = filters;
    },

    // 检查过滤器是否异步加载过滤器选择项列表，是的话异步加载
    async getAsyncFilterList(filter: Filter | MultiFilter): Promise<boolean> {
      if (
        filter instanceof Filter &&
        filter.listAsync &&
        filter.filterListLoadedCount === 0 // 是否已加载过(目前的逻辑是只读取一次)
      ) {
        this.loading.currFilterAsyncList = true;
        filter.isFilterListLoading = true;

        // await new Promise((r) => setTimeout(r, 3000));

        const list = await bizFilter.getFilterListByFilter(
          this.moduleName,
          this.currTabName,
          filter
        );
        filter.list = list;

        filter.filterListLoadedCount++;
        filter.isFilterListLoading = false;
        this.loading.currFilterAsyncList = false;
        return true;
      } else {
        return false;
      }
    },

    // 获取筛选栏下拉框中的所有多列条件筛选项
    async getMultiFilters() {
      this.loading.multiFilters = true;

      this.filterInfo.multiFilters = await bizFilter.getMultiFilters(
        this.moduleName,
        this.currTabName
      );

      this.loading.multiFilters = false;
    },

    // 获取筛选栏下拉框中的所有视图筛选项
    async getViewFilters() {
      this.loading.viewFilters = true;

      this.filterInfo.viewFilters = await bizFilter.getViewFilters(
        this.moduleName,
        this.currTabName
      );

      this.loading.viewFilters = false;
    },

    // 懒加载树状表格子节点数据
    async lazyLoadTableSubRows(
      row: IBaseTableData,
      treeNode: any
    ): Promise<IBaseTableData[]> {
      this.loading.tableRows = true;

      const { filterMode, filterListItems } = this.getFilterModeAndListItems(
        this.historySelections
      );

      const result = await bizTable.getTableSubRows(
        this.moduleName,
        this.currTabName,
        { id: row.id!, $level: row.$level, $parentId: row.$id },
        {
          columns: this.tableInfo.table!.columns,
          sorts: this.tableInfo.sorts,
          filterMode,
          filterListItems,
        }
      );

      // resolve(result);

      // 后端如果返回的id和父节点一致，el-table 会报错，故下面代码用于测试
      // for (let i = 0; i < result.length; i++) {
      //   const obj = result[i];
      //   if (obj.id) obj.id += i;
      // }

      this.loading.tableRows = false;

      return result;
    },

    // 表格中的下拉框单元格 获取下拉列表数据的回调
    async getSelectableCellDropdownData(
      row: IBaseTableData | null | undefined,
      column: Column<IBaseTableData>,
      resolve: (data: object[]) => void
    ) {
      const list = await bizTable.getCellSelectList(
        this.moduleName,
        this.currTabName,
        {
          rowId: row && row.id ? row.id : undefined,
          columnId: column.id,
        }
      );

      resolve(list);
    },

    // -----------------------------------------------------------------

    // 内部使用
    geneParamsForGetTableFormat(
      table?: Table<IBaseTableData>
    ): ParamsForGetTableFormat {
      const textSearch = this.query.textSearchVal || undefined;
      const columns = table?.columns;

      const { filterMode, filterListItems } = this.getFilterModeAndListItems(
        this.historySelections
      );

      return {
        searchText: textSearch,
        columns,
        filterMode,
        filterListItems,
      };
    },

    // 内部使用
    geneParamsForGetTableRows(
      table: Table<IBaseTableData>
    ): ParamsForGetTableRows {
      const textSearch = this.query.textSearchVal || undefined;

      const { filterMode, filterListItems } = this.getFilterModeAndListItems(
        this.historySelections
      );

      return {
        page: this.query.currPage,
        size: this.query.pageSize,
        searchText: textSearch,
        columns: table.columns,
        sorts: this.tableInfo.sorts,
        filterMode,
        filterListItems,
      };
    },

    // -----------------------------------------------------------------
    // #endregion 获取数据相关

    // 当本模块的标签栏(tabs)的标签被点击时
    onClassificationClick(tabName: string) {
      this.query.currPage = 1;
      this.getTableData();
    },

    // 当筛选器选择栏的标签被关闭时
    onFilterConditionClose(
      type: "FilterSelection" | "ColumnFilter",
      data: any
    ) {
      if (type === "FilterSelection") {
        const { filter, oneDTreeNodes, index } = data as {
          filter: FilterType;
          oneDTreeNodes: ITreeNode[];
          index: number;
        };

        // 要同时去除对应的“表头列筛选器”项目
        if (filter instanceof Filter && this.tableInfo.table) {
          this.tableInfo.table.columns.forEach((column) => {
            // 如果同名
            if (
              column instanceof Column &&
              column.title === filter.title &&
              column.filter &&
              (column.filter.type === "string-select" ||
                column.filter.type === "key-str-value-select") &&
              Array.isArray(column.filter.target)
            ) {
              const columnFilterTarget = column.filter.target as string[];
              const idIndex = columnFilterTarget.indexOf(oneDTreeNodes[0].id);
              if (idIndex > -1) columnFilterTarget.splice(idIndex, 1);
            }
          });
        }
      }

      // 当筛选条件有变化，页面重置到第一页
      this.query.currPage = 1;
      this.getTableDataAndAllAsyncColumnFiltersAndStat();
    },

    // 当筛选器选择栏的筛选条件被清空时
    onFilterConditionsClear(
      type: "FilterSelection" | "ColumnFilter",
      data: any
    ) {
      if (type === "FilterSelection") {
        const { filter, twoDTreeNodes } = data as {
          filter: FilterType;
          twoDTreeNodes: ITreeNode[][];
        };

        // 要同时去除对应的“表头列筛选器”项目
        if (filter instanceof Filter && this.tableInfo.table) {
          this.tableInfo.table.columns.forEach((column) => {
            // 如果同名
            if (
              column instanceof Column &&
              column.title === filter.title &&
              column.filter &&
              (column.filter.type === "string-select" ||
                column.filter.type === "key-str-value-select") &&
              Array.isArray(column.filter.target)
            ) {
              column.filter.target.splice(0, column.filter.target.length);
            }
          });
        }
      }

      // 当筛选条件有变化，页面重置到第一页
      this.query.currPage = 1;
      this.getTableDataAndAllAsyncColumnFiltersAndStat();
    },

    // #region 表格事件
    // 当表格的可点击单元格被点击时
    onBodyCellClick(row: any, col: Column<IBaseTableData>) {
      // console.log("row, col :>> ", row, col);
      this.$emit("cell-click", row, col);
    },

    // 表格的当前页码被改变
    onCurrPageChange(page: number) {
      this.getTableData();
      if (this.hasDataStatistics) this.getDataStatistics();
    },

    // 表格当前页大小(条数)被改变
    onPageSizeChange(size: number) {
      window.localStorage.setItem(STORAGE_PAGE_SIZE_NAME, String(size));

      this.getTableData();
      if (this.hasDataStatistics) this.getDataStatistics();
    },

    // 当任意一列的排序要求有变
    onSortChange(sort: Sort) {
      this.getTableData();
    },

    // 当搜索框有改变并确认时
    async onTextSearch(text: string) {
      this.$emit("text-search", text);
      this.getTableDataAndAllAsyncColumnFiltersAndStat();
    },

    // 当表格中的图标列的单元格被点击时
    // 如果有内置icon按钮列，col 为普通列并且 insideBtnCol 为该内置按钮列，否则 col 为按钮列
    async onColumnButtonClick(
      row: IBaseTableData,
      col: Column | ButtonColumn,
      insideBtnCol?: ButtonColumn
    ) {
      this.$emit("column-button-click", row, col, insideBtnCol);
    },

    // 当表格的单元格右上角的提示性按钮被点击时
    onBodyCellCornerTipClick(row: any, col: Column) {
      this.$emit("cell-corner-tip-click", row, col);
    },

    // 表格操作列中的某个按钮被点击(仅用于测试，因为这些按钮是父组件自定义的，不是表格的原生按钮)
    onOperationBtnClick(row: any, name: string) {},

    // 当操作列的隐藏按钮被点击时
    onBtnOperationColShrinkClick() {
      this.isLastColShrink = !this.isLastColShrink;
    },

    // 当多选列的选中状态被改变时
    onRowMultiSelect(selection: IBaseTableData[]) {
      this.rowMultiSelection = selection;
      this.$emit("row-multi-select", selection);
    },

    // 当单选列的选中状态被改变时
    onRowSingleSelect(row: IBaseTableData) {
      this.rowSingleSelection = row;
      this.$emit("row-single-select", row);
    },

    // 当需要导出表格数据时
    async onExportData() {
      if (this.tableInfo.table) {
        const { fileName, url } = await bizTable.getDownloadDataUrl(
          this.moduleName,
          this.currTabName,
          this.geneParamsForGetTableRows(this.tableInfo.table)
        );

        DownloadHelper.downloadByAnchorTag(url, fileName);
      }
    },
    // #endregion 表格事件

    // #region 列的筛选器事件
    // 当列的筛选器的确认按钮被点击时
    onColumnFilterSubmit(column: Column<IBaseTableData>) {},

    // 当列的筛选器的取消按钮被点击时
    onColumnFilterCancel(column: Column<IBaseTableData>) {},

    // 当筛选器有变化时
    onColumnFilterChange(column: Column<IBaseTableData>) {
      (this.$refs["flexibleTable"] as any).$onTableColumnFilterChange(column);

      // 当筛选器有变化，页面重置到第一页
      this.query.currPage = 1;
      this.getTableDataAndAllAsyncColumnFiltersAndStat();
    },
    // #endregion 列的筛选器事件

    // #region 筛选栏事件
    // 数据筛选栏的显隐按钮被点击时
    onMidBarBtnShrinkClick() {
      this.isFiltersSiderVisible = !this.isFiltersSiderVisible;
    },

    // 当筛选栏切换筛选器时
    async onSelectionFilterChange(filter: Filter | MultiFilter | "view") {
      if (filter !== "view") {
        await this.getAsyncFilterList(filter);
      }
      this.currFilter = filter;
    },

    // 当筛选侧栏的当前筛选器的筛选项被选中或取消时
    async onSelectionListCheckboxChange(
      currChecked: ITreeNode[][],
      target: ITreeNode,
      isChecked: boolean
    ) {
      // this.filterInfo.currChecked = currChecked;

      // 同步到 this.tableInfo.table.columns 的 title 与当前 currFilter 的 title 相同的那一列的 filter.target
      this.syncSelectionListChangeToTableColumns(currChecked.map((c) => c[0]));

      this.$emit("selection-list-change", currChecked, target, isChecked);

      // // 筛选条件有变化，页面重置到第一页
      // this.query.currPage = 1;
      // this.getTableDataAndAllAsyncColumnFiltersAndStat();

      this.debounceGetTableDataAndSoOn();
    },

    onSelectionListSingleClick(currChecked: ITreeNode[][], target: ITreeNode) {
      this.syncSelectionListChangeToTableColumns(currChecked.map((c) => c[0]));

      this.$emit("selection-list-change", currChecked, target, true);

      // 筛选条件有变化，页面重置到第一页
      this.query.currPage = 1;
      this.getTableDataAndAllAsyncColumnFiltersAndStat();
    },

    // 当视图编辑器弹窗显示时
    async onViewEditorShow() {
      if (!this.filterInfo.filters4ViewEditor) {
        this.getFilters4ViewEditor();
      }
    },

    onViewEditorClose() {
      if (this.currFilter instanceof Filter) {
        if (this.activeFilters.length === 0) {
          this.currFilter = null;
        }
      }
    },
    // #endregion 筛选栏事件

    // #region 数据概览事件
    // 当“数据概览”的“设置”弹窗弹出时
    async onDialogSettingShow() {},

    // 当“数据概览”的“设置”窗口的“默认展开/关闭”改变时
    async onDefaultFoldChange(fold: boolean) {},

    // 当“数据概览”的“设置”窗口的“概览配置”改变时
    async onItemActiveChange(items: DataOverviewItem[]) {},

    // 当“数据概览”的“设置”窗口的数据有改动并确认时
    async onSettingChange(data: {
      defaultFold: boolean;
      allItems: DataOverviewItem[];
    }) {
      const { defaultFold, allItems } = data;
      const result = await bizDataOverview.updateSettings(
        this.moduleName,
        this.currTabName,
        {
          defaultFold,
          allItems,
        }
      );
    },

    // 当“数据概览”的时间段控件有改变时
    onDateFrameChange(dateFrame?: [Date, Date]) {
      this.getOverview();
    },

    // 当“数据概览”的某一概览项被点击时
    async onOverviewUnitClick(activeItem: DataOverviewItem) {
      if (this.tableInfo.table) {
        this.dataOverviewTableInfo.loading = true;

        const pageResult = await bizTable.getTableRows(
          this.moduleName,
          this.currTabName,
          {
            page: this.queryDataOverview.currPage,
            size: this.queryDataOverview.pageSize,
            overviewItemId: activeItem.id,
            columns: this.tableInfo.table.normalColumns,
            // filters: [],
            sorts: [],
            filterMode: "none",
            filterListItems: undefined,
          }
        );

        this.dataOverviewTableInfo.data = pageResult.records;
        this.dataOverviewTableInfo.totalCount = pageResult.total;

        this.dataOverviewTableInfo.loading = false;
      }
    },
    // #endregion 数据概览事件

    // #region 列属性设置侧边抽屉事件

    // 当列属性设置侧边抽屉的“显示”按钮被点击时
    onColumnsSettingBtnClick() {
      this.isColumnSettingVisible = true;
    },

    // 当列的排序要求被改变时
    async onColumnSort(
      oldIndex: number,
      newIndex: number,
      columnsCopy: Column<IBaseTableData>[]
    ) {
      if (this.tableInfo.table && this.tableInfo.table.columns) {
        // const columnIds = this.tableInfo.table.columns.map((col) => col.id);
        const columnIds = columnsCopy.map((col) => col.id);

        const result = await bizTable.updateColumnOrder(
          this.moduleName,
          this.currTabName,
          columnIds
        );
      }
    },

    // 当列的激活状态被改变时
    async onColumnActiveChange(col: BaseColumn) {
      const result = await bizTable.updateColumnActive(
        this.moduleName,
        this.currTabName,
        [col]
      );
    },

    // 当操作栏的位置被更改时
    async onOptBtnsModify(table: Table<IBaseTableData>) {
      if (this.isOperationBtnsPositionLocal) {
        window.localStorage.setItem(
          STORAGE_OPERATION_BTN_POSITION_NAME,
          table.optBtnsPosition
        );
      }

      await bizTable.updateOperationBtnsPosition(
        this.moduleName,
        this.currTabName,
        {
          optBtnsPosition: table.optBtnsPosition,
          optBtnsTarget: table.optBtnsTarget,
        }
      );
    },

    // 当数据统计项的激活状态被改变时
    async onStatisticActiveChange(statistic: DataStatistic) {
      // 更新统计数据项的激活状态(如果 active 为 true，则把 value 的当前值查询出来，供前端更新)
      const result = (await bizTable.updateDataStatisticsActive(
        this.moduleName,
        this.currTabName,
        [statistic]
      )) as unknown as DataStatistic[];

      if (result[0]?.active) {
        const statistic = this.tableInfo.dataStatistics?.find(
          (s) => s.id === result[0].id
        );

        if (
          statistic &&
          result[0].value !== undefined &&
          result[0].value !== null
        ) {
          statistic.value = result[0].value;
        } else {
          console.error(
            "更新数据统计项的激活状态时，当前统计项的 active 为 true，但后端返回的 id 无法在前端找到对应的统计项或者 value 为 undefined/null 令前端无法更新"
          );
        }
      }
    },

    async onColumnCellAlignChange(col: Column<IBaseTableData>) {
      const result = await bizTable.updateColumnCellAlign(
        this.moduleName,
        this.currTabName,
        [col]
      );
    },

    // #endregion 列属性设置侧边抽屉事件

    // #region 视图编辑器事件
    // 当单列条件筛选器激活或者取消激活时
    async onSingleFilterSwitch(filter: Filter, val: boolean) {
      const result = await bizFilter.updateFilterActive(
        this.moduleName,
        this.currTabName,
        filter
      );

      this.getFilters();
    },

    // 当添加一个多列条件筛选器时
    async onAddNewMultipleFilter(multiFilter: MultiFilter) {
      const result = await bizFilter.createOrUpdateMultiFilter(
        this.moduleName,
        this.currTabName,
        multiFilter
      );
      multiFilter.id = result.id;
      // this.$message.success(`${multiFilter.title} 添加成功`);
    },

    async onEditTitleMultipleFilter(multiFilter: MultiFilter) {
      const result = await bizFilter.createOrUpdateMultiFilter(
        this.moduleName,
        this.currTabName,
        multiFilter
      );
    },

    async onEditFilterMultipleFilter(multiFilter: MultiFilter) {
      const result = await bizFilter.createOrUpdateMultiFilter(
        this.moduleName,
        this.currTabName,
        multiFilter
      );
    },

    // 当添加一个视图筛选器时
    async onAddNewViewFilter(viewFilter: ViewFilter) {
      const result = await bizFilter.createOrUpdateViewFilter(
        this.moduleName,
        this.currTabName,
        viewFilter
      );
      viewFilter.id = result.id;
    },

    // 当修改一个视图筛选器时
    async onEditTitleViewFilter(viewFilter: ViewFilter) {
      const result = await bizFilter.createOrUpdateViewFilter(
        this.moduleName,
        this.currTabName,
        viewFilter
      );
    },

    async onEditFilterViewFilter(viewFilter: ViewFilter, node: any) {
      const result = await bizFilter.createOrUpdateViewFilter(
        this.moduleName,
        this.currTabName,
        viewFilter
      );
    },

    // 当删除一个多条件筛选器时
    async onDelMultipleFilter(multiFilter: MultiFilter) {
      const result = await bizFilter.deleteMultiFilter(
        this.moduleName,
        this.currTabName,
        multiFilter.id
      );
    },

    // 当删除一个视图筛选器时
    async onDelViewFilter(viewFilter: ViewFilter) {
      const result = await bizFilter.deleteViewFilter(
        this.moduleName,
        this.currTabName,
        viewFilter.id
      );
    },

    // 当视图编辑器中的单列条件选择的删除(归档)按钮被点击时
    async onSingleFilterArchive(filter: Filter) {
      const result = await bizFilter.updateFilterArchive(
        this.moduleName,
        this.currTabName,
        [filter]
      );

      this.getFilters();
    },

    // 当单列条件选择器编辑弹窗的确认按钮被点击时
    async onSingleConditionsEditorSubmit(
      filtersUnarchived: Filter[],
      callback: () => void
    ) {
      const result = await bizFilter.updateFilterArchive(
        this.moduleName,
        this.currTabName,
        filtersUnarchived
      );

      callback();

      this.getFilters();
    },
    // #endregion 视图编辑器事件

    // #region 表格内联表单行相关

    // 当表格行中的单元格被编辑时
    async onFormCellInput(row: FormRow, col: TFormColumn, val: any) {
      if (this.formCellUpdateType !== "whenInput") return;

      if (row.isCreated) {
        // 修改。
        // 必填项有非空值，就可以修改
        // 非必填可以随意修改

        const isValidWhenRequired =
          col.required && val !== undefined && val !== null && val !== "";

        const isValidWhenNotRequired = !col.required;

        if (isValidWhenRequired || isValidWhenNotRequired) {
          const colAndVals = [{ column: col, cellValue: val }];

          await this.updateRowCells(row, colAndVals);
        }
      } else {
        // 创建。必须至少所有必填项都有值，才能创建
        if (row.isRequiredCellsValueReady) {
          await this.createRowCells(row);
        }
      }
    },

    // 当整个页面被点击时
    onRootClick(event: MouseEvent) {
      // 检查是否有文本被选择
      const selectedText = window.getSelection()?.toString();
      if (selectedText && selectedText.length > 0) {
        // 这是一个文本选择事件
        // 用户是选择文本，不是想提交表单
      } else {
        // 检查当前所有表单行的 $submissionExceptionClassNames，看看是否有需要排除的元素或其子元素被点击了
        const formRows = this.getCurrFormRows();
        // 先排除重复的类名
        const submissionExceptionClassNames = Array.from(
          new Set(
            formRows
              .map((row) => row.$submissionExceptionClassNames)
              .flat()
              .filter((name) => name)
          )
        );
        // 然后检查点击的元素是否是这些类名的元素或其子元素
        const isClickOnExceptionElement = submissionExceptionClassNames.some(
          (name) => {
            const element = (event.target as Element)?.closest(`.${name}`);
            return !!element;
          }
        );

        if (isClickOnExceptionElement) {
          // 点击的元素是表单行排除元素类名对应的元素或其子元素
          // 视作等同于用户点击了表单行内的元素，不是想提交表单
        } else {
          // 点击的元素不是表单行排除元素类名对应的元素或其子元素
          // 检查点击的元素是否是表格行
          const tableRow = (event.target as Element)?.closest(".el-table__row");
          if (tableRow) {
            // 是表格行
            const isFormRow = tableRow.classList.contains(this.formRowFlag);
            if (!isFormRow) {
              // 鼠标在表格数据行(不含表单行)里面点击了
              this.onNonFormRowClick();
            }
          } else {
            // 不是表格行
            // 鼠标在表格行(含数据行和表单行)外面点击了
            this.onNonFormRowClick();
          }
        }
      }
    },

    // 当鼠标在表格表单行外面点击时(提交表单行数据)
    async onNonFormRowClick() {
      if (this.formCellUpdateType !== "whenBlur") return;

      const formRows = this.getCurrFormRows();

      for (const formRow of formRows) {
        if (formRow.isRequiredCellsValueReady) {
          if (formRow.isCreated) {
            // 修改。
            // 必填项有非空值，就可以修改
            // 非必填可以随意修改

            const colAndVals = formRow.getAllCellsData();

            if (colAndVals.length > 0) {
              await this.updateRowCells(formRow, colAndVals);
              this.switchFormRowToIBaseTableData(
                formRow,
                formRow.toIBaseTableData()
              );
            }
          } else {
            // 创建。必须至少所有必填项都有值，才能创建
            const newRow = await this.createRowCells(formRow);
            this.switchFormRowToIBaseTableData(formRow, newRow);
          }
        } else {
          console.log("必填项未填写完毕");
        }
      }
    },

    // 创建新行发送后端
    async createRowCells(row: FormRow): Promise<IBaseTableData> {
      if (this.tableInfo.table) {
        const newRow = await bizTable.createOrUpdateRowCells(
          this.moduleName,
          this.currTabName,
          undefined,
          row.getNonEmptyCellsData(),
          this.tableInfo.table.columns
        );

        if (!newRow.id) {
          console.error("通用表格创建新行时，后端返回的 id 为空");
        }

        return newRow;
      } else {
        throw new Error("通用表格创建新行时，表格信息为空");
      }
    },

    // 更新行发送后端(row.id 必须有值，即 row.isCreated 为 true)
    async updateRowCells(
      row: FormRow,
      colAndVals: { column: TFormColumn; cellValue: any }[]
    ) {
      if (this.tableInfo.table) {
        const result = await bizTable.createOrUpdateRowCells(
          this.moduleName,
          this.currTabName,
          row.id,
          colAndVals,
          this.tableInfo.table.columns
        );
      } else {
        throw new Error("通用表格更新行时，表格信息为空");
      }
    },

    // 将表单行切换为普通行
    switchFormRowToIBaseTableData(formRow: FormRow, row: IBaseTableData) {
      if (this.tableInfo.data) {
        const index = this.tableInfo.data.indexOf(formRow);
        if (index >= 0) {
          this.tableInfo.data.splice(index, 1, row);
        }
      }
    },

    // 获取当前表格中的所有表单行
    getCurrFormRows() {
      if (this.tableInfo.data) {
        return this.tableInfo.data.filter(
          (row) => row instanceof FormRow
        ) as FormRow[];
      } else {
        return [];
      }
    },

    // #endregion 表格内联表单行相关

    // 根据 filter 的类型返回对应的模式
    getFilterMode(filter: Filter | MultiFilter | "view" | null) {
      if (filter instanceof Filter) {
        return "single";
      } else if (filter instanceof MultiFilter) {
        return "multiple";
      } else if (filter === "view") {
        return "view";
      } else {
        return "none";
      }
    },

    // 根据当前选中的筛选器返回对应的模式和列表项
    getFilterModeAndListItems(historySelections: HistorySelections): {
      filterMode: "single" | "multiple" | "view" | "none";
      filterListItems: ITreeNode[][] | undefined;
    } {
      const selection = historySelections.selections[0];

      if (selection) {
        const filterMode = this.getFilterMode(selection.filter);
        const filterListItems = selection.twoDTreeNodes;

        return { filterMode, filterListItems };
      } else {
        return { filterMode: "none", filterListItems: undefined };
      }
    },

    // 根据当前加载的过滤器数据，初始化历史选择项
    initHistorySelections() {
      if (this.currFilter && this.currFilter instanceof Filter) {
        if (this.currFilter.list && this.currFilter.list.length > 0) {
          const items = this.treeHelper.recursionGetCheckedWithAncestor(
            this.currFilter.list
          );
          if (items && Array.isArray(items)) {
            // items(any[][]) 转换为 TreeNode[][] 类型，即二维数组转化为另一种类型的二维数组
            const treeNodesArr = items.map((subArr) => {
              // 倒序遍历，因为后面的数据是父节点，前面的数据是子节点
              const reverseSubArr = subArr.slice().reverse();

              let parent: TreeNode | undefined = undefined;
              const reverseTreeNodes = reverseSubArr.map((item) => {
                const tn = new TreeNode({
                  ...item,
                  attachment: {
                    filter: this.currFilter,
                    dataCount: item.dataCount,
                  },
                  $parent: parent, // 用于生成 TreeNode 的 $id，因为 FilterDetail.vue 的 syncCurrTreeByHistorySelections 方法中会用到 $id
                });

                parent = tn;

                return tn;
              });

              const treeNodes = reverseTreeNodes.slice().reverse();
              return treeNodes;
            });

            this.historySelections.setTwoDTreeNodes(
              this.currFilter,
              treeNodesArr
            );
          }
        }
      }
    },

    // 同步到 this.tableInfo.table.columns 的 title 与当前 currFilter 的 title 相同的那一列的 filter.target
    syncSelectionListChangeToTableColumns(
      currChecked: ITreeNode[]
      // target: ITreeNode,
      // isChecked: boolean
    ) {
      if (this.currFilter instanceof Filter) {
        const currFilterTitle = this.currFilter.title;
        const columns = this.tableInfo.table?.columns.filter((col) => {
          return (
            col instanceof Column &&
            (col.filter?.type === "string-select" ||
              col.filter?.type === "key-str-value-select") &&
            col.title === currFilterTitle
          );
        });

        if (!columns) return;
        // if (target === null) return;

        for (const column of columns) {
          if (column instanceof Column && column.filter) {
            if (!Array.isArray(column.filter.target)) {
              column.filter.target = [];
            }

            const columnFilterTarget = column.filter.target as string[];

            columnFilterTarget.splice(
              0,
              columnFilterTarget.length,
              ...currChecked.map((c) => c.id)
            );

            // if (isChecked) {
            //   if (target.id !== ROOT_TREE_NODE_TEMPLATE.id) {
            //     columnFilterTarget.push(target.id);
            //   }
            // } else {
            //   const idIndex = columnFilterTarget.indexOf(target.id);
            //   if (idIndex > -1) columnFilterTarget.splice(idIndex, 1);
            // }
          }
        }
      }
    },
  },
});
</script>

<style lang="scss" scoped>
@import "src/flexible-table-module/scss/_variables.scss";

.flexible-table-core {
  width: 100%;
  height: 100%;
  overflow-y: auto;
  background-color: $color-reverse;

  .text-search {
  }

  .midbar {
    display: inline-flex;
    justify-content: flex-start;
    align-items: center;
  }

  .view-switch {
  }

  .col-setting {
  }

  .clickable + .clickable {
    margin-left: 10px;
  }

  .shrink-btn {
    &.inline {
      // display: inline-block;
      float: right;
      margin-right: 8px;
    }

    &.in-mid-bar {
      margin-left: 12px;
    }
  }

  .data-sta-view {
    // text-overflow: ellipsis;
    // white-space: nowrap;
    // overflow: hidden;
  }
}
</style>
