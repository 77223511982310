<template>
  <ElTooltip
    class="shorten-tooltip"
    :content="content"
    :placement="placement"
    :popper-class="popperClass"
  >
    <template v-slot:content>
      <span v-text="content"></span>
    </template>

    <slot></slot>
  </ElTooltip>
</template>
<script lang="ts">
import Vue from "vue";
import { Tooltip as ElTooltip } from "element-ui";

export default Vue.extend({
  inheritAttrs: false,
  name: "ShortenTooltip",
  components: { ElTooltip },
  props: {
    content: { type: String, default: "" }, // 提示文字
    placement: { type: String, default: "top" }, // 提示文字的气泡弹出方向
    popperClass: { type: String }, // 为 Tooltip 的 popper 添加类名
  },
});
</script>