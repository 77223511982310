<template>
  <div
    class="floating-dialog"
    :class="{ expanded, shrinked: !expanded }"
    ref="dialog"
    v-click-outside="onClickOutside"
  >
    <div
      ref="dialogBody"
      class="floating-dialog__body"
      :class="{ expanded, shrinked: !expanded }"
      :style="innerStyle"
    >
      <div class="floating-dialog__body-inner">
        <div
          class="floating-dialog__container"
          :class="{ expanded, shrinked: !expanded }"
          @click="onAreaShrinkedClick"
        >
          <slot></slot>
        </div>
        <template v-if="expanded">
          <slot name="btn-expanded"></slot>
        </template>
      </div>
    </div>
  </div>
</template>
<script lang="ts">
import Vue from "vue";
// @ts-ignore
import vClickOutside from "v-click-outside";
import { ResizeObserverWrapper } from "@/flexible-table-module/util/ResizeObserverWrapper";
import { DomResizingHelper } from "@/flexible-table-module/util/DomResizingHelper";
import { DomDraggingHelper } from "@/flexible-table-module/util/DomDraggingHelper";

export default Vue.extend({
  name: "FloatingDialog",
  directives: {
    clickOutside: vClickOutside.directive,
  },
  props: {
    // 是否展开
    isExpanded: { type: Boolean, default: false },
    // 是否可通过鼠标点击来展开或收缩
    isClickable: { type: Boolean, default: true },
  },
  data: () => ({
    dExpanded: false,
    reszObsvWrapper: null as ResizeObserverWrapper | null,
    innerStyle: {}, // 用于设置 dialogBody 的样式
    domResizingHelper: null as DomResizingHelper | null,
    domDraggingHelper: null as DomDraggingHelper | null,
    isResizing: false,
  }),
  computed: {
    expanded: {
      get(): boolean {
        return this.dExpanded;
      },
      set(val: boolean) {
        this.dExpanded = val;
        this.$emit("update:isExpanded", val);
      },
    },
  },
  watch: {
    isExpanded: {
      immediate: true,
      handler(val) {
        this.expanded = val;
      },
    },
    expanded(expanded: boolean) {
      this.innerStyle = this.getInnerStyle();

      this.$nextTick(() => {
        if (this.domResizingHelper) this.domResizingHelper.disabled = !expanded;
        if (this.domDraggingHelper) this.domDraggingHelper.disabled = !expanded;
      });
    },
    "domResizingHelper.isResizing": {
      async handler(isResizing: boolean) {
        if (isResizing) this.isResizing = true;
        else {
          // await this.$nextTick();
          // 等待一秒
          await new Promise((resolve) => setTimeout(resolve, 500));
          this.isResizing = false;
        }
      },
    },
  },
  mounted() {
    const dialog = this.$refs.dialog as HTMLElement;
    this.reszObsvWrapper = new ResizeObserverWrapper(dialog);
    this.reszObsvWrapper.afterResized((_dialog) => {
      this.innerStyle = this.getInnerStyle();
      this.$emit("resize", dialog.getBoundingClientRect(), this.expanded);
    });

    // --------------------------------

    const dialogBody = this.$refs.dialogBody as HTMLElement;
    this.domResizingHelper = new DomResizingHelper(dialogBody, {
      disabled: !this.isExpanded,
    });
    this.domDraggingHelper = new DomDraggingHelper(dialogBody, {
      disabled: !this.isExpanded,
      includeClasses: [
        "floating-dialog__body-inner",
        "floating-dialog__container",
      ],
    });
  },
  beforeDestroy() {
    this.reszObsvWrapper?.destroy();
    this.domResizingHelper?.destroy();
    this.domDraggingHelper?.destroy();
  },
  methods: {
    // 当对话框内部被点击时
    onAreaShrinkedClick() {
      if (!this.isClickable) return;
      if (!this.expanded) this.expanded = true;
    },
    // 当点击对话框以外部分时
    onClickOutside() {
      if (!this.isClickable) return;
      if (this.expanded) {
        if (this.isResizing) return;
        this.expanded = false;
      }
    },

    getInnerStyle(): {
      position?: string;
      width?: string;
      height?: string;
      borderWidth?: string;
      top?: string;
      left?: string;
      padding?: string;
      overflow?: string;
    } {
      const dialog = this.$refs.dialog as HTMLElement | undefined;
      if (dialog) {
        const rect = dialog.getBoundingClientRect();
        if (this.expanded) {
          // 如果是展开状态
          const padding = 10;
          const border = 1;
          return {
            width: `${rect.width}px`,
            borderWidth: `${border}px`,
            top: `${rect.top - border - padding}px`, // 1px 为 border
            left: `${rect.left - border - padding}px`, // 1px 为 border
            padding: `${padding}px`,
          };
        } else {
          // 如果是收缩状态
          return {
            height: `${rect.height}px`,
          };
        }
      } else return {};
    },
  },
});
</script>

<style lang="scss" scoped>
.floating-dialog {
  box-sizing: border-box;
  background-color: #fff;

  .floating-dialog__body {
    box-sizing: border-box;
    // max-height: 200px;

    &.expanded {
      box-sizing: content-box;
      overflow: auto;
      position: fixed;
      border-style: solid;
      border-color: #d9d9d9;
      background-color: #fff;
      border-radius: 4px;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
      z-index: 1999;
      justify-content: space-between;
    }

    &.shrinked {
      width: 100%;
      overflow: hidden;
      justify-content: flex-start;
    }

    .floating-dialog__body-inner {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: flex-start;

      .floating-dialog__container {
        width: 100%;

        &.expanded {
        }

        &.shrinked {
          height: 100%;
        }
      }
    }
  }
}
</style>