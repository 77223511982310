<template>
  <div class="link-select flex w-h-100">
    <div class="link-list">
      <div
        v-for="item in linklist"
        class="link-list-items-wrapper"
        :key="item.dataId"
        @click="handleClick(item)"
        :class="{ active: activelink && activelink.dataId == item.dataId }"
      >
        <div class="link-list-items">
          <div class="label one-line flex align-items-center">
            <div>{{ item.linkName }}</div>
            <el-tooltip class="item" effect="dark" content="打开新窗口" placement="top">
              <svg-icon
                class="ml-10"
                icon="new_tabs"
                width="16"
                height="16"
                :color="'#34456d'"
                @click.native.prevent="handleOpenTab(item.demoLink)"
              ></svg-icon>
            </el-tooltip>
          </div>
          <div class="items-detail">
            <div class="url">
              {{ item.demoLink }}
            </div>
            <div class="username" v-if="item.loginAccount">
              <span
                >账号:
                <span v-if="!item.loginAccount">无</span>
                <span v-else>{{ item.loginAccount }}</span>
              </span>
              <svg-icon
                v-if="item.loginAccount"
                icon="copy"
                width="16"
                height="16"
                :color="'#34456d'"
                @click.native.prevent.stop="handleCopy(item.loginAccount)"
              ></svg-icon>
            </div>
            <div class="username" v-if="item.loginPassword">
              <span
                >密码:
                <span v-if="!item.loginPassword">无</span>
                <span v-if="item.loginPassword">{{ item.loginPassword }}</span>
              </span>
              <svg-icon
                v-if="item.loginPassword"
                icon="copy"
                width="16"
                height="16"
                :color="'#34456d'"
                @click.native.prevent.stop="handleCopy(item.loginPassword)"
              ></svg-icon>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="netweb">
      <iframe v-if="activeurl" class="w-h-100" :src="activeurl" frameborder="0"></iframe>
      <el-empty v-else :image-size="200"></el-empty>
    </div>
  </div>
</template>

<script>
import { ProductLibrary } from "../../../../api/ProductLibrary";
import UpanddownAround from "../../../ArticleView/template/UpanddownAround.vue";
import LayoutAround from "../../../ArticleView/template/LayoutAround.vue";
export default {
  components: { UpanddownAround, LayoutAround },
  props: {
    activeColumn: Object,
    subject: Object,
    scheme: Object,
    ispre: {
      type: Boolean,
      default: false,
    },
    loginrequired: {
      type: Boolean,
      default: true, //是否需要登录
    },
    shareId: String, //分享id，用于未登录的查看使用
  },
  data() {
    return {
      // queryData: {
      //   pageNo: 1,
      //   pageSize: 99,
      //   subjectColumnId: "",
      //   subjectId: "",
      //   name: "",
      // },
      linklist: [],
      checkedItems: [],
      activeurl: "",
      activelink: null,
    };
  },
  watch: {
    checkedItems(val) {
      const selectdata = {
        dataIds: val,
        dataType: 4,
        schemeId: "",
        subjectColumnId: this.activeColumn.id,
        subjectId: this.subject.id,
      };
      this.$emit("handleselect", selectdata);
    },
    activeColumn() {
      this.getList();
    },
    scheme() {
      this.getList();
    },
  },
  mounted() {
    this.scheme && this.getList();
  },
  methods: {
    /**
     * @description:获取链接列表
     * @return {void}
     */
    async getList() {
      const payload = {
        schemeId: this.scheme.id,
        subjectColumnId: this.activeColumn.id,
      }
      if(!this.loginrequired){
        payload.shareId = this.shareId;
      }
      const res = await ProductLibrary[this.loginrequired?"getSchemeToLinkList":"getSchemeToLinkListNoLogin"](payload);
      this.linklist = res;
      if (this.linklist.length) {
        this.handleClick(this.linklist[0]);
      }
    },
    /**
     * @description: 复制
     * @param {String} text 复制项
     * @return {void}
     */
    handleCopy(text) {
      const res = this.common.handleCopy(text);
      res && this.$message.success("复制成功");
    },
    /**
     * @description:预览
     * @param {Object} value 点击项
     * @return {void}
     */
    handleClick(value) {
      if (this.activelink && this.activelink.dataId == value.dataId) {
        return;
      }
      this.activelink = value;
      this.activeurl = `${value.demoLink.startsWith("http") ? "" : "//"}${
        value.demoLink
      }${value.loginAccount || value.loginPassword ? "?" : ""}${
        value.loginAccount ? `account=${value.loginAccount}` : ""
      }${value.loginPassword ? `&password=${value.loginPassword}` : ""}`;
    },
    /**
     * @description:打开新窗口
     * @param {Object} val 点击项地址
     * @return {void}
     */
    handleOpenTab(val) {
      window.open(val);
    },
  },
};
</script>
<style lang="scss" scoped>
.link-select {
  .link-list {
    width: 434px;
    height: 100%;
    overflow-y: auto;
    padding: 0 20px 0 28px;
    box-sizing: border-box;
    border-right: 1px solid #ccc;
    .link-list-items-wrapper {
      overflow: hidden;
      padding: 18px 0 18px 0;
      // height: 150px;
      box-sizing: border-box;
      border-bottom: 1px solid #ebeef2;
      ::v-deep .el-checkbox__input {
        vertical-align: top;
      }
    }
    .link-list-items {
      margin-top: 10px;
      position: relative;

      .label {
        width: 350px;
        font-weight: 700;
      }

      .items-detail {
        line-height: 28px;
      }
      .url {
        color: var(--color-text-primary);
      }

      .username {
        color: var(--color-text-primary);
        display: flex;
        align-items: center;
      }
    }
  }
  .netweb {
    flex: 1;
  }
  .active {
    color: var(--color-primary);
  }
}
.ml-10 {
  margin-left: 10px;
}
</style>
