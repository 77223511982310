import { BaseColumn } from "./BaseColumn";
export class IconColumn extends BaseColumn {

    // public columnType: "icon" = "icon";

    /** 是否必填项(用于前端新建表单时做非空判断) */
    public required: boolean;

    constructor(params: {
        id: string,
        required?: boolean,
        active?: boolean,
        settingName?: string,
        title?: string,
        fieldOrder?: number,
    }) {
        const { id, required = false, active, settingName, title, fieldOrder, } = params;
        super({ id, active, settingName, title, fieldOrder });

        if (!this.width && !this.minWidth) {
            this.minWidth = 80;
        }

        this.required = required;
    }
}