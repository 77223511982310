<template>
  <div class="w-h-100">
    <previeView v-if="unitqueryids" :readonly="true" :unitqueryids="unitqueryids" :unitdemoid="activeColumn.id" />
  </div>
</template>
<script>
import previeView from "@/views/DemoMode/preview.vue";
export default {
  props: {
    activeColumn: Object,
    subject: Object,
  },
  components: { previeView },
  data() {
    return {
      unitqueryids: null,
    };
  },
  mounted() {
    this.unitqueryids = {
      mirrorId: this.subject.mirrorId,
      subjectId: this.subject.id,
      versionId: this.subject.versionId,
    };
  },
};
</script>
