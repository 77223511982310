import { getRamdomStr } from "../util/EncryptionHelper";

export interface ITreeNode<T = any> {
    id: string;
    title: string;
    isChecked?: boolean; // undefined 和 false 等价
    isHalfChecked?: boolean; // undefined 和 false 等价
    children?: ITreeNode<T>[];

    attachment?: T;
}

export class TreeNode<T = any> implements ITreeNode<T> {

    $id: string;

    id: string;
    title: string;
    isChecked?: boolean; // undefined 和 false 等价
    isHalfChecked?: boolean; // undefined 和 false 等价
    children?: TreeNode<T>[];

    attachment?: T;

    /**
     * 初始化树节点
     * @param params 节点参数
     * @param reNew 是否统一重新初始化所有子节点
     */
    constructor(
        params: ITreeNode & { attachment?: any, $parent?: TreeNode<T> },
        reNew: boolean = true,
    ) {
        const { id, title, isChecked, isHalfChecked, children, attachment, $parent } = params;

        // this.$id = `${getRamdomStr('********')}`
        if ($parent) this.$id = `${$parent.$id}-${id}`;
        else this.$id = id;

        this.id = id;
        this.title = title;
        this.isChecked = isChecked;
        this.isHalfChecked = isHalfChecked;

        this.attachment = attachment;

        if (children) {
            if (children.length > 0) {
                this.children = children.map(child => {
                    if (reNew)
                        return new TreeNode({ ...child, attachment, $parent: this });
                    else {
                        if (child instanceof TreeNode) {
                            child.attachment = attachment;
                            return child;
                        }
                        else return new TreeNode({ ...child, attachment, $parent: this });
                    }
                });
            } else {
                this.children = [];
            }
        }
    }

    /** 递归，包含自身 */
    public traverse(cb: (node: TreeNode) => void, node?: TreeNode) {
        let _node;

        if (node) _node = node;
        else _node = this;

        if (_node.children && _node.children.length > 0) {
            for (const child of _node.children) {
                this.traverse(cb, child);
            }
        }

        cb(_node);
    }
}