<template>
  <div class="content-select w-h-100 flex">
    <div class="content-select-preview flex-1">
      <!-- <div v-html="richText"></div> -->
      <SplitPaneCenter :initialrightWidth="1080" :dividerPosition="2" :minPaneWidth="180">
        <template v-slot:right>
          <div class="w-h-100">
            <iframe
              id="previewiframe"
              src=""
              frameborder="0"
              height="100%"
              width="100%"
            ></iframe>
            <!-- <TinyMce :readonly="true" :value="richText"/> -->
          </div>
        </template>
      </SplitPaneCenter>
    </div>
    <div class="preview-bottom flex">
      <div
        class="content-item pointer"
        :class="{ 'active-item': clickitem.id == item.id }"
        v-for="item in tableData"
        :key="item.id"
        @click="getDetail(item)"
      >
        {{ item.columnName }}
      </div>
    </div>
  </div>
</template>

<script>
import { ProductLibrary } from "../../../../api/ProductLibrary";
import SplitPaneCenter from "@/components/SplitPaneCenter.vue";
export default {
  components: { SplitPaneCenter },
  props: {
    activeColumn: Object,
    subject: Object,
    ispre: {
      type: Boolean,
      default: false,
    },
    scheme: Object,
    loginrequired: {
      type: Boolean,
      default: true, //是否需要登录
    },
    shareId: String, //分享id，用于未登录的查看使用
  },
  data() {
    return {
      selectids: [], //已经选择的id合集
      tableData: [],
      queryData: {
        schemeId: "",
        pageNo: 1,
        pageSize: 999,
        subjectVersionId: "",
        subjectColumnId: "",
      },
      richText: "",
      clickitem: {},
    };
  },
  watch: {
    scheme() {
      this.queryData.subjectColumnId = this.activeColumn.id;
      this.queryData.subjectVersionId = this.subject.versionId;
      this.queryData.schemeId = this.scheme.id;
      this.getTableData();
    },
    activeColumn() {
      this.queryData.subjectColumnId = this.activeColumn.id;
      this.queryData.subjectVersionId = this.subject.versionId;
      this.queryData.schemeId = this.scheme.id;
      this.getTableData();
    },
    richText() {
      // 使用iframe加载预览内容
      let iframe = document.getElementById("previewiframe");
      iframe.src = "about:blank";
      setTimeout(() => {
        iframe && iframe.contentDocument.write(this.richText);
      }, 100);
    },
  },
  mounted() {
    if (this.scheme) {
      this.queryData.subjectColumnId = this.activeColumn.id;
      this.queryData.subjectVersionId = this.subject.versionId;
      this.queryData.schemeId = this.scheme.id;
      this.getTableData();
    }
  },
  methods: {
    /**
     * @description:获取子栏目列表
     * @return {void}
     */
    async getTableData(pageNo) {
      // pageNo && (this.queryData.pageNo = pageNo);
      if (!this.loginrequired) this.queryData.shareId = this.shareId;
      const res = await ProductLibrary[
        this.loginrequired
          ? "getSchemeSubjectColumnChildPageList"
          : "getSchemeSubjectColumnChildPageListNoLogin"
      ](this.queryData);
      let uniqueArray = Array.from(
        res.records.reduce((map, obj) => map.set(obj.id, obj), new Map()).values()
      );
      this.tableData = uniqueArray;
      if (this.tableData.length) {
        this.getDetail(this.tableData[0]);
      } else {
        this.richText = "";
      }
    },
    /**
     * @description: 预览介绍
     * @param {Object} value 介绍id
     * @return {void}
     */
    async getDetail(value) {
      this.clickitem = value;
      let res;
      if (this.loginrequired) {
        res = await ProductLibrary.getSettingSubjectColumnContent(value.id);
      } else {
        res = await ProductLibrary.getSettingSubjectColumnContentNoLogin({
          shareId: this.shareId,
          subjectColumnId: value.id,
        });
      }
      this.richText = res[0].content ? res[0].content : "";
    },
  },
};
</script>
<style lang="scss" scoped>
.content-select {
  flex-direction: column;
  overflow: hidden;
}

.content-select-preview {
  flex: 1;
  background-color: #fff;
  padding: 10px;
  overflow-y: auto;
}
.preview-bottom {
  height: 24px;
  line-height: 24px;
  font-size: 12px;
  background-color: #f7f9fc;
  color: #a7b4cc;
  justify-content: center;
  .content-item {
    padding: 0 20px;
  }
  .active-item {
    font-weight: 700;
    background-color: #fff;
    color: var(--color-text-primary);
  }
}
.pd-10 {
  padding: 0 10px;
  box-sizing: border-box;
}
</style>
