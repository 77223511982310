<template>
  <div class="filter-detail">
    <template v-if="currFilter !== null">
      <template v-if="treeData.length > 0">
        <SimpleTree
          ref="simple-tree"
          :isFirstLevelAlignSecondLevel="true"
          :nodes="treeData"
          :nodeKey="TREE_NODE_KEY"
          :props="TREE_PROPS"
          :isLeafCheckableOnly="currFilter === 'view'"
          :isLeafSingleCheckable="currFilter === 'view'"
          :isNodeClickSingleCheckable="true"
          :disabled="disableTree"
          @checkbox-change="onTreeNodeCheck"
          @node-click="onTreeNodeClick"
        >
          <template v-slot="{ node }">
            <div :id="node[TREE_NODE_KEY]" class="node-content">
              <div class="title">
                <span>{{ node[TREE_PROPS.title] }}</span>
                <template
                  v-if="
                    node.attachment &&
                    node.attachment['dataCount'] !== undefined &&
                    node.attachment['dataCount'] !== null
                  "
                >
                  <span>({{ node.attachment["dataCount"] }})</span>
                </template>
              </div>

              <template
                v-if="
                  currFilter === 'view' &&
                  node.attachment &&
                  node.attachment['filter']
                "
              >
                <ElPopover
                  class="btn-preview-popover"
                  placement="right"
                  :title="`${node[TREE_PROPS.title]} 详情`"
                  trigger="click"
                  :visible-arrow="true"
                >
                  <IconBtn
                    slot="reference"
                    class="btn-preview"
                    :iconSize="iconSize"
                    :padding="iconPadding"
                    @btn-click="onBtnPreviewClick(node)"
                  >
                    <template v-slot:active>
                      <svg-icon
                        sys="common-table"
                        name="ColumnsSetting/eye"
                        :size="iconSize"
                        :color="'#666'"
                      />
                    </template>
                  </IconBtn>

                  <MainViewTrees
                    :filters="node.attachment.filter.filters"
                    :isCheckboxReadonly="true"
                    @tree-checkbox-change="onMainViewTreesCheckboxChange"
                  />
                </ElPopover>
              </template>
            </div>
          </template>
        </SimpleTree>
      </template>
      <template v-else>
        <ElEmpty description="暂无数据"></ElEmpty>
      </template>
    </template>
  </div>
</template>
<script lang="ts">
import Vue, { PropType } from "vue";
import { Popover as ElPopover, Empty as ElEmpty } from "element-ui";
import PubSub from "pubsub-js";
import SvgIcon from "@/flexible-table-module/svg-icon-importer";

import SimpleTree from "@/flexible-table-module/components/Common/Tree/SimpleTree.vue";
import IconBtn from "@/flexible-table-module/components/Common/IconBtn.vue";
import MainViewTrees from "@/flexible-table-module/components/FlexibleTable/ViewEditor/MainViewTrees.vue";

import {
  Filter,
  MultiFilter,
  TypeMode,
  ViewFilter,
} from "@/flexible-table-module/entity/Filter";
import { TreeNode, ITreeNode } from "@/flexible-table-module/entity/TreeNode";
import { HistorySelections } from "@/flexible-table-module/entity/HistorySelections";
import { Column } from "@/flexible-table-module/entity/Table/Column";

import {
  ROOT_TREE_NODE_TEMPLATE,
  TREE_PROPS,
} from "@/flexible-table-module/constants";

const TREE_NODE_KEY = "$id";

export default Vue.extend({
  inheritAttrs: false,
  name: "FilterDetail",
  components: {
    ElPopover,
    ElEmpty,
    SvgIcon,
    SimpleTree,
    IconBtn,
    MainViewTrees,
  },
  props: {
    currFilter: {
      type: [Object, String] as PropType<Filter | MultiFilter | "view" | null>,
    },
    viewFilters: { type: Array as PropType<ViewFilter[]>, default: () => [] },
    disableTree: { type: Boolean, default: false }, // 是否不可用
    // keepHistory: { type: Boolean, required: false }, // 是否保存之前其它筛选器选择项的选择记录
    historySelections: {
      type: Object as PropType<HistorySelections>,
      default: () => new HistorySelections(),
    },
  },
  data(): {
    treeData: TreeNode<Filter>[];
    TREE_PROPS: typeof TREE_PROPS;
    iconSize: number;
    iconPadding: number;
    historyGenerationMap: Map<Filter | MultiFilter | "view", TreeNode[]>; // 存放之前生成过的树状数据
    keepHistory: boolean;
    TREE_NODE_KEY: string;
  } {
    return {
      treeData: [],
      TREE_PROPS,

      iconSize: 12,
      iconPadding: 2,

      historyGenerationMap: new Map(),
      keepHistory: false,

      TREE_NODE_KEY,
    };
  },
  watch: {
    currFilter: {
      immediate: true,
      async handler(currFilter: Filter | MultiFilter | "view" | null) {
        this.geneTreeData(this.keepHistory);
        await this.$nextTick();
        this.syncCurrTreeByHistorySelections();
      },
    },
  },
  mounted() {
    PubSub.subscribe(
      "filter-conditions-tag-close",
      this.onFilterConditionsTagClose
    );

    // PubSub.subscribe(
    //   "table-column-filter-change",
    //   this.onTableColumnFilterChange
    // );
  },
  // updated() {
  //   this.$nextTick(() => {
  //     const refTree = (this.$refs["simple-tree"] as Vue).$refs["elTree"] as any;
  //     console.log("refTree :>> ", refTree);
  //   });
  // },
  methods: {
    // print(slotProps: any) {
    //   console.log("slotProps :>> ", slotProps);
    // },

    // 当表头筛选器的值发生变化时，需要调用该方法，以更新树状选择器的选择项
    $onTableColumnFilterChange(col: Column) {
      if (col.filter) {
        if (
          col.filter.type === "string-select" ||
          col.filter.type === "key-str-value-select"
        ) {
          if (!(this.currFilter instanceof Filter)) return;

          const currFilterTitle = this.currFilter.title;
          if (currFilterTitle !== col.title) return;

          const target = col.filter.target as string[];

          const refTree = this.$refs["simple-tree"] as any;
          const nodes = refTree.getCheckedNodes(true) as ITreeNode[][]; // 二维数组

          // 二维数组转一维数组
          const flatNodes = nodes.reduce((acc, val) => acc.concat(val), []);
          // 获取 flatNodes 的 id
          const ids = flatNodes.map((node) => node.id);

          if (Array.isArray(target)) {
            // 1. 找到 target 中不在 ids 中的元素
            const diff = target.filter((t) => !ids.includes(t));
            // 2. 找到 ids 中不在 target 中的元素
            const diff2 = ids.filter((id) => !target.includes(id));

            this.treeData.forEach((node) => {
              node.traverse((tn) => {
                if (diff.includes(tn.id)) {
                  refTree.setChecked(tn, true);
                } else if (diff2.includes(tn.id)) {
                  refTree.setChecked(tn, false);
                }
              });
            });

            this.whenHistorySelectionsNeedToChange();
          }
        }
      }
    },

    $geneTreeData() {
      this.$nextTick(() => {
        this.geneTreeData(this.keepHistory);
      });
    },

    // ----------------------------------------------------------------------

    onFilterConditionsTagClose(msg: string, data: ITreeNode[][]) {
      const refTree = this.$refs["simple-tree"] as any;
      if(!refTree) return
      for (const nodes of data) {
        refTree.setChecked(nodes[0], false);
      }
    },

    // ----------------------------------------------------------------------

    onTreeNodeCheck(node: ITreeNode, checked: boolean) {
      const nodes = this.whenHistorySelectionsNeedToChange();
      if (nodes) {
        this.$emit("checkbox-change", nodes, node, checked);
      }
    },

    onTreeNodeClick(node: ITreeNode) {
      const nodes = this.whenHistorySelectionsNeedToChange();
      if (nodes) {
        this.$emit("node-click", nodes, node);
      }
    },

    // onCheckedNodesResolved() {
    //   // this.whenHistorySelectionsNeedToChange();
    // },

    whenHistorySelectionsNeedToChange() {
      if (this.currFilter !== null) {
        const refTree = this.$refs["simple-tree"] as any;
        if (refTree) {
          const nodes: ITreeNode[][] = refTree.getCheckedNodes(false);
          this.removeRoot(nodes);
          this.setHistorySelection(this.currFilter, nodes);
          return nodes;
        }
      }
    },

    setHistorySelection(
      filter: Filter | MultiFilter | "view",
      nodes: ITreeNode[][]
    ) {
      this.historySelections.setTwoDTreeNodes(filter, nodes);
    },

    // 把 historySelections 中的选择项同步到到树状选择器中
    async syncCurrTreeByHistorySelections() {
      if (this.currFilter) {
        const twoDNodes = this.historySelections.getTwoDTreeNodes(
          this.currFilter
        );
        if (twoDNodes) {
          const nodes = twoDNodes.map((node) => node[0]);

          const refTree = this.$refs["simple-tree"] as any;
          refTree.setCheckedNodes(nodes);
        }
      }
    },

    // ----------------------------------------------------------------------

    // 获取当前是哪一类展示内容(单列条件、多列条件、视图、无)
    // getCurrMode(
    //   filter: Filter | MultiFilter | "view" | null
    // ): TypeMode | "none" {
    //   if (this.currFilter instanceof Filter) return "single";
    //   else if (this.currFilter instanceof MultiFilter) return "multiple";
    //   else if (this.currFilter === "view") return "view";
    //   else return "none";
    // },

    // 去除代表“全部”的根节点
    removeRoot(nodes: ITreeNode[][]) {
      for (const line of nodes) {
        const last = line[line.length - 1];
        if (
          last.id === ROOT_TREE_NODE_TEMPLATE.id &&
          last.title === ROOT_TREE_NODE_TEMPLATE.title
        ) {
          line.splice(line.length - 1, 1);
        }
      }
    },

    // 根据当前的过滤器生成过滤器项的树形数据
    async geneTreeData(keepHistory: boolean) {
      const currFilter = this.currFilter;

      // ------------------------------------------------------------

      const rootTreeNode = new TreeNode(ROOT_TREE_NODE_TEMPLATE);
      let treeNodes: TreeNode[] | undefined = undefined;

      if (
        keepHistory &&
        currFilter !== null &&
        this.historyGenerationMap.has(currFilter)
      ) {
        // 如果需要读历史生成，并且有历史生成
        treeNodes = this.historyGenerationMap.get(currFilter);
      } else {
        if (currFilter instanceof Filter) {
          // 单列条件
          const newTns = this.filterListToTreeNodes(currFilter);
          treeNodes = newTns;
        } else if (currFilter instanceof MultiFilter) {
          // 多列条件
          treeNodes = [];
          for (const filter of currFilter.filters) {
            if (treeNodes.length === 0) {
              const newTns = this.filterListToTreeNodes(filter);
              treeNodes.push(...newTns);
            } else {
              treeNodes.forEach((tn) => {
                tn.traverse((subTn) => {
                  if (!subTn.children) {
                    // 如果没有 children，证明是末端节点

                    // console.log("subTn :>> ", subTn);

                    const newTns = this.filterListToTreeNodes(filter, subTn);
                    if (newTns.length > 0) subTn.children = newTns;
                  }
                });
              });
            }
          }
        } else if (currFilter === "view") {
          // 视图
          treeNodes = this.viewFilters.map((vf) => {
            return new TreeNode({
              id: vf.title,
              title: vf.title,
              isChecked: false,
              isHalfChecked: false,
              attachment: { filter: vf },
            });
          });
        } else {
          // 此时，treeNodes 等于 undefined
        }

        // 记录生成的历史
        if (
          keepHistory &&
          currFilter instanceof Filter && // 只记录单列条件
          treeNodes !== undefined &&
          !this.historyGenerationMap.has(currFilter)
        ) {
          this.historyGenerationMap.set(currFilter, treeNodes);
        }
      }

      if (currFilter instanceof Filter || currFilter instanceof MultiFilter) {
        if (treeNodes && treeNodes.length > 0) {
          rootTreeNode.children = treeNodes;
          this.treeData = [rootTreeNode];
        } else {
          this.treeData = [];
        }
      } else if (currFilter === "view") {
        this.treeData = treeNodes ?? [];
      } else {
        this.treeData = treeNodes ?? [];
      }
    },

    // 将过滤器项转换为树节点(实际上是递归复制一遍节点，因为树节点的数据结构和过滤器项的数据结构是相同的，都是 ITreeNode 及其子类)
    filterListToTreeNodes(
      filter: Filter,
      parentTreeNode?: TreeNode
    ): TreeNode[] {
      // console.log("parentTreeNode :>> ", parentTreeNode);
      const treeNodes =
        filter.list && filter.list.length > 0
          ? filter.list.map(
              (tn) =>
                new TreeNode({
                  ...tn,
                  attachment: {
                    filter: filter,
                    dataCount: tn.dataCount,
                  },
                  $parent: parentTreeNode,
                })
            )
          : [];
      return treeNodes;
    },

    onBtnPreviewClick(node: any) {},
    onMainViewTreesCheckboxChange() {},
  },
});
</script>
<style lang="scss" scoped>
@import "src/flexible-table-module/scss/_variables.scss";

.filter-detail {
  width: 100%;
  height: 100%;

  .node-content {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    user-select: none;

    .title {
      font-size: $fs-filter-detail--title;
      flex: 1;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .btn-preview-popover {
      display: block;
      margin-right: 4px;

      ::v-deep {
        .el-popover__reference-wrapper {
          display: flex;
          align-items: center;
        }
      }

      .btn-preview {
      }
    }
  }
}
</style>