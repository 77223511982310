import request from "@/utils/appServer";
class Module {
  // 模块列表
  static async getModuleList() {
    try {
      const res = await request.get('/sales-show-4.0/module/queryList');
      return res.data.code === 200 && res.data.result ? res.data.result : [];
    } catch (error) {
      return [];
    }
  }
  // 删除模块
  static async deleteModule(data) {
    try {
      const res = await request.get('/sales-show-4.0/module/delete', { params: data }, true);
      return res.data.code === 200;
    } catch (error) {
      return false;
    }
  }
  // 获取模块图标列表
  static async queryModuleIconList() {
    try {
      const res = await request.get('/sales-show-4.0/module/queryModuleIconList');
      return res.data.code === 200 && res.data.result ? res.data.result : [];
    } catch (error) {
      return [];
    }
  }
  // 删除模块图标
  static async deleteModuleIcon(iconId) {
    try {
      const res = await request.delete('/sales-show-4.0/module/deleteModuleIcon', { params: { iconId } }, true);
      return res.data.code === 200;
    } catch (error) {
      return false;
    }
  }
  // 获取主题栏目图标列表
  static async queryColumnIconList() {
    try {
      const res = await request.get('/sales-show-4.0/subject/queryColumnIconList');
      return res.data.code === 200 && res.data.result ? res.data.result : [];
    } catch (error) {
      return [];
    }
  }
  // 删除栏目图标
  static async deleteSubjectColumnIcon(iconId) {
    try {
      const res = await request.delete('/sales-show-4.0/subject/deleteSubjectColumnIcon', { params: { iconId } }, true);
      return res.data.code === 200;
    } catch (error) {
      return false;
    }
  }
}

export { Module }