<template>
  <div id="app">
    <router-view v-if="isLoginPage" />
    <HomePage v-else />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import HomePage from './views/HomePage/index.vue'
export default {
  name: "App",
  components: {
    HomePage
  },
  computed: {
    ...mapGetters(['userInfo', 'isLoginPage'])
  },
}
</script>

<style lang="scss">
#app {
  width: 100%;
  height: 100%;
  font-size: 14px;
  font-weight: 500;
}
</style>
