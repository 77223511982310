<template>
  <el-container class="h-100">
    <el-aside class="xs-aside" :width="collapse ? '50px' : '180px'">
      <div class="xs-aside__top">
        <!-- <div v-if="!collapse" class="xs-aside__top-ver" @click="viewLog">beta 0.1.01</div> -->
        <!-- <svg-icon v-if="!collapse" icon="newlogo" width="141" height="40"></svg-icon> -->
        <img v-if="!collapse" src="@/assets/images/logo/logo.png" style="width:89px;height:40px" alt="">
        <svg-icon v-else icon="newsmalllogo" width="28" height="28"></svg-icon>
      </div>
      <div class="app-menu-w" :class="{ 'is-collapse': collapse }">
        <el-menu class="app-menu" :default-active="activeMenuId" :collapse="collapse"
          :default-openeds="['ME00000005', 'ME00000004']">
          <el-menu-item class="group2-item" @click="handleClickMenuItem('home')"
            :class="{ 'menu-active': activeMenuId == 'home' }">
            <svg-icon color="#344563" icon="backmenu-home" :is-active="activeMenuId === 'home'"
              :activeColor="'#ffffff'"></svg-icon>
            <span slot="title">工作台</span>
          </el-menu-item>
          <template v-for="item in newmenuList">
            <div v-if="item.moduleEntityList" :key="item.groupId">
              <div class="app-menu-item-group__title" v-if="item.groupName">
                <div class="group-title">{{ item.groupName ? item.groupName : '--' }}</div>
              </div>
              <el-menu-item class="group2-item" v-for="menuiten in item.moduleEntityList" :key="menuiten.id"
                @click="handleClickMenuItem(menuiten)" :class="{ 'menu-active': menuiten.id == activeMenuId }">
                <svg-icon-2 v-if="menuiten.iconType === 'online'" :icon="menuiten.icon"
                  :is-active="activeMenuId === item.id" :activeColor="'#ffffff'" color="#344563" />
                <svg-icon v-if="menuiten.iconType === 'local'" color="#344563"
                  :icon="menuiten.moduleIcon ? menuiten.moduleIcon : 'module-icon-1'"></svg-icon>
                <span slot="title">{{ menuiten.moduleName }}</span>
                <el-popover popper-class="popover group-popover" placement="right-start" width="54" trigger="click"
                  :ref="'menu-' + menuiten.id" :visible-arrow="false" v-if="isAdmin">
                  <div class="el-menu-item__more" slot="reference" @click.stop>
                    <svg-icon icon="more-vertical" width="12" height="12"
                      :color="activeMenuId === menuiten.id ? '#fff' : '#344563'"></svg-icon>
                  </div>
                  <div class="popover-item" @click="showModuleDialog(menuiten)">编辑</div>
                  <div class="popover-divider"></div>
                  <div class="popover-item del" @click="deleteModule(menuiten)">删除</div>
                </el-popover>
              </el-menu-item>
            </div>
            <div v-if="!item.moduleEntityList" :key="item.id">
              <div v-if="item.group" class="app-menu-item-group__title" :key="'group-' + item.id">{{ item.group }}</div>
              <!-- 有展开项的 -->
              <el-submenu v-if="!item.moduleEntityList && item.children.length" :index="item.id" :key="item.id"
                :class="{ 'submenu-active': item.children.find(i => i.id === activeMenuId) }">
                <template slot="title">
                  <svg-icon color="#344563" :icon="item.icon"></svg-icon>
                  <span slot="title" :class="{ 'none': collapse }">{{ item.name }}</span>
                </template>
                <el-menu-item v-for="subitem in item.children" :index="subitem.id" :key="subitem.id"
                  :class="{ 'menu-active': subitem.id === activeMenuId }" @click="handleClickMenuItem(subitem)">{{
      subitem.name }}</el-menu-item>
              </el-submenu>
              <el-menu-item v-else :index="item.id" :key="item.id" :class="{ 'menu-active': item.id === activeMenuId }"
                @click="handleClickMenuItem(item)">
                <svg-icon-2 v-if="item.iconType === 'online'" :icon="item.icon" :is-active="activeMenuId === item.id"
                  color="#344563" :activeColor="collapse ? '#6a87fc' : '#fff'" />
                <svg-icon v-else color="#344563" :icon="item.icon"></svg-icon>
                <span slot="title">{{ item.name }}</span>
                <el-popover v-if="!item.default" popper-class="popover" placement="right-start" width="54"
                  trigger="click" :ref="'menu-' + item.id" :visible-arrow="false">
                  <div class="el-menu-item__more" slot="reference" @click.stop>
                    <svg-icon icon="more-vertical" width="12" height="12"
                      :color="activeMenuId === item.id ? '#fff' : '#344563'"></svg-icon>
                  </div>
                  <div class="popover-item" @click="showModuleDialog(item)">编辑</div>
                  <div class="popover-divider"></div>
                  <div class="popover-item del" @click="deleteModule(item)">删除</div>
                </el-popover>
              </el-menu-item>
            </div>
          </template>
        </el-menu>
      </div>
      <div class="collapse" @click="collapse = !collapse">
        <img class="collapse-icon" src="@/assets/images/menu/collapse.png" :class="{ 'ci-collapse': collapse }">
      </div>
    </el-aside>
    <el-container>
      <el-header class="xs-header" height="66px">
        <AppHeader />
      </el-header>
      <el-container class="ov-auto">
        <el-main class="xs-main">
          <keep-alive :include="cacheList">
            <router-view />
          </keep-alive>
        </el-main>
      </el-container>
    </el-container>
    <AddModuleDialog v-if="amDialogVisible" :visible.sync="amDialogVisible" :module-id="active.id"
      :current-module="editModule" @finish="getModuleList" />
    <!-- <ChatSystem /> -->
  </el-container>
</template>

<script>
import { mapGetters } from 'vuex';
import { Module } from "@/api/Module";
import { Login } from "@/api/Login";
import AddModuleDialog from './dialog/AddModuleDialog.vue';
import menu from "@/utils/menu";
import AppHeader from './AppHeader.vue';
// import ChatSystem from './components/ChatSystem.vue';
import loginChat from '@/utils/LoginChat';
export default {
  name: 'HomeView',
  components: { AppHeader, AddModuleDialog },
  data() {
    return {
      collapse: false,
      activeMenu: null,
      activeMenuId: "",
      menuList: [],
      editModule: null,
      amDialogVisible: false,
      newmenuList: [],
      localmenu: []
    }
  },
  computed: {
    ...mapGetters({ token: 'token', active: 'activeMenu', isAdmin: "isAdmin", userInfo: "userInfo", cacheList: "cacheList", moduleTypeDict: "moduleTypeDict", hasNopowerList: "hasNopowerList" })
  },
  watch: {
    active: {
      handler(val) {
        this.activeMenuId = val.id;
        this.activeMenu = val;
      },
      deep: true,
    }
  },
  mounted() {
    this.localmenu = _.cloneDeep(menu)
    if (this.active) {
      this.activeMenuId = this.active.id;
    }
    this.token && this.getModuleList();
    // this.doLoginTinode();//聊天登录
  },
  methods: {
    /**
     * @description: 获取模块列表
     * @return {void}
     */
    async getModuleList() {
      const res = await Module.getModuleList();
      let list = [];
      const res1 = res.filter(item => !item.groupId)
      const res2 = res.filter(item => item.groupId)
      let resdata = [...res1, ...res2]
      res.forEach(element => {
        element.moduleEntityList.forEach(item => {
          item.icon = item.moduleIcon ? item.moduleIcon : "product";
          item.iconType = item.moduleIcon && item.moduleIcon.startsWith("http") ? "online" : "local";
          item.name = item.moduleName;
          item.url = item.createType == 3 && item.moduleName == "分享管理" ? "share" : this.moduleTypeDict[item.type + ""];
          item.type = "router";
          item.parentId = '-1';
          item.enable = true;
          item.default = false;
        })
      });
      const group1 = res.filter(item => !item.groupId)
      const group2 = res.filter(item => item.groupId)
      // let modelmune=[...group1, ...group2]
      // this.newmenuList = [...modelmune, ...this.localmenu]
      // 控权
      let modelmune = [...group1, ...group2]
      modelmune.forEach(item => {
        if (item.moduleEntityList) {
          item.moduleEntityList = item.moduleEntityList.filter(obj1 => !this.hasNopowerList.some(obj2 => obj2.moduleId === obj1.id));
        }
      })
      modelmune = modelmune.filter(item => item.moduleEntityList && item.moduleEntityList.length != 0)
      this.newmenuList = [...modelmune, ...this.localmenu]
      this.newmenuList.forEach(item => {
        if (item.id == "ME00000005" && this.hasNopowerList.some(obj2 => obj2.permsCode === "friends:")) {
          item.children = item.children.filter(item => item.id != "ME000000051")//"ME000000051"是本地菜单好友管理的id
        }
      })
      resdata.forEach(group => {
        group.moduleEntityList.forEach(element => {
          const item = {
            id: element.id,
            categoryId: element.categoryId,
            // 如果是固定的模块且名称为分享管理，url就提供分享的页面
            url: element.createType == 3 && element.moduleName == "分享管理" ? "share" : this.moduleTypeDict[element.type + ""],
            name: element.moduleName,
            parentId: '-1',
            icon: element.moduleIcon ? element.moduleIcon : "product",
            iconType: element.moduleIcon && element.moduleIcon.startsWith("http") ? "online" : "local",
            enable: true,
            default: false,
            children: [],
            isCreator: element.createBy === this.userInfo.id,
            isAdmin: this.isAdmin,
            type: "router",
            group: group.groupName
          }
          // list.push(item);
          // 控权
          if (!this.hasNopowerList.some(obj => obj.moduleId === item.id)) {
            list.push(item);
          }
        })
      });
      this.menuList = [...list, ...this.localmenu];
      this.$store.commit("saveMenuList", this.menuList);
      this.$store.commit("saveSidesMenuList", this.newmenuList);
    },
    /**
     * @description: 删除模块
     * @return {void}
     */
    async deleteModule(value) {
      this.$refs['menu-' + value.id] && this.$refs['menu-' + value.id][0].doClose();
      this.$showMessage({
        message: `是否确认删除 <span style="font-weight: 1000;">${value.name}</span> ?`,
        ...this.$global.deleteMessage
      }, async (done) => {
        const res = await Module.deleteModule({ id: value.id });
        done(res);
        res && this.getModuleList();
        this.$store.commit("saveActiveMenu", this.menuList[0]);
      });
    },
    /**
     * @description: 点击菜单
     * @param {Object} value 点击项
     * @return {void}
     */
    handleClickMenuItem(value) {
      if (value == "home") {
        this.activeMenu = ""
        this.activeMenuId = "home"
        this.$router.push('/hometable')
        this.$store.commit("saveActiveMenu", {
          name: '工作台',
          id: "home"
        });
        return
      }
      if (value.type === "dialog") {
        this.editModule = null;
        this[value.url] = true;
      } else if (value.type === "router") {
        if (!value.url || (this.activeMenu && this.activeMenu.id === value.id)) {
          return
        }
        this.activeMenu = value;
        this.activeMenuId = value.id;
        this.$store.commit("saveActiveMenu", this.activeMenu);
        if (value.enable) {
          if (value.default) {
            this.$router.push(value.url).catch(() => { })
          } else {
            this.$router.push({
              name: value.url,
              params: {
                id: value.id,
              }
            }).catch(() => { })
          }
        }
      } else {
        value.url && window.open(value.url, '_blank');
      }
    },
    /**
     * @description: 显示 新增、编辑模块 弹窗
     * @param {Object} value
     * @return {void}
     */
    showModuleDialog(value) {
      this.$refs['menu-' + value.id] && this.$refs['menu-' + value.id][0].doClose();
      this.editModule = value;
      this.amDialogVisible = true;
    },
    /**
     * @description: 查看日志
     * @return {void}
     */
    viewLog() {
      const { href } = this.$router.resolve({
        name: 'VersionLogs'
      });
      window.open(href, '_blank');
    },
    /**
     * @description: 登录聊天系统
     * @return {void}
     */
    doLoginTinode() {
      loginChat(this.userInfo.username, this.token);
    },
  }
}
</script>

<style lang="scss" scoped>
.xs-aside {
  display: flex;
  flex-direction: column;

  .xs-aside__top {
    padding: 24px 16px;
    display: flex;
    flex-direction: column;
    // align-items: center;
    height: 80px;
    box-sizing: border-box;

    .xs-aside__top-ver {
      margin-bottom: 4px;
      font-size: 12px;
      transform: scale(.9);
      color: #abb4c4;
      cursor: pointer;
    }
  }

  .collapse {
    padding: 12px 0;
    text-align: center;
    cursor: pointer;

    .collapse-icon {
      width: 24px;
      height: 24px;
      transition: all .3s;

      &.ci-collapse {
        transform: rotate(180deg);
      }
    }
  }
}

.xs-header {
  background-color: #fbfcfc;
  padding: 0 26px;
  overflow: hidden;
}

.xs-main {
  padding: 0 !important;
  background-color: #ebeef2;
}

.my-pagination {
  position: absolute;
  right: 26px;
  bottom: 24px;
  left: 26px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .my-pagination-tip {
    font-size: 14px;
    font-weight: 500;
    color: #5e6c84;
  }

  .app-pagination {
    ::v-deep .el-pager {
      .number {
        height: 30px;
        line-height: 30px;
        font-size: 12px;
        font-weight: 500;
        color: #586677;
        background-color: #fff;
        border: 1px solid #d3dce6;

        &:hover {
          background: #dbeeff;
          border-color: #91b6ff;
        }
      }

      li:not(.disabled).active {
        background: #5991ff;
        border-color: #1664ff;
      }
    }
  }
}

.app-menu-w {
  box-sizing: border-box;
  flex: 1;
  min-height: 400px;
  padding: 0 10px;
  overflow-y: auto;

  ::v-deep(.el-menu--collapse) {
    width: 30px;

    .svg-icon,
    .online-svg,
    .online-img {
      margin-right: 0 !important;
    }

    .app-menu-item-group__title {
      display: none;
    }

    .el-menu-item {
      height: 30px;
      line-height: 100%;
      padding: 0 !important;
      margin-bottom: 24px;

      &:hover {
        background-color: #f5f9ff !important;

        .el-menu-item__more {
          display: none;
        }
      }

      .el-menu-item__more {
        display: none;
      }

      &.menu-active {
        background-color: #fff;

        .svg-icon {
          color: var(--color-primary) !important;
        }
      }

      .el-tooltip {
        display: flex !important;
        align-items: center;
        padding: 0 5px !important;
      }
    }

    .el-submenu {
      .el-submenu__title {
        height: 30px;
        line-height: 30px;
        vertical-align: middle;
        padding: 0 5px !important;
        margin-bottom: 24px;
      }

      &.submenu-active {
        .el-submenu__title {

          .svg-icon {
            color: var(--color-primary) !important;
          }
        }
      }
    }
  }
}

.app-menu {
  border: none;

  .app-menu-item-group__title {
    box-sizing: content-box;
    height: 20px;
    line-height: 20px;
    padding: 18px 0 8px 16px;
    font-size: 12px;
    font-weight: 500;
    color: #999;
  }

  ::v-deep(.el-menu-item) {
    height: 36px;
    line-height: 36px;
    padding: 0 16px !important;
    border-radius: 5px;
    margin-bottom: 4px;
    color: var(--color-text-regular);
    display: flex;
    align-items: center;
    position: relative;

    * {
      vertical-align: baseline;
    }

    .svg-icon,
    .online-svg,
    .online-img {
      margin-right: 8px;
    }

    &.menu-active {
      color: #fff;
      background-color: var(--color-primary);

      .svg-icon {
        color: #fff !important;
      }

      &:hover {
        color: #fff;
        background-color: var(--color-primary-hover);
      }
    }

    &:hover {
      background-color: #FBFAFF;

      .el-menu-item__more {
        display: block;
      }
    }

    .el-menu-item__more {
      display: none;
      position: absolute;
      top: 12px;
      right: 10px;
      width: 12px;
      height: 12px;

      // background-image: url("@/assets/images/common/more_vertical.png");
      // background-size: 12px 12px;
      // background-repeat: no-repeat;
      .svg-icon {
        margin: 0;
        display: block;
      }
    }
  }

  ::v-deep(.el-submenu__title) {
    height: 36px;
    line-height: 36px;
    padding: 0 16px !important;
    border-radius: 5px;
    margin-bottom: 4px;
    display: flex;
    align-items: center;

    .svg-icon {
      margin-right: 8px;
    }

    .el-submenu__icon-arrow {
      color: var(--color-text-regular);
      font-size: 12px;
      font-weight: 600;
      right: 10px;
    }
  }

  ::v-deep(.el-menu-item-group__title) {
    box-sizing: content-box;
    height: 20px;
    line-height: 20px;
    padding: 18px 0 8px 16px;
    font-size: 12px;
    font-weight: 500;
    color: #999;
  }

  ::v-deep(.el-submenu) {
    .el-menu-item {
      min-width: 160px;
      padding-left: 44px !important;
    }
  }
}

::v-deep .el-submenu__title:hover {
  background-color: #fbfaff;
}

.none {
  display: none;
}

.del {
  color: #8997B0;
}
</style>