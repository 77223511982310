import { Filter } from "../entity/Filter";
import BaseAxios from "./_BaseAxios";
// @ts-ignore
import ResHelper from "./_ResponseHelper.js";

export class ApiFilter {

    private PRIFIX: string;

    constructor(private baseURL?: string) {
        if (baseURL) this.PRIFIX = '/filter';
        else this.PRIFIX = '/public/table/filter';
    }

    public getFilters(
        moduleName: string,
        tabName: string,
        includeList: boolean,
    ) {
        return BaseAxios({
            baseURL: this.baseURL,
            url: `${this.PRIFIX}/getFilters`,
            method: "post",
            params: { moduleName, tabName, includeList },
        }).then(ResHelper.handler);
    }

    // public getFilterList(
    //     moduleName: string,
    //     tabName: string,
    //     filterId: string,
    // ) {
    //     return BaseAxios({
    //         baseURL: this.baseURL,
    //         url: `${this.PRIFIX}/getFilterList`,
    //         method: "post",
    //         params: { moduleName, tabName, filterId },
    //     }).then(ResHelper.handler);
    // }

    public getMultiFilters(
        moduleName: string,
        tabName: string,
    ) {
        return BaseAxios({
            baseURL: this.baseURL,
            url: `${this.PRIFIX}/getMultiFilters`,
            method: "post",
            params: { moduleName, tabName },
        }).then(ResHelper.handler);
    }

    public getViewFilters(
        moduleName: string,
        tabName: string,
    ) {
        return BaseAxios({
            baseURL: this.baseURL,
            url: `${this.PRIFIX}/getViewFilters`,
            method: "post",
            params: { moduleName, tabName },
        }).then(ResHelper.handler);
    }

    public getFilterListByFilter(
        moduleName: string,
        tabName: string,
        reqBody: any,
    ) {
        return BaseAxios({
            baseURL: this.baseURL,
            url: `${this.PRIFIX}/getFilterListByFilter`,
            method: "post",
            params: { moduleName, tabName },
            data: reqBody,
        }).then(ResHelper.handler);
    }

    public updateFilterActive(
        moduleName: string,
        tabName: string,
        reqBody: any,
    ) {
        return BaseAxios({
            baseURL: this.baseURL,
            url: `${this.PRIFIX}/updateFilterActive`,
            method: "post",
            params: { moduleName, tabName },
            data: reqBody,
        }).then(ResHelper.handler);
    }

    public updateFilterArchive(
        moduleName: string,
        tabName: string,
        reqBody: any,
    ) {
        return BaseAxios({
            baseURL: this.baseURL,
            url: `${this.PRIFIX}/updateFilterArchive`,
            method: "post",
            params: { moduleName, tabName },
            data: reqBody,
        }).then(ResHelper.handler);
    }

    public createOrUpdateMultiFilter(
        moduleName: string,
        tabName: string,
        reqBody: any,
    ) {
        return BaseAxios({
            baseURL: this.baseURL,
            url: `${this.PRIFIX}/createOrUpdateMultiFilter`,
            method: "post",
            params: { moduleName, tabName },
            data: reqBody,
        }).then(ResHelper.handler);
    }

    public createOrUpdateViewFilter(
        moduleName: string,
        tabName: string,
        reqBody: any,
    ) {
        return BaseAxios({
            baseURL: this.baseURL,
            url: `${this.PRIFIX}/createOrUpdateViewFilter`,
            method: "post",
            params: { moduleName, tabName },
            data: reqBody,
        }).then(ResHelper.handler);
    }

    public deleteMultiFilter(
        moduleName: string,
        tabName: string,
        multiFilterId: string,
    ) {
        return BaseAxios({
            baseURL: this.baseURL,
            url: `${this.PRIFIX}/deleteMultiFilter`,
            method: "post",
            params: { moduleName, tabName, multiFilterId },
        }).then(ResHelper.handler);
    }

    public deleteViewFilter(
        moduleName: string,
        tabName: string,
        viewFilterId: string,
    ) {
        return BaseAxios({
            baseURL: this.baseURL,
            url: `${this.PRIFIX}/deleteViewFilter`,
            method: "post",
            params: { moduleName, tabName, viewFilterId },
        }).then(ResHelper.handler);
    }
}