<template>
  <!-- <div class="columns-setting"> -->
  <!-- <div
      class="button"
      :style="{ width: `${iconSize}px`, height: `${iconSize}px` }"
      @click="onBtnClick"
    >
      <svg-icon
        class="svg-icon"
        :sys="'common-table'"
        :name="'ColumnsSetting/gear'"
        :size="iconSize"
      />
    </div> -->

  <!-- <IconBtnSingle :iconSize="iconSize + 4 * 2" @btn-click="onBtnClick">
      <svg-icon
        class="svg-icon"
        :sys="'common-table'"
        :name="'ColumnsSetting/gear'"
        :size="iconSize"
      />
    </IconBtnSingle> -->

  <ElDrawer
    title="设置"
    :visible.sync="isDrawerVisible"
    :direction="'rtl'"
    :size="420"
    :before-close="beforeClose"
    :append-to-body="false"
    :wrapperClosable="true"
    :modal="false"
    @opened="onOpened"
  >
    <div class="drawer-content" v-loading="!table">
      <template v-if="table">
        <div class="columns-props-setting">
          <div class="title">
            <span>表头字段显示</span>
          </div>
          <div class="body">
            <div
              class="columns"
              v-if="columnsCopy && columnsCopy.length > 0"
              ref="container"
            >
              <template v-for="(col, index) in columnsCopy">
                <div
                  class="column drag-item"
                  :key="col.id + index"
                  :index="index"
                >
                  <div class="drag-handler">
                    <!-- <IconDrag :size="12" /> -->
                    <svg-icon
                      sys="common-table"
                      name="ColumnsSetting/drag"
                      :size="12"
                      :color="'#666'"
                    />
                  </div>
                  <div class="column-content">
                    <div class="title">
                      <span>{{ col.title }}</span>
                    </div>

                    <div class="switch">
                      <!-- <ElSwitch
                          v-model="col.active"
                          :disabled="col.alwaysActivated"
                          :active-color="SWITCH_ACTIVE_COLOR"
                          :inactive-color="SWITCH_INACTIVE_COLOR"
                          @change="onColActiveChange(col, $event)"
                        >
                        </ElSwitch> -->
                      <IconSwitch
                        v-model="col.active"
                        :disabled="col.alwaysActivated"
                        :iconSize="SWITCH_ICON_SIZE"
                        :activeColor="'#666'"
                        :inactiveColor="'#999'"
                        @change="onColActiveChange(col, $event)"
                      >
                        <template v-slot:active>
                          <svg-icon
                            sys="common-table"
                            name="ColumnsSetting/eye"
                            :size="SWITCH_ICON_SIZE"
                          />
                        </template>
                        <template v-slot:inactive>
                          <svg-icon
                            sys="common-table"
                            name="ColumnsSetting/eye-hidden"
                            :size="SWITCH_ICON_SIZE"
                          />
                        </template>
                      </IconSwitch>
                    </div>

                    <div class="select">
                      <MiniSelect
                        v-model="col.cellAlign"
                        :defaultValue="getDefaultCellAlign(col.type)"
                        :options="cellAlignOptions"
                        @change="onCellAlignSelectChange(col, $event)"
                      />
                    </div>
                  </div>
                </div>
              </template>
            </div>
          </div>
        </div>

        <template v-if="hasOperation">
          <div class="operation-btns-setting">
            <div class="title">
              <span>操作按钮显示</span>
            </div>
            <div class="body">
              <ElRadioGroup v-model="table.optBtnsPosition">
                <ElRadio :label="'last-col'">固定在表格最后一列</ElRadio>
                <ElRadio :label="'auto-hide'">自动隐藏</ElRadio>
                <ElRadio :label="'within-col'">
                  <ElSelect
                    v-model="optBtnsTargetId"
                    :disabled="table.optBtnsPosition !== 'within-col'"
                    :size="'mini'"
                    placeholder="请选择"
                  >
                    <template v-if="optBtnsTargetId" v-slot:prefix>在</template>
                    <ElOption
                      v-for="(col, index) in table.columns"
                      :key="col.id + index"
                      :label="col.title"
                      :value="col.id"
                    >
                    </ElOption>
                  </ElSelect>
                  列下
                </ElRadio>
                <ElRadio :label="'next-to-col'">
                  <ElSelect
                    v-model="optBtnsTargetId"
                    :disabled="table.optBtnsPosition !== 'next-to-col'"
                    :size="'mini'"
                    placeholder="请选择"
                  >
                    <template v-if="optBtnsTargetId" v-slot:prefix>在</template>
                    <ElOption
                      v-for="(col, index) in table.columns"
                      :key="col.id + index"
                      :label="col.title"
                      :value="col.id"
                    >
                    </ElOption>
                  </ElSelect>
                  右边
                </ElRadio>
              </ElRadioGroup>
            </div>
          </div>
        </template>

        <template v-if="hasDataStatistics">
          <div class="data-statistics-setting">
            <div class="title">
              <span>数据统计</span>
            </div>
            <div class="body" v-loading="!dataStatistics">
              <div class="statistics">
                <template v-if="dataStatistics && dataStatistics.length > 0">
                  <template v-for="(statistic, index) in dataStatistics">
                    <div class="statistic" :key="index">
                      <div class="title">
                        <span>{{ statistic.title }}</span>
                      </div>
                      <div class="switch">
                        <!-- <ElSwitch
                            v-model="statistic.active"
                            :active-color="SWITCH_ACTIVE_COLOR"
                            :inactive-color="SWITCH_INACTIVE_COLOR"
                            @change="onStatisticActiveChange(statistic, $event)"
                          >
                          </ElSwitch> -->
                        <IconSwitch
                          v-model="statistic.active"
                          :iconSize="SWITCH_ICON_SIZE"
                          :activeColor="'#666'"
                          :inactiveColor="'#999'"
                          @change="onStatisticActiveChange(statistic, $event)"
                        >
                          <template v-slot:active>
                            <svg-icon
                              sys="common-table"
                              name="ColumnsSetting/eye"
                              :size="SWITCH_ICON_SIZE"
                            />
                          </template>
                          <template v-slot:inactive>
                            <svg-icon
                              sys="common-table"
                              name="ColumnsSetting/eye-hidden"
                              :size="SWITCH_ICON_SIZE"
                            />
                          </template>
                        </IconSwitch>
                      </div>
                    </div>
                  </template>
                </template>
              </div>
            </div>
          </div>
        </template>
      </template>
    </div>
  </ElDrawer>
  <!-- </div> -->
</template>
<script lang="ts">
import Vue, { type DirectiveOptions, PropType } from "vue";
import {
  Drawer as ElDrawer,
  Switch as ElSwitch,
  RadioGroup as ElRadioGroup,
  Radio as ElRadio,
  Select as ElSelect,
  Option as ElOption,
} from "element-ui";

import SvgIcon from "@/flexible-table-module/svg-icon-importer";
import IconBtnSingle from "@/flexible-table-module/components/Common/IconBtnSingle.vue";

// @ts-ignore
import Sortable from "sortablejs";

import { Loading } from "@/flexible-table-module/components/Common/Loading";

import IconSwitch from "@/flexible-table-module/components/Common/IconSwitch.vue";
import MiniSelect from "@/flexible-table-module/components/Common/MiniSelect.vue";

import {
  OptBtnsPositionType,
  Table,
} from "@/flexible-table-module/entity/Table";
import { BaseColumn } from "@/flexible-table-module/entity/Table/BaseColumn";
import { Column } from "@/flexible-table-module/entity/Table/Column";
import { IBaseTableData } from "@/flexible-table-module/entity/Table/IBaseTableData";
import { DataStatistic } from "@/flexible-table-module/entity/Table/DataStatistic";
import {
  getDefaultCellAlign,
  SWITCH_ACTIVE_COLOR,
  SWITCH_INACTIVE_COLOR,
  // DEFAULT_CELL_ALIGN,
} from "@/flexible-table-module/constants";
import {
  TColumnaAlign,
  TColumnType,
} from "@/flexible-table-module/entity/Table/ColumnTypes";

const SWITCH_ICON_SIZE = 24;

export default Vue.extend({
  inheritAttrs: false,
  name: "ColumnsSetting",
  components: {
    ElDrawer,
    ElSwitch,
    ElRadioGroup,
    ElRadio,
    ElSelect,
    ElOption,
    SvgIcon,
    IconBtnSingle,
    IconSwitch,
    MiniSelect,
  },
  directives: { loading: Loading.directive },
  props: {
    table: { type: Table }, // 表格定义
    // iconSize: { type: Number, default: 20 },
    dataStatistics: { type: Array as PropType<DataStatistic[]> },
    hasDataStatistics: { type: Boolean, default: false }, // 是否有统计数据
    hasOperation: { type: Boolean, default: true }, // 是否有操作按钮
    drawerVisible: { type: Boolean, default: false },
  },
  data(): {
    // drawerVisible: boolean;
    hasInitial: boolean;
    SWITCH_ACTIVE_COLOR: string;
    SWITCH_INACTIVE_COLOR: string;
    columnsCopy?: Column<IBaseTableData>[];
    SWITCH_ICON_SIZE: number;
    // DEFAULT_CELL_ALIGN: TColumnaAlign;
    getDefaultCellAlign: (colType: TColumnType) => TColumnaAlign;
    cellAlignOptions: {
      key: TColumnaAlign;
      label: string;
      value: TColumnaAlign;
    }[];
  } {
    return {
      // drawerVisible: false,
      hasInitial: false,
      SWITCH_ACTIVE_COLOR,
      SWITCH_INACTIVE_COLOR,
      columnsCopy: undefined, // 因为调整顺序的库 Sortablejs 不会调整 Vue 的数据，只会调整 dom 的顺序，但业务数据需要传递给后端，故需要 copy 一个用于渲染 dom，原来的列数组用于调整顺序
      SWITCH_ICON_SIZE,
      // DEFAULT_CELL_ALIGN,
      getDefaultCellAlign,
      cellAlignOptions: [
        { key: "left", label: "左", value: "left" },
        { key: "center", label: "中", value: "center" },
        { key: "right", label: "右", value: "right" },
      ],
    };
  },
  computed: {
    isDrawerVisible: {
      get(): boolean {
        return this.drawerVisible;
      },
      set(val: boolean) {
        this.$emit("update:drawerVisible", val);
      },
    },
    // iconStyle(): object {
    //   const size = 12;
    //   return { width: `${size}px`, height: `${size}px` };
    // },
    optBtnsTargetId: {
      set(id: string) {
        const column = this.table.columns.find((col) => col.id === id);
        this.table.optBtnsTarget = column;
      },
      get(): string | undefined {
        return this.table.optBtnsTarget?.id;
      },
    },
  },
  watch: {
    "table.columns": {
      immediate: false,
      handler(
        newVal: Column<IBaseTableData>[],
        oldVal: Column<IBaseTableData>[]
      ) {
        // this.columnsCopy = newVal.map((col) => new Column<IBaseTableData>(col));
        if (newVal && newVal.length > 0) this.columnsCopy = [...newVal];
        else this.columnsCopy = [];
      },
    },
    "table.optBtnsPosition": {
      immediate: false,
      handler(newVal: OptBtnsPositionType, oldVal: OptBtnsPositionType) {
        if (oldVal === undefined) return; // 初始化时不触发

        if (newVal === "last-col" || newVal === "auto-hide") {
          this.table.optBtnsTarget = undefined;
          this.$emit("opt-btns-modify", this.table);
        }
      },
    },
    "table.optBtnsTarget": {
      immediate: false,
      handler(newVal: BaseColumn, oldVal: BaseColumn) {
        if (newVal === undefined) return; // table.optBtnsPosition === "last-col" 时不触发

        if (newVal && oldVal && newVal.id === oldVal.id) return; // 选择的列没有变化时不触发

        this.$emit("opt-btns-modify", this.table);
      },
    },
  },
  async mounted() {},
  methods: {
    // onBtnClick() {
    //   // this.drawerVisible = true;
    // },
    beforeClose(done: () => void) {
      done();
    },
    async onOpened() {
      await this.$nextTick();
      if (!this.hasInitial) {
        new Sortable(this.$refs["container"], {
          animation: 300,
          // direction: "vertical",
          handle: ".drag-handler",
          onEnd: (/**Event*/ evt: any) => {
            const {
              itemEl, // dragged HTMLElement
              to, // target list
              from, // previous list
              oldIndex, // element's old index within old parent
              newIndex, // element's new index within new parent
              oldDraggableIndex, // element's old index within old parent, only counting draggable elements
              newDraggableIndex, // element's new index within new parent, only counting draggable elements
              clone, // the clone element
              pullMode, // when item is in another sortable: `"clone"` if cloning, `true` if moving
            } = evt;

            this.table.columns.splice(
              newIndex,
              0,
              this.table.columns.splice(oldIndex, 1)[0]
            );

            this.$emit("column-sort", oldIndex, newIndex, this.table.columns);
          },
        });

        this.hasInitial = true;
      }
    },

    onColActiveChange(col: BaseColumn, val: any) {
      this.$emit("column-active-change", col);
    },

    onStatisticActiveChange(statistic: DataStatistic, val: any) {
      console.log("statistic :>> ", statistic);
      this.$emit("statistic-active-change", statistic);
    },

    onCellAlignSelectChange(col: BaseColumn, cellAlign: TColumnaAlign) {
      this.$emit("column-cell-align-change", col, cellAlign);
    },
  },
});
</script>
<style lang="scss" scoped>
@import "src/flexible-table-module/scss/_variables.scss";

// .columns-setting {
// width: 100%;
// height: 100%;
// display: flex;
// justify-content: center;
// align-items: center;

// .button {
//   &:hover {
//     cursor: pointer;
//   }
//   .svg-icon {
//   }
// }

.drawer-content {
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  padding: 16px 32px;

  .columns-props-setting,
  .operation-btns-setting,
  .data-statistics-setting {
    width: 100%;
    > .title {
      width: 100%;
      height: 32px;
      line-height: 32px;
      font-weight: bold;
      color: #666;
    }
  }

  .columns-props-setting {
    margin-bottom: 16px;
    .body {
      .columns {
        width: 100%;
        margin-bottom: 16px;

        .column {
          width: 100%;
          height: 32px;
          line-height: 32px;
          overflow: hidden;

          .title {
            height: 100%;
            float: left;
          }
          .select {
            height: 100%;
            float: right;
            margin-right: 16px;
            display: flex;
            justify-content: center;
            align-items: center;
          }
          .switch {
            height: 100%;
            float: right;
            display: flex;
            justify-content: center;
            align-items: center;
          }

          &.drag-item {
            user-select: none;
            display: flex;
            justify-content: flex-start;
            align-items: center;

            .drag-handler {
              display: flex;
              justify-content: center;
              align-items: center;

              cursor: grab;

              width: 32px;
              height: 100%;
            }

            .column-content {
              width: calc(100% - 32px);
              height: 100%;
            }
          }
        }
      }
    }
  }

  .operation-btns-setting {
    margin-bottom: 16px;
    .body {
      min-height: 64px;

      .el-radio-group {
        .el-radio {
          width: 100%;
          height: 32px;
          line-height: 32px;

          .el-select {
            width: 150px;
          }
        }
      }
    }
  }

  .data-statistics-setting {
    padding-bottom: 16px;
    .body {
      .statistics {
        .statistic {
          width: 100%;
          height: 32px;
          line-height: 32px;
          overflow: hidden;

          .title {
            float: left;
          }
          .switch {
            float: right;
          }
        }
      }
    }
  }
}
// }
</style>