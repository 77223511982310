<template>
  <ElDatePicker
    class="date-frame-picker"
    v-model="cTimeFrame"
    type="daterange"
    align="right"
    unlink-panels
    range-separator="-"
    start-placeholder="开始日期"
    end-placeholder="结束日期"
    :picker-options="pickerOptions"
    @change="onPickerChange"
  >
  </ElDatePicker>
</template>
<script lang="ts">
import Vue from "vue";
import { DatePicker as ElDatePicker } from "element-ui";

export default Vue.extend({
  inheritAttrs: false,
  name: "DateFramePicker",
  components: { ElDatePicker },
  model: {
    prop: "timeFrame",
    event: "change",
  },
  props: {
    timeFrame: { type: Array, default: undefined },
  },
  data() {
    return {
      pickerOptions: {
        shortcuts: [
          {
            text: "最近一周",
            onClick(picker: any) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近一个月",
            onClick(picker: any) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近三个月",
            onClick(picker: any) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit("pick", [start, end]);
            },
          },
        ],
      },
    };
  },

  computed: {
    cTimeFrame: {
      get(): [Date, Date] | undefined {
        return this.timeFrame as [Date, Date] | undefined;
      },
      set(val: [Date, Date] | null) {
        if (val === null) this.$emit("change", undefined);
        else this.$emit("change", val);
      },
    },
  },

  methods: {
    async onPickerChange(value?: [Date, Date]) {
      await this.$nextTick();
      this.$emit("date-frame-change", this.timeFrame);
    },
  },
});
</script>

<style lang="scss" scoped>
.date-frame-picker {
  &.el-input__inner {
    border: unset;
    background-color: transparent;
    ::v-deep {
      .el-range-input {
        background-color: transparent;
      }
      .el-input__icon {
        vertical-align: middle;
      }
    }
  }
}
</style>