var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"web-page"},[(_vm.current)?_c('div',{staticClass:"web-page-top flex"},[_c('img',{attrs:{"src":_vm.current.article.coverList[0]
          ? _vm.current.article.coverList[0].coverUrl
          : require('@/assets/images/sample-1.png'),"alt":""}}),_c('div',{staticClass:"top-text"},[_c('h2',[_vm._v(_vm._s(_vm.current.article.articleName))]),_c('div',[_vm._v(" "+_vm._s(_vm.current.article.introduce)+" ")])])]):_vm._e(),(_vm.current)?_c('div',{staticClass:"web-page-main"},[_c('div',[_c('h3',{staticClass:"title-text"},[_vm._v("产品简介")]),_c('p',{staticClass:"title-underline"}),_c('div',{staticClass:"web-page-main-richtext",domProps:{"innerHTML":_vm._s(_vm.current.article.detailContent)}})]),(_vm.loginrequired)?[(_vm.current.articleToSubjects && _vm.current.articleToSubjects.length)?_c('div',[_c('h3',{staticClass:"title-text"},[_vm._v("相关产品")]),_c('p',{staticClass:"title-underline"}),_c('div',{staticClass:"web-page-main-relevant"},_vm._l((_vm.current.articleToSubjects),function(item){return _c('div',{key:item.id,staticClass:"relavant-card",on:{"click":function($event){return _vm.handleClickSubject(item)}}},[_c('img',{staticStyle:{"width":"100%"},attrs:{"src":item.coverList && item.subjectCoverVos[0].coverUrl
                  ? item.subjectCoverVos[0].coverUrl
                  : require(`@/assets/images/sample-${_vm.common.getLastChar(
                      item.subjectId
                    )}.png`),"alt":""}}),_c('div',{staticClass:"one-line"},[_vm._v(_vm._s(item.subjectName))])])}),0)]):_vm._e(),(_vm.current.articleToArticles && _vm.current.articleToArticles.length)?_c('div',[_c('h3',{staticClass:"title-text"},[_vm._v("相关文章")]),_c('p',{staticClass:"title-underline"}),_c('div',{staticClass:"web-page-main-relevant"},_vm._l((_vm.current.articleToArticles),function(item){return _c('div',{key:item.id,staticClass:"relavant-card",on:{"click":function($event){return _vm.handleClickArticle(item)}}},[_c('img',{staticStyle:{"width":"100%"},attrs:{"src":item.coverList[0].coverUrl
                  ? item.coverList[0].coverUrl
                  : require('@/assets/images/sample-1.png'),"alt":""}}),_c('div',{staticClass:"one-line"},[_vm._v(_vm._s(item.toArticleName))])])}),0)]):_vm._e()]:_vm._e()],2):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }