<template>
  <div class="single-conditions-editor">
    <table class="layout">
      <template v-for="(filters, key) in filterGroups">
        <tr class="category" :key="key">
          <td class="category-name">
            <div class="category-name-inner">
              <span>{{ key === DEFAULT_CATEGORY ? "" : key }}</span>
            </div>
          </td>

          <td class="conditions">
            <div class="conditions-inner">
              <template v-for="(filter, index) in filters">
                <div class="condition" :key="index">
                  <el-checkbox
                    class="condition-title-checkbox"
                    :value="!filter.archived"
                    @change="toggleArchive(filter)"
                    >{{ filter.title }}</el-checkbox
                  >
                </div>
              </template>
            </div>
          </td>
        </tr>
      </template>
    </table>
    <SubmitAndCancel @submit="onSubmit" @cancel="onCancel" />
  </div>
</template>
<script lang="ts">
import Vue, { PropType } from "vue";
import { Checkbox as ElCheckbox } from "element-ui";
import { Filter } from "@/flexible-table-module/entity/Filter";
import SubmitAndCancel from "../../Common/SubmitAndCancel.vue";

const DEFAULT_CATEGORY = "未分类";

// tip: single condition = single filter = new Filter()

export default Vue.extend({
  inheritAttrs: false,
  components: { ElCheckbox, SubmitAndCancel },
  props: {
    // filtersArchived: { type: Array as PropType<Filter[]> }, // 已归档的过滤器列表
    filtersAll: { type: Array as PropType<Filter[]> }, // 全部的过滤器列表
  },
  data() {
    return {
      DEFAULT_CATEGORY,
      dFiltersAll: [] as Filter[],
      isChanged: false,
    };
  },
  computed: {
    filterGroups(): Record<string, Filter[]> {
      // 根据 Filter 的 category 属性进行分组

      if (!this.dFiltersAll) {
        return {};
      }

      const filtersGroupByCategory = this.dFiltersAll.reduce(
        (acc: Record<string, Filter[]>, filter) => {
          const category = filter.category || DEFAULT_CATEGORY;
          if (!acc[category]) {
            acc[category] = [];
          }
          acc[category].push(filter);
          return acc;
        },
        {}
      );

      return filtersGroupByCategory;
    },
  },
  watch: {
    filtersAll: {
      immediate: true,
      handler(filtersAll: Filter[]) {
        // filtersAll 的克隆，便于在界面被用户修改属性后，但用户取消操作时，恢复原始状态
        this.refreshFiltersAll(filtersAll);
      },
    },
  },
  methods: {
    $refreshFiltersAll() {
      this.refreshFiltersAll(this.filtersAll);
    },
    toggleArchive(filter: Filter) {
      filter.archived = !filter.archived;
      this.isChanged = true;
    },
    onSubmit() {
      if (this.isChanged) {
        const filtersChanged = [];
        const filtersUnarchived = [];
        const filtersArchived = [];

        for (let i = 0; i < this.filtersAll.length; i++) {
          const filter = this.filtersAll[i];
          const dFilter = this.dFiltersAll.find((df) => df.id === filter.id);

          // 找出 dFiltersAll 中被修改过的 filter，将其 archived 属性同步到 filtersAll 中的对应 filter
          if (dFilter && filter.archived !== dFilter.archived) {
            if (dFilter.archived) {
              filtersArchived.push(filter);
            } else {
              filtersUnarchived.push(filter);
            }
            filter.archived = dFilter.archived;
            filtersChanged.push(filter);
          }
        }

        this.$emit("submit", filtersChanged);
        this.isChanged = false;
      } else {
        this.$emit("cancel");
      }
    },
    onCancel() {
      if (this.isChanged) {
        this.refreshFiltersAll(this.filtersAll);
      }

      this.$emit("cancel");
    },
    refreshFiltersAll(filtersAll: Filter[]) {
      this.dFiltersAll = filtersAll.map((filter) => {
        return filter.clone();
      });
    },
  },
});
</script>
<style lang="scss" scoped>
.single-conditions-editor {
  table {
    // width: 100%;
    border-collapse: collapse;
    border-spacing: 0;

    td {
      padding: 8px;
    }

    &.layout {
      .category {
        .category-name {
          .category-name-inner {
            font-weight: bold;
          }
        }
      }

      .conditions {
        .conditions-inner {
          display: flex;
          flex-wrap: wrap;
          justify-content: flex-start;

          .condition {
            &:not(:last-child) {
              margin-right: 12px;
            }

            .condition-title-checkbox {
              &.el-checkbox {
                ::v-deep {
                  .el-checkbox__label {
                    padding-left: 4px;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
</style>