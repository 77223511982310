/*
 * @Author: Billy
 * @Date: 2023-06-08 10:02:10
 * @LastEditors: Billy
 * @LastEditTime: 2023-06-08 10:21:24
 * @Description: 请输入
 */

export default class HistoryStep {
    public readonly id: string = uuidv4();

    constructor(params: {
        onUndo?: () => boolean | void,
        onRedo?: () => boolean | void,
    }) {
        const { onUndo, onRedo } = params;

        this.onUndo = onUndo ? onUndo : () => { };
        this.onRedo = onRedo ? onRedo : () => { };
    }

    /**
     * 回退本次步骤(Ctrl + Z)
     * @returns 成功则返回 true，或不返回；返回 false 表示当前历史步骤不会从历史列表中清除
     * @throws {Error} 回滚出错，但当前历史步骤将从历史列表中清除
     */
    public onUndo: () => boolean | void;

    /**
     * 重做本次步骤(Ctrl + Y)
     * @returns 成功则返回 true，或不返回；返回 false 表示当前重做步骤不会从重做列表中清除
     * @throws {Error} 重做出错，但当前重做步骤将从重做列表中清除
     */
    public onRedo: () => boolean | void;
}

/**
 * 生成uuid/guid
 * @see https://stackoverflow.com/questions/105034/how-to-create-guid-uuid
 * @returns {string} uuid 字符串
 */
function uuidv4(): string {
    return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
        const r = Math.random() * 16 | 0,
            v = c == "x" ? r : (r & 0x3 | 0x8);
        return v.toString(16);
    });
}