import { TFilterTargetType, TFilterType } from "./ColumnTypes";
import { KeyStrValuePair } from "../KeyValuePair";
import { BaseFilter, IFilterListItem } from "../BaseFilter";

export interface ColumnFilter extends BaseFilter {
    /** 筛选类型 */
    type?: TFilterType;
    /** 需要筛选的内容(详情看下面的实现类)(传给后端) */
    target?: TFilterTargetType;
    /** 
     * 供筛选的列表(只有在 type 等于 select 时有效) 
     */
    selectList?: KeyStrValuePair[] /* | (() => KeyStrValuePair[] | Promise<KeyStrValuePair[]>) */;
}

export class StringFilter implements ColumnFilter {
    public id?: string;
    public type: 'string-search' | 'string-select';
    public target?: string | string[];
    public selectList?: KeyStrValuePair[] /* | (() => KeyStrValuePair[] | Promise<KeyStrValuePair[]>) */;
    public list?: IFilterListItem[];

    constructor(params: {
        id?: string,
        type: 'string-search' | 'string-select',
        target?: string | string[],
        selectList?: KeyStrValuePair[] /* | (() => KeyStrValuePair[] | Promise<KeyStrValuePair[]>) */,
        list?: IFilterListItem[],
    }) {
        const { id, type, target, selectList, list } = params;
        this.id = id;
        this.type = type;
        this.target = target;
        this.selectList = selectList;
        this.list = list;
    }
}

export class NumberFilter implements ColumnFilter {
    public id?: string;
    public type: 'number';
    public target?: [number | null, number | null];
    public min?: number;
    public max?: number;

    constructor(params: {
        id?: string,
        target?: [number | null, number | null],
        min?: number,
        max?: number,
    }) {
        this.type = 'number';

        const { id, target, min, max } = params;
        if (min && max && min >= max) throw new Error('min 不可比 max 数值大');
        this.target = target;
        this.id = id;
        this.min = min;
        this.max = max;
    }
}

export class DateTimeFilter implements ColumnFilter {
    public id?: string;
    public type: 'date-time-frame' | 'date-time-single';
    public target?: Date | [Date, Date];

    constructor(params: {
        id?: string,
        type: 'date-time-frame' | 'date-time-single',
        target?: Date | [Date, Date],
    }) {
        const { id, type, target } = params;

        this.id = id;
        this.type = type;
        this.target = target;
    }
}

export class KeyStrValueFilter implements ColumnFilter {
    public id?: string;
    public type: 'key-str-value-search' | 'key-str-value-select';
    public target?: string | string[]; // string 指 search 的字符串内容；string[] 指 select 勾选的键值对的键的集合
    public selectList?: KeyStrValuePair[] /* | (() => KeyStrValuePair[] | Promise<KeyStrValuePair[]>) */;

    constructor(params: {
        id?: string,
        type: 'key-str-value-search' | 'key-str-value-select',
        target?: string | string[],
        selectList?: KeyStrValuePair[] /* | (() => KeyStrValuePair[] | Promise<KeyStrValuePair[]>) */,
    }) {
        const { id, type, target, selectList } = params;
        this.id = id;
        this.type = type;
        this.target = target;
        this.selectList = selectList;
    }
}