import request from "@/utils/appServer";
class Esengine {
  // 查询相关操作-全文检索bool列表查询高亮
  static async boolQueryHighlight(data) {
    try {
      const res = await request.get('/sales-show-4.0/es/queryEsData/boolQueryHighlight', { params: data });
      return res.data.code === 200 && res.data.result ? res.data.result : {};
    } catch (error) {
      return {};
    }
  }
  // 查询相关操作-全文检索自动补全
  static async suggestionQuery(value) {
    try {
      const res = await request.get('/sales-show-4.0/es/queryEsData/suggestionQuery', { params: { value } });
      return res.data.code === 200 && res.data.result ? res.data.result : [];
    } catch (error) {
      return [];
    }
  }
}

export { Esengine }