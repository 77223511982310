<template>
  <div class="corner-tip" @click="onClick">
    <OverflowTooltip :content="cellValueAfterFormat">
      <span class="tip-inner">{{ cellValueAfterFormat }}</span>
    </OverflowTooltip>
  </div>
</template>
<script lang="ts">
import Vue from "vue";
import OverflowTooltip from "@/flexible-table-module/components/Common/OverflowTooltip.vue";
import { Column } from "@/flexible-table-module/entity/Table/Column";

export default Vue.extend({
  inheritAttrs: false,
  name: "CornerTip",
  components: { OverflowTooltip },
  props: {
    column: { type: Column },
    row: { type: Object },
    cellValueBeforeFormat: {},
    cellValueAfterFormat: { type: String },
  },
  methods: {
    onClick() {
      this.$emit("click", this.row, this.column);
    },
  },
});
</script>
<style lang="scss" scoped>
@import "src/flexible-table-module/scss/_variables.scss";

$tip-vertical-padding: 4px;
$tip-horizontal-padding: 4px;

.corner-tip {
  font-size: $fs-corner-tip;

  user-select: none;
  cursor: pointer;
  box-sizing: border-box;
  position: absolute;
  top: 0;
  right: 0;
  color: $color-reverse;
  background-color: $element--color-primary;
  padding: $tip-vertical-padding $tip-horizontal-padding;
  border-radius: 0 8px 0 8px;
  max-width: 50%;
  min-width: $tip-horizontal-padding * 2 + $fs-corner-tip * 2;
  height: $fs-corner-tip + $tip-vertical-padding * 2;
  line-height: $fs-corner-tip + $tip-vertical-padding * 2;
  display: flex;
  justify-content: center;
  align-items: center;

  // .tip-inner {
  // }
}
</style>