<template>
  <div class="product-association" :class="{ 'product-association-preview': ispre }">
    <div class="association-subject-wrapper" @drop="handleDrop" @dragover="allowDrop">
      <div class="association-mian" ref="containwrapper" :style="dynamicStyle">
        <div
          class="flex association-main-card"
          :class="{ 'active-item': selectproductids.includes(i.contentId) && isedit }"
          v-for="i in assproductList"
          :key="i.id"
          @click="handleClick(i)"
        >
          <img
            :src="
              i.toSubjectCoverVos[0]
                ? i.toSubjectCoverVos[0].coverUrl
                : require(`@/assets/images/sample-${common.getLastChar(i.contentId)}.png`)
            "
            alt=""
          />
          <div
            class="association-main-name"
            :class="{
              'active-color': currentItem && currentItem.article.id == i.articleId,
            }"
          >
            <div :title="i.toSubjectName" class="one-line">{{ i.toSubjectName }}</div>
          </div>
          <i
            v-if="!ispre"
            class="el-icon-remove association-main-card-delete"
            @click.stop.prevent="handleDelete(i)"
          ></i>
        </div>
      </div>
    </div>
    <!-- <div class="association-library-wrapper" v-if="!ispre">
      <Toolcenter :accept="[1]" :selectids="selectids" />
    </div> -->
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { ProductLibrary } from "@/api/ProductLibrary";
export default {
  components: {},
  props: {
    activeColumn: Object,
    subject: Object,
    scheme: Object,
    ispre: {
      type: Boolean,
      default: false,
    },
    isedit: {
      type: Boolean,
      default: false,
    },
    alreadychose: Array,
    schemeid: String,
    updatekey: Number,
  },
  data() {
    return {
      dialogVisible: false,
      assproductList: [],
      currentItem: null,
      selectids: [], //已经选择的产品id合集
      itemsPerRow: 0, // 每行放置的元素个数
      resizeObserver: null,
      selectproductids: [], //方案选择的产品id合集
    };
  },
  watch: {
    seleceassData(val) {
      if (val) {
        this.addSubject(val);
      }
    },
    schemeid(val) {
      val && this.getdefaultTableData();
    },
    ispre(val) {
      if (!val) {
        this.$Pubsub.publish("update-resource-visible", { visible: true, type: [1] });
      } else {
        this.$Pubsub.publish("update-resource-visible", { visible: false, type: [] });
      }
    },
    updatekey() {
      this.schemeid && this.getdefaultTableData();
    },
  },
  computed: {
    dynamicStyle() {
      return {
        gridTemplateColumns: `repeat(${this.itemsPerRow}, 1fr)`, // 根据每行放置的元素个数动态设置 Grid 布局的列数
      };
    },
    ...mapGetters(["seleceassData"]),
  },
  mounted() {
    this.getList();
    if (!this.ispre) {
      this.$Pubsub.publish("update-resource-visible", { visible: true, type: [1] });
    }
    this.resizeObserver = new ResizeObserver(this.calculateItemsPerRow);
    this.resizeObserver.observe(this.$refs.containwrapper);
    this.schemeid && this.getdefaultTableData();
  },
  beforeDestroy() {
    this.resizeObserver.disconnect();
  },
  methods: {
    /**
     * @description: 计算放置元素个数
     * @return {void}
     */
    calculateItemsPerRow() {
      if (!this.$refs.containwrapper) {
        return;
      }
      const containerWidth = this.$refs.containwrapper.offsetWidth; // 获取容器宽度
      const itemWidth = 200;
      this.itemsPerRow = Math.floor(containerWidth / itemWidth); // 计算每行放置的元素个数
    },
    /**
     * @description: 更新关联内容列表
     * @return {void}
     */
    async getList() {
      const res = await ProductLibrary.getSubjectToContentList({
        columnId: this.activeColumn.id,
        subjectId: this.subject.id,
      });
      this.assproductList = res;
      this.selectids = [];
      if (this.assproductList.length) {
        this.selectids = this.assproductList.map((item) => item.contentId);
      }
      // this.selectids.push(this.subject.id);
      this.$Pubsub.publish("update-resource-select-data", this.selectids);
    },
    /**
     * @description: 预览产品
     * @param {Object} value 产品
     * @return {void}
     */
    async handleClick(value) {
      if (this.isedit) {
        // 选择
        if (this.selectproductids.includes(value.contentId)) {
          this.selectproductids = this.selectproductids.filter(
            (item) => item != value.contentId
          );
        } else {
          this.selectproductids.push(value.contentId);
        }
        this.handleSelect()
      } else {
        // 预览
        const payload = {
          subjectId: value.contentId,
          mirrorId: value.toMirrorId,
          versionId: value.toVersionId,
        };
        const routeData = this.$router.resolve({
          path: "/column-preview",
          query: payload,
        });
        window.open(routeData.href);
      }
    },
    /**
     * @description: 删除
     * @param {Object} value
     * @return {void}
     */
    handleDelete(value) {
      this.$showMessage(
        {
          message: `是否确认删除 <span style="font-weight: 1000;">${value.toSubjectName}</span> ?`,
          ...this.$global.deleteMessage,
        },
        async (done) => {
          const res = await ProductLibrary.deleteSubjectToContent(value.id);
          res && this.getList();
          done(true);
        }
      );
    },
    /**
     * @description: 添加拖拽过来的产品
     * @return {void}
     */
    async handleDrop(event) {
      if (this.ispre) {
        return;
      }
      event.preventDefault();
      const subject = JSON.parse(event.dataTransfer.getData("dragsubject"));
      const res = await ProductLibrary.saveSubjectToContent({
        contentIds: [subject.id],
        columnId: this.activeColumn.id,
        subjectId: this.subject.id,
      });
      if (res) {
        this.getList();
      }
    },
    /**
     * @description: 添加全局搜索选择的产品
     * @return {void}
     */
    async addSubject(subject) {
      if (this.ispre) {
        return;
      }
      const res = await ProductLibrary.saveSubjectToContent({
        contentIds: [subject.id],
        columnId: this.activeColumn.id,
        subjectId: this.subject.id,
      });
      if (res) {
        this.getList();
      }
    },
    // 拖拽事件
    allowDrop(event) {
      event.preventDefault();
    },
    /**
     * @description:获取已选列表
     * @return {void}
     */
    async getdefaultTableData() {
      const res = await ProductLibrary.getSchemeToContentList({
        schemeId: this.schemeid,
        subjectColumnId: this.activeColumn.id,
      });
      this.selectproductids = res.map((item) => item.dataId);
    },
    /**
     * @description: 选择数据
     * @return {void}
     */
    handleSelect() {
      const selectdata = {
        dataIds: this.selectproductids,
        dataType: 3,
        // id: "",
        schemeId: this.schemeid,
        // sortNo: 0,
        subjectColumnId: this.activeColumn.id,
        subjectId: this.subject.id,
      };
      this.$emit("handleselect", selectdata);
    },
  },
};
</script>
<style lang="scss" scoped>
.product-association {
  display: flex;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  background-color: #fff;
  overflow-y: auto;
  .association-subject-wrapper {
    // flex: 1;
    width: 100%;
    height: 100%;
    overflow: hidden;
  }
  .association-mian {
    // flex: 1;
    width: 100%;
    // overflow-y: auto;
    padding: 20px;
    box-sizing: border-box;
    display: grid;
    grid-gap: 18px 18px;
    .association-main-card {
      height: 200px;
      min-width: 180px;
      width: 100%;
      box-shadow: 0px 0px 13px -2px #ccc;
      border-radius: 5px;
      display: flex;
      flex-direction: column;
      border: 1px solid #ebeef2;
      box-sizing: border-box;
      position: relative;
      &:hover {
        .association-main-card-delete {
          display: block;
        }
      }
      img {
        width: 100%;
        height: 150px;
      }
      .association-main-name {
        color: var(--color-text-primary);
        padding: 10px 0 10px 10px;
        font-size: 14px;
        font-weight: 700;
      }

      .association-main-card-delete {
        position: absolute;
        right: -10px;
        top: -10px;
        font-size: 20px;
        color: var(--color-danger);
        display: none;
      }
    }
    .active-item {
      border: 2px solid #8b6bff;
      position: relative;
      &::before {
        content: "";
        z-index: 2;
        position: absolute;
        top: 0;
        right: 0;
        width: 22px;
        height: 22px;
        background-image: url("@/assets/images/productLibrary/column_checked.png");
        background-size: 22px;
        background-repeat: no-repeat;
      }
    }
  }
  .association-library-wrapper {
    height: 100%;
    // width: 460px;
    width: 323px;
    border-left: 1px solid #ebeef2;
  }
}
.active-color {
  color: var(--color-primary);
}
</style>
