const resouece = {
  state: {
    seleceassData: {},//搜索选择的资源
    temporaryProductQuery: null,//缓存的请求参数
  },
  getters: {
    seleceassData: state => state.seleceassData,
    temporaryProductQuery: state => state.temporaryProductQuery,
  },
  mutations: {
    SaveseleceassData(state, payload = {}) {
      state.seleceassData = payload;
    },
    SavetemporaryProductQuery(state,payload) {
      state.temporaryProductQuery = payload;
    },
  }
};
export default resouece