<template>
  <div class="form-cell-4-string">
    <ElInput
      :class="{ highlight }"
      v-model="cValue"
      :size="FORM_ITEM_SIZE"
    ></ElInput>
  </div>
</template>
<script lang="ts">
import Vue from "vue";
import { mixin } from "./mixin";

import { Input as ElInput } from "element-ui";

export default Vue.extend({
  inheritAttrs: false,
  name: "FormCell4String",
  components: { ElInput },
  mixins: [mixin],
});
</script>
<style lang="scss" scoped>
@import "src/flexible-table-module/scss/common.scss";
.form-cell-4-string {
  @include form-cell-highlight;
}
</style>