<template>
  <div class="second-column" :class="{ 'is-preview': isPreview }">
    <div class="second-column-header" v-if="!ispre">
      <div class="flex align-items-center">
        <div class="column-navigation-bar flex">
          <!-- <div class="breadcrumb-item flex">
            <div class="breadcrumb-inner">{{ parentcolumn.columnName }}</div>
            <div class="breadcrumb-separator el-icon-arrow-right"></div>
          </div> -->
          <div class="breadcrumb-item">
            <!-- <div class="breadcrumb-inner">{{ clickitem.columnName }}</div> -->
            <!-- <div class="breadcrumb-separator"></div> -->
          </div>
        </div>
      </div>
      <div>
        <el-button size="small" type="info" @click="readonly = !readonly">{{
          readonly ? "退出预览" : "预&nbsp;览"
        }}</el-button>
        <el-button
          size="small"
          type="primary"
          :loading="confirmLoading"
          @click="confirmSave"
          >保&nbsp;存</el-button
        >
      </div>
    </div>
    <div class="second-column-main">
      <div class="w-h-100" :class="{ preview: readonly }">
        <WebEdit
          v-show="!readonly"
          ref="webedit"
          :readonly="readonly"
          :value.sync="richText"
          :module-id="queryIds.moduleId"
          :subject-id="queryIds.subjectId"
          :datatype="1"
        />
        <iframe
          v-show="readonly"
          id="myiframe"
          src=""
          frameborder="0"
          height="100%"
          width="100%"
        ></iframe>
      </div>
    </div>
  </div>
</template>

<script>
import WebEdit from "@/components/WebEdit.vue";
import { ProductLibrary } from "@/api/ProductLibrary";
export default {
  components: { WebEdit },
  props: {
    ispre: {
      type: Boolean,
      default: false,
    },
    visible: {
      type: Boolean,
      default: false,
    },
    clickitem: Object,
    parentcolumn: Object,
    queryIds: Object,
  },
  data() {
    return {
      richText: "",
      confirmLoading: false,
      queryData: {
        content: "",
        id: "",
        subjectColumnId: "",
      },
      readonly: false,
    };
  },
  watch: {
    clickitem(val) {
      this.getDetail();
    },
    readonly(val) {
      if (val) {
        this.handleRender();
      }
    },
  },
  computed: {
    isPreview() {
      this.readonly = this.ispre;
      return this.ispre;
    },
    dialogVisible: {
      get() {
        return this.visible;
      },
      set(val) {
        this.$emit("update:visible", val);
      },
    },
  },
  mounted() {
    // this.richText1 = this.richText;
    this.getDetail();
  },
  methods: {
    /**
     * @description:获取介绍详情
     * @return {void}
     */
    async getDetail() {
      const res = await ProductLibrary.getSettingSubjectColumnContent(this.clickitem.id);
      this.queryData.subjectColumnId = res[0].subjectColumnId;
      this.queryData.id = res[0].id;
      this.richText = res[0].content ? res[0].content : "";
      if (this.readonly) {
        this.handleRender(this.richText);
      }
    },
    /**
     * @description:保存
     * @return {void}
     */
    async confirmSave() {
      this.confirmLoading = true;
      this.queryData.content = this.$refs.webedit
        ? this.$refs.webedit.getHTMLText()
        : this.richText;
      await ProductLibrary.saveChildColumnContent(this.queryData);
      this.getDetail();
      this.confirmLoading = false;
    },
    /**
     * @description:使用iframe进行加载预览
     * @return {void}
     */
    handleRender(value) {
      let iframe = document.getElementById("myiframe");
      iframe.src = "about:blank";
      const text = value ? value : this.$refs.webedit.getHTMLText();
      setTimeout(() => {
        iframe && iframe.contentDocument.write(text);
      }, 100);
    },
  },
};
</script>

<style lang="scss" scoped>
.second-column {
  width: 100%;
  min-width: 800px;
  display: flex;
  flex-direction: column;
  height: 100%;
  margin: 0 auto;
  background-color: #fff;

  ::v-deep .ck.ck-content {
    padding-bottom: 110px;
  }

  .second-column-header {
    width: 100%;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 10px;
    box-sizing: border-box;
    border-bottom: 1px solid #ebeef2;

    .column-navigation-bar {
      .breadcrumb-item {
        font-size: 14px;
        font-weight: 500;
        color: var(--color-text-placeholder);
        display: flex;
        align-items: center;
        user-select: none;

        &:last-child {
          color: var(--color-text-secondary);

          &:hover {
            color: var(--color-text-primary);
          }

          .breadcrumb-separator {
            display: none;
          }
        }

        .breadcrumb-inner {
          line-height: 22px;
        }
      }
    }
  }

  .second-column-main {
    flex: 1;
    display: flex;
    justify-content: center;
    overflow: hidden;

    .preview {
      max-width: 1080px;
    }
  }
}

.bold {
  font-weight: bold;
  color: var(--color-text-primary);
}
</style>
