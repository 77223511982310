<template>
  <ElPagination
    class="pagination-wrapper"
    background
    popper-class="pagination-wrapper-popper"
    v-bind="$attrs"
    v-on="$listeners"
  >
  </ElPagination>
</template>
<script lang="ts">
import Vue from "vue";
import { Pagination as ElPagination } from "element-ui";

export default Vue.extend({
  name: "PaginationWrapper",
  components: { ElPagination },
});
</script>

<style lang="scss" scoped>
$border-color: rgba(191, 202, 222);
$border-radius: 4px;
$font-size: 12px;
$font-color: rgba(94, 108, 132);

$active-color: rgba(54, 94, 255);
$disabled-color: rgba(211, 218, 230);

$hover-bg-color: rgba(224, 236, 255);

.pagination-wrapper {
  ::v-deep {
    .el-pagination__sizes {
      .el-select {
        .el-input {
          .el-input__inner {
            border-color: $border-color;
            border-radius: $border-radius;
            font-size: $font-size;
            color: $font-color;
          }
        }
      }
    }

    button {
      &.btn-prev,
      &.btn-next {
        font-size: $font-size;
        border-radius: $border-radius;
        border: 1px solid $border-color;
        background-color: #fff;
        color: $font-color;

        &:disabled {
          color: $disabled-color;
        }

        &:not(:disabled):hover {
          color: $active-color;
          border-color: $active-color;
          background-color: $hover-bg-color;
        }
      }
    }

    .el-pager {
      .number,
      .btn-quicknext,
      .btn-quickprev {
        line-height: 26px;
        font-weight: 500;
        font-size: $font-size;
        border-radius: $border-radius;
        border: 1px solid $border-color;
        background-color: #fff;
      }

      .number {
        &:not(.disabled) {
          &.active {
            color: #fff;
            background-color: $active-color;
          }

          &:not(.active):hover {
            color: $active-color;
            border-color: $active-color;
          }
        }
      }

      .btn-quicknext,
      .btn-quickprev {
        &:hover {
          color: $active-color;
          border-color: $active-color;
          background-color: $hover-bg-color;
        }
      }
    }
  }
}
</style>
<style lang="scss">
$active-color: rgba(54, 94, 255);

.pagination-wrapper-popper {
  .el-select-dropdown__item {
    &.selected {
      color: $active-color;
    }
  }
}
</style>
