import { File } from "@/api/File";
import NETSYSTEM from "@/api/NETSYSTEM";
import { saveAs } from "file-saver";
import store from '@/store';

/**
 * @description: 下载文件，文件流方式下载
 * @param {Object} value 点击项
 * @return {void}
 */
export const AppDownloadByUrl = async (value, isCurrent = 1) => {
  const res = await File.downLoadFile({
    fileId: value.id,
    isCurrent: isCurrent
  })
  if (res) {
    const suffix = res.downUrl.split(".").pop();
    const fileNameSuffix = res.fileName.split(".").pop();
    let fileName = res.fileName;
    if (suffix !== fileNameSuffix) {
      fileName = fileName + "." + suffix;
    }
    saveAs(res.downUrl, fileName);
  }
};
//用于在更新上传文件时自动处理版本号的方法
export const handleFileVersion = (fileVersion) => {
  const endNum = new RegExp("[0-9]*([\.][0-9]*)?$"); //匹配字符串结尾的形如 x.x 的数字
  const numArr = fileVersion.match(endNum);
  let num;
  let newStr;
  if (numArr && numArr.length) {
    num = numArr[0];
    if (num !== '0' && num !== "") {
      num = Number(num) + 1;
      newStr = fileVersion.replace(numArr[0], num);
      const check = newStr.indexOf('.');
      if (check < 0) {
        newStr = newStr + '.0'
      }
    } else if (num.length === 0) {
      num = 1;
      newStr = (fileVersion + num) + '.0';
    } else {
      num = 1;
      newStr = fileVersion.replace(numArr[0], num);
      newStr = newStr + '.0';
    }
  } else {
    num = 1;
    newStr = fileVersion + num;
  }
  return newStr;
}
/**
 * @description: 批量删除
 * @param {Object} payload 参数
 * @return {void}
 */
export const deleteBatch = async (payload) => {
  try {
    const response = await fetch(`${process.env.VUE_APP_URL}/sales-show-4.0/file/deleteBatch`, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        'X-Access-Token': store.state.local.token ? store.state.local.token : "",
        'Tenant-Id': store.state.local.userInfo ? store.state.local.userInfo.tenantIdNow : ""
      },
      body: JSON.stringify(payload)
    });
    const data = await response.json();
    return data.success;
  } catch (error) {
    console.error('Error:', error);
  }
};
/**
 * @description: 文件预览,打开新窗口
 * @param {String} id 文件id
 * @param {String} url 文件路径
 * @return {void}
 */
export const officeView = async (id, url) => {
  // const type = url.split(".").pop();
  // if (type == "ppt" || type == "pptx") {
  //   PPTView(id)
  // } else {
  //   const res = await NETSYSTEM.viewOrEditV1({
  //     fileId: id,
  //     optType: 'view'//操作类型（view:预览，edit:编辑）
  //   })
  //   if (res) {
  //     // 打开新窗口
  //     const newWin = window.open("", "_blank");
  //     newWin.document.write(res);
  //   }
  // }
  const res = await NETSYSTEM.viewOrEditV1({
    fileId: id,
    optType: 'view'//操作类型（view:预览，edit:编辑）
  })
  if (res) {
    // 打开新窗口
    const newWin = window.open("", "_blank");
    newWin.document.write(res);
  }

}
/**
 * @description: PPT文件预览,打开新窗口
 * @param {String} id 文件id
 * @return {void}
 */
export const PPTView = async (id) => {
  const res = await NETSYSTEM.viewOrEditV2({
    fileId: id,
    isCurrent: 1,
    optType: 'view'//操作类型（view:预览，edit:编辑）
  })
  if (res.fileLocalPath) {
    // 打开新窗口
    window.open(res.fileLocalPath);
  }
}
/**
 * @description: 获取图标
 * @param {String} name 文件地址 
 * @return {void}
 */
export const getFileIcon = (name) => {
  let onlyOfficeType = ".docx,.wps,.xlsx,.et,.pptx,.dps,.doc,.txt,.xls,.csv,.ppt,.pdf"
  let videoType = ".mp4,.avi,.rmvb,.wmv,.flv,.webm,.mkv,.mp3,.wma,.wav,.flac,.ogg"
  let ImageType = ".jpg,.jepg,.png,.svg,.webp"
  const suffix = "." + name.split(".").pop();
  if (onlyOfficeType.indexOf(suffix) !== -1) {
    return "office";
  } else if (videoType.indexOf(suffix) !== -1) {
    return "video";
  } else if (ImageType.indexOf(suffix) !== -1) {
    return "picture";
  } else {
    return "unknown";
  }
}
/**
 * @description: 获取图标
 * @param {String} fileurl 文件地址 
 * @return {void}
 */
export const checkFileIcon = (fileurl) => {
  const suffix = "." + fileurl.split(".").pop();
  const OfficePPT = ".ppt,.pptx,.dps";
  const OfficeWord = ".docx,.doc,.wps";
  const OfficeExcel = ".xls,.csv,.xlsx,.et";
  const OfficePDf = ".pdf";
  const txtType = ".txt";
  const dwgType = ".dwg";
  const videoType = ".mp4,.avi,.rmvb,.wmv,.flv,.webm,.mkv,.mp3,.wma,.wav,.flac,.ogg";
  const ImageType = ".jpg,.jpeg,.png,.svg,.webp";
  const zipType = ".zip,.rar,.7z,.tar";
  const modelType = ".pak,.rvt,.3dxml,.gim,.ifc,.igms,.fbx,.3dm,.stp,.gltf,.skp,.glb,.dgn,.nwd,.nwc";
  const fileType = suffix.toLowerCase();
  if (OfficePPT.includes(fileType)) {
    return 'filetype-ppt'; 
  } else if (OfficeWord.includes(fileType)) {
    return 'filetype-doc'; 
  } else if (OfficeExcel.includes(fileType)) {
    return 'filetype-excel';
  } else if (OfficePDf.includes(fileType)) {
    return 'filetype-pdf';
  } else if (txtType.includes(fileType)) {
    return 'filetype-txt';
  } else if (videoType.includes(fileType)) {
    return 'filetype-video';
  } else if (ImageType.includes(fileType)) {
    return 'filetype-image';
  } else if (modelType.includes(fileType)) {
    return 'filetype-model';
  } else if (zipType.includes(fileType)) {
    return 'filetype-zip';
  } else if (dwgType.includes(fileType)) {
    return 'filetype-dwg';
  } else {
    return 'filetype-unknown'; // 如果没有匹配的类型，默认返回一个未知类型
  }
}
// /**
//  * @description: 获取图标
//  * @param {String} fileurl 文件地址 
//  * @return {void}
//  */
// export const checkFileIcon = (fileurl) => {
//   const suffix = "." + fileurl.split(".").pop();
//   const allowedFormats = /\.(ai|avi|bmp|crd|csv|dll|doc|docx|dwg|eps|exe|flv|gif|html|iso|java|jpg|jpeg|mav|mdb|mid|model|mov|mp3|mp4|mpeg|pdf|png|ppt|pptx|ps|psd|pub|rar|raw|rss|svg|tiff|txt|wma|xml|xls|zip|xlsx)$/i;
//   const modelType = ".pak,.rvt,.3dxml,.gim,.ifc,.igms,.fbx,.3dm,.stp,.gltf,.skp,.glb,.dgn,.nwd,.nwc";
//   const fileName = suffix.toLowerCase();
//   if (allowedFormats.test(fileName)) {
//     return `filetype-${fileName.replace('.', "")}`
//   } else if (modelType.indexOf(suffix) !== -1) {
//     return "filetype-model";
//   } else {
//     return "filetype-unknown"
//   }
// }
/**
 * @description: 下载
 * @param {Object} value 点击项
 * @return {void}
 */
export const DownloadFile = async (value, isCurrent = 1) => {
  const res = await File.downLoadFile({
    fileId: value.id,
    isCurrent: isCurrent,
    optType:1,//1.下载，2.预览
  })
  if (res) {
    const suffix = res.downUrl.split(".").pop();
    const fileNameSuffix = res.fileName.split(".").pop();
    let fileName = res.fileName;
    if (suffix !== fileNameSuffix) {
      fileName = fileName + "." + suffix;
    }
    fileName = encodeURIComponent(fileName);// 防止特殊号被识别为锚点
    // 大文件会暂存在这个地址，这里如果是视频会直接打开，还是采取之前的下载
    let prefix = 'https://tc.i3vsoft.com:4431/public-file-system'
    if (!(res.downUrl.startsWith(prefix) && suffix == 'mp4')) {
      // 使用iframe,需要后端配合改http头
      const iframe = document.createElement('iframe')
      iframe.style.display = 'none'  // 防止影响页面
      iframe.style.height = 0  // 防止影响页面
      iframe.src = `${res.downUrl}?name=${fileName}`
      document.body.appendChild(iframe) // 这一行必须，iframe挂在到dom树上才会发请求
      // 5分钟之后删除
      setTimeout(() => {
        iframe.remove()
      }, 5 * 60 * 1000)
    } else {
      saveAs(res.downUrl, fileName);
    }
  }
}
/**
 * @description: 下载文件插件
 * @return {void}
 */
export const handleDownloadI3VMews = () => {
  const fileUrl = process.env.BASE_URL + "I3VMews.exe";
  const link = document.createElement("a");
  link.href = fileUrl;
  link.setAttribute("download", "I3VMews.exe"); // 设置下载文件的名称
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
} 