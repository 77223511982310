<template>
  <ElRadioGroup
    class="view-switcher"
    v-model="cCurrView"
    size="small"
    fill="#f0f3f7"
  >
    <ElRadioButton label="table" :disabled="cCurrView === 'table'">
      <svg-icon sys="common-table" name="MiddleBar/table-mode" :size="11" />
    </ElRadioButton>
    <ElRadioButton label="cards" :disabled="cCurrView === 'cards'">
      <svg-icon sys="common-table" name="MiddleBar/card-mode" :size="11" />
    </ElRadioButton>
  </ElRadioGroup>
</template>

<script lang="ts">
import Vue, { PropType } from "vue";
import {
  RadioGroup as ElRadioGroup,
  RadioButton as ElRadioButton,
} from "element-ui";
import SvgIcon from "@/flexible-table-module/svg-icon-importer";

type TypeView = "cards" | "table";

export default Vue.extend({
  inheritAttrs: false,
  name: "ViewSwitcher",
  components: {
    ElRadioGroup,
    ElRadioButton,
    SvgIcon,
  },
  model: {
    prop: "view",
    event: "input",
  },
  props: {
    view: {
      type: String as PropType<TypeView>,
      default: "table",
    },
  },
  data(): {
    currView: TypeView;
  } {
    return {
      currView: this.view as TypeView,
    };
  },
  computed: {
    cCurrView: {
      get(): TypeView {
        return this.currView;
      },
      set(v: TypeView) {
        this.currView = v;
        this.$emit("input", v);
      },
    },
  },
});
</script>

<style lang="scss" scoped>
@import "src/flexible-table-module/scss/_variables.scss";
.view-switcher {
  display: inline-flex;
  justify-content: flex-start;
  align-items: center;

  ::v-deep {
    .el-radio-button__inner {
      border-color: #d3dae6 !important;
      color: $font-color-dark !important;
    }
  }
}
</style>
