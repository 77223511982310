<template>
  <div class="scheme-container">
    <div class="w-h-100 flex app-scheme-wrapper">
      <div class="app-scheme-sidebar" v-if="!showscheme && showaside">
        <svg-icon class="scheme-sidebar-icon pointer" :class="{ 'scheme-sidebar-icon-active': showscheme }"
          icon="icon-scheme" :height="20" :width="20" @click.native="showscheme = !showscheme"></svg-icon>
      </div>
      <div class="app-scheme-left" v-if="showscheme">
        <div class="scheme-title flex-between align-items-center">
          <div class="scheme-title-name">展示方案</div>
          <div class="flex align-items-center">
            <!-- <svg-icon
              class="mr-10 pointer"
              icon="not-fixed"
              color="#5E6C84"
              :height="12"
              :width="12"
            ></svg-icon> -->
            <svg-icon v-if="!ispre" class="mr-10 pointer" icon="mind-add" color="#5E6C84" :height="12" :width="12"
              @click.native="handleCreat(null)"></svg-icon>
            <svg-icon class="mr-10 pointer" icon="close-side" color="#5E6C84" :height="16" :width="16"
              @click.native="showscheme = false"></svg-icon>
          </div>
        </div>
        <div class="scheme-title-list">
          <div v-for="i in schemeList" :key="i.id" @click="handleClick(i)"
            class="flex scheme-title-list-items pointer align-items-center"
            :class="{ 'items-active': activeScheme && activeScheme.id == i.id }">
            <svg-icon class="scheme-items-icon" icon="icon-scheme" :height="16" :width="16" color="#344563"
              v-if="!i.isDefault"></svg-icon>
            <div class="scheme-items-icon-default" v-if="i.isDefault">默认</div>
            <div class="scheme-items-name one-line">{{ i.name }}</div>
            <!-- <div class="popover-item" @click="handleShare(i)">分享</div> -->
            <svg-icon icon="share" class="share-icon" :heigth="14" :width="14"
              @click.native="handleShare(i)"></svg-icon>
            <el-popover v-if="!ispre" popper-class="toolbar-item-popover popover" placement="right-start" width="120"
              trigger="click" :ref="'toolbar-' + i.id" :visible-arrow="false">
              <div class="toolbar-more" slot="reference" @click.stop></div>
              <div class="popover-item" @click="handleDefault(i)">
                {{ i.isDefault ? "取消默认" : "设为默认" }}
              </div>
              <div class="popover-item" @click="handleCreat(i)">编辑</div>
              <div class="popover-divider"></div>
              <div class="popover-item" @click="handleDelete(i)">删除</div>
            </el-popover>
          </div>
        </div>
      </div>
      <div class="app-scheme-main main-edit" v-if="this.schemeList.length">
        <div class="edit-tool" v-if="!ispre">
          <div>
            <el-button size="small" type="primary" @click="previewvisible = !previewvisible">{{ !previewvisible ? "预览" :
        "退出预览" }}</el-button>
            <el-button size="small" type="primary" :loading="selectsaveloading" @click="handleSelectSave">保存</el-button>
          </div>
        </div>
        <template v-if="isSchemeEdit">
          <TextEditor v-if="activeColumn &&
        columnCategoryTypeDict[activeColumn.columnCategoryId] === '1'
        " :ispre="true" :rich-text.sync="richText" :module-id="subject.moduleId" :subject-id="subject.id"
            :version-id="subject.versionId" :active-column="activeColumn" :isedit="isSchemeEdit"
            @handleselect="handleselect" :scheme="activeScheme" :alreadychose="queryselectData.filter((item) => item.subjectColumnId == activeColumn.id)
        " />
          <AssociateArticle v-if="activeColumn &&
        columnCategoryTypeDict[activeColumn.columnCategoryId] === '5'
        " :active-column="activeColumn" :subject="subject" :ispre="true" :scheme="activeScheme"
            :isedit="isSchemeEdit" @handleselect="handleselect" :alreadychose="queryselectData.filter((item) => item.subjectColumnId == activeColumn.id)
        " />
          <AssociateProduct v-if="activeColumn &&
        columnCategoryTypeDict[activeColumn.columnCategoryId] === '3'
        " :active-column="activeColumn" :subject="subject" :ispre="true" :isedit="isSchemeEdit"
            @handleselect="handleselect" :scheme="activeScheme" :alreadychose="queryselectData.filter((item) => item.subjectColumnId == activeColumn.id)
        " />
          <LinkColumn v-if="activeColumn &&
        columnCategoryTypeDict[activeColumn.columnCategoryId] === '4'
        " :subject="subject" :active-column="activeColumn" :ispre="true" :scheme="activeScheme"
            :isedit="isSchemeEdit" @handleselect="handleselect" :alreadychose="queryselectData.filter((item) => item.subjectColumnId == activeColumn.id)
        " />
          <FileSelect v-if="isfile && !activeColumn" :subject="subject" @handleselect="handleselect"
            @handlesavetemporaryselect="handleSavetemporaryselect" :scheme="activeScheme"
            :alreadychose="queryselectData.filter((item) => item && item.dataType == 2)"
            :temporaryfile="temporaryfile" />
        </template>
        <template v-else>
          <ArticlePreview v-if="activeColumn &&
        columnCategoryTypeDict[activeColumn.columnCategoryId] === '5'
        " :active-column="activeColumn" :scheme="activeScheme" :subject="subject" />
          <ContentPreview v-if="activeColumn &&
        columnCategoryTypeDict[activeColumn.columnCategoryId] === '1'
        " :active-column="activeColumn" :scheme="activeScheme" :subject="subject" />
          <LinkPreview v-if="activeColumn &&
        columnCategoryTypeDict[activeColumn.columnCategoryId] === '4'
        " :active-column="activeColumn" :scheme="activeScheme" :subject="subject" />
          <ProductPreview v-if="activeColumn &&
        columnCategoryTypeDict[activeColumn.columnCategoryId] === '3'
        " :active-column="activeColumn" :scheme="activeScheme" :subject="subject" />
          <FilePreview v-if="!activeColumn && isfile" :active-column="activeColumn" :scheme="activeScheme"
            :subject="subject" />
        </template>
      </div>
      <div v-if="!this.schemeList.length" style="padding: 10px">
        暂无展示方案，请添加方案后操作
      </div>
    </div>
    <div class="tool-center project-tool-center" v-if="this.schemeList.length && ispre && !isSchemeEdit">
      <div v-for="i in columnList" class="tool-center-item" :class="{
        'is-active': activeColumn && i.id === activeColumn.id,
        'show-more': !isPreview,
      }" :key="i.id" @click="changeColumn(i)">
        <div class="tool-center-item-w">
          <component :is="i.columnIcon.startsWith('http') ? 'svg-icon-2' : 'svg-icon'" width="25" height="25"
            color="#5e6c84" activeColor="#fff" :icon="i.columnIcon ? i.columnIcon : 'column-icon-1'"
            :is-active="activeColumn?.id === i.id">
          </component>
          <div :title="i.columnName" class="tool-center-item__label one-line" style="width: 100%; text-align: center">
            {{ i.columnName }}
          </div>
        </div>
      </div>
      <div class="tool-center-item" @click="handleShowFile" :class="{
        'is-active': !activeColumn && isfile,
        'show-more': !isPreview,
      }">
        <div class="tool-center-item-w">
          <svg-icon icon="column-icon-3" width="25" height="25"></svg-icon>
          <div class="tool-center-item__label">文件</div>
        </div>
      </div>
      <!-- <div v-if="!isPreview" class="tool-center-item" @click="showDialog('showColumn')">
        <div class="tool-center-item-w">
          <svg-icon icon="column-icon-set" width="25" height="25"></svg-icon>
          <div class="tool-center-item__label">设置</div>
        </div>
      </div> -->
    </div>
    <SelectColumnData v-if="showColumn" :visible.sync="showColumn" :subject="subject" :scheme="activeScheme"
      @finish="getScheme" />
    <Scheme v-if="addvisible" :visible.sync="addvisible" :subject="subject" :clickitem="clickScheme"
      @finish="getScheme" />
    <SchemePreview v-if="previewvisible" :visible.sync="previewvisible" :subject="subject" :scheme="activeScheme" />
    <ShareDialog v-if="shareVisible" :subject-id="subject.id" :visible.sync="shareVisible" :shareinfo="subject"
      :schemeid="clickScheme.id" />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { ProductLibrary } from "@/api/ProductLibrary";
import SelectColumnData from "../dialog/SelectColumnData.vue";
import SchemePreview from "../dialog/SchemePreview.vue";
import Scheme from "../dialog/Scheme.vue";
import ArticlePreview from "./scheme/ArticlePreview.vue";
import ContentPreview from "./scheme/ContentPreview.vue";
import LinkPreview from "./scheme/LinkPreview.vue";
import ProductPreview from "./scheme/ProductPreview.vue";
import FilePreview from "./scheme/FilePreview.vue";
import TextEditor from "./TextEditor.vue";
import AssociateArticle from "./AssociateArticle.vue";
import AssociateProduct from "./AssociateProduct.vue";
import LinkColumn from "./LinkColumn.vue";
import FileSelect from "../components/scheme/FileSelect.vue";
import ProductFile from "@/views/ProductFile/index.vue";
import ShareDialog from "@/views/ProductLibrary/dialog/share.vue";
export default {
  name: "ShowProject",
  components: {
    SelectColumnData,
    Scheme,
    ArticlePreview,
    ContentPreview,
    LinkPreview,
    ProductPreview,
    FilePreview,
    TextEditor,
    AssociateArticle,
    AssociateProduct,
    LinkColumn,
    ProductFile,
    FileSelect,
    SchemePreview,
    ShareDialog,
  },
  props: {
    ispre: {
      type: Boolean,
      default: false,
    },
    visible: {
      type: Boolean,
      default: false,
    },
    showaside: {
      type: Boolean,
      default: true,
    }, //显示侧边栏
    subject: Object,
    schemeactiveColumn: Object,
    schemeactivefixedColumn: Object,
  },
  data() {
    return {
      richText: "",
      columnLoading: false,
      queryData: {
        dataId: "", //主题id
        dataType: 1,
        description: "",
        id: "",
        name: "",
        tenantId: "",
      },
      schemeList: [], //方案列表
      columnList: [],
      activeColumn: null,
      activeScheme: null, //当前展示方案
      clickScheme: null, //编辑的方案
      addvisible: false, //添加/编辑方案名称弹窗
      showColumn: false, //编辑方案详情弹窗
      isfile: false,
      showscheme: true, //侧边条
      isSchemeEdit: true, //编辑状态
      queryselectData: [],
      temporaryfile: [],
      selectsaveloading: false, //选择数据保存loading
      previewvisible: false,
      shareVisible: false,
    };
  },
  watch: {
    activeScheme(val) {
      this.getSchemeColumn();
    },
    schemeactiveColumn(val) {
      this.queryselectData = [];
      val && (this.activeColumn = val);
    },
    schemeactivefixedColumn(val) {
      this.queryselectData = [];
      val && this.handleShowFile();
    },
    ispre(val) {
      if (this.subject) {
        this.getScheme();
        this.queryData.dataId = this.subject.id;
        this.queryData.tenantId = this.subject.tenantId;
      }
      this.isSchemeEdit = !val;
    },
  },
  computed: {
    isPreview() {
      return this.ispre;
    },
    dialogVisible: {
      get() {
        return this.visible;
      },
      set(val) {
        this.$emit("update:visible", val);
      },
    },
    ...mapGetters(["userInfo", "columnCategoryTypeDict"]),
  },
  mounted() {
    if (this.subject) {
      this.getScheme();
      this.queryData.dataId = this.subject.id;
      this.queryData.tenantId = this.subject.tenantId;
    }
    if (this.isPreview) {
      this.showscheme = false;
      this.isSchemeEdit = false;
    }
  },
  methods: {
    /**
     * @description: 获取展示方案列表
     * @return {void}
     */
    async getScheme() {
      const res = await ProductLibrary.SchemequeryList(this.subject.id);
      this.schemeList = res;
      if (this.schemeList.length) {
        let defaultdata = this.schemeList.filter((item) => item.isDefault);
        if (defaultdata.length) {
          this.activeScheme = defaultdata[0];
        } else {
          this.activeScheme = this.schemeList[0];
        }
        this.getSchemeColumn();
      }
    },
    /**
     * @description: 获取展示方案关联栏目列表
     * @return {void}
     */
    async getSchemeColumn() {
      this.columnLoading = true;
      const res = await ProductLibrary.getSchemeSubjectColumnList({
        schemeId: this.activeScheme.id,
        subjectVersionId: this.subject.versionId,
      });
      this.columnList = _.sortBy(res, "sortNo");
      if (this.columnList.length) {
        this.activeColumn = this.columnList[0];
      } else {
        this.handleShowFile();
      }
      if (this.schemeactiveColumn && this.isSchemeEdit) {
        this.activeColumn = this.schemeactiveColumn;
      }
      this.columnLoading = false;
    },
    /**
     * @description: 创建展示方案
     * @return {void}
     */
    handleCreat(value) {
      this.clickScheme = value;
      this.addvisible = true;
    },
    /**
     * @description: 切换栏目
     * @return {void}
     */
    changeColumn(value) {
      this.activeColumn = value;
    },
    showDialog(visible) {
      this[visible] = true;
    },
    /**
     * @description: 切换方案
     * @return {void}
     */
    handleClick(value) {
      this.activeScheme = value;
      if (this.isSchemeEdit) {
        this.$emit("updatedata");
      }
    },
    /**
     * @description: 设置默认
     * @return {void}
     */
    async handleDefault(value) {
      const props = ["id", "isDefault", "name", "tenantId", "dataId", "dataType"];
      let payload = _.pick(value, props);
      payload.isDefault ? (payload.isDefault = 0) : (payload.isDefault = 1);
      const res = await ProductLibrary.UpdateScheme("edit", payload);
      if (res) {
        this.$message.success("成功");
        this.getScheme();
      }
    },
    /**
     * @description: 显示文件栏目
     * @return {void}
     */
    handleShowFile() {
      this.activeColumn = null;
      this.isfile = true;
    },
    /**
     * @description: 删除
     * @return {void}
     */
    handleDelete(value) {
      this.$showMessage(
        {
          message: `确定删除 <span style="font-weight: 1000;">${value.name}</span> 吗?<br>此次操作会将所选数据永久删除，请谨慎操作！`,
          ...this.$global.deleteMessage,
        },
        async (done) => {
          const res = await ProductLibrary.SchemeDelete(value.id);
          if (res) {
            this.$message.success("删除成功");
            this.activeColumn = null;
            this.isfile = false;
            this.getScheme();
          }
          done(true);
        }
      );
    },
    /**
     * @description: 获取每个栏目选择的数据
     * @return {void}
     */
    async handleselect(val) {
      this.queryselectData = this.updateDataArray(this.queryselectData, val);
      this.queryselectData.forEach((item) => {
        item.schemeId = this.activeScheme.id;
      });
      await ProductLibrary.addrelateBatch(this.queryselectData);
    },
    /**
     * @description: 数据筛选
     * @return {void}
     */
    updateDataArray(dataArray, newData) {
      const foundItem = dataArray.filter(
        (item) => item.subjectColumnId === newData.subjectColumnId
      )[0];
      if (foundItem) {
        Object.assign(foundItem, newData);
      } else {
        dataArray.push(newData);
      }
      return dataArray;
    },
    /**
     * @description: 保存选择栏目数据
     * @return {void}
     */
    async handleSelectSave() {
      if (this.queryselectData.length) {
        this.queryselectData.forEach((item) => {
          item.schemeId = this.activeScheme.id;
        });
        this.selectsaveloading = true;
        const res = await ProductLibrary.addrelateBatch(this.queryselectData);
        if (res) {
          this.$message.success("成功");
          this.queryselectData = [];
          this.$emit("finish");
          this.dialogVisible = false;
        }
        this.selectsaveloading = false;
      }
    },
    /**
     * @description: 暂存一下文件的选择数据详情
     * @return {void}
     */
    handleSavetemporaryselect(val) {
      this.temporaryfile = val;
    },
    handleShare(value) {
      this.clickScheme = value;
      this.shareVisible = true;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@/scss/common.scss";

.scheme-container {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.app-scheme-wrapper {
  flex: 1;
  background-color: #fff;
  overflow: hidden;
}

.project-tool-center {
  height: 60px;
  width: 100%;
  border-top: 1px solid #ebeef2;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff;

  .tool-center-item {
    margin: 0 5px;
    width: 69px;
    height: 51px;
    border-radius: 10px;
    font-size: 12px;
    font-weight: 500;
    color: #344563;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    position: relative;

    &.is-active {
      background: #f1ebff;
      color: var(--color-primary);

      .tool-center-item-w {

        .svg-icon,
        .online-svg {
          color: var(--color-primary) !important;
        }
      }
    }

    .tool-center-item-w {
      width: 69px;
      height: 51px;
      border-radius: 10px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      .svg-icon,
      .online-svg {
        color: #5e6c84 !important;
      }

      .tool-center-item__icon {
        width: 25px;
        height: 25px;
      }

      .tool-center-item__label {
        height: 18px;
        line-height: 18px;
        margin-top: 2px;
        padding: 0 2px;
        box-sizing: border-box;
      }
    }

    &:not(.is-active) {
      .tool-center-item-w {
        &:hover {
          background-color: #f7f9fc;
        }
      }
    }
  }
}

.edit-tool {
  display: flex;
  align-items: center;
  justify-content: end;
  height: 40px;
  border-bottom: 1px solid #ebeef2;
  padding-right: 10px;
  box-sizing: border-box;
  flex-shrink: 0;
}

.main-edit {
  display: flex;
  flex-direction: column;
  overflow: hidden;
}
</style>
