<template>
  <div class="product-card-contain">
    <div
      class="product-card"
      :class="{ 'product-card2': showtype == 2 }"
      v-for="item in tableData"
      :key="item.id"
      @click="handlePreview(item)"
    >
      <div class="product-card-cover">
        <img class="w-h-100" :src="item.coverList[0].coverUrl" alt="" />
      </div>
      <div class="product2-card__info">
        <div
          class="product-card__info-title one-line"
          :title="item.articleName ? item.articleName : ''"
        >
          {{ item.articleName ? item.articleName : "" }}
        </div>
        <div class="product-card__info-introduce" v-if="item.coverRemark">
          {{ item.coverRemark }}
        </div>
        <div
          class="product-card__info-introduce product-card__info-nointroduce"
          v-if="!item.coverRemark"
        >
          <!-- 暂无简介 -->
        </div>
        <div class="product-card__info-bottom">
          <div class="product-card__info-update">
            最新更新：{{ Object.dayjs(item.updateAt) }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "quote",
  props: {
    showtype: {
      type: Number,
      default: 1, //1不需要选框，2有选择框
    },
    demonstrate: {
      type: Boolean,
      default: false, //是否为演示类
    },
    datalist: Array,
  },
  data() {
    return {
      tableData: [],
      viewType: "grid",
      clickItem: null,
      showPreview: false,
      selectData: [],
    };
  },
  computed: {
    ...mapGetters(["userInfo"]),
  },
  created() {},
  mounted() {
    if (this.datalist) {
      this.datalist.forEach((element) => {
        if (element.coverList.length === 0) {
          element.coverList.push({
            coverUrl: require(`@/assets/images/sample-${1}.png`),
          });
        }
      });
      this.tableData = this.datalist;
    }
  },
  methods: {
    /**
     * @description: 查看产品详情
     * @return {void}
     */
    async handlePreview(value) {
      const routeData = this.$router.resolve({
        path: `/articlepreview/${value.id}`,
        query: {
          preveiew: true,
        },
      });
      window.open(routeData.href);
    },
    /**
     * @description: 切换格子/列表视图
     * @param {Object} value 点击项
     * @return {void}
     */
    changeViewType(type) {
      this.viewType = type;
    },
    handleSelect(value) {
      const index = this.selectData.findIndex((item) => item.id === value.id);
      if (index !== -1) {
        this.selectData.splice(index, 1);
      } else {
        this.selectData.push(value);
      }
      this.$emit("select", this.selectData);
    },
    checkSelect(value) {
      const index = this.selectData.findIndex((item) => item.id === value.id);
      return index != "-1";
    },
  },
};
</script>

<style lang="scss" scoped>
.product-card-contain {
  // height: 100%;
  width: 100%;
  padding: 16px 7px;
  box-sizing: border-box;
  display: grid;
  grid-gap: 18px 18px;
  grid-template-columns: repeat(5, minmax(0, 1fr));
  .product-content-grid__wrapper {
    display: grid;
    grid-gap: 18px 18px;
  }
  .product-card {
    height: 230px;
    background-color: #ccc;
    box-shadow: 0px 0px 2px 2px rgba(52, 69, 99, 0.1);
    position: relative;
    .product-card-cover {
      width: 100%;
      height: 120px;
    }
    &:hover {
      box-shadow: 0px 0px 4px 4px rgba(52, 69, 99, 0.1);
    }
    .check-icon {
      position: absolute;
      right: 8px;
      top: 5px;
    }
  }
  .product-card2 {
    height: 260px;
    .product-card-cover {
      width: 100%;
      height: 150px;
    }
  }
}
::v-deep .el-divider--horizonta {
  margin: 15px 0;
}
.morelink-dropdown {
  box-shadow: 0px 0px 5px 2px rgba(94, 108, 132, 0.2);

  .el-dropdown-menu__item {
    line-height: 32px;
    font-size: 14px;
    font-weight: 500;
    color: #344563;
    border-radius: 4px;
    padding: 0;
    margin: 0 8px;

    &:hover {
      color: var(--color-text-primary);
      background-color: #fbfaff;
    }

    .filter-dropdown-item {
      position: relative;
      line-height: 32px;
      padding: 0 10px 0 10px;
      .title {
        width: 96px;
        color: #6a87fc;
      }
      .item {
        display: flex;
        align-items: center;
        &:hover {
          color: var(--color-primary);
          .svg-icon {
            color: var(--color-primary) !important;
          }
        }
      }
    }
  }
}
.product2-card__info {
  position: relative;
  z-index: 3;
  box-sizing: border-box;
  padding-top: 13px;
  border-radius: 0 0 5px 5px;
  background-color: #fff;

  .product-card__info-introduce {
    height: 17px;
    font-size: 12px;
    font-weight: 500;
    margin-bottom: 8px;
    padding: 0 16px;
    color: var(--color-text-primary);
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  .product-card__info-nointroduce {
    color: var(--color-text-placeholder);
  }

  .product-card__info-bottom {
    // width: 100%;
    height: 44px;
    line-height: 44px;
    padding: 0 16px;
    background-color: #f5f8fc;

    .product-card__info-update {
      color: #8997b0;
    }

    .product-card__info-toolbar-left {
      display: flex;
      width: 80%;

      .el-tooltip {
        margin-right: 10px;
      }
    }
  }

  .product-card__info-title {
    position: relative;
    height: 20px;
    line-height: 20px;
    margin-bottom: 8px;
    font-size: 14px;
    font-weight: 700;
    color: var(--color-text-regular);
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    padding: 0 16px;

    &.collection-private {
      padding-left: 20px;

      &::before {
        position: absolute;
        content: "";
        top: 2px;
        left: 0;
        width: 16px;
        height: 16px;
        background: url("@/assets/images/productLibrary/collection-private.png");
        background-size: 16px 16px;
        background-repeat: no-repeat;
      }
    }
  }

  .product-card__info-update {
    // height: 25px;
    font-size: 12px;
    font-weight: 500;
    color: var(--color-text-secondary);
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  .product-card__info-count {
    height: 25px;
    font-size: 12px;
    font-weight: 500;
    color: var(--color-text-secondary);
    display: none;

    div {
      width: 50%;
      min-width: 60px;
      flex-shrink: 0;
    }
  }
}
</style>
