<template>
  <div class="string-select">
    <ElInput
      class="keyword"
      :clearable="true"
      v-model="selectListFilterKeyword"
      placeholder="请输入关键字过滤"
    ></ElInput>
    <ElCheckboxGroup class="group" v-model="dSelections">
      <template v-for="pair in selectListPreparer">
        <ElCheckbox class="item" :key="pair.key" :label="pair.key">{{
          pair.value
        }}</ElCheckbox>
      </template>
    </ElCheckboxGroup>
    <SubmitAndCancel @submit="onSubmit" @cancel="onCancel" />
  </div>
</template>
<script lang="ts">
import Vue, { PropType } from "vue";
import {
  Input as ElInput,
  CheckboxGroup as ElCheckboxGroup,
  Checkbox as ElCheckbox,
} from "element-ui";

import { KeyStrValuePair } from "@/flexible-table-module/entity/KeyValuePair";
import SubmitAndCancel from "../../Common/SubmitAndCancel.vue";
export default Vue.extend({
  inheritAttrs: false,
  name: "StringSelect",
  components: { ElInput, ElCheckboxGroup, ElCheckbox, SubmitAndCancel },
  props: {
    visible: { type: Boolean, default: false },
    selectList: {
      type: /* [ */ Array as PropType<KeyStrValuePair[]> /* , Function] */,
      default: () => [],
    }, // 选择列表或获取选择列表的方法
    selections: { type: Array, default: () => [] }, // 被选
  },
  data(): {
    selectListFilterKeyword: string;
    dSelectList: KeyStrValuePair[];
    dSelections: string[];
  } {
    return {
      selectListFilterKeyword: "",
      dSelectList: [],
      dSelections: this.selections as string[], // 键值对的键的集合
    };
  },
  watch: {
    // visible: {
    //   immediate: false,
    //   handler(newVal, oldVal) {
    //     if (newVal) {
    //       this.freshSelectList(); // 下拉时刷新一下
    //     }
    //   },
    // },
    selections: {
      immediate: false,
      handler(newVal, oldVal) {
        this.dSelections = Array.isArray(newVal) ? newVal : [];
      },
    },
  },
  computed: {
    selectListPreparer(): KeyStrValuePair[] {
      // const selectList = this.dSelectList;
      const selectList = this.selectList;
      if (this.selectListFilterKeyword) {
        const filteredList = [];
        for (const pair of selectList) {
          if (pair.value.includes(this.selectListFilterKeyword)) {
            filteredList.push(pair);
          }
        }
        return filteredList;
      } else {
        return selectList;
      }
    },
  },
  beforeMount() {
    // this.freshSelectList();
  },
  methods: {
    // freshSelectList() {
    //   if (typeof this.selectList === "function") {
    //     const result = this.selectList();
    //     if (result instanceof Promise) {
    //       result.then((list) => {
    //         this.dSelectList = list;
    //       });
    //     } else {
    //       this.dSelectList = result;
    //     }
    //   } else {
    //     this.dSelectList = this.selectList as KeyStrValuePair[];
    //   }
    // },
    onSubmit() {
      this.$emit("submit", this.dSelections);
    },
    onCancel() {
      this.dSelections = this.selections as string[];
      this.$emit("cancel");
    },
  },
});
</script>
<style lang="scss" scoped>
.string-select {
  .keyword {
    margin-bottom: 8px;
  }
  .group {
    max-height: 60vh;
    overflow: auto;
    .item {
      display: block;
      padding: 2px 0;
    }
  }
}
</style>