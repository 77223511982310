<template>
  <div class="filters-dropdown">
    <ElPopover
      v-model="isVisible"
      placement="bottom-start"
      :width="width - headerPadding * 2"
      trigger="click"
      popper-class="filters-dropdown"
    >
      <div class="filters-dropdown_popover-body">
        <div class="part single">
          <div class="title">
            <div class="text">单列条件选择</div>
          </div>
          <div class="content">
            <template v-for="filter in activeFilters">
              <div
                class="filter-item"
                :class="{
                  active: currFilter && filter === currFilter,
                }"
                :key="filter.name"
                @click="onFilterItemClick(filter)"
              >
                {{ filter.title }}
              </div>
            </template>
          </div>
        </div>
        <div class="part multiple">
          <div class="title">
            <div class="text">多列条件选择</div>
          </div>
          <div class="content">
            <template v-for="filter in multiFilters">
              <div
                class="filter-item"
                :class="{
                  active: currFilter && filter === currFilter,
                }"
                :key="filter.id"
                @click="onFilterItemClick(filter)"
              >
                {{ filter.title }}
              </div>
            </template>
          </div>
        </div>
        <div class="part view">
          <div
            class="title"
            :class="{ active: currFilter === 'view' }"
            @click="onViewTitleClick"
          >
            <div class="text">视图</div>
          </div>
        </div>
      </div>
      <span class="popover-ref" slot="reference">
        <span>{{ dropDownTitle }}</span
        ><i class="el-icon-arrow-down"></i>
      </span>
    </ElPopover>
  </div>
</template>
<script lang="ts">
import Vue, { PropType } from "vue";
import { Popover as ElPopover } from "element-ui";
import {
  Filter,
  MultiFilter,
  ViewFilter,
} from "@/flexible-table-module/entity/Filter";
import { IFilterListItem } from "@/flexible-table-module/entity/BaseFilter";

export default Vue.extend({
  inheritAttrs: false,
  name: "FiltersDropdown",
  components: { ElPopover },
  props: {
    width: { type: Number, required: true },
    headerPadding: { type: Number, required: true },
    isMultiple: { type: Boolean, default: false }, // 是否保持"非当前被选中的过滤器"的"已勾选过滤器项"
    activeFilters: { type: Array as PropType<Filter[]>, default: () => [] },
    multiFilters: { type: Array as PropType<MultiFilter[]>, default: () => [] },
    viewFilters: { type: Array as PropType<ViewFilter[]>, default: () => [] },
    currFilter: {
      type: [Object, String] as PropType<Filter | MultiFilter | "view" | null>,
    },
  },
  data(): {
    isVisible: boolean;
    selectedListItems: IFilterListItem[]; // 当前被选中的过滤器中被勾选的过滤器项
  } {
    return {
      isVisible: false,
      selectedListItems: [],
    };
  },
  computed: {
    isDisabled(): boolean {
      return this.activeFilters.length === 0;
    },
    dropDownTitle(): string {
      if (this.currFilter instanceof Filter) return this.currFilter.title;
      else if (this.currFilter instanceof MultiFilter)
        return this.currFilter.title
          ? this.currFilter.title
          : this.currFilter.getDefaultTitle();
      else if (this.currFilter === "view") return "视图列表";
      else return "无过滤条件";
    },
  },
  methods: {
    onFilterItemClick(filter: Filter | MultiFilter) {
      if (filter !== this.currFilter) {
        // this.$emit("update:currFilter", filter);
        this.emitUpdateCurrFilterEvent(filter);
        this.isVisible = false;
      }
    },

    onViewTitleClick() {
      if ("view" !== this.currFilter) {
        // this.$emit("update:currFilter", "view");
        this.emitUpdateCurrFilterEvent("view");
        this.isVisible = false;
      }
    },

    emitUpdateCurrFilterEvent(filter: Filter | MultiFilter | "view") {
      // this.$emit("update:currFilter", filter);
      this.$emit("filter-changed", filter);
    },
  },
});
</script>
<style lang="scss" scoped>
@import "src/flexible-table-module/scss/_variables.scss";

.filters-dropdown {
  user-select: none;

  .popover-ref {
    cursor: pointer;
  }
}
</style>
<style lang="scss">
@import "src/flexible-table-module/scss/_variables.scss";

.filters-dropdown {
  padding: 0 8px;

  .filters-dropdown_popover-body {
    .part {
      width: 100%;

      $title-height: 32px;

      .title {
        height: $title-height;
        line-height: $title-height;
        color: $color-light;
        padding-left: 8px;

        &.active {
          color: $color-primary;
        }

        .text {
          // flex-grow: 1;
        }
      }

      .content {
        $item-height: 24px;

        .filter-item {
          height: $item-height;
          line-height: $item-height;
          padding-left: 24px;
          color: $color-base;

          &:hover {
            cursor: pointer;
            background-color: $color-light;
            color: $color-reverse;
          }

          &.active {
            cursor: default;
            background-color: $background-base;
            color: $color-base;
          }
        }
      }

      &.view {
        .title {
          color: $color-base;

          &:hover {
            cursor: pointer;
            background-color: $color-light;
            color: $color-reverse;
          }

          &.active {
            cursor: default;
            background-color: $background-base;
            color: $color-base;
          }
        }
      }
    }
  }
}
</style>