<template>
  <SelectionTags
    class="filter-selection"
    :isShrinked="isShrinked"
    :tags="tags"
    @tag-close="onTagClose"
    @tags-clear="onTagsClear"
  >
    <template v-slot:title>{{
      selection.filter === "view" ? "视图" : selection.filter.title
    }}</template>

    <!-- <template v-slot:suffix>
      <slot name="suffix"></slot>
    </template> -->
  </SelectionTags>
</template>

<script lang="ts">
import Vue, { PropType } from "vue";

import SelectionTags from "@/flexible-table-module/components/Common/SelectionTags/SelectionTags.vue";

import {
  FilterType,
  SelectionType,
} from "@/flexible-table-module/entity/HistorySelections";
import { ITreeNode } from "@/flexible-table-module/entity/TreeNode";

export default Vue.extend({
  name: "FilterSelection",
  components: {
    SelectionTags,
  },
  props: {
    selection: { type: Object as PropType<SelectionType>, required: true },
    isShrinked: { type: Boolean }, // 是否收缩，收缩的话会把一行放不下的内容隐藏
  },
  computed: {
    tags(): { text: string; attachment?: any }[] {
      return this.selection.twoDTreeNodes.map((oneDTreeNodes) => ({
        text: oneDTreeNodes[0].title,
      }));
    },
  },
  watch: {
    "tags.length": {
      immediate: true,
      handler(newLength, oldLength) {
        // 注意：tags 的长度为 0 时，本组件不会渲染，故不会触发从 1 到 0 的变化
        // console.log(`tags 的长度从 ${oldLength} 变为 ${newLength}`);
        this.$emit("tags-length-change", newLength);
      },
    },
  },
  methods: {
    onTagClose(tag: { text: string; attachment?: any }, index: number) {
      this.$emit(
        "tag-close",
        this.selection.filter,
        this.selection.twoDTreeNodes,
        index
      );
    },

    onTagsClear() {
      this.$emit(
        "tags-clear",
        this.selection.filter,
        this.selection.twoDTreeNodes
      );
    },
  },
});
</script>

<style lang="scss" scoped>
.filter-selection {
}
</style>