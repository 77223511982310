<template>
  <el-dialog class="ss-dialog" title="属性" width="600px" :visible.sync="dialogVisible" append-to-body>
    <div class="custom-header" slot="title">
      <span class="title">属性设置</span>
    </div>
    <div v-loading="loading" class="content">
      <div class="attribute-wrapper">
        <div class="attribute-header">
          <div class="resize-box">
            <div class="resize-box-cell one-line" ref="resizeLeft">属性名称</div>
            <div class="resize-box-cell-right">属性值</div>
          </div>
        </div>
        <div class="attribute-row" v-for="item in categories" :key="item.id">
          <span class="one-line" style="width:100px;" :title="item.dictName">{{item.dictName}}：</span>
          <span class="attribute-row__content">
          <el-select v-if="item.dictItemType==2" v-model="item.select" v-bind="$attrs" multiple size="small" style="width:300px"  placeholder="请选择">
            <el-checkbox-group v-model="item.select">
              <el-option v-for="i in item.detailDTOList" :key="i.id" :label="i.itemText" :value="i.id">
                <el-checkbox style="pointer-events: none" :label="i.id">
                  {{ i.itemText }}
                </el-checkbox>
              </el-option>
            </el-checkbox-group>
          </el-select>
          <span v-else>{{item.detailDTOList[0]?item.detailDTOList[0].itemText:""}}</span>
          </span>
        </div>
      </div>
    </div>
    <div slot="footer">
      <el-button type="info" @click="dialogVisible = false">取 消</el-button>
      <el-button type="primary" :loading="comfirmLoading" @click="handleConfirm">确 定</el-button>
    </div>
  </el-dialog>
</template>

<script>
import { File } from "@/api/File";
import { Netdisk } from '@/api/Netdisk';
export default {
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    category: Array,
    type: {
      type: String,
      default: 'single'
    },//编辑类型，batch批量
    selectfile:Array,//批量编辑时选择的文件列表
  },
  data() {
    return {
      loading: false,
      categories: [], // 分类
      selection: [],
      comfirmLoading: false,
    }
  },
  computed: {
    dialogVisible: {
      get() {
        return this.visible;
      },
      set(val) {
        this.$emit('update:visible', val);
      }
    },
  },
  created() {
    if(this.category.length){
      this.category.forEach(item => {
        item.select=[]
        if(!item.detailDTOList){
          item.detailDTOList = []
        }
        let list=item.detailDTOList.filter(item=>item.isSelected)
        list.forEach(i=>{
          item.select.push(i.id)
        })
      });
      this.categories=_.cloneDeep(this.category)
    }
  },
  mounted() {
    
  },
  methods: {
    /**
     * @description: 确定
     * @return {void}
     */
    async handleConfirm() {
      this.comfirmLoading = true;
      let list=[]
      this.categories.forEach(item=>{
        if(item.select.length){
          list.push({
            "dictId": item.dictId,
            "dictItemIds": item.select
          })
        }else{
          list.push({
            "dictId": item.dictId,
            "dictItemIds": []
          })
        }
      })
      let payload;
      if(this.type=='single'){
        payload={
          "dataIds": [this.categories[0].dataId],
          "dataTypes": [1],
          "dictItemDTOS":list  
        }
      }else{
        const idArray = this.selectfile.map(item => item.id);
        const fileTypeArray = this.selectfile.map(item => parseInt(item.fileType));
        payload={
          "dataIds": idArray,
          "dataTypes": fileTypeArray,
          "dictItemDTOS":list
        }
      }
      const res = await Netdisk.batchSetFileAttribute(payload);
      this.comfirmLoading = false;
      if (res) {
        this.$message.success('成功')
        this.$emit("finish");
        this.dialogVisible = false;
      }
    },
  }
}
</script>

<style lang="scss" scoped>
.custom-header {
  display: flex;
  align-items: center;

  .title {
    font-size: 18px;
    font-weight: 500;
    color: var(--color-text-primary);
  }

  .title-version {
    flex: 1;
    font-size: 12px;
    font-weight: 500;
    padding: 0 28px 0 8px;
    color: var(--color-text-secondary);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.content {
  padding: 6px 30px 20px;
  max-height: 380px;
  overflow-y: auto;

  .attribute-wrapper {
    overflow: auto;
    font-size: 14px;
    font-weight: 500;
    color: var(--color-text-regular);
    .attribute-header {
    width: 100%;
    height: 32px;
    border-radius: 5px 5px 0 0;
    margin-bottom: 16px;
    background-color: #fafbfc;
    display: flex;
    align-items: center;

    .resize-box {
      flex: 1;
      display: flex;
      align-items: center;

      .resize-box-cell {
        width: 76px;
        padding-left: 0px;
      }

      .resize-box-cell-right {
        box-sizing: border-box;
        flex: 1;
        padding-left: 40px;
      }
    }
  }
    .attribute-row {
      line-height: 20px;
      border-radius: 5px;
      margin-bottom: 16px;
      display: flex;
      position: relative;

      .attribute-row__content {
        flex: 1;
        word-break: break-all;
        margin-left: 10px;
        .descr {
          padding-left: 16px;
          color: #9facc2;
        }
      }
    }
  }
}
::v-deep .el-select-dropdown__item.selected::after {
  display: none;
  font-size: 0;
}
</style>