<template>
  <el-dialog
    append-to-body
    class="ss-dialog"
    width="420px"
    :visible.sync="dialogVisible"
    :title="clickitem ? '编辑' : '新建'"
  >
    <div class="dialog-body">
      <el-form
        class="add-form"
        label-position="right"
        label-width="80px"
        :model="queryData"
      >
        <el-form-item label="名称：" required>
          <el-input
            v-model="queryData.name"
            maxlength="10"
            show-word-limit
          ></el-input>
        </el-form-item>
        <el-form-item label="默认：" prop="columnName">
          <el-select v-model="queryData.isDefault" placeholder="请选择">
            <el-option
              v-for="item in options2"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
    </div>
    <span slot="footer">
      <div class="dialog-footer">
        <span class="info"></span>
        <span>
          <el-button type="info" @click="dialogVisible = false">取&nbsp;消</el-button>
          <el-button type="primary" :loading="confirmLoading" @click="confirmSave"
            >确&nbsp;定</el-button
          >
        </span>
      </div>
    </span>
  </el-dialog>
</template>
<script>
import { mapGetters } from "vuex";
import { ProductLibrary } from "@/api/ProductLibrary";
export default {
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    subject: Object,
    clickitem: Object,
  },
  data() {
    return {
      showList: [],
      queryData: {
        dataId: "",
        dataType: 1,
        description: "",
        id: "",
        isDefault: 0,
        name: "",
        tenantId: "",
      },
      confirmLoading: false,
      options2: [
        {
          value: 0,
          label: "否",
        },
        {
          value: 1,
          label: "是",
        },
      ],
    };
  },
  computed: {
    dialogVisible: {
      get() {
        return this.visible;
      },
      set(val) {
        this.$emit("update:visible", val);
      },
    },
    ...mapGetters(["userInfo"]),
  },
  mounted() {
    this.queryData.tenantId=this.userInfo.tenantIdNow
    if (this.clickitem) {
      const props = [
        "id",
        "isDefault",
        "name",
        "tenantId",
        "dataId",
        "dataType"
      ];
      this.queryData = _.pick(this.clickitem, props);
    } else {
      this.queryData.dataId  = this.subject.id;
    }
  },
  methods: {
    /**
     * @description: 保存
     * @return {void}
     */
    async confirmSave() {
      if (!this.queryData.name) {
        return this.$message.warning("请输入名称");
      }
      console.log(this.queryData);
      this.confirmLoading = true;
      const res = await ProductLibrary.UpdateScheme(
        this.clickitem ? "edit" : "add",
        this.queryData
      );
      if (res) {
        this.dialogVisible = false;
        this.$emit("finish");
      }
      this.confirmLoading = false;
    },
  },
};
</script>
<style lang="scss" scoped>
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 100px;
  height: 80px;
  line-height: 80px;
  text-align: center;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.avatar {
  width: 100px;
  height: 80px;
  display: block;
  border-radius: 5px;
}

.add-form {
  padding: 20px 30px;
}
</style>
