
/**
 * @description: 监听一个节点是否出现
 * @param {HTMLElement} el
 * @param {String} mark 用于 el.query.selector
 * @return {Promise}
 */
function promiseDom(el = document.body, mark) {
  return new Promise((resolve, reject) => {
    try {
      if (el.querySelector(mark)) {
        return resolve(true)
      }
      const config = { childList: true, subtree: true };
      const callback = function (_, observer) {
        if (el.querySelector(mark)) {
          observer.disconnect()
          resolve(true)
        }
      };
      const observer = new MutationObserver(callback);
      observer.observe(el, config);
    } catch (err) {
      return reject(err.message)
    }
  })
}


export default promiseDom
