const localfile = {
  state: {
    isconnected: false,
    sale4localuploadList: [],
  },
  getters: {
    isconnected: state => state.isconnected,
    sale4localuploadList: state => state.sale4localuploadList,
  },
  mutations: {
    saveConnected(state, payload) {
      state.isconnected = payload;
    },
    saveUploadList(state, payload) {
      state.sale4localuploadList.push(payload);
    },
    deleteUploadList(state, payload) {
      const index = state.sale4localuploadList.findIndex(item => item.msgId = payload.msgId)
      state.sale4localuploadList.splice(index, 1);
    },
  }
};
export default localfile