import request from "@/utils/appServer";
class Users {
  // 根据id查询用户信息
  static async queryUserInfoById(data) {
    try {
      const res = await request.get('/sales-show-4.0/sys/user/queryUserInfoById', { params: data });
      return res.data.code === 200 && res.data.result ? res.data.result : false;
    } catch (error) {
      return false;
    }
  }
  // 编辑租户下用户信息
  static async editUserInfo(data) {
    try {
      const res = await request.put('/sales-show-4.0/sys/user/editUserInfo', data, true);
      return res.data.code === 200;
    } catch (error) {
      return false;
    }
  }
  // 更换用户密码
  static async updateUserPwd(data) {
    try {
      const res = await request.put('/sales-show-4.0/sys/user/updateUserPwd', data, true);
      return res.data.code === 200 && res.data.result ? res.data.result : [];
    } catch (error) {
      return [];
    }
  }
  // 更换用户手机号
  static async updateUserPhone(data) {
    try {
      const res = await request.post('/sales-show-4.0/sys/user/updateUserPhone', data, true);
      return res.data.code === 200 && res.data.result ? res.data.result : [];
    } catch (error) {
      return [];
    }
  }
  // 获取用户按钮
  static async UserbuttonList() {
    try {
      const res = await request.get('/sales-show-4.0/subject/user/button/list');
      return res.data.code === 200 && res.data.result ? res.data.result : [];
    } catch (error) {
      return [];
    }
  }
  // 设置默认
  static async UserbuttonsetDefault(id) {
    try {
      const res = await request.get('/sales-show-4.0/subject/user/button/setDefault', { params: { id } });
      return res.data.code === 200;
    } catch (error) {
      return false;
    }
  }
  // 设置是否固定
  static async UserbuttonsetFixed(fixed) {
    try {
      const res = await request.get('/sales-show-4.0/subject/user/button/setFixed', { params: { fixed } });
      return res.data.code === 200;
    } catch (error) {
      return false;
    }
  }
  // 获取展示模式快捷键
  static async getShortcutKey() {
    try {
      const res = await request.get('/sales-show-4.0/showShortcutKey/getShortcutKey');
      return res.data.code === 200 && res.data.result ? res.data.result : [];
    } catch (error) {
      return [];
    }
  }
  // 添加展示模式快捷键
  static async addKey(data) {
    try {
      const res = await request.post('/sales-show-4.0/showShortcutKey/addKey', data);
      return res.data.code === 200 && res.data.success;
    } catch (error) {
      return false;
    }
  }
  // 编辑展示模式快捷键
  static async editKey(data) {
    try {
      const res = await request.post('/sales-show-4.0/showShortcutKey/editKey', data);
      return res.data.code === 200 && res.data.success;
    } catch (error) {
      return false;
    }
  }
  // 新增展示方案add/编辑展示方案edit
  static async UpdateKey(url, data) {
    try {
      const res = await request.post(`/sales-show-4.0/showShortcutKey/${url}`, data);
      return res.data.code === 200;
    } catch (error) {
      return false;
    }
  }
  // 恢复默认展示模式快捷键
  static async deleteShortcutKey(id) {
    try {
      const res = await request.delete('/sales-show-4.0/showShortcutKey/deleteShortcutKey', { params: { id } });
      return res.data.code === 200 && res.data.success;
    } catch (error) {
      return false;
    }
  }
}

export { Users }