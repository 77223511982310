<template>
  <div :id="uniqueId" class="filter-conditions" ref="filterConditions">
    <FloatingDialog
      v-if="hasConditions"
      class="filter-conditions__floating-dialog"
      :class="{ expanded: isDialogExpanded }"
      :isExpanded.sync="isDialogExpanded"
      :isClickable="!this.autoExpand"
      @resize="onDialogResize"
    >
      <template v-for="selection in sidebarSelections">
        <FilterSelection
          class="condition"
          :key="selection.filter === 'view' ? '视图' : selection.filter.title"
          :selection="selection"
          :isShrinked="!isDialogExpanded"
          @tag-close="onFilterSelectionTagClose"
          @tags-clear="onFilterSelectionTagsClear"
          @tags-length-change="onTagsLengthChange"
        >
        </FilterSelection>
      </template>
      <template v-for="column in columnsWithFilterTarget">
        <ColumnFilter
          class="condition"
          :key="column.id"
          :column="column"
          :isShrinked="!isDialogExpanded"
          @filter-tag-close="onColumnFilterTagClose"
          @filter-tags-clear="onColumnFilterTagsClear"
          @tags-length-change="onTagsLengthChange"
        >
        </ColumnFilter>
      </template>

      <template v-slot:btn-expanded>
        <div class="btn-clear" @click="onBtnClearClick">清空</div>
      </template>
    </FloatingDialog>
  </div>
</template>
<script lang="ts">
import Vue, { PropType } from "vue";
import { MessageBox as ElMessageBox } from "element-ui";
import PubSub from "pubsub-js";

import FilterSelection from "./FilterConditions/FilterSelection.vue";
import ColumnFilter from "./FilterConditions/ColumnFilter.vue";
import FloatingDialog from "@/flexible-table-module/components/Common/FloatingDialog.vue";

// import { ResizeObserverWrapper } from "@/flexible-table-module/util/ResizeObserverWrapper";

import { ITreeNode } from "@/flexible-table-module/entity/TreeNode";
import {
  HistorySelections,
  FilterType,
  SelectionType,
} from "@/flexible-table-module/entity/HistorySelections";
import { ColumnType } from "@/flexible-table-module/entity/Table";
import { Column } from "@/flexible-table-module/entity/Table/Column";
import { Filter } from "@/flexible-table-module/entity/Filter";

const BTN_ELLIPSIS_CLASS = "btn-ellipsis";

export default Vue.extend({
  inheritAttrs: false,
  name: "FilterConditions",
  components: {
    FilterSelection,
    ColumnFilter,
    FloatingDialog,
  },
  props: {
    // 是否在内容多于两行时自动展开
    autoExpand: { type: Boolean, default: true },
    // 存放所有被选择项的历史记录
    historySelections: {
      type: Object as PropType<HistorySelections>,
      required: true,
    },
    // // 表格的列对象
    // tableColumns: {
    //   type: Array as PropType<ColumnType[]>,
    //   default: () => [],
    // },

    columnsWithFilterTarget: {
      type: Array as PropType<Column[]>,
      default: () => [],
    },
  },
  data: () => ({
    // reszObsvWrapper: null as ResizeObserverWrapper | null,
    isDialogExpanded: false,
    uniqueId: "filter-conditions-" + Math.floor(Math.random() * 100000),
  }),
  computed: {
    // 是否有过滤器条件
    hasConditions(): boolean {
      return (
        this.sidebarSelections.length > 0 ||
        this.columnsWithFilterTarget.length > 0
      );
    },

    // 获取列过滤器被设置过的列
    // columnsWithFilterTarget(): Column[] {
    //   return this.tableColumns.filter((column) => {
    //     if (column instanceof Column) {
    //       if (
    //         !column.filter ||
    //         column.filter.target === undefined ||
    //         column.filter.target === null ||
    //         (Array.isArray(column.filter.target) &&
    //           column.filter.target.length === 0)
    //       ) {
    //         return false;
    //       } else {
    //         return true;
    //       }
    //     } else return false;
    //   }) as Column[];
    // },

    // 所有历史记录中的选择项
    sidebarSelections(): SelectionType[] {
      return [...this.historySelections.selections];
    },

    // ----------------------------------------------------------------

    // // 获取历史记录中的第一个选择项
    // firstHisSelection(): SelectionType | undefined {
    //   return this.sidebarSelections[0];
    // },
    // // 获取历史记录中除了第一个选择项外的其他选择项
    // restHisSelections(): SelectionType[] {
    //   if (this.firstHisSelection) {
    //     return this.sidebarSelections.slice(1);
    //   } else {
    //     return this.sidebarSelections;
    //   }
    // },
    // // 获取第一个有过滤器设置项的列
    // firstColWithTarget(): Column | undefined {
    //   return this.columnsWithFilterTarget[0];
    // },
    // // 获取除了第一个有过滤器设置项的列外的其他有过滤器设置项的列
    // restColsWithTarget(): Column[] {
    //   if (this.firstHisSelection) {
    //     return this.columnsWithFilterTarget;
    //   } else {
    //     return this.columnsWithFilterTarget.slice(1);
    //   }
    // },
  },
  watch: {
    // isDialogExpanded: {
    //   immediate: true,
    //   handler(expanded: boolean) {
    //     // if (expanded) {
    //     //   this.removeBtnEllipsis();
    //     // } else {
    //     //   this.$nextTick(() => {
    //     //     this.geneBtnEllipsis();
    //     //   });
    //     // }
    //   },
    // },
    "columnsWithFilterTarget.length": {
      immediate: true,
      async handler(newLength, oldLength) {
        await this.$nextTick();
        this.onNeedTtoAdjustDisplay();
      },
    },
    "sidebarSelections.length": {
      immediate: true,
      async handler(newLength, oldLength) {
        await this.$nextTick();
        this.onNeedTtoAdjustDisplay();
      },
    },
  },
  mounted() {
    // const filterConditions = this.$refs.filterConditions as HTMLElement;
    // this.reszObsvWrapper = new ResizeObserverWrapper(filterConditions);
    // this.reszObsvWrapper.afterResized(() => {});
  },
  beforeDestroy() {
    // this.reszObsvWrapper?.destroy();
  },
  methods: {
    // 当关闭来自“侧栏筛选器”的某个条件
    async onFilterSelectionTagClose(
      filter: FilterType,
      twoDTreeNodes: ITreeNode[][],
      index: number
    ) {
      const oneDTreeNodes = this.historySelections.delSelectionItem(
        filter,
        index
        // twoDTreeNodes
      );

      // 通知 FilterDetail 组件，刷新树状选择器，树状选择器更新后
      PubSub.publish("filter-conditions-tag-close", [oneDTreeNodes]);

      await this.$nextTick();
      this.$emit("condition-close", "FilterSelection", {
        filter,
        oneDTreeNodes,
        index,
      });
    },

    // 当关闭来自“侧栏筛选器”的清除按钮
    async onFilterSelectionTagsClear(
      filter: FilterType,
      twoDTreeNodes: ITreeNode[][]
    ) {
      const all = this.historySelections.delSelection(filter);

      if (all.length > 0) {
        // 通知 FilterDetail 组件，刷新树状选择器，树状选择器更新后
        PubSub.publish("filter-conditions-tag-close", all);
      }

      await this.$nextTick();
      this.$emit("conditions-clear", "FilterSelection", {
        filter,
        twoDTreeNodes: all,
      });
    },

    // 当关闭来自”表头列筛选器“的某个条件
    onColumnFilterTagClose(column: ColumnType, tagIndex: number) {
      if (column instanceof Column && column.filter) {
        if (
          (column.filter.type === "string-select" ||
            column.filter.type === "key-str-value-select") &&
          Array.isArray(column.filter.target)
        ) {
          column.filter.target.splice(tagIndex, 1);
        } else {
          column.filter.target = undefined;
        }

        this.$emit("condition-close", "ColumnFilter", { column, tagIndex });
      }
    },

    // 当关闭来自”表头列筛选器“的清除按钮
    onColumnFilterTagsClear(column: ColumnType) {
      if (column instanceof Column && column.filter) {
        if (
          (column.filter.type === "string-select" ||
            column.filter.type === "key-str-value-select") &&
          Array.isArray(column.filter.target)
        ) {
          column.filter.target = [];
        } else {
          column.filter.target = undefined;
        }
      }

      this.$emit("conditions-clear", "ColumnFilter", column);
    },

    // 当点击清空按钮
    onBtnClearClick() {
      ElMessageBox.confirm(`是否确定清空所有选择项?`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          const all = this.historySelections.clear();

          if (all.length > 0) {
            // 通知 FilterDetail 组件，刷新树状选择器，树状选择器更新后
            PubSub.publish("filter-conditions-tag-close", all);
          }

          this.columnsWithFilterTarget.forEach((column) => {
            if (column instanceof Column && column.filter) {
              if (
                (column.filter?.type === "string-select" ||
                  column.filter?.type === "key-str-value-select") &&
                Array.isArray(column.filter.target)
              ) {
                column.filter.target = [];
              } else {
                column.filter.target = undefined;
              }
            }
          });

          this.isDialogExpanded = false;
          this.$emit("conditions-clear");
        })
        .catch(() => {});
    },

    // 当 dialog 的左右宽度发生变化时
    async onDialogResize() {
      await this.$nextTick();
      this.onNeedTtoAdjustDisplay();
    },

    // 当 tags 的长度发生变化时
    async onTagsLengthChange(newLength: number) {
      await this.$nextTick();
      this.onNeedTtoAdjustDisplay();
    },

    // 当需要调整显示样式时
    onNeedTtoAdjustDisplay() {
      if (this.autoExpand) {
        if (this.calcCurrentRowCount() > 1) {
          this.isDialogExpanded = true;
        } else {
          this.isDialogExpanded = false;
        }
      } else {
        this.geneBtnEllipsis();
      }
    },

    // 计算当前 dialog 的所有 condition 的 top 值，从而知道当前 dialog 有多少行
    calcCurrentRowCount() {
      const conditions = this.getAllConditions();
      if (!conditions) return 0;

      // 计算每个 condition 的 top 值
      let constionTops: number[];
      if (conditions.length)
        constionTops = Array.from(conditions).map((condition) => {
          return condition.getBoundingClientRect().top;
        });
      else constionTops = [];

      // 相邻的多个 condition 的 top 值相等，说明这两个 condition 在同一行
      // 通过 Set 去重，得到所有行的 top 值
      const tops = Array.from(new Set(constionTops));
      return tops.length;
    },

    // 计算 conditions 位于 dialog 的第几行，从而决定 btn-ellipsis 的显示在哪个 condition 上
    geneBtnEllipsis() {
      const conditions = this.getAllConditions();

      if (!conditions) return;

      // 计算每个 condition 的 top 值
      let constionTops: number[];
      if (conditions.length)
        constionTops = Array.from(conditions).map((condition) => {
          return condition.getBoundingClientRect().top;
        });
      else constionTops = [];

      // 找出 conditionTops 中最少的 tops 中的最后一个
      // 首先，找到数组中的最小值
      const minTop = Math.min(...constionTops);
      // 然后，找到最小值在数组中最后出现的位置(lastIndex 将是最小值最后出现的索引。如果数组为空，minTop 将是 Infinity，lastIndex 将是 -1)
      const lastIndex = constionTops.lastIndexOf(minTop);

      // ----------------------------------------------------------------

      this.removeBtnEllipsis();

      // 如果 lastIndex 刚好是最后一个 condition 的索引，就不需要添加省略号按钮
      if (lastIndex === conditions.length - 1) return;

      conditions?.forEach((condition, index) => {
        if (index === lastIndex) {
          condition.appendChild(this.btnEllipsis(BTN_ELLIPSIS_CLASS));
        } else {
          // const btnEllipsis = condition.querySelector(`.${BTN_ELLIPSIS_CLASS}`);
          // if (btnEllipsis) btnEllipsis.remove();
        }
      });
    },

    // 移除所有省略号按钮
    removeBtnEllipsis() {
      const conditions = this.getAllConditions();
      conditions?.forEach((condition) => {
        const btnEllipsis = condition.querySelector(`.${BTN_ELLIPSIS_CLASS}`);
        if (btnEllipsis) btnEllipsis.remove();
      });
    },

    // 获取所有 condition
    getAllConditions() {
      // 查找 uniqueId 对应的元素
      const filterConditions = document.getElementById(this.uniqueId);
      // 查找 uniqueId 下的所有 condition
      const conditions = filterConditions?.querySelectorAll(".condition");
      return conditions;
    },

    // 代表省略号按钮的 div(<div class="btn-ellipsis">...</div>)
    btnEllipsis(className: string): HTMLElement {
      const div = document.createElement("div");
      div.className = className;
      const svgStr = `<svg t="1724289193973" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="4273" xmlns:xlink="http://www.w3.org/1999/xlink">
                      	<path d="M221 592c-44.183 0-80-35.817-80-80s35.817-80 80-80 80 35.817 80 80-35.817 80-80 80z m291 0c-44.183 0-80-35.817-80-80s35.817-80 80-80 80 35.817 80 80-35.817 80-80 80z m291 0c-44.183 0-80-35.817-80-80s35.817-80 80-80 80 35.817 80 80-35.817 80-80 80z" p-id="4274">
                      	</path>
                      </svg>`;
      const domparser = new DOMParser();
      const svg = domparser.parseFromString(svgStr, "image/svg+xml");
      div.appendChild(svg.documentElement);

      // div.innerText = "...";
      return div;
    },
  },
});
</script>

<style lang="scss" scoped>
@import "src/flexible-table-module/scss/_variables.scss";

.filter-conditions {
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  padding-right: 8px;
  display: flex;
  align-items: center;

  &__floating-dialog {
    width: 100%;
    height: 100%;

    .condition {
    }

    .btn-clear {
      height: 36px;
      line-height: 36px;
      background-color: #fff;
      color: $color-light;
      flex-shrink: 0;
      font-size: $fs-filter-conditions--btn;
      cursor: pointer;
      user-select: none;

      &:hover {
        color: $color-primary;
      }
    }
  }
}
</style>

<style lang="scss">
@import "src/flexible-table-module/scss/_variables.scss";

$btn-size: 24px;

.filter-conditions {
  .condition {
    .btn-ellipsis {
      box-sizing: border-box;
      width: $btn-size;
      height: $btn-size;
      line-height: $btn-size;
      background-color: #fff;
      border: 1px solid $border-color;
      border-radius: 4px;
      color: $color-light;
      fill: $color-light;
      flex-shrink: 0;
      cursor: pointer;
      user-select: none;
      margin-left: 4px;

      &:hover {
        color: $color-primary;
      }
    }
  }

  .expanded {
    .condition {
      // 展开后，隐藏省略号按钮
      .btn-ellipsis {
        display: none;
      }
    }
  }
}
</style>