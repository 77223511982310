import { getRamdomStr } from "../util/EncryptionHelper";
import { BaseFilter, IFilterListItem } from "./BaseFilter";
import { ITreeNode } from "./TreeNode";

export type TypeMode = "single" | "multiple" | "view";

export type TypeFilterConditions = {
    currMode: TypeMode | "none";
    filterScope: {
        filter: Filter | MultiFilter | "view";
        selections: ITreeNode[][]
    }[];
};

export class Filter<T extends string = string> implements BaseFilter {
    /* 前端使用的，由前端自定义的id */
    public $id: string;
    /** 过滤器名称(具有唯一性) */
    public id: T;
    /** 过滤器标题 */
    public title: string;
    /** 是否激活 */
    public active: boolean;
    /** 是否归档 */
    public archived: boolean;
    /** 是否异步加载过滤器筛选项的内容 */
    public listAsync: boolean;
    /** 过滤器分类 */
    public category?: string;
    /** 过滤器筛选项 */
    public list?: IFilterListItem[];

    public filterListLoadedCount: number = 0; // 前端内部使用
    public isFilterListLoading: boolean = false; // 前端内部使用

    constructor(params: {
        $id?: string,
        id: T,
        title: string,
        active?: boolean,
        archived?: boolean,
        listAsync?: boolean,
        category?: string,
        list?: IFilterListItem[],
    }) {
        const {
            $id,
            id,
            title,
            active = true,
            archived = false,
            listAsync = false, // 默认不异步加载，在加载 Filter 时就确定下来不变，需后端特别指定，才异步加载
            category,
            list,
        } = params;

        this.$id = $id ?? `${getRamdomStr('********')}`;
        this.id = id;
        this.title = title;
        this.active = active;
        this.archived = archived;
        this.listAsync = listAsync;
        this.category = category;
        this.list = list;
    }

    /** 被选中的筛选项集合 */
    public get listSelected(): IFilterListItem[] {
        if (this.list) return this.list.filter(item => !!item.isChecked);
        else return [];
    }

    /**
     * 克隆本对象
     * @param removeListCheck 是否把 list 属性的所有项的勾选都重置为非选中
     */
    public clone(removeListCheck: boolean = false): Filter<T> {

        function removeCheck(list: IFilterListItem[]) {
            for (const item of list) {
                item.isChecked = false;
                item.isHalfChecked = false;
                if (item.children && item.children.length > 0) {
                    removeCheck(item.children);
                }
            }
        }

        let listCopy;
        if (this.list === undefined) {
            listCopy = undefined;
        } else {
            listCopy = JSON.parse(JSON.stringify(this.list));

            if (listCopy) {
                if (removeListCheck) {
                    removeCheck(listCopy);
                }
            }
        }

        // $id 需要复制，因为在某些情况下，需要根据 $id 来判断 Filter 是否相等
        const filterCopy = new Filter({ ...this, list: listCopy });

        return filterCopy;
    }
}

abstract class IntegratedFilter {
    abstract title: string;
}

/** 多列条件筛选器 */
export class MultiFilter extends IntegratedFilter {

    public id: string;

    // public title?: string;
    public title: string;

    // public get title(): string {
    //     if (this._title) return this._title; // 标题已被用户手动设置
    //     else {
    //         const titles = this.filters.map(f => f.title);
    //         if (titles.length > 0) return titles.reduce((a, b) => a + '-' + b); // 用户未设置标题，并且有至少一个筛选器被选择
    //         else return ""; // 用户未设置标题，并且没有任何筛选器被选择
    //     }
    // }

    // public set title(val: string) {
    //     this._title = val;
    // }

    public filters: Filter[];

    constructor(params: {
        id: string,
        // title?: string,
        title: string,
        filters: Filter[],
    }) {
        super();

        const { id, title, filters } = params;

        this.id = id;
        this.title = title;

        this.filters = filters.map(f => {
            if (f instanceof Filter) return f;
            else return new Filter(f);
        });
    }

    public clone(): MultiFilter {
        return new MultiFilter({
            id: this.id,
            title: this.title,
            filters: [...this.filters],
        })
    }

    public getDefaultTitle() {
        const titles = this.filters.map(f => f.title);
        if (titles.length > 0) return titles.reduce((a, b) => a + '-' + b); // 用户未设置标题，并且有至少一个筛选器被选择
        else return ""; // 用户未设置标题，并且没有任何筛选器被选择
    }
}

/** 视图型筛选器 */
export class ViewFilter extends IntegratedFilter {
    public id: string;
    public title: string;
    public filters: Filter[];

    constructor(params: {
        id: string,
        title: string,
        filters: Filter[],
    }) {
        super();
        const { id, title, filters } = params;

        this.id = id;
        this.title = title;
        this.filters = filters;
    }

    public clone(): ViewFilter {

        const filtersCopy: Filter[] = [];

        for (const filter of this.filters) {
            const filterCopy = filter.clone(false);
            filtersCopy.push(filterCopy);
        }

        return new ViewFilter({
            id: this.id,
            title: this.title,
            filters: [...filtersCopy],
        });
    }
}