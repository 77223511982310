<template>
  <div class="table-contain">
    <el-table
      ref="multipleTable"
      header-cell-class-name="app-table-header"
      :data="tableData"
      style="width: 100%"
      @selection-change="handleSelectionChange"
    >
      <el-table-column v-if="showtype == 2" type="selection" width="55">
      </el-table-column>
      <el-table-column prop="date" label="名称" min-width="180">
        <template slot-scope="{ row }">
          <div class="quote-item flex pointer" @click="handlePreview(row)">
            <div class="item-cover">
              <img :src="row.coverList[0].coverUrl" alt="" />
            </div>
            <el-link :title="row.articleName ? row.articleName :''">
              <div style="max-width: 300px" class="one-line">
                {{ row.articleName ? row.articleName :"" }}
              </div>
            </el-link>
          </div>
        </template>
      </el-table-column>
      <el-table-column label="简介" width="180">
        <template slot-scope="{ row }">
          <div :title="row.coverRemark" class="one-line">{{ row.coverRemark }}</div>
        </template>
      </el-table-column>
      <el-table-column prop="address" label="更新时间" width="150">
        <template slot-scope="{ row }">
          {{ Object.dayjs(row.updateAt ? row.updateAt : row.createAt) }}
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "quote",
  props: {
    showtype: {
      type: Number,
      default: 1, //1不需要选框，2有选择框
    },
    demonstrate: {
      type: Boolean,
      default: false, //是否为演示类
    },
    datalist: Array,
  },
  data() {
    return {
      tableData: [],
      viewType: "grid",
      clickItem: null,
      showPreview: false,
      selectData: [],
    };
  },
  computed: {
    ...mapGetters(["userInfo"]),
  },
  created() {},
  mounted() {
    if (this.datalist) {
      this.datalist.forEach((element) => {
        if (element.coverList.length === 0) {
          element.coverList.push({
            coverUrl: require(`@/assets/images/sample-${1}.png`),
          });
        }
      });
      this.tableData = this.datalist;
    }
  },
  methods: {
    /**
     * @description: 查看产品详情
     * @return {void}
     */
    async handlePreview(value) {
      const routeData = this.$router.resolve({
        path: `/articlepreview/${value.id}`,
        query: {
          preveiew: true,
        },
      });
      window.open(routeData.href);
    },
    /**
     * @description: 切换格子/列表视图
     * @param {Object} value 点击项
     * @return {void}
     */
    changeViewType(type) {
      this.viewType = type;
    },
    handleSelect(value) {
      const index = this.selectData.findIndex((item) => item.id === value.id);
      if (index !== -1) {
        this.selectData.splice(index, 1);
      } else {
        this.selectData.push(value);
      }
      this.$emit("select", this.selectData);
    },
    checkSelect(value) {
      const index = this.selectData.findIndex((item) => item.id === value.id);
      return index != "-1";
    },
  },
};
</script>

<style lang="scss" scoped>
.table-contain {
  // height: 100%;
  width: 100%;
  padding: 16px 7px;
  // overflow-y: auto;
  box-sizing: border-box;
  .quote-item {
    align-items: center;
    .item-cover {
      border-radius: 5px;
      height: 43px;
      line-height: 43px;
      img {
        width: 73px;
        height: 43px;
        border-radius: 5px;
        margin-right: 14px;
      }
    }
  }
}
::v-deep .el-divider--horizonta {
  margin: 15px 0;
}
</style>
