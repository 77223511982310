<template>
  <div class="fd-main flex">
    <div class="fd-tablebox">
      <div class="flex fd-linkbox">
        <i
          v-if="!leftvisible"
          @click="leftvisible = true"
          class="el-icon-s-unfold showicon pointer"
        ></i>
        <div class="app-scheme-link" v-if="leftvisible">
          <div class="scheme-title flex-between align-items-center">
            <!-- <div class="scheme-title-name">链接</div> -->
            <div class="scheme-title-name"></div>
            <div class="flex align-items-center">
              <svg-icon
                v-if="!ispre"
                class="mr-10 pointer"
                icon="mind-add"
                color="#5E6C84"
                :height="12"
                :width="12"
                @click.native="handleEdit(null)"
              ></svg-icon>
              <svg-icon
                class="mr-10 pointer"
                icon="close-side"
                color="#5E6C84"
                :height="16"
                :width="16"
                @click.native="leftvisible = false"
              ></svg-icon>
            </div>
          </div>
          <div class="scheme-title-list">
            <div
              class="scheme-title-list-items"
              :class="{
                'active-link': activelink.id == i.id && !isedit,
                'select-link': selectlinkids.includes(i.id) && isedit,
              }"
              v-for="i in linklist"
              :key="i.id"
              @click="handleClick(i)"
            >
              <div class="list-items-name flex align-items-center">
                <div :title="i.name">{{ i.name }}</div>
                <template v-if="!ispre">
                  <svg-icon
                    class="pointer"
                    icon="edit-project-name"
                    color="#5E6C84"
                    :height="14"
                    :width="14"
                    @click.native="handleEdit(i)"
                  ></svg-icon>
                  <svg-icon
                    class="pointer"
                    icon="delete16"
                    color="#5E6C84"
                    :height="14"
                    :width="14"
                    @click.native="handleDelete(i)"
                  ></svg-icon>
                </template>
                <svg-icon
                  class="ml-10"
                  icon="new_tabs"
                  width="16"
                  height="16"
                  :color="'#5E6C84'"
                  @click.native.prevent="handleOpenTab(i.demoLink)"
                ></svg-icon>
                <svg-icon
                  v-if="ispre"
                  class="pointer"
                  icon="preview"
                  color="#5E6C84"
                  :height="14"
                  :width="14"
                  :class="{ 'active-icon': activelink.id == i.id }"
                  @click.native.prevent.stop="handleRowClick(i)"
                ></svg-icon>
              </div>
              <div class="flex-between align-items-center list-items-detail">
                <div class="one-line">{{ i.demoLink }}</div>
                <svg-icon
                  class="pointer"
                  icon="copy"
                  color="#5E6C84"
                  :height="18"
                  :width="18"
                  @click.native.prevent.stop="handleCopy(i.demoLink)"
                ></svg-icon>
              </div>
              <div class="flex-between align-items-center" v-if="i.loginAccount">
                <div class="one-line">账号：{{ i.loginAccount }}</div>
                <svg-icon
                  class="pointer"
                  icon="copy"
                  color="#5E6C84"
                  :height="18"
                  :width="18"
                  @click.native.prevent.stop="handleCopy(i.loginAccount)"
                ></svg-icon>
              </div>
              <div class="flex-between align-items-center" v-if="i.loginPassword">
                <div class="one-line">密码：{{ i.loginPassword }}</div>
                <svg-icon
                  class="pointer"
                  icon="copy"
                  color="#5E6C84"
                  :height="18"
                  :width="18"
                  @click.native.prevent.stop="handleCopy(i.loginPassword)"
                ></svg-icon>
              </div>
              <svg-icon class="check-icon" icon="check-icon" color="#365EFF"></svg-icon>
            </div>
          </div>
        </div>
        <div class="netweb">
          <iframe
            v-if="activeurl"
            class="w-h-100"
            :src="activeurl"
            frameborder="0"
          ></iframe>
          <el-empty v-else :image-size="200"></el-empty>
        </div>
      </div>
    </div>
    <UpdateLinkDialog
      v-if="linkvisible"
      :visible.sync="linkvisible"
      :demo-link="clickitem"
      @finish="getEditlink"
    />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import UpdateLinkDialog from "@/views/ProductLibrary/dialog/UpdateLinkDialog.vue";
import { ProductLibrary } from "@/api/ProductLibrary";
export default {
  components: { UpdateLinkDialog },
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    subject: Object,
    activeColumn: Object,
    scheme: Object,
    ispre: {
      type: Boolean,
      default: false,
    },
    isedit: {
      type: Boolean,
      default: false,
    },
    schemeid: String,
    updatekey: Number,
    loginrequired: {
      //是否需要登录查看
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      clickitem: null,
      linkvisible: false,
      linklist: [],
      tablekey: 1,
      activeurl: "",
      leftvisible: true,
      queryData: {
        pageNo: 1,
        pageSize: 99,
        subjectColumnId: "",
        subjectId: "",
        name: "",
      },
      activelink: {},
      selectlinkids: [], //方案选择的id合集
    };
  },
  computed: {
    dialogVisible: {
      get() {
        return this.visible;
      },
      set(val) {
        this.$emit("update:visible", val);
      },
    },
    ...mapGetters(["token", "userInfo"]),
  },
  watch: {
    activeColumn() {
      this.queryData.subjectColumnId = this.activeColumn.id;
      this.queryData.subjectId = this.subject.id;
      this.getList();
    },
    schemeid(val) {
      val && this.gedefaulttList();
    },
    updatekey() {
      this.schemeid && this.gedefaulttList();
    },
    isedit(val) {
      if (val) {
        this.schemeid && this.gedefaulttList();
      }
    },
  },
  mounted() {
    this.queryData.subjectColumnId = this.activeColumn.id;
    this.queryData.subjectId = this.subject.id;
    this.getList();
    // 关闭资源
    if (!this.isPreview) {
      this.$Pubsub.publish("update-resource-select-data", []);
      this.$Pubsub.publish("update-resource-visible", { visible: true, type: [] });
    }
  },
  methods: {
    /**
     * @description:获取链接列表
     * @return {void}
     */
    async getList() {
      if (!this.loginrequired) {
        this.queryData.shareId = this.subject.shareId;
      }
      const res = await ProductLibrary[
        this.loginrequired ? "queryLinkPageList" : "queryLinkPageListNoLogin"
      ](this.queryData);
      // const res = await ProductLibrary.queryLinkPageList(this.queryData);
      this.linklist = res.records;
      if (this.linklist.length) {
        this.handleRowClick(this.linklist[0]);
      }
      if (this.isedit) {
        this.schemeid && this.gedefaulttList();
      }
    },
    /**
     * @description:编辑链接
     * @param {Object} value 点击项
     * @return {void}
     */
    handleEdit(value) {
      this.clickitem = value;
      this.linkvisible = true;
    },
    handleDelete(value, index) {
      this.$showMessage(
        {
          message: `是否确认删除该链接 ?`,
          ...this.$global.deleteMessage,
        },
        async (done) => {
          // this.linklist.splice(index, 1);
          // this.$emit("change", this.linklist);
          const res = await ProductLibrary.deleteSubjectDemoLink(value.id);
          res && this.getList();
          done(true);
        }
      );
    },
    /**
     * @description:获取编辑后的链接
     * @param {Object} value 编辑项
     * @return {void}
     */
    async getEditlink(value) {
      const index = this.linklist.findIndex((item) => item.id == value.id);
      // index!='-1'&&(this.linklist[index]=value);
      if (index != "-1" && value.id) {
        const res = await ProductLibrary.editLink(value);
        res && this.getList();
      } else {
        let payload = value;
        payload.id = null;
        payload.subjectColumnId = this.activeColumn.id;
        payload.subjectId = this.subject.id;
        const res = await ProductLibrary.saveSubjectToLink(payload);
        res && this.getList();
      }
    },
    /**
     * @description:行点击事件
     * @param {Object} value 点击项
     * @return {void}
     */
    handleRowClick(value) {
      this.activelink = value;
      this.activeurl = `${value.demoLink.startsWith("http") ? "" : "//"}${
        value.demoLink
      }${value.loginAccount || value.loginPassword ? "?" : ""}${
        value.loginAccount ? `account=${value.loginAccount}` : ""
      }${value.loginPassword ? `&password=${value.loginPassword}` : ""}`;
    },
    /**
     * @description:获取已选链接列表
     * @return {void}
     */
    async gedefaulttList() {
      const res = await ProductLibrary.getSchemeToLinkList({
        schemeId: this.schemeid,
        subjectColumnId: this.activeColumn.id,
      });
      this.selectlinkids = res.map((item) => item.dataId);
    },
    /**
     * @description: 复制
     * @param {String} text 复制项
     * @return {void}
     */
    handleCopy(text) {
      const res = this.common.handleCopy(text);
      res && this.$message.success("复制成功");
    },
    handleClick(i) {
      if (this.isedit) {
        if (this.selectlinkids.includes(i.id)) {
          this.selectlinkids = this.selectlinkids.filter((item) => item != i.id);
        } else {
          this.selectlinkids.push(i.id);
        }
        this.selectlinkids = Array.from(new Set(this.selectlinkids));
        const selectdata = {
          dataIds: this.selectlinkids,
          dataType: 4,
          schemeId: this.schemeid,
          subjectColumnId: this.activeColumn.id,
          subjectId: this.subject.id,
        };
        this.$emit("handleselect", selectdata);
      } else {
        this.handleRowClick(i);
      }
    },
    /**
     * @description:打开新窗口
     * @param {Object} val 点击项地址
     * @return {void}
     */
    handleOpenTab(val) {
      window.open(val);
    },
  },
};
</script>

<style lang="scss" scoped>
.fd-main {
  height: 100%;
  position: relative;
  font-size: 12px;
  justify-content: center;
  .fd-tablebox {
    min-width: 800px;
    width: 100%;
    // padding: 0 18px;
    box-sizing: border-box;
    background-color: #fff;
    .add-btn {
      padding: 10px 10px 10px 0;
      display: flex;
      justify-content: space-between;
      font-size: 16px;
      color: var(--color-text-secondary);
      .add-btn-title {
        align-items: center;
        i {
          margin-right: 10px;
        }
      }
    }
    .link-name {
      &:hover {
        text-decoration: underline;
      }
    }
  }
  .link-table {
    width: 100%;
  }
}
.oprate-item {
  width: 54px;
  height: 28px;
  line-height: 28px;
  &:hover {
    color: var(--color-primary);
    .svg-icon {
      color: var(--color-primary) !important;
    }
  }
}
.fd-linkbox {
  height: 100%;
  width: 100%;
  overflow: hidden;
  .list {
    width: 500px;
    border-right: 1px solid #e6ebf2;
  }
  .netweb {
    flex: 1;
  }
}
.showicon {
  font-size: 16px;
  line-height: 32px;
  color: #5e6c84;
}

.app-scheme-link {
  width: 322px;
  height: 100%;
  border-right: 1px solid #ebeef2;
  flex-shrink: 0;
  .scheme-title {
    height: 30px;
    border-bottom: 1px solid #d3dbeb;

    .scheme-title-name {
      margin-left: 14px;
      font-size: 12px;
      font-weight: 400;
    }
  }
  .scheme-title-list {
    height: calc(100% - 31px);
    width: 100%;
    padding: 0 10px 10px 10px;
    box-sizing: border-box;
    overflow-y: auto;

    .scheme-title-list-items {
      font-size: 12px;
      width: 100%;
      border-radius: 5px;
      border: 1px solid rgba(94, 108, 132, 1);
      margin-top: 16px;
      padding: 0 16px 10px 16px;
      box-sizing: border-box;
      position: relative;
      .list-items-name {
        line-height: 36px;
        font-weight: 700;
        div {
          margin-right: 10px;
        }
      }
      .list-items-detail {
        line-height: 28px;
      }
      .svg-icon {
        flex-shrink: 0;
        &:hover {
          color: var(--color-primary) !important;
        }
      }
    }
    .active-link {
      border: 2px solid var(--color-primary);
    }
    .active-icon {
      color: var(--color-primary) !important;
    }
    .check-icon {
      display: none;
      position: absolute;
      top: -1px;
      right: -1px;
    }
    .select-link {
      border: 2px solid var(--color-primary);
      position: relative;
      .check-icon {
        display: block;
      }
    }
  }
  .mr-10 {
    margin-right: 10px;
  }
}
</style>
