<template>
  <div
    class="selection-filters"
    :style="{ width: `${width}px`, padding: `0 ${headerPadding}px` }"
  >
    <div
      class="selection-filters__header"
      :style="{ height: `${headerHeight}px` }"
    >
      <FiltersDropdown
        :width="width"
        :headerPadding="headerPadding"
        :isMultiple="isMultiple"
        :activeFilters="activeFilters"
        :multiFilters="multiFilters"
        :viewFilters="viewFilters"
        :currFilter="currFilter"
        @filter-changed="onFiltersDropdownFilterChanged"
      />

      <div class="selection-filters__btn-group">
        <IconBtnSingle
          class="btn"
          :iconSize="ICON_BTN_SIZE"
          @btn-click="onBtnViewEditorClick"
        >
          <svg-icon
            sys="common-table"
            name="HeaderCellIconBtn/filter"
            :size="ICON_BTN_ICON_SIZE"
          />
        </IconBtnSingle>

        <IconBtnSingle
          v-if="hasShrinkBtn && !isShrink"
          class="btn"
          :iconSize="ICON_BTN_SIZE"
          @btn-click="$emit('btn-shrink-click')"
        >
          <svg-icon
            sys="common-table"
            name="OccupyingColumn/fold"
            :size="ICON_BTN_ICON_SIZE"
            :rotation="180"
          />
        </IconBtnSingle>
      </div>
    </div>
    <div
      class="selection-filters__list"
      :style="{ height: `calc(100% - ${headerHeight}px)` }"
    >
      <FilterDetail
        ref="filterDetail"
        :currFilter="currFilter"
        :viewFilters="viewFilters"
        :disableTree="disableFilterDetail"
        :historySelections="historySelections"
        @checkbox-change="onFilterDetailTreeNodeCheck"
        @node-click="onFilterDetailTreeNodeClick"
      />
    </div>

    <ViewEditor
      :visible.sync="isViewEditorVisible"
      :filters="filters4ViewEditor"
      :filtersArchived="filters4ViewEditorArchived"
      :filtersAll="filters4ViewEditorAll"
      :multiFilters="multiFilters"
      :viewFilters="viewFilters"
      @show="onViewEditorShow"
      @close="onViewEditorClose"
      @single-filter-switch="
        (filter, checked) => $emit('single-filter-switch', filter, checked)
      "
      @single-filter-archive="
        (filter, checked) => $emit('single-filter-archive', filter)
      "
      @add-new-multiple-filter="
        (multiFilter) => $emit('add-new-multiple-filter', multiFilter)
      "
      @edit-title-multiple-filter="
        (multiFilter) => $emit('edit-title-multiple-filter', multiFilter)
      "
      @edit-filter-multiple-filter="onEditFilterMultipleFilter"
      @del-multiple-filter="
        (multiFilter) => $emit('del-multiple-filter', multiFilter)
      "
      @add-new-view-filter="onAddNewViewFilter"
      @edit-title-view-filter="onEditTitleViewFilter"
      @edit-filter-view-filter="onEditFilterViewFilter"
      @del-view-filter="onDelViewFilter"
      @single-conditions-editor-submit="
        (filtersUnarchived, callback) =>
          $emit('single-conditions-editor-submit', filtersUnarchived, callback)
      "
    />
  </div>
</template>
<script lang="ts">
import Vue, { PropType } from "vue";
import SvgIcon from "@/flexible-table-module/svg-icon-importer";

// import IconFilter from "@/flexible-table-module/icons/HeaderCellIconBtn/IconFilter.vue";

import ViewEditor from "./ViewEditor.vue";
import FiltersDropdown from "./SelectionFilters/FiltersDropdown.vue";
import FilterDetail from "./SelectionFilters/FilterDetail.vue";
import IconBtnSingle from "@/flexible-table-module/components/Common/IconBtnSingle.vue";

import { ITreeNode } from "@/flexible-table-module/entity/TreeNode";
import {
  Filter,
  MultiFilter,
  TypeFilterConditions,
  TypeMode,
  ViewFilter,
} from "@/flexible-table-module/entity/Filter";
import { HistorySelections } from "@/flexible-table-module/entity/HistorySelections";
import { Column } from "@/flexible-table-module/entity/Table/Column";
import { IFilterListItem } from "@/flexible-table-module/entity/BaseFilter";

import {
  ICON_BTN_ICON_SIZE,
  ICON_BTN_SIZE,
} from "@/flexible-table-module/constants";

// import { debounce } from "@/flexible-table-module/util/InputOptimize";

export default Vue.extend({
  inheritAttrs: false,
  name: "SelectionFilters",
  components: {
    SvgIcon,
    ViewEditor,
    FiltersDropdown,
    FilterDetail,
    IconBtnSingle,
  },
  props: {
    hasShrinkBtn: { type: Boolean, default: false }, // 是否有收缩按钮
    isShrink: { type: Boolean, required: true }, // 是否收缩侧边筛选栏
    filters4ViewEditor: { type: Array as PropType<Filter[]> }, // 未归档的过滤器列表
    filters4ViewEditorArchived: { type: Array as PropType<Filter[]> }, // 已归档的过滤器列表
    filters4ViewEditorAll: { type: Array as PropType<Filter[]> }, // 全部的过滤器列表
    activeFilters: { type: Array as PropType<Filter[]>, default: () => [] },
    multiFilters: { type: Array as PropType<MultiFilter[]> }, // 视图编辑器的多列条件选择的数据
    viewFilters: { type: Array as PropType<ViewFilter[]> }, // 视图编辑器的视图列表的数据
    // defaultFilter: {
    //   type: Object as PropType<Filter | MultiFilter | "view" | null>,
    //   default: null,
    // }, // 默认选中的过滤器
    currFilter: {
      type: [Object, String] as PropType<Filter | MultiFilter | "view" | null>,
    },
    isMultiple: { type: Boolean, default: false }, // 是否保持"非当前被选中的过滤器"的"已勾选过滤器项"
    width: { type: Number, required: true },
    disableFilterDetail: { type: Boolean, default: false }, // 过滤器详细列表是否暂时不可用
    historySelections: {
      type: Object as PropType<HistorySelections>,
      required: true,
    }, // 存放所有被选择项的历史记录
    headerHeight: { type: Number, default: 36 }, // 如果视图模型为卡片，则
  },
  data(): {
    // headerHeight: number;
    // btnWidth: number;
    headerPadding: number;
    // currFilter: Filter | MultiFilter | "view" | null; // 当前被选中的过滤器
    selectedListItems: IFilterListItem[]; // 当前被选中的过滤器中被勾选的过滤器项
    // onFilterListchange: (
    //   currCheckedNodes: ITreeNode[][],
    //   targetNode: ITreeNode | null,
    //   isChecked: boolean
    //   // currMode: "single" | "multiple" | "view"
    // ) => void; // 当前过滤器的过滤器项复选框选中或取消选中时
    isViewEditorVisible: boolean;
    ICON_BTN_SIZE: number;
    ICON_BTN_ICON_SIZE: number;
  } {
    return {
      // headerHeight: 64,
      // btnWidth: 32,
      headerPadding: 8,
      // currFilter: null,
      selectedListItems: [],
      // onFilterListchange: () => {}, // 不可为 undefined，因为页面加载时，checkbox-group 的 change 事件就会触发
      isViewEditorVisible: false,
      ICON_BTN_SIZE,
      ICON_BTN_ICON_SIZE,
    };
  },
  computed: {
    // isDisabled(): boolean {
    //   return this.activeFilters.length === 0;
    // },
    // iconStyle(): object {
    //   const size = 16;
    //   return { width: `${size}px`, height: `${size}px` };
    // },
    // currMode(): "single" | "multiple" | "view" | "none" {
    //   if (this.currFilter instanceof Filter) return "single";
    //   else if (this.currFilter instanceof MultiFilter) return "multiple";
    //   else if (this.currFilter === "view") return "view";
    //   else return "none";
    // },
  },
  watch: {
    // defaultFilter: {
    //   immediate: true,
    //   handler(newVal: Filter | MultiFilter | "view" | null, oldVal) {
    //     if (newVal !== null) {
    //       this.currFilter = newVal;
    //     }
    //   },
    // },
  },
  mounted() {
    // this.onFilterListchange =
    //   // debounce(
    //   (
    //     currChecked: ITreeNode[][],
    //     target: ITreeNode | null,
    //     isChecked: boolean
    //     // currMode: "single" | "multiple" | "view"
    //   ) => {
    //     this.$emit(
    //       "selection-list-change",
    //       currChecked, // 当前被选中的节点集合
    //       target, // 刚刚被选中的节点
    //       isChecked // 是被选中还是被取消选中
    //       // currMode // 当前的过滤模式
    //     );
    //   };
    // 1000
    // );
  },
  methods: {
    $onTableColumnFilterChange(col: Column) {
      (this.$refs["filterDetail"] as any).$onTableColumnFilterChange(col);
    },

    // 当当前过滤器有改变时
    onFiltersDropdownFilterChanged(filter: Filter | MultiFilter | "view") {
      this.$emit("selection-filter-change", filter);
    },

    // 当视图编辑器的按钮被点击时
    onBtnViewEditorClick() {
      this.isViewEditorVisible = true;
    },

    // -------------------------------------

    onFilterDetailTreeNodeCheck(
      currCheckedNodes: ITreeNode[][],
      targetNode: ITreeNode,
      isChecked: boolean
    ) {
      if (this.currFilter !== null) {
        // this.onFilterListChange(currCheckedNodes, targetNode, isChecked);

        this.$emit(
          "selection-list-checkbox-change",
          currCheckedNodes, // 当前被选中的节点集合
          targetNode, // 刚刚被选中的节点
          isChecked // 是被选中还是被取消选中
        );
      }
    },

    onFilterDetailTreeNodeClick(
      currCheckedNodes: ITreeNode[][],
      targetNode: ITreeNode
    ) {
      if (this.currFilter !== null) {
        // this.onFilterListChange(currCheckedNodes, targetNode, true);

        this.$emit(
          "selection-list-single-click",
          currCheckedNodes, // 当前被选中的节点集合
          targetNode, // 刚刚被选中的节点
          true // 是被选中还是被取消选中
        );
      }
    },

    // onFilterListChange(
    //   currChecked: ITreeNode[][],
    //   target: ITreeNode | null,
    //   isChecked: boolean
    // ) {
    //   this.$emit(
    //     "selection-list-change",
    //     currChecked, // 当前被选中的节点集合
    //     target, // 刚刚被选中的节点
    //     isChecked // 是被选中还是被取消选中
    //     // currMode // 当前的过滤模式
    //   );
    // },

    // -------------------------------------

    // getCurrMode(filter: Filter | MultiFilter | "view"): TypeMode {
    //   if (this.currFilter instanceof Filter) return "single";
    //   else if (this.currFilter instanceof MultiFilter) return "multiple";
    //   else if (this.currFilter === "view") return "view";
    //   else throw new Error("getCurrMode 传参出错");
    // },

    onViewEditorShow() {
      this.$emit("view-editor-show");
    },

    onViewEditorClose() {
      // if (this.currFilter instanceof Filter) {
      //   if (this.activeFilters.length === 0) {
      //     this.currFilter = null;
      //   }
      // }

      this.$emit("view-editor-close");
    },

    // --------------------------------------------------------------

    refreshFilterDetailData() {
      (this.$refs["filterDetail"] as any).$geneTreeData();
    },

    onEditFilterMultipleFilter(multiFilter: MultiFilter) {
      this.$emit("edit-filter-multiple-filter", multiFilter);
      this.refreshFilterDetailData();
    },

    onAddNewViewFilter(newViewFilter: ViewFilter) {
      this.$emit("add-new-view-filter", newViewFilter);
      this.refreshFilterDetailData();
    },

    onEditTitleViewFilter(viewFilter: ViewFilter) {
      this.$emit("edit-title-view-filter", viewFilter);
      this.refreshFilterDetailData();
    },

    onEditFilterViewFilter(viewFilter: ViewFilter, node: any) {
      this.$emit("edit-filter-view-filter", viewFilter, node);
      this.refreshFilterDetailData();
    },

    onDelViewFilter(viewFilter: ViewFilter) {
      this.$emit("del-view-filter", viewFilter);
      this.refreshFilterDetailData();
    },
  },
});
</script>
<style lang="scss" scoped>
.selection-filters {
  box-sizing: border-box;
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  // $header-height: 32px;

  .selection-filters__header {
    box-sizing: border-box;
    width: 100%;
    // height: $header-height;

    display: flex;
    justify-content: space-between;
    align-items: center;

    .selection-filters__btn-group {
      height: 100%;
      display: flex;
      justify-content: flex-end;
      align-items: center;

      .btn {
        margin-left: 4px;
      }
    }
  }

  .selection-filters__list {
    box-sizing: border-box;
    width: 100%;
    // height: calc(100% - $header-height);
    padding-top: 16px;
  }
}
</style>