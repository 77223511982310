<template>
  <div class="body-clickable-cell" @click="onClick">
    <OverflowTooltip :content="cellValueAfterFormat" :isRichText="isRichText">
      <BodyRichTextCell
        class="inner"
        v-if="isRichText"
        :richtext="cellValueAfterFormat"
      />
      <div class="inner" v-else v-text="cellValueAfterFormat"></div>
    </OverflowTooltip>
  </div>
</template>
<script lang="ts">
import Vue from "vue";
import OverflowTooltip from "@/flexible-table-module/components/Common/OverflowTooltip.vue";
import BodyRichTextCell from "@/flexible-table-module/components/FlexibleTable/CellInsideContent/BodyRichTextCell.vue";
import { Column } from "@/flexible-table-module/entity/Table/Column";
export default Vue.extend({
  inheritAttrs: false,
  name: "BodyClickableCell",
  components: {
    OverflowTooltip,
    BodyRichTextCell,
  },
  props: {
    scope: { type: Object },
    column: { type: Column },
    row: { type: Object },
    cellValueBeforeFormat: {},
    cellValueAfterFormat: { type: String },
    isRichText: { type: Boolean, default: false },
  },
  mounted() {},
  methods: {
    onClick() {
      this.$emit("cell-click", this.row, this.column);
    },
  },
});
</script>
<style lang="scss" scoped>
@import "src/flexible-table-module/scss/_variables.scss";
.body-clickable-cell {
  // color: $color-brand;

  // width: 100%; // 100% 影响单元格内置按钮的布局
  // flex-shrink: 1; // 收缩不起来

  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;

  &:hover {
    cursor: pointer;
    color: $color-brand;
    // background-color: #efebf7;
  }
}
</style>