import store from '../store';

const menu = [
  // {
  //   id: 'ME00000004',
  //   parentId: '-1',  
  //   name: '应用',
  //   icon: "module-icon-platform",
  //   iconType: "local",
  //   url: '',
  //   enable: false,
  //   default: true,
  //   group: "更多应用",
  //   children: [
  //     {
  //       id: 'ME000000041',
  //       parentId: 'ME00000004',
  //       name: '公共资源',
  //       type: "router",
  //       url: '/file',
  //       enable: true,
  //       default: true,
  //       children: [],
  //     }
  //   ],
  // },
  // {
  //   id: 'ME00000005',
  //   parentId: '-1',
  //   name: '分享管理',
  //   icon: "module-icon-3",
  //   iconType: "local",
  //   url: '/friend',
  //   enable: false,
  //   default: true,
  //   group: "分享中心",
  //   children: [
  //     {
  //       id: 'ME000000051',
  //       parentId: 'ME00000005',
  //       name: '好友管理',
  //       type: "router",
  //       url: '/contacts',
  //       enable: true,
  //       default: true,
  //       children: [],
  //     },
  //     {
  //       id: 'ME000000052',
  //       parentId: 'ME00000005',
  //       name: '分享空间',
  //       type: "router",
  //       url: '/share',
  //       enable: true,
  //       default: true,
  //       children: [],
  //     },
  //     {
  //       id: 'ME000000053',
  //       parentId: 'ME00000005',
  //       name: '我的收藏',
  //       type: "router",
  //       url: '/collect',
  //       enable: true,
  //       default: true,
  //       children: [],
  //     },
  //   ],
  // },
  {
    id: 'ME00000005',
    parentId: '-1',
    name: '分享管理',
    icon: "module-icon-3",
    iconType: "local",
    url: '/share',
    type: "router",
    enable: true,
    default: true,
    group: "基础管理",
    children: [],
  },
  {
    id: 'ME00000006',
    parentId: '-1',
    name: '回收站',
    icon: "module-icon-11",
    iconType: "local",
    url: '/recycle',
    type: "router",
    enable: true,
    default: true,
    group: "回收中心",
    children: [],
  },
  // {
  //   id: 'ME00000006',
  //   parentId: '-1',
  //   name: '空间管理',
  //   icon: "module-icon-4",
  //   iconType: "local",
  //   url: '/space',
  //   enable: false,
  //   default: true,
  //   children: [
  //     {
  //       id: 'ME000000061',
  //       parentId: 'ME00000006',
  //       name: '企业名片',
  //       type: "router",
  //       url: '/companyspace',
  //       enable: true,
  //       default: true,
  //       children: [],
  //     },
  //     {
  //       id: 'ME000000062',
  //       parentId: 'ME00000006',
  //       name: '个人名片',
  //       type: "router",
  //       url: '/businesscard',
  //       enable: true,
  //       default: true,
  //       children: [],
  //     },
  //   ],
  // },
  // {
  //   id: 'ME00000007',
  //   parentId: '-1',
  //   name: '平台通道',
  //   icon: "module-icon-platform",
  //   iconType: "local",
  //   type: "link",
  //   url: '',
  //   enable: false,
  //   default: true,
  //   group: "扩展功能",
  //   children: [
  //     {
  //       id: 'ME000000071',
  //       parentId: 'ME00000007',
  //       name: 'Teamco',
  //       type: "link",
  //       url: process.env.VUE_APP_WEBSITE_TEAMCO,
  //       enable: true,
  //       default: true,
  //       children: [],
  //     },
  //     {
  //       id: 'ME000000072',
  //       parentId: 'ME00000007',
  //       name: '协同平台',
  //       type: "link",
  //       url: process.env.VUE_APP_WEBSITE_COLLABORATION,
  //       enable: true,
  //       default: true,
  //       children: [],
  //     },
  //     {
  //       id: 'ME000000073',
  //       parentId: 'ME00000007',
  //       name: 'SaaS',
  //       type: "link",
  //       url: process.env.VUE_APP_WEBSITE_SAAS,
  //       enable: true,
  //       default: true,
  //       children: [],
  //     },
  //     {
  //       id: 'ME000000074',
  //       parentId: 'ME00000007',
  //       name: '族库',
  //       type: "link",
  //       url: `${process.env.VUE_APP_WEBSITE_ZUKU}?t=${store.getters.token}&tenantId=${store.getters.userInfo.tenantIdNow}`,
  //       enable: true,
  //       default: true,
  //       children: [],
  //     },
  //   ],
  // },
  // {
  //   id: 'ME00000008',
  //   parentId: '-1',
  //   name: '设置应用',
  //   icon: "module-icon-5",
  //   iconType: "local",
  //   type: "dialog",
  //   url: 'amDialogVisible',
  //   enable: false,
  //   default: true,
  //   group: "",
  //   children: [],
  // },
  // {
  //   id: 'ME00000007',
  //   url: '/template',
  //   name: '模板管理',
  //   parentId: '-1',
  //   icon: require('@/assets/images/menu/more_inactive.png'),
  //   icon_active: require('@/assets/images/menu/more_active.png'),
  //   enable: true,
  //   children: [],
  // },
  // {
  //   id: 'ME00000008',
  //   url: '/notabs',
  //   name: '模块分类',
  //   parentId: '-1',
  //   icon: require('@/assets/images/menu/more_inactive.png'),
  //   icon_active: require('@/assets/images/menu/more_active.png'),
  //   enable: true,
  //   children: [],
  // },
]

export default menu