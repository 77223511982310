import { KeyStrValuePair } from "../KeyValuePair";
import { IBaseTableData } from "./IBaseTableData";
import { getRamdomStr } from "../../util/EncryptionHelper"
import { BaseColumn } from "./BaseColumn";
import { Column } from "./Column";
import { PercentColumn } from "./PercentColumn";
import { TFormColumn } from "./TFormColumn";
import { ParamsForCreateOrUpdateRowCell } from "../DTO/ParamsForCreateOrUpdateRowCell";

type TOtherPropType = null | undefined | object | string | number | Date | boolean | IBaseTableData | IBaseTableData[] | KeyStrValuePair;

interface IColumnInfo {
    column: TFormColumn; // 列信息
    isEditing: boolean; // 是否处于编辑状态
}

export class FormRow implements IBaseTableData {

    public id?: string;
    public hasChildren: boolean = false;

    public $level: number;
    public $id: string;
    public $expanded: boolean = false;

    // 鼠标点击应忽略的元素的类名(当鼠标点击本表单行以外区域时，如果有某些元素理应被认为是本表单行的一部分，则不应该触发本表单行的提交事件，那么这些元素的 class 名称应该被添加到本数组中)
    public $submissionExceptionClassNames: string[] = [];

    [key: string]: TOtherPropType;

    private $columnsMap: Map<string, IColumnInfo> = new Map();

    constructor(
        columns: TFormColumn[],
        params: { $level: number, copyFrom?: IBaseTableData },
    ) {
        const { $level, copyFrom } = params;

        for (const column of columns) {
            if (column.id) {
                this.$columnsMap.set(column.id, { column, isEditing: true });

                // if (!column.active) return;

                if (copyFrom) {
                    this[column.id] = copyFrom[column.id] ?? undefined;
                } else {
                    this[column.id] = undefined; // 显式定义为 undefined
                }
            }
        }

        this.$level = $level;
        this.$id = `${$level}-${getRamdomStr('****')}`;
    }

    // 新行是否已创建
    public get isCreated() {
        return !!this.id;
    }

    // 检查新表单行中的所有必填单元格值是否准备就绪。
    public get isRequiredCellsValueReady() {
        for (const columnInfo of this.$columnsMap.values()) {
            if (columnInfo.column.required && this.isValEmpty(this[columnInfo.column.id])) {
                return false;
            }
        }
        return true;
    }

    // 获取新表单行中的所有单元格值
    public getAllCellsData(): ParamsForCreateOrUpdateRowCell[] {
        const params: ParamsForCreateOrUpdateRowCell[] = [];
        for (const columnInfo of this.$columnsMap.values()) {
            params.push({
                column: columnInfo.column,
                cellValue: this[columnInfo.column.id],
            });
        }
        return params;
    }

    // 获取新表单行中的所有准备就绪的单元格值
    public getNonEmptyCellsData(): ParamsForCreateOrUpdateRowCell[] {
        const params: ParamsForCreateOrUpdateRowCell[] = [];
        for (const columnInfo of this.$columnsMap.values()) {
            if (!this.isValEmpty(this[columnInfo.column.id])) {
                params.push({
                    column: columnInfo.column,
                    cellValue: this[columnInfo.column.id],
                });
            }
        }
        return params;
    }

    // FormRow 转换为 IBaseTableData 原始类型数据
    public toIBaseTableData(): IBaseTableData {
        const data: IBaseTableData = {
            hasChildren: this.hasChildren,
            $id: this.$id,
            $level: this.$level,
            $expanded: this.$expanded
        };
        for (const columnInfo of this.$columnsMap.values()) {
            const cellValue = this[columnInfo.column.id];
            data[columnInfo.column.id] = this.isValEmpty(cellValue) ? undefined : cellValue;
        }
        return data;
    }

    // 检查单元格的值是否有效
    private isValEmpty(val: TOtherPropType) {
        return val === undefined || val === null || val === '';
    }


}