const power = {
  state: {
    powerList: [],
    hasNopowerList: [],
    activePower: null,
    poweradmin:0
  },
  getters: {
    activePower: state => state.activePower,
    powerList: state => state.powerList,
    hasNopowerList: state => state.hasNopowerList,
    poweradmin: state => state.poweradmin,
  },
  mutations: {
    savePowerList(state, payload = []) {
      state.powerList = payload;
    },
    savehasNopowerList(state, payload = []) {
      state.hasNopowerList = payload;
    },
    saveActivePower(state, payload = null) {
      state.activePower = payload;
    },
    saveAdmin(state, payload = null) {
      state.poweradmin = payload;
    },
  }
};
export default power