import { render, staticRenderFns } from "./FlexibleTableIntegration.vue?vue&type=template&id=a36e225e&scoped=true"
import script from "./FlexibleTableIntegration.vue?vue&type=script&lang=ts"
export * from "./FlexibleTableIntegration.vue?vue&type=script&lang=ts"
import style0 from "./FlexibleTableIntegration.vue?vue&type=style&index=0&id=a36e225e&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a36e225e",
  null
  
)

export default component.exports