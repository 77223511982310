import { Named } from "./Table/Named";
import { IBaseTableData } from "./Table/IBaseTableData";
import { BaseColumn } from "./Table/BaseColumn";
import { Column } from "./Table/Column";
import { IconColumn } from "./Table/IconColumn";
import { ButtonColumn } from "./Table/ButtonColumn";
import { IndexColumn } from "./Table/IndexColumn";
import { PercentColumn } from "./Table/PercentColumn";
import { IMergeableColumn } from "./Table/IMergeableColumn";
import { TFormColumn } from "./Table/TFormColumn";

export type OptBtnsPositionType = "last-col" | "within-col" | "next-to-col" | "auto-hide";
export type ColumnType<T extends IBaseTableData = IBaseTableData> = Column<T> | IconColumn | ButtonColumn | IndexColumn | PercentColumn;

export class Table<T extends IBaseTableData> extends Named {

    /** 列的集合 */
    public columns: ColumnType<T>[];
    /** 默认冻结的列的 id 数组 */
    public fixedColIds?: string[];
    /** 单元格可合并的列的 id 数组(如果该列的相邻两个单元格的值一样，则合并显示) */
    public mergeableColIds?: (string | IMergeableColumn)[] | string[] | IMergeableColumn[];
    /** 摆放树形表格的展开箭头的列 */
    public treeArrowColIds?: string[];

    // {"last-col": "固定在表格最后一列", "within-col": "在某列的单元格里", "next-to-col": "在某列的右边", "auto-hide": "自动隐藏"}
    /** 操作按钮的位置 */
    public optBtnsPosition: OptBtnsPositionType;
    /** 操作按钮相对于哪一列进行定位(操作按钮固定在表格最后一列时，本字段无意义，应为 undefined) */
    public optBtnsTarget?: Column<T> | ButtonColumn | IndexColumn;

    // 操作按钮保存在本地的位置信息。本字段仅供前端使用，后端不需要保存
    // public optBtnsPositionLocal?: OptBtnsPositionType = "auto-hide";

    constructor(params: {
        name?: string,
        columns: (Column<T> | ButtonColumn | IndexColumn)[],
        fixedColIds?: string[],
        mergeableColIds?: string[],
        treeArrowColIds?: string[],
        optBtnsPosition?: OptBtnsPositionType,
        // optBtnsTarget?: Column<T> | ButtonColumn | IndexColumn,
        optBtnsTarget?: BaseColumn,
    }) {
        const {
            name = '',
            columns,
            fixedColIds,
            mergeableColIds,
            treeArrowColIds,
            optBtnsPosition = "auto-hide",
            optBtnsTarget,
        } = params;

        super(name);

        this.columns = columns;
        this.fixedColIds = fixedColIds;
        this.mergeableColIds = mergeableColIds;
        this.treeArrowColIds = treeArrowColIds;

        if ((optBtnsPosition !== "last-col" && optBtnsPosition !== "auto-hide") && !optBtnsTarget) {
            throw new Error('optBtnsPosition 为 "within-col" 或 "next-to-col" 时，optBtnsTarget 不能为空');
        }

        this.optBtnsPosition = optBtnsPosition;
        this.optBtnsTarget = optBtnsTarget;

        if (!this.isIdsNoRepeat(this.normalColumns)) {
            throw new Error('columns 列数组有 name 重复项');
        }
    }

    private isIdsNoRepeat(array: Column<T>[]) {
        const ids = array.map(o => o.id);
        const ids2 = Array.from(new Set(ids));
        return ids.length === ids2.length;
    }

    public get normalColumns(): Column<T>[] {
        const columns: Column<T>[] = [];
        for (const col of this.columns) if (col instanceof Column) columns.push(col);
        return columns;
    }

    public get formColumns(): (TFormColumn<T>)[] {
        const columns: (TFormColumn<T>)[] = [];
        for (const col of this.columns)
            if (col instanceof Column || col instanceof PercentColumn || col instanceof IconColumn)
                columns.push(col);
        return columns;
    }

}