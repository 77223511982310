<template>
  <div class="tree-arrow">
    <template v-if="totalIndent > 0">
      <div
        class="tree-arrow__indent"
        :style="{ width: `${totalIndent}px` }"
      ></div>
    </template>

    <div
      v-if="row.hasChildren"
      class="tree-arrow__area"
      :style="{ width: `${width}px` }"
      @click="onClick"
    >
      <div
        class="tree-arrow__icon"
        :class="{ expanded: row.$expanded }"
        :style="{ width: `${iconSize}px`, height: `${iconSize}px` }"
      >
        <!-- <IconArrow :size="iconSize" /> -->
        <svg-icon sys="common-table" name="TreeArrow/arrow" :size="iconSize" />
      </div>
    </div>
    <div
      v-else
      class="tree-arrow__empty"
      :style="{ width: `${width}px` }"
    ></div>
  </div>
</template>
<script lang="ts">
import Vue, { PropType } from "vue";
import SvgIcon from "@/flexible-table-module/svg-icon-importer";

// import IconArrow from "@/flexible-table-module/icons/TreeArrow/IconArrow.vue";

import { IBaseTableData } from "@/flexible-table-module/entity/Table/IBaseTableData";

export default Vue.extend({
  inheritAttrs: false,
  components: {
    SvgIcon,
    // IconArrow,
  },
  props: {
    row: { type: Object as PropType<IBaseTableData> },
    width: { type: Number, default: 16 },
    indent: { type: Number, default: 8 },
    // scope: { type: Object, required: true },
    // isExpanded: { type: Boolean, default: false },
  },
  data() {
    return {
      iconSize: 12,
    };
  },
  computed: {
    totalIndent(): number {
      if (this.row.$level > 0) {
        return this.indent * this.row.$level;
      } else {
        return 0;
      }
    },
  },
  mounted() {
    // console.log("scope :>> ", this.scope);
  },
  methods: {
    onClick() {
      this.row.$expanded = !this.row.$expanded;
      this.$emit("cell-tree-arrow-click", this.row, this.row.$expanded);
    },
  },
});
</script>
<style lang="scss" scoped>
@import "src/flexible-table-module/scss/_variables.scss";

.tree-arrow {
  box-sizing: border-box;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  user-select: none;
  overflow: hidden;
  flex-grow: 0;
  flex-shrink: 0;
  height: 20px;
  border-radius: 2px;
  margin-right: 4px;

  .tree-arrow__indent {
    height: 100%;
  }

  .tree-arrow__area {
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;

    &:hover {
      background-color: $color-light;
      cursor: pointer;
    }

    .tree-arrow__icon {
      box-sizing: border-box;
      display: flex;
      justify-content: center;
      align-items: center;

      &.expanded {
        transform: rotate(90deg);
      }
    }
  }

  .tree-arrow__empty {
  }
}
</style>