<template>
  <div class="number-frame">
    <div class="numbers">
      <div class="number">
        <div class="text">最小值</div>
        <ElInputNumber
          class="input"
          v-model="dMin"
          controls-position="right"
          @change="onMinInputChange"
          :min="min"
          :max="typeof dMax === 'number' ? dMax : max"
          label="请输入搜索数字"
        ></ElInputNumber>
      </div>
      <div class="number">
        <div class="text">最大值</div>
        <ElInputNumber
          class="input"
          v-model="dMax"
          controls-position="right"
          @change="onMaxInputChange"
          :min="typeof dMin === 'number' ? dMin : min"
          :max="max"
          label="请输入搜索数字"
        ></ElInputNumber>
      </div>
    </div>

    <SubmitAndCancel @submit="onSubmit" @cancel="onCancel" />
  </div>
</template>

<script lang="ts">
import Vue from "vue";
import { InputNumber as ElInputNumber } from "element-ui";

import SubmitAndCancel from "../../Common/SubmitAndCancel.vue";
export default Vue.extend({
  inheritAttrs: false,
  name: "NumberFrame",
  components: { ElInputNumber, SubmitAndCancel },
  props: {
    currMin: { type: Number },
    currMax: { type: Number },
    min: { type: Number, default: undefined },
    max: { type: Number, default: undefined },
  },
  data(): { dMin?: number; dMax?: number } {
    return {
      dMin: this.currMin ?? this.min,
      dMax: this.currMax ?? this.max,
    };
  },
  methods: {
    onMinInputChange() {},
    onMaxInputChange() {},
    onSubmit() {
      this.$emit("submit", [this.dMin, this.dMax]);
    },
    onCancel() {
      this.dMin = this.currMin;
      this.dMax = this.currMax;
      this.$emit("cancel");
    },
  },
});
</script>
<style lang="scss" scoped>
.number-frame {
  .numbers {
    .number {
      display: flex;
      justify-content: space-between;
      align-items: center;

      &:not(:last-child) {
        margin-bottom: 8px;
      }

      &:last-child {
        margin-bottom: 8px;
      }

      .text {
        flex-shrink: 0;
        margin-right: 12px;
      }
      .input {
      }
    }
  }
}
</style>