<template>
  <div class="split-pane">
    <div class="left-pane">
      <slot name="left"></slot>
    </div>
    <div class="right-pane" :style="{ width: rightWidth + 'px' }">
      <div
        class="divider"
        ref="divider"
        @mousedown="initDrag"
        @touchstart.prevent="initDrag"
        v-if="dividerVisible && dividerPosition == 1"
      ></div>
      <div
        class="divider divider-left"
        ref="divider"
        @mousedown="initDrag"
        @touchstart.prevent="initDrag"
        v-if="dividerVisible && dividerPosition == 2"
      ></div>
      <slot name="right"></slot>
    </div>
    <div class="left-pane">
      <slot name="left2"></slot>
    </div>
  </div>
</template>

<script>
import interact from "interactjs";

export default {
  props: {
    initialrightWidth: {
      type: Number,
      default: 250,
    }, //右侧初始宽度

    minPaneWidth: {
      type: Number,
      default: 10,
    }, //右侧最小宽度 

    dividerPosition: {
      type: Number,
      default: 1,
    }, //拖拽条的位置 1右上角 2右边整条
  },
  data() {
    return {
      rightWidth: this.initialrightWidth,
      isDragging: false,
      dividerVisible: true,
    };
  },
  watch: {
    initialrightWidth(val) {
      if (!val) {
        this.rightWidth = 0;
        this.dividerVisible = false;
      } else {
        this.rightWidth = val;
        this.dividerVisible = true;
      }
    },
  },
  methods: {
    initDrag(e) {
      this.isDragging = true;
      const divider = this.$refs.divider;

      interact(divider)
        .draggable({
          modifiers: [
            interact.modifiers.restrictRect({
              restriction: "parent",
              endOnly: true,
            }),
          ],
          listeners: {
            move: (event) => {
              if (this.isDragging) {
                const dx = event.dx;
                if (this.rightWidth - dx >= this.minPaneWidth) {
                  this.rightWidth -= dx;
                }
              }
            },
          },
        })
        .on("dragend", () => {
          this.isDragging = false;
        });
    },
  },
};
</script>

<style scoped>
.split-pane {
  display: flex;
  width: 100%;
  height: 100%;
  overflow: hidden;
  /* justify-content: center; */
}

.left-pane,
.right-pane {
  /* overflow: auto; */
  height: 100%;
  
}
.right-pane {
  position: relative;
}
.left-pane {
  flex: 1;
  min-width: 10px;
  position: relative;
  overflow: auto;
}

.divider {
  position: absolute;
  /* left: 0; */
  top: 0;
  width: 20px;
  height: 100%;
  cursor: ew-resize !important;
  background-color: transparent;
  z-index: 2000;
  /* background-color: #3797af; */
}

.divider-left {
  left: -20px;
  height: 100%;
}
</style>
