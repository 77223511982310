<template>
  <div class="form-cell-4-percent-column">
    <ElInputNumber
      :class="{ highlight }"
      v-model="cValue"
      :size="FORM_ITEM_SIZE"
      class="form-cell-4-percent-column__input"
      controls-position="right"
      :min="0"
      :max="100"
      :step="1"
    >
    </ElInputNumber>
    <span class="percent-symbol">%</span>
  </div>
</template>
<script lang="ts">
import Vue from "vue";
import { mixin } from "./mixin";

import { InputNumber as ElInputNumber } from "element-ui";

export default Vue.extend({
  inheritAttrs: false,
  name: "FormCell4PercentColumn",
  components: { ElInputNumber },
  mixins: [mixin],
});
</script>
<style lang="scss" scoped>
@import "src/flexible-table-module/scss/_variables.scss";
@import "src/flexible-table-module/scss/common.scss";

.form-cell-4-percent-column {
  @include form-cell-highlight;

  &__input {
    width: 100%;
  }

  $percent-symbol-size: $fs-form-cell-4-percent-column;

  .percent-symbol {
    position: absolute;
    right: 50px;
    top: 50%;
    transform: translateY(-58%);
    font-size: $percent-symbol-size;
    line-height: $percent-symbol-size;
    height: $percent-symbol-size;
  }
}
</style>