<template>
  <ElDialog
    class="dialog-setting"
    title="数据概览配置"
    :visible.sync="cVisible"
    width="30%"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    :modal-append-to-body="false"
  >
    <div class="dialog-content" v-loading="!data">
      <div class="default-fold">
        <ElRadioGroup v-model="defaultFold">
          <ElRadio :label="false">默认展开</ElRadio>
          <ElRadio :label="true">默认关闭</ElRadio>
        </ElRadioGroup>
      </div>
      <div class="title">
        <span v-if="allItems !== undefined"
          >概览配置(总共{{ allItems.length }}，已选5/{{
            allItems.length
          }})</span
        >
      </div>
      <div class="items" ref="container">
        <div class="item" v-for="item in allItems" :key="item.id">
          <div class="drag-handler">
            <!-- <IconDrag :size="12" /> -->
          </div>
          <div class="item-content">
            <div class="item-body">
              <span>{{ item.name }}</span>
            </div>
            <div class="item-radio">
              <ElSwitch
                v-model="item.active"
                :active-color="SWITCH_ACTIVE_COLOR"
                :inactive-color="SWITCH_INACTIVE_COLOR"
              >
              </ElSwitch>
            </div>
          </div>
        </div>
      </div>
    </div>

    <span slot="footer" class="dialog-footer" v-if="data">
      <ElButton @click="onDialogSettingCancel">取 消</ElButton>
      <ElButton type="primary" @click="onDialogSettingSubmit">确 定</ElButton>
    </span>
  </ElDialog>
</template>

<script lang="ts">
import Vue, { type DirectiveOptions, PropType } from "vue";
import {
  Button as ElButton,
  Dialog as ElDialog,
  RadioGroup as ElRadioGroup,
  Radio as ElRadio,
  Switch as ElSwitch,
  Loading as ElLoading,
} from "element-ui";

import { Loading } from "@/flexible-table-module/components/Common/Loading";

// import IconDrag from "@/flexible-table-module/icons/ColumnsSetting/IconDrag.vue";

// @ts-ignore
import Sortable from "sortablejs";

import { DataOverviewItem } from "@/flexible-table-module/entity/DataOverview/DataOverviewItem";
import {
  SWITCH_ACTIVE_COLOR,
  SWITCH_INACTIVE_COLOR,
} from "@/flexible-table-module/constants";

export default Vue.extend({
  inheritAttrs: false,
  name: "DialogSetting",
  components: {
    ElButton,
    ElDialog,
    ElRadioGroup,
    ElRadio,
    // IconDrag,
  },
  directives: { loading: Loading.directive },
  model: {
    prop: "visible",
    event: "update:visible",
  },
  props: {
    visible: { type: Boolean as PropType<boolean>, default: false },
    data: {
      type: Object as PropType<{
        defaultFold: boolean; // 默认展开 还是 默认关闭
        allItems: DataOverviewItem[]; // 概览配置项
      }>,
    },
  },
  data(): {
    defaultFold?: boolean;
    allItems?: DataOverviewItem[];
    hasDraggable: boolean; // 是否已经应用可拖拽
    hasReorder: boolean; // 是否排序被改变过
    SWITCH_ACTIVE_COLOR: string;
    SWITCH_INACTIVE_COLOR: string;
  } {
    return {
      defaultFold: undefined,
      allItems: undefined,
      hasDraggable: false,
      hasReorder: false,
      SWITCH_ACTIVE_COLOR,
      SWITCH_INACTIVE_COLOR,
    };
  },
  watch: {
    visible: {
      immediate: false,
      async handler(newVal, oldVal) {
        if (newVal) {
          this.$emit("dialog-setting-show");
          await this.$nextTick();
          this.draggableItems();
        }
      },
    },
    data: {
      immediate: false,
      async handler(newVal, oldVal) {
        this.freshRenderData();
      },
    },
  },
  computed: {
    // iconStyle(): object {
    //   const size = 12;
    //   return { width: `${size}px`, height: `${size}px` };
    // },
    cVisible: {
      get(): boolean {
        return this.visible;
      },
      set(val: boolean) {
        this.$emit("update:visible", val);
      },
    },
  },
  methods: {
    onDialogSettingSubmit() {
      let hasChange = false;

      if (this.defaultFold !== this.data.defaultFold) {
        this.$emit("default-fold-change", this.defaultFold);
        hasChange = true;
      }

      const dataAllItemActives = this.data.allItems.map((item) => item.active);
      const allItemActives = this.allItems?.map((item) => item.active);

      let isItemsChange = false;
      if (
        allItemActives &&
        dataAllItemActives.length === allItemActives.length
      ) {
        for (let i = 0; i < dataAllItemActives.length; i++) {
          const dac = dataAllItemActives[i];
          const ac = allItemActives[i];
          if (dac !== ac) {
            isItemsChange = true;
            // break;
            this.data.allItems[i].active = ac;
          }
        }
      }

      if (isItemsChange) {
        this.$emit("item-active-change", this.allItems);
        hasChange = true;
      }

      if (hasChange || this.hasReorder) {
        this.$emit("setting-change", {
          defaultFold: this.defaultFold,
          allItems: this.allItems,
        });

        this.hasReorder = false;
      }

      this.cVisible = false;
    },
    onDialogSettingCancel() {
      this.cVisible = false;

      this.freshRenderData();
    },
    freshRenderData() {
      if (this.data) {
        this.defaultFold = this.data.defaultFold;

        // 深度复制
        if (this.data.allItems) {
          this.allItems = this.data.allItems.map(
            (item) =>
              new DataOverviewItem({
                id: item.id,
                name: item.name,
                active: item.active,
                // value: item.value,
                atachment: item.atachment,
              })
          );

          this.hasDraggable = false;
        }
      }
    },
    draggableItems() {
      if (!this.hasDraggable) {
        new Sortable(this.$refs["container"], {
          animation: 300,
          // direction: "vertical",
          handle: ".drag-handler",
          onEnd: (/**Event*/ evt: any) => {
            const {
              itemEl, // dragged HTMLElement
              to, // target list
              from, // previous list
              oldIndex, // element's old index within old parent
              newIndex, // element's new index within new parent
              oldDraggableIndex, // element's old index within old parent, only counting draggable elements
              newDraggableIndex, // element's new index within new parent, only counting draggable elements
              clone, // the clone element
              pullMode, // when item is in another sortable: `"clone"` if cloning, `true` if moving
            } = evt;

            this.hasReorder = true;

            if (this.allItems) {
              this.allItems.splice(
                newIndex,
                0,
                this.allItems.splice(oldIndex, 1)[0]
              );
            }
            // this.$emit("column-sort", oldIndex, newIndex);
          },
        });

        this.hasDraggable = true;
      }
    },
  },
});
</script>
<style lang="scss" scoped>
@import "src/flexible-table-module/scss/_variables.scss";

.dialog-setting {
  .dialog-content {
    .default-fold {
      min-height: 40px;
    }
    .title {
    }
    .items {
      min-height: 80px;
      margin-top: 16px;

      $item-height: 36px;

      .item {
        box-sizing: border-box;
        width: 100%;
        height: $item-height;
        line-height: $item-height;
        margin: 8px 0;

        display: flex;
        justify-content: flex-start;
        align-items: center;

        .drag-handler {
          display: flex;
          justify-content: center;
          align-items: center;

          cursor: grab;

          width: 32px;
          height: 100%;
        }

        .item-content {
          width: calc(100% - 32px);
          height: 100%;
          display: flex;
          justify-content: flex-start;
          align-items: center;

          .item-body {
            box-sizing: border-box;
            width: 160px;
            height: 100%;
            border: 1px solid $color-light;
            border-radius: 4px;
            padding: 0 16px;
          }
          .item-radio {
            width: 120px;
            display: flex;
            justify-content: center;
            align-items: center;
          }
        }
      }
    }
  }
  .dialog-footer {
  }
}
</style>