class PageResult<T extends object> {
    /**
     * @description 分页获取数据的格式类
     * @param records 数据集合
     * @param total 数据总条数
     */
    constructor(
        public records: T[],
        public total: number,
    ) { }
}

export default PageResult;