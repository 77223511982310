// @ts-ignore
import DateHelper from "../util/DateHelper";
import { DATETIME_FORMAT, DATE_FORMAT, TIME_FORMAT } from "../constants";
import { Column } from "../entity/Table/Column";
import { IBaseTableData } from "../entity/Table/IBaseTableData";

export function cellValueFormatter(col: Column<IBaseTableData>, cellValue: any): string | undefined {

    try {
        switch (col.type) {
            case "string": {
                return typeof cellValue === "string" ? String(cellValue) : "";
            }
            case "number": {
                return typeof cellValue === "number" ? String(cellValue) : "";
            }
            case "time": {
                if (isNotEmpty(cellValue))
                    return formatDate(cellValue, TIME_FORMAT);
                else return "";
            }
            case "time-frame": {
                if (isNotEmpty(cellValue))
                    return formatDateFrame(cellValue, TIME_FORMAT);
                else return "-";
            }
            case "date": {
                if (isNotEmpty(cellValue))
                    return formatDate(cellValue, DATE_FORMAT);
                else return "";
            }
            case "date-frame": {
                if (isNotEmpty(cellValue))
                    return formatDateFrame(cellValue, DATE_FORMAT);
                else return "-";
            }
            case "datetime": {
                if (isNotEmpty(cellValue))
                    return formatDate(cellValue, DATETIME_FORMAT);
                else return "";
            }
            case "datetime-frame": {
                if (isNotEmpty(cellValue))
                    return formatDateFrame(cellValue, DATETIME_FORMAT);
                else return "-";
            }
            case "key-str-value": {
                return typeof cellValue.value === "string"
                    ? String(cellValue.value)
                    : "";
            }
            case "rich-text": {
                return cellValue;
            }
        }
    } catch (error: any) {
        console.log(
            "表格在转换单元格数据的显示格式时出错 :>> ",
            error.message,
            cellValue
        );
    }
}

export function datetimeFormatter(date: Date, format: string): string {
    if (isNotEmpty(date)) return formatDate(date, format);
    else return "";
}

function isNotEmpty(val: any) {
    return val !== undefined && val !== null;
}

function formatDate(date: Date, format: string): string {
    if (date instanceof Date) return DateHelper.format(date, format);
    else throw new Error("Date format error");
}

function formatDateFrame(
    dates: (Date | undefined | null)[],
    format: string
): string {
    if (Array.isArray(dates) && dates.length === 2) {
        const begin =
            dates[0] instanceof Date
                ? DateHelper.format(dates[0], format)
                : undefined;
        const end =
            dates[1] instanceof Date
                ? DateHelper.format(dates[1], format)
                : undefined;

        if (begin) {
            if (end) return `${begin} - ${end}`;
            else return `${begin} 始`;
        } else {
            if (end) return `${end} 终`;
            else return ``;
        }
    } else throw new Error("DateFrame format error");
}
