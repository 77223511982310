import Vue from 'vue';
import JhMessage from './JhMessage.vue';
function showMessage(props, clickHandler) {
  const vm = new Vue({
    render: h => h(JhMessage, {
      props,
      on: {
        confirm: () => {
          if (clickHandler) {
            clickHandler((success = true) => {
              vm.$children[0]["loading"] = false;
              if (success) {
                const vDom = vm.$children[0];
                vDom.$destroy();
                document.body.removeChild(vm.$el);
              }
            })
          } else {
            vm.$children[0]["loading"] = false;
            const vDom = vm.$children[0];
            vDom.$destroy();
            document.body.removeChild(vm.$el);
          }
        },
        close: () => {
          const vDom = vm.$children[0];
          vDom.$destroy();
          document.body.removeChild(vm.$el);
        }
      },

    }),
  }).$mount();
  document.body.appendChild(vm.$el);
}

export default showMessage;