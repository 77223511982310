<!--
 * @Author: aardpro
 * @Date: 2023-10-16 08:53:10
 * @LastEditors: aardpro
 * @LastEditTime: 2023-11-07 17:41:51
 * @Description: 一个可以缩放、拖动的浮层
 使用方法
    <FloatPanel
      v-if="floatPanelVisible"
      :canResize="false"
      :canMove="true"
      moveHandle=".move-handle"
      :hasModal="true"
    >
      <slotComponent @ask-close="floatPanelVisible=false" />
    </FloatPanel>
-->

<template>
  <div style="display: none">
    <div
      v-if="hasModal"
      :id="modalId"
      :style="{ zIndex }"
      style="position: absolute; inset: 0; background: rgba(0, 0, 0, 0.5)"
    ></div>
    <div :id="id" :style="customStyle" class="global-float-panel">
      <slot></slot>
    </div>
  </div>
</template>

<script>
import interact from "interactjs";
import promiseDom from "@/utils/promiseDom";

export default {
  components: {},
  props: {
    width: {
      type: String,
      default: "fit-content",
    },
    height: {
      type: String,
      default: "fit-content",
    },
    top: {
      type: String,
      default: "0",
    },
    left: {
      type: String,
      default: "0",
    },
    canResize: {
      type: Boolean,
      default: false,
    },
    canMove: {
      type: Boolean,
      default: false,
    },
    // 是否出现遮罩
    hasModal: {
      type: Boolean,
      default: false,
    },
    // 如果不想让整体被拖拽，而是智能拖拽到某一个元素
    // 则设置一个拖动的把手div，能够通过querySelector获取到
    // 注意要，把手元素必须有一定的宽度和高度，才能拖动
    moveHandle: {
      type: String,
    },
    zIndex: {
      type: Number,
      default: 1510,
    },
  },
  data() {
    return {
      id: "",
      modalId: "",
      customStyle: {},
    };
  },
  computed: {
    floaterId() {
      return `#${this.id}`;
    },
    floaterHandle() {
      return `#${this.id} ${this.moveHandle}`;
    },
  },
  watch: {},
  methods: {
    // --------------------------------- 以下是interact代码
    // 如果canResize, 则moveHandle不起作用
    async startInteract() {
      if (!this.canMove && !this.canResize) {
        return;
      }

      await this.$nextTick();
      if (this.canMove && this.moveHandle) {
        const hasMoveHandle = await promiseDom(
          document.getElementById(this.id),
          this.moveHandle
        );
        if (!hasMoveHandle) {
          console.error(`${this.floaterHandle}不存在`);
          return;
        }
      }
      this.unsetInteract();
      const instance =
        this.canResize || !this.moveHandle
          ? interact(this.floaterId)
          : interact(this.floaterHandle);
      const floater = document.getElementById(this.id);

      if (this.canResize) {
        instance.resizable({
          // resize from all edges and corners
          edges: { left: true, right: true, bottom: true, top: true },

          listeners: {
            move(event) {
              var target = event.target;
              var x = parseFloat(target.getAttribute("data-x")) || 0;
              var y = parseFloat(target.getAttribute("data-y")) || 0;

              // update the element's style
              target.style.width = event.rect.width + "px";
              target.style.height = event.rect.height + "px";

              // translate when resizing from top or left edges
              x += event.deltaRect.left;
              y += event.deltaRect.top;

              target.style.transform = "translate(" + x + "px," + y + "px)";

              target.setAttribute("data-x", x);
              target.setAttribute("data-y", y);
            },
          },
          modifiers: [
            // keep the edges inside the parent
            interact.modifiers.restrictEdges({
              outer: "parent",
            }),

            // minimum size
            interact.modifiers.restrictSize({
              min: { width: 400, height: 300 },
            }),
          ],

          inertia: true,
        });
      }
      if (this.canMove) {
        if (this.moveHandle && !this.canResize) {
          instance.draggable({
            listeners: {
              move(event) {
                const target = floater; //event.target;
                const dx = event.dx;
                const dy = event.dy;
                const x = (parseFloat(target.getAttribute("data-x")) || 0) + dx;
                const y = (parseFloat(target.getAttribute("data-y")) || 0) + dy;
                target.setAttribute("data-x", x);
                target.setAttribute("data-y", y);
                target.style.transform = "translate(" + x + "px, " + y + "px)";
              },
            },
            inertia: true,
            modifiers: [
              interact.modifiers.restrictRect({
                restriction: "parent",
                endOnly: true,
              }),
            ],
          });
        } else {
          instance.draggable({
            // allowFrom: ".mover",
            listeners: {
              move(event) {
                const target = event.target;
                const dx = event.dx;
                const dy = event.dy;
                const x = (parseFloat(target.getAttribute("data-x")) || 0) + dx;
                const y = (parseFloat(target.getAttribute("data-y")) || 0) + dy;
                target.setAttribute("data-x", x);
                target.setAttribute("data-y", y);
                target.style.transform = "translate(" + x + "px, " + y + "px)";
              },
            },
            inertia: true,
            modifiers: [
              interact.modifiers.restrictRect({
                restriction: "parent",
                endOnly: true,
              }),
            ],
          });
        }
      }
    },
    // 取消interact监听
    unsetInteract() {
      // if (interact.isSet(this.floaterId)) {
      interact(this.floaterId).unset();
      // }
      // if (interact.isSet(this.floaterHandle)) {
      interact(this.floaterHandle).unset();
      // }
    },
    askClose() {
      this.$emit("ask-close");
    },
  },
  created() {
    // 设置body为position:relative
    this.id = `id-${Math.random().toString(36).slice(2)}`;
    this.modalId = `id-${Math.random().toString(36).slice(2)}`;
    document.body.style.position = "relative";
  },
  mounted() {
    this.customStyle = {
      "--z-index": this.hasModal ? this.zIndex + 1 : this.zIndex,
      width: this.width,
      height: this.height,
      top: this.top,
      left: this.left,
    };
    // 把div移动到body下面
    const floater = document.getElementById(this.id);
    if (floater) {
      document.body.appendChild(floater);
      this.startInteract();
    }
    const modal = document.getElementById(this.modalId);
    if (modal) {
      document.body.appendChild(modal);
    }

    const floaterHandle = document.querySelector(this.floaterHandle);
    if (floaterHandle) {
      floaterHandle.style.minHeight = "1em";
    }
  },
  beforeDestroy() {
    this.unsetInteract();
    const floater = document.getElementById(this.id);
    if (floater) {
      floater.remove();
    }
    const modal = document.getElementById(this.modalId);
    if (modal) {
      modal.remove();
    }
  },
};
</script>

<style lang="scss">
.global-float-panel {
  position: absolute;
  z-index: var(--z-index, 1500);
}
</style>
