<template>
  <div class="simple-tree" :class="{ disabled: disabled }">
    <div class="simple-tree-inner" @click.capture="onSimpleTreeInnerClick">
      <ElTree
        ref="elTree"
        :class="{ disabled: disabled }"
        :data="nodes"
        :props="{
          label: props.title,
          children: props.children,
          disabled: onDisableResolving,
        }"
        :indent="indent"
        :node-key="nodeKey"
        :show-checkbox="hasCheckbox"
        :default-expand-all="defaultExpanded"
        :expand-on-click-node="false"
        @check-change="onCheckChange"
        @check="onCheck"
        @node-click="onTreeNodeClick"
      >
        <template v-slot="{ node, data }">
          <div
            class="custom-tree-node-container"
            :class="`level-${node.level}`"
            @click.stop="onTreeNodeRightSideClick(data)"
          >
            <slot v-bind="{ node: data, elNode: node }">
              <span class="custom-tree-node">
                <span class="custom-tree-node__label">{{
                  data[props.title]
                }}</span>
              </span>
            </slot>
          </div>
        </template>
      </ElTree>
    </div>
  </div>
</template>

<script lang="ts">
import Vue, { PropType } from "vue";
import { Tree as ElTree } from "element-ui";
import { ITreeNode } from "@/flexible-table-module/entity/TreeNode";

interface IProps {
  title: string;
  children: string;
  isChecked?: string;
  isHalfChecked?: string;
}

const DEFAULT_INDENT = 16;

export default Vue.extend({
  inheritAttrs: false,
  name: "SimpleTree",
  components: { ElTree },
  props: {
    nodes: { type: Array as PropType<ITreeNode[]> }, // 树型数据
    nodeKey: { type: String as PropType<string>, default: "id" }, // 节点的唯一标识
    props: {
      type: Object as PropType<IProps>,
      default: () => {
        return {} as IProps;
      },
    }, // 参考 Element UI 的树型组件的 props

    isFirstLevelAlignSecondLevel: { type: Boolean, default: false }, // tree 的第2级是否需要对齐第1级

    indent: { type: Number, default: DEFAULT_INDENT }, // 缩进宽度
    disabled: { type: Boolean, default: false }, // 是否不可展开、收缩节点；不可多选选中；不可单击及单击选中
    defaultExpanded: { type: Boolean, default: true }, // 默认展开所有节点
    hasCheckbox: { type: Boolean, default: true }, // 是否有复选框
    isCheckboxReadonly: { type: Boolean, default: false }, // 多选框是否只读(hasCheckbox 为 true 的情况下才有效)
    hasLeafCheckboxOnly: { type: Boolean, default: false }, // 是否只有叶子节点有复选框(hasCheckbox 为 true 的情况下才有效)
    isLeafCheckableOnly: { type: Boolean, default: false }, // 是否只有叶子节点可以选中(hasCheckbox 为 true 的情况下才有效)
    isLeafSingleCheckable: { type: Boolean, default: false }, // 是否只能单选叶子节点(hasCheckbox 为 true 的情况下才有效)
    isNodeClickSingleCheckable: { type: Boolean, default: false }, // 是否通过单击节点从而单选节点
  },
  watch: {},
  mounted() {
    this.resetNodePaddingOnUpdate();
  },
  updated() {
    this.resetNodePaddingOnUpdate();
  },
  methods: {
    // // 则返回目前被选中的节点的 key 所组成的数组
    // getCheckedKeys() {
    //   return (this.$refs["elTree"] as any).getCheckedKeys();
    // },

    // 设置某个节点的勾选状态
    setChecked(checkedNode: ITreeNode, isChecked: boolean = true) {
      (this.$refs["elTree"] as any).setChecked(checkedNode, isChecked, false);
    },

    // 设置被勾选的节点(没指定的节点会被取消勾选)
    async setCheckedNodes(checkedNodes: ITreeNode[]) {
      await this.$nextTick(); // 需要等待 tree 组件渲染完成，否则操作对象是上一次的对象

      const refElTree = this.$refs["elTree"] as any;

      // 1. 先将所有节点的选中状态清空
      refElTree.setCheckedKeys([], false);

      // 2. 设置被勾选中的节点
      checkedNodes.forEach((node: ITreeNode) => {
        refElTree.setChecked(node, true, false);
      });
    },

    // 获取所有被勾选中的节点(包括父节点和子节点，格式特殊为二维数组)
    getCheckedNodes(leafOnly: boolean = true): ITreeNode[][] {
      const refElTree = this.$refs["elTree"] as any;

      // 1. 获取所有被勾选中的叶子节点(传给el-tree的节点对象)
      const leafData: ITreeNode[] = refElTree.getCheckedNodes(true, false);

      if (leafOnly) {
        return leafData.map((data: ITreeNode) => [data]);
      } else {
        // 获取所有被勾选中的叶子节点，以及其所有的父节点(不包括根节点)，越往下级的节点越靠前排列

        // 2. 获取所有被勾选中的叶子节点(el-tree 自有的节点对象)
        const leafNodes: any[] = leafData.map((data: ITreeNode) => {
          return refElTree.getNode(data);
        });

        // 3. 获取被勾选中的叶子节点的所有的父节点(不包括根节点)
        const checkedNodes: ITreeNode[][] = [];
        leafNodes.forEach((leafNode: any) => {
          const parentNodes: ITreeNode[] = [];
          // 先加入叶子节点
          parentNodes.push(leafNode.data);

          let parentNode = leafNode.parent;
          while (parentNode) {
            // 去掉根节点(el-tree的根节点是一个虚拟节点)
            if (!parentNode.parent) break;
            parentNodes.push(parentNode.data);
            parentNode = parentNode.parent;
          }

          checkedNodes.push(parentNodes);
        });

        return checkedNodes;
      }
    },

    // 以上方法供外部调用
    // ---------------------------------------------------------------------------------

    // 当节点选中状态发生变化时
    onCheckChange(data: ITreeNode, isChecked: boolean, isHalfChecked: boolean) {
      if (data.isChecked !== isChecked) {
        this.$set(data, "isChecked", isChecked);
      }
      if (data.isHalfChecked !== isHalfChecked) {
        this.$set(data, "isHalfChecked", isHalfChecked);
      }
    },

    // 当复选框被点击时
    onCheck(
      node: ITreeNode,
      { checkedKeys, checkedNodes, halfCheckedKeys, halfCheckedNodes }: any
    ) {
      const checked = checkedNodes.includes(node);
      this.$emit("checkbox-change", node, checked);

      if (this.isLeafSingleCheckable) {
        if (checked) {
          this.$nextTick(() => {
            // 如果是叶子节点单选，则取消其它节点的选中状态
            (this.$refs["elTree"] as any).setCheckedKeys(
              [(node as any)[this.nodeKey]],
              false
            );
          });
        }
      }
    },

    // 当节点被点击时(不含复选框本身，也不含复选框和展开/收缩按钮)
    onTreeNodeClick(node: ITreeNode, elNode: any) {
      this.onTreeNodeLeftSideClick(node);
    },

    // 当树节点右侧被点击时(以复选框右 margin 的右边缘为中心的右侧区域)
    // 监听事件时用了 @click.stop 语法，不会触发 @node-click="onTreeNodeClick" 事件
    onTreeNodeRightSideClick(node: ITreeNode) {
      const refElTree = this.$refs["elTree"] as any;

      const elNode = refElTree.getNode(node);

      if (this.isNodeClickSingleCheckable) {
        this.$nextTick(() => {
          // 同一层的节点只有自己被勾选，
          // 其它兄弟节点都不勾选(即如果有兄弟节点本来是勾选状态的话，则取消勾选)
          const parent = elNode.parent;
          if (parent) {
            parent.childNodes.forEach((childNode: any) => {
              if (childNode !== elNode) {
                refElTree.setChecked(childNode.data, false, true);
              }
            });
          }
          // 将被点击的节点设置为选中状态
          refElTree.setChecked(node, true, true);
          this.$emit("node-click", node);
        });
      } else {
        this.$emit("node-click", node);
      }
    },

    onTreeNodeLeftSideClick(node: ITreeNode) {
      const refElTree = this.$refs["elTree"] as any;
      const elNode = refElTree.getNode(node);

      const checked = !elNode.checked;
      refElTree.setChecked(node, checked, true);

      this.$emit("checkbox-change", node, checked);
    },

    onSimpleTreeInnerClick(event: MouseEvent) {
      // 检查点击的元素是否是展开/收缩按钮(并且该按钮是在叶子节点上)
      let target: HTMLElement | null = event.target as HTMLElement;
      if (
        target.classList.contains("el-tree-node__expand-icon") &&
        target.classList.contains("is-leaf")
      ) {
        // console.log("透明箭头被点击");
      }
    },

    // 当需要决定是否禁用节点时
    onDisableResolving(node: ITreeNode, elNode: any) {
      if (this.disabled) return true;
      else {
        if (this.isLeafCheckableOnly) {
          return node.children && node.children.length > 0;
        }
      }
    },

    // 重置节点的 padding-left 样式
    resetNodePaddingOnUpdate() {
      if (!this.isFirstLevelAlignSecondLevel) return;

      this.$nextTick(() => {
        const treeNodes = (this.$refs["elTree"] as Vue).$el.querySelectorAll(
          ".custom-tree-node-container"
        );

        for (const treeNode of treeNodes) {
          const level = Array.from(treeNode.classList).find((className) =>
            className.startsWith("level-")
          );
          if (level) {
            const levelNumber = parseInt(level.split("-")[1]);

            // 获取 treeNode 的父元素 el-tree-node__content
            const parentNode = treeNode.parentElement;

            // 向上遍历 DOM 树找到祖先元素 el-tree-node__content
            // let parentNode = treeNode.parentElement;
            // while (
            //   parentNode &&
            //   !parentNode.classList.contains("el-tree-node__content")
            // ) {
            //   parentNode = parentNode.parentElement;
            // }

            if (parentNode && levelNumber >= 2) {
              parentNode.style.paddingLeft = `${
                (levelNumber - 2) * this.indent
              }px`;
            }
          }
        }
      });
    },
  },
});
</script>

<style lang="scss" scoped>
@import "src/flexible-table-module/scss/_variables.scss";

.simple-tree {
  font-size: $fs-simple-tree-default;

  width: 100%;
  height: 100%;
  overflow: auto;

  // https://guoshengbo.github.io/2019/02/28/%E8%A7%A3%E5%86%B3Element-ui-Tree%E7%BB%84%E4%BB%B6%E6%97%A0%E6%B3%95%E5%87%BA%E7%8E%B0%E6%A8%AA%E5%90%91%E6%BB%9A%E5%8A%A8%E6%9D%A1/
  .el-tree {
    min-width: 100%;
    display: inline-block !important;

    ::v-deep {
      .el-tree-node > .el-tree-node__children {
        overflow: visible;
      }

      .el-tree-node__content {
        .el-tree-node__expand-icon.is-leaf {
          cursor: pointer;
          pointer-events: none; // 不这样做的话，该”叶子节点的透明箭头“会吞噬点击事件
        }
      }
    }
  }

  &.disabled {
    cursor: not-allowed !important;
  }

  .nodes {
    &.disabled {
      pointer-events: none;
    }
  }

  .custom-tree-node-container {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;

    .custom-tree-node {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      cursor: pointer;

      &__label {
        font-size: inherit;
        flex: 1;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }
}
</style>