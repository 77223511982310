import { render, staticRenderFns } from "./AssociateArticle.vue?vue&type=template&id=c5a77786&scoped=true"
import script from "./AssociateArticle.vue?vue&type=script&lang=js"
export * from "./AssociateArticle.vue?vue&type=script&lang=js"
import style0 from "./AssociateArticle.vue?vue&type=style&index=0&id=c5a77786&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c5a77786",
  null
  
)

export default component.exports