<template>
  <div class="submit-and-cancel">
    <ElButton type="info" size="mini" @click="onCancel">取消</ElButton>
    <ElButton type="primary" size="mini" @click="onSubmit">确定</ElButton>
  </div>
</template>
<script lang="ts">
import Vue from "vue";
import { Button as ElButton } from "element-ui";

export default Vue.extend({
  inheritAttrs: false,
  name: "SubmitAndCancel",
  components: { ElButton },
  methods: {
    onSubmit() {
      this.$emit("submit");
    },
    onCancel() {
      this.$emit("cancel");
    },
  },
});
</script>
<style lang="scss" scoped>
.submit-and-cancel {
  display: flex;
  justify-content: flex-end;
  padding: 8px 0 0;
}
</style>