<template>
  <SelectionTags
    class="column-filter"
    :isShrinked="isShrinked"
    :tags="tags"
    @tag-close="onTagClose"
    @tags-clear="onTagsClear"
  >
    <template v-slot:title>
      <span>{{ column.title }}</span>
    </template>
  </SelectionTags>
</template>

<script lang="ts">
import Vue, { PropType } from "vue";

import SvgIcon from "@/flexible-table-module/svg-icon-importer";
// import { ColumnType } from "@/flexible-table-module/entity/Table";
import { Column } from "@/flexible-table-module/entity/Table/Column";
import { TFilterTargetType } from "@/flexible-table-module/entity/Table/ColumnTypes";

import SelectionTags from "@/flexible-table-module/components/Common/SelectionTags/SelectionTags.vue";
import SelectionTagItem from "@/flexible-table-module/components/Common/SelectionTags/SelectionTagItem.vue";

// @ts-ignore
import DateHelper from "@/flexible-table-module/util/DateHelper";

import {
  DATETIME_FORMAT,
  DATE_FORMAT,
  TIME_FORMAT,
} from "@/flexible-table-module/constants";

const TYPE_ERR_MSG = "后端返回 column filter 信息不完整，type 为 undefined";
const SELECT_LIST_ERR_MSG =
  "后端返回 column filter 信息不完整，selectList 为 undefined";

const SPLITER = " ~ ";

export default Vue.extend({
  name: "ColumnFilter",
  components: {
    SvgIcon,
    SelectionTags,
    SelectionTagItem,
  },
  props: {
    column: { type: Object as PropType<Column>, required: true },
    isShrinked: { type: Boolean }, // 是否收缩，收缩的话会把一行放不下的内容隐藏
  },
  data: () => ({
    tags: [] as { text: string; attachment?: any }[],
  }),
  watch: {
    "column.filter.target": {
      handler(target: TFilterTargetType) {
        this.geneTags();
      },
      deep: true,
      immediate: true,
    },
    "tags.length": {
      immediate: true,
      handler(newLength, oldLength) {
        // 注意：tags 的长度为 0 时，本组件不会渲染，故不会触发从 1 到 0 的变化
        // console.log(`tags 的长度从 ${oldLength} 变为 ${newLength}`);
        this.$emit("tags-length-change", newLength);
      },
    },
  },
  mounted() {},
  methods: {
    onTagClose(tag: { text: string; attachment?: any }, index: number) {
      // target 是数组的话，需要 index 来给父组件指示具体删除的是哪个 tag
      this.$emit("filter-tag-close", this.column, index);
    },

    onTagsClear() {
      this.$emit("filter-tags-clear", this.column);
    },

    geneTags() {
      this.tags.splice(0, this.tags.length); // 清空数组

      if (this.column.filter === undefined) return;

      const { target, type, selectList } = this.column.filter;

      if (type === undefined) {
        console.error(TYPE_ERR_MSG);
        return;
      }

      switch (this.column.filter?.type) {
        case "string-search":
        case "key-str-value-search": {
          this.tags.push({ text: target as string });
          break;
        }
        case "string-select":
        case "key-str-value-select": {
          if (selectList === undefined) {
            console.error(SELECT_LIST_ERR_MSG);
            return;
          }
          if (Array.isArray(target)) {
            for (const item of target) {
              const pair = selectList.find((pair) => pair.key === item);
              if (pair) {
                this.tags.push({ text: pair.value });
              }
            }
          }
          break;
        }
        case "number": {
          if (Array.isArray(target)) {
            const [_from, _to] = target;

            const from = typeof _from === "number" ? _from : "未定义";
            const to = typeof _to === "number" ? _to : "未定义";

            this.tags.push({ text: `${from}${SPLITER}${to}` });
          }
          break;
        }
        case "date-time-frame": {
          if (Array.isArray(target)) {
            const [_start, _end] = target;

            const start =
              _start instanceof Date
                ? DateHelper.format(_start, DATETIME_FORMAT)
                : "未定义";
            const end =
              _end instanceof Date
                ? DateHelper.format(_end, DATETIME_FORMAT)
                : "未定义";

            this.tags.push({ text: `${start}${SPLITER}${end}` });
          }
          break;
        }
        case "date-time-single": {
          this.tags.push({
            text: DateHelper.format(target as Date, DATETIME_FORMAT),
          });
          break;
        }
        // case "key-str-value-search": {
        //   break;
        // }
      }
    },
  },
});
</script>

<style lang="scss" scoped>
.column-filter {
}
</style>