<template>
  <div class="body-rich-text-cell" v-text="inlineText"></div>
</template>

<script lang="ts">
import Vue from "vue";
export default Vue.extend({
  inheritAttrs: false,
  name: "BodyRichTextCell",
  props: {
    richtext: { type: String, default: "" },
  },
  computed: {
    inlineText(): string {
      const regexHtml = /<.+>/;
      if (regexHtml.test(this.richtext)) {
        const domparser = new DOMParser();
        const doc = domparser.parseFromString(this.richtext, "text/html");
        const allInnerText = doc.body.innerText;
        return allInnerText;
      } else return this.richtext;
    },
  },
});
</script>