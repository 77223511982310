/*
 * @Author: Billy
 * @Date: 2022-03-17 11:49:58
 * @LastEditors: Billy
 * @LastEditTime: 2022-05-25 15:44:34
 * @Description: 输入框优化函数
 */

/**
 * @description 防抖函数
 * @param fn 回调
 * @param delay 延迟时间(毫秒)
 * @returns 
 */
export const debounce = (fn: (...args: any[]) => void, delay: number): (...args: any[]) => void => {
    let timerId: any;
    return (...args) => {
        if (timerId) clearTimeout(timerId);
        timerId = setTimeout(() => {
            fn.apply(this, args) // setTimout会发生this隐式丢失；改变this指向为调用debounce所指的对象
        }, delay);
    }
}

