const share = {
    namespaced: true, // 开启命名空间
    state: {
        //分享给我的页面显示
        isShare: false,
        // 分享id
        shareId: ''
    },
    getters: {},
    mutations: {
        //分享给我的页面显示状态
        changeShare(state, bool) {
            state.isShare = bool;
        },
        changeShareId(state, id) {
            state.shareId = id
        }
    },
    actions: {}
}
export default share;